
import { defineComponent } from "vue"
import { init_device } from "@/components/device";
import tester from "@/scripts/tester";
import * as torch from "@/torch"
//import { upload_params, get_params } from "@/scripts/upload_parameters"

export default defineComponent({
    name: "Diffuser",
    data() {
        return {
            device_available: true,
        }
    },
    mounted: async function() {
        this.device_available = await init_device();
    },
    methods: {
        /*
        upload() {
            //upload_params("../../parameters")
        },
        */
        runTestfile(event) {
            console.log(event);
            const reader = new FileReader();
            reader.onload = (event) => {
                const json = JSON.parse(event.target.result as string);
                tester(json, async (img) => {
                    await this.renderResult(img, "");
                    return;
                });
            }
            reader.readAsText(event.target.files[0])
        },
        renderResult: async function(result: torch.Tensor, caption: string) {
            result = result.squeeze(0).transpose(0, 1).transpose(1, 2);
            console.log("result shape: ", result.shape);
            const data = await result.toArrayAsync();
            if(!this.active) console.log("Result: ", data);
            const img_data = new Uint8ClampedArray(data.flat(4) as any);
            const box = document.createElement("div");
            box.className = "result-box";

            const canvas = document.createElement("canvas");
            canvas.setAttribute("width", "64px");
            canvas.setAttribute("height", "64px");
            const context = canvas.getContext("2d");
            context.putImageData(
                new ImageData(img_data, 64, 64), 
                0, 0);
            box.appendChild(canvas);
            const cap = document.createElement("div");
            cap.innerHTML = caption;
            box.appendChild(cap);
            this.$refs["cycle-list"].appendChild(box);
            return;
        }
    }
})

const input = [
    [
        [
            [
                -0.15217241644859314,
                -0.15789154171943665,
                -0.05191723629832268,
                -0.14932724833488464,
                0.13911855220794678,
                -0.1670653074979782,
                -0.166419118642807,
                -0.1642857939004898,
                0.13956820964813232,
                -0.07211896777153015,
                -0.0036497211549431086,
                -0.1258062869310379,
                -0.08916159719228745,
                -0.08853600174188614,
                1.1154553890228271,
                -0.17003723978996277
            ],
            [
                -0.06537487357854843,
                -0.009079975076019764,
                -0.002580619417130947,
                -0.018923280760645866,
                -0.07046319544315338,
                -0.026442188769578934,
                -0.11179881542921066,
                -0.05170280486345291,
                0.3813365697860718,
                -0.0006727168220095336,
                -0.0018999244784936309,
                -0.1540568321943283,
                -0.05311540886759758,
                -0.17001892626285553,
                -0.027957165613770485,
                -0.03002258762717247
            ],
            [
                -0.04469844326376915,
                -0.15882407128810883,
                -0.16075268387794495,
                -0.15636055171489716,
                0.15311434864997864,
                -0.16993668675422668,
                0.25645729899406433,
                -0.0036031831987202168,
                -0.022263281047344208,
                -0.03117697313427925,
                -0.0013501596404239535,
                -0.022944441065192223,
                -0.03923799842596054,
                -0.16618570685386658,
                -0.00023125864390749484,
                -0.14697891473770142
            ],
            [
                0.15158163011074066,
                -0.11834735423326492,
                -0.01307600922882557,
                -0.021737022325396538,
                -0.017704136669635773,
                1.1510002613067627,
                -0.035200633108615875,
                -0.001055292203091085,
                -0.06519563496112823,
                -0.08059251308441162,
                -0.00006431823567254469,
                -0.0030837864615023136,
                -0.00033251597778871655,
                -0.15810059010982513,
                0.9080480337142944,
                -0.023995840921998024
            ],
            [
                -0.14515893161296844,
                -0.06829605251550674,
                -0.015348662622272968,
                -0.000612256582826376,
                -0.004353126045316458,
                -0.004458419978618622,
                -0.05891730636358261,
                -0.011781533248722553,
                -0.1351594775915146,
                -0.0018313064938411117,
                -0.0005871212342754006,
                -0.08294548094272614,
                -0.04436927288770676,
                -0.02326975390315056,
                -0.06978454440832138,
                -0.16897009313106537
            ],
            [
                -0.11052189022302628,
                -0.04281264916062355,
                -0.09388146549463272,
                -0.00016335694817826152,
                -0.038791723549366,
                -0.10005488246679306,
                -0.0008563860319554806,
                -0.05692853033542633,
                -0.08164316415786743,
                -0.01928659901022911,
                -0.0818328857421875,
                1.981881856918335,
                0.6235127449035645,
                -0.030747905373573303,
                -0.16867230832576752,
                -0.002788781188428402
            ],
            [
                -0.09734075516462326,
                -0.15658824145793915,
                0.08863721042871475,
                -0.15914380550384521,
                -0.06013461574912071,
                -0.16280628740787506,
                -0.029811754822731018,
                -0.021404413506388664,
                -0.13874396681785583,
                -0.16006922721862793,
                -0.0017394281458109617,
                -0.006463047116994858,
                -0.0012461201986297965,
                2.5810132026672363,
                2.9294567108154297,
                0.9050734043121338
            ],
            [
                -0.0623420812189579,
                -0.1613251119852066,
                -0.01840209774672985,
                -0.16548320651054382,
                0.013214065693318844,
                -0.14738619327545166,
                -0.117682546377182,
                -0.05138003081083298,
                0.2320753037929535,
                -0.053094688802957535,
                -0.011603888124227524,
                -0.10129348188638687,
                -0.06716829538345337,
                0.9425170421600342,
                -0.131253182888031,
                -0.09460209310054779
            ],
            [
                -0.1613561362028122,
                -0.17001228034496307,
                -0.1698571741580963,
                -0.168723002076149,
                -0.1472441703081131,
                -0.16526542603969574,
                -0.1409134566783905,
                -0.05030796676874161,
                -0.1638675481081009,
                -0.1510820984840393,
                -0.15372343361377716,
                -0.16709056496620178,
                -0.16897398233413696,
                -0.16994228959083557,
                -0.16997376084327698,
                -0.138748899102211
            ],
            [
                -0.14609460532665253,
                -0.17004036903381348,
                -0.13630327582359314,
                -0.16206930577754974,
                -0.16900545358657837,
                -0.13287824392318726,
                -0.03590888902544975,
                -0.0965036004781723,
                -0.1576807051897049,
                -0.12889911234378815,
                -0.14836889505386353,
                -0.1659994274377823,
                -0.14598038792610168,
                -0.15897969901561737,
                -0.16721782088279724,
                -0.1700289398431778
            ],
            [
                -0.1494043618440628,
                -0.1592770218849182,
                -0.16586247086524963,
                -0.16552944481372833,
                -0.15259800851345062,
                -0.15383365750312805,
                -0.14883774518966675,
                -0.16610850393772125,
                -0.14970168471336365,
                -0.10069608688354492,
                -0.1645781546831131,
                -0.16893373429775238,
                -0.10025821626186371,
                -0.15381421148777008,
                -0.16731224954128265,
                -0.14443804323673248
            ],
            [
                -0.16376440227031708,
                -0.0869721844792366,
                -0.16839630901813507,
                -0.13488011062145233,
                -0.16259005665779114,
                -0.15912146866321564,
                -0.16980256140232086,
                -0.15502509474754333,
                -0.16975253820419312,
                -0.170039102435112,
                -0.1278754621744156,
                -0.1645093411207199,
                -0.16308048367500305,
                -0.15756037831306458,
                -0.1677456796169281,
                -0.15023711323738098
            ],
            [
                -0.1569794863462448,
                -0.14968498051166534,
                -0.14609423279762268,
                -0.13556954264640808,
                -0.06481922417879105,
                -0.09919272363185883,
                -0.09493033587932587,
                -0.14686034619808197,
                -0.16754357516765594,
                -0.16991466283798218,
                -0.1684754192829132,
                -0.12579824030399323,
                -0.14431504905223846,
                -0.14832469820976257,
                -0.16904613375663757,
                -0.16597144305706024
            ],
            [
                -0.165893092751503,
                -0.16298550367355347,
                -0.16996213793754578,
                -0.16837674379348755,
                -0.16357767581939697,
                -0.14972789585590363,
                -0.166154146194458,
                -0.14557881653308868,
                -0.16779610514640808,
                -0.15144699811935425,
                -0.1697196364402771,
                -0.09215783327817917,
                -0.16612984240055084,
                -0.1699678897857666,
                -0.17003878951072693,
                -0.1586572825908661
            ],
            [
                -0.15919920802116394,
                -0.12818965315818787,
                -0.1695534586906433,
                -0.06080882251262665,
                -0.16849154233932495,
                -0.16234681010246277,
                -0.0856400653719902,
                -0.04345693439245224,
                -0.08985263854265213,
                -0.1692909598350525,
                -0.1693340390920639,
                -0.16694049537181854,
                -0.1628388613462448,
                -0.1606758087873459,
                -0.10117442160844803,
                -0.16482889652252197
            ],
            [
                -0.16552019119262695,
                -0.09584344923496246,
                -0.1698342114686966,
                -0.1642477959394455,
                -0.14024445414543152,
                -0.14521120488643646,
                -0.15140590071678162,
                -0.15932400524616241,
                -0.16944608092308044,
                -0.16883257031440735,
                -0.16265156865119934,
                -0.15402236580848694,
                -0.16963791847229004,
                -0.15224526822566986,
                -0.0157554242759943,
                -0.06583359092473984
            ]
        ],
        [
            [
                5.750821113586426,
                4.689429759979248,
                4.453963279724121,
                4.576038837432861,
                5.159224510192871,
                6.146286487579346,
                5.012783527374268,
                2.814547061920166,
                5.623816013336182,
                4.8111443519592285,
                4.02213716506958,
                5.064148902893066,
                4.005777359008789,
                5.580315589904785,
                8.704093933105469,
                6.824978828430176
            ],
            [
                2.3558080196380615,
                5.688205718994141,
                3.6532161235809326,
                2.810344934463501,
                2.3892767429351807,
                8.370890617370605,
                5.2422871589660645,
                4.746501445770264,
                4.813155651092529,
                1.0403320789337158,
                0.8163227438926697,
                2.7794415950775146,
                1.3601442575454712,
                5.133358001708984,
                5.581293106079102,
                7.19141960144043
            ],
            [
                2.0313072204589844,
                6.189544677734375,
                3.8332808017730713,
                3.0156919956207275,
                3.4044888019561768,
                6.749273300170898,
                8.536886215209961,
                2.7742977142333984,
                2.8986618518829346,
                0.4046442210674286,
                0.12990546226501465,
                4.45133638381958,
                5.296666622161865,
                8.128273010253906,
                6.875177383422852,
                9.733344078063965
            ],
            [
                7.778208255767822,
                2.4633820056915283,
                1.305253267288208,
                2.347194194793701,
                4.002075672149658,
                7.0952534675598145,
                5.777933120727539,
                1.3041574954986572,
                3.6947476863861084,
                1.1510794162750244,
                1.2363874912261963,
                0.6767011880874634,
                2.4838006496429443,
                9.892027854919434,
                10.184967994689941,
                4.56470251083374
            ],
            [
                8.438282012939453,
                6.765131950378418,
                2.5176005363464355,
                1.2899413108825684,
                2.7983756065368652,
                3.7533392906188965,
                5.529041290283203,
                6.378276348114014,
                4.208486557006836,
                1.6449028253555298,
                2.047018527984619,
                4.170755863189697,
                2.4090518951416016,
                2.375157594680786,
                3.2884175777435303,
                2.8504817485809326
            ],
            [
                5.456572532653809,
                3.1323986053466797,
                6.268856048583984,
                0.7684963345527649,
                2.9084980487823486,
                4.859710216522217,
                2.2526516914367676,
                4.962080478668213,
                0.14939935505390167,
                1.4886736869812012,
                8.603705406188965,
                null,
                7.258160591125488,
                3.472320556640625,
                4.059179782867432,
                5.024796962738037
            ],
            [
                2.736313581466675,
                1.9988601207733154,
                6.180126667022705,
                6.070505619049072,
                3.0280096530914307,
                5.619309902191162,
                5.3746442794799805,
                2.36853289604187,
                4.117227077484131,
                5.685461044311523,
                4.739326477050781,
                5.597548007965088,
                3.2907402515411377,
                null,
                10.237728118896484,
                7.4710693359375
            ],
            [
                2.2457199096679688,
                5.948950290679932,
                9.05046272277832,
                9.015511512756348,
                3.6575326919555664,
                5.071073055267334,
                8.356489181518555,
                4.159657001495361,
                8.030871391296387,
                4.416460990905762,
                1.5115894079208374,
                0.7859272956848145,
                4.514872074127197,
                null,
                null,
                3.7170002460479736
            ],
            [
                2.3689894676208496,
                2.271089792251587,
                1.7432224750518799,
                1.6312650442123413,
                1.9083274602890015,
                2.1831350326538086,
                2.1346750259399414,
                2.411882162094116,
                2.118934392929077,
                1.7099535465240479,
                2.0233402252197266,
                2.204800605773926,
                2.2648825645446777,
                2.1180074214935303,
                1.9981282949447632,
                2.05531907081604
            ],
            [
                2.4307475090026855,
                2.059706211090088,
                1.8192967176437378,
                2.104184627532959,
                2.190404176712036,
                2.101757526397705,
                2.12253999710083,
                2.1421520709991455,
                2.1706063747406006,
                2.036870241165161,
                2.3138046264648438,
                2.0438592433929443,
                2.119734048843384,
                2.085651159286499,
                1.7844842672348022,
                2.045858860015869
            ],
            [
                2.0973644256591797,
                2.069546699523926,
                1.8179054260253906,
                2.1006999015808105,
                1.7428455352783203,
                1.6739137172698975,
                1.8124948740005493,
                1.940528392791748,
                1.8697690963745117,
                1.9886916875839233,
                1.9051786661148071,
                2.213959217071533,
                2.6537535190582275,
                2.024179458618164,
                2.050701379776001,
                2.0386993885040283
            ],
            [
                2.431490421295166,
                1.9296430349349976,
                1.8690332174301147,
                2.046626567840576,
                1.7084420919418335,
                1.7623144388198853,
                1.8725701570510864,
                1.950119137763977,
                1.900790810585022,
                1.9615585803985596,
                1.719050645828247,
                2.076458692550659,
                2.2117044925689697,
                2.1123037338256836,
                2.4654221534729004,
                1.4939769506454468
            ],
            [
                2.311469554901123,
                1.877661108970642,
                1.9714044332504272,
                1.5281460285186768,
                1.7839938402175903,
                1.9858089685440063,
                2.0655064582824707,
                2.0191233158111572,
                1.9289805889129639,
                2.080249786376953,
                2.0574593544006348,
                2.1583354473114014,
                2.030395746231079,
                2.121490478515625,
                2.2681198120117188,
                1.8299484252929688
            ],
            [
                2.3849973678588867,
                2.159001350402832,
                2.0001673698425293,
                1.9341703653335571,
                1.955081582069397,
                1.5391324758529663,
                1.8814127445220947,
                2.1592135429382324,
                1.953059196472168,
                2.0237784385681152,
                2.1170034408569336,
                1.7573083639144897,
                1.9083366394042969,
                2.204192638397217,
                2.0665347576141357,
                2.288104295730591
            ],
            [
                2.354365110397339,
                1.995725154876709,
                1.867392659187317,
                1.9153318405151367,
                1.9802465438842773,
                1.7469608783721924,
                2.0734355449676514,
                2.1024789810180664,
                2.3227429389953613,
                1.9013946056365967,
                1.9504921436309814,
                1.9191548824310303,
                1.8959213495254517,
                2.0557942390441895,
                2.142177104949951,
                2.1168854236602783
            ],
            [
                2.2954444885253906,
                2.165294647216797,
                2.3496932983398438,
                2.2496116161346436,
                2.245675563812256,
                2.0853488445281982,
                2.1646902561187744,
                2.220071792602539,
                2.305654764175415,
                2.204507350921631,
                2.234370231628418,
                2.281123638153076,
                2.227583408355713,
                2.2978620529174805,
                2.3122355937957764,
                2.2049670219421387
            ]
        ],
        [
            [
                2.234840154647827,
                0.39348718523979187,
                3.0100438594818115,
                1.6623148918151855,
                2.5454564094543457,
                4.827805042266846,
                3.0259079933166504,
                0.21908093988895416,
                3.033547878265381,
                3.1217832565307617,
                1.9405614137649536,
                5.206700325012207,
                3.3209640979766846,
                4.531806468963623,
                5.809417247772217,
                0.29705676436424255
            ],
            [
                1.6829965114593506,
                5.63520622253418,
                4.452733993530273,
                3.473471164703369,
                2.244426965713501,
                7.973214626312256,
                5.867137908935547,
                4.964805603027344,
                4.025340557098389,
                0.27775174379348755,
                1.3810800313949585,
                1.8539016246795654,
                3.0733582973480225,
                4.765681266784668,
                5.405418872833252,
                1.9584990739822388
            ],
            [
                1.748342514038086,
                4.952587604522705,
                2.7505686283111572,
                1.7765085697174072,
                2.3173587322235107,
                5.607673645019531,
                4.6139445304870605,
                3.7541186809539795,
                1.5142313241958618,
                0.7444287538528442,
                0.33800166845321655,
                3.3061912059783936,
                3.0743682384490967,
                4.997076034545898,
                4.386207580566406,
                5.168036937713623
            ],
            [
                2.8081424236297607,
                1.8967522382736206,
                3.3203301429748535,
                2.908510208129883,
                2.982318639755249,
                5.99233865737915,
                4.669075012207031,
                1.9758814573287964,
                1.698265552520752,
                0.18954914808273315,
                1.9844629764556885,
                1.147800087928772,
                3.099343776702881,
                7.4273576736450195,
                5.650231838226318,
                1.640284776687622
            ],
            [
                5.436152935028076,
                5.3569111824035645,
                4.116184711456299,
                1.5430501699447632,
                1.843287706375122,
                5.092740058898926,
                3.720285415649414,
                2.7953386306762695,
                0.9660595059394836,
                2.0276947021484375,
                1.2566810846328735,
                2.51012921333313,
                2.4237515926361084,
                2.8035595417022705,
                3.5621087551116943,
                0.9874019622802734
            ],
            [
                4.012331962585449,
                2.7937510013580322,
                5.273455619812012,
                1.6212589740753174,
                2.101919174194336,
                4.3979902267456055,
                1.9431874752044678,
                1.258124589920044,
                1.0217584371566772,
                1.222977876663208,
                7.2736921310424805,
                10.269559860229492,
                4.761157989501953,
                3.5418906211853027,
                4.314561367034912,
                2.0650501251220703
            ],
            [
                2.7623727321624756,
                1.258561134338379,
                3.9997143745422363,
                4.425188064575195,
                2.264892101287842,
                1.4306305646896362,
                3.6295502185821533,
                0.07963213324546814,
                4.003247261047363,
                3.6256837844848633,
                5.172346115112305,
                6.881354331970215,
                3.480628490447998,
                9.76109790802002,
                7.81102991104126,
                2.9907681941986084
            ],
            [
                1.8780226707458496,
                4.746005058288574,
                6.992119789123535,
                7.050966262817383,
                0.9616416096687317,
                1.2245489358901978,
                5.806421756744385,
                3.651265859603882,
                4.616059303283691,
                3.5579888820648193,
                1.236794352531433,
                0.9765519499778748,
                3.77232027053833,
                9.057779312133789,
                7.384681701660156,
                -0.04155139997601509
            ],
            [
                -0.03137531504034996,
                -0.06915158033370972,
                -0.10946022719144821,
                -0.030819641426205635,
                -0.1621948629617691,
                -0.16608929634094238,
                -0.1697300672531128,
                -0.08336833864450455,
                0.04603009298443794,
                -0.14806799590587616,
                -0.1580018550157547,
                -0.16917988657951355,
                -0.15568242967128754,
                -0.16611455380916595,
                -0.1699889451265335,
                -0.15909254550933838
            ],
            [
                -0.15904021263122559,
                -0.09376636892557144,
                -0.13580520451068878,
                -0.16697658598423004,
                -0.166304349899292,
                -0.15683391690254211,
                -0.16999097168445587,
                -0.11868959665298462,
                -0.15634216368198395,
                -0.16191425919532776,
                -0.16865406930446625,
                -0.13885770738124847,
                -0.13919000327587128,
                -0.1692628264427185,
                -0.17000244557857513,
                -0.17000837624073029
            ],
            [
                -0.16648095846176147,
                -0.16370424628257751,
                -0.15407752990722656,
                -0.16714192926883698,
                -0.1688956618309021,
                -0.1689649224281311,
                -0.1318569779396057,
                -0.1649935096502304,
                -0.16328424215316772,
                -0.1608518362045288,
                -0.1683245301246643,
                -0.14925992488861084,
                -0.12922270596027374,
                -0.161992147564888,
                -0.15630404651165009,
                -0.1685386300086975
            ],
            [
                -0.16566038131713867,
                -0.16552749276161194,
                -0.1671978384256363,
                -0.1638464778661728,
                -0.17003165185451508,
                -0.15722894668579102,
                -0.1694442182779312,
                -0.13661640882492065,
                -0.13523295521736145,
                -0.16492897272109985,
                -0.15995237231254578,
                -0.16518448293209076,
                -0.14921942353248596,
                -0.1575484573841095,
                -0.14169122278690338,
                0.03724557161331177
            ],
            [
                -0.1624498963356018,
                -0.16915564239025116,
                -0.17003938555717468,
                -0.14576083421707153,
                -0.06345652788877487,
                -0.12837715446949005,
                -0.15881788730621338,
                -0.163021519780159,
                -0.16988489031791687,
                -0.16335418820381165,
                -0.1699085831642151,
                -0.1512748897075653,
                -0.155125692486763,
                -0.15945971012115479,
                -0.07476149499416351,
                -0.06264639645814896
            ],
            [
                -0.10042092949151993,
                -0.11680074036121368,
                -0.07302138954401016,
                -0.16775347292423248,
                -0.0938393697142601,
                -0.1596526950597763,
                -0.15152738988399506,
                -0.14171870052814484,
                -0.14639781415462494,
                -0.16183215379714966,
                -0.16900910437107086,
                -0.16795548796653748,
                -0.16942080855369568,
                -0.14013071358203888,
                -0.170001819729805,
                -0.13281618058681488
            ],
            [
                -0.10154784470796585,
                -0.138199582695961,
                -0.1270405501127243,
                -0.16423048079013824,
                -0.1346743106842041,
                -0.1473911851644516,
                -0.15138424932956696,
                -0.13980792462825775,
                -0.14788125455379486,
                -0.16966621577739716,
                -0.16594721376895905,
                -0.1531146764755249,
                -0.16909460723400116,
                -0.1699540913105011,
                -0.16864238679409027,
                -0.15992949903011322
            ],
            [
                -0.16390934586524963,
                -0.1672990471124649,
                -0.1614808738231659,
                -0.15357322990894318,
                -0.12901416420936584,
                -0.1435348391532898,
                -0.15529799461364746,
                -0.16291624307632446,
                -0.16900016367435455,
                -0.16993650794029236,
                -0.16740287840366364,
                -0.16508576273918152,
                -0.16415075957775116,
                -0.15053358674049377,
                -0.12326404452323914,
                -0.14018818736076355
            ]
        ],
        [
            [
                -0.02530953288078308,
                -0.14688058197498322,
                -0.008525462821125984,
                -0.014818164519965649,
                -0.05700916051864624,
                -0.14614446461200714,
                -0.0862329974770546,
                -0.0017511307960376143,
                -0.01305937860161066,
                -0.013145332224667072,
                -0.003768252907320857,
                -0.08482076972723007,
                -0.008488710038363934,
                -0.0638088583946228,
                1.0486328601837158,
                -0.1700381487607956
            ],
            [
                -0.027457529678940773,
                -0.16109643876552582,
                -0.05443733185529709,
                -0.008050045929849148,
                -0.007406485732644796,
                0.08941887319087982,
                -0.1267608404159546,
                -0.08206720650196075,
                -0.16419273614883423,
                -0.0011140763526782393,
                -0.000044527751015266404,
                -0.12218592315912247,
                -0.011317874304950237,
                -0.15605859458446503,
                -0.07777014374732971,
                -0.01151580922305584
            ],
            [
                -0.00167572102509439,
                -0.10766178369522095,
                -0.16182969510555267,
                -0.031678762286901474,
                -0.1587401032447815,
                -0.08809949457645416,
                -0.1608024537563324,
                -0.03684297949075699,
                -0.05026565492153168,
                -0.000543329631909728,
                -0.000015266557966242544,
                -0.1517958790063858,
                -0.136806920170784,
                1.0050302743911743,
                -0.08728448301553726,
                0.7087264060974121
            ],
            [
                0.18111951649188995,
                -0.0033332461025565863,
                -0.023714428767561913,
                -0.043921541422605515,
                -0.16617566347122192,
                -0.1290774643421173,
                -0.1162506565451622,
                -0.034174829721450806,
                -0.07393088936805725,
                -0.004643857013434172,
                -0.00040246942080557346,
                -0.0005541382706724107,
                -0.0015028751222416759,
                -0.0681430846452713,
                0.15099447965621948,
                -0.10123185813426971
            ],
            [
                0.10184348374605179,
                0.04652612656354904,
                -0.0070259165950119495,
                -0.05138561874628067,
                -0.0763608068227768,
                -0.04766282066702843,
                -0.1587459295988083,
                0.042476508766412735,
                -0.14333491027355194,
                -0.0001973012840608135,
                -0.00007134956103982404,
                -0.16557441651821136,
                -0.0010972695890814066,
                -0.016517285257577896,
                -0.021450204774737358,
                -0.05519265681505203
            ],
            [
                -0.06471459567546844,
                -0.0043165916576981544,
                -0.14622056484222412,
                -0.00007486093818442896,
                -0.012126808986067772,
                -0.16058184206485748,
                -0.017674820497632027,
                -0.14665694534778595,
                -0.00015485311450902373,
                -0.11044718325138092,
                -0.1059768870472908,
                0.2354714572429657,
                -0.12878991663455963,
                -0.15438058972358704,
                -0.0662306696176529,
                -0.06571709364652634
            ],
            [
                -0.00011557876860024408,
                -0.0002028047456406057,
                -0.16216978430747986,
                -0.16275358200073242,
                -0.0021084975451231003,
                -0.14466363191604614,
                -0.16982103884220123,
                -0.011125140823423862,
                -0.1496628224849701,
                -0.1691172569990158,
                -0.0753580704331398,
                -0.0010298625566065311,
                -0.0214445348829031,
                0.8770042061805725,
                2.0119900703430176,
                0.2636706531047821
            ],
            [
                -0.0006412990624085069,
                -0.11060846596956253,
                0.08949935436248779,
                -0.04975426569581032,
                -0.11094380915164948,
                -0.15730886161327362,
                -0.08944541215896606,
                -0.07211247086524963,
                0.24667276442050934,
                -0.014316820539534092,
                -0.00042987611959688365,
                -0.013905364088714123,
                -0.032367780804634094,
                -0.14067436754703522,
                -0.023051578551530838,
                -0.06856901943683624
            ],
            [
                0.0664592832326889,
                -0.0994182750582695,
                -0.11700600385665894,
                -0.09558889269828796,
                -0.09932120889425278,
                -0.08864876627922058,
                -0.06288472563028336,
                -0.15023773908615112,
                -0.1054844781756401,
                -0.10127366334199905,
                -0.027269307523965836,
                0.04307914897799492,
                -0.12607388198375702,
                -0.11212440580129623,
                -0.06384523957967758,
                0.15650945901870728
            ],
            [
                -0.13455478847026825,
                -0.1391606330871582,
                -0.11241641640663147,
                -0.09518957883119583,
                -0.15538740158081055,
                -0.11673767119646072,
                -0.05099760740995407,
                -0.16886121034622192,
                -0.11050286889076233,
                -0.1664062887430191,
                -0.12112844735383987,
                -0.03440331667661667,
                -0.07824943959712982,
                -0.10456104576587677,
                0.00021591768017970026,
                -0.04137936234474182
            ],
            [
                -0.09622882306575775,
                -0.12219980359077454,
                -0.13790759444236755,
                -0.09149785339832306,
                -0.14518514275550842,
                -0.11196185648441315,
                -0.16774305701255798,
                -0.15604707598686218,
                -0.16490736603736877,
                -0.16751614212989807,
                -0.09279915690422058,
                -0.05799334868788719,
                -0.1314181536436081,
                -0.15814748406410217,
                -0.10256072878837585,
                -0.15865390002727509
            ],
            [
                -0.14720430970191956,
                -0.14468376338481903,
                -0.14961421489715576,
                -0.16971716284751892,
                -0.16933271288871765,
                -0.13514819741249084,
                -0.1237899512052536,
                0.040268056094646454,
                -0.151576966047287,
                -0.1173698827624321,
                -0.16943298280239105,
                -0.01761813275516033,
                0.01763337291777134,
                -0.08790573477745056,
                -0.09150872379541397,
                -0.12111019343137741
            ],
            [
                -0.09773655235767365,
                -0.16575369238853455,
                -0.10503730922937393,
                -0.10033751279115677,
                -0.16537611186504364,
                -0.14469081163406372,
                -0.15212570130825043,
                -0.09239773452281952,
                -0.1129990890622139,
                -0.10297125577926636,
                -0.16282625496387482,
                0.0009221553336828947,
                -0.0015537022845819592,
                -0.002830242272466421,
                -0.10550355166196823,
                -0.10985109955072403
            ],
            [
                -0.07557868212461472,
                -0.15427301824092865,
                -0.09932646155357361,
                -0.12828035652637482,
                -0.1700226068496704,
                -0.12865684926509857,
                -0.16466198861598969,
                -0.12995459139347076,
                -0.13629983365535736,
                -0.1203889474272728,
                -0.022751837968826294,
                -0.10182725638151169,
                -0.16281968355178833,
                0.03117852471768856,
                -0.1230766549706459,
                -0.14193494617938995
            ],
            [
                -0.1153980940580368,
                -0.10334254056215286,
                -0.12042297422885895,
                -0.11719828099012375,
                -0.12975135445594788,
                -0.16641341149806976,
                -0.1108480840921402,
                -0.10740646719932556,
                -0.1065303236246109,
                -0.146806538105011,
                -0.12002173066139221,
                -0.07576395571231842,
                -0.13481149077415466,
                -0.037456244230270386,
                -0.1548377275466919,
                -0.1483529806137085
            ],
            [
                -0.09693906456232071,
                -0.1114514172077179,
                0.013027513399720192,
                0.1439102441072464,
                -0.03970016539096832,
                -0.164788618683815,
                -0.03226226940751076,
                -0.08046058565378189,
                0.041919074952602386,
                -0.009962497279047966,
                -0.033917080610990524,
                -0.055562715977430344,
                -0.1255718469619751,
                -0.042800843715667725,
                -0.008882258087396622,
                -0.12423107028007507
            ]
        ],
        [
            [
                0,
                -1.98322055666722e-7,
                -9.93128878690186e-7,
                0,
                -0.000021831523554283194,
                0,
                -0.000011912519767065533,
                0,
                0,
                -0.000009421629329153802,
                -0.000016362910173484124,
                -0.000001942869630511268,
                -0.00018963648471981287,
                0,
                0,
                -1.86876050634055e-7
            ],
            [
                -0.04337146133184433,
                0,
                -0.00035453561577014625,
                -0.05431077256798744,
                0,
                -0.000007405496035062242,
                0,
                0,
                -0.0005089571350254118,
                -0.00016067054821178317,
                -0.00862053781747818,
                -0.000004315260866860626,
                -0.0004259705892764032,
                0,
                -0.004525656346231699,
                -0.0000011388469829398673
            ],
            [
                -0.00012739442172460258,
                -0.000001811445940802514,
                0,
                -0.001164123066700995,
                0,
                0,
                0,
                -0.010948078706860542,
                -0.000017464721167925745,
                0,
                -4.410042606650677e-7,
                -0.0000018042040892396471,
                0,
                0,
                -0.000004965564130543498,
                -2.9386760047600546e-7
            ],
            [
                0,
                -0.0000212283011933323,
                -0.00021663299412466586,
                -0.00003140665648970753,
                -0.000013409600796876475,
                -0.000003151121973132831,
                0,
                -0.006583753507584333,
                0,
                -0.0002675664145499468,
                -0.01303121168166399,
                0,
                -1.7202634694513108e-7,
                -1.542961740597093e-7,
                0,
                0
            ],
            [
                0,
                -2.1084738932586333e-7,
                -0.0004063945380039513,
                -0.10439375787973404,
                -0.0000015451538502020412,
                -0.00003893775647156872,
                0,
                0,
                0,
                -0.00024767598370090127,
                -0.0000012689364439211204,
                0,
                0,
                -0.001554341521114111,
                -4.331490117692738e-7,
                0
            ],
            [
                0,
                0,
                -1.519914292202884e-7,
                -0.048084456473588943,
                0,
                -0.000001269425865757512,
                -0.006360634230077267,
                -1.737587354000425e-7,
                -0.00002219244561274536,
                -0.00005263031562208198,
                0,
                0,
                -0.00002170599327655509,
                -0.000011793936209869571,
                -0.000013773671525996178,
                -4.4100954710302176e-7
            ],
            [
                -0.01260786596685648,
                -0.00004951696246280335,
                0,
                0,
                -0.000006644241238973336,
                0,
                -0.0000016801161564217182,
                0,
                -2.1602777167117893e-7,
                -1.7833286847235286e-7,
                0,
                -2.9487830488506006e-7,
                -9.97293113869091e-7,
                -0.000016128657080116682,
                0,
                0
            ],
            [
                0,
                -0.011608093976974487,
                -1.9621700175775914e-7,
                -0.000004201952378934948,
                -0.059231288731098175,
                -1.5794785213074647e-7,
                0,
                0,
                -2.927163222921081e-7,
                -0.000043828244088217616,
                -0.005480937659740448,
                -0.0000018108447648046422,
                -0.00014458605437539518,
                -0.001287100836634636,
                -0.0009159271139651537,
                -0.000017709638996166177
            ],
            [
                0.44840750098228455,
                0.7326207160949707,
                0.7475194931030273,
                0.7379708290100098,
                0.5435079336166382,
                0.595058023929596,
                0.16509553790092468,
                0.2791025936603546,
                0.5314152240753174,
                0.43128693103790283,
                0.44487065076828003,
                0.2709799110889435,
                0.3956509530544281,
                0.2670747935771942,
                0.31768009066581726,
                0.3496679365634918
            ],
            [
                0.6295547485351562,
                0.5687726736068726,
                0.673279345035553,
                0.7480951547622681,
                1.0027554035186768,
                0.7297137975692749,
                0.31418532133102417,
                0.5905660390853882,
                0.7137113213539124,
                0.6068234443664551,
                0.9330520629882812,
                0.6494907736778259,
                0.39908885955810547,
                0.5212811827659607,
                0.44108372926712036,
                0.36169716715812683
            ],
            [
                0.35221776366233826,
                1.061381220817566,
                0.7917723655700684,
                0.9206985235214233,
                0.7613939642906189,
                0.7711039781570435,
                0.6533293724060059,
                0.42386412620544434,
                0.3237224817276001,
                0.923362135887146,
                0.7728173136711121,
                0.49311643838882446,
                0.7664621472358704,
                0.43244755268096924,
                0.533570408821106,
                0.47654983401298523
            ],
            [
                0.45730575919151306,
                0.9987335801124573,
                0.6520864367485046,
                1.3741075992584229,
                1.2755316495895386,
                0.737291693687439,
                0.46283695101737976,
                0.5267618894577026,
                0.6346521973609924,
                0.8712349534034729,
                0.5014069080352783,
                0.789375901222229,
                0.4595796763896942,
                0.6955264210700989,
                0.5309238433837891,
                0.8359608054161072
            ],
            [
                0.3594125807285309,
                0.6293306946754456,
                0.8229339122772217,
                0.9752485752105713,
                0.8589786887168884,
                1.0219649076461792,
                0.40867605805397034,
                0.5797492265701294,
                0.5040174722671509,
                0.672698974609375,
                0.7806939482688904,
                0.4231703281402588,
                0.60511314868927,
                0.42287683486938477,
                0.889853298664093,
                1.077284812927246
            ],
            [
                0.651125967502594,
                0.6264225244522095,
                1.1793521642684937,
                0.7217363119125366,
                0.7533758282661438,
                0.3728947341442108,
                0.4889965057373047,
                0.5278076529502869,
                0.6959115266799927,
                0.5070560574531555,
                1.0439419746398926,
                1.4657458066940308,
                0.7897126078605652,
                1.074416995048523,
                0.535654604434967,
                0.809043288230896
            ],
            [
                0.6949660778045654,
                0.7336264848709106,
                0.9881124496459961,
                0.701046347618103,
                1.5358718633651733,
                0.8737645745277405,
                0.8401361703872681,
                0.717406690120697,
                0.6977434754371643,
                1.0893034934997559,
                1.220334529876709,
                1.3339749574661255,
                0.5350807309150696,
                0.998586118221283,
                0.9003131985664368,
                1.0307101011276245
            ],
            [
                0.23731379210948944,
                0.5334565043449402,
                0.6373471021652222,
                0.7796086668968201,
                0.7829599976539612,
                0.876278817653656,
                0.4583004117012024,
                0.4918937683105469,
                0.25287383794784546,
                0.41920867562294006,
                0.6210548281669617,
                0.5154721736907959,
                0.4431702494621277,
                0.5624651908874512,
                0.7231735587120056,
                1.125548243522644
            ]
        ],
        [
            [
                0.8908133506774902,
                1.9440553188323975,
                2.592865467071533,
                0.25239524245262146,
                1.6883392333984375,
                0.02191873826086521,
                -0.1369187831878662,
                0.21984247863292694,
                1.6272013187408447,
                0.33499863743782043,
                0.20594917237758636,
                0.2774961590766907,
                1.0004730224609375,
                0.2506570816040039,
                3.0438640117645264,
                1.284586787223816
            ],
            [
                -0.11001496762037277,
                0.26833152770996094,
                -0.13682670891284943,
                0.7666614651679993,
                0.3474416732788086,
                2.4121878147125244,
                0.40525102615356445,
                1.7391934394836426,
                1.684483528137207,
                -0.016064733266830444,
                -0.06548571586608887,
                -0.07908041775226593,
                -0.1597159206867218,
                1.3169211149215698,
                0.21412742137908936,
                -0.15584376454353333
            ],
            [
                -0.12186770141124725,
                1.231221318244934,
                0.9613167643547058,
                1.5602632761001587,
                0.6789257526397705,
                1.2125056982040405,
                1.3326605558395386,
                -0.026707498356699944,
                -0.08002324402332306,
                -0.13703829050064087,
                -0.004941374529153109,
                0.9561822414398193,
                1.9290670156478882,
                2.423567056655884,
                0.13810810446739197,
                3.884666919708252
            ],
            [
                2.866166114807129,
                0.46818169951438904,
                -0.018983079120516777,
                -0.13694976270198822,
                1.81751549243927,
                0.3898215889930725,
                0.1867857277393341,
                -0.05655120685696602,
                1.148709774017334,
                0.13435520231723785,
                -0.03446540981531143,
                -0.16749536991119385,
                -0.10031642764806747,
                4.793209075927734,
                4.019712924957275,
                0.6549502015113831
            ],
            [
                3.286245346069336,
                2.832336664199829,
                0.05504555627703667,
                -0.15959742665290833,
                -0.16177818179130554,
                -0.15802942216396332,
                0.5665135383605957,
                1.4955215454101562,
                1.4057475328445435,
                0.18637053668498993,
                -0.07690787315368652,
                2.8287994861602783,
                0.267809122800827,
                -0.16786064207553864,
                0.6191635727882385,
                -0.013743209652602673
            ],
            [
                0.2936193346977234,
                -0.16362912952899933,
                1.1306546926498413,
                -0.015337963588535786,
                -0.16333766281604767,
                0.10279319435358047,
                -0.16316509246826172,
                0.5950483083724976,
                -0.16958533227443695,
                -0.018877020105719566,
                1.1721054315567017,
                7.907305717468262,
                0.24799887835979462,
                -0.16639895737171173,
                -0.12157287448644638,
                -0.1205621287226677
            ],
            [
                -0.13755382597446442,
                -0.15407438576221466,
                0.46962621808052063,
                0.09684266895055771,
                -0.16926836967468262,
                2.0834829807281494,
                0.538407564163208,
                -0.1692364513874054,
                0.4674297869205475,
                2.4459831714630127,
                -0.11255685240030289,
                1.409936547279358,
                -0.10993838310241699,
                6.555150508880615,
                4.932380676269531,
                2.88782000541687
            ],
            [
                -0.09133189171552658,
                0.8427309989929199,
                2.809931993484497,
                2.4106881618499756,
                1.1184662580490112,
                0.6699906587600708,
                2.229600429534912,
                0.8864890336990356,
                2.543735980987549,
                -0.1264495849609375,
                -0.0681469663977623,
                -0.060573071241378784,
                -0.10742989182472229,
                5.540345191955566,
                2.5355029106140137,
                -0.12926821410655975
            ],
            [
                -0.11015675961971283,
                -0.1571035385131836,
                0.0681292936205864,
                -0.09989488869905472,
                -0.16823792457580566,
                -0.16444572806358337,
                -0.09146545827388763,
                -0.1464279592037201,
                -0.16962777078151703,
                -0.1459650993347168,
                -0.1544744223356247,
                -0.08824881911277771,
                -0.15747393667697906,
                -0.12958742678165436,
                -0.14273230731487274,
                -0.1674422025680542
            ],
            [
                -0.14844796061515808,
                -0.14710673689842224,
                -0.14075274765491486,
                -0.1624290496110916,
                -0.16995127499103546,
                -0.16790178418159485,
                -0.16994376480579376,
                -0.09489341080188751,
                -0.16174978017807007,
                -0.1661612093448639,
                -0.16906429827213287,
                -0.15984828770160675,
                -0.16925133764743805,
                -0.15352465212345123,
                -0.09976910799741745,
                -0.16988323628902435
            ],
            [
                -0.12333065271377563,
                -0.13973936438560486,
                -0.1692052185535431,
                -0.1545083075761795,
                -0.16763566434383392,
                -0.1602720022201538,
                -0.1578155905008316,
                -0.12565530836582184,
                -0.16244542598724365,
                -0.14174410700798035,
                -0.10684536397457123,
                -0.1652916520833969,
                -0.1663540154695511,
                -0.13336455821990967,
                -0.16724330186843872,
                -0.11282750964164734
            ],
            [
                -0.15042947232723236,
                -0.16778221726417542,
                -0.1687443107366562,
                -0.16887906193733215,
                -0.15098834037780762,
                -0.15699464082717896,
                -0.1418551802635193,
                -0.16784392297267914,
                -0.1378009170293808,
                -0.16440685093402863,
                -0.15229131281375885,
                -0.16380946338176727,
                -0.16997721791267395,
                -0.16944082081317902,
                -0.0639042779803276,
                -0.11787239462137222
            ],
            [
                -0.15777228772640228,
                -0.17000386118888855,
                -0.16530051827430725,
                -0.1606479436159134,
                -0.06061223894357681,
                -0.17002221941947937,
                -0.16486456990242004,
                -0.16954964399337769,
                -0.1694616973400116,
                -0.16273845732212067,
                -0.14660394191741943,
                -0.13707149028778076,
                -0.1568724364042282,
                -0.16380934417247772,
                -0.10704930126667023,
                -0.10504119098186493
            ],
            [
                -0.1340426653623581,
                -0.16726389527320862,
                -0.16945359110832214,
                -0.1638081818819046,
                -0.15254530310630798,
                -0.158861443400383,
                -0.17000620067119598,
                -0.16759996116161346,
                -0.1651405543088913,
                -0.1673220843076706,
                -0.16467899084091187,
                -0.12449854612350464,
                -0.1637374609708786,
                -0.16589348018169403,
                -0.16146861016750336,
                -0.17003990709781647
            ],
            [
                -0.13455814123153687,
                -0.16803695261478424,
                -0.16922429203987122,
                -0.15828950703144073,
                -0.06812439858913422,
                -0.16033700108528137,
                -0.16817475855350494,
                -0.16998210549354553,
                -0.16956447064876556,
                -0.1646527796983719,
                -0.16506990790367126,
                -0.16826212406158447,
                -0.15931260585784912,
                -0.16447457671165466,
                -0.16406294703483582,
                -0.16901430487632751
            ],
            [
                -0.15441617369651794,
                -0.1255808025598526,
                -0.14403308928012848,
                -0.16629618406295776,
                -0.10617243498563766,
                -0.15812459588050842,
                -0.1616978645324707,
                -0.16970190405845642,
                -0.16842950880527496,
                -0.1389259546995163,
                -0.16990335285663605,
                -0.14056408405303955,
                -0.16164471209049225,
                -0.10251106321811676,
                0.09032095223665237,
                -0.00621824013069272
            ]
        ],
        [
            [
                -0.1698400378227234,
                -0.06377818435430527,
                -0.16412942111492157,
                -0.11269737035036087,
                -0.020993640646338463,
                -0.1682049185037613,
                -0.11440109461545944,
                -0.05450216308236122,
                -0.14847469329833984,
                -0.1491611748933792,
                -0.16165338456630707,
                -0.15367542207241058,
                -0.11533722281455994,
                -0.11225877702236176,
                -0.1670849472284317,
                -0.16792631149291992
            ],
            [
                -0.1262795776128769,
                -0.17004002630710602,
                -0.0846857875585556,
                0.5414641499519348,
                1.0893573760986328,
                -0.05832187086343765,
                -0.06786172837018967,
                0.01706332340836525,
                -0.09844069182872772,
                -0.02504844032227993,
                -0.13854657113552094,
                -0.0972491130232811,
                -0.11854389309883118,
                -0.06956794857978821,
                0.36052247881889343,
                -0.1482641100883484
            ],
            [
                0.21895375847816467,
                -0.028524065390229225,
                -0.16190551221370697,
                -0.03709154576063156,
                -0.10222207009792328,
                -0.1568150520324707,
                -0.16987170279026031,
                1.2032192945480347,
                -0.04155442491173744,
                -0.03654724359512329,
                -0.15929965674877167,
                0.23482316732406616,
                -0.017486443743109703,
                -0.08276569098234177,
                -0.1421545296907425,
                -0.0552019365131855
            ],
            [
                0.10770265012979507,
                -0.15653203427791595,
                -0.07989713549613953,
                -0.006305635441094637,
                -0.006121787242591381,
                -0.047103721648454666,
                -0.16157332062721252,
                0.6273800730705261,
                -0.10458464175462723,
                -0.16771000623703003,
                -0.16525353491306305,
                -0.11467921733856201,
                -0.14750708639621735,
                -0.1505574882030487,
                0.13741715252399445,
                -0.06813377141952515
            ],
            [
                0.38028663396835327,
                -0.09747955203056335,
                -0.16680268943309784,
                -0.16849744319915771,
                -0.023391064256429672,
                -0.1221553385257721,
                -0.12069206684827805,
                -0.12366826087236404,
                -0.038225091993808746,
                -0.05933103710412979,
                -0.0765673890709877,
                -0.07836942374706268,
                -0.15854600071907043,
                -0.09564942866563797,
                -0.14350789785385132,
                -0.10610116273164749
            ],
            [
                0.04557288438081741,
                -0.12415800243616104,
                -0.012388644739985466,
                -0.15461061894893646,
                -0.1184854656457901,
                -0.08795391768217087,
                0.2935446500778198,
                -0.06181809678673744,
                -0.01305477600544691,
                -0.013020407408475876,
                -0.1664569228887558,
                0.639853298664093,
                -0.1695970892906189,
                -0.1255335807800293,
                -0.17001716792583466,
                -0.1376713365316391
            ],
            [
                -0.057271718978881836,
                -0.16424213349819183,
                -0.008577491156756878,
                -0.09642444550991058,
                -0.0957607701420784,
                -0.10828731209039688,
                -0.09307654947042465,
                -0.14266155660152435,
                -0.08666722476482391,
                0.4536365270614624,
                -0.16894422471523285,
                0.11887342482805252,
                -0.16990604996681213,
                -0.11313784867525101,
                0.519556999206543,
                0.5545108914375305
            ],
            [
                0.22371670603752136,
                -0.16982099413871765,
                0.16477280855178833,
                3.419807195663452,
                0.7131052613258362,
                -0.12069416791200638,
                -0.09322315454483032,
                -0.11762773245573044,
                0.20606057345867157,
                -0.070485919713974,
                -0.12935473024845123,
                -0.1227497085928917,
                -0.16901680827140808,
                3.8884263038635254,
                1.1625903844833374,
                -0.1700368970632553
            ],
            [
                0.8083817362785339,
                0.7160192728042603,
                0.3889305591583252,
                0.5846401453018188,
                0.2213289439678192,
                0.057441070675849915,
                -0.023083671927452087,
                0.2836991250514984,
                0.7781580686569214,
                0.04514245688915253,
                0.26966607570648193,
                -0.09510474652051926,
                0.4693504273891449,
                0.1583951860666275,
                0.3835068643093109,
                -0.1576618105173111
            ],
            [
                0.2876514494419098,
                0.2924419045448303,
                0.22127684950828552,
                0.204098179936409,
                0.42525947093963623,
                0.014008594676852226,
                -0.16248810291290283,
                0.2053140252828598,
                0.3765406310558319,
                -0.02328488603234291,
                0.517129123210907,
                -0.09072691947221756,
                -0.09965819865465164,
                0.09952620416879654,
                0.25446489453315735,
                -0.06658975780010223
            ],
            [
                0.21296530961990356,
                0.399057537317276,
                -0.0398067943751812,
                0.21975193917751312,
                0.5588896870613098,
                0.12923701107501984,
                0.12151406705379486,
                0.3487780690193176,
                -0.038189906626939774,
                0.05817348510026932,
                0.17564120888710022,
                0.15310364961624146,
                0.05613946169614792,
                0.028995854780077934,
                -0.08313922584056854,
                0.36107781529426575
            ],
            [
                0.19112655520439148,
                0.26314762234687805,
                0.047620274126529694,
                0.5620580315589905,
                0.5428063869476318,
                0.31909963488578796,
                0.2938911020755768,
                0.09690968692302704,
                0.017537465319037437,
                -0.0479951947927475,
                0.11929376423358917,
                0.3421615660190582,
                -0.09228182584047318,
                -0.014487180858850479,
                0.15922652184963226,
                0.27183446288108826
            ],
            [
                0.11564797908067703,
                0.016044676303863525,
                0.2764206826686859,
                0.7087144255638123,
                0.6622083187103271,
                -0.09241268783807755,
                0.009892355650663376,
                0.2494942843914032,
                0.2470981478691101,
                -0.08499830961227417,
                -0.009714807383716106,
                0.6496822237968445,
                -0.10222546011209488,
                -0.10364725440740585,
                1.0100374221801758,
                0.4496577978134155
            ],
            [
                0.28168150782585144,
                0.5342420339584351,
                0.7274723649024963,
                -0.008874689228832722,
                0.3737618625164032,
                -0.014524314552545547,
                0.08473509550094604,
                0.14286138117313385,
                0.04975499212741852,
                0.2333594560623169,
                0.14543190598487854,
                0.6217703223228455,
                0.14932085573673248,
                0.13947847485542297,
                0.17278248071670532,
                0.409592866897583
            ],
            [
                0.5359125733375549,
                0.18502399325370789,
                0.38815101981163025,
                0.340410053730011,
                0.37057188153266907,
                0.17392151057720184,
                0.20907287299633026,
                0.03095516934990883,
                0.2968420684337616,
                0.2142896205186844,
                0.7660541534423828,
                0.20439179241657257,
                0.13113126158714294,
                0.1389273852109909,
                -0.033277660608291626,
                0.43734294176101685
            ],
            [
                0.09512770175933838,
                0.15400178730487823,
                0.14741219580173492,
                0.1907644271850586,
                0.5640493035316467,
                0.4381832182407379,
                -0.03784812241792679,
                0.07633840292692184,
                -0.03992394730448723,
                0.12191807478666306,
                -0.0007781683234497905,
                -0.04011163488030434,
                0.14948037266731262,
                0.29821187257766724,
                0.5069485306739807,
                0.11365917325019836
            ]
        ],
        [
            [
                3.433687925338745,
                3.1787164211273193,
                4.247920989990234,
                3.7318902015686035,
                4.415832042694092,
                4.381402969360352,
                4.860782623291016,
                3.800612449645996,
                4.151246070861816,
                5.145880222320557,
                5.471372604370117,
                5.296479225158691,
                4.075119972229004,
                5.920121669769287,
                6.265059471130371,
                3.997894763946533
            ],
            [
                3.7825138568878174,
                5.055706024169922,
                4.835463047027588,
                5.5319318771362305,
                4.482615947723389,
                6.2033371925354,
                6.453327178955078,
                5.368650436401367,
                3.730552911758423,
                3.3197362422943115,
                3.964533805847168,
                4.346202373504639,
                4.204204082489014,
                5.290098667144775,
                3.8906853199005127,
                7.096286773681641
            ],
            [
                3.667470932006836,
                5.0092082023620605,
                4.920717239379883,
                4.298320293426514,
                4.059459209442139,
                4.443507194519043,
                3.9661648273468018,
                1.768164038658142,
                4.3533430099487305,
                4.774932861328125,
                4.707263469696045,
                5.4976959228515625,
                6.182385444641113,
                5.550964832305908,
                4.998055458068848,
                7.033472537994385
            ],
            [
                4.4395928382873535,
                5.049917221069336,
                3.8387789726257324,
                3.8574914932250977,
                3.2811930179595947,
                5.111434459686279,
                4.470602512359619,
                3.3163678646087646,
                4.944973945617676,
                4.9739203453063965,
                3.994102954864502,
                4.424211025238037,
                5.601195335388184,
                5.681327819824219,
                4.920185089111328,
                5.237438201904297
            ],
            [
                4.759433746337891,
                4.959343433380127,
                5.020877838134766,
                3.9132070541381836,
                4.708025932312012,
                5.0577592849731445,
                5.744332790374756,
                4.472991943359375,
                4.080092906951904,
                3.552189588546753,
                4.024092674255371,
                5.470983505249023,
                4.129222393035889,
                5.133607864379883,
                4.201239109039307,
                4.792976379394531
            ],
            [
                5.779305458068848,
                4.872632026672363,
                5.241086483001709,
                2.9092013835906982,
                4.576979160308838,
                4.085453987121582,
                4.604264259338379,
                4.4869232177734375,
                3.5029282569885254,
                3.703418016433716,
                5.625614643096924,
                9.048553466796875,
                5.344778060913086,
                2.8155126571655273,
                6.271846771240234,
                3.542679786682129
            ],
            [
                3.338944911956787,
                3.507589101791382,
                4.37289571762085,
                4.296602249145508,
                4.932486534118652,
                5.658511161804199,
                4.304304599761963,
                3.59062123298645,
                3.104469060897827,
                4.789764881134033,
                5.952086925506592,
                5.364157676696777,
                4.3442158699035645,
                7.637762069702148,
                7.39720344543457,
                4.985817909240723
            ],
            [
                3.4202020168304443,
                5.310458183288574,
                5.636885643005371,
                7.302513599395752,
                4.212070465087891,
                4.101064205169678,
                5.366686820983887,
                5.903400421142578,
                4.063880443572998,
                4.929584503173828,
                3.0465199947357178,
                3.569199800491333,
                4.9513959884643555,
                7.390252113342285,
                6.451932907104492,
                2.977276563644409
            ],
            [
                0.2941061854362488,
                0.8830531239509583,
                0.856746256351471,
                0.928000807762146,
                1.309139370918274,
                0.8212501406669617,
                0.7022165656089783,
                0.6146075129508972,
                0.8342763781547546,
                0.8450096845626831,
                0.4518119692802429,
                1.2216962575912476,
                0.5954829454421997,
                0.9710243344306946,
                0.47990405559539795,
                0.42064645886421204
            ],
            [
                0.8223459720611572,
                0.9807984828948975,
                0.963598370552063,
                0.70186448097229,
                0.787857711315155,
                0.3146432638168335,
                1.0596920251846313,
                0.812999427318573,
                0.577238917350769,
                1.1572340726852417,
                0.30417874455451965,
                0.6623008847236633,
                0.48706063628196716,
                1.3649322986602783,
                1.1048502922058105,
                1.0509127378463745
            ],
            [
                0.7566512823104858,
                0.7238134145736694,
                0.3238431215286255,
                0.9458011984825134,
                0.941700279712677,
                1.2303589582443237,
                1.2367913722991943,
                1.2829618453979492,
                0.8506229519844055,
                1.155647873878479,
                0.7750880122184753,
                0.34830084443092346,
                0.5995256304740906,
                0.8668276071548462,
                1.2396337985992432,
                1.1505210399627686
            ],
            [
                0.9053884744644165,
                0.5850502252578735,
                0.6372993588447571,
                0.4520476758480072,
                0.6802219748497009,
                1.238472819328308,
                0.7711148262023926,
                0.7366893291473389,
                0.11333867907524109,
                0.8612914681434631,
                0.9392141699790955,
                0.27813276648521423,
                0.5903169512748718,
                0.5612820982933044,
                1.5489038228988647,
                1.0707799196243286
            ],
            [
                0.7559437155723572,
                1.0955966711044312,
                0.5705768465995789,
                0.9517637491226196,
                0.6079269647598267,
                0.5592824220657349,
                0.8829845190048218,
                0.7097095251083374,
                0.862968921661377,
                0.6797764301300049,
                0.9657942652702332,
                0.14697907865047455,
                0.3720204830169678,
                0.5963693857192993,
                0.8766425251960754,
                0.7585696578025818
            ],
            [
                0.7723254561424255,
                1.3432847261428833,
                0.6889081001281738,
                0.6807522177696228,
                1.706720232963562,
                0.7791517376899719,
                0.594845175743103,
                0.5495471358299255,
                0.6477724313735962,
                0.28301936388015747,
                0.5241315960884094,
                0.028584880754351616,
                0.47343316674232483,
                0.613577663898468,
                0.6845948696136475,
                0.856880784034729
            ],
            [
                0.7984946370124817,
                1.326977252960205,
                0.7975325584411621,
                0.5634471774101257,
                1.3226438760757446,
                0.6614474058151245,
                0.49937960505485535,
                0.7578791975975037,
                0.6408723592758179,
                0.352514386177063,
                -0.04591825231909752,
                0.25545260310173035,
                1.0361226797103882,
                0.7954269051551819,
                0.45623451471328735,
                0.7083081007003784
            ],
            [
                0.8230035901069641,
                0.7686430215835571,
                1.0171819925308228,
                0.5171146392822266,
                0.6129664182662964,
                0.5698994398117065,
                0.8136509656906128,
                0.805461049079895,
                0.9262251853942871,
                0.6728208065032959,
                0.2523818016052246,
                0.9759032130241394,
                1.0736335515975952,
                0.6647362112998962,
                0.224529430270195,
                0.9317666292190552
            ]
        ],
        [
            [
                1.3321857452392578,
                0.9840219616889954,
                2.143019199371338,
                2.701470375061035,
                2.1330454349517822,
                2.3036670684814453,
                2.9129478931427,
                2.327150821685791,
                1.8797779083251953,
                2.157399892807007,
                1.648207664489746,
                3.1615190505981445,
                2.509615659713745,
                2.5119025707244873,
                2.856182813644409,
                0.34923914074897766
            ],
            [
                2.3968734741210938,
                2.4143900871276855,
                1.6986690759658813,
                1.755441427230835,
                2.421973705291748,
                2.109602928161621,
                2.370865821838379,
                2.5024917125701904,
                2.770714282989502,
                2.43266224861145,
                2.3727474212646484,
                2.154534339904785,
                2.289288282394409,
                2.504286766052246,
                3.0381886959075928,
                1.3799725770950317
            ],
            [
                1.9984307289123535,
                1.7903523445129395,
                2.9642860889434814,
                3.2389636039733887,
                2.9538233280181885,
                2.996107339859009,
                2.4322140216827393,
                2.0824592113494873,
                2.936414957046509,
                2.1562864780426025,
                1.467921495437622,
                2.430175542831421,
                1.95658540725708,
                4.267171859741211,
                2.354095220565796,
                1.7154608964920044
            ],
            [
                0.26562318205833435,
                2.9680538177490234,
                3.1142098903656006,
                3.6052730083465576,
                3.4310786724090576,
                3.6327672004699707,
                3.7135493755340576,
                2.211195468902588,
                2.1136043071746826,
                2.3163084983825684,
                2.872166156768799,
                2.5828258991241455,
                2.435173273086548,
                2.3300509452819824,
                1.7886226177215576,
                1.7443102598190308
            ],
            [
                0.6386498212814331,
                3.481111526489258,
                2.162144184112549,
                1.78769052028656,
                2.0230536460876465,
                3.0928077697753906,
                1.2764651775360107,
                2.0363242626190186,
                1.0394768714904785,
                2.2090208530426025,
                2.301508665084839,
                3.048734426498413,
                3.142505645751953,
                2.966860771179199,
                2.3951587677001953,
                0.8166891932487488
            ],
            [
                2.227698564529419,
                2.45622181892395,
                2.683494806289673,
                2.075920820236206,
                1.618224859237671,
                2.5112006664276123,
                2.474484920501709,
                1.848949909210205,
                3.1572508811950684,
                2.914538860321045,
                3.332725763320923,
                3.1123411655426025,
                2.0355842113494873,
                2.5675199031829834,
                2.6706690788269043,
                1.3576831817626953
            ],
            [
                2.210020065307617,
                3.2958457469940186,
                2.8012664318084717,
                2.4853451251983643,
                2.1011059284210205,
                1.879914402961731,
                1.693137764930725,
                1.9802632331848145,
                2.494797945022583,
                1.7772985696792603,
                3.331084728240967,
                2.4951319694519043,
                2.78830623626709,
                4.775700092315674,
                1.9668668508529663,
                2.1221389770507812
            ],
            [
                1.2367557287216187,
                2.3113861083984375,
                2.635582447052002,
                2.102710723876953,
                0.3191685676574707,
                1.425899624824524,
                1.6559221744537354,
                1.6060214042663574,
                2.234848976135254,
                1.3698267936706543,
                1.8387666940689087,
                2.580601930618286,
                2.3948750495910645,
                2.176273822784424,
                2.129763126373291,
                0.6919729113578796
            ],
            [
                -0.13845162093639374,
                0.29030537605285645,
                -0.05495031550526619,
                0.14771074056625366,
                -0.003385854884982109,
                -0.0881853848695755,
                -0.10676287859678268,
                0.13181999325752258,
                0.20926305651664734,
                0.18356934189796448,
                -0.09719058871269226,
                -0.09722413867712021,
                -0.03492603823542595,
                -0.03182939440011978,
                0.02668444998562336,
                0.04412144422531128
            ],
            [
                -0.06661885976791382,
                0.199380561709404,
                0.019975939765572548,
                -0.1401369720697403,
                0.05968721583485603,
                -0.06469020992517471,
                -0.0975675955414772,
                -0.045690733939409256,
                -0.07869739085435867,
                -0.06447620689868927,
                -0.13000917434692383,
                -0.13572798669338226,
                -0.15842579305171967,
                -0.1363442838191986,
                -0.11919990926980972,
                -0.08403743803501129
            ],
            [
                -0.16343073546886444,
                -0.011763719841837883,
                0.03138068690896034,
                0.12284470349550247,
                -0.016902554780244827,
                -0.046566568315029144,
                0.052135057747364044,
                -0.0749521553516388,
                -0.10327212512493134,
                -0.06243667006492615,
                -0.09290851652622223,
                -0.13652636110782623,
                -0.1143079325556755,
                0.009285969659686089,
                -0.16792616248130798,
                -0.07535155862569809
            ],
            [
                -0.15018892288208008,
                -0.05527263879776001,
                -0.005577299743890762,
                0.1745455116033554,
                0.2051667422056198,
                -0.05387689918279648,
                -0.11502661556005478,
                -0.11345471441745758,
                -0.013098481111228466,
                -0.05380363017320633,
                0.10970629006624222,
                -0.06637226790189743,
                -0.04607278108596802,
                -0.08080921322107315,
                0.0253064576536417,
                0.31900128722190857
            ],
            [
                -0.14676274359226227,
                -0.10761356353759766,
                -0.0951511412858963,
                0.1032862663269043,
                0.1974925845861435,
                -0.12777626514434814,
                -0.15076269209384918,
                -0.06099851056933403,
                -0.027650082483887672,
                -0.12550772726535797,
                -0.04674883559346199,
                -0.14084871113300323,
                -0.060903266072273254,
                -0.0015310753369703889,
                0.022690143436193466,
                0.29367074370384216
            ],
            [
                -0.15432493388652802,
                -0.053205106407403946,
                0.31201863288879395,
                -0.06016644090414047,
                -0.004766953177750111,
                -0.040073212236166,
                -0.14619767665863037,
                -0.07776734977960587,
                -0.08516047149896622,
                -0.11275117099285126,
                0.235642209649086,
                0.22470775246620178,
                -0.03607763722538948,
                -0.08078154176473618,
                -0.055418435484170914,
                -0.03712574020028114
            ],
            [
                -0.06866715103387833,
                -0.0397312305867672,
                0.19734033942222595,
                0.05283954739570618,
                -0.021800905466079712,
                -0.019857924431562424,
                -0.1126602366566658,
                -0.11430340260267258,
                -0.1233770102262497,
                -0.056089188903570175,
                0.36758115887641907,
                0.06713869422674179,
                -0.06762295216321945,
                -0.05197582393884659,
                0.10022544860839844,
                0.11067134886980057
            ],
            [
                -0.16065619885921478,
                -0.16785544157028198,
                -0.158333882689476,
                -0.10332874208688736,
                -0.12599313259124756,
                -0.039976149797439575,
                -0.13094909489154816,
                -0.16710643470287323,
                -0.15719878673553467,
                -0.16853711009025574,
                -0.1276090294122696,
                -0.15522441267967224,
                -0.16797876358032227,
                -0.16230061650276184,
                -0.14704543352127075,
                -0.011940279975533485
            ]
        ],
        [
            [
                3.9250621795654297,
                4.652328014373779,
                5.458840847015381,
                4.802887916564941,
                4.682369709014893,
                4.357059001922607,
                3.244462490081787,
                4.438325881958008,
                4.716688632965088,
                3.076519012451172,
                3.8628203868865967,
                3.09420108795166,
                3.309800624847412,
                3.5979111194610596,
                2.6886305809020996,
                4.780313968658447
            ],
            [
                3.860318183898926,
                3.93888521194458,
                4.318724632263184,
                2.9518959522247314,
                4.192653179168701,
                3.2115979194641113,
                4.424081802368164,
                4.332186222076416,
                3.7492434978485107,
                4.198994159698486,
                3.9555022716522217,
                2.8750622272491455,
                4.671636581420898,
                3.10233998298645,
                4.109973907470703,
                5.044114112854004
            ],
            [
                3.052140951156616,
                2.9094865322113037,
                3.4113211631774902,
                2.8411741256713867,
                4.913296699523926,
                3.905031204223633,
                3.646350145339966,
                3.9852449893951416,
                5.079004287719727,
                3.813917398452759,
                4.034400939941406,
                3.8396942615509033,
                4.573753833770752,
                3.7897305488586426,
                6.407598972320557,
                4.297003269195557
            ],
            [
                2.6314985752105713,
                3.429384469985962,
                3.00885009765625,
                4.511394500732422,
                3.4024858474731445,
                5.431271076202393,
                3.20715069770813,
                3.1724648475646973,
                4.399868965148926,
                3.819056987762451,
                4.14478063583374,
                4.121280193328857,
                3.9151201248168945,
                3.7594456672668457,
                3.440127372741699,
                4.141560077667236
            ],
            [
                1.6682568788528442,
                3.5833890438079834,
                2.497648000717163,
                4.037752628326416,
                4.535304069519043,
                3.735504150390625,
                3.270082712173462,
                2.34187650680542,
                4.548354625701904,
                4.341650485992432,
                4.855829238891602,
                3.5681300163269043,
                4.300142288208008,
                3.09051513671875,
                2.488713264465332,
                3.6962509155273438
            ],
            [
                2.4935100078582764,
                4.428860187530518,
                3.354692220687866,
                3.7491953372955322,
                4.009941577911377,
                3.408517599105835,
                4.417078971862793,
                2.2504687309265137,
                4.009783744812012,
                4.413485527038574,
                3.790473699569702,
                4.567445278167725,
                4.046402931213379,
                5.726138591766357,
                4.125088691711426,
                3.703233480453491
            ],
            [
                4.061557769775391,
                3.889425039291382,
                2.9653921127319336,
                4.83701753616333,
                3.306352138519287,
                3.840717315673828,
                3.5640339851379395,
                3.6221773624420166,
                3.2189087867736816,
                4.092629432678223,
                3.710988759994507,
                4.762836456298828,
                4.5981855392456055,
                3.93818998336792,
                5.544960975646973,
                3.045013904571533
            ],
            [
                3.1827878952026367,
                2.8528084754943848,
                1.8950797319412231,
                2.438995838165283,
                3.665105104446411,
                2.9318273067474365,
                2.2715744972229004,
                2.67192006111145,
                3.822002410888672,
                4.569474697113037,
                2.9890339374542236,
                3.417381525039673,
                3.1069653034210205,
                4.835265159606934,
                6.391515731811523,
                4.419490814208984
            ],
            [
                0.4803353548049927,
                0.9935140609741211,
                0.9151110053062439,
                0.6772807836532593,
                0.9876068234443665,
                0.3094295561313629,
                0.37363484501838684,
                0.6112989783287048,
                0.958954393863678,
                0.2604730427265167,
                0.2688799798488617,
                0.09361086040735245,
                0.8728805184364319,
                1.8363850116729736,
                1.3973004817962646,
                0.4475913941860199
            ],
            [
                -0.016000231727957726,
                0.24541382491588593,
                0.5221454501152039,
                0.4005228281021118,
                0.4003869593143463,
                0.6569535732269287,
                0.29142501950263977,
                0.012791079469025135,
                0.4424799084663391,
                -0.04950675740838051,
                0.4550175964832306,
                0.5206130743026733,
                -0.015215182676911354,
                0.004296710714697838,
                0.1215212345123291,
                -0.01046859472990036
            ],
            [
                0.31742891669273376,
                0.772120475769043,
                0.3014647662639618,
                0.5718958377838135,
                0.6808582544326782,
                0.06849437952041626,
                -0.02146761864423752,
                -0.061613764613866806,
                -0.029537934809923172,
                -0.012317566201090813,
                0.19068530201911926,
                0.07311876118183136,
                0.07303962111473083,
                -0.036733098328113556,
                0.004574126563966274,
                0.04888131096959114
            ],
            [
                0.012838938273489475,
                0.08908680826425552,
                0.7324332594871521,
                0.9707287549972534,
                0.8136759996414185,
                0.2816825211048126,
                0.20621640980243683,
                0.4683830142021179,
                0.2989881932735443,
                0.13025058805942535,
                0.199590265750885,
                0.32550665736198425,
                0.3564481735229492,
                0.08634095638990402,
                0.1903148889541626,
                0.16182829439640045
            ],
            [
                -0.11685500293970108,
                -0.01044692937284708,
                0.25629907846450806,
                0.5123063325881958,
                0.7633999586105347,
                0.40945783257484436,
                0.17989951372146606,
                0.0872243270277977,
                0.10767845064401627,
                0.2607993185520172,
                0.3455231785774231,
                0.6460527181625366,
                0.3468261659145355,
                0.33280235528945923,
                0.16183465719223022,
                0.2722865343093872
            ],
            [
                0.18378804624080658,
                0.582021951675415,
                0.8656277656555176,
                0.13244156539440155,
                0.040366534143686295,
                -0.04077354446053505,
                -0.08670052886009216,
                0.04773025959730148,
                0.18354620039463043,
                0.5395402312278748,
                0.7804850339889526,
                1.3272019624710083,
                0.3134519159793854,
                0.9946262240409851,
                0.3619808852672577,
                0.40053480863571167
            ],
            [
                0.05407797545194626,
                0.08614505082368851,
                0.33962148427963257,
                0.5270679593086243,
                0.9651069045066833,
                0.7086629867553711,
                -0.020649604499340057,
                -0.05746624991297722,
                0.10192518681287766,
                0.3694632649421692,
                0.6140249967575073,
                0.6443865895271301,
                0.23991738259792328,
                0.2584068477153778,
                0.7076773643493652,
                0.8291521072387695
            ],
            [
                -0.07841863483190536,
                -0.0016473508439958096,
                0.16079571843147278,
                0.44274166226387024,
                0.2974849343299866,
                0.08867061138153076,
                0.05348682031035423,
                -0.07744304835796356,
                -0.0050577884539961815,
                0.14948640763759613,
                0.09015451371669769,
                0.052241239696741104,
                0.01814214326441288,
                0.45022720098495483,
                0.44441497325897217,
                0.5165934562683105
            ]
        ],
        [
            [
                8.107122421264648,
                10.358182907104492,
                8.17474365234375,
                null,
                8.616456985473633,
                10.556929588317871,
                12.063660621643066,
                7.816887855529785,
                11.494028091430664,
                12.039353370666504,
                6.852487564086914,
                11.650449752807617,
                9.448841094970703,
                11.174674034118652,
                11.1387300491333,
                null
            ],
            [
                7.677529811859131,
                null,
                8.182796478271484,
                6.326923847198486,
                10.137521743774414,
                null,
                9.219922065734863,
                null,
                12.349652290344238,
                6.972719669342041,
                6.501957893371582,
                7.452539920806885,
                9.255697250366211,
                8.97830581665039,
                null,
                null
            ],
            [
                8.703385353088379,
                null,
                10.570331573486328,
                5.780577659606934,
                11.887557029724121,
                null,
                null,
                7.9693498611450195,
                12.51323127746582,
                7.757757663726807,
                5.929746150970459,
                11.31954288482666,
                6.49077844619751,
                null,
                null,
                null
            ],
            [
                8.793374061584473,
                9.531375885009766,
                6.2969651222229,
                8.423595428466797,
                12.569822311401367,
                null,
                null,
                9.458179473876953,
                null,
                4.020537853240967,
                6.610664367675781,
                8.566593170166016,
                10.425447463989258,
                null,
                null,
                9.770891189575195
            ],
            [
                12.187358856201172,
                null,
                8.729423522949219,
                4.588107109069824,
                10.063297271728516,
                12.709299087524414,
                null,
                6.498178482055664,
                11.558731079101562,
                11.036340713500977,
                8.287185668945312,
                8.9752779006958,
                7.642543792724609,
                7.38648796081543,
                9.688041687011719,
                7.9884033203125
            ],
            [
                9.191141128540039,
                10.555828094482422,
                11.8981294631958,
                7.262306213378906,
                9.119812965393066,
                8.386418342590332,
                9.153851509094238,
                8.31960678100586,
                8.32256031036377,
                7.637649059295654,
                null,
                null,
                null,
                11.386805534362793,
                null,
                11.43397045135498
            ],
            [
                6.783770561218262,
                12.114725112915039,
                8.698633193969727,
                11.16899299621582,
                7.908571243286133,
                7.638611793518066,
                11.002116203308105,
                6.450198173522949,
                12.306612968444824,
                null,
                12.793749809265137,
                10.11190414428711,
                null,
                null,
                null,
                12.896125793457031
            ],
            [
                7.504804611206055,
                12.908975601196289,
                null,
                null,
                4.4551544189453125,
                7.217522144317627,
                12.327383995056152,
                7.810798645019531,
                null,
                12.47308349609375,
                9.632122993469238,
                7.3044586181640625,
                10.980624198913574,
                null,
                null,
                8.538606643676758
            ],
            [
                0.24336226284503937,
                0.5044501423835754,
                0.47595489025115967,
                0.5419831871986389,
                0.4144601821899414,
                0.3593232035636902,
                0.12311484664678574,
                0.3058483302593231,
                0.2317838966846466,
                0.7546958327293396,
                0.3700924515724182,
                0.08725962042808533,
                0.6440058946609497,
                0.7627449631690979,
                0.8312023878097534,
                0.1862533688545227
            ],
            [
                0.7376954555511475,
                0.5095945000648499,
                0.8799835443496704,
                0.4307098984718323,
                0.5288696885108948,
                0.6273506283760071,
                0.12434280663728714,
                0.6560069918632507,
                0.3516439199447632,
                0.1968456655740738,
                0.8824682831764221,
                0.1082841232419014,
                0.46556082367897034,
                0.632622241973877,
                0.14124293625354767,
                0.16428060829639435
            ],
            [
                0.3010118901729584,
                0.5711733102798462,
                0.2595064640045166,
                0.890329122543335,
                0.6486495137214661,
                0.5858023762702942,
                0.17901821434497833,
                0.7277530431747437,
                0.7501029968261719,
                0.8024861216545105,
                0.2625943422317505,
                0.38291069865226746,
                0.16316859424114227,
                0.581035315990448,
                0.3441944420337677,
                0.36826908588409424
            ],
            [
                0.6074764132499695,
                1.0200406312942505,
                0.06742338091135025,
                0.7367634773254395,
                0.9783973693847656,
                0.6847960352897644,
                0.5381759405136108,
                0.3687756657600403,
                0.2571341097354889,
                0.409994512796402,
                0.39699283242225647,
                0.6585973501205444,
                0.19340813159942627,
                0.3264906108379364,
                0.37590691447257996,
                0.3168565630912781
            ],
            [
                0.6229948401451111,
                0.3356685936450958,
                0.4019644260406494,
                0.3010108470916748,
                0.9306864142417908,
                0.4347502291202545,
                0.5496248602867126,
                0.5701656341552734,
                0.6150583028793335,
                0.5254723429679871,
                0.5630190372467041,
                0.8592899441719055,
                0.18300361931324005,
                0.11892441660165787,
                0.3134196102619171,
                0.18787404894828796
            ],
            [
                0.2555602490901947,
                0.420695960521698,
                0.5381332635879517,
                0.3230515420436859,
                0.841395378112793,
                0.35869863629341125,
                0.9563616514205933,
                0.3181231617927551,
                0.3711043894290924,
                0.4160568118095398,
                0.007882633246481419,
                0.06314876675605774,
                0.39599287509918213,
                0.14017949998378754,
                0.25386443734169006,
                1.1090623140335083
            ],
            [
                0.5477128624916077,
                1.001067876815796,
                0.6671841740608215,
                0.021876772865653038,
                0.3501671850681305,
                0.34954458475112915,
                0.30204522609710693,
                0.609928548336029,
                0.5719515085220337,
                0.23255667090415955,
                0.5090848207473755,
                0.43902698159217834,
                0.24988003075122833,
                0.2605866491794586,
                0.04287874698638916,
                0.5624037981033325
            ],
            [
                0.5696304440498352,
                0.5446619391441345,
                0.7850823402404785,
                0.053736843168735504,
                0.4553728997707367,
                0.646933913230896,
                0.254593163728714,
                0.18406915664672852,
                0.17783701419830322,
                0.40583324432373047,
                0.1330769658088684,
                0.6928472518920898,
                0.2921169698238373,
                0.2868530750274658,
                0.21177975833415985,
                0.25200164318084717
            ]
        ],
        [
            [
                -0.007905405946075916,
                -0.006162906531244516,
                -0.03166016936302185,
                -0.0277250986546278,
                -0.020751871168613434,
                -0.01208596769720316,
                -0.00289744115434587,
                -0.03149230778217316,
                -0.04668018966913223,
                -0.03162117674946785,
                -0.023943422362208366,
                -0.058272723108530045,
                -0.043277084827423096,
                -0.0019497312605381012,
                -0.1689075082540512,
                0
            ],
            [
                0.9428391456604004,
                -0.13746725022792816,
                -0.0057588298805058,
                -0.0522286482155323,
                -0.0385134220123291,
                0.038414426147937775,
                -0.07881759107112885,
                -0.10003776103258133,
                -0.11636166274547577,
                -0.046401843428611755,
                -0.003841069992631674,
                -0.15555137395858765,
                -0.17001768946647644,
                -0.14492906630039215,
                -0.006374265532940626,
                0
            ],
            [
                -0.12636004388332367,
                -0.07336872816085815,
                0.2048056274652481,
                1.3057377338409424,
                -0.04129521921277046,
                0.4497755467891693,
                0.0007464367663487792,
                -0.01469533983618021,
                0.17288607358932495,
                -0.16113652288913727,
                -0.0014575882814824581,
                0.5058318972587585,
                -0.07936416566371918,
                -0.07829700410366058,
                -0.00005008879816159606,
                0.6636121869087219
            ],
            [
                -0.009757517836987972,
                0.5191861987113953,
                -0.10676403343677521,
                1.3206112384796143,
                0.8512082695960999,
                1.2737226486206055,
                -0.01901363581418991,
                -0.018728531897068024,
                -0.14143192768096924,
                -0.1691237837076187,
                -0.16731180250644684,
                -0.054070886224508286,
                -0.09799540787935257,
                -0.0222462248057127,
                -0.00008631849777884781,
                -0.02239947021007538
            ],
            [
                -0.02232268638908863,
                -0.03411407396197319,
                -0.14095208048820496,
                -0.13264349102973938,
                -0.16763684153556824,
                -0.05947721749544144,
                -0.00020861705706920475,
                -0.14854545891284943,
                -0.025356290861964226,
                -0.1537455916404724,
                -0.022789213806390762,
                0.7177578806877136,
                -0.07415997236967087,
                -0.16740122437477112,
                -0.07264852523803711,
                -0.00024402924464084208
            ],
            [
                -0.14464071393013,
                -0.16845422983169556,
                -0.06118828430771828,
                -0.12702418863773346,
                -0.16490797698497772,
                -0.13243073225021362,
                -0.12193187326192856,
                -0.014643032103776932,
                0.2744126617908478,
                -0.08560647815465927,
                -0.05323084071278572,
                -0.0000010013491191784851,
                0.06619594991207123,
                -0.16044393181800842,
                -0.0028923528734594584,
                -0.00020130863413214684
            ],
            [
                0.23143699765205383,
                -0.08895948529243469,
                0.38913217186927795,
                -0.13441325724124908,
                -0.16963252425193787,
                0.932487428188324,
                -0.10200976580381393,
                -0.13233624398708344,
                -0.16930921375751495,
                -0.06603167951107025,
                -0.14693790674209595,
                -0.16324864327907562,
                -0.015441430732607841,
                -0.15719866752624512,
                -0.0020714171696454287,
                -0.06310627609491348
            ],
            [
                -0.0001944186951732263,
                -0.12322462350130081,
                -0.004360402002930641,
                -0.07743751257658005,
                -0.0018680042121559381,
                -0.0018774591153487563,
                -0.000908882764633745,
                -0.03340138494968414,
                -0.004676753655076027,
                -1.8042504734694376e-7,
                -0.061390772461891174,
                -0.03058650903403759,
                -0.12828673422336578,
                -0.0000016813502270451863,
                0,
                -0.0000010091905551234959
            ],
            [
                0.11740690469741821,
                0.030308013781905174,
                0.4872104823589325,
                -0.007434333674609661,
                0.39369067549705505,
                0.3027043640613556,
                0.4171500504016876,
                0.6261528730392456,
                0.07895214110612869,
                0.37098485231399536,
                0.468106746673584,
                0.6907908320426941,
                0.43466562032699585,
                0.38646140694618225,
                0.2556725740432739,
                0.13742181658744812
            ],
            [
                0.44197532534599304,
                0.9046493768692017,
                0.3145829737186432,
                0.28451675176620483,
                0.6276547312736511,
                0.018037771806120872,
                0.8236203789710999,
                1.2177704572677612,
                0.23840487003326416,
                0.8571550846099854,
                0.23504430055618286,
                0.542245090007782,
                0.45582857728004456,
                0.4244348704814911,
                0.5765856504440308,
                0.2076234519481659
            ],
            [
                0.22221432626247406,
                -0.035192470997571945,
                0.35204803943634033,
                0.1628992110490799,
                0.2496585100889206,
                0.5434038639068604,
                0.8665566444396973,
                0.6602203249931335,
                0.6217663884162903,
                0.7191551923751831,
                0.1940309852361679,
                0.3493567705154419,
                0.2098221629858017,
                0.7840451002120972,
                0.12263181805610657,
                0.3010043501853943
            ],
            [
                0.4392978549003601,
                0.44843414425849915,
                0.1600724160671234,
                -0.061071280390024185,
                -0.06470122933387756,
                0.19485591351985931,
                0.6932936310768127,
                0.2809275686740875,
                0.25505518913269043,
                0.27257731556892395,
                0.6778007745742798,
                0.5082826018333435,
                0.15077868103981018,
                0.2850886881351471,
                0.6253716349601746,
                0.6124471426010132
            ],
            [
                0.7378813624382019,
                0.9812977910041809,
                0.5047057867050171,
                0.26676690578460693,
                0.40834155678749084,
                0.24775336682796478,
                0.5059435367584229,
                0.2245771288871765,
                0.2920997142791748,
                0.33203378319740295,
                0.8795910477638245,
                0.20019154250621796,
                0.20297421514987946,
                0.2578817307949066,
                0.11915954947471619,
                -0.06441517919301987
            ],
            [
                0.5903182029724121,
                0.7652794122695923,
                0.1258566975593567,
                0.41883817315101624,
                0.7907013893127441,
                0.46700727939605713,
                0.5579572319984436,
                0.22575747966766357,
                0.24724167585372925,
                0.3053855001926422,
                0.12402696162462234,
                -0.018468346446752548,
                0.49665307998657227,
                -0.024716703221201897,
                0.25476154685020447,
                0.15351778268814087
            ],
            [
                0.16716113686561584,
                0.5133040547370911,
                -0.017794331535696983,
                0.18550659716129303,
                0.4258638918399811,
                0.26405423879623413,
                0.4539608359336853,
                0.3164166510105133,
                0.584201991558075,
                0.2134687304496765,
                0.5114778280258179,
                -0.045559048652648926,
                0.5155708193778992,
                0.23584818840026855,
                0.7641317844390869,
                -0.13989266753196716
            ],
            [
                0.8057158589363098,
                0.2473858743906021,
                0.5140971541404724,
                0.3116287887096405,
                0.24176107347011566,
                0.050730202347040176,
                0.13158315420150757,
                0.07246451824903488,
                0.5251628160476685,
                0.4334239065647125,
                0.1255323588848114,
                0.2607145309448242,
                0.5775617957115173,
                0.44376006722450256,
                0.24441982805728912,
                -0.1063661202788353
            ]
        ],
        [
            [
                2.81023907661438,
                4.685727596282959,
                3.5668373107910156,
                5.693130016326904,
                5.653675079345703,
                5.196375846862793,
                2.5777065753936768,
                4.24791955947876,
                5.567923545837402,
                2.5684614181518555,
                4.0192131996154785,
                2.6263349056243896,
                2.8774425983428955,
                3.5821235179901123,
                3.922373056411743,
                7.699085235595703
            ],
            [
                4.687906742095947,
                3.493757724761963,
                4.376543998718262,
                3.118083953857422,
                4.9952073097229,
                4.602108478546143,
                2.4928629398345947,
                5.435952663421631,
                4.464340686798096,
                3.1371819972991943,
                3.0300488471984863,
                3.1632866859436035,
                3.492084264755249,
                4.173824787139893,
                5.2941999435424805,
                4.942715644836426
            ],
            [
                2.242105722427368,
                4.067067623138428,
                3.715275287628174,
                4.942810535430908,
                6.006363391876221,
                4.764251708984375,
                4.965742588043213,
                4.351799964904785,
                4.692858695983887,
                2.9896721839904785,
                3.146876573562622,
                2.7524588108062744,
                4.863112449645996,
                5.81734561920166,
                7.070420265197754,
                4.094100475311279
            ],
            [
                5.12473726272583,
                4.590439796447754,
                3.2904093265533447,
                4.0392889976501465,
                5.029816627502441,
                5.659408092498779,
                4.80241584777832,
                3.725705146789551,
                4.224447250366211,
                3.1384451389312744,
                2.6083216667175293,
                4.016471862792969,
                3.8111743927001953,
                5.006431579589844,
                6.116928577423096,
                4.0027079582214355
            ],
            [
                2.810934066772461,
                3.504697799682617,
                2.8479785919189453,
                3.62353777885437,
                5.631840229034424,
                4.680842399597168,
                3.306732654571533,
                4.825923919677734,
                6.432873249053955,
                4.225638389587402,
                3.8293795585632324,
                4.469343185424805,
                4.1063737869262695,
                3.457052230834961,
                3.66204571723938,
                3.558680534362793
            ],
            [
                3.44987154006958,
                4.254306793212891,
                4.383200168609619,
                3.417717933654785,
                3.2227885723114014,
                4.344242095947266,
                2.948805332183838,
                2.6625618934631348,
                2.5636098384857178,
                4.414276599884033,
                4.559382438659668,
                6.573055744171143,
                5.4251837730407715,
                6.279183387756348,
                3.11421799659729,
                2.7080280780792236
            ],
            [
                2.9587395191192627,
                3.5622684955596924,
                4.494297981262207,
                4.574246406555176,
                3.2946531772613525,
                4.005603790283203,
                3.8085782527923584,
                3.3959949016571045,
                4.464500427246094,
                6.811591148376465,
                3.3350582122802734,
                3.2649765014648438,
                6.590816974639893,
                5.892868995666504,
                7.047221660614014,
                6.180258750915527
            ],
            [
                2.772019624710083,
                4.615751266479492,
                2.771507501602173,
                5.023200988769531,
                3.791931629180908,
                2.9298810958862305,
                3.6421494483947754,
                2.768343448638916,
                6.407894611358643,
                3.99776554107666,
                2.8061811923980713,
                2.896798849105835,
                5.242643356323242,
                7.343437194824219,
                8.771906852722168,
                4.392607688903809
            ],
            [
                2.4718430042266846,
                2.7219181060791016,
                2.6979966163635254,
                2.7425496578216553,
                2.748574733734131,
                2.626744270324707,
                2.7078492641448975,
                2.751560688018799,
                2.7912912368774414,
                2.8003311157226562,
                2.6484930515289307,
                2.5243072509765625,
                2.5452845096588135,
                2.7331531047821045,
                2.819443702697754,
                2.4802236557006836
            ],
            [
                2.552502393722534,
                2.7991092205047607,
                2.84184193611145,
                2.647502899169922,
                2.7474849224090576,
                2.703137159347534,
                2.897585153579712,
                2.86134672164917,
                2.670610189437866,
                2.7577202320098877,
                2.6366586685180664,
                2.7292258739471436,
                2.7850329875946045,
                2.7907721996307373,
                2.732431411743164,
                2.491083860397339
            ],
            [
                2.414701461791992,
                2.664822816848755,
                2.6729865074157715,
                2.7130863666534424,
                2.7388668060302734,
                2.886411428451538,
                2.872427225112915,
                2.8281657695770264,
                2.7989585399627686,
                2.8141019344329834,
                2.713876962661743,
                2.640962600708008,
                2.6575958728790283,
                2.687527656555176,
                2.626424789428711,
                2.490553140640259
            ],
            [
                2.6398801803588867,
                2.898047685623169,
                2.7027928829193115,
                2.70068621635437,
                2.6493537425994873,
                2.690720558166504,
                2.675189733505249,
                2.6171510219573975,
                2.6722638607025146,
                2.8143608570098877,
                2.7726380825042725,
                2.6862592697143555,
                2.6127266883850098,
                2.637547016143799,
                2.904543399810791,
                2.7932043075561523
            ],
            [
                2.6441338062286377,
                2.920285940170288,
                2.807783842086792,
                2.866868257522583,
                2.7421252727508545,
                2.6505746841430664,
                2.753495693206787,
                2.6443026065826416,
                2.7082901000976562,
                2.7368297576904297,
                2.777038812637329,
                2.7029480934143066,
                2.643573522567749,
                2.6687116622924805,
                2.7484543323516846,
                2.6428396701812744
            ],
            [
                2.497025728225708,
                2.8215811252593994,
                2.7477500438690186,
                2.9581310749053955,
                2.875401258468628,
                2.679316759109497,
                2.709367036819458,
                2.5700879096984863,
                2.740739345550537,
                2.7437996864318848,
                2.606257677078247,
                2.569748878479004,
                2.598332405090332,
                2.5719833374023438,
                2.6383044719696045,
                2.4523370265960693
            ],
            [
                2.4881746768951416,
                2.7973997592926025,
                2.774660587310791,
                2.5954267978668213,
                2.623063325881958,
                2.610609531402588,
                2.690403699874878,
                2.6671555042266846,
                2.7165985107421875,
                2.5547149181365967,
                2.554642677307129,
                2.6661055088043213,
                2.695178985595703,
                2.5403497219085693,
                2.4718244075775146,
                2.454596757888794
            ],
            [
                2.3074846267700195,
                2.5395662784576416,
                2.522921562194824,
                2.368350028991699,
                2.472332715988159,
                2.4620521068573,
                2.43045711517334,
                2.4531991481781006,
                2.4984970092773438,
                2.426165819168091,
                2.438218116760254,
                2.4804561138153076,
                2.525731086730957,
                2.4048190116882324,
                2.421959400177002,
                2.2626137733459473
            ]
        ],
        [
            [
                -0.085955411195755,
                -0.11630222946405411,
                -0.06506744772195816,
                -0.0000309237620967906,
                -0.061500780284404755,
                -0.0010344175389036536,
                -0.0013672413770109415,
                -0.15752717852592468,
                0.2662043273448944,
                -0.0040294332429766655,
                -0.002770639257505536,
                -0.00038006799877621233,
                -0.010779207572340965,
                -0.000010184739039686974,
                -0.14024734497070312,
                -0.16188734769821167
            ],
            [
                -0.10717912763357162,
                -0.00015247306146193296,
                -0.00010311853839084506,
                0,
                -0.13018621504306793,
                -0.1578822284936905,
                -0.0090407133102417,
                -0.14625698328018188,
                -0.024457108229398727,
                -0.0028982642106711864,
                0,
                -0.07792533934116364,
                -0.15389789640903473,
                0.9246305227279663,
                -0.00012160767073510215,
                -0.021908940747380257
            ],
            [
                -0.0005409849691204727,
                -0.14187105000019073,
                -0.058011773973703384,
                1.4919536113739014,
                -0.17000222206115723,
                -0.00017439386283513159,
                -0.13528813421726227,
                -0.0572982132434845,
                -0.1361425518989563,
                -0.16901284456253052,
                -0.002321572508662939,
                -0.06454995274543762,
                -0.0830017700791359,
                -0.08582649379968643,
                -0.000017826660041464493,
                3.208747148513794
            ],
            [
                1.8003861904144287,
                -0.16244634985923767,
                -0.026522502303123474,
                -0.14724771678447723,
                -0.03822796046733856,
                1.0828287601470947,
                -0.1406828910112381,
                0,
                0.1283578723669052,
                -0.002461638767272234,
                -0.0000035354194096726133,
                -0.021937096491456032,
                -0.0000010097138556375285,
                -0.019931500777602196,
                -0.02284533530473709,
                -0.04262973740696907
            ],
            [
                -0.14618179202079773,
                -0.07116437703371048,
                -0.002147843362763524,
                0,
                -0.022920941933989525,
                0,
                -0.0053601693361997604,
                0.5331995487213135,
                0.22796934843063354,
                -0.00027564834454096854,
                -0.003111686324700713,
                -0.1667688637971878,
                -0.0006179964984767139,
                -0.06309377402067184,
                -0.038656000047922134,
                -0.1700284630060196
            ],
            [
                -0.024534663185477257,
                -0.11621192842721939,
                -0.04303046315908432,
                -0.0000012720992117465357,
                0.046398941427469254,
                -0.11986198276281357,
                -0.06591971963644028,
                -0.1697845607995987,
                0.1715870350599289,
                0.2102726846933365,
                -0.05616625398397446,
                0,
                2.2331149578094482,
                -0.007104048505425453,
                -0.015104555524885654,
                -0.00012359126412775367
            ],
            [
                -0.15791867673397064,
                -0.16793841123580933,
                2.717447519302368,
                -0.12607412040233612,
                -0.021948978304862976,
                1.5632985830307007,
                -0.006300688721239567,
                -0.16909876465797424,
                -0.09795736521482468,
                -0.14676064252853394,
                -0.000021706344341509975,
                -0.0161850955337286,
                0,
                -0.00008966682071331888,
                1.4493573904037476,
                0.6864097118377686
            ],
            [
                -0.1510835438966751,
                0.8430856466293335,
                -0.16987210512161255,
                -5.815780923512648e-7,
                -0.10603367537260056,
                1.2907301187515259,
                -0.16575264930725098,
                1.0671360492706299,
                1.172842025756836,
                -0.0020359153859317303,
                -0.014827544800937176,
                -0.08996778726577759,
                -0.08277107030153275,
                -0.0000011292660246908781,
                0,
                -0.153407484292984
            ],
            [
                -0.002204017248004675,
                0.01025732047855854,
                0.23974239826202393,
                0.13116155564785004,
                0.03812570497393608,
                0.10517227649688721,
                0.19321806728839874,
                0.31581997871398926,
                0.2933780252933502,
                0.37220099568367004,
                0.03923991322517395,
                0.03796137496829033,
                0.033600982278585434,
                0.13655273616313934,
                0.14492657780647278,
                0.11704111099243164
            ],
            [
                0.05876297131180763,
                0.2767235040664673,
                0.05830708146095276,
                0.00864200759679079,
                0.13885891437530518,
                0.21546445786952972,
                0.09368845075368881,
                0.2737576961517334,
                0.15572164952754974,
                0.013793271034955978,
                0.22241240739822388,
                0.14437714219093323,
                -0.02197219431400299,
                0.17409542202949524,
                0.15198226273059845,
                0.06607707589864731
            ],
            [
                0.12110122293233871,
                0.0346052385866642,
                0.07779213786125183,
                0.3878481984138489,
                0.16474325954914093,
                0.16706186532974243,
                0.24575385451316833,
                0.25774049758911133,
                -0.01655898429453373,
                0.12417447566986084,
                0.22846443951129913,
                -0.0416390486061573,
                0.05141240730881691,
                0.2043786644935608,
                0.06427276134490967,
                -0.02993592992424965
            ],
            [
                0.10703417658805847,
                0.017401888966560364,
                -0.020279258489608765,
                0.298142671585083,
                0.0513942614197731,
                0.008576609194278717,
                0.26075196266174316,
                0.3980885148048401,
                0.20237623155117035,
                0.2711254954338074,
                0.05850902572274208,
                0.3099357485771179,
                0.07345961779356003,
                0.08920814841985703,
                0.10224547237157822,
                0.04084956273436546
            ],
            [
                0.1408458799123764,
                0.10635992139577866,
                0.12915989756584167,
                0.18424957990646362,
                0.09522707015275955,
                0.16078713536262512,
                0.11818218976259232,
                0.10342519730329514,
                0.26681020855903625,
                -0.10308481752872467,
                0.037579115480184555,
                0.16982245445251465,
                -0.008354667574167252,
                0.035462118685245514,
                0.43855687975883484,
                0.05636142939329147
            ],
            [
                0.05556175857782364,
                0.011003230698406696,
                0.4841742217540741,
                -0.032472044229507446,
                0.19746918976306915,
                0.24141080677509308,
                0.11632704734802246,
                0.09491170197725296,
                0.21587252616882324,
                0.02895725518465042,
                -0.0478275865316391,
                0.2549099922180176,
                -0.013791481964290142,
                0.1350022852420807,
                0.028166642412543297,
                0.17566080391407013
            ],
            [
                0.1707846224308014,
                0.23604705929756165,
                0.10861002653837204,
                0.10065176337957382,
                0.13134238123893738,
                -0.024267449975013733,
                0.21671134233474731,
                0.16694022715091705,
                0.11996660381555557,
                0.26619452238082886,
                0.21385571360588074,
                0.4546786844730377,
                0.1604633629322052,
                0.10014492273330688,
                0.06393568217754364,
                0.14264871180057526
            ],
            [
                0.03411276265978813,
                -0.07477221637964249,
                0.14570379257202148,
                0.029591452330350876,
                0.17962931096553802,
                0.01635100692510605,
                0.28170520067214966,
                0.06058230996131897,
                0.2918238937854767,
                0.03575177490711212,
                -0.12337365001440048,
                -0.02229229360818863,
                -0.03212045133113861,
                0.0606110543012619,
                0.06074582040309906,
                0.114293172955513
            ]
        ],
        [
            [
                7.787730693817139,
                7.097060203552246,
                7.548262596130371,
                7.3045244216918945,
                8.50555419921875,
                8.908726692199707,
                9.787103652954102,
                8.028343200683594,
                9.268499374389648,
                9.001821517944336,
                8.221816062927246,
                9.125974655151367,
                8.505905151367188,
                10.967251777648926,
                9.542264938354492,
                7.736873626708984
            ],
            [
                8.428926467895508,
                11.92900562286377,
                10.930315017700195,
                9.948356628417969,
                9.89041519165039,
                11.313624382019043,
                10.603266716003418,
                9.680587768554688,
                8.701915740966797,
                8.534378051757812,
                8.51073169708252,
                8.995125770568848,
                9.774481773376465,
                10.946371078491211,
                9.482749938964844,
                10.371565818786621
            ],
            [
                8.124311447143555,
                9.125712394714355,
                8.978466987609863,
                8.350300788879395,
                8.672282218933105,
                9.530001640319824,
                9.845285415649414,
                8.302703857421875,
                8.82030963897705,
                8.44453239440918,
                7.891663551330566,
                8.731027603149414,
                11.036147117614746,
                11.437975883483887,
                11.049965858459473,
                11.231744766235352
            ],
            [
                8.281421661376953,
                11.136155128479004,
                9.446826934814453,
                8.519123077392578,
                9.081399917602539,
                9.111502647399902,
                9.505802154541016,
                8.090126991271973,
                9.135542869567871,
                7.627614974975586,
                7.4019622802734375,
                8.360063552856445,
                9.640992164611816,
                10.64547061920166,
                10.334447860717773,
                10.235916137695312
            ],
            [
                8.228601455688477,
                10.082568168640137,
                10.477227210998535,
                8.622913360595703,
                9.577326774597168,
                11.14964485168457,
                10.917062759399414,
                8.800167083740234,
                8.457765579223633,
                7.514808654785156,
                8.74731731414795,
                9.309014320373535,
                9.187969207763672,
                10.540733337402344,
                10.027583122253418,
                8.368582725524902
            ],
            [
                8.70598316192627,
                9.39708423614502,
                10.15424633026123,
                9.397547721862793,
                10.597036361694336,
                9.976940155029297,
                9.76827621459961,
                7.89332389831543,
                7.6186842918396,
                8.721940994262695,
                12.016305923461914,
                12.471535682678223,
                8.830490112304688,
                10.139440536499023,
                10.017013549804688,
                9.905519485473633
            ],
            [
                7.5376200675964355,
                10.25334644317627,
                10.487360000610352,
                9.582418441772461,
                8.82274055480957,
                8.281259536743164,
                9.99431324005127,
                7.936376571655273,
                8.70508098602295,
                8.229995727539062,
                11.17270278930664,
                10.27456283569336,
                10.951634407043457,
                null,
                9.448025703430176,
                9.786139488220215
            ],
            [
                8.06283950805664,
                12.508588790893555,
                11.074197769165039,
                10.120562553405762,
                9.327266693115234,
                7.466074466705322,
                8.809144973754883,
                9.382857322692871,
                8.747970581054688,
                10.406488418579102,
                8.95190143585205,
                9.562830924987793,
                10.727436065673828,
                null,
                9.71535873413086,
                6.923555374145508
            ],
            [
                0.0043745217844843864,
                2.052286148071289,
                1.7285857200622559,
                1.8265388011932373,
                1.0957072973251343,
                0.3158375322818756,
                0.0923672690987587,
                0.6046143770217896,
                1.6872332096099854,
                0.9694809913635254,
                0.13582384586334229,
                0.4527042806148529,
                0.6096082329750061,
                0.8624343276023865,
                0.9523126482963562,
                -0.011054535396397114
            ],
            [
                -0.01530076190829277,
                1.1889647245407104,
                1.492161512374878,
                0.26573389768600464,
                0.644899845123291,
                0.261391818523407,
                0.8583913445472717,
                1.7752424478530884,
                0.2782546877861023,
                0.7646024823188782,
                0.6785666346549988,
                -0.14909197390079498,
                0.08509232103824615,
                0.9247410297393799,
                0.8009496331214905,
                0.15984903275966644
            ],
            [
                -0.1296648234128952,
                0.3061276376247406,
                0.36027878522872925,
                0.7400231957435608,
                0.8734792470932007,
                0.726860761642456,
                1.4074304103851318,
                1.3609850406646729,
                0.6592419743537903,
                0.9821566343307495,
                0.5587579011917114,
                -0.1641603708267212,
                -0.11686835438013077,
                0.3025951087474823,
                0.23256254196166992,
                0.5907314419746399
            ],
            [
                0.01809396781027317,
                0.8392674922943115,
                0.681221604347229,
                0.6447621583938599,
                0.664774477481842,
                0.7521392703056335,
                0.44419166445732117,
                -0.03314722329378128,
                -0.016379281878471375,
                0.44163477420806885,
                0.4584263563156128,
                0.5515310168266296,
                -0.12035980820655823,
                -0.01650538668036461,
                0.9597403407096863,
                1.709113359451294
            ],
            [
                0.04049791023135185,
                0.8733488321304321,
                0.8982486724853516,
                1.2920804023742676,
                1.0049721002578735,
                0.2212722897529602,
                0.28602200746536255,
                0.034587785601615906,
                0.34658145904541016,
                0.12147154659032822,
                0.7460861802101135,
                0.5432641506195068,
                0.07026065140962601,
                -0.09437127411365509,
                1.242908000946045,
                1.136853814125061
            ],
            [
                0.16987240314483643,
                1.5458414554595947,
                0.6259925961494446,
                0.5154598951339722,
                1.5838373899459839,
                0.7670008540153503,
                0.3631848692893982,
                0.0678686797618866,
                0.11874799430370331,
                0.02707156352698803,
                -0.08356835693120956,
                0.40716421604156494,
                0.4636056423187256,
                -0.03951520845293999,
                0.2274758368730545,
                0.5929126143455505
            ],
            [
                0.157511904835701,
                0.8216339349746704,
                0.9590343832969666,
                0.12986694276332855,
                0.5401395559310913,
                0.29927122592926025,
                0.036600563675165176,
                0.10425993800163269,
                0.43752068281173706,
                0.40807974338531494,
                0.25538817048072815,
                0.0025019505992531776,
                0.5995171070098877,
                0.21096724271774292,
                0.08696959912776947,
                0.6015649437904358
            ],
            [
                0.1601984202861786,
                -0.06613127887248993,
                -0.015027749352157116,
                -0.06165119260549545,
                0.29728177189826965,
                0.7186703681945801,
                -0.06390369683504105,
                -0.11242476850748062,
                0.12618863582611084,
                0.11689510941505432,
                -0.014285587705671787,
                0.23573555052280426,
                0.25161728262901306,
                0.18843209743499756,
                0.9119545221328735,
                0.6728695034980774
            ]
        ],
        [
            [
                3.07943058013916,
                2.411243200302124,
                3.7687416076660156,
                4.664554119110107,
                3.8155863285064697,
                5.047154426574707,
                5.168116569519043,
                3.1399712562561035,
                3.788233518600464,
                3.1182708740234375,
                3.4780993461608887,
                3.9615418910980225,
                4.1174092292785645,
                4.606086254119873,
                5.036957263946533,
                3.205578565597534
            ],
            [
                3.39001727104187,
                3.64628267288208,
                3.803341865539551,
                4.697074890136719,
                3.332435131072998,
                4.176155090332031,
                2.812034845352173,
                5.159640312194824,
                4.8565802574157715,
                3.1860291957855225,
                2.9977927207946777,
                2.3428444862365723,
                3.4592626094818115,
                2.852703809738159,
                3.206291437149048,
                5.1955952644348145
            ],
            [
                3.4157602787017822,
                2.503641128540039,
                2.4880549907684326,
                1.1618648767471313,
                2.918248176574707,
                4.886312961578369,
                4.303989410400391,
                3.374553680419922,
                3.4107861518859863,
                2.3883349895477295,
                2.6026761531829834,
                4.016170501708984,
                3.3665759563446045,
                5.266217231750488,
                5.956516742706299,
                5.850140571594238
            ],
            [
                2.0526278018951416,
                3.264385223388672,
                3.634901523590088,
                3.821514129638672,
                3.494861364364624,
                5.272923946380615,
                3.5484352111816406,
                4.318473815917969,
                3.979548454284668,
                2.2758820056915283,
                2.967186212539673,
                4.685230255126953,
                2.6947922706604004,
                3.5103049278259277,
                3.899667739868164,
                3.0074610710144043
            ],
            [
                3.2293028831481934,
                3.5907516479492188,
                2.7645158767700195,
                3.813260793685913,
                3.5819320678710938,
                5.079922199249268,
                3.835578441619873,
                2.5817127227783203,
                2.4237494468688965,
                2.82478404045105,
                2.962759494781494,
                2.9214274883270264,
                2.7480194568634033,
                3.311755418777466,
                3.131441116333008,
                2.914685010910034
            ],
            [
                3.806554079055786,
                2.4652373790740967,
                3.332829236984253,
                4.203531742095947,
                2.8933706283569336,
                1.9781962633132935,
                3.4298954010009766,
                2.6590168476104736,
                3.7293930053710938,
                3.3981051445007324,
                4.1548285484313965,
                5.6737380027771,
                4.712164402008057,
                2.416438102722168,
                4.876253604888916,
                3.788174867630005
            ],
            [
                2.584397315979004,
                4.3479437828063965,
                3.1390342712402344,
                3.34871768951416,
                2.652895927429199,
                2.6282386779785156,
                2.2840359210968018,
                1.3796844482421875,
                3.1257238388061523,
                3.542283058166504,
                5.121646881103516,
                4.239731311798096,
                3.7089312076568604,
                6.88862419128418,
                5.199793815612793,
                3.2721469402313232
            ],
            [
                3.1640286445617676,
                3.3228681087493896,
                5.464670181274414,
                6.665327072143555,
                2.867414951324463,
                3.0371298789978027,
                3.7848081588745117,
                2.8450896739959717,
                4.510955333709717,
                5.08090353012085,
                3.080183267593384,
                3.4647881984710693,
                4.411933898925781,
                7.8000078201293945,
                6.940975666046143,
                3.008596181869507
            ],
            [
                -0.16152481734752655,
                -0.1009051650762558,
                -0.07191559672355652,
                -0.050090257078409195,
                -0.005751388613134623,
                -0.07243511080741882,
                -0.045158758759498596,
                -0.06645695865154266,
                0.053562525659799576,
                0.22641855478286743,
                0.15976257622241974,
                -0.022464876994490623,
                -0.09912234544754028,
                -0.05815902724862099,
                -0.06396306306123734,
                -0.0036582923494279385
            ],
            [
                0.009909302927553654,
                0.01894211210310459,
                0.05263754725456238,
                0.06170186400413513,
                -0.05567983537912369,
                0.07982157170772552,
                0.07175993919372559,
                0.1192585751414299,
                -0.06500615924596786,
                0.041220106184482574,
                0.17694881558418274,
                0.11201046407222748,
                0.017748959362506866,
                0.39681804180145264,
                0.09070410579442978,
                -0.0015379851683974266
            ],
            [
                -0.034841857850551605,
                0.1494830697774887,
                -0.04449727013707161,
                0.038676872849464417,
                0.2295996993780136,
                0.33340173959732056,
                0.1644439995288849,
                0.14489813148975372,
                0.06505849957466125,
                0.18430359661579132,
                0.09707421064376831,
                -0.004415632225573063,
                0.21003806591033936,
                0.4166349470615387,
                0.08134474605321884,
                0.10923787951469421
            ],
            [
                0.023669332265853882,
                0.5133143067359924,
                0.014323499985039234,
                -0.09707792103290558,
                0.26374351978302,
                0.2724266052246094,
                0.03510277718305588,
                0.07952983677387238,
                0.07382970303297043,
                0.21436427533626556,
                -0.023071713745594025,
                0.21077248454093933,
                0.12170568853616714,
                0.09573303908109665,
                0.3325485587120056,
                0.7078439593315125
            ],
            [
                0.05453590303659439,
                0.3027704954147339,
                0.2813323140144348,
                0.4476637840270996,
                -0.08165071159601212,
                0.08382432162761688,
                0.266804575920105,
                0.1720547080039978,
                0.3084588646888733,
                0.051342662423849106,
                0.035820089280605316,
                -0.06381835788488388,
                0.02741287276148796,
                0.18370939791202545,
                0.20323346555233002,
                0.021269340068101883
            ],
            [
                -0.047120846807956696,
                0.015389708802103996,
                0.2659490704536438,
                0.1304778903722763,
                0.3044176399707794,
                0.01821638084948063,
                0.16466151177883148,
                0.04894673079252243,
                0.14114049077033997,
                -0.041113968938589096,
                -0.0635879784822464,
                0.08407014608383179,
                0.1023559719324112,
                0.04717037454247475,
                -0.041386928409338,
                0.10988398641347885
            ],
            [
                -0.07212494313716888,
                0.18433523178100586,
                0.20866887271404266,
                -0.06750355660915375,
                0.4946070611476898,
                0.0662459209561348,
                0.28979918360710144,
                0.1840081512928009,
                0.22477708756923676,
                -0.012331068515777588,
                -0.07251924276351929,
                0.1214105635881424,
                0.08672638982534409,
                0.1646704524755478,
                0.04745634272694588,
                -0.03038882650434971
            ],
            [
                0.3137454092502594,
                0.19841669499874115,
                0.2038477510213852,
                0.05111277103424072,
                -0.007700285408645868,
                0.2345607578754425,
                0.2129496932029724,
                0.1200556755065918,
                0.2965092360973358,
                0.20024771988391876,
                -0.007922212593257427,
                0.34125426411628723,
                0.16230955719947815,
                0.051677968353033066,
                -0.1420162469148636,
                -0.011019882746040821
            ]
        ],
        [
            [
                1.754906177520752,
                2.636091947555542,
                3.559455394744873,
                4.859561443328857,
                2.1365320682525635,
                3.3679463863372803,
                3.903212547302246,
                3.891134262084961,
                1.9530396461486816,
                3.6662046909332275,
                2.939586639404297,
                2.8151190280914307,
                2.542635440826416,
                3.7474119663238525,
                0.7677208185195923,
                2.192176103591919
            ],
            [
                4.544186592102051,
                3.1301379203796387,
                3.746157169342041,
                4.391059875488281,
                2.522493600845337,
                1.1643165349960327,
                2.1357181072235107,
                4.530937671661377,
                5.956239223480225,
                4.4270339012146,
                4.65311336517334,
                4.272663116455078,
                3.6114089488983154,
                1.8667839765548706,
                4.428175449371338,
                3.12199330329895
            ],
            [
                4.932099342346191,
                2.618377923965454,
                2.8750264644622803,
                3.0364248752593994,
                2.8865671157836914,
                3.7760350704193115,
                3.662033796310425,
                5.054088592529297,
                3.949317693710327,
                4.0377116203308105,
                5.2194905281066895,
                3.509591817855835,
                2.3657238483428955,
                0.42049410939216614,
                6.620492458343506,
                1.7737311124801636
            ],
            [
                1.8537013530731201,
                4.080290794372559,
                4.275885105133057,
                4.360395908355713,
                4.2457404136657715,
                6.277257442474365,
                2.3267321586608887,
                4.279415130615234,
                5.732382297515869,
                3.6128296852111816,
                5.501298904418945,
                4.589908123016357,
                4.766080856323242,
                2.943605899810791,
                1.8510005474090576,
                2.7702622413635254
            ],
            [
                0.9465958476066589,
                3.7237935066223145,
                2.9650330543518066,
                4.893813610076904,
                4.759335994720459,
                4.662818908691406,
                2.379958391189575,
                1.2159863710403442,
                3.100797653198242,
                6.194239139556885,
                6.171439170837402,
                3.4527206420898438,
                3.9124975204467773,
                2.7227635383605957,
                1.6712568998336792,
                3.3000364303588867
            ],
            [
                3.232147455215454,
                2.6220593452453613,
                2.2121200561523438,
                5.048887729644775,
                3.489823341369629,
                1.5388885736465454,
                2.7316224575042725,
                2.7579150199890137,
                4.2409892082214355,
                5.6331467628479,
                2.8180172443389893,
                0.6402896642684937,
                2.113455057144165,
                3.717180013656616,
                3.6790215969085693,
                3.126889944076538
            ],
            [
                3.276832103729248,
                3.5187947750091553,
                0.7734339237213135,
                3.7796432971954346,
                2.3963959217071533,
                0.9462308883666992,
                2.4384255409240723,
                3.3023459911346436,
                2.0599007606506348,
                3.611546277999878,
                5.449558734893799,
                3.8379721641540527,
                2.4239988327026367,
                5.34094762802124,
                3.249737501144409,
                1.1169488430023193
            ],
            [
                2.8424417972564697,
                2.6396069526672363,
                2.4207663536071777,
                5.269069194793701,
                3.1860954761505127,
                3.9089934825897217,
                2.04374098777771,
                2.836293935775757,
                0.7009701132774353,
                4.528240203857422,
                5.283782958984375,
                4.106956481933594,
                4.590032577514648,
                2.5612006187438965,
                6.228711128234863,
                6.135231971740723
            ],
            [
                -0.12484994530677795,
                -0.10034973919391632,
                -0.0807327926158905,
                -0.08835329115390778,
                -0.08474276959896088,
                -0.148877814412117,
                -0.1207432746887207,
                -0.10898811370134354,
                -0.0851384699344635,
                -0.08918555825948715,
                -0.14134721457958221,
                -0.13095764815807343,
                -0.13487310707569122,
                -0.07873357832431793,
                -0.08749743551015854,
                -0.1464502513408661
            ],
            [
                -0.15596036612987518,
                -0.12198927998542786,
                -0.11212962120771408,
                -0.1491815149784088,
                -0.10098358988761902,
                -0.1302088052034378,
                -0.10893788933753967,
                -0.12039206922054291,
                -0.12199658155441284,
                -0.1037503182888031,
                -0.16672590374946594,
                -0.1090652272105217,
                -0.1274178922176361,
                -0.13177825510501862,
                -0.12877222895622253,
                -0.16287793219089508
            ],
            [
                -0.14089253544807434,
                -0.13958555459976196,
                -0.10671203583478928,
                -0.09119702875614166,
                -0.09463930875062943,
                -0.1193690299987793,
                -0.150314062833786,
                -0.11439966410398483,
                -0.14576022326946259,
                -0.12982484698295593,
                -0.1403382569551468,
                -0.15223759412765503,
                -0.1650906354188919,
                -0.15446895360946655,
                -0.16484546661376953,
                -0.16992829740047455
            ],
            [
                -0.1501484513282776,
                -0.13992759585380554,
                -0.1651749461889267,
                -0.13641005754470825,
                -0.13516061007976532,
                -0.12018975615501404,
                -0.13424569368362427,
                -0.13610978424549103,
                -0.14837603271007538,
                -0.08493265509605408,
                -0.1608402580022812,
                -0.16618290543556213,
                -0.14842519164085388,
                -0.15272927284240723,
                -0.16373156011104584,
                -0.1591920405626297
            ],
            [
                -0.1520184576511383,
                -0.1321958303451538,
                -0.11901500821113586,
                -0.10517266392707825,
                -0.13911738991737366,
                -0.15341395139694214,
                -0.14534315466880798,
                -0.14692769944667816,
                -0.1332259327173233,
                -0.14606928825378418,
                -0.15014693140983582,
                -0.1399402618408203,
                -0.1533178836107254,
                -0.14129219949245453,
                -0.12862978875637054,
                -0.16856743395328522
            ],
            [
                -0.13957729935646057,
                -0.10511066764593124,
                -0.11276464909315109,
                -0.11585073173046112,
                -0.1065952256321907,
                -0.13334110379219055,
                -0.11345609277486801,
                -0.13723798096179962,
                -0.13746482133865356,
                -0.12110144644975662,
                -0.1667768657207489,
                -0.14350196719169617,
                -0.11663501709699631,
                -0.1602368801832199,
                -0.10237126052379608,
                -0.14621739089488983
            ],
            [
                -0.14319512248039246,
                -0.12176193296909332,
                -0.1214907243847847,
                -0.12383866310119629,
                -0.12136811763048172,
                -0.1262815147638321,
                -0.11788986623287201,
                -0.11903810501098633,
                -0.11436737328767776,
                -0.13690625131130219,
                -0.06596175581216812,
                -0.08613041043281555,
                -0.10029296576976776,
                -0.1570740044116974,
                -0.14402423799037933,
                -0.14913536608219147
            ],
            [
                -0.16734972596168518,
                -0.15650838613510132,
                -0.1694968044757843,
                -0.1460287719964981,
                -0.15639133751392365,
                -0.15046262741088867,
                -0.16622953116893768,
                -0.1694473773241043,
                -0.1600060611963272,
                -0.14604325592517853,
                -0.14055953919887543,
                -0.15725964307785034,
                -0.16560325026512146,
                -0.1663760542869568,
                -0.12240838259458542,
                -0.1605299413204193
            ]
        ],
        [
            [
                -0.0033108920324593782,
                -0.03387630358338356,
                -0.006361403968185186,
                -0.017592741176486015,
                -0.021630121394991875,
                -0.007202150300145149,
                -0.04845741391181946,
                -0.016341060400009155,
                -0.02763310819864273,
                -0.020277880132198334,
                -0.0008289580582641065,
                -0.006342877168208361,
                -0.001753166550770402,
                -0.01306516956537962,
                -0.003996731713414192,
                -0.004543885588645935
            ],
            [
                -0.047615718096494675,
                -0.027356188744306564,
                -0.0013004792854189873,
                -0.0017039402155205607,
                -0.0007652430795133114,
                -0.011520293541252613,
                -0.004516272805631161,
                -0.0215719323605299,
                -0.015216268599033356,
                -0.04817929491400719,
                -0.006765464786440134,
                -0.020354820415377617,
                -0.04083675146102905,
                -0.00889752246439457,
                -0.06121576949954033,
                -0.056693948805332184
            ],
            [
                -0.007948516868054867,
                -0.0014433010946959257,
                -0.02053573727607727,
                -0.1437721848487854,
                -0.009583798237144947,
                -0.08355195820331573,
                -0.08226413279771805,
                -0.0008931768825277686,
                -0.011342817917466164,
                -0.06962662190198898,
                -0.021541109308600426,
                -0.13527676463127136,
                -0.018747905269265175,
                -0.000029484588594641536,
                -0.01983608491718769,
                -0.06779634952545166
            ],
            [
                -0.001871246611699462,
                -0.14283840358257294,
                -0.00656311959028244,
                -0.008049456402659416,
                -0.03807152435183525,
                -0.025545185431838036,
                -0.013599766418337822,
                -0.007383421994745731,
                -0.07523840665817261,
                -0.035492729395627975,
                -0.08193571865558624,
                -0.011302829720079899,
                -0.06336668878793716,
                -0.005808291491121054,
                -0.0024788668379187584,
                -0.11141963303089142
            ],
            [
                -0.0006996188894845545,
                -0.08463896065950394,
                -0.011322016827762127,
                -0.00005974373561912216,
                -0.020228300243616104,
                -0.031283777207136154,
                -0.001706678536720574,
                -0.0006061310996301472,
                -0.005717551801353693,
                -0.033847346901893616,
                -0.06436832994222641,
                -0.04736703261733055,
                -0.021863913163542747,
                -0.01119423471391201,
                -0.03400242328643799,
                -0.03863278776407242
            ],
            [
                -0.031492337584495544,
                -0.12524528801441193,
                -0.07926103472709656,
                -0.0008358679478988051,
                -0.011107667349278927,
                -0.0010982195381075144,
                -0.022574227303266525,
                -0.058623313903808594,
                -0.15335960686206818,
                -0.024506036192178726,
                -0.074927918612957,
                -0.000027114567274111323,
                -0.004902189131826162,
                -0.0011218274012207985,
                -0.16322188079357147,
                -0.1258946806192398
            ],
            [
                -0.0638674944639206,
                -0.012051496654748917,
                -0.039401598274707794,
                -0.04595131799578667,
                -0.019604427739977837,
                -0.015061221085488796,
                -0.04146689176559448,
                -0.08640962094068527,
                -0.004061756655573845,
                -0.02523103356361389,
                -0.16102975606918335,
                -0.015217239037156105,
                -0.04026995226740837,
                -0.01993265189230442,
                -0.003359829541295767,
                -0.045265551656484604
            ],
            [
                -0.002505885437130928,
                -0.0010078165214508772,
                -0.004000175278633833,
                -0.0000023507159312430304,
                -0.021315500140190125,
                -0.057827237993478775,
                -0.0024143292102962732,
                -0.09929709136486053,
                -0.0005082542193122208,
                -0.018962783738970757,
                -0.0027405081782490015,
                -0.004496071022003889,
                -0.000492036051582545,
                -4.3391560211603064e-7,
                -4.336939980476018e-7,
                -0.03392292559146881
            ],
            [
                -0.08121847361326218,
                -0.06831453740596771,
                -0.06801507622003555,
                -0.09973348677158356,
                0.12670369446277618,
                0.10821417719125748,
                0.0235263891518116,
                -0.028147302567958832,
                0.05221947655081749,
                -0.04760005697607994,
                0.02235284261405468,
                0.012453403323888779,
                -0.009423668496310711,
                0.08619961142539978,
                0.034303441643714905,
                -0.01948825642466545
            ],
            [
                -0.08478173613548279,
                -0.02825275994837284,
                0.03598897159099579,
                -0.008517772890627384,
                -0.07644804567098618,
                0.11972390860319138,
                -0.09311623126268387,
                -0.08226919174194336,
                -0.008011843077838421,
                -0.040085434913635254,
                0.07212825864553452,
                -0.08065023273229599,
                -0.0888025313615799,
                -0.04331956431269646,
                -0.04811714589595795,
                -0.11688464879989624
            ],
            [
                -0.1107788160443306,
                0.2215319126844406,
                0.05966833233833313,
                0.1220080703496933,
                0.08395418524742126,
                -0.04395676031708717,
                -0.049495093524456024,
                0.046509940177202225,
                0.03278398513793945,
                -0.05527171865105629,
                -0.014785871841013432,
                -0.05689563229680061,
                0.031776975840330124,
                0.02046746388077736,
                -0.0960608571767807,
                -0.08158867061138153
            ],
            [
                -0.09497104585170746,
                0.004556304309517145,
                0.15862135589122772,
                0.16003859043121338,
                0.14839276671409607,
                -0.032462041825056076,
                0.0387447215616703,
                0.014851060695946217,
                0.2100539356470108,
                0.08652476966381073,
                0.10685766488313675,
                0.14574265480041504,
                -0.03456026688218117,
                -0.05377762392163277,
                -0.07576768845319748,
                -0.07187484949827194
            ],
            [
                -0.11817581951618195,
                -0.06538346409797668,
                -0.04402278736233711,
                0.010421124286949635,
                0.1684921383857727,
                0.029301360249519348,
                0.014909953810274601,
                -0.03491690382361412,
                0.00794128142297268,
                -0.014424599707126617,
                0.00587552972137928,
                -0.042266346514225006,
                0.14510874450206757,
                -0.10313659906387329,
                -0.06536417454481125,
                -0.015931161120533943
            ],
            [
                -0.08770886808633804,
                -0.06464286893606186,
                0.12143924832344055,
                -0.07243485003709793,
                -0.04319597780704498,
                0.07256243377923965,
                0.1886848509311676,
                0.05126655474305153,
                0.017831619828939438,
                -0.04340512678027153,
                0.09006042778491974,
                0.28868141770362854,
                0.12348338216543198,
                0.10464209318161011,
                -0.06421913951635361,
                0.029188619926571846
            ],
            [
                -0.06561554968357086,
                0.0034123293589800596,
                0.15189547836780548,
                0.17058254778385162,
                0.20013339817523956,
                0.29583045840263367,
                0.194891557097435,
                0.05053209513425827,
                -0.010145613923668861,
                0.06774720549583435,
                0.12921573221683502,
                0.17189432680606842,
                -0.10808368027210236,
                0.08125722408294678,
                0.03543258458375931,
                0.08449988067150116
            ],
            [
                -0.04142027720808983,
                -0.10198711603879929,
                -0.11127953976392746,
                -0.1577432006597519,
                -0.0076161278411746025,
                0.1748388558626175,
                0.24342915415763855,
                -0.0821082592010498,
                -0.11754566431045532,
                -0.1447017341852188,
                -0.050118930637836456,
                -0.08129806071519852,
                -0.14438016712665558,
                -0.12737800180912018,
                -0.01080904807895422,
                0.38555631041526794
            ]
        ],
        [
            [
                1.5459668636322021,
                2.4860873222351074,
                0.15302081406116486,
                1.694574236869812,
                0.06637371331453323,
                2.387032985687256,
                0.24651439487934113,
                0.9285293221473694,
                1.6834301948547363,
                3.6696815490722656,
                -0.01874314248561859,
                2.040302038192749,
                1.6447422504425049,
                0.8110091686248779,
                4.863369464874268,
                1.9962393045425415
            ],
            [
                0.6029703617095947,
                3.914372682571411,
                3.0078647136688232,
                1.1911771297454834,
                3.485508441925049,
                4.212785243988037,
                2.8634042739868164,
                2.8160808086395264,
                3.180363178253174,
                -0.08255112916231155,
                -0.06605198234319687,
                0.650466799736023,
                0.0852467492222786,
                2.1656885147094727,
                3.1390955448150635,
                2.034874200820923
            ],
            [
                2.088779926300049,
                4.544824600219727,
                3.4423723220825195,
                1.854607343673706,
                3.639470338821411,
                2.6184723377227783,
                5.412957668304443,
                2.0638089179992676,
                1.9741710424423218,
                0.19487933814525604,
                -0.1478876918554306,
                1.7932190895080566,
                3.5858817100524902,
                6.72412633895874,
                6.093186378479004,
                2.9124085903167725
            ],
            [
                2.37414813041687,
                2.0678350925445557,
                0.25955817103385925,
                1.3837124109268188,
                3.7643041610717773,
                2.5332725048065186,
                2.3435001373291016,
                -0.07166214287281036,
                1.8826429843902588,
                1.5974122285842896,
                -0.12435168027877808,
                1.062181830406189,
                2.3302183151245117,
                6.9232869148254395,
                6.520455837249756,
                1.9522690773010254
            ],
            [
                2.9638478755950928,
                4.790608882904053,
                2.7668561935424805,
                -0.16163811087608337,
                2.993274688720703,
                2.1819729804992676,
                3.244138717651367,
                3.825493812561035,
                3.4934933185577393,
                0.9380885362625122,
                1.060010313987732,
                2.488764524459839,
                1.0767160654067993,
                0.9604193568229675,
                2.6279866695404053,
                1.6873517036437988
            ],
            [
                0.9443104267120361,
                1.8594285249710083,
                3.8363254070281982,
                0.9178844690322876,
                2.871133804321289,
                2.5109543800354004,
                0.8045903444290161,
                1.906050443649292,
                0.9603788256645203,
                1.8976168632507324,
                3.6882717609405518,
                7.70927095413208,
                4.21052360534668,
                1.352530598640442,
                1.8884270191192627,
                0.6863873600959778
            ],
            [
                -0.03781355917453766,
                0.8143512606620789,
                4.048738956451416,
                1.9741305112838745,
                1.9506946802139282,
                2.5045464038848877,
                2.860097885131836,
                0.9139449596405029,
                2.5569374561309814,
                3.022698163986206,
                3.0396525859832764,
                4.352962493896484,
                3.6728515625,
                6.025232791900635,
                4.946177959442139,
                4.0737762451171875
            ],
            [
                1.095326542854309,
                5.180521011352539,
                5.297049522399902,
                3.8332924842834473,
                2.8070554733276367,
                3.370325803756714,
                5.081439971923828,
                3.057943344116211,
                5.497675895690918,
                3.9251651763916016,
                0.9027958512306213,
                1.1584745645523071,
                2.4663214683532715,
                7.581418991088867,
                4.215699672698975,
                2.1847195625305176
            ],
            [
                0.30347827076911926,
                0.43163347244262695,
                -0.0016926453681662679,
                0.03476003184914589,
                -0.0003096717700827867,
                -0.09824501723051071,
                -0.1086820662021637,
                0.03500418737530708,
                0.46250131726264954,
                -0.056808389723300934,
                -0.14383091032505035,
                -0.09519928693771362,
                -0.008422584272921085,
                -0.07767807692289352,
                0.06465677917003632,
                0.1047610193490982
            ],
            [
                0.1820024847984314,
                -0.02782076969742775,
                -0.09156125783920288,
                -0.1360590159893036,
                -0.13427378237247467,
                -0.09425944089889526,
                -0.13320915400981903,
                -0.09704920649528503,
                -0.10020048171281815,
                -0.15245862305164337,
                -0.06361271440982819,
                -0.06755352020263672,
                -0.09174629300832748,
                -0.10121693462133408,
                -0.10621792823076248,
                0.06703020632266998
            ],
            [
                0.06105295196175575,
                0.007762301247566938,
                -0.13409020006656647,
                -0.12047361582517624,
                -0.041422486305236816,
                -0.07611528784036636,
                -0.10528723895549774,
                -0.09612450748682022,
                -0.16273309290409088,
                -0.10808434337377548,
                -0.0558059886097908,
                -0.1217895969748497,
                -0.07272674143314362,
                -0.08306412398815155,
                -0.13467155396938324,
                0.12455030530691147
            ],
            [
                0.002670666202902794,
                -0.0849694162607193,
                -0.09791246056556702,
                -0.08971545845270157,
                -0.1293327510356903,
                -0.1425134688615799,
                -0.11707766354084015,
                -0.15793980658054352,
                -0.16120271384716034,
                -0.1205296590924263,
                -0.1328716278076172,
                -0.10274176299571991,
                -0.08601150661706924,
                -0.11909960955381393,
                -0.09964676201343536,
                0.17590326070785522
            ],
            [
                0.018794668838381767,
                -0.10873906314373016,
                -0.1344376653432846,
                -0.0824013203382492,
                -0.08519934117794037,
                -0.14487116038799286,
                -0.14661990106105804,
                -0.11801838129758835,
                -0.03440355136990547,
                -0.10151740163564682,
                -0.14498963952064514,
                -0.12042685598134995,
                -0.09502911567687988,
                -0.06670437753200531,
                0.023486122488975525,
                0.3355060815811157
            ],
            [
                0.2019963413476944,
                0.04325789213180542,
                0.00006390269118128344,
                0.0015188733814284205,
                -0.06793894618749619,
                -0.15047205984592438,
                -0.10126064717769623,
                -0.1242300420999527,
                -0.10916310548782349,
                -0.0858592838048935,
                0.10252339392900467,
                0.1549670696258545,
                -0.00885321106761694,
                -0.13008224964141846,
                -0.07397331297397614,
                0.0858863964676857
            ],
            [
                0.12870116531848907,
                -0.11611353605985641,
                0.01169696543365717,
                -0.06216415390372276,
                -0.04119925945997238,
                -0.1539982110261917,
                -0.09618549048900604,
                -0.0831872969865799,
                -0.02603963389992714,
                -0.09363311529159546,
                0.03916708007454872,
                -0.0670941099524498,
                0.004919526167213917,
                -0.014442319050431252,
                0.08355709165334702,
                -0.08699778467416763
            ],
            [
                0.05571240931749344,
                -0.06154439598321915,
                0.07329834252595901,
                0.007281840313225985,
                0.11081870645284653,
                -0.003522015642374754,
                -0.062303416430950165,
                0.02880941517651081,
                0.1471216231584549,
                0.1483350396156311,
                0.05157271772623062,
                0.0022544325329363346,
                -0.060717225074768066,
                0.15357470512390137,
                0.007186856120824814,
                0.05290675908327103
            ]
        ],
        [
            [
                -0.0002157889975933358,
                -0.10311701148748398,
                -0.15753300487995148,
                -0.002428249455988407,
                -0.032523754984140396,
                -0.000050316019041929394,
                -0.003956989385187626,
                -0.025807691738009453,
                0.3327179253101349,
                -0.0008124512387439609,
                -1.5288702570614987e-7,
                -0.04038727656006813,
                -0.0000023541858809039695,
                -0.0000018100679426424904,
                1.7875152826309204,
                -0.15724530816078186
            ],
            [
                -0.05459166690707207,
                0,
                -0.0000052295617933850735,
                -1.48880957340225e-7,
                -0.000393997470382601,
                -0.02120278961956501,
                -0.0000032855646168172825,
                -0.0000967858504736796,
                0.12886537611484528,
                0,
                0,
                -0.05291792005300522,
                -0.00010133125761058182,
                -0.10195834934711456,
                -0.010276137851178646,
                -0.0011785475071519613
            ],
            [
                -2.1648453696343495e-7,
                -0.08097279071807861,
                -0.0027347146533429623,
                -0.002087413566187024,
                -0.00325603224337101,
                -0.027382535859942436,
                -0.0474623404443264,
                -0.0007857427699491382,
                -0.0022243098355829716,
                -2.9126931622158736e-7,
                0,
                -0.000010807582839333918,
                -0.08152617514133453,
                -0.005435214377939701,
                -0.000003150551492581144,
                0.5815132856369019
            ],
            [
                -0.05834309756755829,
                -0.000019408713342272677,
                -0.0015504281036555767,
                -0.002943574683740735,
                -2.9394453804343357e-7,
                1.926460862159729,
                -0.021051911637187004,
                0,
                -0.006208695005625486,
                -0.00129028526134789,
                0,
                0,
                0,
                -0.022181173786520958,
                0.09631814062595367,
                -0.0000024775881684035994
            ],
            [
                -0.05085470899939537,
                -0.028248392045497894,
                0,
                -0.0000011401868960092543,
                0,
                0,
                -0.000007150634701247327,
                -0.017152369022369385,
                -0.008170119486749172,
                -1.8174090143929789e-7,
                -1.867691139523231e-7,
                -0.006414693780243397,
                -1.5343150039370812e-7,
                -0.0017869739094749093,
                -0.00004302134402678348,
                0
            ],
            [
                -0.02031255140900612,
                -0.000029368666218942963,
                0,
                0,
                -0.000022316944523481652,
                -0.000003270294200774515,
                0,
                -0.016073815524578094,
                -0.0006787966703996062,
                -0.000033404612622689456,
                -0.00019644897838588804,
                4.446356296539307,
                0.37396273016929626,
                -0.000014282093616202474,
                -0.000025083698346861638,
                -0.000004068760063091759
            ],
            [
                -0.014734568074345589,
                -0.0057715196162462234,
                -0.14939631521701813,
                -0.05174366012215614,
                -0.00000127001226246648,
                -0.1699160784482956,
                0,
                0,
                -0.002290633972734213,
                -0.00047267472837120295,
                0,
                -0.0009482671739533544,
                -2.017090139361244e-7,
                3.790621519088745,
                1.4236100912094116,
                1.7502983808517456
            ],
            [
                -0.0002530927595216781,
                -0.07584748417139053,
                -0.00813404843211174,
                -0.14522594213485718,
                -0.15663693845272064,
                -0.06496323645114899,
                -0.09797345101833344,
                -0.01106240227818489,
                0.2690381109714508,
                -0.0008414509356953204,
                -1.5170320466495468e-7,
                -0.006410055793821812,
                -0.0001537623320473358,
                2.6646692752838135,
                3.5033321380615234,
                -0.025451406836509705
            ],
            [
                0.02122271992266178,
                -0.01115493569523096,
                0.276078999042511,
                0.43658900260925293,
                0.09948478639125824,
                0.04507669433951378,
                0.08318902552127838,
                0.055721789598464966,
                0.04428854212164879,
                -0.027870621532201767,
                -0.009097070433199406,
                0.10125568509101868,
                0.012405489571392536,
                0.12386833876371384,
                -0.024758029729127884,
                -0.11933273077011108
            ],
            [
                0.03078664280474186,
                0.16025331616401672,
                0.3870227038860321,
                0.07065615057945251,
                -0.1002991646528244,
                -0.0028516226448118687,
                -0.04213739559054375,
                0.09215756505727768,
                -0.018344217911362648,
                -0.10350537300109863,
                0.07204397767782211,
                -0.0033599273301661015,
                -0.07909027487039566,
                0.0669303834438324,
                0.1436643749475479,
                0.037276219576597214
            ],
            [
                -0.03914717212319374,
                -0.10540752857923508,
                0.17814508080482483,
                -0.012973879463970661,
                -0.09458276629447937,
                0.14892561733722687,
                0.03718401864171028,
                0.3863304853439331,
                0.0842878520488739,
                -0.014331085607409477,
                0.21205128729343414,
                0.05541786924004555,
                -0.0735054463148117,
                0.04425319284200668,
                0.04198716580867767,
                -0.04058106243610382
            ],
            [
                0.03480539470911026,
                0.03019716404378414,
                0.12795856595039368,
                0.03661823645234108,
                0.022072818130254745,
                0.3052426278591156,
                0.21050427854061127,
                0.2086576670408249,
                -0.0803012028336525,
                -0.0033262434881180525,
                -0.06560292094945908,
                -0.054629627615213394,
                -0.09008318930864334,
                -0.03366125747561455,
                -0.03352443501353264,
                0.027786770835518837
            ],
            [
                0.22109012305736542,
                0.007106191013008356,
                -0.0279818307608366,
                0.3230849802494049,
                -0.07468681037425995,
                0.49070513248443604,
                -0.04392845556139946,
                -0.06315699964761734,
                0.1930343210697174,
                -0.032546836882829666,
                -0.04127325117588043,
                -0.12818053364753723,
                -0.1505785882472992,
                0.12788622081279755,
                0.08315912634134293,
                -0.08657415211200714
            ],
            [
                0.1398254930973053,
                0.14063696563243866,
                -0.004430864937603474,
                0.07401037216186523,
                -0.05074658617377281,
                0.09631083905696869,
                -0.06766976416110992,
                -0.1409343034029007,
                0.1156127080321312,
                0.10845445841550827,
                -0.012917104177176952,
                0.050724808126688004,
                -0.05688561126589775,
                0.0962507352232933,
                -0.0031263637356460094,
                -0.08262670040130615
            ],
            [
                -0.02200171910226345,
                0.1794396936893463,
                0.3076666295528412,
                -0.029564566910266876,
                0.087270587682724,
                0.22845475375652313,
                -0.024851709604263306,
                -0.08011753857135773,
                -0.1051003560423851,
                0.16932310163974762,
                -0.14837293326854706,
                0.04094025492668152,
                0.07734379917383194,
                -0.032297007739543915,
                0.004978612065315247,
                0.07999119907617569
            ],
            [
                -0.05553615465760231,
                -0.035701122134923935,
                0.3115248680114746,
                -0.10656797885894775,
                -0.14603346586227417,
                0.2213430553674698,
                0.009451398625969887,
                -0.10104968398809433,
                -0.04550398513674736,
                0.025157317519187927,
                -0.06234727054834366,
                -0.07967846095561981,
                0.05890810117125511,
                -0.043856821954250336,
                0.08394966274499893,
                -0.09791678190231323
            ]
        ],
        [
            [
                5.100460529327393,
                4.206377983093262,
                3.182504653930664,
                3.0047919750213623,
                3.327162265777588,
                3.4744186401367188,
                2.3085625171661377,
                3.8313512802124023,
                4.147701740264893,
                4.146819114685059,
                3.353062152862549,
                2.407416343688965,
                2.8794307708740234,
                3.8579814434051514,
                4.592827796936035,
                5.196758270263672
            ],
            [
                3.8233704566955566,
                1.5955908298492432,
                1.2884109020233154,
                0.7250781059265137,
                3.1686859130859375,
                1.5302987098693848,
                1.985160231590271,
                2.3825087547302246,
                -0.10726231336593628,
                0.7836092710494995,
                1.1189401149749756,
                1.765199065208435,
                1.4407657384872437,
                3.744452714920044,
                -0.06716947257518768,
                4.092563629150391
            ],
            [
                3.4672694206237793,
                2.309413194656372,
                2.5051119327545166,
                3.3729088306427,
                2.5243685245513916,
                -0.16623619198799133,
                5.235180377960205,
                0.3932713568210602,
                2.3271355628967285,
                4.008060932159424,
                2.755727529525757,
                3.816554546356201,
                3.502262830734253,
                3.597691535949707,
                -0.024581054225564003,
                4.030267238616943
            ],
            [
                6.328515529632568,
                3.188565492630005,
                0.7115637063980103,
                0.6808900237083435,
                2.8027400970458984,
                -0.16687019169330597,
                2.5663836002349854,
                0.029403775930404663,
                3.564218044281006,
                2.996631145477295,
                1.4758862257003784,
                2.929011344909668,
                1.8926970958709717,
                3.3610079288482666,
                3.4451327323913574,
                3.2966742515563965
            ],
            [
                5.686117172241211,
                3.7766635417938232,
                1.0702271461486816,
                -0.052053019404411316,
                1.5307186841964722,
                1.895059585571289,
                3.4901106357574463,
                3.920626163482666,
                4.54962682723999,
                0.5258305072784424,
                1.3534762859344482,
                2.498323678970337,
                0.7583513259887695,
                1.0733956098556519,
                0.7173756957054138,
                3.8432228565216064
            ],
            [
                3.879910469055176,
                2.1895058155059814,
                2.958977460861206,
                0.2855309844017029,
                2.7746026515960693,
                2.0798141956329346,
                2.2612667083740234,
                4.2533955574035645,
                3.0634751319885254,
                3.138478994369507,
                1.4164721965789795,
                2.8510777950286865,
                0.7144370079040527,
                1.962357997894287,
                0.9252620935440063,
                2.1381349563598633
            ],
            [
                3.8348746299743652,
                0.7858094573020935,
                2.519822835922241,
                0.3457701802253723,
                1.7764784097671509,
                4.483702659606934,
                3.5645415782928467,
                3.8182668685913086,
                4.033684730529785,
                1.176941156387329,
                1.1471401453018188,
                0.7949268221855164,
                0.1350785344839096,
                3.540118455886841,
                4.880303382873535,
                5.963372230529785
            ],
            [
                4.030203342437744,
                1.747718334197998,
                0.943879246711731,
                -0.16185720264911652,
                2.2869021892547607,
                3.7545082569122314,
                3.2649641036987305,
                4.404423236846924,
                2.8881099224090576,
                0.2995690703392029,
                1.8777735233306885,
                2.149600028991699,
                0.5262424349784851,
                5.177544116973877,
                0.6586241722106934,
                2.4461090564727783
            ],
            [
                -0.17003673315048218,
                -0.14836862683296204,
                -0.06946433335542679,
                -0.05080696940422058,
                -0.0533980056643486,
                -0.12451931834220886,
                -0.13073384761810303,
                -0.15932412445545197,
                -0.05677466839551926,
                -0.08606036007404327,
                -0.07194392383098602,
                -0.09478624910116196,
                -0.09864326566457748,
                -0.030897706747055054,
                -0.02965233288705349,
                -0.12419599294662476
            ],
            [
                -0.14348161220550537,
                -0.04441722482442856,
                -0.05888115242123604,
                -0.1291828751564026,
                -0.09822201728820801,
                -0.07615784555673599,
                -0.09611400961875916,
                -0.15419267117977142,
                -0.11796177178621292,
                -0.1045776829123497,
                -0.12247290462255478,
                -0.08307957649230957,
                -0.0884866788983345,
                -0.07202945649623871,
                -0.05101833492517471,
                -0.16990545392036438
            ],
            [
                -0.14277470111846924,
                -0.03523973375558853,
                -0.07893233746290207,
                -0.13979820907115936,
                -0.04645055532455444,
                -0.0581977516412735,
                -0.06327708065509796,
                -0.12613826990127563,
                -0.12153375148773193,
                -0.09547882527112961,
                -0.09249061346054077,
                -0.03813650831580162,
                -0.15351873636245728,
                -0.10102570801973343,
                -0.13061895966529846,
                -0.1696016788482666
            ],
            [
                -0.16064906120300293,
                -0.02818804234266281,
                -0.031055908650159836,
                -0.04349646344780922,
                -0.051975417882204056,
                -0.05800718069076538,
                -0.07861647754907608,
                -0.04630877077579498,
                -0.06781183928251266,
                -0.04395298287272453,
                -0.07017365843057632,
                -0.06957364082336426,
                -0.04601428657770157,
                -0.05647074803709984,
                -0.028811145573854446,
                -0.11668599396944046
            ],
            [
                -0.16704635322093964,
                -0.07977356016635895,
                -0.08964786678552628,
                -0.017086924985051155,
                -0.07674555480480194,
                -0.1040119081735611,
                -0.11076346039772034,
                -0.13848251104354858,
                -0.059221889823675156,
                -0.044384315609931946,
                -0.10354076325893402,
                -0.169032022356987,
                -0.11497657746076584,
                -0.05195135250687599,
                -0.12904910743236542,
                -0.13093720376491547
            ],
            [
                -0.16535498201847076,
                -0.05229467526078224,
                -0.06395170837640762,
                -0.06490857899188995,
                -0.0697614774107933,
                -0.09072422981262207,
                -0.08219456672668457,
                -0.0763213261961937,
                -0.06924700736999512,
                -0.1244886964559555,
                -0.019513195380568504,
                -0.16589969396591187,
                -0.08815394341945648,
                -0.07652493566274643,
                -0.09891104698181152,
                -0.14192384481430054
            ],
            [
                -0.16344720125198364,
                -0.028738630935549736,
                -0.03191623464226723,
                -0.08294988423585892,
                -0.0771443322300911,
                -0.06831314414739609,
                -0.09862428158521652,
                -0.09424592554569244,
                -0.07478739321231842,
                -0.08936050534248352,
                -0.06397306174039841,
                -0.02178020216524601,
                -0.04960944503545761,
                -0.05907316133379936,
                -0.08362844586372375,
                -0.13031350076198578
            ],
            [
                -0.17003995180130005,
                -0.1631123423576355,
                -0.1564519703388214,
                -0.11992139369249344,
                -0.10797000676393509,
                -0.1456960141658783,
                -0.1434987187385559,
                -0.14677588641643524,
                -0.12048697471618652,
                -0.14214996993541718,
                -0.15054987370967865,
                -0.16622790694236755,
                -0.16214539110660553,
                -0.0797855332493782,
                -0.11477426439523697,
                -0.1496419459581375
            ]
        ],
        [
            [
                -0.16817711293697357,
                0.5906545519828796,
                -0.11249902844429016,
                -0.08518645167350769,
                -0.029047122225165367,
                -0.09730885177850723,
                -0.0068700285628438,
                -0.09524790197610855,
                0.4009268283843994,
                0.14904533326625824,
                0.17845375835895538,
                -0.11948500573635101,
                0.331246018409729,
                -0.0625436007976532,
                0.45046520233154297,
                0.6576632857322693
            ],
            [
                -0.006815849803388119,
                1.2725698947906494,
                0.020044686272740364,
                -0.07708528637886047,
                2.242278814315796,
                1.5419564247131348,
                0.44723209738731384,
                1.146178960800171,
                0.001223081024363637,
                -0.11932992935180664,
                -0.16815011203289032,
                -0.04786171764135361,
                -0.16356174647808075,
                2.4160995483398438,
                0.3473649322986603,
                0.5709243416786194
            ],
            [
                -0.14491182565689087,
                2.0562009811401367,
                2.0071065425872803,
                1.4360414743423462,
                2.4662978649139404,
                -0.14744704961776733,
                1.1753395795822144,
                -0.16004578769207,
                2.966588258743286,
                2.018087148666382,
                0.16558197140693665,
                -0.15664289891719818,
                1.8727219104766846,
                0.6918074488639832,
                -0.029812823981046677,
                0.6542937755584717
            ],
            [
                1.0352756977081299,
                1.0706453323364258,
                -0.15822218358516693,
                0.14402152597904205,
                0.7024737596511841,
                1.0537354946136475,
                0.6089351773262024,
                -0.016687583178281784,
                2.823915958404541,
                0.6915358304977417,
                -0.07598839700222015,
                0.1994573026895523,
                1.5068870782852173,
                -0.008751983754336834,
                2.6101043224334717,
                1.775587558746338
            ],
            [
                -0.026568438857793808,
                0.40758875012397766,
                1.1191684007644653,
                -0.034531936049461365,
                2.624680280685425,
                -0.03540061041712761,
                1.4174119234085083,
                3.3141376972198486,
                3.1307921409606934,
                0.1972917914390564,
                -0.15442970395088196,
                -0.14529141783714294,
                -0.1428370475769043,
                -0.134499654173851,
                0.5506441593170166,
                1.7087328433990479
            ],
            [
                0.13785302639007568,
                -0.05416186526417732,
                0.5985851883888245,
                -0.11369749903678894,
                1.5050069093704224,
                0.09532493352890015,
                1.0057635307312012,
                1.319800615310669,
                0.7627010941505432,
                1.989809513092041,
                -0.002233163919299841,
                0.4244018495082855,
                1.5007131099700928,
                1.2530845403671265,
                -0.07652929425239563,
                -0.15938438475131989
            ],
            [
                -0.009952394291758537,
                -0.026601998135447502,
                1.0538465976715088,
                -0.1649206429719925,
                -0.04593920335173607,
                0.06120073050260544,
                0.7756526470184326,
                0.6713271141052246,
                0.8909790515899658,
                0.4178919196128845,
                1.5610928535461426,
                0.6926030516624451,
                -0.05725879594683647,
                -0.025028059259057045,
                3.2651543617248535,
                2.045173168182373
            ],
            [
                -0.10750322043895721,
                0.03163598105311394,
                0.4635046720504761,
                -0.0664079487323761,
                1.5184718370437622,
                0.3757750988006592,
                0.9395659565925598,
                0.8687357306480408,
                1.5606838464736938,
                0.26302051544189453,
                0.7087482213973999,
                0.020549023523926735,
                -0.06479532271623611,
                0.2688124477863312,
                -0.11517657339572906,
                0.5504209995269775
            ],
            [
                0.5299403667449951,
                0.8384022116661072,
                0.9551355242729187,
                0.8507830500602722,
                1.0661646127700806,
                0.42348867654800415,
                0.31929153203964233,
                0.5873346328735352,
                0.6531782746315002,
                0.8173527717590332,
                0.7005605697631836,
                0.5413210391998291,
                0.25015315413475037,
                0.5345481634140015,
                0.3955121338367462,
                0.3293105959892273
            ],
            [
                0.5967798233032227,
                0.8055240511894226,
                0.8079330325126648,
                0.3199393153190613,
                0.31370919942855835,
                0.10048557817935944,
                0.37169885635375977,
                0.35691243410110474,
                0.5911478400230408,
                0.25056374073028564,
                0.18433620035648346,
                0.47631359100341797,
                0.11995750665664673,
                0.7266403436660767,
                0.9934825301170349,
                0.36425286531448364
            ],
            [
                0.6348681449890137,
                0.5959863066673279,
                0.33390012383461,
                0.46087905764579773,
                0.8381531834602356,
                0.5898002982139587,
                0.5237110257148743,
                0.35855236649513245,
                0.1775914877653122,
                0.41701740026474,
                0.48695820569992065,
                0.3115645945072174,
                0.0768250972032547,
                0.6024542450904846,
                0.42420345544815063,
                0.3999880254268646
            ],
            [
                0.38512057065963745,
                0.5905045866966248,
                0.25674882531166077,
                0.16492822766304016,
                0.4033694863319397,
                0.918170154094696,
                0.6025549173355103,
                0.7001537680625916,
                0.03463131561875343,
                0.7446566224098206,
                0.3083238899707794,
                0.18886131048202515,
                0.2659720182418823,
                0.39262840151786804,
                0.7359421849250793,
                1.0273464918136597
            ],
            [
                0.5883733630180359,
                0.8083162903785706,
                0.5589981079101562,
                0.9490926265716553,
                0.7880142331123352,
                0.35423147678375244,
                0.20092880725860596,
                0.634921133518219,
                0.656582772731781,
                0.4150756001472473,
                0.20223648846149445,
                0.3523704409599304,
                0.07312525063753128,
                0.23981505632400513,
                0.7363258004188538,
                0.8733989596366882
            ],
            [
                0.40133264660835266,
                0.6696176528930664,
                0.5144830942153931,
                0.3937534689903259,
                0.3411206305027008,
                0.45664456486701965,
                0.4357992112636566,
                0.02436750940978527,
                0.17964927852153778,
                0.24660636484622955,
                0.19840624928474426,
                0.8576270937919617,
                0.7024166584014893,
                0.10206407308578491,
                0.5299451351165771,
                0.39134716987609863
            ],
            [
                0.3963111340999603,
                0.2974352240562439,
                0.40020135045051575,
                0.4266516864299774,
                0.29458877444267273,
                0.11335111409425735,
                0.3669065833091736,
                0.36012399196624756,
                0.7862114310264587,
                0.3303564488887787,
                0.7421490550041199,
                0.30386126041412354,
                0.6248638033866882,
                0.3541586995124817,
                0.15285073220729828,
                0.052380818873643875
            ],
            [
                0.2966693937778473,
                0.3482499122619629,
                0.3107718229293823,
                0.5916152000427246,
                0.670982301235199,
                0.339192658662796,
                0.22272993624210358,
                0.2552676498889923,
                0.6778074502944946,
                0.41318902373313904,
                0.1064252257347107,
                0.4307539463043213,
                0.43201732635498047,
                0.6754083037376404,
                0.2891746163368225,
                0.13867567479610443
            ]
        ],
        [
            [
                -0.002177819376811385,
                -0.08329103887081146,
                -0.040773507207632065,
                -0.05578123778104782,
                -0.057042382657527924,
                -0.0008572502410970628,
                -0.008450116962194443,
                -0.0019918170291930437,
                -0.00017095822840929031,
                -0.033967483788728714,
                -0.061956971883773804,
                -0.07222949713468552,
                -0.045504190027713776,
                -0.0031215024646371603,
                -0.00006328762538032606,
                -1.4785841528919264e-7
            ],
            [
                -0.051132332533597946,
                -0.002247127704322338,
                -0.04487048089504242,
                -0.003774940734729171,
                -0.0001431751879863441,
                -0.0011072475463151932,
                -0.00006283033144427463,
                -0.001849982189014554,
                -0.058888960629701614,
                -0.01061332132667303,
                -0.018732907250523567,
                -0.004804201889783144,
                -0.047301098704338074,
                -0.0009294442716054618,
                -0.09745531529188156,
                -0.007625986821949482
            ],
            [
                -0.04336632415652275,
                -0.013916212134063244,
                -0.01651298999786377,
                -0.16328246891498566,
                -0.0612015463411808,
                -0.015583555214107037,
                -0.00002159740870411042,
                -0.0009805187582969666,
                -0.06869564950466156,
                -0.0019481537165120244,
                -0.0007506359834223986,
                -0.01407373696565628,
                -0.001652521314099431,
                0,
                -0.010273785330355167,
                -0.012857629917562008
            ],
            [
                -0.00004593586709233932,
                -0.007064631674438715,
                -0.07932115346193314,
                -0.12009438872337341,
                -0.09193098545074463,
                -0.00039541401201859117,
                -0.0028642877005040646,
                -0.05496542900800705,
                -0.0003880501608364284,
                -0.01295926421880722,
                -0.03528163954615593,
                -0.001945188152603805,
                -0.003338308073580265,
                -0.00074803177267313,
                0,
                -0.00005860380406375043
            ],
            [
                -0.000010052721336251125,
                -0.0005503048887476325,
                -0.12956802546977997,
                -0.16683627665042877,
                -0.12498379498720169,
                -0.007035823538899422,
                -0.00002639919512148481,
                -1.5170510891948652e-7,
                -0.00040710237226448953,
                -0.05983138456940651,
                -0.008410018868744373,
                -0.024794507771730423,
                -0.002445848425850272,
                -0.0622921958565712,
                -0.017934199422597885,
                -0.0002662089536897838
            ],
            [
                -0.003988498356193304,
                -0.036043159663677216,
                -0.005291357636451721,
                -0.017025699838995934,
                -0.0014646020717918873,
                -0.000634011288639158,
                -0.019126391038298607,
                -0.00026787532260641456,
                -0.006951360497623682,
                -0.005113339517265558,
                -0.0017080893740057945,
                -1.6076778308615758e-7,
                -0.09234602749347687,
                -0.08363671600818634,
                -0.0071206046268343925,
                -0.0005814614705741405
            ],
            [
                -0.1336696594953537,
                -0.043805524706840515,
                -0.0009817202808335423,
                -0.024358633905649185,
                -0.004920478444546461,
                -0.0004189500177744776,
                -0.0008520400151610374,
                -0.0017348334658890963,
                -0.00000663625587549177,
                -0.008487701416015625,
                -0.0010625047143548727,
                -0.009751658886671066,
                -0.017350871115922928,
                -0.01232121791690588,
                -1.7742188163083483e-7,
                -4.4145150468466454e-7
            ],
            [
                -0.0013552106684073806,
                -0.0294911190867424,
                0,
                -0.00004009762415080331,
                -0.07561352103948593,
                -0.0007681113784201443,
                -1.5072502890234318e-7,
                -0.0006671254523098469,
                -0.00007620685937581584,
                -0.00025320149143226445,
                -0.0012687318958342075,
                -0.00032526228460483253,
                -0.0062208701856434345,
                -0.0002499799884390086,
                -0.000034810338547686115,
                -0.0027411647606641054
            ],
            [
                2.2190043926239014,
                2.897320508956909,
                3.0217082500457764,
                2.6947314739227295,
                2.881185531616211,
                2.3587474822998047,
                2.0570032596588135,
                2.0047223567962646,
                2.303828001022339,
                2.403067111968994,
                2.518777847290039,
                2.0811476707458496,
                1.8321802616119385,
                2.647155523300171,
                2.5813345909118652,
                2.297822952270508
            ],
            [
                2.3505444526672363,
                2.453357219696045,
                2.5721163749694824,
                2.3045284748077393,
                2.530808448791504,
                2.308061122894287,
                1.8577308654785156,
                2.1903131008148193,
                2.5840203762054443,
                1.7423266172409058,
                2.532660484313965,
                2.315577507019043,
                2.13362193107605,
                2.4429028034210205,
                2.9866786003112793,
                2.3989357948303223
            ],
            [
                2.3646514415740967,
                2.940192937850952,
                2.007617473602295,
                2.6594817638397217,
                2.888408660888672,
                2.5981757640838623,
                1.7467129230499268,
                1.8950461149215698,
                1.3004350662231445,
                2.025635004043579,
                2.28277325630188,
                2.493389844894409,
                1.8228468894958496,
                2.622504711151123,
                2.338062286376953,
                1.3023288249969482
            ],
            [
                2.2780213356018066,
                2.6040053367614746,
                2.6553592681884766,
                2.0502967834472656,
                2.3436179161071777,
                2.055312156677246,
                2.252690315246582,
                2.6081457138061523,
                2.1867566108703613,
                2.301107168197632,
                2.0850870609283447,
                2.5831055641174316,
                2.5840766429901123,
                2.4217870235443115,
                2.793776750564575,
                3.0575520992279053
            ],
            [
                2.074928045272827,
                2.2404375076293945,
                2.64365553855896,
                3.0692107677459717,
                2.3559348583221436,
                1.7835054397583008,
                1.6659185886383057,
                2.2768476009368896,
                2.3336219787597656,
                2.4627764225006104,
                1.9799139499664307,
                2.475829601287842,
                2.315122365951538,
                1.9853862524032593,
                2.8239831924438477,
                2.978217840194702
            ],
            [
                1.6753044128417969,
                2.4292266368865967,
                2.7860422134399414,
                2.326671600341797,
                2.3569939136505127,
                1.8633689880371094,
                1.7773373126983643,
                1.6808899641036987,
                1.737980604171753,
                2.214803695678711,
                3.0271201133728027,
                3.154324531555176,
                2.0808892250061035,
                2.797680616378784,
                2.3465423583984375,
                1.5493849515914917
            ],
            [
                1.89337956905365,
                2.146254777908325,
                2.667865037918091,
                2.296251058578491,
                2.788041591644287,
                2.2694733142852783,
                1.9866571426391602,
                2.295133352279663,
                2.5501818656921387,
                2.0702593326568604,
                2.752939462661743,
                3.054593086242676,
                2.6724932193756104,
                2.62587833404541,
                2.236666202545166,
                1.7374013662338257
            ],
            [
                1.7376151084899902,
                1.715360403060913,
                2.214855194091797,
                3.0091054439544678,
                2.109128952026367,
                2.2694170475006104,
                2.3714802265167236,
                1.9837017059326172,
                2.4000601768493652,
                2.371795892715454,
                2.0105996131896973,
                2.3484725952148438,
                1.9739153385162354,
                2.846982002258301,
                2.5094683170318604,
                1.8225672245025635
            ]
        ],
        [
            [
                1.7227215766906738,
                0.2478751242160797,
                1.5465352535247803,
                0.3603989779949188,
                0.782497763633728,
                1.394115924835205,
                0.16710126399993896,
                -0.15728351473808289,
                1.566636562347412,
                1.4933160543441772,
                1.7270903587341309,
                2.3582491874694824,
                3.4156558513641357,
                1.1556181907653809,
                4.567724227905273,
                -0.1517171859741211
            ],
            [
                2.821653127670288,
                2.645144462585449,
                1.162091612815857,
                3.2516326904296875,
                1.4387325048446655,
                3.2134218215942383,
                2.3017706871032715,
                1.5939881801605225,
                2.6395585536956787,
                -0.12026222795248032,
                -0.023009052500128746,
                2.092003107070923,
                1.8353633880615234,
                3.1381285190582275,
                2.1272144317626953,
                -0.09017781913280487
            ],
            [
                0.00004202409400022589,
                4.039003372192383,
                3.225189685821533,
                2.580780029296875,
                3.3193225860595703,
                1.444152593612671,
                2.4055747985839844,
                2.667039632797241,
                3.451977014541626,
                -0.07408501952886581,
                -0.060365043580532074,
                0.600040853023529,
                2.439103126525879,
                2.8677940368652344,
                -0.13834433257579803,
                3.0463545322418213
            ],
            [
                1.5361287593841553,
                0.48976895213127136,
                1.7874013185501099,
                3.4064581394195557,
                3.6417503356933594,
                4.681948661804199,
                2.4244272708892822,
                -0.06768017262220383,
                0.6733788251876831,
                -0.15077149868011475,
                0.21575163304805756,
                0.8805139064788818,
                -0.047890033572912216,
                3.271951198577881,
                3.6775012016296387,
                -0.16882692277431488
            ],
            [
                1.5491257905960083,
                2.7684247493743896,
                2.360377311706543,
                0.44040900468826294,
                2.0058319568634033,
                1.6580040454864502,
                1.4173533916473389,
                2.8714680671691895,
                2.0079712867736816,
                0.23886047303676605,
                -0.018468402326107025,
                0.9400738477706909,
                0.5937427878379822,
                2.182663679122925,
                2.456324577331543,
                0.26815539598464966
            ],
            [
                1.4035261869430542,
                2.0602917671203613,
                2.1701784133911133,
                0.6463820338249207,
                1.774264931678772,
                4.094882965087891,
                1.7868643999099731,
                2.3725595474243164,
                0.4125862121582031,
                0.6067048907279968,
                0.3184211254119873,
                4.607627868652344,
                2.615006685256958,
                1.9459079504013062,
                2.035255193710327,
                -0.1320403516292572
            ],
            [
                2.0292742252349854,
                0.7638568878173828,
                2.999022960662842,
                1.5894793272018433,
                2.6138408184051514,
                2.617140531539917,
                1.2520239353179932,
                0.5759215354919434,
                1.9168598651885986,
                1.4399319887161255,
                1.8350987434387207,
                4.545603275299072,
                0.16295132040977478,
                3.7547435760498047,
                2.1673073768615723,
                3.1260628700256348
            ],
            [
                0.22643162310123444,
                2.449352741241455,
                4.139931678771973,
                3.01298451423645,
                1.6706846952438354,
                1.3061665296554565,
                2.6631619930267334,
                1.1070131063461304,
                2.3655223846435547,
                -0.06419236958026886,
                1.2069469690322876,
                1.419694185256958,
                2.280809164047241,
                3.436079978942871,
                -0.08920780569314957,
                -0.13307487964630127
            ],
            [
                -0.11524416506290436,
                0.029913559556007385,
                0.3163549602031708,
                0.22721439599990845,
                0.19262103736400604,
                0.0031841376330703497,
                0.07563554495573044,
                -0.07980149239301682,
                0.11102599650621414,
                0.18423275649547577,
                0.6063007116317749,
                -0.05796654894948006,
                0.12589696049690247,
                0.26142096519470215,
                0.023240188136696815,
                -0.06695496290922165
            ],
            [
                -0.09531200677156448,
                0.1792587786912918,
                0.27245837450027466,
                0.030679285526275635,
                0.11806360632181168,
                -0.0007811411051079631,
                0.015248119831085205,
                0.44349533319473267,
                -0.035305313766002655,
                0.1012941524386406,
                0.2942897379398346,
                -0.08586270362138748,
                0.09868548065423965,
                0.5001071095466614,
                0.3134472966194153,
                -0.008968357928097248
            ],
            [
                -0.05338801443576813,
                0.12720684707164764,
                0.12407218664884567,
                0.4233388900756836,
                0.17701704800128937,
                0.1353815793991089,
                0.1290885955095291,
                0.9016757011413574,
                0.40270280838012695,
                0.10456172376871109,
                0.49642133712768555,
                -0.06379258632659912,
                0.07392270117998123,
                0.03472321107983589,
                -0.06276142597198486,
                0.4586174190044403
            ],
            [
                -0.01066182367503643,
                0.27768638730049133,
                -0.06994757056236267,
                0.1747901439666748,
                0.08695150911808014,
                0.7362970113754272,
                0.3665907084941864,
                -0.053003109991550446,
                0.08823435753583908,
                0.4834352731704712,
                -0.038981448858976364,
                0.25380903482437134,
                -0.054176364094018936,
                0.03351472318172455,
                -0.03053773194551468,
                0.542211651802063
            ],
            [
                0.11718650907278061,
                0.16613386571407318,
                0.016915105283260345,
                0.5240064263343811,
                0.40081658959388733,
                0.28089436888694763,
                0.14105330407619476,
                0.21894215047359467,
                0.3204154372215271,
                0.08148601651191711,
                0.30386003851890564,
                0.02760854922235012,
                0.044605132192373276,
                0.13281364738941193,
                0.06641823798418045,
                0.19327017664909363
            ],
            [
                -0.014862465672194958,
                0.17855863273143768,
                0.278566837310791,
                0.20058660209178925,
                0.3189353048801422,
                0.29264575242996216,
                0.43789538741111755,
                0.11295948922634125,
                0.13762913644313812,
                -0.001779718091711402,
                -0.13364262878894806,
                -0.008790791966021061,
                -0.0034898521844297647,
                0.07955393940210342,
                0.16419333219528198,
                0.3509984314441681
            ],
            [
                0.20356003940105438,
                0.10712886601686478,
                0.014664827845990658,
                -0.02881339192390442,
                0.0787048265337944,
                0.3440146744251251,
                0.013781068846583366,
                0.06219445541501045,
                0.0783359706401825,
                -0.13038726150989532,
                -0.04578002169728279,
                -0.04376666620373726,
                0.12757040560245514,
                -0.010074676014482975,
                0.20555472373962402,
                0.4294002056121826
            ],
            [
                0.2410842627286911,
                -0.06021971255540848,
                0.12583452463150024,
                -0.10025201737880707,
                0.05808901786804199,
                0.29635146260261536,
                -0.03170044720172882,
                -0.05008405074477196,
                0.06125803291797638,
                0.02255750633776188,
                -0.0755133256316185,
                0.23956505954265594,
                0.24249809980392456,
                -0.0709722712635994,
                -0.06491579860448837,
                0.36518338322639465
            ]
        ],
        [
            [
                4.498764514923096,
                6.411316394805908,
                6.9892425537109375,
                7.186699867248535,
                7.3053202629089355,
                9.011967658996582,
                6.081450462341309,
                7.205316543579102,
                6.257456302642822,
                6.207587718963623,
                7.923787593841553,
                7.206702709197998,
                6.400938510894775,
                7.479650497436523,
                3.8331425189971924,
                8.225728988647461
            ],
            [
                7.683058261871338,
                4.155392169952393,
                8.60177993774414,
                10.429945945739746,
                7.632087230682373,
                4.710195064544678,
                3.712704658508301,
                7.875333309173584,
                7.447340488433838,
                8.458002090454102,
                8.532163619995117,
                7.568929672241211,
                6.856082439422607,
                5.138458251953125,
                9.126859664916992,
                7.28397274017334
            ],
            [
                6.267635822296143,
                5.9204206466674805,
                3.9458067417144775,
                7.108240127563477,
                7.049933433532715,
                4.200189590454102,
                5.184503555297852,
                9.975312232971191,
                8.235126495361328,
                5.245489120483398,
                8.041796684265137,
                6.452677249908447,
                5.190098762512207,
                4.731177806854248,
                9.679303169250488,
                4.433465957641602
            ],
            [
                4.563599109649658,
                6.778244495391846,
                7.630488395690918,
                6.930386066436768,
                8.91139030456543,
                8.965566635131836,
                5.554242134094238,
                8.123181343078613,
                4.909998416900635,
                9.95775318145752,
                8.175515174865723,
                8.721131324768066,
                7.185680866241455,
                6.407436370849609,
                5.936890602111816,
                4.3232622146606445
            ],
            [
                4.4627299308776855,
                3.095879316329956,
                5.366358757019043,
                9.33270263671875,
                8.849652290344238,
                6.7074456214904785,
                5.242530822753906,
                4.405661106109619,
                6.757655143737793,
                6.606742858886719,
                7.2317352294921875,
                4.238428115844727,
                6.254260540008545,
                7.63961124420166,
                5.155395984649658,
                3.7621164321899414
            ],
            [
                7.3520588874816895,
                5.968318939208984,
                6.1540422439575195,
                10.138779640197754,
                6.133049964904785,
                5.327104568481445,
                6.175702095031738,
                4.415596961975098,
                7.7760114669799805,
                9.557843208312988,
                6.229281902313232,
                6.668213844299316,
                6.767241954803467,
                8.176708221435547,
                4.944318771362305,
                6.3826446533203125
            ],
            [
                7.270284652709961,
                8.493178367614746,
                3.3967318534851074,
                5.552127838134766,
                5.362699508666992,
                3.7664191722869873,
                4.514771461486816,
                4.534778118133545,
                5.447121620178223,
                6.595643520355225,
                5.297677993774414,
                5.871748924255371,
                6.341310501098633,
                6.897418022155762,
                6.290454864501953,
                5.280755519866943
            ],
            [
                6.237545013427734,
                6.442845344543457,
                4.147663116455078,
                7.5182976722717285,
                6.647428512573242,
                4.895906448364258,
                3.146068572998047,
                5.033346652984619,
                6.5217084884643555,
                7.325740337371826,
                8.865277290344238,
                9.25549602508545,
                8.07129192352295,
                9.169050216674805,
                10.942976951599121,
                8.093671798706055
            ],
            [
                0.46539703011512756,
                -0.133425772190094,
                -0.14684408903121948,
                0.19227896630764008,
                0.06648684293031693,
                -0.1010594293475151,
                0.6047652363777161,
                0.0054649971425533295,
                -0.1380564570426941,
                -0.062418464571237564,
                0.0764535591006279,
                0.13575518131256104,
                0.11013875901699066,
                0.11387209594249725,
                0.13708384335041046,
                0.12578590214252472
            ],
            [
                0.32222217321395874,
                0.2778644263744354,
                -0.052276451140642166,
                0.15116672217845917,
                -0.04223588481545448,
                -0.04400889202952385,
                0.5346318483352661,
                0.12320689857006073,
                0.13115206360816956,
                0.3247038722038269,
                0.04839476943016052,
                0.2594680190086365,
                0.42543667554855347,
                0.27876511216163635,
                0.20479728281497955,
                0.49436017870903015
            ],
            [
                0.48095884919166565,
                0.23303008079528809,
                0.15806825459003448,
                0.12158776819705963,
                -0.0822456032037735,
                0.008162081241607666,
                0.37545278668403625,
                0.0025122242514044046,
                0.0860142707824707,
                0.0709008127450943,
                -0.004616843536496162,
                0.3661063611507416,
                0.3050306737422943,
                0.29148226976394653,
                0.3730449080467224,
                0.23024782538414001
            ],
            [
                0.5589352250099182,
                0.48524177074432373,
                0.3552042841911316,
                -0.1695062518119812,
                -0.16836848855018616,
                -0.0257556214928627,
                0.30282077193260193,
                0.24990399181842804,
                0.2974371016025543,
                0.1684178113937378,
                0.21249325573444366,
                0.08989714086055756,
                0.45255160331726074,
                0.3749707043170929,
                0.10466846823692322,
                -0.017280366271734238
            ],
            [
                0.9917395710945129,
                0.5294289588928223,
                0.2511235475540161,
                0.3138993978500366,
                -0.12951920926570892,
                0.12055493891239166,
                0.3595006465911865,
                0.07259903848171234,
                0.3478020429611206,
                0.22289057075977325,
                0.020852338522672653,
                0.00022213577176444232,
                0.38991665840148926,
                0.26701226830482483,
                -0.1582196205854416,
                -0.1700083613395691
            ],
            [
                0.6736672520637512,
                0.2989347577095032,
                -0.15691426396369934,
                0.33304816484451294,
                -0.03109823167324066,
                0.1344175487756729,
                0.3074713945388794,
                0.2318044751882553,
                0.25605377554893494,
                0.22055640816688538,
                0.04663112014532089,
                -0.13353513181209564,
                -0.020136401057243347,
                0.14250867068767548,
                0.07008649408817291,
                -0.08920758962631226
            ],
            [
                0.3681322932243347,
                0.19314692914485931,
                0.3101970851421356,
                -0.07447078824043274,
                0.1746603399515152,
                0.056019287556409836,
                0.14702513813972473,
                0.1450851708650589,
                0.374074250459671,
                0.022820057347416878,
                -0.057132065296173096,
                0.21163305640220642,
                0.4398808777332306,
                -0.033552274107933044,
                -0.07320819795131683,
                -0.09488331526517868
            ],
            [
                0.37204718589782715,
                0.4235672652721405,
                0.4043436646461487,
                0.3091557025909424,
                0.3937598764896393,
                0.13923577964305878,
                0.3018219470977783,
                0.5268951654434204,
                0.8699180483818054,
                0.34795403480529785,
                0.8454440832138062,
                0.36428868770599365,
                0.29840049147605896,
                0.20404107868671417,
                0.4086461663246155,
                -0.05301271378993988
            ]
        ],
        [
            [
                2.9076077938079834,
                3.182826042175293,
                0.9936445951461792,
                2.23332142829895,
                1.7738462686538696,
                2.609694719314575,
                2.0864720344543457,
                4.698794841766357,
                2.8145549297332764,
                0.959445059299469,
                1.7021944522857666,
                2.125107765197754,
                2.6971893310546875,
                1.3690019845962524,
                -0.1252843737602234,
                3.1094565391540527
            ],
            [
                0.7833656072616577,
                -0.061987753957509995,
                0.19461281597614288,
                0.1808055192232132,
                2.3071322441101074,
                3.1615166664123535,
                2.013162136077881,
                0.4185347259044647,
                0.07366811484098434,
                2.814131259918213,
                3.153986930847168,
                3.792724132537842,
                2.1155025959014893,
                0.14861726760864258,
                3.3976731300354004,
                3.0910487174987793
            ],
            [
                3.156473398208618,
                -0.1691071093082428,
                0.5077018141746521,
                0.03578506410121918,
                0.6067812442779541,
                3.5460057258605957,
                3.260646343231201,
                2.0837035179138184,
                -0.15827089548110962,
                2.3269994258880615,
                3.717925548553467,
                1.0846669673919678,
                0.36960020661354065,
                -0.1062154546380043,
                1.2010235786437988,
                -0.03236723318696022
            ],
            [
                2.600292205810547,
                1.5640642642974854,
                3.6999528408050537,
                -0.14502385258674622,
                -0.019675564020872116,
                -0.16872826218605042,
                1.506978988647461,
                4.584287166595459,
                0.17566204071044922,
                2.286925792694092,
                4.097976207733154,
                1.7739213705062866,
                1.813097596168518,
                1.6506783962249756,
                2.804702043533325,
                3.3674638271331787
            ],
            [
                1.7612873315811157,
                3.6557061672210693,
                -0.002240339294075966,
                3.0991601943969727,
                -0.11350788921117783,
                -0.08021332323551178,
                1.12201988697052,
                -0.13607028126716614,
                0.8432945013046265,
                -0.07399528473615646,
                2.7558095455169678,
                0.9598569273948669,
                2.7451746463775635,
                0.7246063947677612,
                0.206913560628891,
                3.728681802749634
            ],
            [
                1.6867891550064087,
                2.2110395431518555,
                0.9925681948661804,
                2.000117301940918,
                0.24654731154441833,
                2.9758729934692383,
                2.395623207092285,
                1.8928656578063965,
                2.1336193084716797,
                3.6040596961975098,
                0.9749833345413208,
                1.5001834630966187,
                2.1860339641571045,
                1.2957690954208374,
                0.25847840309143066,
                3.9833011627197266
            ],
            [
                3.088275909423828,
                2.5069565773010254,
                1.3714464902877808,
                1.8557242155075073,
                1.1740524768829346,
                3.4438860416412354,
                3.0841495990753174,
                4.773050785064697,
                2.459052085876465,
                0.5290354490280151,
                -0.005144425667822361,
                0.14993859827518463,
                0.4540964365005493,
                1.399852991104126,
                5.159934043884277,
                1.6453003883361816
            ],
            [
                4.191858291625977,
                2.3758857250213623,
                -0.08386651426553726,
                -0.0690649151802063,
                3.895993709564209,
                5.391260147094727,
                2.6849606037139893,
                3.213578224182129,
                2.4219298362731934,
                4.16276216506958,
                5.238887310028076,
                3.7474915981292725,
                0.2844051718711853,
                0.618877649307251,
                3.955376625061035,
                5.240789413452148
            ],
            [
                -0.1266646385192871,
                -0.06538903713226318,
                0.057754214853048325,
                -0.0711447224020958,
                -0.09730688482522964,
                -0.09987352788448334,
                -0.11488397419452667,
                0.07747875899076462,
                -0.12377039343118668,
                0.11556059122085571,
                -0.08541665226221085,
                -0.12986038625240326,
                -0.10762331634759903,
                -0.1679040640592575,
                -0.16760744154453278,
                0.15096303820610046
            ],
            [
                -0.12091369926929474,
                -0.03538178279995918,
                0.0074923597276210785,
                -0.060139577835798264,
                -0.06809431314468384,
                -0.13142311573028564,
                -0.06287292391061783,
                -0.024757087230682373,
                -0.07334835827350616,
                -0.011089716106653214,
                -0.05338704213500023,
                -0.10464246571063995,
                -0.10797078907489777,
                -0.10421447455883026,
                -0.02325376681983471,
                0.18853969871997833
            ],
            [
                -0.109420545399189,
                -0.10503397136926651,
                -0.08561743050813675,
                -0.05275808647274971,
                -0.055692560970783234,
                -0.0234279315918684,
                0.1496659368276596,
                -0.0770966187119484,
                -0.11223286390304565,
                -0.047002241015434265,
                -0.05444153770804405,
                -0.047970641404390335,
                -0.01444927603006363,
                -0.020957330241799355,
                -0.010449736379086971,
                0.11853436380624771
            ],
            [
                -0.08512219041585922,
                -0.11165709048509598,
                -0.12754036486148834,
                -0.05726730823516846,
                0.013112957589328289,
                -0.007628497201949358,
                0.02275877073407173,
                -0.05305856093764305,
                -0.12323933839797974,
                -0.10538513213396072,
                0.02750987559556961,
                -0.14991119503974915,
                -0.13145461678504944,
                0.04941873252391815,
                0.12217144668102264,
                0.2671465575695038
            ],
            [
                0.006021726410835981,
                -0.04590318351984024,
                0.015743259340524673,
                -0.11305323243141174,
                -0.03173140436410904,
                -0.06329345703125,
                -0.10405024141073227,
                0.03946368768811226,
                -0.08411284536123276,
                -0.13713866472244263,
                -0.09628540277481079,
                -0.04004925116896629,
                -0.0005601248703896999,
                -0.10053565353155136,
                0.025698788464069366,
                0.3987703025341034
            ],
            [
                -0.03281848505139351,
                -0.11312146484851837,
                -0.06322462856769562,
                -0.03523416444659233,
                -0.13968712091445923,
                -0.13372357189655304,
                -0.1267622858285904,
                -0.0852777510881424,
                -0.10487615317106247,
                -0.13654154539108276,
                0.006432644557207823,
                0.007121365517377853,
                -0.008828690275549889,
                -0.12385117262601852,
                -0.06776753813028336,
                0.07772934436798096
            ],
            [
                -0.1103539764881134,
                -0.1105753481388092,
                -0.09760016947984695,
                0.03376733139157295,
                -0.020439857617020607,
                -0.08202451467514038,
                -0.014245113357901573,
                -0.059617482125759125,
                -0.12588395178318024,
                -0.07718434184789658,
                -0.15534992516040802,
                -0.01818726770579815,
                -0.13746371865272522,
                -0.09063568711280823,
                -0.09651739150285721,
                -0.004594278987497091
            ],
            [
                -0.008303496986627579,
                0.06416349112987518,
                0.03610057756304741,
                -0.009525936096906662,
                -0.060830213129520416,
                0.04068482667207718,
                0.05869592726230621,
                0.05422072112560272,
                0.04367562383413315,
                -0.04936754330992699,
                -0.07481908798217773,
                -0.023506982252001762,
                0.01821391098201275,
                0.04124992713332176,
                -0.09974198788404465,
                0.12402739375829697
            ]
        ],
        [
            [
                -0.02281288057565689,
                -0.0036379145458340645,
                -0.1295475959777832,
                -0.0023691996466368437,
                -0.13224320113658905,
                -0.16836540400981903,
                -0.058184050023555756,
                -0.00169270986225456,
                -0.13639295101165771,
                -0.13953475654125214,
                -0.1291087567806244,
                -0.06980598717927933,
                -0.08781827241182327,
                -0.0316658541560173,
                -0.10533256083726883,
                -0.045989565551280975
            ],
            [
                -0.13688039779663086,
                -0.003675367683172226,
                -0.0016556221526116133,
                -0.01661154255270958,
                -0.018440039828419685,
                -0.007744581904262304,
                -0.009099231101572514,
                -0.006878092885017395,
                -0.016725394874811172,
                -0.0005441077519208193,
                -0.001917731249704957,
                -0.0075023360550403595,
                -0.011874980293214321,
                -0.06285585463047028,
                -0.062166910618543625,
                -0.04700610414147377
            ],
            [
                -0.0012972025433555245,
                -0.014580599032342434,
                -0.1693331003189087,
                -0.01588422618806362,
                -0.16959017515182495,
                -0.005473273806273937,
                -0.1280488669872284,
                -0.005830077920109034,
                -0.13773135840892792,
                -0.00033976242411881685,
                -0.00004056250327266753,
                -0.04493186995387077,
                -0.02248332090675831,
                -0.024992922320961952,
                0.08170659095048904,
                -0.1690981090068817
            ],
            [
                -0.0022435325663536787,
                -0.08672437816858292,
                -0.1665721982717514,
                -0.030296500772237778,
                -0.16983956098556519,
                -0.13881543278694153,
                -0.036299556493759155,
                -0.0007251440547406673,
                -0.02089635655283928,
                -0.008486575447022915,
                -0.0976327434182167,
                -0.015385936014354229,
                -0.0027527923230081797,
                -0.06885980814695358,
                -0.0014750950504094362,
                -0.0022786546032875776
            ],
            [
                -0.0002558051492087543,
                -0.08044400066137314,
                -0.05789073184132576,
                -0.0020235157571733,
                -0.02884085476398468,
                -0.0023816507309675217,
                -0.000004321190317568835,
                -0.00291849160566926,
                -0.007575786206871271,
                -0.10957738012075424,
                -0.0011326083913445473,
                -0.02861337549984455,
                -0.02492082118988037,
                -0.16860541701316833,
                -0.06257537007331848,
                -0.006336130667477846
            ],
            [
                -0.012555420398712158,
                -0.028154565021395683,
                -0.012763277627527714,
                -0.004833771847188473,
                -0.03475577384233475,
                -0.05134451389312744,
                -0.15705610811710358,
                -0.004990192595869303,
                -0.0014015429187566042,
                -0.0012274947948753834,
                -0.015751926228404045,
                -0.02804233878850937,
                -0.12609371542930603,
                -0.005588126368820667,
                -0.00023858249187469482,
                -0.003772448981180787
            ],
            [
                -0.07191649824380875,
                -0.06250261515378952,
                -0.16202843189239502,
                -0.0958557277917862,
                -0.0003438341955188662,
                -0.14529862999916077,
                -0.014552873559296131,
                -0.005678924266248941,
                -0.027655288577079773,
                -0.004544279538094997,
                -0.040413375943899155,
                -0.025293299928307533,
                -0.0009370255866087973,
                -0.08648112416267395,
                -0.02789313532412052,
                -0.011848021298646927
            ],
            [
                -0.00023124921426642686,
                -0.053779423236846924,
                -0.00029486414860002697,
                -0.0989941656589508,
                -0.009216878563165665,
                -0.020688243210315704,
                -0.0043400865979492664,
                -0.0016585573321208358,
                -0.15902604162693024,
                -0.00014740136975888163,
                -0.002099868142977357,
                -0.005705887917429209,
                -0.16505946218967438,
                -0.0007939156494103372,
                -0.13083678483963013,
                -0.00045625478378497064
            ],
            [
                0.18709833920001984,
                0.498782217502594,
                0.9083665609359741,
                0.40012088418006897,
                0.4915041923522949,
                -0.05743983015418053,
                0.10286016762256622,
                0.22922061383724213,
                1.0626102685928345,
                0.41136935353279114,
                0.4899293780326843,
                0.5095200538635254,
                0.38888534903526306,
                0.22101324796676636,
                0.033010274171829224,
                -0.011632848531007767
            ],
            [
                0.1820196509361267,
                0.42194369435310364,
                0.5059570074081421,
                0.1035616472363472,
                0.08522728830575943,
                -0.07772819697856903,
                0.22277309000492096,
                0.7018909454345703,
                -0.00187304662540555,
                0.16255511343479156,
                0.2345961183309555,
                -0.07451663166284561,
                -0.07398621737957001,
                0.018635008484125137,
                0.38207006454467773,
                0.1660916805267334
            ],
            [
                0.11290188878774643,
                0.15528655052185059,
                0.20330952107906342,
                0.3172229528427124,
                0.11543243378400803,
                0.08887691795825958,
                0.6672723293304443,
                0.6382108330726624,
                -0.02158481813967228,
                0.5804662108421326,
                0.3321094512939453,
                -0.007341271266341209,
                -0.14077448844909668,
                0.0983155369758606,
                -0.08695652335882187,
                0.36554673314094543
            ],
            [
                0.02146279439330101,
                0.5882323980331421,
                -0.06667585670948029,
                0.16222301125526428,
                0.5428277850151062,
                0.3440104126930237,
                0.388163685798645,
                0.03365568071603775,
                -0.11005547642707825,
                0.4408511519432068,
                -0.08027850836515427,
                0.21062038838863373,
                -0.10593803972005844,
                0.005404094234108925,
                0.5367384552955627,
                0.7626535296440125
            ],
            [
                -0.04630989581346512,
                0.12387949228286743,
                -0.051676079630851746,
                0.6274456977844238,
                0.4992799758911133,
                -0.06975915282964706,
                0.2679460644721985,
                0.008306197822093964,
                0.3295007348060608,
                0.16853441298007965,
                0.26855263113975525,
                0.11184120178222656,
                0.16157309710979462,
                0.017464471980929375,
                0.8861737251281738,
                0.5011624693870544
            ],
            [
                0.2588033974170685,
                0.4052986204624176,
                0.32852408289909363,
                0.00917570386081934,
                0.8175862431526184,
                0.1573852300643921,
                0.3112182319164276,
                -0.13558855652809143,
                0.12499585747718811,
                -0.09095950424671173,
                -0.10973240435123444,
                0.2816876173019409,
                0.08646869659423828,
                -0.1018453910946846,
                0.28269127011299133,
                0.3219238817691803
            ],
            [
                0.07338158786296844,
                0.17915917932987213,
                0.4739468991756439,
                0.05156167596578598,
                0.5026559829711914,
                0.008212951943278313,
                -0.048073217272758484,
                0.2402782142162323,
                0.2834298610687256,
                0.15742628276348114,
                0.22596730291843414,
                0.014387940056622028,
                0.09985705465078354,
                0.012660600244998932,
                0.16716860234737396,
                -0.007739006541669369
            ],
            [
                0.10383807867765427,
                -0.04750684276223183,
                0.27138763666152954,
                -0.01973295770585537,
                0.10889854282140732,
                0.2720186114311218,
                -0.09749574214220047,
                -0.08480730652809143,
                0.12379883974790573,
                0.15488386154174805,
                -0.10883665829896927,
                0.05324041098356247,
                0.11138326674699783,
                0.144534170627594,
                -0.08648465573787689,
                0.07393268495798111
            ]
        ],
        [
            [
                0.8801782727241516,
                -0.07187846302986145,
                2.497858762741089,
                0.8134914040565491,
                1.162685751914978,
                3.1064417362213135,
                1.412057876586914,
                0.7194952368736267,
                2.5104756355285645,
                1.04265558719635,
                2.6109790802001953,
                3.0023152828216553,
                1.7804195880889893,
                4.520226001739502,
                4.170615196228027,
                1.5405577421188354
            ],
            [
                1.0904136896133423,
                6.215114116668701,
                3.719160556793213,
                4.610897064208984,
                1.193251132965088,
                6.089183330535889,
                4.2657904624938965,
                2.727418899536133,
                1.2541987895965576,
                1.0885270833969116,
                1.8362369537353516,
                1.1536427736282349,
                1.3942344188690186,
                2.4800212383270264,
                1.9400349855422974,
                4.093783855438232
            ],
            [
                1.3568187952041626,
                3.7247886657714844,
                2.111572265625,
                1.066658854484558,
                1.2593183517456055,
                3.3980422019958496,
                3.1804311275482178,
                1.2287551164627075,
                1.7651842832565308,
                1.208735466003418,
                1.116875171661377,
                1.2066760063171387,
                3.774702310562134,
                3.848510980606079,
                2.8385283946990967,
                7.542749404907227
            ],
            [
                0.9520302414894104,
                1.545981764793396,
                2.181760787963867,
                2.38047456741333,
                3.0382611751556396,
                0.8479340672492981,
                4.4422783851623535,
                0.23919238150119781,
                3.5366528034210205,
                -0.07841604948043823,
                1.7365955114364624,
                1.2431272268295288,
                3.610905647277832,
                5.379490375518799,
                4.3708696365356445,
                4.971436023712158
            ],
            [
                3.4835927486419678,
                3.35626482963562,
                2.1462535858154297,
                1.128572702407837,
                2.1724069118499756,
                2.775958299636841,
                5.346681594848633,
                2.2484066486358643,
                1.2405850887298584,
                1.3243166208267212,
                1.4705544710159302,
                3.2052605152130127,
                1.4813792705535889,
                2.310067892074585,
                2.38041090965271,
                1.907355546951294
            ],
            [
                2.535353660583496,
                2.8134243488311768,
                2.8756582736968994,
                1.655645728111267,
                2.4055631160736084,
                2.5145087242126465,
                1.96552312374115,
                1.33829665184021,
                -0.06342457234859467,
                0.5782188773155212,
                4.36028528213501,
                8.435553550720215,
                3.0964114665985107,
                2.6718204021453857,
                3.941891670227051,
                1.4535783529281616
            ],
            [
                0.10581395030021667,
                1.5978327989578247,
                3.077406644821167,
                4.456081390380859,
                2.369001626968384,
                1.7647513151168823,
                2.16823148727417,
                0.03544919937849045,
                1.0487116575241089,
                3.901887893676758,
                4.399703025817871,
                4.172817707061768,
                2.0187251567840576,
                7.671329498291016,
                4.491888523101807,
                3.4418699741363525
            ],
            [
                0.38582924008369446,
                3.9861090183258057,
                4.411046981811523,
                4.354069232940674,
                1.5910311937332153,
                0.8595012426376343,
                3.91123366355896,
                2.877936840057373,
                2.497551918029785,
                3.2129743099212646,
                1.2323909997940063,
                1.1290050745010376,
                3.941296100616455,
                7.546257495880127,
                2.8819191455841064,
                1.7557648420333862
            ],
            [
                0.11523622274398804,
                0.23301821947097778,
                -0.0642710030078888,
                0.5686028599739075,
                -0.11374841630458832,
                0.5303433537483215,
                -0.09408049285411835,
                -0.0007539240177720785,
                0.07432857155799866,
                -0.07070688158273697,
                0.020336473360657692,
                -0.010830212384462357,
                0.1313409060239792,
                0.12051612883806229,
                0.31941282749176025,
                -0.04755869135260582
            ],
            [
                0.08538135141134262,
                0.39397406578063965,
                0.06409209221601486,
                0.06299411505460739,
                0.17376962304115295,
                0.0949292853474617,
                -0.009936008602380753,
                0.5042045712471008,
                -0.026270553469657898,
                -0.014291639439761639,
                0.06831230223178864,
                -0.09976544231176376,
                0.1262694150209427,
                0.11392424255609512,
                0.5879750847816467,
                0.24893034994602203
            ],
            [
                0.10735338926315308,
                0.6937528252601624,
                -0.08297920227050781,
                0.07070700079202652,
                -0.00437123654410243,
                0.051767490804195404,
                0.22822138667106628,
                0.24824945628643036,
                0.05593615025281906,
                0.08694524317979813,
                0.24704879522323608,
                -0.09565281867980957,
                0.13653455674648285,
                -0.04190107807517052,
                0.1017693355679512,
                0.32778385281562805
            ],
            [
                0.2816925644874573,
                0.2724986970424652,
                0.008608778938651085,
                0.13993212580680847,
                0.519041121006012,
                0.4224837124347687,
                0.37363913655281067,
                0.0712246373295784,
                -0.03383691981434822,
                0.16788870096206665,
                0.01095504593104124,
                0.41397756338119507,
                -0.13263653218746185,
                0.2533400356769562,
                0.24863851070404053,
                0.14557789266109467
            ],
            [
                0.2926669418811798,
                0.2364082932472229,
                0.3368823826313019,
                0.12224350869655609,
                0.39601582288742065,
                0.018424080684781075,
                0.055620335042476654,
                0.06688515096902847,
                0.3870845139026642,
                0.16861270368099213,
                0.03200290724635124,
                0.06927293539047241,
                0.6176229119300842,
                -0.004444863181561232,
                0.37608376145362854,
                0.19704897701740265
            ],
            [
                0.3324117660522461,
                0.3657940924167633,
                0.05226174741983414,
                -0.07382871955633163,
                0.4382648766040802,
                -0.0408417172729969,
                0.008196151815354824,
                0.10048400610685349,
                0.3856355547904968,
                0.10022368282079697,
                0.08872037380933762,
                -0.08946847915649414,
                -0.04518508166074753,
                0.06374585628509521,
                0.038260262459516525,
                0.37475165724754333
            ],
            [
                0.16012531518936157,
                0.14128625392913818,
                0.6417746543884277,
                -0.03884924203157425,
                0.37748414278030396,
                0.5843388438224792,
                0.06255954504013062,
                0.06919395178556442,
                -0.029411060735583305,
                0.015183987095952034,
                0.27549120783805847,
                0.1119450256228447,
                0.07360979914665222,
                -0.013278133235871792,
                -0.06652355194091797,
                0.6215338110923767
            ],
            [
                0.31965315341949463,
                0.028205113485455513,
                0.07200197875499725,
                0.25750449299812317,
                0.11351898312568665,
                0.8534060716629028,
                -0.08251302689313889,
                0.38262999057769775,
                0.20765350759029388,
                0.18673087656497955,
                0.36124053597450256,
                0.18308444321155548,
                0.14724086225032806,
                0.4097711145877838,
                0.2373012900352478,
                0.7579081654548645
            ]
        ],
        [
            [
                -0.06167127192020416,
                -0.07223188132047653,
                -0.021746771410107613,
                -0.09398803114891052,
                -0.08486408740282059,
                0.0013890372356399894,
                -0.08469359576702118,
                0.6944975852966309,
                0.13148187100887299,
                -0.008092899806797504,
                -0.16593396663665771,
                -0.029767898842692375,
                -0.16548670828342438,
                -0.07061196118593216,
                -0.00001673193764872849,
                0.5882202982902527
            ],
            [
                -0.004371773451566696,
                -1.8854952088531718e-7,
                0,
                -0.00002937733006547205,
                -0.0015733299078419805,
                -1.737408865665202e-7,
                0,
                -0.0018834967631846666,
                -0.0000031502941055805422,
                -0.000004196997451799689,
                -0.06331916153430939,
                0,
                -0.0008057086379267275,
                0,
                0,
                -0.11706807464361191
            ],
            [
                -0.034511201083660126,
                0,
                -0.0010306901531293988,
                0,
                0,
                -0.0003832158399745822,
                -0.004969234112650156,
                -0.00002627845788083505,
                -0.00001255477673112182,
                -0.0006947062793187797,
                -0.010108514688909054,
                -0.0000011341954859744874,
                0,
                0,
                0,
                -0.13235080242156982
            ],
            [
                -0.002086131600663066,
                -0.0003412897640373558,
                0,
                0,
                -1.866269343508975e-7,
                -0.00008002411050256342,
                -0.000005356044312065933,
                -0.01051135640591383,
                -0.01665743626654148,
                -0.0029154038056731224,
                -0.039507437497377396,
                -0.06673678010702133,
                -0.00003635088069131598,
                -0.00019367359345778823,
                -0.000013654548638442066,
                -0.10826349258422852
            ],
            [
                -0.012850135564804077,
                0,
                0,
                -0.0011383905075490475,
                0,
                -1.523155219729233e-7,
                -0.0000014072625162953045,
                -0.00030536053236573935,
                -0.0000035328293961356394,
                -0.0005174453253857791,
                -0.07184913754463196,
                0,
                -0.016064109280705452,
                0,
                -0.000016247584426309913,
                0.23035915195941925
            ],
            [
                -0.07370461523532867,
                -0.0022516860626637936,
                -1.7269945828957134e-7,
                -0.000014399748579307925,
                -0.0010468551190569997,
                0,
                -0.006688150577247143,
                -0.00003693535109050572,
                -0.0021431948989629745,
                0,
                -0.000011797613296948839,
                0,
                -0.0000023572854388476117,
                0,
                -0.005176612641662359,
                0.23024290800094604
            ],
            [
                -0.13287325203418732,
                -0.027645504102110863,
                0,
                0,
                -0.0074876765720546246,
                -0.0011113957734778523,
                -0.04964488744735718,
                -0.012832865118980408,
                0,
                -2.889897245950124e-7,
                0,
                -2.899777484799415e-7,
                -0.003653628984466195,
                -0.0000072802449722075835,
                0,
                -0.14273512363433838
            ],
            [
                -0.01987854391336441,
                0,
                0,
                -0.13337382674217224,
                -0.000010930476491921581,
                -0.1315068006515503,
                -0.009821003302931786,
                -0.0005783255328424275,
                0,
                -0.037107914686203,
                -0.007632334250956774,
                -0.0013926997780799866,
                -4.3848615405295277e-7,
                -0.002593700774013996,
                -0.06305145472288132,
                0.002173189539462328
            ],
            [
                -0.14293426275253296,
                -0.07861162722110748,
                -0.1313454955816269,
                -0.1357688009738922,
                -0.12689785659313202,
                -0.15443681180477142,
                -0.16157084703445435,
                -0.10147267580032349,
                -0.08574607968330383,
                -0.027663540095090866,
                -0.1646902710199356,
                -0.16698196530342102,
                -0.16374948620796204,
                -0.1698385775089264,
                -0.12563511729240417,
                0.06657233089208603
            ],
            [
                -0.06858223676681519,
                -0.11401647329330444,
                -0.025160156190395355,
                -0.11193092912435532,
                -0.16782505810260773,
                -0.16970416903495789,
                -0.15793633460998535,
                -0.15536506474018097,
                -0.1680314987897873,
                -0.1310407817363739,
                -0.14616049826145172,
                -0.15313121676445007,
                -0.16770866513252258,
                -0.16882596909999847,
                -0.16320228576660156,
                0.011613838374614716
            ],
            [
                -0.11543162912130356,
                -0.14507673680782318,
                -0.15131491422653198,
                -0.10895060747861862,
                -0.1554405391216278,
                -0.13329969346523285,
                -0.16871342062950134,
                -0.16980606317520142,
                -0.1629459410905838,
                -0.1688442826271057,
                -0.1532774269580841,
                -0.10752388834953308,
                -0.14733125269412994,
                -0.16832740604877472,
                -0.15007701516151428,
                -0.1464989185333252
            ],
            [
                -0.1401086151599884,
                -0.16818049550056458,
                -0.1297905594110489,
                -0.09965097904205322,
                -0.11052215099334717,
                -0.14150086045265198,
                -0.0857703685760498,
                -0.06742075085639954,
                -0.16304375231266022,
                -0.11540427058935165,
                -0.16164331138134003,
                -0.1699046641588211,
                -0.1068820059299469,
                -0.1564769148826599,
                -0.10284862667322159,
                -0.01735249161720276
            ],
            [
                -0.13154642283916473,
                -0.1700121909379959,
                -0.1485721319913864,
                -0.15948711335659027,
                -0.13721083104610443,
                -0.15662434697151184,
                -0.16987568140029907,
                -0.09208723157644272,
                -0.1462250053882599,
                -0.16339747607707977,
                -0.1433451622724533,
                -0.08821256458759308,
                -0.1169678270816803,
                -0.13657276332378387,
                -0.15041346848011017,
                0.021849049255251884
            ],
            [
                -0.06334042549133301,
                -0.17001569271087646,
                -0.08408458530902863,
                -0.1163235530257225,
                -0.16683357954025269,
                -0.14469744265079498,
                -0.14924463629722595,
                -0.14674259722232819,
                -0.16855384409427643,
                -0.12685410678386688,
                -0.020636091008782387,
                -0.06921063363552094,
                -0.14894837141036987,
                -0.11671649664640427,
                -0.10963990539312363,
                -0.040783852338790894
            ],
            [
                -0.12526655197143555,
                -0.15764039754867554,
                -0.13855873048305511,
                -0.0720893144607544,
                -0.15513184666633606,
                -0.11457913368940353,
                -0.15208874642848969,
                -0.1700110286474228,
                -0.1700170636177063,
                -0.16324502229690552,
                -0.164260596036911,
                0.0599430575966835,
                -0.14998221397399902,
                -0.14383558928966522,
                -0.044097162783145905,
                -0.026431111618876457
            ],
            [
                -0.09852895885705948,
                -0.14390845596790314,
                -0.12350639700889587,
                -0.15234966576099396,
                -0.04635533317923546,
                -0.14949370920658112,
                -0.1592337042093277,
                -0.16742227971553802,
                -0.16813130676746368,
                -0.16169311106204987,
                -0.1678517907857895,
                -0.13962295651435852,
                -0.16935762763023376,
                -0.16911834478378296,
                -0.16982586681842804,
                -0.09428878128528595
            ]
        ],
        [
            [
                0.05093330889940262,
                -0.15978595614433289,
                0.22286926209926605,
                0.9531522393226624,
                -0.10819551348686218,
                -0.1674911379814148,
                -0.16482317447662354,
                -0.13730488717556,
                -0.10846606642007828,
                -0.1678820550441742,
                -0.09750431030988693,
                -0.16221420466899872,
                -0.09950648248195648,
                -0.11857613176107407,
                1.1392335891723633,
                1.145508050918579
            ],
            [
                -0.16935552656650543,
                -0.1052980124950409,
                -0.15279753506183624,
                -0.16444863379001617,
                -0.05172457918524742,
                -0.16572636365890503,
                -0.11617237329483032,
                0.6645373702049255,
                0.0806991457939148,
                -0.13796447217464447,
                -0.05335862934589386,
                -0.03506994619965553,
                -0.12521912157535553,
                -0.1324348747730255,
                -0.1654738485813141,
                2.3653693199157715
            ],
            [
                -0.1288159191608429,
                -0.09724532812833786,
                -0.10733005404472351,
                -0.10774438828229904,
                -0.06073993816971779,
                0.1672210693359375,
                1.9844263792037964,
                -0.128652885556221,
                0.5594627261161804,
                -0.09501484781503677,
                -0.16071771085262299,
                -0.16952164471149445,
                0.0964813083410263,
                -0.0912754088640213,
                0.9260033965110779,
                3.0587048530578613
            ],
            [
                -0.10218042880296707,
                -0.061895888298749924,
                -0.06505893170833588,
                -0.05826623737812042,
                -0.10467894375324249,
                0.801612377166748,
                0.31912851333618164,
                0.047315169125795364,
                0.49469712376594543,
                -0.12497381120920181,
                -0.16910646855831146,
                -0.1692759096622467,
                -0.10202927887439728,
                -0.132012739777565,
                0.21634262800216675,
                -0.026724152266979218
            ],
            [
                0.03145277872681618,
                0.08449728041887283,
                -0.10617303103208542,
                -0.16037839651107788,
                -0.15919336676597595,
                -0.05762840807437897,
                0.2757040560245514,
                -0.12224583327770233,
                -0.143196702003479,
                -0.07552339881658554,
                -0.15296636521816254,
                -0.15100610256195068,
                -0.08831953257322311,
                -0.13468368351459503,
                -0.1560174822807312,
                0.40921688079833984
            ],
            [
                -0.16708523035049438,
                -0.13758361339569092,
                0.005496801342815161,
                -0.1316903531551361,
                -0.010645581409335136,
                -0.0110819386318326,
                -0.0755019336938858,
                -0.1385510414838791,
                -0.02160561829805374,
                -0.016376469284296036,
                -0.0877341777086258,
                4.837129592895508,
                0.25037845969200134,
                -0.14348357915878296,
                -0.16044066846370697,
                0.11109234392642975
            ],
            [
                -0.14967791736125946,
                -0.0536012165248394,
                -0.15906305611133575,
                -0.031099164858460426,
                -0.13662809133529663,
                -0.1617976576089859,
                -0.047686293721199036,
                -0.0677848756313324,
                -0.13568511605262756,
                0.41174888610839844,
                -0.14983892440795898,
                -0.15768404304981232,
                -0.1274057924747467,
                5.646453380584717,
                1.323019027709961,
                -0.017626779153943062
            ],
            [
                -0.17001551389694214,
                -0.09863772988319397,
                0.6891380548477173,
                1.6450014114379883,
                0.38786861300468445,
                -0.1517302542924881,
                -0.1666964292526245,
                -0.09286122769117355,
                1.4897903203964233,
                -0.16536273062229156,
                -0.03165411576628685,
                -0.029376663267612457,
                -0.16888566315174103,
                4.287286758422852,
                4.214667320251465,
                -0.16999465227127075
            ],
            [
                -0.14975036680698395,
                -0.09746304899454117,
                -0.08749594539403915,
                -0.0963132306933403,
                -0.04830722138285637,
                -0.02143014222383499,
                -0.1664007306098938,
                -0.13644270598888397,
                0.06638114154338837,
                -0.06683993339538574,
                0.013789944350719452,
                -0.058694418519735336,
                -0.15849417448043823,
                -0.11145538091659546,
                -0.12634117901325226,
                -0.16481493413448334
            ],
            [
                0.00994547363370657,
                0.018990924581885338,
                0.19655656814575195,
                -0.06644143909215927,
                0.5801113843917847,
                -0.07206268608570099,
                -0.1667642742395401,
                -0.1152958944439888,
                -0.16507676243782043,
                -0.15938633680343628,
                0.06225235015153885,
                0.12709186971187592,
                0.03394218906760216,
                -0.10404184460639954,
                0.012005791068077087,
                -0.006698598153889179
            ],
            [
                -0.06175432354211807,
                0.026036445051431656,
                0.09944134205579758,
                0.28559502959251404,
                0.42004984617233276,
                -0.06527990102767944,
                -0.0558481402695179,
                0.014830918982625008,
                0.14815616607666016,
                0.13688185811042786,
                0.13078102469444275,
                0.07097727805376053,
                -0.08963139355182648,
                -0.022833390161395073,
                0.058542802929878235,
                -0.10266845673322678
            ],
            [
                -0.15051884949207306,
                -0.07035339623689651,
                -0.045522406697273254,
                0.2999020218849182,
                0.6211049556732178,
                0.21607765555381775,
                0.10817863047122955,
                0.0083438316360116,
                -0.1447112113237381,
                -0.017106372863054276,
                -0.15053392946720123,
                0.09778975695371628,
                -0.11253873258829117,
                -0.029312144964933395,
                0.08643341064453125,
                0.4785657823085785
            ],
            [
                -0.11042997986078262,
                -0.12821772694587708,
                0.18360167741775513,
                -0.10984604060649872,
                0.3310517370700836,
                0.14599797129631042,
                -0.15277768671512604,
                0.07847025990486145,
                0.10360512137413025,
                -0.054941326379776,
                0.012727762572467327,
                0.29031533002853394,
                -0.06128685921430588,
                -0.04857797548174858,
                -0.07544220238924026,
                0.1351454257965088
            ],
            [
                0.057046644389629364,
                0.06551505625247955,
                0.2623732089996338,
                0.11202916502952576,
                0.09906207025051117,
                -0.026778217405080795,
                -0.06489968299865723,
                -0.16383357346057892,
                -0.10401422530412674,
                0.03766513988375664,
                0.1536647230386734,
                0.2776273488998413,
                0.08512110263109207,
                0.016224579885601997,
                -0.08697281777858734,
                -0.05690355971455574
            ],
            [
                -0.1138337105512619,
                -0.014026968739926815,
                0.1535995602607727,
                -0.0386049784719944,
                0.24575869739055634,
                0.027803342789411545,
                -0.08183671534061432,
                -0.005135381128638983,
                -0.12901151180267334,
                0.004084039945155382,
                -0.03407033532857895,
                -0.05359555035829544,
                -0.03412625938653946,
                -0.04732290655374527,
                -0.022286785766482353,
                0.14821164309978485
            ],
            [
                -0.101741261780262,
                -0.09128724783658981,
                -0.022372908890247345,
                -0.09028343111276627,
                -0.053315985947847366,
                -0.12373536080121994,
                -0.16393853724002838,
                -0.08007382601499557,
                -0.07624773681163788,
                -0.08579621464014053,
                -0.07386719435453415,
                -0.04360129311680794,
                -0.12316583096981049,
                -0.08882288634777069,
                -0.02953365258872509,
                0.021280154585838318
            ]
        ],
        [
            [
                -0.11359591782093048,
                -0.006913365796208382,
                -0.04121119901537895,
                -0.0002384925464866683,
                -0.1690370887517929,
                -0.015599827282130718,
                -0.013759071007370949,
                -0.11290951818227768,
                -0.13018369674682617,
                -0.04670582339167595,
                -0.16933315992355347,
                -0.05939676612615585,
                -0.02716607227921486,
                -0.048862386494874954,
                -0.1203041672706604,
                -0.1686672568321228
            ],
            [
                -0.16785402595996857,
                -0.014794877730309963,
                -0.04089045524597168,
                -0.1532214879989624,
                -0.1682351976633072,
                -0.008475485257804394,
                -0.07643145322799683,
                -0.024794049561023712,
                -0.013181946240365505,
                -0.08999080210924149,
                -0.06062105670571327,
                -0.029926778748631477,
                -0.047174904495477676,
                -0.062463052570819855,
                -0.009210554882884026,
                -0.12396319955587387
            ],
            [
                -0.07710785418748856,
                -0.14825187623500824,
                -0.1174224317073822,
                -0.16504840552806854,
                -0.023431705310940742,
                -0.00859862845391035,
                -0.03830348700284958,
                -0.0008364327368326485,
                -0.10233327746391296,
                -0.14438660442829132,
                -0.053213879466056824,
                -0.1389419436454773,
                -0.07564177364110947,
                -0.03242001682519913,
                -0.03126005455851555,
                -0.04892095550894737
            ],
            [
                -0.12408068776130676,
                -0.12223852425813675,
                -0.07421935349702835,
                -0.00746991578489542,
                -0.0035628676414489746,
                -0.006686095613986254,
                -0.08846023678779602,
                -0.0014403436798602343,
                -0.16961254179477692,
                -0.052144017070531845,
                -0.03292373940348625,
                -0.16202029585838318,
                -0.02586500532925129,
                -0.029537685215473175,
                -0.0909426361322403,
                -0.16964535415172577
            ],
            [
                -0.10126059502363205,
                -0.047012507915496826,
                -0.11625110357999802,
                -0.00015095809067133814,
                -0.006191117689013481,
                -0.002163941040635109,
                -0.07562943547964096,
                -0.08937203139066696,
                -0.14586488902568817,
                -0.04993835464119911,
                -0.007822871208190918,
                -0.16916696727275848,
                -0.058150406926870346,
                -0.13271203637123108,
                -0.1507151871919632,
                -0.08863687515258789
            ],
            [
                -0.16853702068328857,
                -0.15730394423007965,
                -0.12879620492458344,
                -0.0041098203510046005,
                -0.12528888881206512,
                -0.036526765674352646,
                -0.1482161581516266,
                -0.1615995317697525,
                -0.10646504163742065,
                -0.007813842967152596,
                -0.00739206001162529,
                -0.1519266813993454,
                -0.048879463225603104,
                -0.00006726726132910699,
                -0.0899239033460617,
                -0.15638965368270874
            ],
            [
                -0.16007506847381592,
                -0.02900741808116436,
                -0.021740615367889404,
                -0.005453758407384157,
                -0.07555027306079865,
                -0.14649373292922974,
                -0.16760887205600739,
                -0.16106608510017395,
                -0.0020794582087546587,
                -0.0007400965550914407,
                -0.0061473180539906025,
                -0.09231559932231903,
                -0.01505354791879654,
                -0.040811847895383835,
                -0.015541834756731987,
                -0.11855679750442505
            ],
            [
                -0.05087604746222496,
                -0.01790875755250454,
                -0.1360113024711609,
                -0.06722044944763184,
                0.956649899482727,
                -0.16211789846420288,
                -0.021632613614201546,
                -0.16795714199543,
                -0.1022520437836647,
                -0.0790727436542511,
                -0.0986936166882515,
                -0.1535336822271347,
                -0.010579653084278107,
                -0.022207895293831825,
                -0.00139079918153584,
                -0.04550331085920334
            ],
            [
                0.26875486969947815,
                0.012484710663557053,
                0.3280070126056671,
                0.25085511803627014,
                0.5027132034301758,
                0.22341568768024445,
                0.3148573637008667,
                0.22379915416240692,
                0.1926863044500351,
                0.07668764889240265,
                0.13029485940933228,
                0.056269321590662,
                -0.004803448915481567,
                0.22693806886672974,
                0.4499347507953644,
                0.007680097594857216
            ],
            [
                -0.019714944064617157,
                0.14688104391098022,
                0.03162914887070656,
                0.3251373767852783,
                0.15978974103927612,
                0.4036959707736969,
                0.27654242515563965,
                0.4118824899196625,
                0.33871737122535706,
                0.3473576009273529,
                0.6370455622673035,
                0.28747084736824036,
                0.2837393581867218,
                0.3093421161174774,
                0.44096171855926514,
                -0.010643985122442245
            ],
            [
                0.30656614899635315,
                0.31783607602119446,
                0.5203799605369568,
                0.3134397864341736,
                0.46591246128082275,
                0.13526511192321777,
                0.2514229714870453,
                0.17078156769275665,
                0.20227263867855072,
                0.18471495807170868,
                0.17146311700344086,
                0.14973531663417816,
                0.2082122564315796,
                0.15957266092300415,
                0.05785642936825752,
                0.13412095606327057
            ],
            [
                0.1399233341217041,
                0.1033022403717041,
                0.8487141728401184,
                0.5300998091697693,
                0.22850093245506287,
                0.18197306990623474,
                0.22823691368103027,
                0.5251683592796326,
                0.5612280368804932,
                0.18847450613975525,
                0.32441216707229614,
                0.5276187658309937,
                0.4437658190727234,
                0.07191137969493866,
                0.18983350694179535,
                0.16322116553783417
            ],
            [
                -0.018439065665006638,
                0.1492358297109604,
                0.46376359462738037,
                0.4534006714820862,
                0.3156939446926117,
                0.3637828528881073,
                0.36280104517936707,
                0.06649316102266312,
                0.21668070554733276,
                0.18783214688301086,
                0.3792026937007904,
                0.24694077670574188,
                0.38493382930755615,
                0.3317886292934418,
                0.22330036759376526,
                -0.035112347453832626
            ],
            [
                0.12090927362442017,
                0.41027119755744934,
                0.6114818453788757,
                0.29741278290748596,
                0.3608101010322571,
                0.3620641231536865,
                0.2965058386325836,
                0.2112467885017395,
                0.35113704204559326,
                0.2910887897014618,
                0.5777120590209961,
                0.1813637912273407,
                0.48517686128616333,
                0.5125713348388672,
                0.34377527236938477,
                0.22483371198177338
            ],
            [
                0.1922627091407776,
                0.2560509741306305,
                0.3767636716365814,
                0.2877626419067383,
                0.18608352541923523,
                0.49809426069259644,
                0.14799004793167114,
                0.18679089844226837,
                0.15582919120788574,
                0.47798630595207214,
                0.3319096863269806,
                0.37937131524086,
                0.29042214155197144,
                0.5268601179122925,
                0.2251950055360794,
                -0.013263305649161339
            ],
            [
                -0.060996200889348984,
                0.012051790952682495,
                0.17467567324638367,
                0.1395135372877121,
                0.12267110496759415,
                0.18974626064300537,
                0.301185667514801,
                -0.009767219424247742,
                0.1787625104188919,
                0.061033330857753754,
                0.09059996157884598,
                -0.006031950004398823,
                0.12570109963417053,
                0.19449631869792938,
                0.5764439105987549,
                0.29771357774734497
            ]
        ],
        [
            [
                2.97127366065979,
                1.9819293022155762,
                1.259909749031067,
                1.6820272207260132,
                0.9134377241134644,
                3.586805820465088,
                1.1396557092666626,
                2.4615585803985596,
                1.8804152011871338,
                1.5657819509506226,
                1.0382046699523926,
                2.588839530944824,
                0.9379698634147644,
                0.7756807804107666,
                1.942001223564148,
                2.612967014312744
            ],
            [
                1.6948010921478271,
                -0.044878773391246796,
                -0.0510210245847702,
                0.5775833129882812,
                -0.16137120127677917,
                2.151414155960083,
                0.5026669502258301,
                1.462829828262329,
                2.3405845165252686,
                -0.1694338172674179,
                -0.01863822527229786,
                0.3691394627094269,
                0.5672262907028198,
                -0.0008025147835724056,
                0.11103376746177673,
                1.2600735425949097
            ],
            [
                1.5928704738616943,
                0.09543775767087936,
                0.24328048527240753,
                0.3890807032585144,
                0.4065004587173462,
                0.9960551857948303,
                1.2892279624938965,
                -0.15381231904029846,
                0.3079098165035248,
                0.446742981672287,
                -0.16354237496852875,
                0.7260838747024536,
                0.1768771857023239,
                0.8697131872177124,
                0.9901620745658875,
                0.17043937742710114
            ],
            [
                4.059040069580078,
                0.580104649066925,
                0.008861861191689968,
                0.17609766125679016,
                -0.1411101371049881,
                3.341397762298584,
                0.6519898176193237,
                0.7705399394035339,
                1.4599798917770386,
                0.8823246359825134,
                0.2104143500328064,
                -0.05870851129293442,
                0.40590766072273254,
                0.16606657207012177,
                0.1829337179660797,
                0.4010731875896454
            ],
            [
                3.008071184158325,
                1.6091212034225464,
                0.9755117297172546,
                -0.159016415476799,
                0.31707897782325745,
                1.596758484840393,
                -0.04010549560189247,
                0.7487157583236694,
                0.5177153944969177,
                0.512132465839386,
                -0.066432423889637,
                0.10809239745140076,
                -0.12620112299919128,
                -0.1605212241411209,
                0.31987813115119934,
                1.5388883352279663
            ],
            [
                2.2461791038513184,
                0.9441707730293274,
                0.9740818738937378,
                0.14977774024009705,
                -0.14625827968120575,
                0.601844072341919,
                0.5996478796005249,
                0.7273041009902954,
                0.549824595451355,
                -0.16423842310905457,
                1.9495365619659424,
                1.437487244606018,
                0.6775562763214111,
                -0.07225432991981506,
                0.6375566124916077,
                1.7808716297149658
            ],
            [
                1.6521722078323364,
                0.08949137479066849,
                0.47295844554901123,
                0.39194899797439575,
                -0.005970056634396315,
                2.022304058074951,
                1.453721284866333,
                1.2274457216262817,
                0.33490416407585144,
                0.18605805933475494,
                1.7137095928192139,
                -0.10393081605434418,
                0.30025985836982727,
                0.27734678983688354,
                1.1762770414352417,
                0.8464487195014954
            ],
            [
                1.589563012123108,
                0.46824660897254944,
                1.7358838319778442,
                3.2774572372436523,
                0.6067973375320435,
                1.5792908668518066,
                2.232409954071045,
                0.6116735935211182,
                1.0414496660232544,
                0.9310469031333923,
                -0.1522524654865265,
                0.568945586681366,
                0.2563815712928772,
                -0.12925443053245544,
                2.2350566387176514,
                0.008696059696376324
            ],
            [
                0.019045522436499596,
                -0.09096944332122803,
                0.05785280466079712,
                -0.15450890362262726,
                -0.15595212578773499,
                -0.16673004627227783,
                -0.12379606813192368,
                -0.16536393761634827,
                -0.10500701516866684,
                -0.16117984056472778,
                -0.07160599529743195,
                -0.13404600322246552,
                -0.14703068137168884,
                -0.12851981818675995,
                -0.1550382673740387,
                -0.1641564667224884
            ],
            [
                0.011222973465919495,
                -0.16971087455749512,
                -0.16634191572666168,
                -0.12893076241016388,
                -0.16556283831596375,
                -0.07924353331327438,
                -0.15160435438156128,
                -0.127556711435318,
                -0.13848693668842316,
                -0.16351629793643951,
                -0.10363277792930603,
                -0.1695224493741989,
                -0.14840909838676453,
                -0.16967666149139404,
                -0.06801453232765198,
                -0.07200567424297333
            ],
            [
                0.059638861566782,
                -0.16858653724193573,
                -0.17001202702522278,
                -0.0859769955277443,
                -0.02157536707818508,
                -0.1699722558259964,
                -0.16560640931129456,
                -0.16876399517059326,
                -0.16707150638103485,
                -0.16787928342819214,
                -0.042910803109407425,
                -0.15591788291931152,
                -0.16701717674732208,
                -0.16366241872310638,
                -0.16560623049736023,
                -0.1454593390226364
            ],
            [
                -0.004186357371509075,
                -0.1656494438648224,
                -0.13507477939128876,
                -0.1666393131017685,
                -0.16635870933532715,
                -0.08475563675165176,
                -0.13062849640846252,
                0.09755130112171173,
                -0.16653016209602356,
                -0.16745048761367798,
                -0.12479139119386673,
                -0.14281105995178223,
                -0.16643090546131134,
                -0.1625830978155136,
                -0.02438287064433098,
                -0.12177220731973648
            ],
            [
                -0.1324392557144165,
                -0.1683690994977951,
                -0.15006820857524872,
                -0.133326455950737,
                -0.042944811284542084,
                -0.1699182093143463,
                -0.16002945601940155,
                -0.13646702468395233,
                -0.1238693967461586,
                -0.12897446751594543,
                -0.16944536566734314,
                -0.10414955765008926,
                -0.1623154580593109,
                -0.1695849597454071,
                0.4286316931247711,
                -0.013144594617187977
            ],
            [
                0.1482122838497162,
                -0.14613233506679535,
                0.13274845480918884,
                -0.1588028073310852,
                -0.16550952196121216,
                -0.16782069206237793,
                -0.1688086837530136,
                -0.16819843649864197,
                -0.16094639897346497,
                -0.11853636801242828,
                -0.08326587080955505,
                -0.12877869606018066,
                -0.054830558598041534,
                -0.04704907909035683,
                -0.1481916904449463,
                -0.1404169797897339
            ],
            [
                -0.0770203173160553,
                -0.1683592051267624,
                -0.0722196027636528,
                -0.15965504944324493,
                -0.0748642086982727,
                -0.16814546287059784,
                -0.16985489428043365,
                -0.16345657408237457,
                -0.16985677182674408,
                -0.17004063725471497,
                -0.008621955290436745,
                -0.1433737576007843,
                -0.1198672279715538,
                -0.1600802093744278,
                -0.16431963443756104,
                -0.16968978941440582
            ],
            [
                -0.05796542018651962,
                -0.12798039615154266,
                -0.13400514423847198,
                -0.1148664578795433,
                0.3305741846561432,
                -0.166464701294899,
                0.005472864024341106,
                -0.10561001300811768,
                -0.16052667796611786,
                -0.12829247117042542,
                -0.1636728197336197,
                -0.11713366955518723,
                -0.08534885942935944,
                -0.07505228370428085,
                -0.12390366941690445,
                0.1592697948217392
            ]
        ],
        [
            [
                2.454366683959961,
                0.319415420293808,
                2.5544819831848145,
                2.7331173419952393,
                1.6885911226272583,
                3.4786601066589355,
                3.0510287284851074,
                1.6645153760910034,
                1.263301968574524,
                2.7953615188598633,
                2.144223690032959,
                3.0884296894073486,
                1.5601478815078735,
                3.564110279083252,
                4.89604377746582,
                1.773317813873291
            ],
            [
                2.6745989322662354,
                4.530694961547852,
                5.14090633392334,
                5.368351936340332,
                2.8935015201568604,
                5.011675834655762,
                2.2592477798461914,
                2.922149658203125,
                4.325390815734863,
                2.8303794860839844,
                2.8140475749969482,
                2.7909159660339355,
                3.5723631381988525,
                3.31577467918396,
                3.4816858768463135,
                3.509824275970459
            ],
            [
                2.9600558280944824,
                3.0091536045074463,
                3.246999740600586,
                1.7863645553588867,
                2.3296077251434326,
                4.2102742195129395,
                6.828092098236084,
                1.9557995796203613,
                2.7333920001983643,
                3.0011892318725586,
                1.3927217721939087,
                3.3719124794006348,
                3.3054943084716797,
                3.3487727642059326,
                4.5043511390686035,
                6.862057209014893
            ],
            [
                3.6661221981048584,
                4.376550197601318,
                2.7448904514312744,
                2.89676833152771,
                3.66162371635437,
                4.0695672035217285,
                3.3953185081481934,
                3.1629750728607178,
                3.875006914138794,
                1.207387089729309,
                2.548849105834961,
                3.257935047149658,
                3.3942983150482178,
                4.7403106689453125,
                5.140696048736572,
                4.4964776039123535
            ],
            [
                5.169902801513672,
                3.212531089782715,
                2.7456603050231934,
                4.475285053253174,
                4.184106349945068,
                5.707045555114746,
                4.924981594085693,
                2.2848634719848633,
                1.9448482990264893,
                2.8633742332458496,
                3.4025156497955322,
                4.029478073120117,
                2.7610766887664795,
                3.324714422225952,
                2.7576565742492676,
                1.9073444604873657
            ],
            [
                4.6210856437683105,
                3.0284628868103027,
                4.982717037200928,
                4.4048237800598145,
                2.8585574626922607,
                2.5868940353393555,
                2.6645922660827637,
                1.8498084545135498,
                2.874079704284668,
                2.9098780155181885,
                5.879341125488281,
                9.319890975952148,
                3.48343563079834,
                1.184890627861023,
                5.560295104980469,
                4.432782173156738
            ],
            [
                1.1949139833450317,
                2.996518850326538,
                3.321322441101074,
                4.4014787673950195,
                3.5920684337615967,
                4.087685585021973,
                2.1486191749572754,
                1.4736378192901611,
                0.5905947089195251,
                3.7757890224456787,
                5.321016311645508,
                5.260616779327393,
                2.6023428440093994,
                9.684664726257324,
                5.6708526611328125,
                3.67891263961792
            ],
            [
                1.7518126964569092,
                3.160151720046997,
                6.355023384094238,
                4.818944931030273,
                2.2369606494903564,
                1.990404725074768,
                3.4694480895996094,
                3.098745584487915,
                2.4438512325286865,
                2.956289529800415,
                2.224440574645996,
                2.2169981002807617,
                5.137667179107666,
                8.211347579956055,
                4.555512428283691,
                2.6781914234161377
            ],
            [
                -0.16151422262191772,
                -0.11131617426872253,
                -0.16709953546524048,
                -0.1693439483642578,
                -0.16683225333690643,
                -0.12221364676952362,
                -0.16937631368637085,
                -0.16190582513809204,
                -0.16841089725494385,
                -0.16762232780456543,
                -0.16999591886997223,
                -0.1698913425207138,
                -0.17001408338546753,
                -0.15759478509426117,
                -0.16130869090557098,
                -0.14776945114135742
            ],
            [
                -0.16995206475257874,
                -0.1515706479549408,
                -0.16803878545761108,
                -0.14519475400447845,
                -0.16880810260772705,
                -0.1498894989490509,
                -0.169850155711174,
                -0.16288775205612183,
                -0.12521885335445404,
                -0.16208615899085999,
                -0.1495194137096405,
                -0.16472125053405762,
                -0.16933828592300415,
                -0.16935116052627563,
                -0.16168257594108582,
                -0.16988776624202728
            ],
            [
                -0.1685309112071991,
                -0.13310803472995758,
                -0.1654118299484253,
                -0.16908660531044006,
                -0.1593533158302307,
                -0.1678743064403534,
                -0.13364706933498383,
                -0.1602320373058319,
                -0.1323426216840744,
                -0.13453362882137299,
                -0.17004019021987915,
                -0.16354380548000336,
                -0.13022349774837494,
                -0.1698552370071411,
                -0.14781032502651215,
                -0.1664232462644577
            ],
            [
                -0.1690502017736435,
                -0.16633473336696625,
                -0.1379547417163849,
                -0.10066546499729156,
                -0.16975736618041992,
                -0.16793441772460938,
                -0.16887688636779785,
                -0.16922414302825928,
                -0.12446478009223938,
                -0.16951529681682587,
                -0.13286811113357544,
                -0.16384580731391907,
                -0.16856275498867035,
                -0.15056101977825165,
                -0.11620470136404037,
                -0.16968151926994324
            ],
            [
                -0.1695442795753479,
                -0.1456729620695114,
                -0.12825822830200195,
                -0.1652008444070816,
                -0.12758341431617737,
                -0.16432826220989227,
                -0.16959954798221588,
                -0.17003904283046722,
                -0.16999053955078125,
                -0.15808920562267303,
                -0.1326555609703064,
                -0.16193538904190063,
                -0.15659433603286743,
                -0.16943974792957306,
                -0.10607276111841202,
                -0.16871775686740875
            ],
            [
                -0.13538490235805511,
                -0.09139485657215118,
                -0.11516579240560532,
                -0.1670982390642166,
                -0.13601146638393402,
                -0.16964635252952576,
                -0.1662491410970688,
                -0.15577127039432526,
                -0.15331940352916718,
                -0.16377364099025726,
                -0.14670351147651672,
                -0.1197681874036789,
                -0.12030444294214249,
                -0.1674233376979828,
                -0.168850839138031,
                -0.1697726994752884
            ],
            [
                -0.1695692539215088,
                -0.16971339285373688,
                -0.1674826741218567,
                -0.11045324057340622,
                -0.1291816532611847,
                -0.1657085120677948,
                -0.16757822036743164,
                -0.1384582370519638,
                -0.1509934961795807,
                -0.1509934365749359,
                -0.16988158226013184,
                -0.16414226591587067,
                -0.16991807520389557,
                -0.1463664472103119,
                -0.1682930886745453,
                -0.16376039385795593
            ],
            [
                -0.1681433469057083,
                -0.16987819969654083,
                -0.16915346682071686,
                -0.16481171548366547,
                -0.16756023466587067,
                -0.1568705439567566,
                -0.12768036127090454,
                -0.1695041060447693,
                -0.1679609715938568,
                -0.16474294662475586,
                -0.16862930357456207,
                -0.16408875584602356,
                -0.16801132261753082,
                -0.15535518527030945,
                -0.1507166624069214,
                -0.16510963439941406
            ]
        ],
        [
            [
                3.996760368347168,
                4.706297397613525,
                2.6869874000549316,
                3.5677807331085205,
                3.7237095832824707,
                3.771265745162964,
                2.5202906131744385,
                2.941408395767212,
                5.8003997802734375,
                3.9132802486419678,
                2.77603816986084,
                2.5099949836730957,
                3.4793877601623535,
                3.2568845748901367,
                4.4498515129089355,
                7.133284091949463
            ],
            [
                4.870387077331543,
                5.978092193603516,
                3.969006299972534,
                4.031855583190918,
                4.785110950469971,
                4.026293754577637,
                4.755031585693359,
                4.752912521362305,
                2.3235435485839844,
                3.104881763458252,
                3.190139055252075,
                4.100167274475098,
                4.10637903213501,
                4.242678642272949,
                4.544984340667725,
                6.362303733825684
            ],
            [
                3.1627159118652344,
                5.966612815856934,
                5.6710205078125,
                4.091597557067871,
                5.4110541343688965,
                3.8621513843536377,
                7.007187843322754,
                4.500113487243652,
                4.404597759246826,
                3.4857678413391113,
                3.7383179664611816,
                2.1667046546936035,
                5.179698467254639,
                7.400614261627197,
                5.496666431427002,
                6.162761211395264
            ],
            [
                5.752460956573486,
                4.374953746795654,
                3.5378146171569824,
                2.905879497528076,
                4.461556434631348,
                3.6383094787597656,
                5.340203762054443,
                3.054269790649414,
                4.859663486480713,
                3.480553388595581,
                3.763185977935791,
                3.687915563583374,
                3.9116318225860596,
                8.032978057861328,
                8.048563957214355,
                3.718590497970581
            ],
            [
                4.574999809265137,
                5.834732532501221,
                2.531902551651001,
                3.379225254058838,
                4.89003324508667,
                4.63338565826416,
                6.125708103179932,
                6.3731279373168945,
                6.27761697769165,
                2.279153823852539,
                3.2721688747406006,
                3.6386911869049072,
                3.2916650772094727,
                2.1867597103118896,
                2.575519323348999,
                4.156588077545166
            ],
            [
                3.6685495376586914,
                3.5369110107421875,
                5.030994892120361,
                2.173562526702881,
                3.971426486968994,
                5.661398410797119,
                2.757931709289551,
                4.623465061187744,
                3.0904548168182373,
                4.380195140838623,
                5.245562553405762,
                12.007811546325684,
                4.929733753204346,
                5.235652446746826,
                3.91420316696167,
                3.0179741382598877
            ],
            [
                3.488349437713623,
                2.868253469467163,
                4.3623738288879395,
                4.256889343261719,
                4.047706127166748,
                5.410386562347412,
                4.362371444702148,
                4.658634662628174,
                4.474881172180176,
                6.247648239135742,
                3.2747697830200195,
                5.059720516204834,
                3.9200735092163086,
                11.46882438659668,
                10.468494415283203,
                8.498237609863281
            ],
            [
                3.2499146461486816,
                4.9815568923950195,
                5.185024261474609,
                4.85269021987915,
                4.4741435050964355,
                4.656059741973877,
                6.12338924407959,
                3.8042116165161133,
                8.159773826599121,
                4.346494674682617,
                3.757370710372925,
                3.913480758666992,
                3.8999574184417725,
                11.536836624145508,
                9.974617004394531,
                5.765653610229492
            ],
            [
                0.2899041771888733,
                0.12172111123800278,
                0.3836231529712677,
                0.30207258462905884,
                0.10665221512317657,
                0.31173405051231384,
                0.3998578190803528,
                0.5954831838607788,
                -0.032483313232660294,
                -0.10229458659887314,
                0.15725715458393097,
                0.09279342740774155,
                0.2217574566602707,
                0.7517346739768982,
                0.7010681629180908,
                0.3109937310218811
            ],
            [
                -0.07506494969129562,
                0.08106837421655655,
                -0.07508008927106857,
                0.040168434381484985,
                0.019737137481570244,
                0.23536325991153717,
                0.5652804970741272,
                0.22024010121822357,
                0.1301562488079071,
                0.15952332317829132,
                0.20958274602890015,
                0.3336978554725647,
                0.3930533230304718,
                0.0687205046415329,
                -0.05190785601735115,
                -0.008201973512768745
            ],
            [
                0.08458123356103897,
                0.046748630702495575,
                0.3100002706050873,
                0.19073759019374847,
                0.05129531770944595,
                -0.03899264708161354,
                0.1258469820022583,
                -0.032331254333257675,
                0.24530251324176788,
                -0.015732698142528534,
                0.15206243097782135,
                0.14899756014347076,
                0.056417737156152725,
                -0.055000320076942444,
                0.0643806904554367,
                0.05310608819127083
            ],
            [
                0.08969109505414963,
                -0.07072897255420685,
                0.3120479881763458,
                0.13053852319717407,
                -0.0805584117770195,
                -0.010340135544538498,
                -0.03498784825205803,
                0.02320019155740738,
                0.24906931817531586,
                -0.04112955182790756,
                0.03186120465397835,
                0.1012519896030426,
                0.4538475275039673,
                -0.024707745760679245,
                -0.04706590622663498,
                -0.06490873545408249
            ],
            [
                0.0900871679186821,
                -0.04270084574818611,
                0.07705842703580856,
                0.16943195462226868,
                -0.030027290806174278,
                0.21931397914886475,
                0.21572990715503693,
                -0.04815603420138359,
                0.02631896547973156,
                0.20742735266685486,
                0.23575706779956818,
                0.06457791477441788,
                0.24858878552913666,
                0.35744965076446533,
                -0.0786699578166008,
                -0.14591147005558014
            ],
            [
                0.07060456275939941,
                0.1199265643954277,
                0.13259214162826538,
                0.13789428770542145,
                -0.08251374214887619,
                0.2664355933666229,
                0.05342862382531166,
                0.26810628175735474,
                -0.024087034165859222,
                0.27659744024276733,
                0.21884961426258087,
                0.08991079777479172,
                -0.03903960436582565,
                0.27778249979019165,
                0.12938734889030457,
                -0.009180751629173756
            ],
            [
                0.14033427834510803,
                0.01203687023371458,
                0.10177967697381973,
                0.0928737074136734,
                0.15632496774196625,
                0.1410500407218933,
                0.05444975569844246,
                0.10193634033203125,
                0.06476648896932602,
                0.17202962934970856,
                -0.06081297993659973,
                0.0011428649304434657,
                0.10209406167268753,
                0.21937566995620728,
                0.2523384690284729,
                0.20197439193725586
            ],
            [
                -0.07223624736070633,
                0.07884600013494492,
                0.07089798152446747,
                0.06240544840693474,
                0.09320685267448425,
                -0.02689555287361145,
                -0.041272059082984924,
                -0.057504504919052124,
                -0.021655062213540077,
                0.03696455806493759,
                0.17071758210659027,
                0.04529697075486183,
                -0.0074707623571157455,
                -0.021444540470838547,
                0.3465675711631775,
                0.01998230069875717
            ]
        ],
        [
            [
                3.5665571689605713,
                3.8282203674316406,
                3.2356348037719727,
                2.5553536415100098,
                3.522777557373047,
                2.9006996154785156,
                2.5118520259857178,
                1.8894482851028442,
                4.115326404571533,
                3.2753193378448486,
                1.4779719114303589,
                1.1616244316101074,
                2.1016957759857178,
                3.0040647983551025,
                3.43664288520813,
                5.2871317863464355
            ],
            [
                0.12063281238079071,
                1.622986912727356,
                -0.12065211683511734,
                -0.09941786527633667,
                2.3796029090881348,
                2.8481431007385254,
                2.782176971435547,
                3.2928497791290283,
                0.1390775442123413,
                -0.11757493764162064,
                -0.16783614456653595,
                0.06019460782408714,
                -0.015692515298724174,
                1.6184581518173218,
                2.8770158290863037,
                2.5809874534606934
            ],
            [
                2.10972261428833,
                2.1081185340881348,
                4.062289714813232,
                1.295847773551941,
                1.1675328016281128,
                2.208228349685669,
                4.1422271728515625,
                0.31360095739364624,
                1.35386323928833,
                0.23393552005290985,
                -0.024410106241703033,
                2.06416916847229,
                3.1602070331573486,
                3.666977882385254,
                0.9592796564102173,
                4.792718887329102
            ],
            [
                3.4041991233825684,
                1.853147029876709,
                -0.12345849722623825,
                -0.14341023564338684,
                0.36952531337738037,
                0.012701105326414108,
                2.5300076007843018,
                -0.06101552024483681,
                1.8878719806671143,
                0.985543966293335,
                -0.1025402769446373,
                -0.005877737421542406,
                1.0636128187179565,
                3.6354198455810547,
                4.515523910522461,
                3.2988104820251465
            ],
            [
                4.530446529388428,
                4.3625102043151855,
                0.17864695191383362,
                -0.0037381802685558796,
                0.17024116218090057,
                0.0013152356259524822,
                1.7659916877746582,
                3.4202141761779785,
                3.172931432723999,
                1.1904984712600708,
                0.2987070083618164,
                0.8560745716094971,
                2.606060266494751,
                -0.1627434641122818,
                1.2141274213790894,
                3.6819114685058594
            ],
            [
                2.5129785537719727,
                1.7147637605667114,
                2.9534637928009033,
                0.17630605399608612,
                0.4834589660167694,
                1.6946853399276733,
                0.362274169921875,
                2.873264789581299,
                0.7454466819763184,
                0.9430094361305237,
                0.7098788619041443,
                4.259371757507324,
                1.8362561464309692,
                2.4231796264648438,
                0.8317825198173523,
                2.14935564994812
            ],
            [
                0.6437855362892151,
                -0.166731595993042,
                2.302661180496216,
                1.4251391887664795,
                1.2694875001907349,
                3.2978310585021973,
                3.84861159324646,
                2.365718126296997,
                3.302661418914795,
                1.2244911193847656,
                0.6738113164901733,
                -0.12736494839191437,
                2.3698933124542236,
                5.008107662200928,
                4.734123229980469,
                4.8837571144104
            ],
            [
                0.7774562239646912,
                1.5037950277328491,
                0.9559237957000732,
                0.9108916521072388,
                1.708880066871643,
                2.611614227294922,
                3.848839521408081,
                2.665661573410034,
                6.022080898284912,
                3.082341432571411,
                -0.1401987373828888,
                -0.1671287566423416,
                0.02130289562046528,
                5.538646221160889,
                2.186418056488037,
                1.5895432233810425
            ],
            [
                -0.1684526652097702,
                -0.14682933688163757,
                -0.17002955079078674,
                -0.1556343138217926,
                -0.16853611171245575,
                -0.1668107509613037,
                -0.15683013200759888,
                -0.164973184466362,
                -0.1529831737279892,
                -0.16529826819896698,
                -0.16716554760932922,
                -0.16075466573238373,
                -0.16908349096775055,
                -0.10564578324556351,
                -0.16512633860111237,
                -0.16958989202976227
            ],
            [
                -0.16780565679073334,
                -0.1699836701154709,
                -0.1666242778301239,
                -0.16332106292247772,
                -0.15971378982067108,
                -0.16750989854335785,
                -0.10502779483795166,
                -0.1587989330291748,
                -0.14251023530960083,
                -0.13964368402957916,
                -0.16701824963092804,
                -0.12141700088977814,
                -0.13239237666130066,
                -0.13612046837806702,
                -0.15578554570674896,
                -0.1500483751296997
            ],
            [
                -0.15880891680717468,
                -0.15561671555042267,
                -0.14038431644439697,
                -0.14564149081707,
                -0.1573815643787384,
                -0.14978164434432983,
                -0.11949937790632248,
                -0.14556185901165009,
                -0.12421996146440506,
                -0.1509300321340561,
                -0.162051722407341,
                -0.11154498904943466,
                -0.15536853671073914,
                -0.12306107580661774,
                -0.1546744853258133,
                -0.1699228584766388
            ],
            [
                -0.16023647785186768,
                -0.13602450489997864,
                -0.13416580855846405,
                -0.1692265421152115,
                -0.0941055566072464,
                -0.16871891915798187,
                -0.136910080909729,
                -0.16932988166809082,
                -0.12609778344631195,
                -0.14651687443256378,
                -0.126729816198349,
                -0.16626563668251038,
                -0.16826412081718445,
                -0.1609555184841156,
                -0.1694897711277008,
                -0.1281471848487854
            ],
            [
                -0.16990648210048676,
                -0.13376224040985107,
                -0.15556743741035461,
                -0.14330902695655823,
                -0.16975654661655426,
                -0.13231533765792847,
                -0.11588037759065628,
                -0.16845013201236725,
                -0.1326178014278412,
                -0.13942880928516388,
                -0.1339988261461258,
                -0.16601814329624176,
                -0.14469969272613525,
                -0.1175796166062355,
                -0.1383955180644989,
                -0.14339950680732727
            ],
            [
                -0.16956229507923126,
                -0.1537024825811386,
                -0.1674152910709381,
                -0.13293246924877167,
                -0.16911542415618896,
                -0.10980742424726486,
                -0.11238442361354828,
                -0.10766365379095078,
                -0.15200670063495636,
                -0.1662747710943222,
                -0.1677873134613037,
                -0.11569207161664963,
                -0.09968815743923187,
                -0.1528046578168869,
                -0.16333475708961487,
                -0.1508471816778183
            ],
            [
                -0.16689756512641907,
                -0.15234945714473724,
                -0.1605129987001419,
                -0.16972550749778748,
                -0.08004781603813171,
                -0.1646469086408615,
                -0.15272140502929688,
                -0.12208810448646545,
                -0.11602980643510818,
                -0.16906677186489105,
                -0.16876260936260223,
                -0.16971616446971893,
                -0.13357354700565338,
                -0.16979943215847015,
                -0.16986508667469025,
                -0.009772183373570442
            ],
            [
                -0.16518253087997437,
                -0.15973761677742004,
                -0.1689421832561493,
                -0.16831639409065247,
                -0.16978514194488525,
                -0.16864599287509918,
                -0.15530189871788025,
                -0.1698680818080902,
                -0.14242173731327057,
                -0.16673389077186584,
                -0.1574796587228775,
                -0.16832220554351807,
                -0.15706022083759308,
                -0.16416098177433014,
                -0.15452751517295837,
                -0.13477973639965057
            ]
        ],
        [
            [
                0.7470623850822449,
                0.5673587322235107,
                1.0937288999557495,
                0.4837587773799896,
                0.6540294885635376,
                0.374979168176651,
                0.15976746380329132,
                0.16490551829338074,
                0.5947537422180176,
                0.3569418489933014,
                0.6868759393692017,
                0.9757565259933472,
                0.05474788695573807,
                0.1273561418056488,
                -0.122775137424469,
                0.09916575998067856
            ],
            [
                0.3395436406135559,
                -0.08750870823860168,
                -0.15421149134635925,
                -0.005436931736767292,
                1.1205732822418213,
                -0.15277740359306335,
                -0.04473813623189926,
                -0.16283534467220306,
                -0.1309618204832077,
                -0.10142990201711655,
                -0.16295315325260162,
                0.2223212569952011,
                -0.15330533683300018,
                -0.1700156033039093,
                0.4873301386833191,
                0.24454368650913239
            ],
            [
                0.7842392325401306,
                -0.10995923727750778,
                -0.1623031198978424,
                -0.12427245080471039,
                -0.09727112948894501,
                -0.16163834929466248,
                0.1285872906446457,
                -0.03694207966327667,
                -0.1625172197818756,
                -0.14196254312992096,
                0.17479397356510162,
                0.052531179040670395,
                -0.11861076205968857,
                -0.04529937729239464,
                0.08925608545541763,
                -0.15008211135864258
            ],
            [
                0.28340601921081543,
                -0.13665005564689636,
                -0.09435754269361496,
                -0.09941163659095764,
                -0.16072288155555725,
                -0.15466198325157166,
                -0.1669609546661377,
                -0.07447697967290878,
                -0.14580702781677246,
                -0.14355182647705078,
                -0.10141582787036896,
                -0.15858367085456848,
                -0.14638736844062805,
                0.19985699653625488,
                -0.07790964841842651,
                -0.13055436313152313
            ],
            [
                0.4890514016151428,
                -0.14086514711380005,
                -0.16356295347213745,
                -0.14941458404064178,
                -0.05667715519666672,
                -0.059291694313287735,
                -0.16079169511795044,
                -0.07334327697753906,
                -0.09570291638374329,
                -0.15624620020389557,
                0.17801208794116974,
                -0.15153566002845764,
                -0.12757796049118042,
                -0.14475730061531067,
                -0.1695365458726883,
                0.03924182057380676
            ],
            [
                0.9256237745285034,
                -0.16177351772785187,
                -0.16747891902923584,
                -0.14437758922576904,
                -0.15553003549575806,
                0.04662651941180229,
                -0.1573830395936966,
                -0.12381286174058914,
                0.1814499944448471,
                -0.15809863805770874,
                -0.15233485400676727,
                1.077623724937439,
                -0.08951511979103088,
                -0.0717393308877945,
                -0.16688118875026703,
                0.08397375047206879
            ],
            [
                0.16997960209846497,
                -0.16683660447597504,
                -0.16903449594974518,
                -0.1481107771396637,
                -0.09449451416730881,
                -0.061282359063625336,
                -0.12542180716991425,
                -0.16351068019866943,
                -0.11456488072872162,
                -0.15088790655136108,
                0.3410455286502838,
                0.05686601996421814,
                -0.16466224193572998,
                0.532861590385437,
                -0.16137303411960602,
                0.23061338067054749
            ],
            [
                0.9604212045669556,
                -0.08759855479001999,
                0.3298156261444092,
                0.16948933899402618,
                -0.11815661936998367,
                0.17825421690940857,
                -0.13576045632362366,
                0.0018323879921808839,
                0.01622331514954567,
                -0.12811721861362457,
                -0.01855349913239479,
                0.3812684118747711,
                -0.08856374770402908,
                0.24976032972335815,
                0.14795947074890137,
                0.7188243865966797
            ],
            [
                -0.13745367527008057,
                0.09975847601890564,
                -0.07052203267812729,
                0.006584370043128729,
                -0.16196458041667938,
                -0.13728898763656616,
                -0.1681891679763794,
                -0.14674660563468933,
                -0.06005663797259331,
                -0.11061178892850876,
                -0.13270747661590576,
                -0.12361395359039307,
                -0.07562079280614853,
                -0.16613465547561646,
                -0.11757226288318634,
                -0.06906646490097046
            ],
            [
                -0.15706613659858704,
                -0.15066346526145935,
                -0.15158146619796753,
                -0.15878306329250336,
                -0.12135174870491028,
                -0.1539326310157776,
                -0.16279105842113495,
                -0.1672220677137375,
                -0.17002782225608826,
                -0.1383388638496399,
                -0.1547197699546814,
                -0.1577778458595276,
                -0.16403983533382416,
                -0.16466709971427917,
                -0.166032075881958,
                -0.028476787731051445
            ],
            [
                -0.16483135521411896,
                -0.1648012399673462,
                -0.1699809581041336,
                -0.14230240881443024,
                -0.1477086842060089,
                -0.1699741929769516,
                -0.1699470430612564,
                -0.14903923869132996,
                -0.16418364644050598,
                -0.16191406548023224,
                -0.15712618827819824,
                -0.16640476882457733,
                -0.16782359778881073,
                -0.1679430902004242,
                -0.1679852306842804,
                -0.016877900809049606
            ],
            [
                -0.16481761634349823,
                -0.16985578835010529,
                -0.16965222358703613,
                -0.0869678258895874,
                -0.026712248101830482,
                -0.15526333451271057,
                -0.1674068123102188,
                -0.15879330039024353,
                -0.16989697515964508,
                -0.1668878048658371,
                -0.16659457981586456,
                -0.16882948577404022,
                -0.1667739748954773,
                -0.17003950476646423,
                -0.15160728991031647,
                -0.07155987620353699
            ],
            [
                -0.13851812481880188,
                -0.1627793312072754,
                -0.10225240886211395,
                -0.16963158547878265,
                -0.13561178743839264,
                -0.16909384727478027,
                -0.17001262307167053,
                -0.16453464329242706,
                -0.16985861957073212,
                -0.162397101521492,
                -0.1460222601890564,
                -0.05260205641388893,
                -0.1692378968000412,
                -0.16643309593200684,
                -0.1098429411649704,
                0.036278195679187775
            ],
            [
                -0.16378900408744812,
                -0.16998536884784698,
                -0.1632205992937088,
                -0.16965024173259735,
                -0.11339583992958069,
                -0.16850018501281738,
                -0.16903483867645264,
                -0.1619797646999359,
                -0.16958624124526978,
                -0.1675577461719513,
                -0.07071360945701599,
                0.04719003289937973,
                -0.020173875615000725,
                -0.16954748332500458,
                -0.15764741599559784,
                -0.08537231385707855
            ],
            [
                -0.15904280543327332,
                -0.15844479203224182,
                -0.15898729860782623,
                -0.09225460886955261,
                -0.07452081143856049,
                -0.16804136335849762,
                -0.15462329983711243,
                -0.16447626054286957,
                -0.16695380210876465,
                -0.1556549072265625,
                -0.108027882874012,
                -0.14698806405067444,
                -0.16941417753696442,
                -0.13755883276462555,
                -0.11584428697824478,
                0.09253567457199097
            ],
            [
                -0.1478744000196457,
                -0.16991180181503296,
                -0.151521697640419,
                -0.1699666529893875,
                -0.13651210069656372,
                -0.15671025216579437,
                -0.1697879582643509,
                -0.1686449646949768,
                -0.16675332188606262,
                -0.16932150721549988,
                -0.16963893175125122,
                -0.16611094772815704,
                -0.16957618296146393,
                -0.16853363811969757,
                -0.1679876148700714,
                -0.1077103242278099
            ]
        ],
        [
            [
                -0.1475675106048584,
                -0.15948441624641418,
                2.7607851028442383,
                2.549794912338257,
                2.257164478302002,
                0.6688138246536255,
                0.7146242260932922,
                0.053001049906015396,
                0.5880693793296814,
                0.9179750680923462,
                0.8128539323806763,
                2.5322859287261963,
                -0.022555630654096603,
                0.2642335593700409,
                2.0177266597747803,
                -0.10404105484485626
            ],
            [
                1.7581535577774048,
                -0.1510823369026184,
                0.8780473470687866,
                2.6260221004486084,
                0.10528510808944702,
                0.35686376690864563,
                -0.14755642414093018,
                2.2843244075775146,
                1.4427144527435303,
                1.1673400402069092,
                0.22650790214538574,
                0.8835561275482178,
                0.3838188648223877,
                -0.1593388319015503,
                2.3060338497161865,
                -0.07500296831130981
            ],
            [
                1.079565405845642,
                0.3025674521923065,
                -0.15405228734016418,
                -0.1697041541337967,
                -0.06383902579545975,
                0.03972973674535751,
                0.006921369582414627,
                0.2978772222995758,
                -0.16368909180164337,
                0.03921135887503624,
                0.43935397267341614,
                0.7411251664161682,
                -0.11583263427019119,
                -0.16660507023334503,
                4.268080711364746,
                1.3250974416732788
            ],
            [
                -0.13876976072788239,
                -0.1625872254371643,
                0.3202856481075287,
                1.0043683052062988,
                0.35232576727867126,
                0.3304688334465027,
                -0.0484924390912056,
                4.103034019470215,
                -0.11366770416498184,
                1.6097722053527832,
                0.870188295841217,
                0.3506980836391449,
                0.1596384346485138,
                1.2493205070495605,
                -0.1699855625629425,
                -0.16241806745529175
            ],
            [
                0.769554615020752,
                -0.16501635313034058,
                0.9389632344245911,
                2.439500570297241,
                0.7924869060516357,
                -0.08796630054712296,
                1.0952749252319336,
                -0.11227422207593918,
                -0.0269169881939888,
                0.23987139761447906,
                1.4161432981491089,
                0.6907008290290833,
                0.6812114119529724,
                2.4533870220184326,
                0.7443069219589233,
                -0.04574522376060486
            ],
            [
                -0.048173800110816956,
                -0.1700049191713333,
                -0.1392744928598404,
                1.563851237297058,
                -0.13208426535129547,
                -0.1541581153869629,
                1.6501152515411377,
                -0.16982102394104004,
                0.2325751930475235,
                0.41205739974975586,
                0.9880347847938538,
                0.7467659711837769,
                -0.10892105847597122,
                0.43869632482528687,
                0.8795753717422485,
                0.5561039447784424
            ],
            [
                0.8114190697669983,
                0.853706419467926,
                0.880753755569458,
                0.7274103164672852,
                0.4338274598121643,
                -0.13776016235351562,
                -0.14751781523227692,
                -0.0737975537776947,
                -0.053496282547712326,
                0.38618379831314087,
                0.10015265643596649,
                1.2932628393173218,
                1.1973118782043457,
                2.4705591201782227,
                -0.1653013974428177,
                -0.15275593101978302
            ],
            [
                -0.15743544697761536,
                0.6034163236618042,
                0.0008596543339081109,
                -0.0191678237169981,
                0.7137566208839417,
                -0.12322608381509781,
                -0.16687503457069397,
                0.8136307001113892,
                0.5378772020339966,
                -0.060238368809223175,
                0.009995019994676113,
                -0.16747327148914337,
                0.007492718752473593,
                2.135683059692383,
                0.8317226767539978,
                0.8156198263168335
            ],
            [
                0.7298148274421692,
                0.49394723773002625,
                0.4323306083679199,
                0.4498908817768097,
                0.3716460168361664,
                1.0278409719467163,
                0.9971293210983276,
                1.1213469505310059,
                0.6799308657646179,
                0.6269313097000122,
                0.5883487462997437,
                0.6777382493019104,
                1.0823084115982056,
                0.6915321946144104,
                0.5944505929946899,
                0.28417372703552246
            ],
            [
                0.5674628615379333,
                0.44302091002464294,
                0.6759331226348877,
                0.6117017269134521,
                0.9864876866340637,
                0.7289775013923645,
                1.197625994682312,
                1.1791608333587646,
                0.6511650085449219,
                1.0939921140670776,
                0.6458868384361267,
                0.6355738043785095,
                1.007120966911316,
                0.7319253087043762,
                0.3327646851539612,
                0.4506094455718994
            ],
            [
                0.5723488330841064,
                0.3115142285823822,
                0.8620028495788574,
                1.010340929031372,
                0.3033292591571808,
                0.45025983452796936,
                0.7640647888183594,
                0.883101224899292,
                0.9754717350006104,
                0.9214414954185486,
                0.4580708146095276,
                0.6675722599029541,
                0.8873270750045776,
                0.7585431337356567,
                0.6591952443122864,
                0.7402791380882263
            ],
            [
                0.7185797095298767,
                0.5276160836219788,
                0.47535932064056396,
                0.4925278127193451,
                0.8265717029571533,
                0.6195042729377747,
                0.4937652051448822,
                0.47978097200393677,
                0.7468101978302002,
                0.7531814575195312,
                0.6840603947639465,
                0.9656619429588318,
                0.6272342205047607,
                0.393444299697876,
                0.4886239469051361,
                0.07531162351369858
            ],
            [
                0.8292533159255981,
                0.8080648183822632,
                0.5780315399169922,
                0.2964995205402374,
                0.8108072280883789,
                0.8853009343147278,
                1.0270183086395264,
                0.6704087853431702,
                0.4594024419784546,
                0.7854472994804382,
                0.8222740888595581,
                0.7617197036743164,
                0.7468302845954895,
                0.9008337259292603,
                1.1341513395309448,
                0.14666034281253815
            ],
            [
                0.789825439453125,
                1.0582849979400635,
                0.2651865482330322,
                0.7381905317306519,
                0.9341108798980713,
                0.5017590522766113,
                1.0979453325271606,
                1.06161367893219,
                0.942123532295227,
                0.7881428599357605,
                0.39514636993408203,
                0.22191964089870453,
                0.6002289056777954,
                0.6645328998565674,
                0.7770183086395264,
                0.6679904460906982
            ],
            [
                0.8877760767936707,
                0.5884659290313721,
                0.2938295900821686,
                0.4686015546321869,
                0.6627597808837891,
                0.7102158069610596,
                0.9388900399208069,
                0.8724052309989929,
                1.1976323127746582,
                0.42271310091018677,
                0.7020726203918457,
                0.25384819507598877,
                0.4985989034175873,
                0.450418621301651,
                0.6796744465827942,
                0.8525170087814331
            ],
            [
                0.7139617204666138,
                0.6595112085342407,
                0.46089237928390503,
                0.27016744017601013,
                0.44300729036331177,
                0.38665860891342163,
                0.29257839918136597,
                0.49967870116233826,
                0.5522741675376892,
                0.35431358218193054,
                0.3993077278137207,
                0.4004558324813843,
                0.5707857608795166,
                0.17020103335380554,
                0.6447036862373352,
                0.2933593690395355
            ]
        ],
        [
            [
                2.93426251411438,
                3.0327842235565186,
                3.4302375316619873,
                3.0349597930908203,
                3.9862403869628906,
                2.9433887004852295,
                3.2064197063446045,
                1.905332088470459,
                3.1344146728515625,
                2.1543524265289307,
                1.115902304649353,
                2.7221245765686035,
                0.8478671312332153,
                3.6715171337127686,
                4.217919826507568,
                4.796433448791504
            ],
            [
                0.9674034118652344,
                2.687098979949951,
                1.5679967403411865,
                2.1935298442840576,
                2.499689817428589,
                5.694387912750244,
                2.994389533996582,
                3.173220157623291,
                4.274698734283447,
                0.010010951198637486,
                0.07779312133789062,
                2.039794921875,
                0.42904335260391235,
                4.212472915649414,
                4.448010444641113,
                3.3565473556518555
            ],
            [
                0.7578755617141724,
                5.145705223083496,
                3.5005416870117188,
                2.044381856918335,
                2.8134984970092773,
                5.292245388031006,
                4.076343536376953,
                2.1252264976501465,
                2.1649835109710693,
                0.26500773429870605,
                -0.024747459217905998,
                2.0554823875427246,
                2.7267990112304688,
                5.778446674346924,
                4.741420745849609,
                4.65438175201416
            ],
            [
                4.341490268707275,
                1.4490463733673096,
                -0.11789196729660034,
                0.6488174796104431,
                2.0420947074890137,
                4.830374240875244,
                4.143340587615967,
                0.011609267443418503,
                2.6668541431427,
                0.07550936192274094,
                0.4374698996543884,
                0.7791822552680969,
                2.5464086532592773,
                5.332005023956299,
                7.850192546844482,
                1.4218357801437378
            ],
            [
                3.861272096633911,
                4.043615341186523,
                1.4070053100585938,
                -0.09646826237440109,
                0.793136477470398,
                3.2002995014190674,
                2.2794580459594727,
                4.269684791564941,
                4.235054969787598,
                2.632758617401123,
                -0.1668998897075653,
                0.53358393907547,
                0.6586071848869324,
                1.1908475160598755,
                1.4211270809173584,
                2.15090012550354
            ],
            [
                2.675459861755371,
                1.4580081701278687,
                2.1098392009735107,
                -0.08422307670116425,
                0.8828864097595215,
                2.8769900798797607,
                3.0074360370635986,
                2.176051378250122,
                0.33107513189315796,
                0.03383345901966095,
                3.7324986457824707,
                9.289061546325684,
                4.005415916442871,
                2.8951520919799805,
                2.4564807415008545,
                0.9970956444740295
            ],
            [
                0.49154728651046753,
                0.10526370257139206,
                2.879110336303711,
                1.437540888786316,
                1.622705101966858,
                2.9772539138793945,
                4.00519323348999,
                0.5512195825576782,
                4.2422776222229,
                2.8486008644104004,
                3.823580741882324,
                2.015920877456665,
                3.541139841079712,
                7.719195365905762,
                5.799604415893555,
                5.784018516540527
            ],
            [
                1.9199635982513428,
                2.6527302265167236,
                4.445423126220703,
                5.933680534362793,
                0.5427508354187012,
                2.9402010440826416,
                5.179770469665527,
                1.7754974365234375,
                4.8147873878479,
                2.303105354309082,
                1.1710416078567505,
                1.08380126953125,
                2.7815420627593994,
                11.546307563781738,
                8.961825370788574,
                0.9639583230018616
            ],
            [
                -0.03390622138977051,
                -0.11892075836658478,
                -0.03179765120148659,
                -0.1688714474439621,
                -0.1268615424633026,
                -0.09771531075239182,
                0.22349229454994202,
                0.27497875690460205,
                -0.15818479657173157,
                -0.05449763312935829,
                0.08462904393672943,
                0.002993502886965871,
                -0.14630496501922607,
                -0.16920606791973114,
                -0.1699446737766266,
                -0.1345466822385788
            ],
            [
                0.09929926693439484,
                0.0034041807521134615,
                0.030223950743675232,
                0.13621912896633148,
                0.0667479932308197,
                -0.14774760603904724,
                -0.10112468898296356,
                -0.07914603501558304,
                -0.024538932368159294,
                -0.04081811010837555,
                -0.14306160807609558,
                0.07454697787761688,
                -0.1177741214632988,
                -0.10562228411436081,
                0.14187045395374298,
                -0.004645438399165869
            ],
            [
                0.01134306751191616,
                -0.13307645916938782,
                0.023815331980586052,
                -0.04349829629063606,
                -0.1230541467666626,
                -0.1228722631931305,
                -0.04487801715731621,
                -0.11185825616121292,
                0.019692344591021538,
                -0.04798253998160362,
                -0.014835613779723644,
                -0.09899365901947021,
                -0.03296668455004692,
                -0.031839851289987564,
                -0.07919509708881378,
                0.09224256128072739
            ],
            [
                0.10152452439069748,
                -0.1319275200366974,
                -0.16493625938892365,
                -0.10929010808467865,
                -0.14001891016960144,
                0.006764852907508612,
                -0.13032273948192596,
                -0.05690426006913185,
                -0.1641107201576233,
                -0.0577094592154026,
                -0.1660865694284439,
                -0.08636931329965591,
                -0.04478907957673073,
                -0.11922244727611542,
                -0.029958516359329224,
                0.1492963433265686
            ],
            [
                -0.09315866976976395,
                -0.0664789155125618,
                -0.1487531065940857,
                -0.09363795071840286,
                -0.041672833263874054,
                -0.1552133858203888,
                0.003754975041374564,
                0.025500064715743065,
                -0.03223579749464989,
                -0.06922941654920578,
                0.04878198355436325,
                0.009211566299200058,
                -0.07458712160587311,
                0.20237460732460022,
                0.13314220309257507,
                0.019696786999702454
            ],
            [
                -0.08493193238973618,
                -0.07217171788215637,
                0.10419994592666626,
                -0.14537771046161652,
                -0.004228237085044384,
                -0.07010485976934433,
                -0.08435056358575821,
                -0.11156647652387619,
                -0.11059501767158508,
                -0.1400335431098938,
                -0.16882853209972382,
                0.4155159294605255,
                -0.08494089543819427,
                -0.12244511395692825,
                -0.0531812347471714,
                -0.06445485353469849
            ],
            [
                -0.06844406574964523,
                -0.06323237717151642,
                -0.13929899036884308,
                -0.020964277908205986,
                0.010086677968502045,
                -0.16404129564762115,
                -0.06031087413430214,
                -0.10012105852365494,
                -0.12082924693822861,
                -0.12772081792354584,
                0.06703130900859833,
                -0.16959495842456818,
                -0.04524395242333412,
                0.0001679512788541615,
                -0.10299582779407501,
                -0.10393110662698746
            ],
            [
                0.12887772917747498,
                -0.06247356906533241,
                0.0587577149271965,
                -0.09515977650880814,
                -0.0654074028134346,
                -0.09540723264217377,
                -0.15506279468536377,
                -0.11996306478977203,
                -0.13570712506771088,
                -0.058603741228580475,
                -0.16840845346450806,
                0.05619002506136894,
                0.027293521910905838,
                -0.12748192250728607,
                -0.13527412712574005,
                -0.16700047254562378
            ]
        ],
        [
            [
                0.05101596564054489,
                1.0482573509216309,
                0.8312065601348877,
                1.9730002880096436,
                1.1443074941635132,
                0.20759309828281403,
                0.07742848992347717,
                -0.10681989789009094,
                0.22982114553451538,
                1.376671314239502,
                -0.11509201675653458,
                1.2282557487487793,
                1.1538933515548706,
                1.4241000413894653,
                3.994539976119995,
                -0.10428021848201752
            ],
            [
                0.6571188569068909,
                3.648855686187744,
                1.986873984336853,
                2.0560178756713867,
                3.1007015705108643,
                2.5306732654571533,
                0.5470560789108276,
                3.7207930088043213,
                3.426408529281616,
                -0.1469738632440567,
                -0.1669800579547882,
                2.875056266784668,
                0.9408820271492004,
                3.2955193519592285,
                2.609984874725342,
                0.6475581526756287
            ],
            [
                0.1769511103630066,
                2.962493896484375,
                1.7008349895477295,
                2.484893321990967,
                4.407683372497559,
                4.76102876663208,
                1.2419077157974243,
                0.849791407585144,
                1.127516746520996,
                0.054921429604291916,
                -0.06793507933616638,
                3.4476449489593506,
                3.305934190750122,
                5.7029218673706055,
                4.687777042388916,
                1.2297922372817993
            ],
            [
                0.3713466227054596,
                2.4567267894744873,
                0.05969563126564026,
                2.5089008808135986,
                4.768471717834473,
                3.876993417739868,
                2.013572931289673,
                0.1137240007519722,
                2.1957881450653076,
                1.2638756036758423,
                -0.04299022629857063,
                1.112735629081726,
                2.133533000946045,
                3.6869308948516846,
                3.0048656463623047,
                0.7917864322662354
            ],
            [
                0.4849965274333954,
                2.249763250350952,
                2.0311758518218994,
                0.5878125429153442,
                1.864796757698059,
                3.0874218940734863,
                0.9191259741783142,
                2.7117059230804443,
                2.480860710144043,
                2.635531425476074,
                0.3274080455303192,
                2.2670788764953613,
                2.6802480220794678,
                1.5370532274246216,
                3.033313751220703,
                -0.17000597715377808
            ],
            [
                0.44516775012016296,
                1.0057176351547241,
                2.2462854385375977,
                -0.14758507907390594,
                0.3071210980415344,
                2.0018112659454346,
                0.3224090337753296,
                0.5026473999023438,
                0.7204424142837524,
                1.4616633653640747,
                3.4874868392944336,
                4.102704048156738,
                2.713822841644287,
                3.000661849975586,
                3.920283079147339,
                -0.1676967889070511
            ],
            [
                0.3736012279987335,
                0.7318570017814636,
                1.3914456367492676,
                2.0588717460632324,
                1.3076030015945435,
                2.068861722946167,
                0.9186854362487793,
                0.09249649941921234,
                3.3197362422943115,
                2.8535542488098145,
                3.869518518447876,
                2.953742742538452,
                4.139951229095459,
                3.4813101291656494,
                3.7049996852874756,
                0.3526097238063812
            ],
            [
                -0.0437735952436924,
                2.6695356369018555,
                4.136012554168701,
                4.760985374450684,
                0.5678751468658447,
                0.01620074175298214,
                0.4909742474555969,
                0.2602427005767822,
                2.111785888671875,
                0.6279686689376831,
                -0.051820285618305206,
                0.10691410303115845,
                2.625387191772461,
                5.2776713371276855,
                2.7686033248901367,
                -0.08115740120410919
            ],
            [
                -0.16865509748458862,
                -0.14479054510593414,
                0.0012826542370021343,
                -0.1301131248474121,
                -0.03992244228720665,
                -0.17002920806407928,
                -0.1008496806025505,
                -0.13985532522201538,
                -0.12937353551387787,
                -0.10706812143325806,
                -0.02833557315170765,
                -0.09339015930891037,
                -0.01975175179541111,
                -0.05465472862124443,
                -0.14744582772254944,
                -0.09859088808298111
            ],
            [
                -0.1659994125366211,
                -0.15400974452495575,
                -0.08672351390123367,
                -0.11727938055992126,
                -0.03567328676581383,
                -0.16987662017345428,
                -0.15378975868225098,
                -0.1386939436197281,
                -0.15841583907604218,
                -0.16109640896320343,
                -0.11373280733823776,
                -0.14973340928554535,
                -0.16976948082447052,
                -0.16800953447818756,
                -0.06411749869585037,
                -0.09906938672065735
            ],
            [
                -0.16949650645256042,
                -0.16901974380016327,
                -0.12858016788959503,
                -0.15881133079528809,
                -0.13420256972312927,
                -0.10683689266443253,
                -0.12109216302633286,
                -0.08030155301094055,
                -0.1120646595954895,
                -0.12168750166893005,
                -0.14410638809204102,
                0.006302219815552235,
                -0.16746672987937927,
                -0.1650696098804474,
                -0.16952554881572723,
                -0.15787865221500397
            ],
            [
                -0.16646982729434967,
                -0.1659659445285797,
                -0.1504300981760025,
                -0.1602228581905365,
                -0.16214072704315186,
                -0.0731421560049057,
                -0.14471663534641266,
                -0.13693298399448395,
                -0.06035777926445007,
                -0.12216753512620926,
                -0.12296496331691742,
                -0.0781911164522171,
                -0.15931369364261627,
                -0.15638886392116547,
                0.03776225820183754,
                -0.14963193237781525
            ],
            [
                -0.12488825619220734,
                -0.13696584105491638,
                -0.16599233448505402,
                -0.1607930064201355,
                -0.12563925981521606,
                -0.09888822585344315,
                -0.16982117295265198,
                -0.12732332944869995,
                -0.14900217950344086,
                -0.10058161616325378,
                -0.05456022173166275,
                -0.10804890841245651,
                -0.16949087381362915,
                -0.14915907382965088,
                0.07437924295663834,
                -0.16385748982429504
            ],
            [
                -0.12369212508201599,
                -0.15543562173843384,
                -0.07548778504133224,
                -0.13718099892139435,
                -0.038468196988105774,
                -0.01318820659071207,
                -0.16280512511730194,
                -0.16640537977218628,
                -0.12485554069280624,
                -0.11625267565250397,
                -0.16097593307495117,
                -0.15799380838871002,
                -0.12253826856613159,
                -0.15699484944343567,
                -0.13789358735084534,
                -0.16545797884464264
            ],
            [
                -0.16498138010501862,
                -0.16771043837070465,
                0.02867484837770462,
                -0.1687624603509903,
                -0.014560861513018608,
                -0.14359882473945618,
                -0.15935564041137695,
                -0.12941594421863556,
                -0.16858583688735962,
                -0.1524353176355362,
                -0.15124142169952393,
                -0.16803783178329468,
                -0.11860481649637222,
                -0.1425226926803589,
                -0.11503971368074417,
                -0.13274088501930237
            ],
            [
                -0.04187607020139694,
                -0.1456376165151596,
                -0.15607361495494843,
                -0.15641067922115326,
                -0.12266397476196289,
                -0.15960422158241272,
                -0.07457340508699417,
                -0.12880846858024597,
                -0.1558833122253418,
                -0.031685348600149155,
                -0.11490778625011444,
                -0.14673268795013428,
                -0.1494717299938202,
                -0.104102224111557,
                -0.10540451854467392,
                -0.16978351771831512
            ]
        ],
        [
            [
                1.8806836605072021,
                1.6465004682540894,
                0.09867819398641586,
                -0.05068559944629669,
                1.7566957473754883,
                0.73478102684021,
                0.8613855838775635,
                0.20683284103870392,
                2.5187573432922363,
                1.6873904466629028,
                -0.08928339183330536,
                -0.1329953968524933,
                0.039650142192840576,
                1.089547872543335,
                1.629356861114502,
                3.928143262863159
            ],
            [
                -0.0653749406337738,
                1.566643238067627,
                -0.012418868951499462,
                -0.009403309784829617,
                4.046790599822998,
                2.7842767238616943,
                0.3669256567955017,
                3.7449610233306885,
                -0.16895386576652527,
                -0.035194408148527145,
                -0.08955549448728561,
                -0.04072549194097519,
                -0.019766414538025856,
                0.604052722454071,
                0.9080277681350708,
                3.361938238143921
            ],
            [
                0.0411597341299057,
                2.7592573165893555,
                1.623824119567871,
                -0.029085613787174225,
                2.323598623275757,
                2.1761391162872314,
                3.478829860687256,
                -0.02274242602288723,
                0.00177380524110049,
                -0.16962537169456482,
                -0.10549426078796387,
                0.46223339438438416,
                3.1166961193084717,
                5.799581050872803,
                1.88590669631958,
                3.6473166942596436
            ],
            [
                3.7942631244659424,
                1.8721116781234741,
                -0.000025682669729576446,
                -0.00006670074071735144,
                1.61772882938385,
                0.16004784405231476,
                -0.05056373029947281,
                -0.002008062554523349,
                0.8813194632530212,
                -0.1546545922756195,
                -0.09867135435342789,
                -0.01964288391172886,
                -0.1090034544467926,
                4.619814395904541,
                4.196792125701904,
                1.4944132566452026
            ],
            [
                4.359381675720215,
                1.9985865354537964,
                -0.00021757282956968993,
                0,
                -0.0034015700221061707,
                -0.05174659937620163,
                2.5021438598632812,
                2.434333562850952,
                2.434983730316162,
                -0.15412382781505585,
                -0.023132475093007088,
                -0.14660924673080444,
                -0.021828439086675644,
                -0.0007407636730931699,
                0.10782955586910248,
                1.0916924476623535
            ],
            [
                0.35859858989715576,
                0.5489711165428162,
                0.755670428276062,
                0,
                -0.06956838071346283,
                -0.14186939597129822,
                -0.02140444703400135,
                -0.1324159801006317,
                -0.13006044924259186,
                0.09992269426584244,
                1.481486439704895,
                4.165116786956787,
                0.6012930274009705,
                0.20171242952346802,
                0.5017827749252319,
                0.023857075721025467
            ],
            [
                -0.05885440856218338,
                -0.11131555587053299,
                0.6204999685287476,
                -0.15523986518383026,
                -0.13688813149929047,
                2.6137423515319824,
                2.9668235778808594,
                0.16805337369441986,
                1.998802661895752,
                2.242020606994629,
                0.12084784358739853,
                -0.11148794740438461,
                2.882110834121704,
                6.035085201263428,
                4.187978267669678,
                5.710702896118164
            ],
            [
                0.07282049208879471,
                0.6333464980125427,
                0.4237058758735657,
                0.6051535606384277,
                1.614141583442688,
                2.8608832359313965,
                3.654463529586792,
                1.9218132495880127,
                4.4941606521606445,
                2.6630821228027344,
                -0.0022747197654098272,
                -0.028419066220521927,
                -0.007223467342555523,
                9.787574768066406,
                5.187159538269043,
                0.526060938835144
            ],
            [
                0.6203816533088684,
                0.2514079213142395,
                0.003186967456713319,
                0.37920939922332764,
                0.6996350288391113,
                0.08273337036371231,
                0.7122611999511719,
                0.4858201742172241,
                0.06426144391298294,
                0.1881662905216217,
                0.6239477396011353,
                0.6009154915809631,
                0.358084499835968,
                0.9238315224647522,
                0.7590994238853455,
                0.35057470202445984
            ],
            [
                0.42178094387054443,
                0.5669593214988708,
                0.24532602727413177,
                0.5099800229072571,
                0.6667211055755615,
                0.32539671659469604,
                0.693256676197052,
                0.4418290853500366,
                0.30850234627723694,
                0.31787145137786865,
                0.1596004068851471,
                0.9457204937934875,
                0.542679488658905,
                0.5861255526542664,
                0.3696756362915039,
                0.45972707867622375
            ],
            [
                0.8643049597740173,
                0.707476794719696,
                0.523716926574707,
                0.6689963936805725,
                0.34093374013900757,
                0.5110735297203064,
                0.47367799282073975,
                0.3389158546924591,
                0.30004650354385376,
                0.21870486438274384,
                0.24963659048080444,
                0.6722244024276733,
                0.16223981976509094,
                0.5480791926383972,
                0.2877771556377411,
                0.23156192898750305
            ],
            [
                0.4380999803543091,
                0.9370229244232178,
                0.867161750793457,
                0.12263943254947662,
                0.42342671751976013,
                0.7023274898529053,
                0.5349321365356445,
                0.9318775534629822,
                0.5814322829246521,
                0.6114386916160583,
                0.5062116980552673,
                0.6635029911994934,
                0.5578370690345764,
                0.40638116002082825,
                0.47904953360557556,
                0.18465732038021088
            ],
            [
                0.4619050920009613,
                0.6396633386611938,
                0.6298828721046448,
                0.790183961391449,
                0.5289917588233948,
                0.8139373660087585,
                0.7778471112251282,
                0.815761923789978,
                0.6412800550460815,
                0.9160324931144714,
                0.16897214949131012,
                0.7200475931167603,
                0.6090306639671326,
                0.4480929672718048,
                -0.10015944391489029,
                -0.07190204411745071
            ],
            [
                0.2598467469215393,
                0.5458163619041443,
                0.4114154279232025,
                0.6083179116249084,
                0.4046793282032013,
                0.4109797775745392,
                0.5732816457748413,
                0.3548201620578766,
                0.3096943199634552,
                0.7187622785568237,
                0.5692723393440247,
                0.12148535996675491,
                0.2780688405036926,
                0.4463520348072052,
                0.7400965690612793,
                0.11884326487779617
            ],
            [
                0.5505056977272034,
                0.844793438911438,
                0.5608843564987183,
                0.23368461430072784,
                0.3219006359577179,
                0.5535669326782227,
                0.5689561367034912,
                0.6059379577636719,
                0.6030195355415344,
                0.416704922914505,
                0.5814043879508972,
                0.5218201279640198,
                0.5841805338859558,
                0.47480401396751404,
                0.31434568762779236,
                -0.001900151139125228
            ],
            [
                0.5386483073234558,
                0.7010172009468079,
                0.5595369935035706,
                0.6897425055503845,
                0.6781096458435059,
                0.36789703369140625,
                0.5191982388496399,
                0.37349116802215576,
                0.7634971141815186,
                0.6818667054176331,
                0.5253153443336487,
                0.6171053647994995,
                0.4039537310600281,
                0.9218201041221619,
                0.8563908338546753,
                0.37975558638572693
            ]
        ],
        [
            [
                0.24067682027816772,
                0.757156252861023,
                -0.07527918368577957,
                -0.14809824526309967,
                -0.14645397663116455,
                -0.16894228756427765,
                -0.16245870292186737,
                -0.04662191867828369,
                -0.0996437594294548,
                1.0198861360549927,
                0.4499390125274658,
                0.7428793907165527,
                0.4388608932495117,
                -0.13876654207706451,
                2.663177490234375,
                -0.165786474943161
            ],
            [
                -0.13863040506839752,
                3.310004234313965,
                0.06333240866661072,
                0.16215090453624725,
                0.9528123140335083,
                1.0686182975769043,
                2.1913955211639404,
                0.7074083089828491,
                0.32932353019714355,
                -0.0893520936369896,
                -0.1513637900352478,
                -0.1404944360256195,
                -0.170002743601799,
                0.2034268081188202,
                1.2088364362716675,
                0.7652003765106201
            ],
            [
                -0.15063075721263885,
                1.862191915512085,
                1.4680441617965698,
                1.3153983354568481,
                0.6706864237785339,
                -0.12798619270324707,
                0.09014272689819336,
                -0.05479694530367851,
                2.1376888751983643,
                0.26522961258888245,
                -0.14612747728824615,
                0.0114133944734931,
                1.7016288042068481,
                1.6944899559020996,
                -0.16503600776195526,
                1.870131015777588
            ],
            [
                0.0944073423743248,
                0.25531288981437683,
                -0.07985452562570572,
                0.19963273406028748,
                0.8410612344741821,
                0.9297073483467102,
                0.28379377722740173,
                -0.01128105353564024,
                2.3318309783935547,
                -0.06406937539577484,
                -0.14155879616737366,
                -0.01063074916601181,
                -0.1698673665523529,
                0.08308643847703934,
                0.40936869382858276,
                -0.16443875432014465
            ],
            [
                0.06260249763727188,
                0.3084598779678345,
                1.9900158643722534,
                -0.00010311591177014634,
                0.45823368430137634,
                0.2752346098423004,
                -0.12708212435245514,
                1.660340428352356,
                1.3968695402145386,
                -0.16035357117652893,
                -0.16508843004703522,
                0.9244231581687927,
                -0.04259178414940834,
                -0.12426487356424332,
                0.693469762802124,
                -0.1442784070968628
            ],
            [
                0.7702106833457947,
                0.6393141746520996,
                0.9741820693016052,
                -0.04578148201107979,
                1.2756319046020508,
                0.5677142143249512,
                0.5925419330596924,
                0.11792002618312836,
                1.227445125579834,
                1.308154582977295,
                0.9698286652565002,
                1.6853917837142944,
                0.032141704112291336,
                1.7621941566467285,
                -0.06422615796327591,
                -0.14289920032024384
            ],
            [
                -0.08092013746500015,
                -0.033452000468969345,
                0.5763121247291565,
                0.5080687403678894,
                1.1024454832077026,
                0.0930778756737709,
                1.3003175258636475,
                0.09712862223386765,
                3.366694211959839,
                -0.16969119012355804,
                0.6067066192626953,
                3.5953307151794434,
                0.9329349398612976,
                0.03920019790530205,
                -0.1657426506280899,
                1.0663281679153442
            ],
            [
                -0.14652499556541443,
                2.143887996673584,
                2.0074405670166016,
                1.2456353902816772,
                1.4707552194595337,
                0.11376799643039703,
                0.5027593374252319,
                1.1110239028930664,
                3.0080924034118652,
                1.8856385946273804,
                0.01907108724117279,
                0.21981054544448853,
                -0.15341845154762268,
                0.020392531529068947,
                -0.006889329757541418,
                -0.14551396667957306
            ],
            [
                0.2889394164085388,
                0.6053473949432373,
                0.6284952759742737,
                0.8060176372528076,
                0.49753040075302124,
                0.5397207736968994,
                0.227179616689682,
                0.41169923543930054,
                1.2863091230392456,
                0.757575511932373,
                0.5348084568977356,
                0.40847888588905334,
                0.03633831441402435,
                0.6393706202507019,
                0.5535944104194641,
                0.07334175705909729
            ],
            [
                0.3485751152038574,
                0.40887147188186646,
                0.7039263844490051,
                0.22634482383728027,
                0.7503724098205566,
                0.5508518218994141,
                0.07661696523427963,
                0.9198462963104248,
                0.5883178114891052,
                0.34763428568840027,
                0.6690642833709717,
                0.5339601039886475,
                0.5161049962043762,
                0.5799183249473572,
                0.7928242683410645,
                0.20520824193954468
            ],
            [
                0.28833895921707153,
                0.9493287801742554,
                0.3568207323551178,
                0.999976396560669,
                1.0820603370666504,
                0.8811141848564148,
                0.5207624435424805,
                0.5990079641342163,
                0.27470648288726807,
                0.6160416007041931,
                0.3045533001422882,
                0.2939692735671997,
                0.17362786829471588,
                0.5899316668510437,
                0.09721414744853973,
                0.3019900918006897
            ],
            [
                -0.011089270934462547,
                0.694800078868866,
                0.5349986553192139,
                0.4335780739784241,
                0.6760380864143372,
                0.4105580747127533,
                0.2866726815700531,
                0.7017718553543091,
                0.22485248744487762,
                0.6163245439529419,
                0.40277913212776184,
                0.875805675983429,
                0.6648069024085999,
                0.4099905788898468,
                0.6603638529777527,
                1.6785385608673096
            ],
            [
                0.18030111491680145,
                0.6473889946937561,
                0.61353600025177,
                1.263391137123108,
                0.4964570999145508,
                0.28334885835647583,
                0.6077519059181213,
                0.6449943780899048,
                0.8624336123466492,
                0.4288592040538788,
                0.4700538218021393,
                0.5460865497589111,
                0.8464750051498413,
                0.5048548579216003,
                0.8173553347587585,
                1.273950219154358
            ],
            [
                0.034300290048122406,
                0.8843749165534973,
                0.793271005153656,
                0.4756071865558624,
                1.3179004192352295,
                0.36510273814201355,
                0.6344332098960876,
                0.20980200171470642,
                0.3139156401157379,
                0.42467835545539856,
                0.3417256772518158,
                1.831212043762207,
                0.7995011806488037,
                0.7430047392845154,
                0.6411938071250916,
                0.2540169656276703
            ],
            [
                0.42840856313705444,
                0.52569180727005,
                1.1184383630752563,
                0.32021766901016235,
                0.8061590194702148,
                0.5488901138305664,
                0.5330345034599304,
                0.9091441035270691,
                1.0612316131591797,
                0.6565385460853577,
                0.7594058513641357,
                0.3482902944087982,
                0.6298383474349976,
                0.7884802222251892,
                0.09455987811088562,
                0.26686495542526245
            ],
            [
                0.3235056698322296,
                0.32524916529655457,
                0.5482689142227173,
                0.8733086585998535,
                0.5045998096466064,
                1.0260400772094727,
                0.592811107635498,
                0.4291186034679413,
                0.513019859790802,
                0.4682326018810272,
                0.23710469901561737,
                0.8074105978012085,
                0.3145989179611206,
                0.5144275426864624,
                0.27833691239356995,
                0.5758270621299744
            ]
        ],
        [
            [
                0,
                0,
                -1.6395355828535685e-7,
                -2.2106335961780132e-7,
                0,
                -1.8751755703760864e-7,
                -1.9648734905786114e-7,
                0,
                -1.9705075260390004e-7,
                0,
                -1.9563751152418263e-7,
                -0.0000023463564957637573,
                -1.842444987687486e-7,
                0,
                -2.1601655930680863e-7,
                0
            ],
            [
                0,
                0,
                0,
                -1.521929675618594e-7,
                -0.000017951660993276164,
                -0.000027948437491431832,
                -0.00015096490096766502,
                -1.9736371825729293e-7,
                0,
                0,
                0,
                -0.00009290351590607315,
                0,
                -2.108186691884839e-7,
                -1.566437362043871e-7,
                -0.0000024819321424729424
            ],
            [
                0,
                0,
                0,
                -0.0000043157092477486,
                0,
                0,
                0,
                -0.00020048113947268575,
                0,
                -1.7650505412802886e-7,
                0,
                0,
                0,
                -2.5570966499799397e-7,
                null,
                0
            ],
            [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                -0.00007948292477522045,
                -0.00001721478474792093,
                -0.0000044467183215601835,
                -0.00000406614981329767,
                0,
                0,
                0,
                0,
                0
            ],
            [
                0,
                -2.0167371417301183e-7,
                0,
                0,
                0,
                0,
                -2.5083392074520816e-7,
                -1.8333349771637586e-7,
                -2.0509530429535516e-7,
                0,
                0,
                -0.00009356605005450547,
                -1.7099981164392375e-7,
                0,
                -2.1542419403886015e-7,
                -2.1738979683050275e-7
            ],
            [
                -0.0000034142481126764324,
                0,
                0,
                0,
                0,
                0,
                -0.0000011430183803895488,
                0,
                -0.000004448500476428308,
                -1.730144418843338e-7,
                -2.0398499600560172e-7,
                0,
                -2.1835040797668626e-7,
                0,
                0,
                -0.000021707088308176026
            ],
            [
                0,
                0,
                -2.0446849191557703e-7,
                0,
                -1.5366700267804845e-7,
                0,
                0,
                -8.589213393861428e-7,
                -0.0000032749594538472593,
                0,
                0,
                -2.632058624385536e-7,
                0,
                -2.719065150813549e-7,
                0,
                -0.000001686101882114599
            ],
            [
                0,
                -2.233989420119542e-7,
                -0.000009176304956781678,
                0,
                0,
                -0.000004191713742329739,
                -2.1119709003869502e-7,
                0,
                -0.0023262023460119963,
                -0.000011050362445530482,
                -0.000018805614672601223,
                -0.000001009787183647859,
                0,
                0,
                null,
                0
            ],
            [
                0.12293145060539246,
                0.4413823187351227,
                0.3860904276371002,
                0.5550554990768433,
                0.4477119445800781,
                -0.04915495589375496,
                0.014151081442832947,
                0.08799430727958679,
                0.842513918876648,
                0.43689730763435364,
                -0.14387108385562897,
                -0.12205805629491806,
                -0.05974911153316498,
                0.8517704606056213,
                1.1600040197372437,
                0.23966357111930847
            ],
            [
                -0.14992429316043854,
                -0.028541844338178635,
                0.038772061467170715,
                -0.13910001516342163,
                -0.09976271539926529,
                -0.13984739780426025,
                -0.01103723794221878,
                -0.14444109797477722,
                -0.16131435334682465,
                -0.11933334171772003,
                -0.1457318216562271,
                -0.12517191469669342,
                -0.09094864130020142,
                -0.11097261309623718,
                -0.15223351120948792,
                -0.15010996162891388
            ],
            [
                -0.12441680580377579,
                -0.07180485129356384,
                0.018553150817751884,
                -0.07847274839878082,
                -0.01353499386459589,
                -0.11464864015579224,
                -0.16131503880023956,
                -0.16183161735534668,
                -0.11649934202432632,
                -0.17002332210540771,
                -0.16747580468654633,
                -0.139851912856102,
                -0.16948559880256653,
                -0.16705287992954254,
                -0.16995769739151,
                -0.16981345415115356
            ],
            [
                -0.1376701295375824,
                -0.11592522263526917,
                -0.03407002240419388,
                -0.08693163841962814,
                -0.10948395729064941,
                -0.14768175780773163,
                -0.156515970826149,
                -0.13376222550868988,
                -0.060381170362234116,
                -0.12969115376472473,
                -0.14026996493339539,
                -0.09729568660259247,
                0.0485239215195179,
                -0.15848521888256073,
                -0.14235930144786835,
                -0.005007402040064335
            ],
            [
                -0.12590721249580383,
                -0.16343845427036285,
                -0.1270596981048584,
                -0.08747567981481552,
                -0.0706423819065094,
                -0.04970762878656387,
                -0.13306410610675812,
                -0.15921705961227417,
                -0.11544409394264221,
                -0.1463417410850525,
                -0.16973651945590973,
                -0.15695731341838837,
                0.0219852514564991,
                -0.10110725462436676,
                -0.15760017931461334,
                0.04364582151174545
            ],
            [
                -0.1697915643453598,
                0.015356674790382385,
                0.10509733855724335,
                -0.08233772218227386,
                -0.10260865092277527,
                -0.13178567588329315,
                -0.15841053426265717,
                -0.10366515070199966,
                -0.15899066627025604,
                -0.12967339158058167,
                -0.018246453255414963,
                0.025724399834871292,
                -0.12951453030109406,
                -0.11107230186462402,
                -0.11261308193206787,
                -0.14345528185367584
            ],
            [
                -0.11529089510440826,
                0.032594505697488785,
                -0.015198063105344772,
                -0.02907145768404007,
                -0.08995620906352997,
                -0.10014615207910538,
                -0.15072990953922272,
                -0.13717332482337952,
                -0.1526375412940979,
                -0.09611067175865173,
                0.013658467680215836,
                0.4235019087791443,
                -0.007958857342600822,
                -0.09641914069652557,
                -0.11039505898952484,
                -0.11037880182266235
            ],
            [
                -0.15319469571113586,
                -0.1600559651851654,
                -0.13804931938648224,
                -0.025513680651783943,
                0.017690099775791168,
                -0.0004805212374776602,
                -0.13064706325531006,
                -0.15572655200958252,
                -0.12522834539413452,
                -0.0853063091635704,
                0.2404898703098297,
                -0.1230367124080658,
                -0.17001685500144958,
                -0.11854568868875504,
                0.06968175619840622,
                -0.1125965490937233
            ]
        ],
        [
            [
                3.999760389328003,
                3.591867685317993,
                2.336512804031372,
                7.118443965911865,
                3.7351369857788086,
                6.082487106323242,
                5.218221664428711,
                4.297519683837891,
                5.939789295196533,
                3.8049938678741455,
                2.8873696327209473,
                3.756648063659668,
                5.10140323638916,
                5.035002708435059,
                5.150589942932129,
                4.208303928375244
            ],
            [
                2.8809237480163574,
                5.038441181182861,
                4.005472660064697,
                3.749171733856201,
                3.699725389480591,
                4.193607330322266,
                2.029262065887451,
                6.980185508728027,
                5.166952133178711,
                2.3137989044189453,
                2.400348424911499,
                2.502925395965576,
                3.817450761795044,
                3.266174793243408,
                2.0958189964294434,
                7.544012069702148
            ],
            [
                5.148993015289307,
                1.409945011138916,
                2.9303789138793945,
                0.7172204852104187,
                2.1350715160369873,
                5.079363822937012,
                8.568026542663574,
                1.7566652297973633,
                4.646885395050049,
                3.9734046459198,
                3.592824697494507,
                3.5277743339538574,
                4.472548007965088,
                5.927830219268799,
                9.210262298583984,
                8.624544143676758
            ],
            [
                3.417415142059326,
                4.2758331298828125,
                2.2968204021453857,
                4.6020073890686035,
                4.505053520202637,
                7.545464992523193,
                5.498796463012695,
                5.74188232421875,
                6.215999603271484,
                2.2018983364105225,
                3.6053507328033447,
                5.086010932922363,
                3.58331561088562,
                3.0959277153015137,
                5.724437713623047,
                6.1346049308776855
            ],
            [
                5.620952129364014,
                5.056331634521484,
                1.4464399814605713,
                2.4713802337646484,
                3.3188629150390625,
                7.066375255584717,
                4.288056373596191,
                3.958311080932617,
                3.253748893737793,
                3.1861965656280518,
                5.555476188659668,
                3.6773266792297363,
                3.8848278522491455,
                4.1998138427734375,
                3.333073377609253,
                4.45471715927124
            ],
            [
                5.541264057159424,
                5.524759292602539,
                4.336203098297119,
                1.9579873085021973,
                3.9255220890045166,
                2.612576961517334,
                3.6510868072509766,
                3.415562152862549,
                5.165496826171875,
                4.10934591293335,
                7.148064613342285,
                5.234261989593506,
                2.944502115249634,
                3.571091413497925,
                6.291920185089111,
                6.718203544616699
            ],
            [
                4.4468488693237305,
                6.428582191467285,
                2.656855583190918,
                4.606643199920654,
                3.9155757427215576,
                4.308770179748535,
                4.399958610534668,
                3.2350361347198486,
                4.918883323669434,
                3.679725170135498,
                5.557024002075195,
                3.119582414627075,
                6.465085506439209,
                9.330022811889648,
                4.256294250488281,
                3.5922350883483887
            ],
            [
                6.034369945526123,
                6.828299522399902,
                8.098247528076172,
                4.903425216674805,
                -0.03435364365577698,
                3.7768845558166504,
                3.058511734008789,
                1.7640029191970825,
                5.219725608825684,
                5.236398696899414,
                2.367469072341919,
                3.0129213333129883,
                4.128354072570801,
                7.591580867767334,
                6.656613349914551,
                2.3162431716918945
            ],
            [
                -0.08091222494840622,
                -0.16806277632713318,
                -0.14959333837032318,
                -0.16376441717147827,
                -0.16929517686367035,
                -0.14370429515838623,
                -0.1700247824192047,
                -0.14058439433574677,
                -0.16997332870960236,
                -0.07825250923633575,
                0.01360335387289524,
                -0.16974209249019623,
                -0.16726943850517273,
                -0.16808931529521942,
                -0.16124112904071808,
                -0.15264588594436646
            ],
            [
                0.00588777894154191,
                -0.16930556297302246,
                -0.1504697948694229,
                -0.11660470813512802,
                0.10138323903083801,
                -0.12558861076831818,
                -0.07544669508934021,
                -0.1527889519929886,
                -0.16189154982566833,
                -0.062057975679636,
                -0.09328557550907135,
                -0.1352885514497757,
                -0.15597209334373474,
                -0.1551266312599182,
                -0.16725336015224457,
                -0.14326393604278564
            ],
            [
                -0.12258421629667282,
                -0.09305676817893982,
                -0.16339725255966187,
                -0.16129037737846375,
                -0.1651880294084549,
                -0.14127886295318604,
                -0.1411050707101822,
                -0.16980406641960144,
                -0.16357018053531647,
                -0.15672782063484192,
                -0.16748037934303284,
                -0.07965746521949768,
                -0.1699201464653015,
                -0.13642123341560364,
                -0.15136507153511047,
                -0.018815474584698677
            ],
            [
                -0.02360563538968563,
                -0.009290061891078949,
                -0.17002488672733307,
                -0.1571594625711441,
                -0.16908720135688782,
                -0.14459548890590668,
                -0.12724965810775757,
                -0.156284362077713,
                -0.16164711117744446,
                -0.1697150468826294,
                -0.1406169831752777,
                -0.12911933660507202,
                -0.1687028855085373,
                -0.07509083300828934,
                -0.08775275945663452,
                -0.1042419821023941
            ],
            [
                -0.11051034182310104,
                -0.10647235065698624,
                -0.16560573875904083,
                -0.16322648525238037,
                -0.12831999361515045,
                -0.16553379595279694,
                -0.16348576545715332,
                -0.15821413695812225,
                -0.13002362847328186,
                -0.15770192444324493,
                -0.16785378754138947,
                0.041209325194358826,
                -0.026975972577929497,
                -0.16569319367408752,
                -0.1625678390264511,
                -0.13290253281593323
            ],
            [
                -0.1482098251581192,
                -0.12203958630561829,
                -0.07253256440162659,
                -0.09043145924806595,
                -0.1490430384874344,
                -0.15795643627643585,
                -0.1500760316848755,
                -0.16910217702388763,
                -0.16906563937664032,
                -0.15968768298625946,
                -0.03141501545906067,
                -0.04186585918068886,
                -0.15021824836730957,
                -0.1628800630569458,
                -0.1328078955411911,
                -0.1283944696187973
            ],
            [
                -0.06663594394922256,
                -0.17000427842140198,
                -0.1695069819688797,
                -0.15797026455402374,
                -0.16891513764858246,
                -0.12278164178133011,
                -0.1625181883573532,
                -0.09485878050327301,
                -0.12127295881509781,
                -0.15906406939029694,
                -0.12482859194278717,
                -0.1697942167520523,
                -0.14909546077251434,
                -0.1653369665145874,
                -0.16961538791656494,
                -0.16112801432609558
            ],
            [
                0.2426169365644455,
                0.18777769804000854,
                0.35810521245002747,
                0.19332179427146912,
                0.12636248767375946,
                0.2462458312511444,
                0.11708871275186539,
                0.1528709977865219,
                0.22096388041973114,
                0.2774457335472107,
                0.38728612661361694,
                0.08565067499876022,
                0.10408660024404526,
                0.144703209400177,
                0.11675675213336945,
                -0.01342901960015297
            ]
        ],
        [
            [
                4.8262739181518555,
                6.392597198486328,
                4.339504241943359,
                4.035662651062012,
                3.8301303386688232,
                5.050200462341309,
                4.474781513214111,
                4.6343512535095215,
                6.983218193054199,
                3.2228024005889893,
                4.2445902824401855,
                3.2370707988739014,
                5.330122947692871,
                5.30482816696167,
                2.6060993671417236,
                6.029553413391113
            ],
            [
                3.4758527278900146,
                4.780022144317627,
                5.001656532287598,
                3.03759503364563,
                5.6873979568481445,
                4.957953929901123,
                4.51058292388916,
                4.823145866394043,
                3.8019943237304688,
                4.390304088592529,
                4.131465435028076,
                4.36525297164917,
                4.532750129699707,
                5.081170082092285,
                4.565786838531494,
                4.348647594451904
            ],
            [
                5.744484901428223,
                2.9699466228485107,
                5.6648430824279785,
                3.872664213180542,
                3.907566785812378,
                5.474030494689941,
                5.986471176147461,
                3.991610527038574,
                3.3056082725524902,
                4.171671390533447,
                3.9159693717956543,
                2.8253557682037354,
                5.35600471496582,
                3.4140701293945312,
                4.129322052001953,
                4.049954414367676
            ],
            [
                5.139994144439697,
                6.966602325439453,
                3.616063356399536,
                3.6965274810791016,
                2.6372897624969482,
                3.2361233234405518,
                5.363655090332031,
                5.189420700073242,
                5.559492588043213,
                3.808220148086548,
                3.375645637512207,
                4.89024019241333,
                4.153041362762451,
                4.26373815536499,
                5.818149089813232,
                4.356916427612305
            ],
            [
                4.356569290161133,
                5.757424354553223,
                4.264340877532959,
                3.445666551589966,
                4.1723222732543945,
                5.5560760498046875,
                3.933307409286499,
                4.469692707061768,
                5.050318241119385,
                2.755842924118042,
                4.2509331703186035,
                6.354475021362305,
                5.438300609588623,
                3.1904919147491455,
                4.423557281494141,
                5.011995792388916
            ],
            [
                5.747722148895264,
                5.325136184692383,
                4.844090938568115,
                4.182389259338379,
                5.226553440093994,
                5.782828330993652,
                4.196743488311768,
                4.784281253814697,
                4.113600254058838,
                4.654870510101318,
                4.502295017242432,
                3.7005069255828857,
                4.069852352142334,
                5.195458889007568,
                3.66215181350708,
                5.435717582702637
            ],
            [
                4.3970866203308105,
                5.196420192718506,
                5.247209548950195,
                4.778741359710693,
                4.5385918617248535,
                4.888424873352051,
                6.159727096557617,
                5.811466693878174,
                4.379683017730713,
                4.7252044677734375,
                4.345205783843994,
                3.001413583755493,
                4.569874286651611,
                4.156526565551758,
                5.990046501159668,
                5.126319408416748
            ],
            [
                5.869234085083008,
                5.777373313903809,
                4.657939910888672,
                5.487240791320801,
                5.627233505249023,
                5.839438438415527,
                4.874382495880127,
                3.8603668212890625,
                7.452072620391846,
                6.802114009857178,
                4.901830196380615,
                5.087278366088867,
                4.453157424926758,
                5.00891637802124,
                4.083289623260498,
                3.453578233718872
            ],
            [
                -0.027278531342744827,
                -0.10935810953378677,
                -0.1277000904083252,
                -0.03939206153154373,
                -0.06303190439939499,
                -0.04851953312754631,
                0.07573864609003067,
                0.09353437274694443,
                -0.07705730944871902,
                -0.1248483881354332,
                0.13001081347465515,
                0.12450531125068665,
                0.1262531727552414,
                0.07212857902050018,
                -0.002474613254889846,
                0.37540116906166077
            ],
            [
                -0.05359717458486557,
                -0.01650337129831314,
                0.007085006684064865,
                0.016182897612452507,
                -0.054104313254356384,
                0.108225017786026,
                0.43441399931907654,
                0.07588259875774384,
                0.14104555547237396,
                0.20105476677417755,
                -0.0046412330120801926,
                0.22890740633010864,
                0.14644229412078857,
                0.16111792623996735,
                0.1061568334698677,
                0.05748812481760979
            ],
            [
                -0.0098962327465415,
                -0.12369982153177261,
                -0.012566396966576576,
                0.052785202860832214,
                -0.0820777639746666,
                -0.014985325746238232,
                -0.017895454540848732,
                -0.026927737519145012,
                0.014718507416546345,
                -0.03294231742620468,
                0.15549497306346893,
                0.16667158901691437,
                0.029747290536761284,
                -0.028190303593873978,
                0.07684006541967392,
                0.04350067675113678
            ],
            [
                -0.018601875752210617,
                0.007168798707425594,
                -0.01606203429400921,
                -0.1146383285522461,
                -0.03985710069537163,
                -0.07039844989776611,
                -0.04800207540392876,
                -0.016821295022964478,
                -0.11170727014541626,
                -0.09443089365959167,
                -0.037522926926612854,
                -0.007110772654414177,
                0.27418461441993713,
                0.07337749749422073,
                -0.08619371801614761,
                -0.10003391653299332
            ],
            [
                0.014241735450923443,
                0.0341840423643589,
                0.013265708461403847,
                0.07754981517791748,
                0.027876898646354675,
                0.23035383224487305,
                0.20521634817123413,
                0.04167729988694191,
                -0.0530240535736084,
                0.271692156791687,
                0.11249419301748276,
                0.12356673181056976,
                0.10861539095640182,
                0.3143214285373688,
                0.08135282248258591,
                -0.02816467359662056
            ],
            [
                -0.033970315009355545,
                -0.052005067467689514,
                -0.0493415929377079,
                0.15570522844791412,
                -0.12312930822372437,
                0.07327667623758316,
                -0.024847500026226044,
                -0.008135348558425903,
                -0.07005228847265244,
                0.43244469165802,
                -0.0019436761504039168,
                -0.07220340520143509,
                -0.09178203344345093,
                0.15586306154727936,
                0.22091539204120636,
                -0.04194086417555809
            ],
            [
                -0.057326529175043106,
                -0.04546923190355301,
                0.11181094497442245,
                0.10331568121910095,
                -0.12013431638479233,
                -0.006365980487316847,
                0.022638922557234764,
                0.006321309600025415,
                0.12681534886360168,
                0.052443377673625946,
                -0.13342611491680145,
                0.12055503576993942,
                0.1046493649482727,
                0.045794226229190826,
                0.0650831088423729,
                -0.08915345370769501
            ],
            [
                0.07037343084812164,
                0.47999438643455505,
                0.1390916407108307,
                0.3147122263908386,
                0.46271830797195435,
                0.24471892416477203,
                0.3485407829284668,
                0.2187434881925583,
                0.2651376724243164,
                0.2660256624221802,
                0.444115549325943,
                0.39729732275009155,
                0.2301175594329834,
                0.27680423855781555,
                0.376007616519928,
                0.15864786505699158
            ]
        ],
        [
            [
                -0.0019063014769926667,
                -0.006312526762485504,
                -0.01630934327840805,
                -0.16606087982654572,
                -0.10391240566968918,
                -0.0013235054211691022,
                -0.00717534963041544,
                -0.11014442890882492,
                -0.0028287286404520273,
                -0.002703665057197213,
                -0.06767880171537399,
                -0.00876710843294859,
                -0.013807250186800957,
                -0.0030162769835442305,
                -0.003936971537768841,
                -0.011888883076608181
            ],
            [
                -0.00832015834748745,
                -0.0006534025305882096,
                -0.031128820031881332,
                -0.029806245118379593,
                -0.13575415313243866,
                -0.15246045589447021,
                -0.00023670500377193093,
                -0.10439139604568481,
                -0.16999098658561707,
                -0.13925839960575104,
                -0.16421900689601898,
                -0.012162500061094761,
                -0.00401465967297554,
                -0.00038805653457529843,
                -0.023631053045392036,
                -0.10211344063282013
            ],
            [
                -0.07535461336374283,
                -0.0037205868866294622,
                -0.023605121299624443,
                -0.0015505030751228333,
                -0.001167152775451541,
                -0.1416405439376831,
                -0.07955024391412735,
                -0.015375388786196709,
                -0.0010094926692545414,
                -0.08460266888141632,
                -0.04265613481402397,
                -0.06846427172422409,
                -0.019492315128445625,
                -0.000011053866728616413,
                -0.0072785052470862865,
                0.4198320806026459
            ],
            [
                -0.014012879692018032,
                -0.021393314003944397,
                -0.007394027896225452,
                -0.00008014882769202814,
                -1.5291797694771958e-7,
                -0.032279834151268005,
                -0.004395000170916319,
                -0.10421378910541534,
                -0.003090060316026211,
                -0.16459499299526215,
                -0.08631844073534012,
                -0.00792927946895361,
                -0.009902702644467354,
                -0.04287143051624298,
                -0.04178247228264809,
                -0.07777180522680283
            ],
            [
                -0.1486169695854187,
                -0.026003284379839897,
                -0.013105135411024094,
                -0.08265960216522217,
                -0.0035953144542872906,
                -0.000008788123523117974,
                -0.00694196205586195,
                -0.019642524421215057,
                -0.003205602988600731,
                -0.008677585981786251,
                -0.09024523943662643,
                -0.025775739923119545,
                -0.021733155474066734,
                -0.006438828073441982,
                -0.09829503297805786,
                -0.05546898767352104
            ],
            [
                -0.06726554781198502,
                -0.014418946579098701,
                -0.006683922838419676,
                -0.1337784230709076,
                -0.08012974262237549,
                -0.0007647533202543855,
                -0.027325714007019997,
                -0.03423600643873215,
                -0.00879705511033535,
                -0.01108219288289547,
                -0.16990137100219727,
                0.25336116552352905,
                -0.03662625700235367,
                -0.000050910559366457164,
                -0.032550204545259476,
                -0.04758718982338905
            ],
            [
                -0.10910534113645554,
                -0.16148963570594788,
                -0.05329333245754242,
                -0.00745378527790308,
                -0.09125152975320816,
                -0.017461813986301422,
                -0.029906926676630974,
                -0.06854314357042313,
                -0.000022558329874300398,
                -0.028957396745681763,
                -0.0025835817214101553,
                -0.07180652767419815,
                -0.005918964743614197,
                -0.16985848546028137,
                -0.13363035023212433,
                -0.021597187966108322
            ],
            [
                -0.037230636924505234,
                -0.16921913623809814,
                -0.01813574880361557,
                1.053061604499817,
                0.5491690635681152,
                -0.09861782193183899,
                -0.0401763841509819,
                -0.01187417097389698,
                0.027648596093058586,
                -0.1147475615143776,
                -0.1491016447544098,
                -0.027468696236610413,
                -0.0030282598454505205,
                -0.1573735773563385,
                0.39135223627090454,
                0.014464086852967739
            ],
            [
                0.017067372798919678,
                -0.1089974120259285,
                -0.10718060284852982,
                -0.15983568131923676,
                -0.13890226185321808,
                -0.16138114035129547,
                -0.12671898305416107,
                -0.14982591569423676,
                -0.06268340349197388,
                -0.15400516986846924,
                -0.13830459117889404,
                -0.15991123020648956,
                -0.15281778573989868,
                -0.13116173446178436,
                -0.12689894437789917,
                -0.15339474380016327
            ],
            [
                -0.1337146908044815,
                -0.12954925000667572,
                -0.16821487247943878,
                -0.15315908193588257,
                -0.15805116295814514,
                -0.12142056226730347,
                -0.1661752164363861,
                -0.10994227975606918,
                -0.14446891844272614,
                -0.15593913197517395,
                -0.15365934371948242,
                -0.12233775109052658,
                -0.1395341455936432,
                -0.1266203075647354,
                -0.13344676792621613,
                -0.15992842614650726
            ],
            [
                -0.13420987129211426,
                -0.1567455530166626,
                -0.1696244180202484,
                -0.16720017790794373,
                -0.06613427400588989,
                -0.14702001214027405,
                -0.1541130095720291,
                -0.16024670004844666,
                -0.14889955520629883,
                -0.16431836783885956,
                -0.15215447545051575,
                -0.14089062809944153,
                -0.156887948513031,
                -0.1607355773448944,
                -0.1388799101114273,
                -0.15121199190616608
            ],
            [
                -0.1301749348640442,
                -0.16846558451652527,
                -0.1295558363199234,
                -0.14982788264751434,
                -0.17003047466278076,
                -0.16978004574775696,
                -0.16258765757083893,
                -0.09109607338905334,
                -0.16124221682548523,
                -0.1468476951122284,
                -0.14536908268928528,
                -0.15647853910923004,
                -0.16665247082710266,
                -0.1385745108127594,
                -0.1323642134666443,
                -0.13593702018260956
            ],
            [
                -0.119688980281353,
                -0.16157934069633484,
                -0.1555388867855072,
                -0.10115800052881241,
                -0.09521835297346115,
                -0.13857914507389069,
                -0.1520022600889206,
                -0.16090849041938782,
                -0.15348657965660095,
                -0.12989701330661774,
                -0.16989904642105103,
                -0.0681743174791336,
                -0.10629056394100189,
                -0.1500083953142166,
                -0.12624263763427734,
                -0.16417421400547028
            ],
            [
                -0.1542969048023224,
                -0.16912700235843658,
                -0.13371603190898895,
                -0.15265990793704987,
                -0.1698305904865265,
                -0.16346590220928192,
                -0.1655750274658203,
                -0.15058571100234985,
                -0.15743394196033478,
                -0.12548284232616425,
                -0.06165342777967453,
                -0.1460762321949005,
                -0.13457919657230377,
                -0.0586526058614254,
                -0.15833859145641327,
                -0.16378632187843323
            ],
            [
                -0.12231292575597763,
                -0.1622443050146103,
                -0.12539052963256836,
                -0.16479073464870453,
                -0.15526007115840912,
                -0.16795261204242706,
                -0.16199153661727905,
                -0.16031432151794434,
                -0.1641084849834442,
                -0.16328193247318268,
                -0.16622813045978546,
                -0.1602870225906372,
                -0.11962023377418518,
                -0.15972277522087097,
                -0.17002101242542267,
                -0.16982142627239227
            ],
            [
                -0.1167539656162262,
                -0.15856751799583435,
                -0.13999368250370026,
                -0.0712357833981514,
                -0.03778672218322754,
                -0.1644958257675171,
                -0.10023574531078339,
                -0.1510762870311737,
                -0.14032664895057678,
                -0.06243503466248512,
                -0.09113188087940216,
                -0.16042225062847137,
                -0.1146961897611618,
                -0.1120629757642746,
                -0.10242214053869247,
                -0.07569698244333267
            ]
        ],
        [
            [
                -0.012135254219174385,
                -0.07841897755861282,
                -0.16033844649791718,
                -0.07141271233558655,
                -0.1643214225769043,
                -0.09541256725788116,
                -0.1693248152732849,
                -0.16943466663360596,
                0.002265463350340724,
                -0.055983636528253555,
                -0.03186948969960213,
                -0.13500387966632843,
                -0.03581702709197998,
                -0.06334289908409119,
                -0.051477979868650436,
                -0.0583835169672966
            ],
            [
                -0.0952044278383255,
                0,
                0,
                -0.0000010005657031797455,
                -0.000002350034947085078,
                -0.0008431497262790799,
                -0.00370395858772099,
                -0.00014999823179095984,
                -0.0022622141987085342,
                -0.0851186215877533,
                -0.005808659363538027,
                -0.0351639986038208,
                -0.004626381676644087,
                -0.012269115075469017,
                -0.06239045038819313,
                -0.03432946279644966
            ],
            [
                -0.00004755648478749208,
                -0.00034628197317942977,
                -0.001469090348109603,
                -0.029601238667964935,
                -0.000002355793867536704,
                -0.0024452703073620796,
                -0.10796044766902924,
                -0.0005730462726205587,
                -0.0026098093949258327,
                -0.10384757816791534,
                -0.014796688221395016,
                -0.035909298807382584,
                -0.00011567671754164621,
                0,
                -0.000004196901045361301,
                -0.16813012957572937
            ],
            [
                -0.0017211547819897532,
                -0.009587452746927738,
                -0.048873793333768845,
                -0.008244662545621395,
                -0.001077971770428121,
                -0.011636784300208092,
                -0.001294557237997651,
                -0.00001746114867273718,
                -0.006616386119276285,
                -0.1693769097328186,
                -0.10647667944431305,
                -0.000008034655365918297,
                -0.000017949547327589244,
                -0.00676450552418828,
                -2.929004949692171e-7,
                -0.04180038720369339
            ],
            [
                -0.006721749901771545,
                -0.001273635309189558,
                -0.000025922881832229905,
                -0.00037906531360931695,
                -0.000002487303390807938,
                0,
                0,
                0,
                -0.0001768402144080028,
                -0.0015698660863563418,
                -0.0023350808769464493,
                -0.08772194385528564,
                -0.022060414776206017,
                -0.06126836687326431,
                -0.03276706486940384,
                -0.031026413664221764
            ],
            [
                -0.026019664481282234,
                -0.0019157265778630972,
                -0.0002892912016250193,
                -0.000008162317499227356,
                -0.001070194412022829,
                -0.00036010591429658234,
                -0.009164298884570599,
                -0.00775868957862258,
                -0.018313419073820114,
                -0.0005858479999005795,
                -0.13143879175186157,
                -2.9338983154048037e-7,
                -0.001149069401435554,
                -0.00010907705291174352,
                -0.00001832058660511393,
                -0.13360077142715454
            ],
            [
                -0.10093428939580917,
                -0.15068167448043823,
                -0.01774517260491848,
                -0.0007651537307538092,
                -0.00007224766159197316,
                -0.02890177257359028,
                -0.000934869865886867,
                -0.003573901951313019,
                -0.000004963187620887766,
                -0.0005534455995075405,
                0,
                -0.0000015454866115760524,
                0,
                -0.02158236689865589,
                0,
                -0.00043318013194948435
            ],
            [
                -0.01047206949442625,
                -0.0033402687404304743,
                -0.0000011396249419703963,
                0,
                -0.1035289466381073,
                -0.060634125024080276,
                -0.006941845640540123,
                -0.011552826501429081,
                -0.027800321578979492,
                -0.016121050342917442,
                -0.13409456610679626,
                -0.09814766049385071,
                -0.0007822940242476761,
                0,
                -1.5621226623352413e-7,
                -0.07971496134996414
            ],
            [
                0.49203187227249146,
                0.43840712308883667,
                0.1321861296892166,
                0.25700643658638,
                0.2725096642971039,
                0.3483625650405884,
                0.5356608033180237,
                0.8931545615196228,
                0.12127906084060669,
                -0.003332022577524185,
                0.36807364225387573,
                0.43538638949394226,
                0.7614157795906067,
                0.7165152430534363,
                1.1082876920700073,
                0.9610680937767029
            ],
            [
                -0.009238982573151588,
                0.017306247726082802,
                0.01937163807451725,
                0.19338515400886536,
                0.06024627760052681,
                0.4542634189128876,
                0.6306416392326355,
                0.48185887932777405,
                0.46468570828437805,
                0.5212340950965881,
                0.3957837224006653,
                0.3192043900489807,
                0.6040853261947632,
                0.2351883351802826,
                0.10624302178621292,
                0.5229786038398743
            ],
            [
                0.2811537981033325,
                0.5135694742202759,
                0.6536673307418823,
                0.5184531807899475,
                0.5763449668884277,
                0.14955081045627594,
                0.3803125023841858,
                0.40609776973724365,
                0.7910495400428772,
                0.3140128552913666,
                0.2376636415719986,
                0.8237241506576538,
                0.7914461493492126,
                0.1465650349855423,
                0.3210509717464447,
                0.5707979798316956
            ],
            [
                0.2697044014930725,
                0.2773173749446869,
                0.632213830947876,
                0.5240889191627502,
                0.06998143345117569,
                0.46028056740760803,
                0.34793728590011597,
                0.750568687915802,
                0.6043944954872131,
                0.4263327717781067,
                0.4115452170372009,
                0.13703814148902893,
                0.7378261089324951,
                0.31838124990463257,
                0.29410433769226074,
                0.1845977008342743
            ],
            [
                0.1908903568983078,
                0.03872143104672432,
                0.32337284088134766,
                0.14573489129543304,
                0.59105384349823,
                0.4592207372188568,
                0.6372500658035278,
                0.48127686977386475,
                0.4126942753791809,
                0.8389601111412048,
                0.6241235733032227,
                0.29288774728775024,
                0.5212196111679077,
                0.7083500623703003,
                0.32267481088638306,
                0.014836110174655914
            ],
            [
                0.4570639729499817,
                0.3891006410121918,
                0.4237470328807831,
                0.12480786442756653,
                0.12939107418060303,
                0.5937187075614929,
                0.4297223389148712,
                0.8822062015533447,
                0.6100185513496399,
                0.3980198800563812,
                0.5332085490226746,
                0.42223381996154785,
                0.3431714177131653,
                0.5843053460121155,
                0.4509604275226593,
                0.7641870975494385
            ],
            [
                0.3211612403392792,
                0.5443773865699768,
                0.44954901933670044,
                0.3980761170387268,
                0.18346811830997467,
                0.45799899101257324,
                0.6212818622589111,
                0.4433057904243469,
                0.6014294028282166,
                0.5790484547615051,
                0.7853737473487854,
                0.6463310122489929,
                0.2070654183626175,
                0.5235072374343872,
                0.817511796951294,
                0.7004291415214539
            ],
            [
                -0.06570234149694443,
                0.24030205607414246,
                0.09530401974916458,
                0.40706440806388855,
                0.40742218494415283,
                0.25099316239356995,
                0.4004277288913727,
                0.15367341041564941,
                0.05638417974114418,
                0.38166919350624084,
                0.3958100378513336,
                0.38694775104522705,
                0.009818214923143387,
                0.2467292994260788,
                0.827636182308197,
                1.215818166732788
            ]
        ],
        [
            [
                3.812946319580078,
                3.992999315261841,
                1.6726330518722534,
                2.5705771446228027,
                3.3176770210266113,
                5.771608352661133,
                6.221410751342773,
                4.280702114105225,
                5.475241661071777,
                4.687911033630371,
                2.6942198276519775,
                4.856555461883545,
                5.371744155883789,
                4.309772968292236,
                2.964552402496338,
                3.943781614303589
            ],
            [
                4.86866569519043,
                5.194416522979736,
                3.6052963733673096,
                2.0147716999053955,
                3.5025076866149902,
                2.859501838684082,
                4.690310001373291,
                3.1064939498901367,
                0.9582338333129883,
                3.3494210243225098,
                4.343510627746582,
                4.456499099731445,
                3.9331510066986084,
                3.0257608890533447,
                0.5175581574440002,
                3.997330665588379
            ],
            [
                4.273509979248047,
                2.22977614402771,
                3.140650749206543,
                1.786200761795044,
                2.574445962905884,
                1.7756705284118652,
                5.28508186340332,
                2.3367292881011963,
                5.300782203674316,
                5.231555938720703,
                5.315648078918457,
                5.525890350341797,
                2.8538663387298584,
                3.3401341438293457,
                1.6223998069763184,
                5.1154632568359375
            ],
            [
                3.5537798404693604,
                3.895198106765747,
                3.056088447570801,
                1.762272596359253,
                2.564680337905884,
                3.982164144515991,
                5.115727424621582,
                2.9129252433776855,
                5.5863752365112305,
                3.4062323570251465,
                5.0662922859191895,
                5.290625095367432,
                3.811272382736206,
                2.2003445625305176,
                3.7066490650177,
                4.027839183807373
            ],
            [
                4.162651538848877,
                5.3938493728637695,
                2.2218377590179443,
                1.3440808057785034,
                3.5797057151794434,
                4.668100357055664,
                3.655397415161133,
                3.7268078327178955,
                2.1706202030181885,
                2.8991281986236572,
                3.2535080909729004,
                3.5470638275146484,
                4.416876316070557,
                3.3282718658447266,
                1.8309308290481567,
                5.755668640136719
            ],
            [
                3.792118549346924,
                4.881808280944824,
                4.47657585144043,
                3.3842570781707764,
                4.226675510406494,
                4.023008823394775,
                3.780848264694214,
                5.3277363777160645,
                5.522394180297852,
                3.752021312713623,
                3.77063250541687,
                0.35889244079589844,
                2.8494770526885986,
                3.4478542804718018,
                3.946558713912964,
                5.298819541931152
            ],
            [
                4.678808212280273,
                6.748537063598633,
                2.4048430919647217,
                2.491272211074829,
                4.654660701751709,
                5.1598310470581055,
                4.611815452575684,
                5.016207218170166,
                3.8990626335144043,
                1.026344656944275,
                3.454801082611084,
                2.554703950881958,
                3.3157482147216797,
                2.3164548873901367,
                1.4189844131469727,
                4.42269229888916
            ],
            [
                4.783565044403076,
                5.4855451583862305,
                4.2931294441223145,
                2.059713840484619,
                1.7374120950698853,
                5.843283176422119,
                5.816263675689697,
                3.855043649673462,
                2.114790439605713,
                4.0768723487854,
                4.926012992858887,
                4.329711437225342,
                4.516066551208496,
                0.14062140882015228,
                -0.11821744590997696,
                3.6188466548919678
            ],
            [
                0.03802694007754326,
                0.3539712727069855,
                0.3187008500099182,
                0.19827911257743835,
                0.44038504362106323,
                0.17207469046115875,
                -0.08031891286373138,
                0.27891048789024353,
                0.8391450643539429,
                0.5633554458618164,
                0.1997867077589035,
                -0.12712925672531128,
                -0.10511628538370132,
                -0.085811085999012,
                0.08797725290060043,
                0.18156544864177704
            ],
            [
                0.4597460925579071,
                0.30279403924942017,
                0.8966352343559265,
                -0.001132163917645812,
                0.18416506052017212,
                -0.041913364082574844,
                0.37799951434135437,
                0.5347157120704651,
                -0.029382986947894096,
                0.21168141067028046,
                0.262829065322876,
                0.13946771621704102,
                0.07583251595497131,
                0.11460741609334946,
                0.3120100498199463,
                0.06440997123718262
            ],
            [
                -0.013081447221338749,
                0.0017572318902239203,
                0.20282895863056183,
                -0.024408560246229172,
                0.0299745574593544,
                0.16055132448673248,
                0.2880965769290924,
                0.08670561015605927,
                0.36200153827667236,
                0.2109650820493698,
                0.0395071804523468,
                0.2032964825630188,
                -0.018223732709884644,
                0.13572975993156433,
                -0.07242576032876968,
                0.0807645171880722
            ],
            [
                0.23650974035263062,
                0.5098750591278076,
                0.2728498876094818,
                0.013148963451385498,
                -0.032015491276979446,
                0.1549684703350067,
                0.23242796957492828,
                -0.0670430138707161,
                0.13768717646598816,
                0.055997494608163834,
                0.13579417765140533,
                0.1112014651298523,
                -0.06426350772380829,
                0.1383114457130432,
                0.37507566809654236,
                0.6665759086608887
            ],
            [
                0.3664913773536682,
                0.5128468871116638,
                0.14865510165691376,
                0.06978015601634979,
                0.365751713514328,
                0.38188084959983826,
                0.0620114766061306,
                -0.021011803299188614,
                0.08917274326086044,
                -0.11569301784038544,
                0.13068899512290955,
                0.04571322351694107,
                0.01497428584843874,
                -0.11229969561100006,
                0.035985734313726425,
                0.29613423347473145
            ],
            [
                -0.011463762260973454,
                0.03542778268456459,
                0.326840341091156,
                0.919955313205719,
                0.08676225692033768,
                0.10775525122880936,
                0.0926981270313263,
                0.06778232753276825,
                -0.08028876781463623,
                0.023680727928876877,
                0.08151870965957642,
                0.6060457825660706,
                -0.01831262744963169,
                -0.08051098138093948,
                0.12921378016471863,
                -0.06275070458650589
            ],
            [
                -0.03589550033211708,
                0.2272670567035675,
                0.16485507786273956,
                0.24295681715011597,
                -0.05471261218190193,
                0.010599634610116482,
                0.17898687720298767,
                0.2043924331665039,
                0.10984168201684952,
                -0.10924731940031052,
                0.18934942781925201,
                -0.022837217897176743,
                -0.08633138239383698,
                -0.08937658369541168,
                -0.16192559897899628,
                -0.16995854675769806
            ],
            [
                0.3514317274093628,
                0.2754340171813965,
                0.2850317656993866,
                0.11031963676214218,
                0.7138370871543884,
                0.6947242021560669,
                0.20242133736610413,
                0.3594108521938324,
                0.5296722054481506,
                0.2121923863887787,
                0.21296623349189758,
                0.4234159290790558,
                0.2332189679145813,
                0.2554031312465668,
                0.21707265079021454,
                0.10554087162017822
            ]
        ],
        [
            [
                3.313891887664795,
                3.571535110473633,
                1.2202916145324707,
                1.7342376708984375,
                2.4027225971221924,
                4.184978008270264,
                2.7148191928863525,
                3.3401870727539062,
                5.358201026916504,
                1.5357784032821655,
                0.6117933988571167,
                0.7824121713638306,
                2.982351541519165,
                2.1787233352661133,
                3.636583089828491,
                2.9903135299682617
            ],
            [
                3.5390989780426025,
                3.550508499145508,
                3.6380391120910645,
                0.19908499717712402,
                2.7938241958618164,
                4.7348809242248535,
                3.82806134223938,
                4.681218147277832,
                2.6031646728515625,
                1.5456271171569824,
                0.10418212413787842,
                3.6169991493225098,
                4.6724853515625,
                4.053203582763672,
                1.063759684562683,
                1.1209157705307007
            ],
            [
                2.098811149597168,
                0.6769125461578369,
                3.893833875656128,
                2.634164810180664,
                4.932794094085693,
                1.5846627950668335,
                6.768001079559326,
                3.2267677783966064,
                1.21730375289917,
                0.840355396270752,
                1.0925852060317993,
                2.6344704627990723,
                3.6796700954437256,
                5.121621608734131,
                -0.11621628701686859,
                3.652317762374878
            ],
            [
                3.582117795944214,
                2.9353086948394775,
                2.0328094959259033,
                2.2408251762390137,
                4.111588001251221,
                3.676297664642334,
                3.0131876468658447,
                1.649296522140503,
                4.753794193267822,
                2.133148670196533,
                2.4479122161865234,
                3.26259446144104,
                1.9681106805801392,
                5.145385265350342,
                4.9107489585876465,
                3.6295247077941895
            ],
            [
                4.966708183288574,
                6.091862678527832,
                0.6653264164924622,
                2.9060816764831543,
                5.853237628936768,
                5.206716537475586,
                4.460676670074463,
                3.0046684741973877,
                3.003412961959839,
                1.2093932628631592,
                1.483796238899231,
                3.1680874824523926,
                2.723830223083496,
                0.16391608119010925,
                2.6554224491119385,
                2.2097647190093994
            ],
            [
                2.702298879623413,
                2.2006001472473145,
                4.314258098602295,
                0.20879392325878143,
                1.460666298866272,
                5.21852445602417,
                0.8184065222740173,
                2.910301923751831,
                3.886685848236084,
                3.5680532455444336,
                3.3111913204193115,
                1.4307371377944946,
                3.5218944549560547,
                4.215069770812988,
                2.859706401824951,
                2.804973840713501
            ],
            [
                2.357426881790161,
                3.1646597385406494,
                3.8655879497528076,
                2.9754395484924316,
                1.8328957557678223,
                4.618859767913818,
                3.5725948810577393,
                3.5042755603790283,
                4.455964088439941,
                4.768352508544922,
                2.4659628868103027,
                4.631231784820557,
                4.287108421325684,
                2.9230737686157227,
                8.084507942199707,
                3.353955030441284
            ],
            [
                2.6941885948181152,
                2.923447608947754,
                3.8923020362854004,
                0.28047099709510803,
                0.8727210760116577,
                2.952894449234009,
                3.417332887649536,
                2.508037805557251,
                5.1402106285095215,
                2.2127177715301514,
                1.7373342514038086,
                2.2045493125915527,
                0.9107160568237305,
                1.8591896295547485,
                2.787477493286133,
                0.22092707455158234
            ],
            [
                -0.15837998688220978,
                -0.16662241518497467,
                -0.08269736915826797,
                -0.12245800346136093,
                -0.07654894143342972,
                -0.008750177919864655,
                -0.024647632613778114,
                -0.11193178594112396,
                -0.1013663038611412,
                -0.09641451388597488,
                -0.01741715706884861,
                -0.017809633165597916,
                -0.08713206648826599,
                -0.07956916093826294,
                -0.13182316720485687,
                -0.10437598824501038
            ],
            [
                -0.09020984172821045,
                -0.03520078584551811,
                -0.034646257758140564,
                -0.0473741814494133,
                -0.01712760515511036,
                -0.03784504905343056,
                -0.0024831683840602636,
                -0.014618130400776863,
                -0.06573441624641418,
                -0.014308689162135124,
                -0.05287023261189461,
                -0.0031201946549117565,
                -0.002226786920800805,
                -0.003632256994023919,
                -0.005559863988310099,
                -0.05518016964197159
            ],
            [
                -0.0397525392472744,
                -0.11156119406223297,
                -0.022520843893289566,
                -0.05181475356221199,
                -0.07062235474586487,
                -0.020204447209835052,
                -0.023830218240618706,
                -0.01310459803789854,
                -0.009759560227394104,
                -0.030703140422701836,
                -0.018661869689822197,
                -0.009644169360399246,
                -0.05137956514954567,
                -0.0050264825113117695,
                -0.006506130564957857,
                -0.1125427857041359
            ],
            [
                -0.060567986220121384,
                -0.02743745781481266,
                -0.017121050506830215,
                -0.120555080473423,
                -0.12460749596357346,
                -0.029291484504938126,
                -0.023080507293343544,
                -0.03508792072534561,
                -0.028826018795371056,
                -0.025053054094314575,
                -0.030541472136974335,
                -0.020944153890013695,
                -0.012718346901237965,
                -0.014749882742762566,
                -0.03473494201898575,
                -0.10027549415826797
            ],
            [
                -0.04445374384522438,
                -0.005009446758776903,
                -0.012111686170101166,
                -0.0668092742562294,
                -0.12502263486385345,
                -0.054495103657245636,
                -0.016396354883909225,
                -0.04395506903529167,
                -0.02049487642943859,
                -0.00922851637005806,
                -0.02480453997850418,
                -0.060196708887815475,
                -0.04614492878317833,
                -0.01032076682895422,
                -0.0783793106675148,
                -0.16841481626033783
            ],
            [
                -0.1524241417646408,
                -0.03807814046740532,
                -0.1433066576719284,
                -0.014692198485136032,
                -0.031316664069890976,
                -0.020526565611362457,
                -0.010766374878585339,
                -0.03261756896972656,
                -0.009234295226633549,
                -0.012230169028043747,
                -0.13563799858093262,
                -0.15033669769763947,
                -0.07298331707715988,
                -0.03785864636301994,
                -0.02733009122312069,
                -0.14546863734722137
            ],
            [
                -0.12079479545354843,
                -0.027466846629977226,
                -0.09789440035820007,
                -0.09124794602394104,
                -0.1675705909729004,
                -0.04826483502984047,
                -0.023919209837913513,
                -0.036452122032642365,
                -0.035968683660030365,
                -0.06067726016044617,
                -0.16978496313095093,
                -0.1536950170993805,
                -0.034145671874284744,
                -0.06449471414089203,
                -0.04819440841674805,
                -0.126913920044899
            ],
            [
                -0.11560861021280289,
                -0.03368192911148071,
                -0.09229022264480591,
                -0.05402538180351257,
                -0.16203872859477997,
                -0.12727110087871552,
                -0.08108868449926376,
                -0.04894009605050087,
                -0.048272229731082916,
                -0.04828670620918274,
                -0.05305105075240135,
                -0.03716818243265152,
                -0.03535445034503937,
                -0.05140537768602371,
                -0.07543128728866577,
                -0.16995900869369507
            ]
        ],
        [
            [
                3.3731722831726074,
                3.4438302516937256,
                3.568478584289551,
                2.4303548336029053,
                5.226263046264648,
                3.2591168880462646,
                2.9068572521209717,
                4.399144649505615,
                4.157464981079102,
                2.0426831245422363,
                2.753371477127075,
                1.6473309993743896,
                2.4177932739257812,
                3.9346485137939453,
                3.5116782188415527,
                6.246061325073242
            ],
            [
                1.5248833894729614,
                5.064079761505127,
                0.14179636538028717,
                2.4137539863586426,
                1.7367656230926514,
                2.412843704223633,
                0.9877039194107056,
                2.144145965576172,
                -0.11153101921081543,
                0.009797662496566772,
                1.260191798210144,
                1.1831071376800537,
                1.9243652820587158,
                1.4851748943328857,
                2.0255494117736816,
                4.554222106933594
            ],
            [
                1.9350301027297974,
                2.569749593734741,
                2.9070165157318115,
                0.44311559200286865,
                2.561870574951172,
                0.971622109413147,
                4.601328372955322,
                1.445417881011963,
                0.8315412402153015,
                1.2257306575775146,
                1.5623743534088135,
                2.28306245803833,
                3.7438435554504395,
                2.890260934829712,
                1.554109811782837,
                7.481652736663818
            ],
            [
                1.7171032428741455,
                2.545610189437866,
                0.835591733455658,
                3.0239431858062744,
                4.968075275421143,
                5.101767539978027,
                2.7377712726593018,
                2.201345682144165,
                4.76346492767334,
                -0.16945131123065948,
                1.8771448135375977,
                3.8668339252471924,
                3.1404943466186523,
                1.6848011016845703,
                2.412627935409546,
                2.6419026851654053
            ],
            [
                4.1715168952941895,
                0.05748475715517998,
                -0.021336490288376808,
                1.1590888500213623,
                1.9802707433700562,
                2.1505494117736816,
                3.4505608081817627,
                1.6770991086959839,
                1.9304993152618408,
                2.9584057331085205,
                1.7262345552444458,
                0.020591070875525475,
                2.664447069168091,
                0.3003018796443939,
                0.5735471844673157,
                4.7027506828308105
            ],
            [
                3.8877336978912354,
                1.9411242008209229,
                1.276979923248291,
                1.1665853261947632,
                1.593855619430542,
                2.495953321456909,
                0.4914596974849701,
                1.6106141805648804,
                1.6564210653305054,
                0.09038238972425461,
                3.4000396728515625,
                3.348432779312134,
                0.2127472311258316,
                3.554321050643921,
                2.975226402282715,
                3.1754987239837646
            ],
            [
                2.0310277938842773,
                2.547333240509033,
                -0.14999955892562866,
                1.2566368579864502,
                1.1594650745391846,
                0.2501966953277588,
                1.9333876371383667,
                1.5330790281295776,
                4.207720756530762,
                3.8431427478790283,
                4.8049211502075195,
                0.9298163652420044,
                6.207889556884766,
                3.0810863971710205,
                1.9820067882537842,
                5.63939094543457
            ],
            [
                2.6281511783599854,
                4.234528064727783,
                3.056116819381714,
                0.6266156435012817,
                -0.16951346397399902,
                1.1356123685836792,
                0.812692403793335,
                0.006871802732348442,
                3.607724189758301,
                0.9462775588035583,
                2.414104700088501,
                2.4802796840667725,
                1.6619789600372314,
                7.349410057067871,
                0.12230923771858215,
                1.8382123708724976
            ],
            [
                0.06507166475057602,
                0.41498127579689026,
                -0.14164134860038757,
                -0.000043448770156828687,
                0.22156009078025818,
                0.07040241360664368,
                0.21026025712490082,
                0.11628331989049911,
                -0.018056664615869522,
                0.025876618921756744,
                0.12950176000595093,
                0.1164064034819603,
                -0.014479642733931541,
                0.04033125191926956,
                0.013750684447586536,
                -0.039436519145965576
            ],
            [
                0.56778883934021,
                0.39504167437553406,
                0.4764684736728668,
                0.044055450707674026,
                0.13343586027622223,
                -0.15071149170398712,
                0.02629036456346512,
                0.2550087869167328,
                -0.021127454936504364,
                0.24330651760101318,
                0.31536877155303955,
                0.038057152181863785,
                0.09570170938968658,
                0.22244806587696075,
                0.12580569088459015,
                0.04698173701763153
            ],
            [
                0.1710934042930603,
                0.13431477546691895,
                0.187213733792305,
                -0.033147141337394714,
                -0.07423431426286697,
                0.09808666259050369,
                0.21061153709888458,
                0.23527739942073822,
                0.26073217391967773,
                0.5837094783782959,
                0.2402278184890747,
                0.021052706986665726,
                0.026777194812893867,
                0.4248214662075043,
                0.07348355650901794,
                0.10076794028282166
            ],
            [
                0.6584004759788513,
                0.26172685623168945,
                -0.08004483580589294,
                -0.11653966456651688,
                -0.14486850798130035,
                0.15117740631103516,
                0.1388598084449768,
                0.07139160484075546,
                -0.05353759229183197,
                0.39149609208106995,
                -0.06810054928064346,
                0.07751647382974625,
                0.30818837881088257,
                -0.01607634127140045,
                0.020591942593455315,
                0.004277393221855164
            ],
            [
                0.13995647430419922,
                0.36293110251426697,
                -0.00029594619991257787,
                0.23912014067173004,
                0.004239984788000584,
                -0.11335202306509018,
                0.24922902882099152,
                0.11413535475730896,
                -0.018350645899772644,
                -0.037310559302568436,
                0.25201451778411865,
                0.1985998898744583,
                -0.10160654038190842,
                0.23045791685581207,
                0.22445915639400482,
                0.009200879372656345
            ],
            [
                -0.11936207115650177,
                0.05922582000494003,
                0.13336898386478424,
                0.147403746843338,
                0.47365719079971313,
                0.08665774017572403,
                0.42607444524765015,
                -0.043617285788059235,
                -0.040181826800107956,
                0.03962591290473938,
                0.24208852648735046,
                -0.0221317820250988,
                0.07098507881164551,
                -0.1410789042711258,
                0.4059716463088989,
                -0.0420292429625988
            ],
            [
                0.1833946704864502,
                0.2387787401676178,
                -0.03516674414277077,
                0.10958322882652283,
                -0.14943508803844452,
                -0.10758945345878601,
                0.24377258121967316,
                0.0973140299320221,
                0.30702221393585205,
                0.04467125982046127,
                0.1309867948293686,
                -0.026492329314351082,
                0.12378708273172379,
                0.06696076691150665,
                -0.10372557491064072,
                -0.07723066210746765
            ],
            [
                -0.011149981990456581,
                0.4527147114276886,
                0.05186969041824341,
                -0.03488803654909134,
                0.2968970537185669,
                -0.03902174159884453,
                -0.14033617079257965,
                -0.0015467483317479491,
                0.020707951858639717,
                0.061702847480773926,
                -0.07839640229940414,
                0.1554359644651413,
                0.06488576531410217,
                0.1063302755355835,
                0.08455871045589447,
                -0.13043759763240814
            ]
        ],
        [
            [
                -0.10946232080459595,
                0.29949066042900085,
                0.8101583123207092,
                2.676948308944702,
                1.1226986646652222,
                -0.1568201184272766,
                0.11573413759469986,
                -0.160738006234169,
                0.041009776294231415,
                -0.15824195742607117,
                -0.02509852685034275,
                -0.16331668198108673,
                -0.0063146986067295074,
                0.14741279184818268,
                1.3804447650909424,
                0.4624064862728119
            ],
            [
                -0.006529484875500202,
                -0.07948649674654007,
                -0.0738990530371666,
                0.02756122127175331,
                -0.1689603328704834,
                0.1563478261232376,
                -0.01626279391348362,
                2.7016847133636475,
                3.1227879524230957,
                -0.00833221897482872,
                -0.006898736581206322,
                -0.08529626578092575,
                -0.0242697075009346,
                0.28012341260910034,
                1.693041205406189,
                1.0848113298416138
            ],
            [
                -0.048158228397369385,
                0.3701719045639038,
                -0.16625802218914032,
                -0.12817220389842987,
                0.2675302028656006,
                3.186889886856079,
                0.17742033302783966,
                -0.0878593921661377,
                -0.09204648435115814,
                -0.08344753831624985,
                -0.0008237360161729157,
                1.6278244256973267,
                1.2081973552703857,
                3.181983232498169,
                3.644315481185913,
                3.2650766372680664
            ],
            [
                -0.06526992470026016,
                -0.16803383827209473,
                -0.031812261790037155,
                -0.1121428832411766,
                1.1584781408309937,
                4.225169658660889,
                1.25066077709198,
                -0.15902318060398102,
                1.2212046384811401,
                -0.11670754104852676,
                -0.08688952028751373,
                -0.0781172439455986,
                0.4980367124080658,
                0.833232045173645,
                1.5718835592269897,
                -0.10899856686592102
            ],
            [
                -0.156752809882164,
                0.7932761907577515,
                -0.10376354306936264,
                -0.000417455070419237,
                -0.15922527015209198,
                0.463550329208374,
                -0.1322711557149887,
                0.007518330123275518,
                0.4005531668663025,
                0.5694558620452881,
                -0.09452454745769501,
                -0.07923636585474014,
                -0.16857095062732697,
                -0.11503416299819946,
                -0.16361941397190094,
                -0.112850621342659
            ],
            [
                -0.16030201315879822,
                -0.14428283274173737,
                -0.16264541447162628,
                -0.0001767508074408397,
                -0.0012161677004769444,
                -0.010465506464242935,
                -0.09732430428266525,
                -0.0753982663154602,
                -0.014620638452470303,
                -0.14796940982341766,
                1.8505966663360596,
                5.376184463500977,
                1.5771396160125732,
                0.1453075408935547,
                1.5853843688964844,
                -0.11623197048902512
            ],
            [
                -0.016078680753707886,
                -0.00391316507011652,
                -0.027507714927196503,
                -0.12524627149105072,
                -0.009997961111366749,
                -0.003123810049146414,
                -0.11750103533267975,
                -0.01026826724410057,
                1.1371549367904663,
                2.6138038635253906,
                0.5129464864730835,
                -0.17004074156284332,
                2.174759864807129,
                6.833235263824463,
                3.1288394927978516,
                0.4958641529083252
            ],
            [
                -0.04105648398399353,
                -0.06863045692443848,
                0.42655256390571594,
                3.389841079711914,
                -0.07038551568984985,
                -0.0860951617360115,
                -0.11890344321727753,
                -0.06941511482000351,
                1.3441503047943115,
                1.7438513040542603,
                -0.012804703786969185,
                -0.06977689266204834,
                -0.16951778531074524,
                7.193589687347412,
                6.667693614959717,
                -0.08157867938280106
            ],
            [
                -0.11012638360261917,
                0.17459560930728912,
                0.18136657774448395,
                0.021278638392686844,
                -0.06467573344707489,
                0.0269926805049181,
                0.12340187281370163,
                0.19112302362918854,
                0.40194571018218994,
                0.23452328145503998,
                -0.049998097121715546,
                0.054715778678655624,
                0.006259182933717966,
                0.10012906044721603,
                0.4494888186454773,
                -0.12146645039319992
            ],
            [
                -0.12134584039449692,
                -0.05270727351307869,
                -0.05614680424332619,
                -0.08803582191467285,
                0.01823311671614647,
                0.14967556297779083,
                -0.09088130295276642,
                0.16220559179782867,
                0.005344203673303127,
                -0.020736509934067726,
                -0.02795880101621151,
                -0.11693506687879562,
                -0.033822692930698395,
                -0.035103533416986465,
                -0.10806769877672195,
                -0.14927920699119568
            ],
            [
                -0.1302514672279358,
                -0.07167400419712067,
                0.01699281483888626,
                0.057245656847953796,
                0.15260611474514008,
                -0.066375233232975,
                -0.09000653773546219,
                -0.06553030759096146,
                0.11858214437961578,
                -0.04807290434837341,
                -0.05619078502058983,
                -0.11775482445955276,
                -0.023462794721126556,
                -0.0579889677464962,
                -0.03469914197921753,
                -0.11780758947134018
            ],
            [
                -0.14964206516742706,
                0.019714636728167534,
                -0.028965990990400314,
                0.3306404650211334,
                0.1778753250837326,
                -0.11662174016237259,
                -0.06558515131473541,
                -0.05647721141576767,
                0.06752803176641464,
                -0.00560363894328475,
                -0.09793756902217865,
                0.2698424160480499,
                -0.006267752964049578,
                -0.1332148164510727,
                -0.07811877876520157,
                -0.15733899176120758
            ],
            [
                -0.1364426612854004,
                -0.08761858940124512,
                0.0901966243982315,
                -0.06876815110445023,
                0.5352268815040588,
                -0.03386976569890976,
                0.04069209843873978,
                0.03583795577287674,
                -0.11725924164056778,
                -0.08491511642932892,
                0.002005892340093851,
                0.7502259016036987,
                0.2104688286781311,
                -0.09532997012138367,
                0.1804107129573822,
                -0.12822286784648895
            ],
            [
                -0.09679298102855682,
                0.023841561749577522,
                0.21825741231441498,
                -0.13361240923404694,
                0.06386648863554001,
                -0.06618331372737885,
                0.05792025849223137,
                0.05462978035211563,
                0.08272009342908859,
                0.09333276748657227,
                -0.02022429183125496,
                0.5302616357803345,
                0.11860161274671555,
                0.2889253795146942,
                0.06337469071149826,
                -0.02520892210304737
            ],
            [
                -0.1386118233203888,
                -0.04752007871866226,
                -0.06869467347860336,
                0.04761052504181862,
                0.47064200043678284,
                0.06079964339733124,
                -0.0010452510323375463,
                -0.018647560849785805,
                0.050606656819581985,
                0.1369108259677887,
                0.23212189972400665,
                -0.09262523800134659,
                -0.036860860884189606,
                0.05262565240263939,
                0.00776117155328393,
                -0.0202731154859066
            ],
            [
                -0.1492205113172531,
                -0.12262962758541107,
                -0.05458056554198265,
                -0.16796860098838806,
                -0.0622759684920311,
                -0.09765972942113876,
                -0.13823091983795166,
                -0.13369330763816833,
                -0.155301034450531,
                -0.1532924920320511,
                -0.02129223570227623,
                -0.1265631467103958,
                -0.1515190601348877,
                -0.1639380007982254,
                0.05367213860154152,
                -0.035591237246990204
            ]
        ],
        [
            [
                -0.000006760613814549288,
                -0.0000024800776827760274,
                -0.0013232359196990728,
                -0.00010719597776187584,
                -0.013243498280644417,
                -0.000030093138775555417,
                -0.00017011018644552678,
                -0.004785572178661823,
                -0.0000803672955953516,
                -0.000008674159289512318,
                -0.00001697560219326988,
                -0.00021198505419306457,
                -0.00023209118808154017,
                -0.000049293394113192335,
                -0.000007150283181545092,
                -0.0000016817575669847429
            ],
            [
                -0.000012426381545083132,
                0,
                -0.0003709711891133338,
                -0.000015268122297129594,
                -0.00029806746169924736,
                -0.0002264303038828075,
                -0.0000018210705547971884,
                -0.008245102129876614,
                -0.00002555636274337303,
                -0.00002877691986213904,
                -0.00001612069900147617,
                -0.00000791296497482108,
                -0.00016497421893291175,
                -0.000004198756414552918,
                -0.00001795520438463427,
                -0.00040569319389760494
            ],
            [
                -0.002064055996015668,
                0,
                -0.000021107591237523593,
                0,
                -0.000019050972696277313,
                -0.051047682762145996,
                -0.0002086097956635058,
                -0.000018808012100635096,
                0,
                -0.00001105749197449768,
                -0.000001804131443350343,
                -0.00003846090112347156,
                -0.0000011288695986877428,
                0,
                0,
                -0.0006983514176681638
            ],
            [
                -0.00008999944111565128,
                -0.001352048828266561,
                -0.000018323786207474768,
                -0.000018322081814403646,
                -0.0000010025999017670983,
                -0.0000011322908903821371,
                -0.0007776410784572363,
                -0.00007441233174176887,
                -0.000005096960649098037,
                -0.00039128336356952786,
                -0.000011921236364287324,
                -0.00028722418937832117,
                -0.0008117729448713362,
                -0.0011478550732135773,
                -0.00006885551556479186,
                -0.000990131520666182
            ],
            [
                -0.0032114346977323294,
                -0.0000024883461264835205,
                -0.00006795208901166916,
                -0.0000018128647525372799,
                -0.000006642613698204514,
                -4.35217373251362e-7,
                -0.0013050101697444916,
                -4.3535681015782757e-7,
                -4.346701132362796e-7,
                -0.00006987993401708081,
                -0.000596372876316309,
                -0.000026402394723845646,
                -0.000397330877603963,
                -0.0002509151818230748,
                -0.000007277726581378374,
                -4.357811462796235e-7
            ],
            [
                -0.00013253360521048307,
                -0.00006510292587336153,
                -0.0000010012178108809167,
                -0.0001048761623678729,
                -0.0000034018539736280218,
                -0.0000014094541711529018,
                -0.0012926602503284812,
                -0.00000248671449298854,
                -0.000020625700926757418,
                0,
                -0.0000026117843390238704,
                -0.000007273819392139558,
                -0.000023160106138675474,
                -1.5356060600879573e-7,
                -0.0005891727050766349,
                -0.007591262459754944
            ],
            [
                -0.005121151451021433,
                -0.00022148966672830284,
                -0.000004316502327128546,
                -0.00003975323852500878,
                -0.000030565406632376835,
                -0.0000015452593515874469,
                -0.0000035315490549692186,
                -4.331345166974643e-7,
                -0.0000010065210744869546,
                -0.00011668040679069236,
                -0.00001624366814212408,
                -0.00006179856427479535,
                -0.00001254831295227632,
                -0.000014285218639997765,
                -0.0004673214571084827,
                -0.00032465060940012336
            ],
            [
                -0.001089629135094583,
                -0.000007263613042596262,
                -4.371626687316166e-7,
                -0.15659649670124054,
                -0.0038916573394089937,
                -0.0008512867498211563,
                -0.00022945573437027633,
                -0.0008502427372150123,
                -0.00012424259330146015,
                -0.06114048883318901,
                -0.00015916275151539594,
                -0.0000080314757724409,
                -0.000009303498700319324,
                -0.00013546405534725636,
                -0.005623925011605024,
                -0.006272695027291775
            ],
            [
                -0.0841643288731575,
                -0.08510210365056992,
                0.04056908190250397,
                -0.05022171512246132,
                0.14669358730316162,
                -0.05793483182787895,
                -0.06414814293384552,
                -0.13327786326408386,
                -0.004318047780543566,
                -0.07142949849367142,
                -0.1556553691625595,
                -0.12312699109315872,
                0.16692961752414703,
                0.34554949402809143,
                0.501761794090271,
                0.11653541773557663
            ],
            [
                -0.14937807619571686,
                -0.1344396322965622,
                -0.06895481795072556,
                -0.13553635776042938,
                -0.12109923362731934,
                0.10951235145330429,
                -0.13020090758800507,
                -0.15407943725585938,
                0.010174287483096123,
                -0.14779086410999298,
                -0.08812365680932999,
                -0.16936622560024261,
                -0.1639881283044815,
                -0.16208481788635254,
                -0.16555997729301453,
                -0.17003433406352997
            ],
            [
                -0.16988536715507507,
                -0.028718849644064903,
                -0.16048897802829742,
                -0.1185431182384491,
                -0.13600309193134308,
                -0.1550670713186264,
                -0.16416463255882263,
                -0.11117389798164368,
                -0.13945575058460236,
                -0.16599924862384796,
                -0.10213807970285416,
                -0.15659967064857483,
                -0.02684791572391987,
                -0.1473652720451355,
                -0.17004048824310303,
                -0.1686309576034546
            ],
            [
                -0.14957992732524872,
                -0.13349886238574982,
                -0.14158104360103607,
                0.03089313395321369,
                -0.10529164224863052,
                -0.13869012892246246,
                -0.16960257291793823,
                -0.12344370037317276,
                -0.0672895759344101,
                -0.07398679852485657,
                -0.12661564350128174,
                0.08332765847444534,
                -0.07143296301364899,
                -0.15807686746120453,
                -0.16800591349601746,
                -0.16130882501602173
            ],
            [
                -0.1678590625524521,
                -0.16775459051132202,
                -0.042626023292541504,
                -0.15089388191699982,
                0.0672268494963646,
                0.11665000766515732,
                -0.12462303787469864,
                -0.15997885167598724,
                -0.12950797379016876,
                -0.11247683316469193,
                -0.12100250273942947,
                0.04159296303987503,
                0.14800234138965607,
                -0.03907571732997894,
                -0.1624809205532074,
                -0.16875812411308289
            ],
            [
                -0.15758386254310608,
                -0.15771590173244476,
                -0.04211149364709854,
                -0.15640369057655334,
                -0.10035403072834015,
                -0.15510514378547668,
                -0.16789868474006653,
                -0.08290707319974899,
                -0.11665350943803787,
                -0.046941399574279785,
                0.01813037134706974,
                0.00682302750647068,
                -0.037164174020290375,
                0.17095984518527985,
                -0.08684471994638443,
                -0.0675274208188057
            ],
            [
                -0.14344117045402527,
                -0.1342262625694275,
                -0.12934055924415588,
                -0.16007667779922485,
                0.05439024791121483,
                -0.09934090077877045,
                -0.12333589792251587,
                -0.16076818108558655,
                -0.16992689669132233,
                -0.019786562770605087,
                -0.13133540749549866,
                0.15359985828399658,
                -0.1513124257326126,
                -0.09354502707719803,
                -0.016551556065678596,
                0.3209778964519501
            ],
            [
                -0.1688443124294281,
                -0.16843168437480927,
                -0.16540616750717163,
                -0.09575096517801285,
                -0.12603269517421722,
                -0.15466031432151794,
                0.036191631108522415,
                -0.1587027907371521,
                -0.1695202887058258,
                -0.11360370367765427,
                -0.1542331725358963,
                -0.1399284452199936,
                -0.17001895606517792,
                -0.1487899124622345,
                -0.15177863836288452,
                0.2354358434677124
            ]
        ],
        [
            [
                1.0713049173355103,
                3.9926652908325195,
                1.9280298948287964,
                5.271693706512451,
                4.201086044311523,
                5.968657493591309,
                4.9779582023620605,
                1.3254077434539795,
                3.350386381149292,
                2.65981388092041,
                3.3973851203918457,
                5.2247490882873535,
                2.5213027000427246,
                3.597724437713623,
                4.308248043060303,
                4.244178295135498
            ],
            [
                2.5624964237213135,
                6.324542045593262,
                3.2154557704925537,
                1.9055410623550415,
                4.281720161437988,
                6.043613910675049,
                4.242528438568115,
                4.392855644226074,
                4.662384986877441,
                2.938549757003784,
                1.8967336416244507,
                2.8615012168884277,
                3.611793041229248,
                5.371723651885986,
                5.174234867095947,
                3.8479959964752197
            ],
            [
                1.0851489305496216,
                4.663102149963379,
                2.6450681686401367,
                5.830653190612793,
                6.820442199707031,
                6.780814170837402,
                6.240017890930176,
                5.115530490875244,
                6.578695297241211,
                3.8550257682800293,
                1.038100242614746,
                4.282928943634033,
                4.76298189163208,
                7.2011637687683105,
                8.374764442443848,
                3.9955506324768066
            ],
            [
                2.4041054248809814,
                2.8927273750305176,
                3.814626455307007,
                4.622745037078857,
                6.297712326049805,
                7.19257116317749,
                6.477502822875977,
                2.5472536087036133,
                4.077395915985107,
                1.7313448190689087,
                3.256032943725586,
                3.751309394836426,
                3.4040627479553223,
                5.42942476272583,
                5.995846271514893,
                2.498551368713379
            ],
            [
                4.148097038269043,
                8.183239936828613,
                4.475017070770264,
                1.6012349128723145,
                4.779548645019531,
                6.633681774139404,
                3.71581768989563,
                4.384775638580322,
                2.9854605197906494,
                5.3778486251831055,
                2.8326687812805176,
                5.7055768966674805,
                2.055649518966675,
                3.090132236480713,
                5.0902581214904785,
                2.3153903484344482
            ],
            [
                0.44621625542640686,
                3.79115629196167,
                4.985537052154541,
                4.489823341369629,
                3.2041726112365723,
                3.734544277191162,
                6.191727638244629,
                3.840855598449707,
                2.9006187915802,
                4.976320743560791,
                5.8159871101379395,
                6.682356357574463,
                6.2522406578063965,
                4.46167516708374,
                5.433509826660156,
                3.495232343673706
            ],
            [
                2.511441946029663,
                4.972616672515869,
                6.469185829162598,
                5.515809535980225,
                2.761028289794922,
                3.756347894668579,
                3.3723578453063965,
                1.7587649822235107,
                3.654083728790283,
                3.575733184814453,
                5.367179870605469,
                2.671527147293091,
                4.689285755157471,
                7.288454532623291,
                5.168584823608398,
                4.724188804626465
            ],
            [
                1.8162858486175537,
                5.2634053230285645,
                6.730315685272217,
                6.665921688079834,
                2.2106587886810303,
                3.3584885597229004,
                4.780979156494141,
                2.602175235748291,
                4.080715179443359,
                3.595508337020874,
                3.074439764022827,
                3.93839168548584,
                7.207724094390869,
                6.101027011871338,
                4.203465461730957,
                1.6487253904342651
            ],
            [
                -0.1556251496076584,
                -0.040695976465940475,
                0.017720911651849747,
                -0.15578873455524445,
                -0.1696888953447342,
                -0.16875691711902618,
                -0.11152333766222,
                -0.08267946541309357,
                -0.04298006370663643,
                -0.031064525246620178,
                -0.14849701523780823,
                -0.14310891926288605,
                -0.14999905228614807,
                -0.16987891495227814,
                -0.16962967813014984,
                -0.158379927277565
            ],
            [
                0.19781161844730377,
                -0.14660315215587616,
                -0.11417147517204285,
                -0.05564674735069275,
                -0.11054719984531403,
                -0.13542751967906952,
                -0.0845290794968605,
                -0.027354484423995018,
                -0.16789820790290833,
                -0.15729738771915436,
                -0.1374998390674591,
                -0.12062830477952957,
                -0.14194457232952118,
                -0.15897080302238464,
                -0.16372321546077728,
                0.027576327323913574
            ],
            [
                -0.15639610588550568,
                -0.16185840964317322,
                -0.1549849510192871,
                -0.1238098293542862,
                -0.170039564371109,
                -0.16682416200637817,
                0.035000067204236984,
                -0.1172841265797615,
                -0.14101819694042206,
                -0.07961629331111908,
                -0.10174684971570969,
                -0.15468242764472961,
                -0.13495483994483948,
                -0.08005347847938538,
                -0.1159001886844635,
                -0.03932180628180504
            ],
            [
                -0.0736815333366394,
                -0.0994437038898468,
                -0.16947321593761444,
                -0.1656065732240677,
                -0.15546391904354095,
                -0.13627956807613373,
                -0.15529726445674896,
                -0.16873469948768616,
                -0.16675367951393127,
                -0.16981390118598938,
                -0.16969047486782074,
                -0.11715931445360184,
                -0.16987542808055878,
                -0.16757220029830933,
                -0.12024504691362381,
                -0.07351343333721161
            ],
            [
                -0.15776607394218445,
                -0.05730084702372551,
                -0.12264098227024078,
                -0.15195976197719574,
                -0.14779329299926758,
                -0.15023425221443176,
                -0.1457938700914383,
                -0.15594716370105743,
                -0.164495587348938,
                -0.1695958524942398,
                -0.11193937063217163,
                -0.0431111678481102,
                -0.15873274207115173,
                -0.13515157997608185,
                -0.11410319060087204,
                -0.008082827553153038
            ],
            [
                0.05480130761861801,
                -0.12614497542381287,
                -0.125832661986351,
                -0.16616353392601013,
                -0.13021649420261383,
                -0.1669938862323761,
                -0.16262038052082062,
                -0.16301339864730835,
                -0.14641986787319183,
                -0.15722493827342987,
                -0.15897086262702942,
                0.06468655914068222,
                -0.1673547774553299,
                -0.12972649931907654,
                -0.13631795346736908,
                -0.09287150204181671
            ],
            [
                -0.13648928701877594,
                -0.16526445746421814,
                -0.16922712326049805,
                -0.15345171093940735,
                -0.09412268549203873,
                -0.14871513843536377,
                -0.1342223435640335,
                -0.11147080361843109,
                -0.1289198249578476,
                -0.12101916968822479,
                -0.12882179021835327,
                -0.14155612885951996,
                -0.16534699499607086,
                -0.08958867192268372,
                -0.14398077130317688,
                -0.10870493948459625
            ],
            [
                -0.08504462242126465,
                -0.10422069579362869,
                -0.008688204921782017,
                -0.13322648406028748,
                -0.1644943803548813,
                -0.13831204175949097,
                -0.14636050164699554,
                -0.03254200890660286,
                0.00544454762712121,
                -0.008703540079295635,
                -0.092148058116436,
                -0.09078720211982727,
                -0.08667592704296112,
                -0.032565608620643616,
                -0.09773224592208862,
                -0.026033960282802582
            ]
        ],
        [
            [
                -0.15654955804347992,
                0.4299708306789398,
                1.861484169960022,
                5.2464141845703125,
                3.171703815460205,
                -0.06600907444953918,
                0.0014507374726235867,
                0.356221079826355,
                0.1348934769630432,
                -0.16836519539356232,
                -0.0693017914891243,
                0.33323365449905396,
                -0.13176503777503967,
                -0.06445607542991638,
                0.5281721949577332,
                0.9820355176925659
            ],
            [
                0.06912556290626526,
                1.9614895582199097,
                0.6642801761627197,
                4.201065540313721,
                2.6637885570526123,
                -0.12253901362419128,
                -0.13598373532295227,
                4.84900426864624,
                4.416601657867432,
                0.4915294945240021,
                0.14548932015895844,
                -0.16997690498828888,
                0.8934929370880127,
                -0.1353192776441574,
                2.2287826538085938,
                4.621269226074219
            ],
            [
                1.9414762258529663,
                0.8827258944511414,
                1.334852933883667,
                0.22708262503147125,
                0.8915215730667114,
                1.7868695259094238,
                0.4072704017162323,
                2.330327272415161,
                2.9468178749084473,
                0.17198646068572998,
                0.3381347060203552,
                2.5225489139556885,
                1.7803610563278198,
                1.5905641317367554,
                8.94384765625,
                3.3016231060028076
            ],
            [
                -0.13391633331775665,
                -0.06392581015825272,
                0.254672646522522,
                1.8846323490142822,
                2.253488540649414,
                6.1618571281433105,
                1.05789053440094,
                3.0456857681274414,
                4.375113010406494,
                1.2270666360855103,
                0.40638992190361023,
                2.2510316371917725,
                1.9345782995224,
                1.4801114797592163,
                1.8090115785598755,
                1.8818466663360596
            ],
            [
                -0.11315842717885971,
                0.3149690330028534,
                0.34095385670661926,
                2.271793842315674,
                4.255681037902832,
                3.7562780380249023,
                1.3186473846435547,
                0.34938013553619385,
                2.6694583892822266,
                2.372565507888794,
                3.827307939529419,
                0.27450066804885864,
                0.1628570705652237,
                0.9394746422767639,
                0.642527163028717,
                -0.16796767711639404
            ],
            [
                -0.17004065215587616,
                0.5255188941955566,
                -0.15675698220729828,
                1.865736722946167,
                1.6468452215194702,
                -0.14720353484153748,
                0.18831142783164978,
                -0.07990241795778275,
                1.960753083229065,
                1.1906911134719849,
                4.034007549285889,
                3.0731632709503174,
                1.5547279119491577,
                1.6561442613601685,
                3.7338478565216064,
                0.27721527218818665
            ],
            [
                -0.01309263240545988,
                0.931433916091919,
                0.594192624092102,
                2.090425491333008,
                -0.12866781651973724,
                -0.16878823935985565,
                0.01996421441435814,
                -0.14647553861141205,
                1.5950254201889038,
                3.5777223110198975,
                3.789116144180298,
                2.52960205078125,
                3.534986734390259,
                6.458973407745361,
                4.842074871063232,
                -0.08976008743047714
            ],
            [
                -0.1698024421930313,
                -0.12516416609287262,
                1.164070963859558,
                4.309462547302246,
                2.4352729320526123,
                -0.056099820882081985,
                -0.10334739834070206,
                -0.16780886054039001,
                0.7888323068618774,
                4.069459438323975,
                -0.16996967792510986,
                0.026340898126363754,
                1.030967116355896,
                2.4524083137512207,
                7.863964080810547,
                4.124107360839844
            ],
            [
                -0.16921843588352203,
                -0.16680727899074554,
                -0.16619054973125458,
                -0.1630534827709198,
                -0.16203634440898895,
                -0.16944731771945953,
                -0.16960427165031433,
                -0.16023428738117218,
                -0.14484983682632446,
                -0.17002081871032715,
                -0.1630166918039322,
                -0.16730031371116638,
                -0.16216987371444702,
                -0.15584807097911835,
                -0.16101068258285522,
                -0.1508968621492386
            ],
            [
                -0.15670564770698547,
                -0.15857632458209991,
                -0.16789904236793518,
                -0.16794203221797943,
                -0.16489838063716888,
                -0.16116036474704742,
                -0.1699732095003128,
                -0.1660212278366089,
                -0.17002375423908234,
                -0.16312293708324432,
                -0.166525736451149,
                -0.14815577864646912,
                -0.1689123958349228,
                -0.16875219345092773,
                -0.1691727489233017,
                -0.1654428392648697
            ],
            [
                -0.16849641501903534,
                -0.1686517745256424,
                -0.14294065535068512,
                -0.13893266022205353,
                -0.128449484705925,
                -0.16055947542190552,
                -0.16732139885425568,
                -0.15559221804141998,
                -0.1591712236404419,
                -0.15386667847633362,
                -0.16987618803977966,
                -0.1699589490890503,
                -0.16998150944709778,
                -0.1694200038909912,
                -0.1644565761089325,
                -0.17000751197338104
            ],
            [
                -0.16986426711082458,
                -0.15898625552654266,
                -0.14839744567871094,
                -0.10990504920482635,
                -0.15033908188343048,
                -0.1689632087945938,
                -0.1565261334180832,
                -0.16407229006290436,
                -0.1636277437210083,
                -0.13952897489070892,
                -0.1424524039030075,
                -0.16242578625679016,
                -0.16898497939109802,
                -0.16702069342136383,
                -0.16505537927150726,
                -0.16664370894432068
            ],
            [
                -0.1696140319108963,
                -0.1616133153438568,
                -0.16364595293998718,
                -0.13393409550189972,
                -0.11741942167282104,
                -0.16320209205150604,
                -0.15260782837867737,
                -0.1675996482372284,
                -0.1695384830236435,
                -0.16803617775440216,
                -0.1438969075679779,
                -0.16355304419994354,
                -0.16555483639240265,
                -0.16472755372524261,
                -0.1696006804704666,
                -0.16672870516777039
            ],
            [
                -0.1693570762872696,
                -0.15991614758968353,
                -0.15419124066829681,
                -0.16508832573890686,
                -0.14932143688201904,
                -0.16710075736045837,
                -0.16859844326972961,
                -0.14284685254096985,
                -0.16907218098640442,
                -0.16918045282363892,
                -0.155308797955513,
                -0.13849413394927979,
                -0.16869919002056122,
                -0.16630838811397552,
                -0.13838399946689606,
                -0.1670127511024475
            ],
            [
                -0.169536292552948,
                -0.15831995010375977,
                -0.1532222330570221,
                -0.17001289129257202,
                -0.16999956965446472,
                -0.1428564190864563,
                -0.16417177021503448,
                -0.16660720109939575,
                -0.16600903868675232,
                -0.16114993393421173,
                -0.14302393794059753,
                -0.16554486751556396,
                -0.16029305756092072,
                -0.15896762907505035,
                -0.13750943541526794,
                -0.14625906944274902
            ],
            [
                -0.15576447546482086,
                -0.16725917160511017,
                -0.16176116466522217,
                -0.16792908310890198,
                -0.15801095962524414,
                -0.16225922107696533,
                -0.16978707909584045,
                -0.16568388044834137,
                -0.13488887250423431,
                -0.1632295697927475,
                -0.16437232494354248,
                -0.16798482835292816,
                -0.16814333200454712,
                -0.16709649562835693,
                -0.16802331805229187,
                -0.16984610259532928
            ]
        ],
        [
            [
                -0.16426604986190796,
                -0.15341126918792725,
                -0.1010163202881813,
                -0.10934517532587051,
                0.4112083613872528,
                0.2681591212749481,
                -0.14403922855854034,
                -0.03680087625980377,
                -0.013901832513511181,
                -0.15262483060359955,
                -0.10118337720632553,
                -0.10540357977151871,
                -0.1658214032649994,
                -0.10817791521549225,
                0.8781542181968689,
                -0.01701285131275654
            ],
            [
                -0.05109940096735954,
                0.26390036940574646,
                -0.10626830160617828,
                -0.11477896571159363,
                1.0888948440551758,
                2.1389145851135254,
                -0.11318850517272949,
                1.0473525524139404,
                1.5333309173583984,
                -0.1351935714483261,
                -0.06114984676241875,
                0.19142527878284454,
                0.22338350117206573,
                0.33341971039772034,
                0.3636398911476135,
                -0.018657496199011803
            ],
            [
                -0.07569856941699982,
                1.391587734222412,
                0.6401451230049133,
                0.7290331721305847,
                2.1510801315307617,
                0.130192831158638,
                1.903668999671936,
                0.25072258710861206,
                0.16867168247699738,
                -0.16358260810375214,
                -0.01651044376194477,
                -0.009551307186484337,
                -0.1571151614189148,
                2.311253070831299,
                0.23888856172561646,
                1.234026312828064
            ],
            [
                0.20262117683887482,
                0.3256016671657562,
                0.2190699279308319,
                0.1584213674068451,
                1.0490176677703857,
                2.7110211849212646,
                1.6156882047653198,
                -0.012630063109099865,
                -0.041644562035799026,
                -0.056897200644016266,
                -0.10469819605350494,
                -0.08614729344844818,
                -0.051682259887456894,
                3.109372138977051,
                2.582815647125244,
                -0.06258290261030197
            ],
            [
                0.7178616523742676,
                1.9547964334487915,
                0.050257351249456406,
                -0.010305059142410755,
                0.14415238797664642,
                0.38313230872154236,
                0.04383542388677597,
                1.9071145057678223,
                0.9072666764259338,
                -0.17004038393497467,
                -0.06306224316358566,
                -0.10823626816272736,
                -0.1072576567530632,
                -0.1671527624130249,
                -0.15465177595615387,
                -0.16891548037528992
            ],
            [
                -0.13189290463924408,
                -0.16613996028900146,
                0.19228190183639526,
                -0.10606131702661514,
                0.10694339126348495,
                0.12196522206068039,
                0.11509048938751221,
                0.018973223865032196,
                0.07633355259895325,
                1.4290070533752441,
                1.3369132280349731,
                3.1259498596191406,
                2.2185328006744385,
                -0.05390172079205513,
                -0.1299838423728943,
                -0.09462228417396545
            ],
            [
                -0.11926300078630447,
                -0.019945291802287102,
                3.2686522006988525,
                1.0698813199996948,
                0.6332472562789917,
                1.5227234363555908,
                -0.16020309925079346,
                -0.09884850680828094,
                0.6283984780311584,
                0.3349902033805847,
                -0.08280272781848907,
                0.6287769079208374,
                -0.09552047401666641,
                3.1853842735290527,
                2.3819642066955566,
                1.2433106899261475
            ],
            [
                -0.10042506456375122,
                1.5580828189849854,
                1.3003324270248413,
                0.009731636382639408,
                -0.055296629667282104,
                0.4525908827781677,
                2.1061367988586426,
                0.04442602023482323,
                1.0935205221176147,
                -0.14918388426303864,
                -0.16904276609420776,
                -0.11073395609855652,
                0.6616434454917908,
                1.6742817163467407,
                0.09367619454860687,
                -0.08323103934526443
            ],
            [
                0.3884751498699188,
                0.1394416242837906,
                -0.09242105484008789,
                0.08565498888492584,
                0.2061496078968048,
                0.29547521471977234,
                0.036762285977602005,
                0.11219886690378189,
                0.4009762704372406,
                0.022470753639936447,
                -0.0006172377034090459,
                -0.11568638682365417,
                -0.10912105441093445,
                0.04390469565987587,
                0.04893411695957184,
                -0.043672312051057816
            ],
            [
                0.11742797493934631,
                0.11373655498027802,
                0.09474687278270721,
                0.35785654187202454,
                0.22106027603149414,
                0.16138681769371033,
                0.12523594498634338,
                0.29940173029899597,
                -0.07217513024806976,
                0.12262731790542603,
                0.20019634068012238,
                0.3703294098377228,
                0.4622601270675659,
                0.17016534507274628,
                -0.0051406859420239925,
                -0.05958732217550278
            ],
            [
                0.1358213722705841,
                0.07977092266082764,
                0.123288094997406,
                0.26311013102531433,
                0.2818406820297241,
                0.0949791669845581,
                0.19397065043449402,
                0.048190515488386154,
                0.5942769646644592,
                0.32310751080513,
                0.15597374737262726,
                0.2223491370677948,
                0.7265681028366089,
                0.08534742891788483,
                0.2779407203197479,
                -0.07891624420881271
            ],
            [
                0.19215455651283264,
                0.2114008367061615,
                0.10157984495162964,
                0.19597367942333221,
                0.11482109129428864,
                0.129119873046875,
                0.062141142785549164,
                0.1960357427597046,
                0.4075902998447418,
                0.14792153239250183,
                0.035616304725408554,
                0.24263755977153778,
                0.0467829555273056,
                0.05131630226969719,
                0.3804166316986084,
                0.3595218062400818
            ],
            [
                0.19707860052585602,
                0.439008504152298,
                0.529783308506012,
                0.41138023138046265,
                0.3105621337890625,
                0.5605576634407043,
                0.10470657795667648,
                0.3487536609172821,
                0.10226046293973923,
                0.033113978803157806,
                0.13037976622581482,
                0.2681254744529724,
                0.3942292630672455,
                0.36110255122184753,
                0.06375095993280411,
                -0.021578673273324966
            ],
            [
                0.13042055070400238,
                0.03440966084599495,
                0.12156186997890472,
                0.19058802723884583,
                0.42914023995399475,
                0.04000825434923172,
                0.2585393190383911,
                0.6631348133087158,
                0.20156775414943695,
                0.1769818514585495,
                0.22014160454273224,
                0.19970066845417023,
                0.31401368975639343,
                0.23080149292945862,
                0.08345461636781693,
                0.12337423115968704
            ],
            [
                0.07822323590517044,
                0.23650255799293518,
                0.08190333098173141,
                0.07276421040296555,
                0.06995967775583267,
                0.3473432958126068,
                0.46944233775138855,
                0.24394986033439636,
                0.14525899291038513,
                0.13771747052669525,
                0.26495176553726196,
                0.09655836969614029,
                0.020033884793519974,
                0.3037603497505188,
                0.3045387268066406,
                0.0720839649438858
            ],
            [
                0.20236754417419434,
                0.06247498095035553,
                0.01611267775297165,
                -0.03547259792685509,
                0.09505622833967209,
                0.12345339357852936,
                0.001267123967409134,
                0.10429385304450989,
                0.14164090156555176,
                0.18309658765792847,
                0.1350843459367752,
                0.026091352105140686,
                -0.05493152514100075,
                -0.026894671842455864,
                0.19184771180152893,
                0.0777992233633995
            ]
        ],
        [
            [
                1.0075496435165405,
                1.380461573600769,
                0.40151503682136536,
                1.1853272914886475,
                0.7486971616744995,
                0.9453560709953308,
                1.3874714374542236,
                1.2885265350341797,
                1.644524097442627,
                2.3404884338378906,
                0.3913625478744507,
                0.6320428252220154,
                1.5977498292922974,
                1.6197552680969238,
                2.7694664001464844,
                0.8482311367988586
            ],
            [
                0.47681760787963867,
                1.3492629528045654,
                0.6762923002243042,
                0.047618620097637177,
                2.0806427001953125,
                1.2813471555709839,
                1.4729949235916138,
                3.82883620262146,
                0.7850964665412903,
                0.6799877285957336,
                0.6149269342422485,
                1.1595022678375244,
                0.5643054246902466,
                1.0614006519317627,
                1.5800256729125977,
                2.02315616607666
            ],
            [
                1.0579252243041992,
                1.9942830801010132,
                2.498441457748413,
                0.64689040184021,
                1.1766417026519775,
                2.442612648010254,
                3.7618517875671387,
                -0.1149362325668335,
                0.44383180141448975,
                0.9318730235099792,
                1.6272039413452148,
                1.9658193588256836,
                2.269329309463501,
                1.4410587549209595,
                1.1069473028182983,
                3.041445732116699
            ],
            [
                1.3675264120101929,
                2.610403060913086,
                -0.16284315288066864,
                -0.07732764631509781,
                2.1920504570007324,
                1.6768704652786255,
                1.146704912185669,
                0.18328723311424255,
                1.7549467086791992,
                2.1500561237335205,
                0.6348780393600464,
                1.8126657009124756,
                1.5182974338531494,
                3.0421206951141357,
                2.6633782386779785,
                1.9059393405914307
            ],
            [
                2.0328612327575684,
                1.7133222818374634,
                0.1368524581193924,
                -0.07260923832654953,
                -0.00166149134747684,
                0.49757710099220276,
                2.100959062576294,
                1.9568930864334106,
                2.404536485671997,
                1.7407159805297852,
                0.6117016077041626,
                1.0702670812606812,
                2.160400867462158,
                0.7737491726875305,
                2.659731149673462,
                1.960517168045044
            ],
            [
                1.612223505973816,
                1.313346266746521,
                2.2239551544189453,
                -0.153616264462471,
                1.5476806163787842,
                -0.02730879932641983,
                0.46268805861473083,
                1.8589023351669312,
                0.711519181728363,
                0.16352571547031403,
                1.4917656183242798,
                3.516510009765625,
                2.905291795730591,
                0.6399208903312683,
                2.0525195598602295,
                2.0200183391571045
            ],
            [
                1.1909027099609375,
                0.7850217223167419,
                0.8323478102684021,
                0.8040676712989807,
                2.2357494831085205,
                1.5402723550796509,
                2.127530097961426,
                1.7153286933898926,
                2.2060844898223877,
                1.8905267715454102,
                1.0848644971847534,
                1.3984593152999878,
                3.56508207321167,
                3.1668803691864014,
                2.1553795337677,
                2.5403826236724854
            ],
            [
                1.397172451019287,
                1.3698052167892456,
                2.518756628036499,
                1.3208909034729004,
                2.571150779724121,
                2.51874041557312,
                2.1927835941314697,
                1.5853075981140137,
                2.3620123863220215,
                3.0393216609954834,
                0.4534945487976074,
                0.6149865388870239,
                0.7118142247200012,
                4.333901882171631,
                3.2252886295318604,
                2.3987956047058105
            ],
            [
                -0.17004072666168213,
                -0.16794610023498535,
                -0.1690211296081543,
                -0.13385862112045288,
                -0.14504782855510712,
                -0.16630209982395172,
                -0.14117951691150665,
                -0.16638226807117462,
                -0.16889259219169617,
                -0.1686343252658844,
                -0.16986139118671417,
                -0.16957417130470276,
                -0.15888558328151703,
                -0.1532214879989624,
                -0.10130633413791656,
                -0.15539275109767914
            ],
            [
                -0.16472765803337097,
                -0.15476039052009583,
                -0.16738851368427277,
                -0.11599195003509521,
                -0.1302078515291214,
                -0.14002400636672974,
                -0.12756261229515076,
                -0.15801967680454254,
                -0.16041551530361176,
                -0.15525731444358826,
                -0.16088862717151642,
                -0.1310179978609085,
                -0.16022580862045288,
                -0.15865643322467804,
                -0.12837116420269012,
                -0.16000717878341675
            ],
            [
                -0.14093926548957825,
                -0.14659303426742554,
                -0.10456214845180511,
                -0.13648658990859985,
                -0.12069851905107498,
                -0.15611431002616882,
                -0.15051642060279846,
                -0.16359426081180573,
                -0.12479118257761002,
                -0.1453811526298523,
                -0.13436412811279297,
                -0.1414029598236084,
                -0.15729528665542603,
                -0.13242396712303162,
                -0.13832178711891174,
                -0.16669297218322754
            ],
            [
                -0.1699998676776886,
                -0.15380023419857025,
                -0.06875105947256088,
                -0.15089397132396698,
                -0.15212053060531616,
                -0.15902361273765564,
                -0.1401284635066986,
                -0.03020681068301201,
                -0.07083795219659805,
                -0.10498183965682983,
                -0.12165936082601547,
                -0.06258045136928558,
                -0.05207674950361252,
                -0.14049594104290009,
                -0.14261634647846222,
                -0.15184274315834045
            ],
            [
                -0.1560974270105362,
                -0.10936897248029709,
                -0.08901943266391754,
                -0.07586351037025452,
                -0.1101875752210617,
                -0.13862057030200958,
                -0.1273312121629715,
                -0.14618629217147827,
                -0.12228140234947205,
                -0.11944717913866043,
                -0.1250789314508438,
                -0.09187714010477066,
                -0.060419727116823196,
                -0.10834737867116928,
                -0.16161364316940308,
                -0.16942225396633148
            ],
            [
                -0.16950425505638123,
                -0.10944794863462448,
                -0.06212923303246498,
                -0.1047329306602478,
                -0.13278411328792572,
                -0.10630565881729126,
                -0.1276950091123581,
                -0.12689976394176483,
                -0.060545265674591064,
                -0.07824129611253738,
                0.059319473803043365,
                -0.14232291281223297,
                -0.11700296401977539,
                -0.05364714190363884,
                -0.1024247407913208,
                -0.17002582550048828
            ],
            [
                -0.16146080195903778,
                -0.13717052340507507,
                -0.09212112426757812,
                -0.11426854133605957,
                -0.16306015849113464,
                -0.07924028486013412,
                -0.12830066680908203,
                -0.1411879062652588,
                -0.1465308964252472,
                -0.006721471901983023,
                -0.12295766919851303,
                -0.13273237645626068,
                -0.11748196929693222,
                -0.12008018791675568,
                -0.12202893197536469,
                -0.16809436678886414
            ],
            [
                -0.15561586618423462,
                -0.16906563937664032,
                -0.13694290816783905,
                -0.1696482002735138,
                -0.16557034850120544,
                -0.16888907551765442,
                -0.15402890741825104,
                -0.16737766563892365,
                -0.1616355925798416,
                -0.16867612302303314,
                -0.1649298220872879,
                -0.16594412922859192,
                -0.16701029241085052,
                -0.1636984646320343,
                -0.14241410791873932,
                -0.16922689974308014
            ]
        ],
        [
            [
                4.296636581420898,
                2.380197048187256,
                4.222677707672119,
                2.684330701828003,
                2.6346871852874756,
                3.225478172302246,
                4.996190547943115,
                3.484997510910034,
                4.5925750732421875,
                3.8182449340820312,
                4.451594829559326,
                6.138909339904785,
                2.051419496536255,
                4.246903896331787,
                4.561363220214844,
                4.244538307189941
            ],
            [
                2.544248342514038,
                8.725264549255371,
                5.551200866699219,
                5.975714683532715,
                3.986424446105957,
                4.233265399932861,
                3.324528932571411,
                3.6601343154907227,
                2.5930027961730957,
                2.5579469203948975,
                4.56664514541626,
                3.264660358428955,
                3.2198405265808105,
                4.060078144073486,
                5.641863822937012,
                5.1657514572143555
            ],
            [
                4.970448970794678,
                2.9440784454345703,
                5.024955749511719,
                1.511428713798523,
                3.475231647491455,
                2.5225119590759277,
                5.259144306182861,
                2.629603862762451,
                4.311513900756836,
                5.452452182769775,
                3.702472686767578,
                4.285547733306885,
                5.812535762786865,
                8.164382934570312,
                2.61818790435791,
                9.757621765136719
            ],
            [
                3.880920886993408,
                4.346668243408203,
                1.8920422792434692,
                3.5813684463500977,
                5.572045803070068,
                6.467373371124268,
                3.485886573791504,
                3.38508939743042,
                6.288278579711914,
                4.503782749176025,
                3.2455060482025146,
                4.098695278167725,
                5.755383491516113,
                2.4980688095092773,
                5.004443168640137,
                5.955487251281738
            ],
            [
                5.316920280456543,
                3.311772346496582,
                3.5144762992858887,
                1.8733444213867188,
                4.181496620178223,
                3.700068950653076,
                3.5117053985595703,
                6.183725833892822,
                3.9471113681793213,
                4.276691436767578,
                2.853450298309326,
                5.845641136169434,
                3.0174431800842285,
                5.1968278884887695,
                2.937530517578125,
                6.291743755340576
            ],
            [
                4.2779154777526855,
                4.451168537139893,
                4.562895774841309,
                1.181321620941162,
                5.149228096008301,
                2.0408823490142822,
                3.6783175468444824,
                4.127364158630371,
                3.7591490745544434,
                3.74196720123291,
                8.868546485900879,
                3.141556739807129,
                3.1247403621673584,
                5.124855995178223,
                5.530788421630859,
                4.455242156982422
            ],
            [
                1.9323902130126953,
                3.5589752197265625,
                1.8854169845581055,
                2.4443986415863037,
                3.612182140350342,
                2.8760406970977783,
                4.461885452270508,
                2.8060836791992188,
                5.681869029998779,
                1.702476978302002,
                5.373143672943115,
                6.193551063537598,
                6.331984043121338,
                4.606679439544678,
                0.07594884186983109,
                5.671553134918213
            ],
            [
                3.168949604034424,
                6.164339542388916,
                4.48197078704834,
                2.8594324588775635,
                2.0132737159729004,
                2.1615946292877197,
                3.7689998149871826,
                3.4102349281311035,
                3.7731058597564697,
                3.635455846786499,
                2.782242774963379,
                2.4310004711151123,
                4.276553153991699,
                5.233163356781006,
                0.3263973593711853,
                0.8605899214744568
            ],
            [
                -0.15955623984336853,
                -0.0723252072930336,
                -0.1683422476053238,
                -0.1689670979976654,
                -0.1517484039068222,
                -0.14915059506893158,
                -0.15219344198703766,
                -0.15777462720870972,
                -0.01032510120421648,
                -0.16690297424793243,
                -0.16117392480373383,
                -0.16933836042881012,
                -0.15719696879386902,
                -0.15977148711681366,
                -0.14425654709339142,
                -0.16528165340423584
            ],
            [
                -0.16578637063503265,
                -0.11850722134113312,
                -0.16407538950443268,
                -0.15941232442855835,
                -0.13489800691604614,
                -0.13289733231067657,
                -0.12150639295578003,
                -0.15313149988651276,
                -0.10452629625797272,
                -0.14508767426013947,
                -0.16127003729343414,
                -0.12158974260091782,
                -0.1340646594762802,
                -0.10560370236635208,
                -0.13645906746387482,
                -0.07053162157535553
            ],
            [
                -0.13775137066841125,
                -0.1612987071275711,
                -0.15699927508831024,
                -0.16306164860725403,
                -0.15216495096683502,
                -0.14940467476844788,
                -0.1683773398399353,
                -0.16267277300357819,
                -0.16601501405239105,
                -0.1641065627336502,
                -0.1386946141719818,
                -0.09225310385227203,
                -0.11600193381309509,
                -0.16165250539779663,
                -0.14271798729896545,
                -0.0803968608379364
            ],
            [
                -0.13146981596946716,
                -0.16100172698497772,
                -0.09191108494997025,
                -0.1650504320859909,
                -0.0716310441493988,
                -0.15163028240203857,
                -0.16072319447994232,
                -0.1471855640411377,
                -0.12501399219036102,
                -0.16348682343959808,
                -0.09587674587965012,
                -0.15356990694999695,
                -0.11564797163009644,
                -0.13958340883255005,
                -0.14894358813762665,
                -0.04648108780384064
            ],
            [
                -0.15826383233070374,
                -0.11367291957139969,
                -0.13399037718772888,
                -0.15008950233459473,
                -0.11336024105548859,
                -0.13881804049015045,
                -0.16477760672569275,
                -0.14911328256130219,
                -0.16097696125507355,
                -0.1103447824716568,
                -0.16992709040641785,
                -0.07192288339138031,
                -0.1687980592250824,
                -0.08242907375097275,
                -0.11658164113759995,
                0.02018561214208603
            ],
            [
                -0.1304246336221695,
                -0.16853992640972137,
                -0.16076429188251495,
                -0.09183322638273239,
                -0.16269351541996002,
                -0.14094223082065582,
                -0.16154687106609344,
                -0.0950208306312561,
                -0.11905237287282944,
                -0.11789587140083313,
                -0.12601439654827118,
                -0.15326820313930511,
                -0.16990305483341217,
                -0.14977382123470306,
                -0.15167194604873657,
                -0.1549987643957138
            ],
            [
                -0.16714052855968475,
                -0.15911400318145752,
                -0.16871915757656097,
                -0.1637028008699417,
                0.14691756665706635,
                -0.1612226963043213,
                -0.14622651040554047,
                -0.15619154274463654,
                -0.14790423214435577,
                -0.16551518440246582,
                -0.16279123723506927,
                -0.1557500660419464,
                -0.16561877727508545,
                -0.16965988278388977,
                -0.13759179413318634,
                -0.1698555052280426
            ],
            [
                -0.15253688395023346,
                -0.16824257373809814,
                -0.12187379598617554,
                -0.1622515618801117,
                -0.17002643644809723,
                -0.10094773769378662,
                -0.15598510205745697,
                -0.16929936408996582,
                -0.16106143593788147,
                -0.16962294280529022,
                -0.15006044507026672,
                -0.15456904470920563,
                -0.1683300882577896,
                -0.14793014526367188,
                -0.16994808614253998,
                0.014865529723465443
            ]
        ],
        [
            [
                4.484879493713379,
                4.1042160987854,
                3.4250380992889404,
                4.418054580688477,
                3.121896266937256,
                4.602386951446533,
                5.192808628082275,
                4.4817118644714355,
                4.078240871429443,
                4.379214286804199,
                4.310226917266846,
                4.562381267547607,
                4.769883632659912,
                5.47335147857666,
                3.4835286140441895,
                3.2534563541412354
            ],
            [
                5.705346584320068,
                5.039841175079346,
                5.34411096572876,
                5.517033100128174,
                4.596400737762451,
                3.808180093765259,
                4.695879936218262,
                5.461789608001709,
                6.02327823638916,
                5.318404674530029,
                4.450311660766602,
                5.715437889099121,
                4.931746006011963,
                5.181137561798096,
                5.549105644226074,
                3.272874116897583
            ],
            [
                5.124410629272461,
                4.649487495422363,
                4.7111663818359375,
                5.236669063568115,
                6.227902412414551,
                3.788292646408081,
                5.894190788269043,
                6.296838283538818,
                6.246397018432617,
                5.753464221954346,
                6.141283988952637,
                4.252761363983154,
                5.768542289733887,
                5.751607418060303,
                7.516269683837891,
                4.677122592926025
            ],
            [
                5.609685897827148,
                5.509740829467773,
                5.576147079467773,
                6.049774646759033,
                7.938645839691162,
                5.239200592041016,
                5.343532562255859,
                4.510175704956055,
                5.562067985534668,
                3.5151119232177734,
                4.601140022277832,
                6.019793510437012,
                6.190229415893555,
                5.155576229095459,
                5.533985137939453,
                4.660765171051025
            ],
            [
                4.308412075042725,
                4.918759822845459,
                4.563782215118408,
                5.1807098388671875,
                6.363508224487305,
                6.299652576446533,
                5.561851978302002,
                5.233442306518555,
                6.308618545532227,
                5.034608840942383,
                4.774845123291016,
                5.582059860229492,
                4.9290971755981445,
                5.407327175140381,
                4.002810955047607,
                5.51209831237793
            ],
            [
                5.726504802703857,
                5.127980709075928,
                5.186861038208008,
                5.125640392303467,
                4.641793727874756,
                4.462325572967529,
                5.1536970138549805,
                4.550848484039307,
                6.002442836761475,
                6.534482955932617,
                3.9884278774261475,
                2.5912885665893555,
                4.064529895782471,
                6.01682710647583,
                5.364546775817871,
                5.683197021484375
            ],
            [
                4.988353729248047,
                5.530786514282227,
                4.735539436340332,
                5.061923980712891,
                5.29841947555542,
                4.993146896362305,
                4.277773857116699,
                5.542781352996826,
                5.89727783203125,
                5.3186821937561035,
                5.520922660827637,
                5.864790439605713,
                4.243449687957764,
                5.546599388122559,
                6.702817916870117,
                5.833248615264893
            ],
            [
                4.823671340942383,
                6.1323394775390625,
                6.228837966918945,
                3.270282506942749,
                4.794275283813477,
                4.3075666427612305,
                4.40460205078125,
                4.684658050537109,
                3.7281546592712402,
                4.211982250213623,
                5.651596546173096,
                6.448635578155518,
                7.7598676681518555,
                5.225292205810547,
                3.2656047344207764,
                5.657886505126953
            ],
            [
                -0.16616716980934143,
                -0.16849982738494873,
                -0.16863861680030823,
                -0.1673237681388855,
                -0.16784825921058655,
                -0.1649107038974762,
                -0.16908971965312958,
                -0.16899120807647705,
                -0.16849736869335175,
                -0.168717622756958,
                -0.16345585882663727,
                -0.16237762570381165,
                -0.16534024477005005,
                -0.1690686196088791,
                -0.16927547752857208,
                -0.164444237947464
            ],
            [
                -0.16669481992721558,
                -0.16954673826694489,
                -0.16932228207588196,
                -0.16302210092544556,
                -0.16710394620895386,
                -0.1664726585149765,
                -0.16980917751789093,
                -0.16980011761188507,
                -0.16801095008850098,
                -0.16841895878314972,
                -0.16370326280593872,
                -0.16202761232852936,
                -0.16742095351219177,
                -0.16577447950839996,
                -0.16729776561260223,
                -0.15884104371070862
            ],
            [
                -0.16722548007965088,
                -0.16912096738815308,
                -0.16796371340751648,
                -0.16914206743240356,
                -0.1670093536376953,
                -0.16983264684677124,
                -0.16959933936595917,
                -0.16919709742069244,
                -0.1698402464389801,
                -0.16979862749576569,
                -0.1680515706539154,
                -0.16819079220294952,
                -0.16720063984394073,
                -0.16854213178157806,
                -0.16345272958278656,
                -0.16229483485221863
            ],
            [
                -0.1699942797422409,
                -0.16850583255290985,
                -0.1697929948568344,
                -0.1688910871744156,
                -0.16388879716396332,
                -0.16577288508415222,
                -0.1689029186964035,
                -0.16868451237678528,
                -0.16879527270793915,
                -0.1690082550048828,
                -0.16897080838680267,
                -0.16817568242549896,
                -0.16584016382694244,
                -0.16729451715946198,
                -0.1694556325674057,
                -0.1695460081100464
            ],
            [
                -0.16974788904190063,
                -0.16927145421504974,
                -0.16995647549629211,
                -0.16998207569122314,
                -0.17003898322582245,
                -0.16759636998176575,
                -0.16871377825737,
                -0.16736383736133575,
                -0.16720916330814362,
                -0.1695532649755478,
                -0.16986554861068726,
                -0.1639995574951172,
                -0.16835807263851166,
                -0.16682012379169464,
                -0.16842161118984222,
                -0.166621133685112
            ],
            [
                -0.16758455336093903,
                -0.16983503103256226,
                -0.16994799673557281,
                -0.1699172407388687,
                -0.16998621821403503,
                -0.1663723587989807,
                -0.168410986661911,
                -0.16469600796699524,
                -0.16863568127155304,
                -0.16766758263111115,
                -0.16626010835170746,
                -0.16077451407909393,
                -0.16479717195034027,
                -0.16482321918010712,
                -0.16466538608074188,
                -0.15658903121948242
            ],
            [
                -0.16566066443920135,
                -0.17003029584884644,
                -0.17000705003738403,
                -0.16622582077980042,
                -0.16888423264026642,
                -0.16673940420150757,
                -0.16760611534118652,
                -0.1675385683774948,
                -0.16958004236221313,
                -0.16647368669509888,
                -0.1671535074710846,
                -0.16207720339298248,
                -0.16529306769371033,
                -0.1651584655046463,
                -0.16224302351474762,
                -0.16052646934986115
            ],
            [
                -0.15906625986099243,
                -0.1623183786869049,
                -0.16381442546844482,
                -0.15884047746658325,
                -0.16341067850589752,
                -0.16494332253932953,
                -0.1615055948495865,
                -0.16228672862052917,
                -0.16439782083034515,
                -0.1590881198644638,
                -0.15923179686069489,
                -0.1636655330657959,
                -0.16391560435295105,
                -0.1618935465812683,
                -0.16220706701278687,
                -0.16342774033546448
            ]
        ],
        [
            [
                -0.10364502668380737,
                -0.0007282505976036191,
                0.37056949734687805,
                -0.10969693958759308,
                -0.16957315802574158,
                -0.07755862921476364,
                -0.038244787603616714,
                0.8350362181663513,
                0.5130404829978943,
                -0.09283136576414108,
                -0.16517749428749084,
                0.003039778210222721,
                -0.15761034190654755,
                -0.05796179175376892,
                -0.000004313648332754383,
                1.9100348949432373
            ],
            [
                -0.06665782630443573,
                -0.00012674593017436564,
                -0.000032927757274592295,
                -0.035495199263095856,
                -0.12662459909915924,
                -0.020200327038764954,
                -0.043501466512680054,
                -0.000302795204333961,
                -0.00006190670683281496,
                -0.13827630877494812,
                -0.14605183899402618,
                -0.13782258331775665,
                -0.13109274208545685,
                -0.14949993789196014,
                -0.014345456846058369,
                1.764909029006958
            ],
            [
                0.2630804777145386,
                -0.00027222649077884853,
                -0.14533643424510956,
                1.276393175125122,
                -0.005410205572843552,
                -0.000044646785681834444,
                -0.004484871402382851,
                0.026762329041957855,
                -0.16989116370677948,
                2.4111342430114746,
                1.1385037899017334,
                -0.1677490621805191,
                -0.002838339190930128,
                -0.0000010063146191896521,
                0,
                0.18684923648834229
            ],
            [
                -0.11792614310979843,
                0.1332487165927887,
                -0.021854927763342857,
                -0.00023807398974895477,
                -0.000007397337412839988,
                0,
                -0.16544587910175323,
                -0.013429486192762852,
                2.174785614013672,
                1.3806395530700684,
                -0.08747462183237076,
                -0.16593775153160095,
                -0.0000016865558336576214,
                -0.01879964768886566,
                -0.00027035322273150086,
                0.7673466205596924
            ],
            [
                -0.0006428344640880823,
                -0.0006133322021923959,
                -0.05972777307033539,
                -0.00030762405367568135,
                -0.00008843844989314675,
                -4.3659707671395154e-7,
                -0.00008374403114430606,
                -0.01074015162885189,
                -0.1575353592634201,
                0.4018116295337677,
                -0.11865321546792984,
                0.3472970426082611,
                -0.1586475521326065,
                -0.16980671882629395,
                -0.13133729994297028,
                0.348789244890213
            ],
            [
                0.6697777509689331,
                -0.009775891900062561,
                -0.06211168318986893,
                -0.1696866750717163,
                -0.1392228901386261,
                -0.013575714081525803,
                -0.07364175468683243,
                -0.16280239820480347,
                1.1958421468734741,
                -0.12740354239940643,
                -0.061707623302936554,
                0,
                -0.00926596112549305,
                0,
                -0.0013842136831954122,
                0.1034335196018219
            ],
            [
                -0.15850873291492462,
                -0.03657711669802666,
                -0.0008208746439777315,
                -0.02307065762579441,
                -0.12855109572410583,
                -0.1330132633447647,
                -0.02299072965979576,
                0.3437168598175049,
                -0.12253899872303009,
                -0.0038882119115442038,
                -0.000004978054676030297,
                -0.000025073928554775193,
                -0.16820715367794037,
                -0.00003068218575208448,
                -0.00019347193301655352,
                -0.02427191659808159
            ],
            [
                0.3815999925136566,
                -0.007654313463717699,
                -0.00011490864562802017,
                0,
                0.7672960162162781,
                0.10174515098333359,
                -0.039034295827150345,
                0.09582959860563278,
                -0.09579282253980637,
                0.3044223189353943,
                -0.03186943382024765,
                0.39930301904678345,
                -0.014901305548846722,
                0,
                -2.7408870550971187e-7,
                0.2718740999698639
            ],
            [
                -0.131146639585495,
                -0.15743900835514069,
                -0.12477772682905197,
                -0.1619662344455719,
                -0.12045065313577652,
                -0.16860274970531464,
                -0.1700279265642166,
                -0.16896627843379974,
                -0.17004072666168213,
                -0.16941216588020325,
                -0.16014140844345093,
                -0.16915211081504822,
                -0.1569560319185257,
                -0.153737872838974,
                -0.17001092433929443,
                -0.1408534198999405
            ],
            [
                -0.13085922598838806,
                -0.16970404982566833,
                -0.1698443591594696,
                -0.16983678936958313,
                -0.16418805718421936,
                -0.15842974185943604,
                -0.153225377202034,
                -0.14227284491062164,
                -0.16807149350643158,
                -0.15453900396823883,
                -0.16176347434520721,
                -0.16394905745983124,
                -0.14114180207252502,
                -0.16230154037475586,
                -0.15266314148902893,
                -0.15698671340942383
            ],
            [
                -0.05081327259540558,
                -0.16930630803108215,
                -0.16995854675769806,
                -0.16984431445598602,
                -0.15742062032222748,
                -0.16971756517887115,
                -0.15606020390987396,
                -0.16672708094120026,
                -0.14582349359989166,
                -0.16633211076259613,
                -0.15706762671470642,
                -0.16860900819301605,
                -0.15914784371852875,
                -0.17003783583641052,
                -0.16864269971847534,
                -0.1683550328016281
            ],
            [
                -0.14673347771167755,
                -0.15536381304264069,
                -0.16889317333698273,
                -0.1700362265110016,
                -0.16455738246440887,
                -0.1665564775466919,
                -0.16728739440441132,
                -0.15774278342723846,
                -0.15577353537082672,
                -0.16201123595237732,
                -0.16513323783874512,
                -0.16304801404476166,
                -0.17000052332878113,
                -0.1662053018808365,
                -0.12112704664468765,
                -0.10617954283952713
            ],
            [
                -0.15050794184207916,
                -0.16969497501850128,
                -0.1647905558347702,
                -0.1686021089553833,
                -0.16910682618618011,
                -0.1677136868238449,
                -0.1531275361776352,
                -0.1699669063091278,
                -0.16419829428195953,
                -0.15603752434253693,
                -0.1698487401008606,
                -0.14628808200359344,
                -0.1699390560388565,
                -0.16128118336200714,
                -0.06785997748374939,
                -0.10834414511919022
            ],
            [
                -0.14270362257957458,
                -0.16905130445957184,
                -0.1679435819387436,
                -0.15419140458106995,
                -0.1505095660686493,
                -0.16935186088085175,
                -0.15609712898731232,
                -0.14723193645477295,
                -0.1536453813314438,
                -0.10215377807617188,
                -0.1423684060573578,
                -0.1502842754125595,
                -0.16279146075248718,
                -0.16956156492233276,
                -0.1684771329164505,
                -0.16761276125907898
            ],
            [
                -0.15746478736400604,
                -0.16920135915279388,
                -0.16696305572986603,
                -0.15220095217227936,
                -0.12596510350704193,
                -0.1691698580980301,
                -0.1676604300737381,
                -0.14805978536605835,
                -0.1611010730266571,
                -0.17004016041755676,
                -0.16627028584480286,
                -0.16237643361091614,
                -0.16649426519870758,
                -0.16346414387226105,
                -0.13914655148983002,
                -0.1643863171339035
            ],
            [
                -0.14694954454898834,
                -0.16695643961429596,
                -0.16989362239837646,
                -0.16360381245613098,
                -0.17004059255123138,
                -0.16451960802078247,
                -0.16615797579288483,
                -0.1698966771364212,
                -0.16587047278881073,
                -0.16986355185508728,
                -0.1614280790090561,
                -0.1654895842075348,
                -0.16839677095413208,
                -0.1700291931629181,
                -0.16377483308315277,
                -0.16441141068935394
            ]
        ],
        [
            [
                3.0249593257904053,
                2.7373218536376953,
                2.5580615997314453,
                2.8374407291412354,
                3.4402201175689697,
                4.543308258056641,
                4.072225570678711,
                1.9743834733963013,
                2.745237112045288,
                3.339879035949707,
                1.357825517654419,
                2.361680269241333,
                2.1230318546295166,
                3.001648187637329,
                4.4224748611450195,
                4.4282660484313965
            ],
            [
                2.303903102874756,
                3.9182326793670654,
                1.7457836866378784,
                2.4552500247955322,
                1.4272030591964722,
                7.4301934242248535,
                4.422476291656494,
                3.171478033065796,
                4.998790264129639,
                1.5597790479660034,
                2.647028923034668,
                3.7629687786102295,
                2.1877710819244385,
                5.38200569152832,
                5.447446823120117,
                4.696310520172119
            ],
            [
                0.46602439880371094,
                4.953266620635986,
                2.534680128097534,
                3.4357945919036865,
                1.8566339015960693,
                7.013497352600098,
                5.898708343505859,
                2.2348804473876953,
                3.173417091369629,
                1.6717848777770996,
                1.9327079057693481,
                2.602804183959961,
                4.10022497177124,
                5.083184719085693,
                4.757502555847168,
                6.777173042297363
            ],
            [
                5.118133544921875,
                2.650786876678467,
                2.4877631664276123,
                1.8392926454544067,
                4.453402996063232,
                6.034097671508789,
                4.455145359039307,
                2.681851387023926,
                2.072528839111328,
                3.346019983291626,
                3.217702627182007,
                2.5514872074127197,
                3.8395299911499023,
                8.804980278015137,
                9.175326347351074,
                1.9934537410736084
            ],
            [
                4.867690563201904,
                8.16195297241211,
                2.7586441040039062,
                1.8864167928695679,
                2.249782085418701,
                4.339685440063477,
                3.6083462238311768,
                5.372469425201416,
                2.842512369155884,
                3.582458019256592,
                1.4337313175201416,
                1.6729189157485962,
                1.446229338645935,
                2.7926838397979736,
                2.087559938430786,
                1.3554621934890747
            ],
            [
                2.675554037094116,
                1.7507643699645996,
                4.325084686279297,
                3.1438302993774414,
                2.305933713912964,
                3.768890619277954,
                2.77189302444458,
                3.104646921157837,
                2.051039934158325,
                2.816232919692993,
                4.901998043060303,
                null,
                3.7112984657287598,
                1.6533145904541016,
                3.76186203956604,
                4.131416320800781
            ],
            [
                2.3758606910705566,
                2.405331611633301,
                5.493658542633057,
                4.138186454772949,
                3.2094335556030273,
                4.630543231964111,
                4.640244007110596,
                1.6771453619003296,
                3.2638447284698486,
                4.694659233093262,
                4.669260025024414,
                2.5732080936431885,
                2.1042823791503906,
                10.515182495117188,
                9.701554298400879,
                7.22848653793335
            ],
            [
                2.8901336193084717,
                3.920631170272827,
                5.1685872077941895,
                5.830621242523193,
                0.24564683437347412,
                3.2915666103363037,
                7.200198650360107,
                2.253617525100708,
                4.365637302398682,
                3.7517786026000977,
                2.731877326965332,
                2.0689029693603516,
                3.48779296875,
                10.969520568847656,
                12.708518981933594,
                1.9591199159622192
            ],
            [
                0.44202250242233276,
                -0.06524445861577988,
                0.17224957048892975,
                0.2509959042072296,
                0.0605810321867466,
                0.34222060441970825,
                0.3276103138923645,
                0.24546073377132416,
                0.16751983761787415,
                0.3318050503730774,
                0.23477712273597717,
                0.10842261463403702,
                0.15878255665302277,
                -0.11703124642372131,
                0.18492136895656586,
                0.4613902270793915
            ],
            [
                0.34111565351486206,
                0.00036163610639050603,
                0.04969256371259689,
                0.042703475803136826,
                0.14946642518043518,
                0.05116233602166176,
                -0.08219678699970245,
                -0.10872022807598114,
                0.07156315445899963,
                -0.05769552290439606,
                0.2016446590423584,
                0.21717455983161926,
                0.13994719088077545,
                0.006608966737985611,
                0.045512884855270386,
                0.31814852356910706
            ],
            [
                0.34067121148109436,
                0.0267461109906435,
                0.04603563994169235,
                -0.04309116303920746,
                0.17376750707626343,
                0.1472943127155304,
                -0.012797964736819267,
                0.02617504447698593,
                0.1387506127357483,
                -0.002535062376409769,
                0.11281102150678635,
                0.2771402597427368,
                0.2560625970363617,
                0.24288442730903625,
                0.2532619833946228,
                0.1073029488325119
            ],
            [
                0.23553648591041565,
                -0.09459511190652847,
                -0.002671437803655863,
                -0.046998899430036545,
                0.0738767758011818,
                0.1067667156457901,
                0.09510575979948044,
                0.1047036275267601,
                0.1435203105211258,
                -0.0605916790664196,
                -0.011067594401538372,
                0.3019550144672394,
                0.18014563620090485,
                0.20963329076766968,
                0.015369034372270107,
                0.03997607156634331
            ],
            [
                0.2895543575286865,
                0.048477787524461746,
                0.06738219410181046,
                -0.11777790635824203,
                -0.12361542880535126,
                0.10326233506202698,
                0.04279933124780655,
                0.11949167400598526,
                0.04614150524139404,
                -0.0761503055691719,
                -0.0004886297974735498,
                0.7233709096908569,
                0.4247385561466217,
                0.17015992105007172,
                -0.028416406363248825,
                0.14749063551425934
            ],
            [
                0.20196141302585602,
                -0.125869020819664,
                -0.012658532708883286,
                0.15609210729599,
                -0.08648855239152908,
                0.1304524689912796,
                0.05576368793845177,
                0.19433127343654633,
                -0.04167482629418373,
                0.23370185494422913,
                0.4464664161205292,
                0.08910947293043137,
                0.2551356554031372,
                0.3168334662914276,
                0.2492786943912506,
                0.22819553315639496
            ],
            [
                0.16381657123565674,
                -0.07317084074020386,
                0.22724506258964539,
                0.39267006516456604,
                0.038838695734739304,
                0.07992959767580032,
                0.37782782316207886,
                0.08076400309801102,
                0.12660028040409088,
                0.1576298624277115,
                0.2971344590187073,
                -0.0303266029804945,
                -0.04445436969399452,
                0.2005241960287094,
                0.23929446935653687,
                0.0439801849424839
            ],
            [
                0.47438836097717285,
                0.13824045658111572,
                0.3034897744655609,
                0.38347262144088745,
                0.3320066034793854,
                0.12390495091676712,
                0.28564971685409546,
                0.5649732351303101,
                0.3571079969406128,
                0.26964718103408813,
                0.4200916588306427,
                0.09002050757408142,
                0.18562990427017212,
                0.1355566829442978,
                0.044747788459062576,
                0.020465724170207977
            ]
        ],
        [
            [
                4.127816677093506,
                5.3634114265441895,
                8.508163452148438,
                10.114336013793945,
                4.965865612030029,
                5.925052642822266,
                6.401883125305176,
                8.003520965576172,
                5.955583572387695,
                5.166672229766846,
                5.280587196350098,
                5.917876243591309,
                6.030343055725098,
                4.960914611816406,
                5.410239219665527,
                4.430941581726074
            ],
            [
                5.736513614654541,
                4.023049831390381,
                4.699368000030518,
                7.232580661773682,
                6.738927841186523,
                2.96713924407959,
                3.8499913215637207,
                10.262008666992188,
                6.919630527496338,
                5.197283744812012,
                4.52606725692749,
                3.2937653064727783,
                5.124119758605957,
                4.166651725769043,
                4.576652526855469,
                7.860600471496582
            ],
            [
                8.45891284942627,
                3.6527438163757324,
                4.8640642166137695,
                0.7690774202346802,
                4.023794174194336,
                6.627493858337402,
                4.142309665679932,
                3.321470260620117,
                5.845850944519043,
                5.329564094543457,
                4.479874610900879,
                6.2401814460754395,
                2.934619426727295,
                5.737192153930664,
                12.088432312011719,
                5.562736511230469
            ],
            [
                5.050740718841553,
                6.5795111656188965,
                4.423610210418701,
                4.756950378417969,
                3.196873664855957,
                8.322070121765137,
                4.054283618927002,
                8.167304992675781,
                7.452151775360107,
                6.340465068817139,
                6.652690887451172,
                7.359865188598633,
                5.495924472808838,
                2.8489222526550293,
                5.320239543914795,
                4.202054977416992
            ],
            [
                4.5752177238464355,
                4.95304012298584,
                5.8140034675598145,
                6.476972579956055,
                5.487360000610352,
                8.020706176757812,
                6.375221252441406,
                4.997148513793945,
                4.347250461578369,
                4.349229335784912,
                5.820680141448975,
                4.308707237243652,
                5.967175483703613,
                6.375227928161621,
                3.6914494037628174,
                5.308969020843506
            ],
            [
                6.731307029724121,
                5.0277204513549805,
                3.7088496685028076,
                5.610537052154541,
                3.483738899230957,
                1.4694892168045044,
                7.291632652282715,
                4.79263162612915,
                7.218677997589111,
                5.122297286987305,
                6.936062812805176,
                5.246236801147461,
                5.478108882904053,
                2.9391660690307617,
                7.8227362632751465,
                7.008756637573242
            ],
            [
                5.848299503326416,
                6.874514102935791,
                2.9452455043792725,
                4.651937007904053,
                4.517337799072266,
                5.934892654418945,
                5.951725482940674,
                3.854121685028076,
                3.079172372817993,
                5.486063003540039,
                6.305285453796387,
                6.141107559204102,
                7.108497142791748,
                10.0359525680542,
                4.82513952255249,
                4.629183769226074
            ],
            [
                6.478193759918213,
                3.0649476051330566,
                7.980274677276611,
                9.29539680480957,
                3.614752769470215,
                5.998654365539551,
                4.611434459686279,
                3.8475828170776367,
                4.3428144454956055,
                9.7117919921875,
                3.975238800048828,
                4.952481269836426,
                6.479029178619385,
                5.724353790283203,
                10.284692764282227,
                5.200581073760986
            ],
            [
                0.2544911801815033,
                0.31252363324165344,
                0.2118561863899231,
                0.13805118203163147,
                0.43069934844970703,
                0.05907227843999863,
                0.009561571292579174,
                0.07101882249116898,
                0.07486674189567566,
                0.12361733615398407,
                0.26975956559181213,
                -0.032265547662973404,
                0.23346611857414246,
                0.3786434531211853,
                0.29993027448654175,
                -0.054482340812683105
            ],
            [
                0.05689544230699539,
                0.32034775614738464,
                0.4027622938156128,
                0.23794874548912048,
                0.13358141481876373,
                0.3049010634422302,
                -0.05672597885131836,
                0.6989148259162903,
                0.22810383141040802,
                0.07970408350229263,
                0.36100485920906067,
                -0.09628061950206757,
                0.017160797491669655,
                0.35828301310539246,
                0.24100135266780853,
                0.1181463822722435
            ],
            [
                0.19826947152614594,
                0.5239177942276001,
                -0.08126325160264969,
                0.18899467587471008,
                0.6218851208686829,
                0.1848604679107666,
                0.13609915971755981,
                0.4400921165943146,
                0.013993321917951107,
                0.17911826074123383,
                0.5203932523727417,
                -0.007450331002473831,
                -0.11120124906301498,
                0.08408288657665253,
                0.2414737343788147,
                0.3197920620441437
            ],
            [
                0.15714044868946075,
                0.49473753571510315,
                0.10778899490833282,
                0.40499791502952576,
                0.1630486398935318,
                0.22306892275810242,
                0.5553627610206604,
                0.4012521505355835,
                -0.017150266095995903,
                0.026693427935242653,
                0.5428226590156555,
                0.3974384367465973,
                -0.11225937306880951,
                0.013548585586249828,
                0.27718257904052734,
                0.26925668120384216
            ],
            [
                0.1065635085105896,
                0.19075649976730347,
                0.16769495606422424,
                0.6274666786193848,
                1.1194552183151245,
                -0.008611309342086315,
                0.1542234867811203,
                0.2867232859134674,
                0.4000253975391388,
                0.34074264764785767,
                -0.02990471012890339,
                0.273167222738266,
                0.03756837919354439,
                0.011085930280387402,
                0.19673199951648712,
                0.19484513998031616
            ],
            [
                0.14735588431358337,
                0.30714449286460876,
                0.5721551179885864,
                0.06927348673343658,
                0.34322959184646606,
                0.09055370837450027,
                0.14786890149116516,
                0.1024346649646759,
                0.028019186109304428,
                -0.04502880573272705,
                0.15953072905540466,
                0.04095271974802017,
                -0.018181269988417625,
                0.3280654549598694,
                0.04370484873652458,
                0.5362021327018738
            ],
            [
                0.15511269867420197,
                0.23598188161849976,
                0.45755499601364136,
                0.1399705410003662,
                0.39117860794067383,
                0.05599672719836235,
                -0.10005318373441696,
                0.010333558544516563,
                0.2470940798521042,
                0.1767413169145584,
                -0.0009871915681287646,
                -0.014164002612233162,
                0.1081608235836029,
                0.2437734752893448,
                -0.12437983602285385,
                -0.006354654673486948
            ],
            [
                0.006623920984566212,
                0.007372155319899321,
                0.12384437769651413,
                -0.08548290282487869,
                0.7613038420677185,
                0.26971083879470825,
                0.5370118021965027,
                0.13550417125225067,
                0.13117724657058716,
                0.45839032530784607,
                -0.1556655615568161,
                0.005988443270325661,
                0.13311803340911865,
                0.6023197174072266,
                0.6024109721183777,
                0.20243367552757263
            ]
        ],
        [
            [
                1.8130252361297607,
                1.34126615524292,
                2.2057440280914307,
                1.231643557548523,
                1.4058200120925903,
                1.2163007259368896,
                1.4849478006362915,
                1.0901753902435303,
                1.3642404079437256,
                1.5600533485412598,
                1.103960394859314,
                1.307551622390747,
                0.97490394115448,
                1.2944753170013428,
                0.7247891426086426,
                0.7273802757263184
            ],
            [
                1.38759183883667,
                3.3692574501037598,
                3.1584179401397705,
                4.1289143562316895,
                3.6147685050964355,
                2.0337159633636475,
                2.4748330116271973,
                1.6119953393936157,
                1.2757245302200317,
                2.3728208541870117,
                3.094724416732788,
                3.669231414794922,
                2.123246908187866,
                2.4484810829162598,
                1.3258211612701416,
                2.7448067665100098
            ],
            [
                1.1277316808700562,
                2.1943366527557373,
                2.5246822834014893,
                1.7220665216445923,
                2.5366404056549072,
                0.8339575529098511,
                3.1515800952911377,
                2.384385824203491,
                2.679499626159668,
                3.0157089233398438,
                3.0391507148742676,
                2.27799654006958,
                2.3329553604125977,
                1.4287482500076294,
                0.7469673156738281,
                1.2062513828277588
            ],
            [
                2.403036117553711,
                2.684309720993042,
                1.8776789903640747,
                0.9546312689781189,
                1.2064586877822876,
                1.0737884044647217,
                1.8356722593307495,
                0.4940062463283539,
                2.558518886566162,
                2.605009078979492,
                2.036367893218994,
                2.6246931552886963,
                1.7979521751403809,
                2.5268170833587646,
                1.7938212156295776,
                2.4578664302825928
            ],
            [
                1.3228131532669067,
                1.8766552209854126,
                3.0926976203918457,
                2.2238996028900146,
                2.086120843887329,
                2.7249460220336914,
                2.587411403656006,
                2.6133203506469727,
                2.8478431701660156,
                3.3605360984802246,
                2.3474133014678955,
                2.5170249938964844,
                3.089033842086792,
                2.119743824005127,
                1.894978642463684,
                1.7541104555130005
            ],
            [
                1.2244317531585693,
                2.4164021015167236,
                2.0837481021881104,
                2.093513011932373,
                3.4269065856933594,
                2.236623764038086,
                2.7073659896850586,
                2.0071628093719482,
                2.7395150661468506,
                2.2355780601501465,
                1.9384000301361084,
                2.3265182971954346,
                1.241465449333191,
                1.2856239080429077,
                2.646761894226074,
                1.4224574565887451
            ],
            [
                1.7787408828735352,
                1.3563857078552246,
                1.7851290702819824,
                3.2779746055603027,
                3.36647629737854,
                1.8832913637161255,
                2.207831621170044,
                2.5319323539733887,
                1.9240639209747314,
                1.6091110706329346,
                2.025148868560791,
                2.4619030952453613,
                3.216968297958374,
                1.57764732837677,
                2.713984489440918,
                1.4141803979873657
            ],
            [
                2.1376678943634033,
                2.734509229660034,
                1.8197444677352905,
                1.5211224555969238,
                3.367924213409424,
                1.8067600727081299,
                1.7084887027740479,
                2.763334274291992,
                1.8197060823440552,
                3.2127387523651123,
                3.00460147857666,
                2.0690603256225586,
                1.6570188999176025,
                0.6176275610923767,
                -0.11432880163192749,
                1.1935936212539673
            ],
            [
                -0.14155510067939758,
                -0.11518635600805283,
                -0.04663349688053131,
                -0.1041797623038292,
                0.10892953723669052,
                -0.14631059765815735,
                -0.10813139379024506,
                -0.14835867285728455,
                -0.16998104751110077,
                -0.1283608376979828,
                -0.12191080302000046,
                -0.14874717593193054,
                -0.1343977153301239,
                -0.05123518034815788,
                -0.112991102039814,
                -0.16746748983860016
            ],
            [
                -0.13440664112567902,
                -0.09783295542001724,
                -0.1248236745595932,
                -0.09616824984550476,
                -0.16218659281730652,
                -0.1639564037322998,
                -0.12140689790248871,
                -0.0841434895992279,
                -0.08053728193044662,
                -0.16430062055587769,
                -0.14313547313213348,
                0.029229341074824333,
                -0.14915205538272858,
                -0.14396131038665771,
                -0.12048881500959396,
                -0.13497522473335266
            ],
            [
                -0.06973623484373093,
                -0.13654351234436035,
                -0.15970413386821747,
                -0.1570848673582077,
                -0.16634932160377502,
                -0.16495375335216522,
                -0.1600499153137207,
                -0.16812148690223694,
                -0.16914287209510803,
                -0.12072907388210297,
                -0.10811149328947067,
                -0.08944954723119736,
                -0.15324755012989044,
                -0.08438344299793243,
                -0.16278602182865143,
                -0.15831424295902252
            ],
            [
                -0.1540713608264923,
                -0.16612602770328522,
                -0.11474340409040451,
                -0.16833576560020447,
                -0.15951135754585266,
                -0.10105715692043304,
                -0.14131873846054077,
                -0.1423799842596054,
                -0.1470654010772705,
                -0.15597350895404816,
                -0.16361959278583527,
                -0.16061656177043915,
                -0.15641656517982483,
                -0.14335449039936066,
                -0.12160354852676392,
                -0.16773134469985962
            ],
            [
                -0.15857075154781342,
                -0.07500419020652771,
                -0.1422426849603653,
                -0.10288576781749725,
                -0.16723208129405975,
                -0.14531292021274567,
                -0.16813287138938904,
                -0.16928549110889435,
                -0.13992264866828918,
                -0.05124368891119957,
                -0.14681516587734222,
                -0.1514258235692978,
                -0.15281051397323608,
                -0.16204872727394104,
                -0.16146788001060486,
                -0.16980348527431488
            ],
            [
                -0.08621345460414886,
                -0.15293045341968536,
                -0.16273222863674164,
                -0.1373092532157898,
                -0.15028119087219238,
                -0.11665908247232437,
                -0.14471416175365448,
                -0.169883593916893,
                -0.15266448259353638,
                -0.10870718210935593,
                -0.09206949919462204,
                -0.16974441707134247,
                -0.11516071856021881,
                -0.1486307829618454,
                -0.10497484356164932,
                -0.15354691445827484
            ],
            [
                -0.14851225912570953,
                -0.1328580677509308,
                -0.16126197576522827,
                -0.14790788292884827,
                0.06672962009906769,
                -0.13134941458702087,
                -0.15077903866767883,
                -0.145441472530365,
                -0.16785851120948792,
                -0.16897296905517578,
                -0.16968846321105957,
                -0.12579680979251862,
                -0.12099290639162064,
                -0.15635022521018982,
                -0.14202402532100677,
                -0.12658198177814484
            ],
            [
                -0.1663133054971695,
                -0.16915783286094666,
                -0.16125346720218658,
                -0.14598451554775238,
                -0.16724561154842377,
                -0.1676105111837387,
                -0.16978634893894196,
                -0.17002473771572113,
                -0.15941639244556427,
                -0.14216630160808563,
                -0.1589631736278534,
                -0.17002595961093903,
                -0.16509899497032166,
                -0.14270097017288208,
                -0.1684330701828003,
                -0.16849640011787415
            ]
        ],
        [
            [
                6.183687686920166,
                5.401137828826904,
                4.152982234954834,
                4.238164901733398,
                5.870161533355713,
                4.983263969421387,
                4.661635398864746,
                4.047707557678223,
                5.967399597167969,
                4.581395626068115,
                3.9868457317352295,
                3.5262486934661865,
                4.5360002517700195,
                4.474390983581543,
                6.025169372558594,
                6.602623462677002
            ],
            [
                3.158364772796631,
                6.04099702835083,
                3.993692398071289,
                3.5901291370391846,
                4.942135334014893,
                6.345766067504883,
                6.46255350112915,
                5.233597755432129,
                4.747104167938232,
                5.135138511657715,
                2.9973092079162598,
                4.696202754974365,
                3.157006025314331,
                6.138826370239258,
                4.3908281326293945,
                5.892099857330322
            ],
            [
                3.7387306690216064,
                6.054175853729248,
                6.278081893920898,
                4.100265026092529,
                4.228967666625977,
                5.180952072143555,
                7.745237827301025,
                5.130575180053711,
                2.9635767936706543,
                3.35333514213562,
                4.018806457519531,
                3.825458526611328,
                3.752413749694824,
                8.016608238220215,
                7.851363182067871,
                5.627194404602051
            ],
            [
                8.60350227355957,
                6.102555274963379,
                3.821659564971924,
                3.1721231937408447,
                3.7306690216064453,
                5.326937675476074,
                4.870336532592773,
                3.722886323928833,
                3.1146891117095947,
                1.4817514419555664,
                3.9099085330963135,
                4.987010478973389,
                2.5197651386260986,
                7.869048118591309,
                10.00986099243164,
                4.2695136070251465
            ],
            [
                7.992435455322266,
                7.68180513381958,
                2.9581298828125,
                2.5729730129241943,
                5.237252235412598,
                4.894321441650391,
                6.075435161590576,
                7.056037902832031,
                7.045598983764648,
                2.026880979537964,
                3.618447780609131,
                4.822155952453613,
                3.509073257446289,
                2.0201187133789062,
                4.144477367401123,
                4.829428672790527
            ],
            [
                4.504342079162598,
                4.013372898101807,
                6.091526508331299,
                3.9610726833343506,
                3.821455240249634,
                7.315450668334961,
                3.078015089035034,
                5.137997627258301,
                5.033131122589111,
                3.9789748191833496,
                4.749767303466797,
                9.235020637512207,
                5.672401428222656,
                5.408975124359131,
                3.635615587234497,
                4.116513252258301
            ],
            [
                4.943724155426025,
                4.124320030212402,
                7.0607686042785645,
                5.668130874633789,
                3.5772855281829834,
                6.737512111663818,
                7.179841995239258,
                5.440277576446533,
                6.009904861450195,
                4.075634956359863,
                4.414973735809326,
                5.219869613647461,
                3.956015110015869,
                8.979188919067383,
                10.193778038024902,
                7.617169380187988
            ],
            [
                4.4860382080078125,
                5.925520896911621,
                7.947667121887207,
                7.618854999542236,
                4.391556262969971,
                6.327366828918457,
                8.300803184509277,
                4.902584552764893,
                8.068056106567383,
                6.81684684753418,
                5.7006988525390625,
                4.735767364501953,
                4.2736124992370605,
                11.93826675415039,
                8.883460998535156,
                3.5065879821777344
            ],
            [
                -0.1383245289325714,
                -0.1692401021718979,
                -0.15163245797157288,
                -0.14901265501976013,
                -0.15786513686180115,
                -0.12631039321422577,
                -0.11869828402996063,
                -0.1578092724084854,
                -0.1695345789194107,
                -0.16557462513446808,
                -0.13477416336536407,
                -0.12836994230747223,
                -0.13294847309589386,
                -0.15134218335151672,
                -0.14736729860305786,
                -0.1387684941291809
            ],
            [
                -0.15961992740631104,
                -0.16745635867118835,
                -0.17003169655799866,
                -0.15783976018428802,
                -0.16448010504245758,
                -0.1431620568037033,
                -0.16742493212223053,
                -0.1676802933216095,
                -0.12443125247955322,
                -0.15987296402454376,
                -0.15803398191928864,
                -0.11751701682806015,
                -0.13697519898414612,
                -0.14108267426490784,
                -0.14138561487197876,
                -0.1591431051492691
            ],
            [
                -0.14046616852283478,
                -0.1582447737455368,
                -0.15911157429218292,
                -0.16511188447475433,
                -0.17003589868545532,
                -0.16278710961341858,
                -0.1532287448644638,
                -0.15889286994934082,
                -0.16374370455741882,
                -0.16854320466518402,
                -0.16992782056331635,
                -0.14449149370193481,
                -0.11592980474233627,
                -0.1593354195356369,
                -0.1393098533153534,
                -0.16936422884464264
            ],
            [
                -0.1626146137714386,
                -0.16151471436023712,
                -0.16015617549419403,
                -0.16529588401317596,
                -0.15651024878025055,
                -0.16968823969364166,
                -0.16835564374923706,
                -0.11141105741262436,
                -0.1653909683227539,
                -0.1686747968196869,
                -0.15878444910049438,
                -0.14063653349876404,
                -0.1468932330608368,
                -0.14648699760437012,
                -0.15892666578292847,
                -0.1647610068321228
            ],
            [
                -0.1608862429857254,
                -0.16993390023708344,
                -0.15615494549274445,
                -0.1662592738866806,
                -0.16610082983970642,
                -0.14439667761325836,
                -0.15338167548179626,
                -0.13000965118408203,
                -0.15613605082035065,
                -0.13755947351455688,
                -0.16572347283363342,
                -0.15299606323242188,
                -0.11316944658756256,
                -0.1558159440755844,
                -0.15788699686527252,
                -0.1699659526348114
            ],
            [
                -0.14302773773670197,
                -0.15324045717716217,
                -0.1636222004890442,
                -0.16611497104167938,
                -0.1491214632987976,
                -0.1595192551612854,
                -0.13263662159442902,
                -0.13023582100868225,
                -0.15561822056770325,
                -0.1434570699930191,
                -0.13222761452198029,
                -0.13741055130958557,
                -0.12141469866037369,
                -0.14251737296581268,
                -0.16659477353096008,
                -0.15659670531749725
            ],
            [
                -0.14520440995693207,
                -0.1669008582830429,
                -0.1639152467250824,
                -0.13503344357013702,
                -0.16995084285736084,
                -0.16611231863498688,
                -0.12708736956119537,
                -0.1317501962184906,
                -0.13172948360443115,
                -0.10384918004274368,
                -0.13010801374912262,
                -0.14558741450309753,
                -0.1651022732257843,
                -0.1272076666355133,
                -0.17003768682479858,
                -0.1593274176120758
            ],
            [
                -0.14649972319602966,
                -0.11661222577095032,
                -0.13283076882362366,
                -0.1445091813802719,
                -0.148826465010643,
                -0.14286626875400543,
                -0.13348428905010223,
                -0.12027402967214584,
                -0.14034950733184814,
                -0.14133162796497345,
                -0.12579673528671265,
                -0.14103175699710846,
                -0.13224618136882782,
                -0.15532217919826508,
                -0.1597662717103958,
                -0.15875773131847382
            ]
        ],
        [
            [
                -0.09215416014194489,
                -0.13532675802707672,
                -0.1473981738090515,
                -0.13572660088539124,
                -0.16869421303272247,
                0.1167478859424591,
                0.06151100620627403,
                -0.15744861960411072,
                -0.1679094433784485,
                -0.1260228008031845,
                -0.10668295621871948,
                0.02481159009039402,
                -0.12974922358989716,
                -0.07292693108320236,
                -0.0736248791217804,
                -0.10054650157690048
            ],
            [
                0.30532458424568176,
                1.51738703250885,
                -0.012086988426744938,
                0.07101645320653915,
                0.45210012793540955,
                -0.13676530122756958,
                -0.16033057868480682,
                0.2981245815753937,
                -0.09492944180965424,
                -0.11729990690946579,
                -0.03634240850806236,
                0.13308806717395782,
                0.07000723481178284,
                0.5789985060691833,
                -0.09793756902217865,
                -0.12941814959049225
            ],
            [
                -0.16778995096683502,
                -0.1676502674818039,
                0.3887791335582733,
                -0.05919039994478226,
                1.046677589416504,
                -0.16140364110469818,
                -0.045788779854774475,
                -0.16810621321201324,
                0.8071187138557434,
                0.9427299499511719,
                -0.16950678825378418,
                0.12933816015720367,
                -0.12442141771316528,
                -0.1604219526052475,
                -0.01272252295166254,
                -0.14301960170269012
            ],
            [
                -0.0816802829504013,
                -0.159385085105896,
                -0.05845502391457558,
                0.8777015805244446,
                0.4578043818473816,
                -0.00040284692659042776,
                -0.04651021584868431,
                -0.04409037157893181,
                0.8949045538902283,
                -0.15599974989891052,
                -0.05063271149992943,
                -0.0947408676147461,
                -0.05098077654838562,
                -0.16136254370212555,
                -0.10836191475391388,
                -0.15107235312461853
            ],
            [
                -0.04980853572487831,
                -0.07367942482233047,
                0.1429506540298462,
                -0.08204473555088043,
                -0.09234114736318588,
                2.1186487674713135,
                -0.1466914415359497,
                0.2541373670101166,
                0.043596770614385605,
                0.2646196782588959,
                -0.1591472029685974,
                -0.16981826722621918,
                -0.16551166772842407,
                -0.0665379986166954,
                -0.16929991543293,
                -0.16650013625621796
            ],
            [
                -0.014944171532988548,
                -0.09477119892835617,
                -0.15092036128044128,
                -0.16664229333400726,
                0.08570507168769836,
                -0.00956485141068697,
                0.5483823418617249,
                0.04776720330119133,
                0.22590529918670654,
                1.0398173332214355,
                0.23071999847888947,
                -0.016275180503726006,
                -0.14199887216091156,
                0.950590968132019,
                0.012651119381189346,
                -0.10586043447256088
            ],
            [
                -0.12645266950130463,
                0.7869861125946045,
                -0.13593582808971405,
                -0.1672607958316803,
                -0.13000105321407318,
                -0.10938537120819092,
                -0.17002269625663757,
                -0.16324849426746368,
                0.2429819107055664,
                -0.1589052379131317,
                1.2143100500106812,
                -0.04835134744644165,
                0.6004401445388794,
                -0.026636941358447075,
                -0.04951532185077667,
                -0.10781518369913101
            ],
            [
                -0.1454135924577713,
                1.509835958480835,
                -0.16858601570129395,
                -0.04117823392152786,
                -0.008347561582922935,
                -0.05484563484787941,
                -0.15855814516544342,
                -0.11730581521987915,
                -0.023150833323597908,
                -0.10382359474897385,
                0.044034797698259354,
                0.19081099331378937,
                0.28702110052108765,
                0.06039046496152878,
                -0.004953635390847921,
                -0.0947093740105629
            ],
            [
                -0.12302175909280777,
                0.044881388545036316,
                0.07309557497501373,
                -0.0069521390832960606,
                -0.1685016006231308,
                -0.16682197153568268,
                -0.10576402395963669,
                -0.042239960283041,
                -0.03237375244498253,
                -0.03655398264527321,
                -0.07820151746273041,
                -0.14041535556316376,
                -0.03673756122589111,
                -0.1700037568807602,
                -0.01580871269106865,
                -0.06197535619139671
            ],
            [
                -0.010392723605036736,
                -0.12135249376296997,
                0.018306633457541466,
                -0.09544861316680908,
                -0.07457108795642853,
                -0.140609011054039,
                -0.11016084998846054,
                0.12778475880622864,
                -0.06735707819461823,
                -0.13073234260082245,
                0.04850439727306366,
                -0.1465659737586975,
                -0.16720148921012878,
                -0.16350960731506348,
                -0.11812164634466171,
                -0.04454880952835083
            ],
            [
                -0.15911319851875305,
                -0.04213836416602135,
                -0.05816361680626869,
                -0.10231722146272659,
                -0.1696036159992218,
                -0.13960443437099457,
                0.04829489812254906,
                -0.008071188814938068,
                -0.08961223065853119,
                0.16010066866874695,
                -0.09733441472053528,
                -0.16986899077892303,
                -0.056900084018707275,
                -0.08088047802448273,
                -0.14317868649959564,
                0.009360666386783123
            ],
            [
                -0.04896337538957596,
                0.09906157851219177,
                -0.1687823385000229,
                -0.037484992295503616,
                -0.1320319026708603,
                -0.13822725415229797,
                -0.09715995192527771,
                -0.15023167431354523,
                -0.12636519968509674,
                -0.12225154787302017,
                -0.15599676966667175,
                -0.04407839849591255,
                -0.1694781333208084,
                -0.0889570489525795,
                -0.11963594704866409,
                -0.08021215349435806
            ],
            [
                -0.13661977648735046,
                -0.11244074255228043,
                -0.10788718611001968,
                -0.15603266656398773,
                0.18090000748634338,
                -0.14418192207813263,
                -0.1360231339931488,
                0.069947749376297,
                -0.14969415962696075,
                -0.14273394644260406,
                -0.023213181644678116,
                0.22160972654819489,
                -0.15499216318130493,
                -0.1607666313648224,
                0.19944816827774048,
                0.05768672376871109
            ],
            [
                0.09568781405687332,
                0.07212725281715393,
                -0.0037136722821742296,
                -0.16362392902374268,
                0.06026669219136238,
                -0.12356648594141006,
                -0.07373864203691483,
                -0.15005066990852356,
                -0.1301642507314682,
                -0.12388179451227188,
                -0.1048826053738594,
                -0.10036817938089371,
                -0.1577942967414856,
                -0.16711029410362244,
                -0.1406458616256714,
                -0.029129471629858017
            ],
            [
                0.0747760608792305,
                -0.09951328486204147,
                -0.13378503918647766,
                -0.02228562720119953,
                0.044713094830513,
                -0.11250003427267075,
                -0.1070832908153534,
                -0.0255805104970932,
                0.1458008736371994,
                -0.08937019854784012,
                0.10201296210289001,
                0.002642054809257388,
                -0.16159866750240326,
                -0.04633956402540207,
                -0.043009474873542786,
                -0.03410221263766289
            ],
            [
                -0.05068577453494072,
                -0.11899165064096451,
                0.004720902070403099,
                -0.15045897662639618,
                -0.0872291773557663,
                0.04990433529019356,
                -0.05368445813655853,
                -0.06611334532499313,
                -0.01314710546284914,
                -0.02680397219955921,
                -0.10795058310031891,
                -0.0011640541488304734,
                -0.10888586938381195,
                -0.07352849096059799,
                -0.059071287512779236,
                0.23123201727867126
            ]
        ],
        [
            [
                3.6178946495056152,
                1.7431460618972778,
                1.4380351305007935,
                0.012128624133765697,
                1.2450205087661743,
                1.3627803325653076,
                2.410529375076294,
                1.2413268089294434,
                2.32940673828125,
                2.283360481262207,
                1.4026881456375122,
                1.6445832252502441,
                0.340577095746994,
                2.8276689052581787,
                4.286863327026367,
                2.976755142211914
            ],
            [
                0.8185164928436279,
                2.4584386348724365,
                3.979259967803955,
                2.1934432983398438,
                1.2957943677902222,
                3.4935927391052246,
                2.0888378620147705,
                1.725412368774414,
                1.7125133275985718,
                2.021650552749634,
                1.8278285264968872,
                1.645641565322876,
                0.833107590675354,
                4.767274856567383,
                1.8273943662643433,
                3.6139943599700928
            ],
            [
                0.384112685918808,
                2.2024619579315186,
                2.7405567169189453,
                1.458540678024292,
                0.8840469717979431,
                1.4554499387741089,
                5.2995076179504395,
                0.7944831848144531,
                2.5704405307769775,
                2.9343714714050293,
                0.9339417815208435,
                2.0362775325775146,
                2.8710358142852783,
                2.728541374206543,
                2.824751615524292,
                5.644625663757324
            ],
            [
                5.673136234283447,
                2.8478431701660156,
                1.43906831741333,
                0.42317456007003784,
                0.7583630084991455,
                1.9097156524658203,
                1.8069161176681519,
                0.26215291023254395,
                1.435949444770813,
                1.3425421714782715,
                0.665330708026886,
                0.13762840628623962,
                2.419961929321289,
                5.111257553100586,
                5.785004138946533,
                3.5732109546661377
            ],
            [
                5.568731784820557,
                3.9689571857452393,
                1.5092839002609253,
                0.3097077012062073,
                0.8514281511306763,
                1.7845879793167114,
                2.7910044193267822,
                4.500250339508057,
                4.068089962005615,
                0.6990712285041809,
                0.04142885282635689,
                2.8280751705169678,
                -0.08820725232362747,
                0.939923107624054,
                1.3127110004425049,
                2.3349595069885254
            ],
            [
                2.8753151893615723,
                1.3854026794433594,
                4.438076019287109,
                0.7814860343933105,
                2.328122854232788,
                2.9188737869262695,
                3.1126341819763184,
                4.443088054656982,
                2.2620551586151123,
                1.6665221452713013,
                3.1593871116638184,
                7.481051921844482,
                4.273072242736816,
                0.5010174512863159,
                1.7359219789505005,
                2.4244329929351807
            ],
            [
                1.0091538429260254,
                0.5831015706062317,
                5.21956729888916,
                1.3242558240890503,
                3.1567821502685547,
                4.097699165344238,
                4.016325950622559,
                1.8669464588165283,
                2.1354541778564453,
                1.0232429504394531,
                0.9870043396949768,
                1.9082684516906738,
                0.10292042791843414,
                6.750708103179932,
                4.229402542114258,
                4.0840606689453125
            ],
            [
                1.6247695684432983,
                1.6114263534545898,
                2.7535624504089355,
                0.526827871799469,
                1.0107108354568481,
                4.074209690093994,
                5.515962600708008,
                3.1954379081726074,
                2.541466474533081,
                2.1045563220977783,
                1.2118732929229736,
                0.4511169493198395,
                1.3707597255706787,
                6.442889213562012,
                1.5946346521377563,
                0.7406618595123291
            ],
            [
                -0.1552947759628296,
                -0.1543843001127243,
                -0.14383193850517273,
                -0.16311824321746826,
                -0.07167519629001617,
                -0.14027805626392365,
                -0.16213707625865936,
                -0.15232697129249573,
                -0.013581323437392712,
                -0.06876484304666519,
                -0.1690012514591217,
                -0.16366511583328247,
                -0.17003899812698364,
                -0.16996531188488007,
                -0.15798860788345337,
                -0.09368418902158737
            ],
            [
                -0.1125333160161972,
                -0.1570460945367813,
                -0.15978668630123138,
                -0.15694662928581238,
                -0.09419261664152145,
                -0.1692245602607727,
                -0.13388179242610931,
                -0.17004062235355377,
                -0.14802134037017822,
                -0.16768507659435272,
                -0.1633697897195816,
                -0.11413035541772842,
                -0.15445271134376526,
                -0.15459422767162323,
                -0.10802585631608963,
                0.4284507930278778
            ],
            [
                -0.08782640844583511,
                -0.16992196440696716,
                -0.16738367080688477,
                -0.15870389342308044,
                -0.16774465143680573,
                -0.14288192987442017,
                -0.16226351261138916,
                -0.1697153002023697,
                -0.16802793741226196,
                -0.1603555828332901,
                -0.12948301434516907,
                -0.13885259628295898,
                -0.16324017941951752,
                -0.16544586420059204,
                -0.014672105200588703,
                -0.09862974286079407
            ],
            [
                -0.08520202338695526,
                -0.15886907279491425,
                -0.15768501162528992,
                -0.1646556556224823,
                -0.1384582221508026,
                -0.16824138164520264,
                -0.10099727660417557,
                -0.118065744638443,
                -0.16467300057411194,
                -0.16939270496368408,
                -0.17002032697200775,
                -0.14943984150886536,
                -0.15682734549045563,
                -0.1320069283246994,
                -0.14492827653884888,
                -0.1078668013215065
            ],
            [
                -0.0652904212474823,
                -0.126564621925354,
                -0.16928230226039886,
                -0.16136501729488373,
                -0.15347200632095337,
                -0.16822069883346558,
                -0.16965632140636444,
                -0.1680546998977661,
                -0.13608002662658691,
                -0.09789407253265381,
                -0.16160368919372559,
                -0.16750997304916382,
                -0.11186227947473526,
                -0.10042653232812881,
                -0.1642543226480484,
                -0.12831321358680725
            ],
            [
                -0.14212395250797272,
                -0.16839481890201569,
                -0.17001405358314514,
                -0.16896653175354004,
                -0.1667250543832779,
                -0.163956418633461,
                -0.14958684146404266,
                -0.16797053813934326,
                -0.16501125693321228,
                -0.16814500093460083,
                -0.12850089371204376,
                -0.14665374159812927,
                -0.13533726334571838,
                -0.1610843390226364,
                -0.16587364673614502,
                -0.16142579913139343
            ],
            [
                -0.12907010316848755,
                -0.14077015221118927,
                -0.1692797839641571,
                -0.11777035892009735,
                -0.14926834404468536,
                -0.1683836281299591,
                -0.1508677452802658,
                -0.12926867604255676,
                -0.16109433770179749,
                -0.14285701513290405,
                -0.15899723768234253,
                -0.16451942920684814,
                -0.14777600765228271,
                -0.15262623131275177,
                -0.16844812035560608,
                -0.16536791622638702
            ],
            [
                -0.13342633843421936,
                -0.046966422349214554,
                -0.07309851795434952,
                -0.05464211851358414,
                -0.022174572572112083,
                -0.14695636928081512,
                -0.001292754546739161,
                -0.10410405695438385,
                0.08732116222381592,
                -0.05765468254685402,
                -0.056923527270555496,
                -0.08820309489965439,
                -0.05610629916191101,
                0.08387923985719681,
                0.04173339158296585,
                -0.11529345065355301
            ]
        ],
        [
            [
                5.349878311157227,
                5.651141166687012,
                5.29408597946167,
                10.082404136657715,
                7.155221462249756,
                7.101680755615234,
                7.534937381744385,
                6.113386154174805,
                7.058103561401367,
                7.594311714172363,
                4.61745548248291,
                6.329408645629883,
                5.770536422729492,
                7.405346870422363,
                7.223231315612793,
                7.114352226257324
            ],
            [
                3.3823652267456055,
                8.123307228088379,
                3.617392063140869,
                3.7223246097564697,
                5.323217391967773,
                11.005956649780273,
                4.8961920738220215,
                9.055488586425781,
                8.013843536376953,
                3.710289716720581,
                3.3095154762268066,
                4.30462121963501,
                5.22664737701416,
                4.680942058563232,
                6.517570972442627,
                9.904818534851074
            ],
            [
                4.009366512298584,
                7.5267014503479,
                6.436667442321777,
                3.6885035037994385,
                6.212040424346924,
                10.441253662109375,
                9.275519371032715,
                2.8961377143859863,
                6.766983985900879,
                4.622981071472168,
                3.7502939701080322,
                7.274206161499023,
                7.409881114959717,
                10.046746253967285,
                10.7255277633667,
                11.37639331817627
            ],
            [
                3.347273588180542,
                5.221493244171143,
                3.2696030139923096,
                6.864476203918457,
                6.28242826461792,
                11.432870864868164,
                7.872435092926025,
                6.831152439117432,
                8.432564735412598,
                1.8718663454055786,
                4.717319488525391,
                5.825795650482178,
                6.180447578430176,
                8.794567108154297,
                10.451959609985352,
                5.364500999450684
            ],
            [
                6.731137752532959,
                8.753196716308594,
                4.13739013671875,
                3.2523128986358643,
                5.672126770019531,
                9.053391456604004,
                6.88239049911499,
                6.392486572265625,
                6.157925605773926,
                7.288902282714844,
                5.126493453979492,
                4.195760726928711,
                5.74608850479126,
                4.062768459320068,
                4.744073867797852,
                6.074985980987549
            ],
            [
                5.819242000579834,
                6.463446617126465,
                5.961390495300293,
                3.6923165321350098,
                4.86002779006958,
                5.152795791625977,
                4.761479377746582,
                5.654379367828369,
                5.917730331420898,
                3.3602890968322754,
                8.155640602111816,
                null,
                8.126882553100586,
                6.676174640655518,
                10.000833511352539,
                6.9396653175354
            ],
            [
                4.600137233734131,
                6.784264087677002,
                4.188783645629883,
                7.3708577156066895,
                4.881905555725098,
                4.7939581871032715,
                6.400474548339844,
                3.748518466949463,
                8.177606582641602,
                10.214664459228516,
                9.734376907348633,
                5.0139875411987305,
                10.349827766418457,
                null,
                11.212198257446289,
                8.121283531188965
            ],
            [
                4.200523853302002,
                7.7279510498046875,
                10.07308292388916,
                10.235142707824707,
                0.5827329158782959,
                5.147185802459717,
                6.283617973327637,
                3.74131178855896,
                9.075716972351074,
                7.583742141723633,
                5.48297119140625,
                3.9437553882598877,
                5.920392036437988,
                null,
                null,
                3.4527812004089355
            ],
            [
                -0.024120332673192024,
                -0.07326187193393707,
                -0.006459069438278675,
                0.06075553968548775,
                -0.052325207740068436,
                0.2780943810939789,
                -0.045971617102622986,
                -0.0760389193892479,
                -0.13604427874088287,
                0.11683078110218048,
                -0.07738275825977325,
                -0.00047156435903161764,
                0.11004052311182022,
                -0.015409693121910095,
                -0.15704423189163208,
                -0.04026322066783905
            ],
            [
                0.061172209680080414,
                0.08434006571769714,
                0.07073096185922623,
                -0.02827083319425583,
                -0.13334481418132782,
                -0.13916464149951935,
                0.0067773181945085526,
                -0.07253928482532501,
                -0.1599709391593933,
                -0.08992341160774231,
                0.07002995908260345,
                -0.02624521031975746,
                -0.02494843117892742,
                0.049095138907432556,
                -0.1438731700181961,
                0.22508776187896729
            ],
            [
                -0.027432432398200035,
                -0.09750207513570786,
                -0.10051323473453522,
                0.07745209336280823,
                -0.0152793163433671,
                -0.10055018216371536,
                -0.011380746960639954,
                0.05798555538058281,
                -0.006068352144211531,
                -0.015009358525276184,
                -0.06403845548629761,
                -0.12145662307739258,
                0.038806892931461334,
                0.002881634049117565,
                0.008205109275877476,
                -0.011980723589658737
            ],
            [
                -0.0873846635222435,
                -0.07823048532009125,
                -0.16869424283504486,
                -0.13480927050113678,
                -0.00009568064706400037,
                -0.10044202953577042,
                -0.07586359977722168,
                -0.08706027269363403,
                -0.161995530128479,
                -0.08502083271741867,
                -0.1425696462392807,
                -0.088496133685112,
                -0.1681673526763916,
                -0.1612459272146225,
                0.03544154390692711,
                0.09840016812086105
            ],
            [
                0.07528699934482574,
                -0.040899764746427536,
                -0.005718026775866747,
                -0.0869041234254837,
                -0.1436462700366974,
                -0.1397596001625061,
                -0.09306365251541138,
                -0.06209249049425125,
                -0.06826543807983398,
                -0.09334022551774979,
                -0.1100282147526741,
                0.07024386525154114,
                -0.16950230300426483,
                -0.16620001196861267,
                -0.06515290588140488,
                0.04837191849946976
            ],
            [
                -0.01728995330631733,
                -0.11109505593776703,
                -0.135901540517807,
                -0.06544656306505203,
                -0.06502421200275421,
                -0.14380089938640594,
                0.2080935686826706,
                -0.16858640313148499,
                -0.09534406661987305,
                -0.1463625580072403,
                -0.14597256481647491,
                -0.11513949930667877,
                -0.07773218303918839,
                -0.1679973602294922,
                -0.16142578423023224,
                -0.012711088173091412
            ],
            [
                -0.05818244814872742,
                -0.12682272493839264,
                -0.11147776246070862,
                -0.13590848445892334,
                0.004638774320483208,
                -0.14864975214004517,
                -0.1500432938337326,
                -0.13331136107444763,
                -0.14140528440475464,
                -0.1700369417667389,
                -0.16991934180259705,
                -0.16092991828918457,
                -0.12898921966552734,
                -0.11476363241672516,
                0.013621282763779163,
                -0.030429353937506676
            ],
            [
                0.15987512469291687,
                0.06524229049682617,
                -0.016813693568110466,
                -0.04143412038683891,
                -0.11269021034240723,
                -0.022342069074511528,
                -0.12063835561275482,
                -0.13720829784870148,
                -0.048054516315460205,
                0.03680668771266937,
                -0.13519369065761566,
                0.08067066222429276,
                0.02472778782248497,
                -0.10427659749984741,
                -0.16743606328964233,
                -0.16990458965301514
            ]
        ],
        [
            [
                0.12477581948041916,
                -0.09284166246652603,
                1.2177520990371704,
                3.391993761062622,
                1.4375996589660645,
                2.1045827865600586,
                2.767930507659912,
                1.2613073587417603,
                2.764921188354492,
                0.40585145354270935,
                1.0854305028915405,
                0.17563021183013916,
                2.1890571117401123,
                2.3680593967437744,
                -0.1475493609905243,
                1.6631367206573486
            ],
            [
                1.7640663385391235,
                2.0433907508850098,
                0.6689175367355347,
                0.38514941930770874,
                1.013979434967041,
                1.9570821523666382,
                2.6221609115600586,
                3.0141232013702393,
                0.5849511623382568,
                2.274898052215576,
                1.332991361618042,
                1.1197950839996338,
                0.9611844420433044,
                0.09200531989336014,
                0.5473926663398743,
                3.9040653705596924
            ],
            [
                2.1644954681396484,
                0.6449569463729858,
                2.2147698402404785,
                0.0995890200138092,
                1.0974313020706177,
                2.2838690280914307,
                1.8321508169174194,
                -0.08402863889932632,
                1.6224820613861084,
                1.4917479753494263,
                1.9330140352249146,
                0.7993438839912415,
                2.223083734512329,
                0.909037709236145,
                2.7007100582122803,
                3.5128912925720215
            ],
            [
                0.19932162761688232,
                1.1686800718307495,
                0.624291718006134,
                0.3229518532752991,
                0.21506373584270477,
                1.5213316679000854,
                2.4539480209350586,
                0.6753440499305725,
                3.475867509841919,
                -0.152986079454422,
                1.087390661239624,
                3.5338172912597656,
                2.472944974899292,
                2.5806097984313965,
                1.8165297508239746,
                2.3850817680358887
            ],
            [
                2.334296703338623,
                2.7810943126678467,
                0.16985395550727844,
                -0.08205856382846832,
                1.537792682647705,
                2.819448947906494,
                2.961350679397583,
                -0.041106145828962326,
                -0.06745998561382294,
                1.800391435623169,
                3.720397710800171,
                1.1345298290252686,
                1.0342410802841187,
                0.023424500599503517,
                1.1131596565246582,
                3.002213954925537
            ],
            [
                1.6203312873840332,
                3.259655237197876,
                1.092872977256775,
                0.5939591526985168,
                1.31336510181427,
                2.033745288848877,
                2.0399811267852783,
                0.4506092369556427,
                0.5783166289329529,
                0.5549923777580261,
                2.9978580474853516,
                3.3432915210723877,
                1.0598809719085693,
                0.15886357426643372,
                3.9047746658325195,
                2.233182191848755
            ],
            [
                0.4739157557487488,
                2.2902419567108154,
                1.5357415676116943,
                4.473903179168701,
                1.9862103462219238,
                2.3002285957336426,
                1.773042917251587,
                1.6773871183395386,
                -0.14511623978614807,
                3.804793119430542,
                3.5899298191070557,
                0.9304772615432739,
                1.2154557704925537,
                3.6641857624053955,
                3.659454107284546,
                2.281714916229248
            ],
            [
                1.1973001956939697,
                2.7694172859191895,
                3.642113447189331,
                4.7685651779174805,
                0.6020177602767944,
                3.3127083778381348,
                2.8422887325286865,
                2.1391103267669678,
                0.31829583644866943,
                4.00858211517334,
                2.2519655227661133,
                1.475634217262268,
                1.543227195739746,
                2.304513931274414,
                4.358455657958984,
                2.2699880599975586
            ],
            [
                -0.022549105808138847,
                0.5202865600585938,
                -0.0206825640052557,
                -0.025588935241103172,
                0.04782722145318985,
                -0.053890351206064224,
                -0.09530750662088394,
                0.15987394750118256,
                -0.02376684918999672,
                -0.0018904204480350018,
                -0.13564588129520416,
                0.08897344768047333,
                -0.10860093683004379,
                -0.09130454063415527,
                -0.10607853531837463,
                0.3645358383655548
            ],
            [
                -0.02859329618513584,
                0.15004204213619232,
                -0.026322564110159874,
                -0.02705921046435833,
                -0.10963685810565948,
                -0.03928510099649429,
                0.2779507637023926,
                -0.09969214349985123,
                -0.0128754498437047,
                0.0809950903058052,
                -0.027992015704512596,
                -0.09052262455224991,
                -0.10331600904464722,
                0.1353660225868225,
                0.03490990027785301,
                0.4896409809589386
            ],
            [
                -0.03180420771241188,
                0.13525058329105377,
                0.040722932666540146,
                -0.042952410876750946,
                0.04408371448516846,
                0.21821556985378265,
                0.3663135766983032,
                0.00851950142532587,
                -0.11822232604026794,
                0.3695305585861206,
                -0.052943453192710876,
                -0.16975222527980804,
                0.059533536434173584,
                -0.10288161039352417,
                0.08932242542505264,
                0.225799098610878
            ],
            [
                0.12454640865325928,
                -0.05620505288243294,
                -0.10807808488607407,
                0.02329551987349987,
                0.10065313428640366,
                0.034514110535383224,
                -0.028311453759670258,
                -0.05092250555753708,
                -0.12707611918449402,
                0.16488388180732727,
                0.13348732888698578,
                -0.16470307111740112,
                0.03053051047027111,
                -0.08602821081876755,
                0.3500077426433563,
                0.36034494638442993
            ],
            [
                0.21309104561805725,
                -0.015709463506937027,
                0.11525766551494598,
                0.1081896498799324,
                0.019486548379063606,
                0.02905016392469406,
                0.0026617248076945543,
                0.1838814914226532,
                -0.06855317205190659,
                -0.03063288703560829,
                0.12143489718437195,
                -0.12940934300422668,
                -0.10630910843610764,
                0.004876565653830767,
                0.09531518071889877,
                0.12909100949764252
            ],
            [
                0.34500187635421753,
                0.0718161016702652,
                -0.022088926285505295,
                0.09186024963855743,
                0.435811847448349,
                -0.11507857590913773,
                -0.08143965154886246,
                0.07942675799131393,
                -0.10782724618911743,
                -0.16937796771526337,
                -0.06470954418182373,
                -0.016746893525123596,
                -0.1337907314300537,
                -0.09199462831020355,
                0.09887921065092087,
                0.4003220796585083
            ],
            [
                -0.02196292206645012,
                0.1753244549036026,
                -0.11200758069753647,
                0.3789661228656769,
                0.1468074917793274,
                -0.03107837773859501,
                -0.06383246183395386,
                -0.03119221329689026,
                -0.051036324352025986,
                -0.13015612959861755,
                -0.03562970459461212,
                0.06315843015909195,
                -0.08104649931192398,
                -0.14258748292922974,
                0.14539997279644012,
                0.3822087049484253
            ],
            [
                0.07276620715856552,
                0.1597224771976471,
                -0.11164261400699615,
                0.04765807092189789,
                0.01109384000301361,
                0.02518060989677906,
                0.04736628383398056,
                -0.02591876871883869,
                0.16108986735343933,
                -0.038670044392347336,
                -0.012487784959375858,
                0.019773252308368683,
                0.006892630364745855,
                0.15392069518566132,
                -0.11054803431034088,
                0.3970479369163513
            ]
        ],
        [
            [
                2.8753559589385986,
                3.2518904209136963,
                4.787095546722412,
                5.2158660888671875,
                6.303225994110107,
                4.23675537109375,
                3.1726186275482178,
                3.936729669570923,
                4.525324821472168,
                3.193740129470825,
                3.3094916343688965,
                3.789369821548462,
                3.845681667327881,
                3.4972035884857178,
                5.133736610412598,
                6.005574703216553
            ],
            [
                4.4747490882873535,
                3.68179988861084,
                3.1575191020965576,
                3.8147823810577393,
                3.336606025695801,
                5.449182033538818,
                3.265632390975952,
                6.491734504699707,
                5.044841289520264,
                3.044654369354248,
                1.855062484741211,
                1.8037536144256592,
                2.4632928371429443,
                2.4967613220214844,
                5.272648334503174,
                6.4591240882873535
            ],
            [
                3.09982967376709,
                3.815078020095825,
                2.496058225631714,
                2.4014127254486084,
                4.614025592803955,
                5.721946716308594,
                6.014470100402832,
                3.6861534118652344,
                3.7230491638183594,
                0.7573055028915405,
                2.1809301376342773,
                3.1227669715881348,
                3.9449963569641113,
                5.543540954589844,
                6.698965549468994,
                5.813473701477051
            ],
            [
                3.5917015075683594,
                1.8808385133743286,
                2.9549338817596436,
                2.8550941944122314,
                4.863524436950684,
                7.748232364654541,
                5.644288063049316,
                3.9678351879119873,
                3.334226369857788,
                4.248859405517578,
                2.77793288230896,
                2.693753957748413,
                3.7169041633605957,
                6.642284393310547,
                7.191833019256592,
                3.6910512447357178
            ],
            [
                4.237721920013428,
                4.443965911865234,
                2.9345309734344482,
                2.9640674591064453,
                4.167735576629639,
                3.596689462661743,
                3.324509620666504,
                3.634855031967163,
                3.38417649269104,
                2.3058722019195557,
                3.1139724254608154,
                3.5757172107696533,
                3.403937578201294,
                3.1207029819488525,
                3.2603518962860107,
                1.648283839225769
            ],
            [
                3.212475299835205,
                2.9697022438049316,
                3.496457576751709,
                2.537343978881836,
                1.6384913921356201,
                3.1258327960968018,
                2.8036046028137207,
                2.0709643363952637,
                2.192817449569702,
                3.2297236919403076,
                7.834323406219482,
                12.067116737365723,
                5.526293754577637,
                4.657074928283691,
                3.4303483963012695,
                3.183743715286255
            ],
            [
                3.4652786254882812,
                2.883960247039795,
                4.026940822601318,
                4.978763103485107,
                2.9114530086517334,
                2.5169317722320557,
                2.9957118034362793,
                1.7748103141784668,
                3.9851646423339844,
                5.744673728942871,
                3.7665855884552,
                5.401196002960205,
                5.057369232177734,
                12.52702522277832,
                8.106209754943848,
                4.937446117401123
            ],
            [
                3.138017416000366,
                3.5014891624450684,
                5.556975841522217,
                7.457066535949707,
                3.4188404083251953,
                2.7633297443389893,
                4.522637367248535,
                2.8263988494873047,
                7.491896629333496,
                4.847522258758545,
                2.68178391456604,
                2.5853850841522217,
                5.098977565765381,
                null,
                null,
                3.0405819416046143
            ],
            [
                0.6711875200271606,
                0.11032500863075256,
                0.6154219508171082,
                0.2549465298652649,
                0.3996325135231018,
                0.47223174571990967,
                0.2873116433620453,
                0.49511462450027466,
                0.6144759058952332,
                0.49422958493232727,
                0.2695976793766022,
                0.29494452476501465,
                0.4596788287162781,
                0.6108013391494751,
                0.46656346321105957,
                0.2140635848045349
            ],
            [
                0.3407551944255829,
                0.24501940608024597,
                0.49570325016975403,
                0.6373615264892578,
                0.38029125332832336,
                0.5786333084106445,
                0.48314258456230164,
                0.6120772361755371,
                0.7942043542861938,
                0.628227174282074,
                0.46514230966567993,
                0.2440357506275177,
                0.37673330307006836,
                0.399894118309021,
                0.409252792596817,
                0.18648065626621246
            ],
            [
                0.4601127803325653,
                0.4648401141166687,
                0.356192409992218,
                0.4595794975757599,
                0.27555182576179504,
                0.2747069001197815,
                0.5586625337600708,
                0.3195137679576874,
                0.5030741095542908,
                0.4443705976009369,
                0.24376855790615082,
                0.5886162519454956,
                0.4564027488231659,
                0.34507840871810913,
                0.1840851902961731,
                0.16495876014232635
            ],
            [
                0.4390062987804413,
                0.5000812411308289,
                0.8880501389503479,
                0.3284485638141632,
                0.16317419707775116,
                0.23098455369472504,
                0.47545337677001953,
                0.50714510679245,
                0.4523717164993286,
                0.36461859941482544,
                0.4796787202358246,
                0.6876930594444275,
                0.6013967394828796,
                0.595736563205719,
                0.29130879044532776,
                0.22699490189552307
            ],
            [
                0.7184881567955017,
                0.48677608370780945,
                0.5422567129135132,
                0.44129690527915955,
                0.8059011697769165,
                0.6452392935752869,
                0.3961700201034546,
                0.47577738761901855,
                0.43206682801246643,
                0.3998037874698639,
                0.42610636353492737,
                0.37175217270851135,
                0.5734710097312927,
                0.41368383169174194,
                0.35082268714904785,
                0.24595829844474792
            ],
            [
                0.43393823504447937,
                0.6653798222541809,
                0.5097509026527405,
                0.4679049849510193,
                0.6622803807258606,
                0.5390022993087769,
                0.5781551003456116,
                0.6060087084770203,
                0.5381540060043335,
                0.43107596039772034,
                0.5748919248580933,
                0.080112524330616,
                0.42809590697288513,
                0.5948421955108643,
                0.5008333325386047,
                0.3890403211116791
            ],
            [
                0.31339383125305176,
                0.44049912691116333,
                0.5918553471565247,
                0.28355836868286133,
                0.8629241585731506,
                0.5707970261573792,
                0.3943740129470825,
                0.34998756647109985,
                0.45093247294425964,
                0.5150524973869324,
                0.6091009974479675,
                0.30507969856262207,
                0.5209429860115051,
                0.3524285852909088,
                0.258794903755188,
                0.17649617791175842
            ],
            [
                0.4101640582084656,
                0.20126307010650635,
                0.446187824010849,
                0.6211814880371094,
                0.4716310501098633,
                0.5539700984954834,
                0.8690565824508667,
                0.5606721639633179,
                0.5295800566673279,
                0.5516017079353333,
                0.7266142964363098,
                0.4673607647418976,
                0.5005171298980713,
                0.4305294156074524,
                0.5149639248847961,
                0.588546633720398
            ]
        ],
        [
            [
                -1.4764827938051894e-7,
                -1.89951563811519e-7,
                0,
                0,
                0,
                0,
                -1.5091585225945892e-7,
                -2.0170159586996306e-7,
                -1.9046890997742594e-7,
                0,
                -0.0000026089921902894275,
                -0.00000235033871831547,
                -0.000021105883206473663,
                0,
                -1.7571740329458407e-7,
                0
            ],
            [
                -0.0000015461607745237416,
                0,
                0,
                -4.341835619925405e-7,
                -1.7016796505231468e-7,
                0,
                0,
                0,
                0,
                0,
                -0.000015262316082953475,
                0,
                0,
                -1.6860289520082006e-7,
                0,
                -1.5563632871362643e-7
            ],
            [
                -0.00003282127727288753,
                -0.0000011389738574507646,
                0,
                -0.0000363463768735528,
                0,
                -0.000023408820197801106,
                -2.0252574017831648e-7,
                -0.00001624617652851157,
                -1.5727543711818726e-7,
                -0.000007398793059110176,
                -4.365122663330112e-7,
                0,
                0,
                0,
                0,
                0
            ],
            [
                0,
                -4.4351043015922187e-7,
                -0.0000010088473345604143,
                -0.00007575929339509457,
                -4.339327688285266e-7,
                0,
                -0.0000031469003261008766,
                0,
                0,
                -0.0000043233867472736165,
                -0.000003281958015577402,
                0,
                0,
                0,
                0,
                0
            ],
            [
                -2.2072366334668914e-7,
                -2.1666043892309972e-7,
                0,
                -0.000002343314918107353,
                -0.0000018222674498247216,
                0,
                0,
                0,
                0,
                -1.6642030686853104e-7,
                0,
                0,
                0,
                -8.633166999061359e-7,
                0,
                0
            ],
            [
                0,
                0,
                -1.779384319888777e-7,
                -9.988985993913957e-7,
                0,
                0,
                -0.00007192571501946077,
                -1.7648109462697903e-7,
                0,
                0,
                -1.57147212576092e-7,
                0,
                -0.000001814519009712967,
                -2.078164555996409e-7,
                -0.0000042024821595987305,
                -0.000003281050567238708
            ],
            [
                -4.335668677413196e-7,
                -0.00014825713878963143,
                0,
                -1.866116861037881e-7,
                0,
                -2.1870201294404978e-7,
                0,
                0,
                0,
                -1.77860826511278e-7,
                -0.00003199435377609916,
                0,
                0,
                0,
                0,
                0
            ],
            [
                0,
                -1.7357493220515607e-7,
                0,
                0,
                0,
                -2.073998075502459e-7,
                0,
                0,
                -0.0000016743374544603284,
                0,
                0,
                -1.5177150203271594e-7,
                0,
                0,
                0,
                -1.5677117914947303e-7
            ],
            [
                0.14705297350883484,
                0.05435702204704285,
                0.3444957137107849,
                0.13427463173866272,
                -0.033287953585386276,
                0.28984227776527405,
                0.15715624392032623,
                0.023648884147405624,
                0.03858581930398941,
                0.3241581618785858,
                0.16778457164764404,
                0.15914462506771088,
                -0.017510028555989265,
                -0.04672567546367645,
                -0.06400670111179352,
                0.0026708163786679506
            ],
            [
                0.35327449440956116,
                0.02580077387392521,
                0.6794838309288025,
                0.15465998649597168,
                0.3854663074016571,
                0.11205670237541199,
                0.19082479178905487,
                0.4463862478733063,
                0.07496011257171631,
                0.14483176171779633,
                0.21594834327697754,
                0.04435189813375473,
                -0.0346645787358284,
                0.23279650509357452,
                0.519565999507904,
                0.419198602437973
            ],
            [
                0.166075199842453,
                0.04747370630502701,
                0.042119625955820084,
                0.2312888205051422,
                0.11559750139713287,
                0.44751015305519104,
                0.5166364312171936,
                0.37645626068115234,
                0.13769300282001495,
                0.46669819951057434,
                0.4470445215702057,
                0.25663191080093384,
                0.09613848477602005,
                0.28733929991722107,
                0.44483959674835205,
                0.6606429815292358
            ],
            [
                0.20328350365161896,
                0.21499086916446686,
                0.2082356959581375,
                0.30310267210006714,
                0.5250652432441711,
                0.3985285758972168,
                0.16822907328605652,
                0.23838908970355988,
                -0.11212272197008133,
                -0.004588759504258633,
                0.04441719129681587,
                0.3613562285900116,
                -0.025589315220713615,
                0.21570630371570587,
                0.5936394929885864,
                0.6300628781318665
            ],
            [
                0.24026358127593994,
                0.22835558652877808,
                0.10575184971094131,
                0.12790101766586304,
                0.40901222825050354,
                0.2000245898962021,
                0.034831106662750244,
                0.2142294943332672,
                0.11591584980487823,
                0.2522929012775421,
                0.3347199261188507,
                0.25484004616737366,
                0.19642682373523712,
                0.12627753615379333,
                0.68663090467453,
                0.7971017956733704
            ],
            [
                0.3256477415561676,
                0.41333431005477905,
                0.4880673885345459,
                0.30455878376960754,
                0.40612292289733887,
                0.23441791534423828,
                0.27646052837371826,
                -0.029492726549506187,
                0.13924157619476318,
                0.1326858550310135,
                0.0600336492061615,
                0.292060911655426,
                0.37985581159591675,
                0.10335630178451538,
                0.25326016545295715,
                0.1820761114358902
            ],
            [
                0.34960973262786865,
                0.12811298668384552,
                0.034421440213918686,
                0.20230527222156525,
                0.5238000750541687,
                0.22618494927883148,
                0.25286099314689636,
                0.25396549701690674,
                0.39497989416122437,
                0.21230560541152954,
                0.07770799845457077,
                -0.08953877538442612,
                0.011252977885305882,
                0.2889779806137085,
                0.08486846834421158,
                0.09003400057554245
            ],
            [
                0.3319070339202881,
                0.3085229694843292,
                0.37877166271209717,
                0.16976770758628845,
                0.1378142237663269,
                0.16946552693843842,
                0.23925991356372833,
                0.38420262932777405,
                0.4089301526546478,
                0.2447485774755478,
                0.06801238656044006,
                0.503998875617981,
                0.32221946120262146,
                0.3292035460472107,
                0.37355974316596985,
                0.4544745981693268
            ]
        ],
        [
            [
                0.7523343563079834,
                1.5592015981674194,
                0.5659152269363403,
                0.3331439197063446,
                0.30606523156166077,
                -0.1673605889081955,
                -0.14079251885414124,
                0.7242206931114197,
                1.2498767375946045,
                0.01100064069032669,
                -0.12976494431495667,
                -0.1671450287103653,
                -0.10527235269546509,
                -0.16571179032325745,
                -0.07529915869235992,
                2.4082136154174805
            ],
            [
                -0.14075703918933868,
                -0.01367496419698,
                -0.007257090415805578,
                -0.05037112534046173,
                -0.1331787109375,
                0.02432987093925476,
                -0.14746583998203278,
                -0.16983838379383087,
                -0.13968133926391602,
                -0.03697321563959122,
                -0.15387052297592163,
                -0.06034195050597191,
                -0.13421466946601868,
                -0.10288573801517487,
                -0.15445426106452942,
                0.7825911045074463
            ],
            [
                0.7625550031661987,
                0.03731831535696983,
                0.7027449011802673,
                -0.15918725728988647,
                -0.14252732694149017,
                -0.05150151625275612,
                0.40895983576774597,
                -0.15844090282917023,
                -0.16857953369617462,
                0.4452875554561615,
                1.1617937088012695,
                -0.16852538287639618,
                -0.14760205149650574,
                -0.08642104268074036,
                -0.16761232912540436,
                0.10957896709442139
            ],
            [
                0.8400366306304932,
                -0.16572092473506927,
                -0.03868646174669266,
                -0.0005229609669186175,
                -0.012365513481199741,
                -0.15897418558597565,
                -0.14386309683322906,
                -0.021108394488692284,
                -0.09881458431482315,
                0.26538583636283875,
                0.22140222787857056,
                -0.12806007266044617,
                -0.03160731866955757,
                -0.16561292111873627,
                0.5298019051551819,
                0.20852985978126526
            ],
            [
                0.593342661857605,
                -0.03481973335146904,
                -0.05612288415431976,
                -0.02358093671500683,
                -0.007906991988420486,
                -0.0005853463662788272,
                -0.1298273503780365,
                0.587844729423523,
                0.21406583487987518,
                -0.15814796090126038,
                -0.15434418618679047,
                -0.14494240283966064,
                -0.16892583668231964,
                -0.02253059111535549,
                -0.12319468706846237,
                1.7426310777664185
            ],
            [
                0.3288031220436096,
                -0.16998854279518127,
                -0.16360582411289215,
                -0.031474605202674866,
                -0.1354876458644867,
                -0.15555304288864136,
                -0.04461108520627022,
                -0.15464720129966736,
                -0.11144834756851196,
                -0.1317874938249588,
                -0.07231943309307098,
                3.887000322341919,
                -0.16706658899784088,
                -0.11548890918493271,
                -0.0315430648624897,
                -0.09873812645673752
            ],
            [
                0.5581650733947754,
                -0.1278659999370575,
                -0.12252576649188995,
                -0.1288384050130844,
                -0.1687459796667099,
                0.398703008890152,
                0.1163078248500824,
                0.7424004077911377,
                -0.15162456035614014,
                -0.16090607643127441,
                -0.018765419721603394,
                -0.0992293581366539,
                -0.09854234755039215,
                0.9717773199081421,
                2.6399178504943848,
                2.4103951454162598
            ],
            [
                0.5843461751937866,
                -0.12073053419589996,
                0.02974492870271206,
                1.1035525798797607,
                0.9930381178855896,
                0.9068836569786072,
                0.22849473357200623,
                0.20858220756053925,
                1.5191127061843872,
                1.0627195835113525,
                -0.14739294350147247,
                -0.16552481055259705,
                -0.04375692829489708,
                1.778020977973938,
                4.531346797943115,
                0.488985538482666
            ],
            [
                0.021090814843773842,
                -0.16152113676071167,
                0.1500082165002823,
                0.07744733989238739,
                -0.09377109259366989,
                -0.14683444797992706,
                0.02837325818836689,
                -0.09692012518644333,
                -0.1630805879831314,
                -0.13205847144126892,
                -0.12407568097114563,
                -0.09856904298067093,
                -0.1325838416814804,
                -0.07819388806819916,
                -0.11203113198280334,
                -0.0835905522108078
            ],
            [
                -0.044956643134355545,
                0.02595902606844902,
                -0.08754269033670425,
                -0.09450186043977737,
                -0.12958510220050812,
                -0.16730347275733948,
                -0.058927860110998154,
                -0.0523710772395134,
                -0.1368284821510315,
                -0.15958474576473236,
                -0.026335397735238075,
                0.014056812040507793,
                -0.1383250653743744,
                -0.124452605843544,
                -0.10906361788511276,
                -0.09847021847963333
            ],
            [
                0.03377300128340721,
                -0.1456715315580368,
                -0.11313862353563309,
                -0.0492461696267128,
                -0.13985130190849304,
                -0.10972054302692413,
                -0.15347902476787567,
                -0.1084476113319397,
                -0.0966806635260582,
                -0.16909858584403992,
                -0.06942150741815567,
                -0.057568471878767014,
                -0.14059501886367798,
                -0.043754417449235916,
                0.04958866909146309,
                -0.13843820989131927
            ],
            [
                -0.014347461052238941,
                -0.16465646028518677,
                -0.100660540163517,
                -0.1679302453994751,
                -0.1287076771259308,
                -0.0752064660191536,
                -0.11542729288339615,
                -0.015166640281677246,
                -0.13513576984405518,
                -0.15127518773078918,
                -0.12906260788440704,
                -0.169634148478508,
                -0.05410464480519295,
                -0.14440463483333588,
                -0.1250327229499817,
                -0.06841713935136795
            ],
            [
                -0.002932877978309989,
                -0.042510438710451126,
                -0.11716622859239578,
                -0.1444878876209259,
                -0.1698044389486313,
                -0.005412159021943808,
                -0.14320158958435059,
                -0.10505496710538864,
                -0.06062454730272293,
                -0.15748637914657593,
                -0.06849968433380127,
                -0.0775166004896164,
                -0.10315847396850586,
                -0.07890372723340988,
                -0.16614176332950592,
                -0.16187721490859985
            ],
            [
                -0.08125396817922592,
                -0.1431296169757843,
                -0.1512354016304016,
                -0.07297293841838837,
                -0.16963635385036469,
                -0.0823342353105545,
                -0.12017746269702911,
                -0.14692381024360657,
                -0.1188768744468689,
                -0.02483223006129265,
                -0.025203626602888107,
                -0.09655113518238068,
                -0.1620676964521408,
                -0.12761035561561584,
                -0.11127191036939621,
                -0.10478974133729935
            ],
            [
                -0.011571708135306835,
                -0.14787617325782776,
                0.040819667279720306,
                -0.142940953373909,
                -0.032242223620414734,
                -0.11427183449268341,
                -0.04566722363233566,
                -0.15536093711853027,
                -0.16550345718860626,
                -0.13298580050468445,
                -0.14519356191158295,
                -0.09453169256448746,
                -0.11363381892442703,
                -0.10897982865571976,
                -0.08863180130720139,
                -0.06244374066591263
            ],
            [
                -0.015628036111593246,
                0.023061474785208702,
                -0.059712667018175125,
                -0.024219539016485214,
                -0.06025666370987892,
                -0.12788575887680054,
                -0.06004795804619789,
                -0.07330494374036789,
                -0.020229840651154518,
                -0.029380246996879578,
                -0.009540636092424393,
                -0.0627739205956459,
                -0.007150196470320225,
                0.010228666476905346,
                -0.06779464334249496,
                -0.14240482449531555
            ]
        ],
        [
            [
                -0.11452557891607285,
                0.6079702973365784,
                -0.05755609646439552,
                -0.026418408378958702,
                -0.09291815012693405,
                -0.01044913288205862,
                -0.021129895001649857,
                -0.16822130978107452,
                -0.035310063511133194,
                0.1754797399044037,
                -0.053243983536958694,
                -0.00314005627296865,
                -0.16114570200443268,
                -0.01755215972661972,
                -0.15588518977165222,
                1.4648597240447998
            ],
            [
                -0.04913938418030739,
                -0.15962544083595276,
                -0.07162918150424957,
                -0.001739585306495428,
                -0.027416298165917397,
                0.22245287895202637,
                -0.1416371762752533,
                -0.04924599453806877,
                0.21242845058441162,
                -0.11439073085784912,
                -0.03728228062391281,
                -0.13228683173656464,
                -0.09503332525491714,
                0.1574130654335022,
                -0.037713129073381424,
                0.2194899171590805
            ],
            [
                -0.1183476448059082,
                -0.12417799979448318,
                0.8216331601142883,
                2.292177438735962,
                -0.1557788997888565,
                0.04764079302549362,
                0.07023872435092926,
                -0.08148570358753204,
                0.09084182232618332,
                0.2497556358575821,
                -0.16946811974048615,
                -0.12921945750713348,
                0.28861090540885925,
                -0.02653896063566208,
                -0.15747646987438202,
                1.9260170459747314
            ],
            [
                0.8823959827423096,
                0.29630351066589355,
                -0.15159432590007782,
                -0.08549955487251282,
                -0.11317119002342224,
                -0.11333730071783066,
                0.00779976649209857,
                -0.014092579483985901,
                1.4861481189727783,
                0.8351451754570007,
                -0.03831348568201065,
                -0.0746699795126915,
                -0.06895309686660767,
                0.12344915419816971,
                0.014073674567043781,
                0.9894538521766663
            ],
            [
                -0.1680704951286316,
                -0.15966913104057312,
                -0.10129457712173462,
                -0.0016828894149512053,
                -0.1561897248029709,
                -0.003557769348844886,
                -0.1296188235282898,
                1.258264422416687,
                2.604487657546997,
                0.1136716902256012,
                -0.03682997450232506,
                0.11043762415647507,
                -0.058273810893297195,
                -0.1643824428319931,
                -0.11029588431119919,
                1.090316653251648
            ],
            [
                0.10877608507871628,
                -0.1700257509946823,
                -0.16804371774196625,
                -0.034098658710718155,
                -0.010517589747905731,
                -0.12817434966564178,
                0.3355836868286133,
                1.0901545286178589,
                0.46887093782424927,
                0.131071075797081,
                -0.1557149589061737,
                -0.0006615293677896261,
                0.1302345246076584,
                -0.1456790268421173,
                -0.06749369949102402,
                -0.0699334368109703
            ],
            [
                -0.0645207092165947,
                -0.048924531787633896,
                0.920307993888855,
                -0.08800623565912247,
                -0.06455203145742416,
                1.8386250734329224,
                0.8483320474624634,
                0.7509346008300781,
                0.5616768002510071,
                -0.11278413981199265,
                -0.06472747772932053,
                -0.1202039122581482,
                -0.11436761170625687,
                -0.13798148930072784,
                -0.0018222527578473091,
                1.332521677017212
            ],
            [
                -0.12605810165405273,
                -0.06633226573467255,
                -0.11425181478261948,
                -0.0068494537845253944,
                -0.16129978001117706,
                0.9623532891273499,
                2.071399688720703,
                1.9258360862731934,
                0.02030498906970024,
                -0.16546335816383362,
                -0.14800095558166504,
                -0.10649506747722626,
                -0.0673423781991005,
                -0.00023240913287736475,
                -0.001390728517435491,
                -0.14834080636501312
            ],
            [
                -0.023154474794864655,
                0.39578866958618164,
                0.3197247385978699,
                -0.00909252930432558,
                -0.10038542747497559,
                -0.16849856078624725,
                -0.15887151658535004,
                -0.09169077128171921,
                0.5804712176322937,
                0.09329821914434433,
                -0.014220120385289192,
                -0.15728485584259033,
                -0.12711399793624878,
                -0.1136670783162117,
                -0.14064419269561768,
                -0.16986329853534698
            ],
            [
                -0.15792232751846313,
                -0.0013118375791236758,
                -0.03471097722649574,
                -0.16343502700328827,
                -0.162284255027771,
                -0.169457346200943,
                -0.14731445908546448,
                -0.12624651193618774,
                -0.16983862221240997,
                -0.16288697719573975,
                -0.16889676451683044,
                -0.16614186763763428,
                -0.14161741733551025,
                -0.1695738583803177,
                -0.08256065845489502,
                -0.13957977294921875
            ],
            [
                -0.04599924758076668,
                -0.14403526484966278,
                -0.16825853288173676,
                -0.12182974070310593,
                -0.05281257629394531,
                -0.1647636741399765,
                -0.04477611184120178,
                -0.15974587202072144,
                -0.16889332234859467,
                -0.14924268424510956,
                -0.032852061092853546,
                -0.15237604081630707,
                -0.12956003844738007,
                -0.1695389747619629,
                -0.1679883450269699,
                -0.0976567342877388
            ],
            [
                -0.1699889749288559,
                -0.15692095458507538,
                -0.16114097833633423,
                -0.09966262429952621,
                -0.1420997977256775,
                -0.13083209097385406,
                -0.07646156847476959,
                -0.11022541671991348,
                -0.138107031583786,
                -0.13678628206253052,
                -0.16605232656002045,
                -0.16891923546791077,
                -0.1222418025135994,
                -0.16995882987976074,
                0.09391733258962631,
                0.5060592293739319
            ],
            [
                -0.16695323586463928,
                -0.16984006762504578,
                -0.1648314893245697,
                -0.02343205362558365,
                0.029486680403351784,
                -0.14617910981178284,
                -0.168535515666008,
                -0.14716923236846924,
                -0.16992123425006866,
                -0.16852453351020813,
                -0.1606093943119049,
                -0.16147874295711517,
                -0.170016810297966,
                -0.16850128769874573,
                0.3581864833831787,
                0.018297594040632248
            ],
            [
                -0.017382098361849785,
                -0.014231771230697632,
                0.3705652058124542,
                -0.16993699967861176,
                -0.05488363280892372,
                -0.1699264496564865,
                -0.1630660891532898,
                -0.1316317468881607,
                -0.12668608129024506,
                -0.1544537991285324,
                -0.12348616868257523,
                0.2248876988887787,
                -0.14974571764469147,
                -0.1698208451271057,
                -0.06520191580057144,
                -0.1266038715839386
            ],
            [
                -0.1397467702627182,
                -0.16478459537029266,
                -0.09656965732574463,
                -0.09743767231702805,
                0.11012264341115952,
                -0.16944487392902374,
                -0.15009835362434387,
                -0.1700143665075302,
                -0.13328075408935547,
                -0.14421287178993225,
                -0.04500522464513779,
                -0.16985143721103668,
                -0.16548575460910797,
                -0.15208002924919128,
                -0.16946087777614594,
                -0.15634754300117493
            ],
            [
                -0.16647009551525116,
                -0.16391217708587646,
                -0.09017997235059738,
                -0.15583296120166779,
                -0.04705681651830673,
                -0.10363098978996277,
                -0.17002904415130615,
                -0.16984239220619202,
                -0.13726043701171875,
                -0.11914125084877014,
                -0.1646653711795807,
                -0.14897891879081726,
                -0.0676971971988678,
                -0.10383721441030502,
                -0.1479768007993698,
                -0.0992489829659462
            ]
        ],
        [
            [
                -0.013056367635726929,
                -0.15884941816329956,
                1.0916306972503662,
                -0.15953601896762848,
                -0.10509806871414185,
                -0.14662134647369385,
                -0.13269199430942535,
                -0.16397039592266083,
                0.4161839187145233,
                -0.0441586934030056,
                -0.16617251932621002,
                0.2991510331630707,
                -0.015486880205571651,
                -0.01797010377049446,
                -0.13089169561862946,
                -0.15926826000213623
            ],
            [
                -0.16694143414497375,
                0,
                -0.12305859476327896,
                -0.00775148207321763,
                -0.000008673087904753629,
                -0.032756704837083817,
                -0.00004615914076566696,
                -0.00033914082450792193,
                -0.005550405941903591,
                -0.00043778339750133455,
                -0.0002365967520745471,
                -0.02258698083460331,
                -0.0031502775382250547,
                -0.006009625270962715,
                2.5703072547912598,
                -0.16785405576229095
            ],
            [
                -0.0013172547332942486,
                -1.8580473692964006e-7,
                -0.00012412430078256875,
                -0.0020927905570715666,
                -0.00004371977411210537,
                -0.061665330082178116,
                -0.0024642578791826963,
                -0.13859230279922485,
                -0.00010609212040435523,
                -0.01636369526386261,
                -0.00020122354908380657,
                -0.00021654253941960633,
                -0.0004908390110358596,
                -1.57980309722916e-7,
                -0.07253313809633255,
                -0.004513780120760202
            ],
            [
                -0.0015714565524831414,
                -0.0006113974377512932,
                -0.05487789213657379,
                -0.0017091520130634308,
                -0.000004840206656808732,
                -0.0020343451760709286,
                -0.006954096257686615,
                -0.1655084639787674,
                -0.0006636487087234855,
                0.5019178986549377,
                -0.025683294981718063,
                0,
                -0.000022197402358870022,
                -0.0001331935782218352,
                0,
                -0.004221865441650152
            ],
            [
                -0.00014718635065946728,
                -0.0000054770066526543815,
                -0.00017095681687351316,
                -0.1419830620288849,
                -0.0000012711778936136398,
                0,
                0,
                -2.9004826274103834e-7,
                -0.00013143467367626727,
                -0.00022821855964139104,
                -0.0005548076587729156,
                -0.058240484446287155,
                -0.036558397114276886,
                -0.09514487534761429,
                -0.000007268842182384105,
                -0.00022336757683660835
            ],
            [
                -0.004945792257785797,
                -0.00001782419167284388,
                -0.000004314718353271019,
                0,
                0,
                0,
                -0.011311343871057034,
                0,
                -0.000020622923329938203,
                -0.00008429420267930254,
                -0.09728036820888519,
                -0.0012795589864253998,
                -0.001807776396162808,
                -0.022756684571504593,
                -0.0000018173208218286163,
                -0.03673582151532173
            ],
            [
                -0.02652588300406933,
                -0.00964485201984644,
                -0.00012303603580221534,
                -0.009096791967749596,
                -0.00018665262905415148,
                -2.908182921146363e-7,
                -0.000001949975967363571,
                -9.97499796540069e-7,
                -0.029500562697649002,
                -0.11519809067249298,
                0,
                0,
                -4.3688800133168115e-7,
                0.20698437094688416,
                -0.0000334119213221129,
                -0.00857448298484087
            ],
            [
                -0.015179392881691456,
                -0.00353120150975883,
                -9.918909427142353e-7,
                -0.00010664208821253851,
                -0.06712000072002411,
                -0.006563407834619284,
                -1.6759257448484277e-7,
                -0.0003068108344450593,
                -0.0338817797601223,
                -0.06799966841936111,
                -0.009440001100301743,
                -0.02860231325030327,
                -0.0005935643566772342,
                -0.005341131240129471,
                -0.07425311952829361,
                -0.1385701298713684
            ],
            [
                -0.16553759574890137,
                -0.0994558185338974,
                -0.14807264506816864,
                -0.16841092705726624,
                -0.14505411684513092,
                -0.1474733203649521,
                -0.14718236029148102,
                -0.13548867404460907,
                -0.08998307585716248,
                -0.08422920107841492,
                -0.14229319989681244,
                -0.15712597966194153,
                -0.15656961500644684,
                -0.16124571859836578,
                -0.16393788158893585,
                -0.10014817863702774
            ],
            [
                -0.15173304080963135,
                -0.12752647697925568,
                -0.06982479989528656,
                -0.15269224345684052,
                -0.1440984308719635,
                -0.16902956366539001,
                -0.16915425658226013,
                -0.15245087444782257,
                -0.1673142910003662,
                -0.1493765413761139,
                -0.1652921736240387,
                -0.16867822408676147,
                -0.1516236662864685,
                -0.1354084312915802,
                -0.15392786264419556,
                -0.15813767910003662
            ],
            [
                -0.16999860107898712,
                -0.16559967398643494,
                -0.1384330540895462,
                -0.14893174171447754,
                -0.1240365132689476,
                -0.09747426956892014,
                -0.08956078439950943,
                -0.11822042614221573,
                -0.09871535003185272,
                -0.07262077927589417,
                -0.12971317768096924,
                -0.15829980373382568,
                -0.15049536526203156,
                -0.13160185515880585,
                -0.15238037705421448,
                -0.15482717752456665
            ],
            [
                -0.15632808208465576,
                -0.12384532392024994,
                -0.15571483969688416,
                -0.16117136180400848,
                -0.1174907386302948,
                -0.10489057749509811,
                -0.15061528980731964,
                -0.1419818252325058,
                -0.16944758594036102,
                -0.14570929110050201,
                -0.12930914759635925,
                -0.16838352382183075,
                -0.13569210469722748,
                -0.16333505511283875,
                -0.12973308563232422,
                -0.14072534441947937
            ],
            [
                -0.15331637859344482,
                -0.1411011517047882,
                -0.1582220196723938,
                -0.11401433497667313,
                -0.15020149946212769,
                -0.1627863049507141,
                -0.15046486258506775,
                -0.13526740670204163,
                -0.1427682489156723,
                -0.15105856955051422,
                -0.12880650162696838,
                -0.16036590933799744,
                -0.16962410509586334,
                -0.1696590930223465,
                -0.13287827372550964,
                -0.1421097368001938
            ],
            [
                -0.13992957770824432,
                -0.10239988565444946,
                -0.15381130576133728,
                -0.12165471911430359,
                -0.08887606114149094,
                -0.12626314163208008,
                -0.11689575761556625,
                -0.15337234735488892,
                -0.1410813182592392,
                -0.16704533994197845,
                -0.16977769136428833,
                -0.11851325631141663,
                -0.15769778192043304,
                -0.17002294957637787,
                -0.13831599056720734,
                -0.1700398027896881
            ],
            [
                -0.14945758879184723,
                -0.14098060131072998,
                -0.16997811198234558,
                -0.129557803273201,
                -0.15478402376174927,
                -0.1422143429517746,
                -0.1317218691110611,
                -0.09547621756792068,
                -0.11073180288076401,
                -0.16065393388271332,
                -0.1538752019405365,
                -0.16699714958667755,
                -0.15634888410568237,
                -0.16646507382392883,
                -0.1523628532886505,
                -0.16841547191143036
            ],
            [
                -0.1700216680765152,
                -0.16542480885982513,
                -0.16656723618507385,
                -0.1474636346101761,
                -0.17003661394119263,
                -0.16006800532341003,
                -0.16664175689220428,
                -0.16992677748203278,
                -0.16989313066005707,
                -0.16829116642475128,
                -0.1387353390455246,
                -0.16885586082935333,
                -0.16143052279949188,
                -0.1673843264579773,
                -0.16462165117263794,
                -0.16634874045848846
            ]
        ],
        [
            [
                0.049316443502902985,
                -0.07991784065961838,
                0.5398213863372803,
                -0.1286812275648117,
                0.13020674884319305,
                1.7938145399093628,
                -0.15593531727790833,
                0.1256156861782074,
                -0.13628064095973969,
                0.9921084046363831,
                2.746103525161743,
                2.6874306201934814,
                0.19075176119804382,
                2.2066705226898193,
                3.8713431358337402,
                0.5441771745681763
            ],
            [
                -0.16893233358860016,
                3.521369457244873,
                1.9359030723571777,
                1.1920247077941895,
                -0.17004069685935974,
                0.5073623657226562,
                3.451481819152832,
                -0.16675254702568054,
                -0.11625967919826508,
                -0.1055394858121872,
                0.2981182932853699,
                0.22103850543498993,
                -0.15977530181407928,
                0.12611067295074463,
                -0.0014857525238767266,
                2.7595529556274414
            ],
            [
                0.7959033846855164,
                3.386117696762085,
                0.4134182333946228,
                0.3552904427051544,
                -0.1611652970314026,
                -0.012650306336581707,
                -0.12830764055252075,
                -0.16023220121860504,
                0.9930127263069153,
                0.5386462211608887,
                1.7924528121948242,
                -0.15513859689235687,
                1.4474616050720215,
                0.4753216803073883,
                -0.14316028356552124,
                6.479124069213867
            ],
            [
                -0.16532926261425018,
                0.5602332353591919,
                -0.1471751630306244,
                1.6671375036239624,
                0.2732159197330475,
                -0.015776444226503372,
                0.620292067527771,
                0.025481732562184334,
                2.7068634033203125,
                0.8155196309089661,
                1.561447024345398,
                0.07934652268886566,
                1.8784340620040894,
                1.3318959474563599,
                0.09298455715179443,
                5.408942699432373
            ],
            [
                1.12698495388031,
                1.1342483758926392,
                0.6293380260467529,
                -0.04644224792718887,
                2.2000913619995117,
                0.3979094624519348,
                3.2240803241729736,
                -0.07187571376562119,
                -0.03607524558901787,
                1.424546718597412,
                2.8018908500671387,
                4.7030110359191895,
                -0.16198593378067017,
                2.0218567848205566,
                -0.041093602776527405,
                0.5959187150001526
            ],
            [
                0.00028538276092149317,
                0.8907009363174438,
                1.356884241104126,
                -0.15334011614322662,
                0.8910399675369263,
                0.44098302721977234,
                1.0349242687225342,
                1.7241466045379639,
                -0.14042657613754272,
                -0.1663287878036499,
                1.4657323360443115,
                3.0575857162475586,
                0.11263080686330795,
                -0.10797815769910812,
                0.27279600501060486,
                -0.13830949366092682
            ],
            [
                -0.12970392405986786,
                0.6989602446556091,
                -0.04517475888133049,
                1.135352611541748,
                1.279454231262207,
                2.1174585819244385,
                -0.15609712898731232,
                -0.1628543585538864,
                0.006257055327296257,
                1.2388856410980225,
                1.34491765499115,
                1.729678750038147,
                -0.16401119530200958,
                1.9128042459487915,
                0.8498584628105164,
                0.7424991726875305
            ],
            [
                -0.15587593615055084,
                2.1151998043060303,
                0.6422330141067505,
                3.1761727333068848,
                1.0248430967330933,
                -0.14988923072814941,
                3.1516573429107666,
                1.4098881483078003,
                0.3658256232738495,
                0.6301262974739075,
                0.4736515283584595,
                -0.16615132987499237,
                2.5225207805633545,
                -0.08927395194768906,
                2.364283800125122,
                -0.016409963369369507
            ],
            [
                -0.1009322851896286,
                0.6615342497825623,
                0.25586602091789246,
                0.3361585736274719,
                -0.09116010367870331,
                -0.05258089676499367,
                -0.07902827858924866,
                0.14631453156471252,
                0.9317112565040588,
                0.2029160112142563,
                0.12495559453964233,
                0.14745749533176422,
                -0.1476866900920868,
                -0.09706763923168182,
                -0.09135262668132782,
                -0.12822003662586212
            ],
            [
                0.4376097321510315,
                0.25685107707977295,
                -0.012862258590757847,
                0.017437797039747238,
                0.18752606213092804,
                0.07307200133800507,
                0.5334975719451904,
                0.44840577244758606,
                0.024519626051187515,
                0.13720887899398804,
                -0.05958074331283569,
                -0.0566118024289608,
                -0.08526583015918732,
                0.015180100686848164,
                0.13402794301509857,
                -0.049642033874988556
            ],
            [
                -0.09989439696073532,
                0.11229564994573593,
                0.08365555852651596,
                -0.1485985964536667,
                0.28798550367355347,
                0.28423959016799927,
                0.6630117297172546,
                0.2063065767288208,
                -0.03401757776737213,
                0.4667185842990875,
                0.03534238040447235,
                0.22395789623260498,
                0.15772099792957306,
                0.17474892735481262,
                0.5425757169723511,
                0.08445172011852264
            ],
            [
                0.018281517550349236,
                0.05395328253507614,
                0.1627148538827896,
                -0.11308759450912476,
                0.25452014803886414,
                0.006173093803226948,
                -0.11004982143640518,
                -0.049082059413194656,
                -0.038349222391843796,
                -0.0674530640244484,
                0.11372825503349304,
                0.2509974539279938,
                -0.16064688563346863,
                0.12733183801174164,
                0.5566565990447998,
                0.15734392404556274
            ],
            [
                0.38688403367996216,
                0.2670295238494873,
                0.054758042097091675,
                0.054967351257801056,
                0.12663552165031433,
                0.06639092415571213,
                -0.06198103725910187,
                -0.10183320194482803,
                -0.05516354739665985,
                -0.020888030529022217,
                0.13536985218524933,
                -0.03509032353758812,
                0.5634748339653015,
                0.0158280860632658,
                -0.015840940177440643,
                0.09237952530384064
            ],
            [
                0.09437789022922516,
                0.23009401559829712,
                0.0013479402987286448,
                0.23969431221485138,
                0.3786322772502899,
                0.0616559237241745,
                -0.13251471519470215,
                -0.09713614732027054,
                -0.11759115010499954,
                0.023616483435034752,
                0.4387013018131256,
                0.37105968594551086,
                0.182067409157753,
                0.06912776082754135,
                -0.10958962142467499,
                -0.15714626014232635
            ],
            [
                0.009298085235059261,
                -0.013674001209437847,
                0.15477187931537628,
                0.3495677709579468,
                0.5863105058670044,
                -0.0418514758348465,
                0.2921205759048462,
                0.12945662438869476,
                0.1429756134748459,
                0.09562086313962936,
                -0.016262812539935112,
                0.038135826587677,
                0.16808953881263733,
                -0.08588442951440811,
                0.3710682690143585,
                -0.16962483525276184
            ],
            [
                -0.024951601400971413,
                -0.0652521401643753,
                0.05944695696234703,
                -0.0014197699492797256,
                -0.06899498403072357,
                0.08930876106023788,
                0.0022143786773085594,
                0.01696314848959446,
                0.6091392636299133,
                0.0030708173289895058,
                0.10635527223348618,
                -0.029397856444120407,
                0.12702356278896332,
                0.3143824338912964,
                0.1901758760213852,
                -0.03577055409550667
            ]
        ],
        [
            [
                6.262214660644531,
                7.807503700256348,
                5.921962261199951,
                5.493945598602295,
                5.802384853363037,
                3.9380438327789307,
                3.3300061225891113,
                6.885826587677002,
                7.453218460083008,
                7.633470058441162,
                6.808786869049072,
                4.007901191711426,
                4.946938991546631,
                4.572470664978027,
                8.023696899414062,
                7.4900970458984375
            ],
            [
                4.137485980987549,
                4.704202651977539,
                5.491649627685547,
                3.103454828262329,
                3.200347661972046,
                3.4926531314849854,
                4.564731121063232,
                6.564522743225098,
                2.8483402729034424,
                4.271646022796631,
                3.8698086738586426,
                3.1934359073638916,
                5.416454792022705,
                4.575794696807861,
                4.979607582092285,
                7.324151515960693
            ],
            [
                6.754052639007568,
                3.6918931007385254,
                6.457195281982422,
                5.8897199630737305,
                5.581283092498779,
                4.034639835357666,
                4.828824996948242,
                3.392348527908325,
                7.049564838409424,
                5.270167827606201,
                6.226868629455566,
                5.50272274017334,
                8.197020530700684,
                9.562173843383789,
                6.955229759216309,
                9.302810668945312
            ],
            [
                4.888086318969727,
                4.75026798248291,
                2.8548717498779297,
                4.110755443572998,
                7.093242168426514,
                6.189874172210693,
                5.559138774871826,
                5.6849236488342285,
                8.153146743774414,
                7.831727027893066,
                5.8898844718933105,
                5.210049629211426,
                5.920139312744141,
                7.25169563293457,
                6.871898651123047,
                7.313290596008301
            ],
            [
                4.701934814453125,
                6.745993614196777,
                5.340412616729736,
                4.8181328773498535,
                5.110039234161377,
                1.6442514657974243,
                5.8098578453063965,
                6.3918046951293945,
                8.767494201660156,
                5.039004325866699,
                5.48892879486084,
                8.110489845275879,
                6.901983261108398,
                6.426322937011719,
                4.941468715667725,
                5.735042572021484
            ],
            [
                4.520120620727539,
                4.24240255355835,
                6.299853324890137,
                3.118415594100952,
                3.766305923461914,
                3.020512819290161,
                4.008944034576416,
                5.56624698638916,
                5.324324607849121,
                4.619754314422607,
                5.950325012207031,
                8.249407768249512,
                6.089741230010986,
                4.884936332702637,
                3.548055648803711,
                5.079991340637207
            ],
            [
                4.618405342102051,
                4.671064853668213,
                4.5034894943237305,
                3.3559181690216064,
                2.968677520751953,
                5.99558687210083,
                6.128915786743164,
                6.695477485656738,
                7.200054168701172,
                7.975154399871826,
                4.226576328277588,
                4.805676460266113,
                6.780866622924805,
                7.583454608917236,
                7.392560005187988,
                5.1520280838012695
            ],
            [
                6.463796615600586,
                4.551218032836914,
                6.136126518249512,
                5.87054967880249,
                4.80526876449585,
                4.4480977058410645,
                6.286602973937988,
                5.339620590209961,
                7.693652629852295,
                4.671501159667969,
                3.904600143432617,
                3.4432990550994873,
                2.6140024662017822,
                8.006525039672852,
                7.9449615478515625,
                5.225584983825684
            ],
            [
                0.7694386839866638,
                0.14480771124362946,
                0.32066968083381653,
                0.566443145275116,
                0.2580704391002655,
                0.46804067492485046,
                0.6050752997398376,
                0.46579673886299133,
                0.1850873976945877,
                -0.009710103273391724,
                0.7793705463409424,
                0.9754113554954529,
                1.0291322469711304,
                0.32290613651275635,
                0.5201183557510376,
                0.42358148097991943
            ],
            [
                0.8579917550086975,
                0.47730183601379395,
                0.4557653069496155,
                0.42160388827323914,
                0.717438280582428,
                0.9792551398277283,
                0.4459165632724762,
                0.626663327217102,
                0.7417297959327698,
                0.5405762195587158,
                0.4236588478088379,
                0.8230065107345581,
                0.575086236000061,
                0.6460257768630981,
                0.47615963220596313,
                0.48351871967315674
            ],
            [
                0.9634145498275757,
                0.4405873119831085,
                0.4389769732952118,
                0.5207460522651672,
                0.3807864785194397,
                0.40452292561531067,
                0.5355538129806519,
                0.8184888958930969,
                0.662086009979248,
                0.44181933999061584,
                0.715153694152832,
                0.6460241079330444,
                0.8771187663078308,
                0.6801713109016418,
                0.8210704326629639,
                0.8037325739860535
            ],
            [
                0.6284520626068115,
                0.6913467645645142,
                0.4740803837776184,
                0.3461369276046753,
                0.42720291018486023,
                0.459701806306839,
                0.6353234052658081,
                0.7391653060913086,
                0.5042925477027893,
                0.5388431549072266,
                0.6705280542373657,
                0.56886225938797,
                0.8081833720207214,
                0.6425423622131348,
                0.19697561860084534,
                0.1925559788942337
            ],
            [
                0.9186416268348694,
                0.5657640695571899,
                0.4065239727497101,
                0.31681081652641296,
                0.479885995388031,
                0.7183381915092468,
                0.8274902105331421,
                0.6203262805938721,
                0.8133527636528015,
                0.6598636507987976,
                0.674229085445404,
                0.7528039216995239,
                0.5210927724838257,
                0.9988284111022949,
                0.35439857840538025,
                0.2656424045562744
            ],
            [
                0.692513644695282,
                0.6943449974060059,
                0.26561662554740906,
                0.3755270540714264,
                0.7668007612228394,
                0.5418628454208374,
                0.5401191115379333,
                0.8491448163986206,
                0.8033154010772705,
                0.7692435383796692,
                0.6402475237846375,
                0.13352592289447784,
                0.8867130279541016,
                0.6937836408615112,
                0.4984404444694519,
                0.6645217537879944
            ],
            [
                1.0071935653686523,
                0.48958078026771545,
                0.47320231795310974,
                0.35542839765548706,
                0.5747149586677551,
                0.47798946499824524,
                0.7730436325073242,
                1.0836827754974365,
                0.4081834554672241,
                0.8691608905792236,
                0.47984522581100464,
                0.5467829704284668,
                0.7103020548820496,
                0.712287187576294,
                0.8432802557945251,
                0.7422951459884644
            ],
            [
                0.9910107851028442,
                0.7176547050476074,
                0.713750422000885,
                0.9271883964538574,
                0.5494282245635986,
                0.5123650431632996,
                0.70802241563797,
                0.8448493480682373,
                0.45409026741981506,
                0.5399634838104248,
                0.5472184419631958,
                0.6695405840873718,
                0.6197729110717773,
                0.8819602131843567,
                0.44862809777259827,
                0.5225121974945068
            ]
        ],
        [
            [
                0.05655570328235626,
                -0.14196716248989105,
                0.6988857388496399,
                -0.13875578343868256,
                -0.12560640275478363,
                -0.17003491520881653,
                -0.10864970088005066,
                -0.16947653889656067,
                0.8718188405036926,
                0.009685569442808628,
                -0.13208888471126556,
                -0.16561467945575714,
                -0.16451364755630493,
                -0.061442986130714417,
                1.5102291107177734,
                1.53371262550354
            ],
            [
                0.6717707514762878,
                -0.08843310177326202,
                0.1361880898475647,
                -0.007464596536010504,
                1.0579601526260376,
                1.1890380382537842,
                0.13915376365184784,
                1.2272526025772095,
                -0.11523192375898361,
                -0.13269567489624023,
                -0.1679852306842804,
                -0.05535503104329109,
                -0.1124204695224762,
                1.2351722717285156,
                -0.11611981689929962,
                0.2055789679288864
            ],
            [
                -0.16637729108333588,
                0.2620510756969452,
                0.4775645434856415,
                0.9907553195953369,
                2.7421255111694336,
                -0.02881774678826332,
                0.48858755826950073,
                -0.16264523565769196,
                1.2192176580429077,
                -0.14879490435123444,
                -0.08959110081195831,
                -0.16078877449035645,
                -0.02642926201224327,
                1.6462433338165283,
                0.22818498313426971,
                2.726902961730957
            ],
            [
                1.4766145944595337,
                -0.16386978328227997,
                0.21787112951278687,
                -0.14313575625419617,
                0.11121654510498047,
                -0.16887155175209045,
                -0.04702683165669441,
                -0.09060260653495789,
                0.17049361765384674,
                -0.1548343151807785,
                -0.1577971875667572,
                -0.1642238199710846,
                -0.1536118984222412,
                1.8688721656799316,
                1.9626528024673462,
                -0.15029655396938324
            ],
            [
                0.16873277723789215,
                0.33258143067359924,
                -0.1584896445274353,
                -0.16010338068008423,
                0.2696671187877655,
                -0.07379473000764847,
                0.21132276952266693,
                1.3704301118850708,
                1.4275685548782349,
                -0.16367775201797485,
                -0.1129920482635498,
                -0.164978489279747,
                -0.11264055222272873,
                0.5345580577850342,
                -0.15756715834140778,
                -0.1488497257232666
            ],
            [
                -0.1312737762928009,
                -0.09836458414793015,
                0.049086641520261765,
                -0.08580569922924042,
                1.1045000553131104,
                0.4515402019023895,
                0.9177163243293762,
                1.7053767442703247,
                -0.16411808133125305,
                0.16021087765693665,
                -0.11327840387821198,
                2.9531869888305664,
                0.36013156175613403,
                0.6857372522354126,
                -0.10458725690841675,
                -0.10600511729717255
            ],
            [
                -0.15026502311229706,
                -0.05408676713705063,
                0.5926805734634399,
                0.12212863564491272,
                -0.16403718292713165,
                1.467246651649475,
                -0.11153211444616318,
                -0.13416540622711182,
                0.7126125693321228,
                0.3428291082382202,
                -0.16031043231487274,
                0.16792549192905426,
                -0.08178991079330444,
                3.8245656490325928,
                2.1928188800811768,
                2.1990983486175537
            ],
            [
                -0.15625271201133728,
                -0.08927855640649796,
                0.528548002243042,
                -0.11116322875022888,
                -0.03302467241883278,
                0.9487066864967346,
                0.4991549253463745,
                0.7405009269714355,
                0.22651787102222443,
                0.16072311997413635,
                -0.16731807589530945,
                -0.044113341718912125,
                0.21852551400661469,
                2.8056154251098633,
                -0.038665276020765305,
                -0.13997946679592133
            ],
            [
                -0.15779823064804077,
                -0.10381058603525162,
                -0.15782488882541656,
                -0.1497928649187088,
                -0.16237153112888336,
                -0.16979636251926422,
                -0.16214683651924133,
                -0.1683358997106552,
                -0.10678712278604507,
                -0.16432081162929535,
                -0.15797361731529236,
                -0.1685175597667694,
                -0.14913587272167206,
                -0.15698453783988953,
                -0.16676458716392517,
                -0.15401314198970795
            ],
            [
                -0.1634938269853592,
                -0.16532309353351593,
                -0.1698402613401413,
                -0.16369569301605225,
                -0.15159884095191956,
                -0.10213962197303772,
                -0.16875560581684113,
                -0.1686953604221344,
                -0.12041792273521423,
                -0.15581826865673065,
                -0.14184202253818512,
                -0.1461109220981598,
                -0.17001831531524658,
                -0.16681547462940216,
                -0.1667269915342331,
                0.07641918957233429
            ],
            [
                -0.1664011925458908,
                -0.16779248416423798,
                -0.16286060214042664,
                -0.1630728542804718,
                -0.14951051771640778,
                -0.16389824450016022,
                -0.1408725529909134,
                -0.14731529355049133,
                -0.14896215498447418,
                -0.1609373539686203,
                -0.16704189777374268,
                -0.1699175238609314,
                -0.1700335294008255,
                -0.1623924821615219,
                -0.16962459683418274,
                -0.052073877304792404
            ],
            [
                -0.15574151277542114,
                -0.1437353789806366,
                -0.16656789183616638,
                -0.15366308391094208,
                -0.16789571940898895,
                -0.17003417015075684,
                -0.15582242608070374,
                -0.15220709145069122,
                -0.16006016731262207,
                -0.12098625302314758,
                -0.15646719932556152,
                -0.16498050093650818,
                -0.15823666751384735,
                -0.16637611389160156,
                -0.14724156260490417,
                -0.10491121560335159
            ],
            [
                -0.1699138730764389,
                -0.1700056940317154,
                -0.1697142869234085,
                -0.12896741926670074,
                -0.16449789702892303,
                -0.15164801478385925,
                -0.1536535918712616,
                -0.1618022471666336,
                -0.16099309921264648,
                -0.11614236980676651,
                -0.17001770436763763,
                -0.157987579703331,
                -0.10168886929750443,
                -0.16568465530872345,
                -0.09201155602931976,
                -0.16826848685741425
            ],
            [
                -0.16845650970935822,
                -0.1698550283908844,
                -0.15799367427825928,
                -0.1390007883310318,
                -0.14332690834999084,
                -0.1351255178451538,
                -0.15376213192939758,
                -0.13839435577392578,
                -0.16292031109333038,
                -0.163876473903656,
                -0.14169247448444366,
                -0.17004072666168213,
                -0.1380111426115036,
                -0.14591270685195923,
                -0.1535983681678772,
                -0.054358404129743576
            ],
            [
                -0.16782966256141663,
                -0.15038128197193146,
                -0.12707750499248505,
                -0.11805082857608795,
                -0.16329753398895264,
                -0.15269735455513,
                -0.16987492144107819,
                -0.1600319743156433,
                -0.14354859292507172,
                -0.16966195404529572,
                -0.16760039329528809,
                -0.10611706972122192,
                -0.16787295043468475,
                -0.1657172590494156,
                -0.16173741221427917,
                -0.1541094332933426
            ],
            [
                -0.1562802940607071,
                -0.11617247015237808,
                0.00765034556388855,
                -0.16623413562774658,
                -0.16696350276470184,
                -0.08457647264003754,
                0.036111731082201004,
                -0.07572664320468903,
                0.0500689297914505,
                -0.11496526002883911,
                -0.07387756556272507,
                -0.10078059881925583,
                -0.07469641417264938,
                -0.15739956498146057,
                -0.028911450877785683,
                0.032628413289785385
            ]
        ],
        [
            [
                1.5907487869262695,
                2.2303569316864014,
                1.699681043624878,
                1.2503069639205933,
                1.6797674894332886,
                1.8371851444244385,
                1.6113280057907104,
                0.5912467837333679,
                2.0870790481567383,
                3.012697458267212,
                1.328837275505066,
                1.948170781135559,
                1.3245726823806763,
                0.6915424466133118,
                3.610490083694458,
                1.031501293182373
            ],
            [
                1.5774264335632324,
                2.2064545154571533,
                0.020944900810718536,
                0.17775927484035492,
                1.8359771966934204,
                1.0053621530532837,
                0.01371910609304905,
                2.637641429901123,
                1.5962721109390259,
                -0.1459200084209442,
                0.005017983261495829,
                2.5967366695404053,
                0.1736995130777359,
                2.2762296199798584,
                3.660423517227173,
                1.9583390951156616
            ],
            [
                0.6068733334541321,
                2.1149463653564453,
                3.546480417251587,
                3.116792678833008,
                3.3750791549682617,
                1.605006217956543,
                1.7298686504364014,
                0.4991019070148468,
                2.730255365371704,
                0.8128625154495239,
                0.014866792596876621,
                3.078284978866577,
                2.7762374877929688,
                5.076201438903809,
                1.1029210090637207,
                0.8783422112464905
            ],
            [
                1.9668097496032715,
                0.6151602864265442,
                1.0007045269012451,
                2.325861930847168,
                3.9400272369384766,
                4.318624496459961,
                2.348036527633667,
                -0.0416538380086422,
                2.3274343013763428,
                2.0758469104766846,
                0.7035007476806641,
                1.0716968774795532,
                3.013063430786133,
                0.6348046660423279,
                2.919984817504883,
                0.1277020424604416
            ],
            [
                0.5656977891921997,
                1.5533220767974854,
                0.7946294546127319,
                -0.1673809140920639,
                0.82895827293396,
                1.7253063917160034,
                0.6354931592941284,
                4.012508392333984,
                3.207059144973755,
                2.483380079269409,
                0.012524086982011795,
                1.3732638359069824,
                0.28805121779441833,
                1.5974876880645752,
                0.6456266045570374,
                0.7877506613731384
            ],
            [
                1.7778863906860352,
                0.937995195388794,
                1.153935432434082,
                -0.1654030829668045,
                0.4039437472820282,
                2.441411018371582,
                3.287630796432495,
                1.845308542251587,
                1.3185713291168213,
                1.6605560779571533,
                1.3530701398849487,
                1.1820818185806274,
                2.330200433731079,
                3.955491542816162,
                0.674813985824585,
                -0.15132178366184235
            ],
            [
                0.5669767260551453,
                -0.16865257918834686,
                0.28869330883026123,
                1.1282154321670532,
                1.209377646446228,
                1.612311840057373,
                1.8332806825637817,
                1.4188898801803589,
                3.972015619277954,
                1.1114150285720825,
                3.4947328567504883,
                0.9176799058914185,
                2.4868059158325195,
                1.2821218967437744,
                0.8315617442131042,
                2.0318830013275146
            ],
            [
                1.5779738426208496,
                0.06500380486249924,
                2.1941967010498047,
                3.9104294776916504,
                -0.14025098085403442,
                1.8377628326416016,
                1.1289634704589844,
                1.767159104347229,
                2.4296345710754395,
                -0.1691151261329651,
                0.3044982850551605,
                0.037468187510967255,
                0.5852178931236267,
                3.9508700370788574,
                1.4086332321166992,
                -0.16977821290493011
            ],
            [
                0.26527848839759827,
                0.6308915019035339,
                0.916440486907959,
                0.6800544261932373,
                0.7240502238273621,
                0.50569748878479,
                0.7280011773109436,
                0.7302188873291016,
                0.7555269598960876,
                0.49037396907806396,
                0.665387749671936,
                0.8376572728157043,
                0.6000035405158997,
                1.0541303157806396,
                0.298419326543808,
                0.11490442603826523
            ],
            [
                0.17747020721435547,
                0.7869055271148682,
                0.588387131690979,
                0.1994927078485489,
                0.2839261293411255,
                0.2735632359981537,
                0.5911484956741333,
                0.7208358645439148,
                0.3227865397930145,
                0.6228013038635254,
                0.48040342330932617,
                0.17447002232074738,
                0.12176036834716797,
                0.6401904225349426,
                0.7428798079490662,
                0.3531840443611145
            ],
            [
                0.31528428196907043,
                0.0901365652680397,
                0.31702619791030884,
                0.6312240958213806,
                0.8120155930519104,
                0.6678096055984497,
                0.7196179628372192,
                0.6783862113952637,
                0.5993408560752869,
                0.4620063900947571,
                0.5081697702407837,
                0.5038485527038574,
                0.00565370311960578,
                0.3435039222240448,
                0.1526220589876175,
                0.2502744495868683
            ],
            [
                0.10512971878051758,
                0.9751062393188477,
                0.5570512413978577,
                0.3298710882663727,
                0.6535289883613586,
                1.0369073152542114,
                0.9929502606391907,
                0.28947657346725464,
                0.24699240922927856,
                0.9367321729660034,
                0.35769686102867126,
                0.8593191504478455,
                0.2215568572282791,
                0.4285009503364563,
                0.8907197117805481,
                0.7133607268333435
            ],
            [
                0.5580155849456787,
                0.4240577220916748,
                0.5216577053070068,
                1.4839637279510498,
                1.1639410257339478,
                0.5758777260780334,
                0.48027971386909485,
                0.389152467250824,
                0.6031537652015686,
                0.5230921506881714,
                0.7565420866012573,
                0.0765255019068718,
                0.11374720931053162,
                0.5925413966178894,
                0.665541410446167,
                0.26577073335647583
            ],
            [
                0.29352006316185,
                0.8961279392242432,
                0.2811163365840912,
                0.5768499374389648,
                1.0473297834396362,
                0.49191510677337646,
                0.7281209230422974,
                0.2081100195646286,
                0.5700516700744629,
                0.6258435845375061,
                0.3706396520137787,
                0.16012445092201233,
                0.24226664006710052,
                0.21013975143432617,
                0.5426726937294006,
                0.14002200961112976
            ],
            [
                0.20727910101413727,
                0.8065882921218872,
                0.456316202878952,
                0.25127527117729187,
                0.6124160289764404,
                0.8115005493164062,
                0.3958926498889923,
                0.43281400203704834,
                0.4170500934123993,
                0.29944708943367004,
                0.2906830906867981,
                0.567643404006958,
                0.5896695256233215,
                0.36952894926071167,
                1.2929494380950928,
                0.03161874786019325
            ],
            [
                0.28650203347206116,
                0.11912618577480316,
                0.2262486219406128,
                0.2257988452911377,
                0.2704748511314392,
                0.6809502243995667,
                0.4402494430541992,
                0.2583099901676178,
                0.5282506942749023,
                0.43694785237312317,
                0.18395334482192993,
                0.1836656779050827,
                0.40656355023384094,
                0.20664632320404053,
                0.15975575149059296,
                0.029331961646676064
            ]
        ],
        [
            [
                5.929787635803223,
                4.930012226104736,
                4.298198223114014,
                3.4765169620513916,
                5.3180012702941895,
                6.094962120056152,
                4.704391002655029,
                2.7652270793914795,
                4.905306816101074,
                4.001823902130127,
                4.680637359619141,
                4.187705039978027,
                2.9013712406158447,
                5.443665504455566,
                7.300289154052734,
                4.355769634246826
            ],
            [
                3.781994342803955,
                5.939697742462158,
                2.478952169418335,
                3.127068042755127,
                5.253464221954346,
                5.9156413078308105,
                4.652272701263428,
                4.498115062713623,
                2.618954658508301,
                2.9566524028778076,
                1.935103416442871,
                4.69227409362793,
                2.7711050510406494,
                6.728288650512695,
                3.6286087036132812,
                3.044053792953491
            ],
            [
                3.4040603637695312,
                6.447767734527588,
                4.755728721618652,
                4.395667552947998,
                5.822120189666748,
                4.505072116851807,
                5.9602203369140625,
                2.4356865882873535,
                3.732041597366333,
                2.2864458560943604,
                1.4567621946334839,
                4.549708843231201,
                3.758443593978882,
                9.224285125732422,
                4.476367473602295,
                4.316011905670166
            ],
            [
                7.338367462158203,
                3.8311548233032227,
                3.824894905090332,
                4.863124370574951,
                6.311002731323242,
                4.507394313812256,
                4.932541370391846,
                2.414890766143799,
                2.805291175842285,
                2.7401297092437744,
                2.235612630844116,
                3.69469952583313,
                2.0277771949768066,
                8.374361038208008,
                7.69870662689209,
                1.881080150604248
            ],
            [
                7.500218391418457,
                6.150975704193115,
                3.347053289413452,
                2.751042127609253,
                5.0031208992004395,
                5.640314102172852,
                4.970891952514648,
                7.020113468170166,
                5.200535774230957,
                3.3896565437316895,
                2.533015727996826,
                3.8330578804016113,
                2.964714765548706,
                4.535579681396484,
                2.8908069133758545,
                2.4422190189361572
            ],
            [
                4.181471347808838,
                4.357548236846924,
                5.960564613342285,
                2.554943323135376,
                3.9184837341308594,
                5.141983985900879,
                3.5063157081604004,
                5.234394550323486,
                4.078579902648926,
                3.5885021686553955,
                4.233583927154541,
                9.824634552001953,
                4.212904930114746,
                3.7778971195220947,
                4.32665491104126,
                2.1673433780670166
            ],
            [
                4.08478307723999,
                2.823272466659546,
                6.336894512176514,
                5.121445655822754,
                4.030980587005615,
                6.6184282302856445,
                6.419637203216553,
                3.7083845138549805,
                5.109195232391357,
                4.941659450531006,
                5.487517833709717,
                4.472501754760742,
                4.900705337524414,
                8.744155883789062,
                6.012666702270508,
                7.832590103149414
            ],
            [
                4.47261381149292,
                5.605403900146484,
                5.155127048492432,
                7.216481685638428,
                2.161834716796875,
                5.964992046356201,
                7.360915660858154,
                5.410951614379883,
                5.533108234405518,
                2.7776267528533936,
                3.6114799976348877,
                3.438002586364746,
                5.5435051918029785,
                9.956958770751953,
                8.201316833496094,
                0.9736394882202148
            ],
            [
                1.1548806428909302,
                1.1116358041763306,
                1.4064866304397583,
                1.2662458419799805,
                0.946622371673584,
                0.96540766954422,
                0.9310354590415955,
                1.2603857517242432,
                1.4654958248138428,
                1.4734574556350708,
                1.3956702947616577,
                1.2088552713394165,
                0.6962378025054932,
                1.197891116142273,
                0.5348122715950012,
                0.13480199873447418
            ],
            [
                1.4717369079589844,
                1.6010944843292236,
                1.7807272672653198,
                1.2718108892440796,
                0.6482920050621033,
                0.7482799887657166,
                1.3275047540664673,
                1.7596579790115356,
                1.0118907690048218,
                1.2262096405029297,
                0.8825391530990601,
                1.2138460874557495,
                1.2496743202209473,
                1.4471701383590698,
                1.3757582902908325,
                0.9734059572219849
            ],
            [
                1.2038723230361938,
                0.7106539607048035,
                1.2908580303192139,
                0.9215924739837646,
                0.9456554651260376,
                1.436785340309143,
                1.5945264101028442,
                1.7437187433242798,
                1.4754618406295776,
                1.2508153915405273,
                1.0566297769546509,
                0.948284387588501,
                0.5702291131019592,
                1.2772517204284668,
                1.0013774633407593,
                0.8168191909790039
            ],
            [
                1.2601956129074097,
                2.0392661094665527,
                1.1756612062454224,
                0.16299639642238617,
                0.5661560297012329,
                1.1994765996932983,
                1.4095237255096436,
                0.8582144379615784,
                0.6763684749603271,
                1.4597402811050415,
                0.9520702362060547,
                0.9799888134002686,
                0.6011782288551331,
                0.9513536691665649,
                1.551620364189148,
                1.3513809442520142
            ],
            [
                1.4642188549041748,
                1.7158061265945435,
                0.9773340821266174,
                1.3959758281707764,
                1.4866281747817993,
                0.8578624129295349,
                1.0786871910095215,
                0.9430622458457947,
                1.3051213026046753,
                0.9181758761405945,
                1.0459331274032593,
                0.6576807498931885,
                0.22255156934261322,
                0.8138473629951477,
                0.7804312705993652,
                1.0456634759902954
            ],
            [
                1.0950067043304443,
                1.3850994110107422,
                0.851665735244751,
                1.4906872510910034,
                1.8199273347854614,
                1.2679647207260132,
                1.1416486501693726,
                0.7152837514877319,
                0.5692341327667236,
                1.086095929145813,
                0.20152370631694794,
                0.40479013323783875,
                0.47000718116760254,
                0.27627530694007874,
                0.8236018419265747,
                0.38765010237693787
            ],
            [
                1.424460768699646,
                1.2013453245162964,
                0.8497307896614075,
                0.621484100818634,
                0.688439130783081,
                0.7627982497215271,
                0.9445657730102539,
                1.3328185081481934,
                1.566939115524292,
                0.8804451823234558,
                0.5459882616996765,
                0.5284833908081055,
                1.1630243062973022,
                0.924775242805481,
                0.27921757102012634,
                0.08920024335384369
            ],
            [
                1.347705364227295,
                0.9537269473075867,
                0.8512650728225708,
                0.7160794734954834,
                0.996865451335907,
                0.78729248046875,
                0.6613194942474365,
                0.7794671058654785,
                1.5251991748809814,
                1.1703498363494873,
                0.4695824384689331,
                1.0128947496414185,
                1.2118946313858032,
                1.4584152698516846,
                0.9254056215286255,
                0.28769585490226746
            ]
        ],
        [
            [
                3.539499521255493,
                5.1196136474609375,
                7.72805118560791,
                9.22343921661377,
                4.57590913772583,
                4.870367527008057,
                6.264369010925293,
                6.2510833740234375,
                6.052034854888916,
                4.359941482543945,
                5.9862775802612305,
                5.746620178222656,
                6.700645446777344,
                5.106997489929199,
                2.4827616214752197,
                4.820826530456543
            ],
            [
                6.728703022003174,
                4.338922023773193,
                5.900768280029297,
                9.508427619934082,
                6.0171380043029785,
                3.2365803718566895,
                3.949612617492676,
                8.051928520202637,
                7.842944145202637,
                5.717243671417236,
                6.01234769821167,
                5.457927703857422,
                5.206014156341553,
                4.6096625328063965,
                4.6592888832092285,
                7.074517250061035
            ],
            [
                8.356849670410156,
                4.348403453826904,
                4.1685471534729,
                3.2406251430511475,
                4.737107753753662,
                5.587034702301025,
                4.046708106994629,
                4.36829137802124,
                6.048802375793457,
                5.39472770690918,
                6.2832865715026855,
                6.5044074058532715,
                4.792496681213379,
                4.035017013549805,
                10.544448852539062,
                7.706903457641602
            ],
            [
                3.9579074382781982,
                6.645864009857178,
                6.792479991912842,
                5.626668453216553,
                4.600302219390869,
                5.435861110687256,
                4.4905571937561035,
                7.855659484863281,
                11.127842903137207,
                7.154942989349365,
                7.759250164031982,
                8.481426239013672,
                6.905426502227783,
                4.7261962890625,
                3.929187536239624,
                5.537592887878418
            ],
            [
                3.1003925800323486,
                5.277129650115967,
                3.7215096950531006,
                8.276814460754395,
                6.228490829467773,
                6.711111068725586,
                5.349628448486328,
                2.302366018295288,
                5.462057113647461,
                5.417164325714111,
                8.54030990600586,
                5.787203311920166,
                6.160831928253174,
                6.503873348236084,
                2.9895267486572266,
                6.36290168762207
            ],
            [
                5.351545810699463,
                6.579240322113037,
                2.524434804916382,
                7.194279193878174,
                4.018826007843018,
                2.5955941677093506,
                5.468989849090576,
                2.944911479949951,
                6.4865193367004395,
                7.408430576324463,
                5.022363662719727,
                4.223435878753662,
                4.231741905212402,
                3.4580495357513428,
                7.594775199890137,
                6.9456048011779785
            ],
            [
                5.674045562744141,
                6.448399066925049,
                2.343242883682251,
                6.161820888519287,
                5.124817848205566,
                4.091578006744385,
                5.742990493774414,
                4.3147454261779785,
                2.7074735164642334,
                6.991307258605957,
                6.551156044006348,
                5.418536186218262,
                5.649790287017822,
                8.571271896362305,
                7.954453468322754,
                5.021811008453369
            ],
            [
                5.717994213104248,
                4.082829475402832,
                4.9368577003479,
                10.893058776855469,
                2.9518768787384033,
                5.9657392501831055,
                4.9746832847595215,
                4.403323650360107,
                4.6458845138549805,
                8.840569496154785,
                7.356760025024414,
                5.984671592712402,
                6.490394592285156,
                4.95996618270874,
                9.607321739196777,
                8.118621826171875
            ],
            [
                1.2855201959609985,
                1.6227989196777344,
                1.1463385820388794,
                1.3714359998703003,
                1.530098557472229,
                1.7032817602157593,
                1.7053290605545044,
                1.5112818479537964,
                1.117559790611267,
                1.5766096115112305,
                1.3055106401443481,
                1.2396461963653564,
                1.8391965627670288,
                2.1744210720062256,
                2.5757956504821777,
                2.284116506576538
            ],
            [
                0.5593180060386658,
                1.0465984344482422,
                1.145394206047058,
                1.534838080406189,
                1.63894784450531,
                2.459332227706909,
                2.1910934448242188,
                0.8009929060935974,
                2.1288363933563232,
                2.079636335372925,
                1.529150128364563,
                1.4020092487335205,
                1.6674647331237793,
                1.2400002479553223,
                1.0706391334533691,
                0.9704525470733643
            ],
            [
                1.039011836051941,
                2.0059940814971924,
                1.6911805868148804,
                1.376185655593872,
                1.4445141553878784,
                1.2836123704910278,
                1.4374347925186157,
                1.181328535079956,
                1.3371738195419312,
                1.1202194690704346,
                1.372378945350647,
                1.9636749029159546,
                2.122467517852783,
                1.2935220003128052,
                1.0660717487335205,
                0.9148164391517639
            ],
            [
                1.1269936561584473,
                1.796813726425171,
                1.939041018486023,
                1.9110416173934937,
                1.6105577945709229,
                1.23250412940979,
                1.2200499773025513,
                1.4693790674209595,
                2.069042205810547,
                1.7649251222610474,
                1.7222291231155396,
                2.0111687183380127,
                2.300941228866577,
                1.6580740213394165,
                1.192428708076477,
                0.6014949679374695
            ],
            [
                0.9414510130882263,
                1.1448646783828735,
                1.5246185064315796,
                1.8666226863861084,
                1.7552813291549683,
                1.9306584596633911,
                1.403502345085144,
                1.444015622138977,
                1.7455960512161255,
                2.175558567047119,
                1.4637218713760376,
                1.7860914468765259,
                2.0744104385375977,
                1.8149434328079224,
                1.1850074529647827,
                0.8215571641921997
            ],
            [
                0.9028716087341309,
                1.6413111686706543,
                1.51186203956604,
                1.4521969556808472,
                0.9038838744163513,
                1.8185408115386963,
                1.4140044450759888,
                1.9145292043685913,
                1.8378095626831055,
                1.941019892692566,
                2.6627376079559326,
                1.6462781429290771,
                1.654906153678894,
                1.9894262552261353,
                1.6604374647140503,
                1.3577969074249268
            ],
            [
                0.7176240682601929,
                1.6009604930877686,
                1.4205927848815918,
                1.8700463771820068,
                1.890213131904602,
                1.757684350013733,
                1.5365076065063477,
                1.8296717405319214,
                1.2909706830978394,
                2.0213873386383057,
                2.5551233291625977,
                2.520469903945923,
                1.8932327032089233,
                1.9340157508850098,
                2.4237751960754395,
                2.2172133922576904
            ],
            [
                0.1762448251247406,
                1.072471261024475,
                1.0735427141189575,
                1.8223398923873901,
                1.7120730876922607,
                1.5996841192245483,
                1.889346957206726,
                1.3633230924606323,
                0.9927302598953247,
                1.5531411170959473,
                1.8017722368240356,
                1.3909039497375488,
                0.8505640029907227,
                1.3262125253677368,
                2.400831460952759,
                2.555333375930786
            ]
        ],
        [
            [
                0.12742333114147186,
                0.5713152885437012,
                0.19437547028064728,
                -0.1457507610321045,
                -0.1677120178937912,
                0.21085719764232635,
                -0.09139356762170792,
                0.47647812962532043,
                1.4805710315704346,
                -0.06710328906774521,
                0.26848679780960083,
                0.11263211071491241,
                0.40513476729393005,
                0.12254533916711807,
                -0.16823121905326843,
                0.441515177488327
            ],
            [
                0.14806392788887024,
                0.8248467445373535,
                0.6518803834915161,
                2.1913866996765137,
                0.46356651186943054,
                0.007797277066856623,
                0.5982423424720764,
                1.3883781433105469,
                0.5727850794792175,
                0.2786156237125397,
                0.8965437412261963,
                0.021629853174090385,
                0.23000821471214294,
                0.6480759978294373,
                1.3832118511199951,
                0.1395004838705063
            ],
            [
                0.6194742321968079,
                0.08493220061063766,
                0.9905722737312317,
                0.08510073274374008,
                0.048575252294540405,
                0.00236358935944736,
                0.9963710308074951,
                0.3791230618953705,
                0.009006275795400143,
                0.3653480112552643,
                0.4881550371646881,
                0.18706096708774567,
                0.9759748578071594,
                0.848723292350769,
                -0.14804869890213013,
                0.10778292268514633
            ],
            [
                0.8873022198677063,
                1.6031436920166016,
                0.2911459803581238,
                -0.15476009249687195,
                -0.1682543307542801,
                -0.12033502012491226,
                0.5333142280578613,
                0.5494825839996338,
                0.36182865500450134,
                0.8833557963371277,
                0.7606475949287415,
                0.32235655188560486,
                0.5733762383460999,
                1.2372634410858154,
                1.738224744796753,
                0.6954934000968933
            ],
            [
                0.025024734437465668,
                0.023269953206181526,
                0.6095074415206909,
                1.169177770614624,
                1.205074667930603,
                -0.08003160357475281,
                0.539818286895752,
                0.7458372712135315,
                1.0042093992233276,
                0.21403804421424866,
                0.7241464853286743,
                0.9604189991950989,
                0.31221145391464233,
                0.20767122507095337,
                0.26992014050483704,
                1.4007705450057983
            ],
            [
                0.7982093691825867,
                0.27616506814956665,
                0.20871177315711975,
                0.6408670544624329,
                0.7188617587089539,
                0.527432382106781,
                0.38728681206703186,
                0.293904185295105,
                0.4393146336078644,
                0.7124606966972351,
                0.7665207982063293,
                0.2813946008682251,
                0.0738886222243309,
                0.6530423164367676,
                -0.16969728469848633,
                0.17997124791145325
            ],
            [
                0.4609324038028717,
                0.41387471556663513,
                0.6984313130378723,
                0.10405336320400238,
                -0.13571152091026306,
                0.6282619833946228,
                1.1875053644180298,
                1.0063024759292603,
                0.8168156743049622,
                0.8685031533241272,
                0.12383245676755905,
                0.3369578719139099,
                0.033233392983675,
                0.33756139874458313,
                2.0034849643707275,
                0.19688227772712708
            ],
            [
                0.6708331108093262,
                0.02587640844285488,
                0.620851993560791,
                0.8184884190559387,
                1.4780603647232056,
                1.0038580894470215,
                1.2608070373535156,
                0.041961465030908585,
                0.8400843739509583,
                1.1805685758590698,
                0.8797452449798584,
                0.1151844933629036,
                0.5434869527816772,
                0.457283079624176,
                0.052780862897634506,
                0.2883022725582123
            ],
            [
                0.32672300934791565,
                0.408112496137619,
                0.48921307921409607,
                0.4827103316783905,
                0.8304844498634338,
                0.768252432346344,
                0.43992698192596436,
                0.4999603033065796,
                1.1554477214813232,
                0.7344417572021484,
                0.6109932065010071,
                0.4552462697029114,
                0.4225887954235077,
                0.7320288419723511,
                0.6937693953514099,
                0.30243200063705444
            ],
            [
                0.5056864023208618,
                0.4828563928604126,
                0.5790407657623291,
                0.67829829454422,
                0.8970615267753601,
                0.9320831894874573,
                0.25738248229026794,
                0.9300732016563416,
                0.6710522174835205,
                0.9015564322471619,
                0.8310154676437378,
                0.34971484541893005,
                0.472892165184021,
                0.6033116579055786,
                0.6601256728172302,
                0.21569056808948517
            ],
            [
                0.6335923075675964,
                1.0425864458084106,
                0.7507674694061279,
                1.2600597143173218,
                1.143972396850586,
                0.6395784616470337,
                0.7121425867080688,
                0.7296602725982666,
                0.873123824596405,
                0.6464099884033203,
                0.490677148103714,
                0.49544984102249146,
                0.7100496292114258,
                0.6994729042053223,
                0.41088801622390747,
                0.4826146364212036
            ],
            [
                0.33758702874183655,
                1.0021857023239136,
                1.1883968114852905,
                1.1948570013046265,
                0.7196909785270691,
                0.9059759378433228,
                0.5513610243797302,
                0.9400116205215454,
                0.9286222457885742,
                0.9979536533355713,
                0.9236679077148438,
                1.4019442796707153,
                0.8319586515426636,
                0.5746089220046997,
                0.8123257160186768,
                1.0059845447540283
            ],
            [
                0.5009772181510925,
                0.7562006115913391,
                0.9397897720336914,
                1.3905375003814697,
                1.1157642602920532,
                0.7521310448646545,
                0.9780116677284241,
                0.5594700574874878,
                0.6562694907188416,
                0.7598506808280945,
                0.6287724375724792,
                0.7942450642585754,
                0.856819748878479,
                0.8063148856163025,
                0.7394952178001404,
                0.5188353657722473
            ],
            [
                0.11568358540534973,
                1.0290559530258179,
                0.9974556565284729,
                0.48337066173553467,
                1.105736494064331,
                0.8205654621124268,
                1.1138055324554443,
                0.8350436091423035,
                0.7260107398033142,
                0.6988065242767334,
                0.7473858594894409,
                1.0728294849395752,
                0.8285463452339172,
                0.9129864573478699,
                0.47355836629867554,
                0.2425665408372879
            ],
            [
                0.44505107402801514,
                0.9775391221046448,
                1.2095928192138672,
                0.7144113779067993,
                0.90910404920578,
                0.925385594367981,
                0.7023736238479614,
                0.8104920983314514,
                0.7852133512496948,
                1.0295709371566772,
                0.9263737201690674,
                0.8098955750465393,
                0.7464078068733215,
                0.525556743144989,
                0.5360022187232971,
                0.42683953046798706
            ],
            [
                0.6496981382369995,
                0.29347091913223267,
                0.42487773299217224,
                0.23149973154067993,
                0.4777835011482239,
                0.9636667966842651,
                1.0086312294006348,
                0.5018266439437866,
                0.3882334530353546,
                0.5951136350631714,
                0.45121824741363525,
                0.4635535478591919,
                0.32017457485198975,
                0.3869064152240753,
                0.7596536874771118,
                0.7289494872093201
            ]
        ],
        [
            [
                -0.14550355076789856,
                -0.09765896201133728,
                -0.14807011187076569,
                -0.168906107544899,
                -0.1695861965417862,
                -0.1475311666727066,
                -0.07484976202249527,
                -0.14134690165519714,
                -0.16954925656318665,
                -0.1459391862154007,
                -0.16506271064281464,
                -0.15620440244674683,
                -0.08775759488344193,
                -0.1663324385881424,
                -0.05902440473437309,
                -0.15452535450458527
            ],
            [
                -0.16252346336841583,
                -0.015516615472733974,
                -0.02258739434182644,
                -0.04706290364265442,
                -0.10219944268465042,
                -0.0009619396878406405,
                -0.03231995552778244,
                -0.16284245252609253,
                -0.14909657835960388,
                -0.16396354138851166,
                -0.16252774000167847,
                -0.04942139983177185,
                -0.0022693369537591934,
                -0.011162460781633854,
                -0.007019218523055315,
                -0.17001815140247345
            ],
            [
                -0.07726987451314926,
                -0.006972122937440872,
                -0.045029159635305405,
                -0.00133329548407346,
                -0.01016922015696764,
                -0.02165774442255497,
                -0.13882675766944885,
                -0.0907747820019722,
                -0.030104920268058777,
                -0.09878992289304733,
                -0.12341205775737762,
                -0.16867311298847198,
                -0.043938640505075455,
                -0.0031593397725373507,
                -0.07182597368955612,
                -0.08066383749246597
            ],
            [
                -0.16549575328826904,
                -0.005210486706346273,
                -0.011736172251403332,
                -0.0031398218125104904,
                -0.04212179034948349,
                -0.0016162947285920382,
                -0.036295559257268906,
                -0.16802150011062622,
                -0.1341429501771927,
                -0.11571721732616425,
                -0.07106171548366547,
                -0.07487937062978745,
                -0.11119697988033295,
                -0.05313916504383087,
                -0.008503464050590992,
                -0.07451307773590088
            ],
            [
                -0.13291938602924347,
                -0.011551335453987122,
                -0.029192211106419563,
                -0.0600736029446125,
                -0.04187518730759621,
                -0.020953763276338577,
                -0.022949011996388435,
                -0.0377921536564827,
                -0.016061827540397644,
                -0.04232051596045494,
                -0.13553068041801453,
                -0.03394994139671326,
                -0.09482301771640778,
                -0.0572877861559391,
                -0.04822496697306633,
                -0.07789605110883713
            ],
            [
                -0.1651707887649536,
                -0.030711838975548744,
                -0.03228672221302986,
                -0.003647218458354473,
                -0.01424679160118103,
                -0.000054253567213891074,
                -0.13018707931041718,
                -0.11220310628414154,
                -0.05150694400072098,
                -0.013017376884818077,
                -0.012335271574556828,
                -0.001661123358644545,
                -0.0034001816529780626,
                -0.004056338686496019,
                -0.007905349135398865,
                -0.09564219415187836
            ],
            [
                -0.1613432765007019,
                -0.07588028162717819,
                -0.00003693858889164403,
                -0.0007529263966716826,
                -0.07561861723661423,
                -0.012156124226748943,
                -0.040391795337200165,
                -0.06616602838039398,
                -0.02761847898364067,
                -0.002103752689436078,
                -0.013428849168121815,
                -0.09650823473930359,
                -0.03283001482486725,
                -0.049041748046875,
                -0.0022413749247789383,
                -0.0053390334360301495
            ],
            [
                -0.1664952039718628,
                -0.0039324830286204815,
                -0.0009712711907923222,
                -0.008537755347788334,
                -0.11661835759878159,
                -0.1511460840702057,
                -0.01757064275443554,
                -0.10003486275672913,
                -0.03664017096161842,
                -0.11176159977912903,
                -0.1601046323776245,
                -0.08204012364149094,
                -0.009154957719147205,
                -0.06567906588315964,
                -0.11432020366191864,
                0.34756284952163696
            ],
            [
                0.5490463376045227,
                0.30069929361343384,
                0.5018162727355957,
                0.7612512707710266,
                0.4264257252216339,
                0.28366348147392273,
                0.13894884288311005,
                0.3350140452384949,
                0.6011868119239807,
                0.530306875705719,
                0.22373826801776886,
                0.38205981254577637,
                0.47292107343673706,
                0.5582326650619507,
                0.6014044284820557,
                0.34909021854400635
            ],
            [
                0.43055590987205505,
                0.3730335831642151,
                0.3701675832271576,
                0.38621702790260315,
                0.29824066162109375,
                0.2798137366771698,
                0.15081514418125153,
                0.4377328157424927,
                0.19625431299209595,
                0.267564982175827,
                0.20550286769866943,
                0.30033403635025024,
                0.31785503029823303,
                0.3806300461292267,
                0.37178361415863037,
                0.3529031574726105
            ],
            [
                0.14226028323173523,
                -0.01804853416979313,
                0.28239092230796814,
                0.08192461729049683,
                0.11532162129878998,
                0.3933210074901581,
                0.27838408946990967,
                0.31005096435546875,
                0.4225771427154541,
                0.4367065727710724,
                0.6529821753501892,
                0.3098866045475006,
                0.21120092272758484,
                0.3899230360984802,
                0.2903346121311188,
                0.37226369976997375
            ],
            [
                0.36009395122528076,
                0.6928016543388367,
                0.3631642162799835,
                0.1739865094423294,
                0.12128053605556488,
                0.19192557036876678,
                0.28949427604675293,
                0.23228897154331207,
                0.3296533524990082,
                0.3359370231628418,
                0.19234248995780945,
                0.21204428374767303,
                0.3681708872318268,
                0.4820851981639862,
                0.228370800614357,
                0.5153632760047913
            ],
            [
                0.5323134064674377,
                0.4302203059196472,
                0.2640664577484131,
                0.6106701493263245,
                0.3867341876029968,
                0.29956239461898804,
                0.2270118147134781,
                0.31091490387916565,
                0.41975414752960205,
                0.23168456554412842,
                0.10900180786848068,
                0.4105091094970703,
                0.07989819347858429,
                0.3500526249408722,
                0.4625154733657837,
                0.5657758116722107
            ],
            [
                0.11897590756416321,
                0.0583638921380043,
                0.21769502758979797,
                0.3348020315170288,
                0.46453332901000977,
                0.6679294109344482,
                0.21589085459709167,
                0.27594509720802307,
                0.1549178659915924,
                0.41610023379325867,
                0.21757543087005615,
                0.07863713055849075,
                0.3758845925331116,
                0.10387317836284637,
                0.3830702006816864,
                0.3780936598777771
            ],
            [
                0.4930851459503174,
                0.40855392813682556,
                0.5348349213600159,
                0.44879865646362305,
                0.08432753384113312,
                0.21074196696281433,
                0.2843196392059326,
                0.29318001866340637,
                0.438031941652298,
                0.3478448688983917,
                0.393698513507843,
                0.26279646158218384,
                0.3909340798854828,
                0.5313969254493713,
                0.2761046588420868,
                0.3087145686149597
            ],
            [
                0.682889461517334,
                0.4958166182041168,
                0.538017988204956,
                0.5869656801223755,
                0.6862159967422485,
                0.6317605376243591,
                0.5341756939888,
                0.3789255619049072,
                0.4819638431072235,
                0.6491992473602295,
                0.5757278800010681,
                0.4127442240715027,
                0.5359524488449097,
                0.6145179867744446,
                0.871820867061615,
                0.6498855352401733
            ]
        ],
        [
            [
                0.738223135471344,
                2.7383310794830322,
                -0.12289820611476898,
                1.712656855583191,
                0.9978064298629761,
                -0.10164259374141693,
                0.3660843074321747,
                2.9467904567718506,
                2.6700100898742676,
                1.2311376333236694,
                1.7674570083618164,
                0.23875246942043304,
                0.6593474745750427,
                0.7756115198135376,
                -0.1266242414712906,
                3.9501821994781494
            ],
            [
                1.079994797706604,
                -0.16630765795707703,
                -0.1567343920469284,
                -0.06445980072021484,
                -0.1229950413107872,
                -0.05173124000430107,
                -0.16120676696300507,
                0.23171855509281158,
                -0.1525832861661911,
                -0.09160168468952179,
                1.8159428834915161,
                -0.0998663678765297,
                0.6077682375907898,
                -0.1670953929424286,
                -0.04828605428338051,
                3.1589012145996094
            ],
            [
                2.207885503768921,
                0.2559790015220642,
                1.1236423254013062,
                0.03191773220896721,
                -0.09947479516267776,
                -0.09650767594575882,
                -0.004540718160569668,
                -0.047320157289505005,
                1.1378450393676758,
                2.5445895195007324,
                3.1201796531677246,
                0.08919927477836609,
                1.8045004606246948,
                0.6023178696632385,
                2.461332082748413,
                2.1974704265594482
            ],
            [
                0.47409045696258545,
                1.4636622667312622,
                -0.08215400576591492,
                -0.15133635699748993,
                -0.0755673199892044,
                0.04070398956537247,
                -0.16910618543624878,
                0.19853802025318146,
                3.7117397785186768,
                3.339003086090088,
                2.4506847858428955,
                1.9549907445907593,
                1.3370380401611328,
                -0.1628800630569458,
                0.2940613031387329,
                2.820157766342163
            ],
            [
                -0.10241663455963135,
                0.07624661177396774,
                -0.10397787392139435,
                -0.0689917653799057,
                0.12325464934110641,
                -0.02012314833700657,
                -0.16993573307991028,
                0.29953375458717346,
                3.2805562019348145,
                2.87856125831604,
                2.6668803691864014,
                0.36093670129776,
                0.611298680305481,
                0.5360787510871887,
                -0.13446404039859772,
                4.542796611785889
            ],
            [
                1.1257987022399902,
                0.644719660282135,
                -0.16080458462238312,
                -0.14649651944637299,
                0.47723573446273804,
                -0.15354755520820618,
                -0.12041454762220383,
                2.3197860717773438,
                1.9967846870422363,
                1.6899621486663818,
                -0.12212136387825012,
                -0.011390329338610172,
                -0.15933340787887573,
                0.7105661630630493,
                -0.09986669570207596,
                2.706148862838745
            ],
            [
                0.18044035136699677,
                -0.11485569179058075,
                -0.009924240410327911,
                -0.05782703682780266,
                -0.16620832681655884,
                0.4385799169540405,
                1.9039477109909058,
                3.1480624675750732,
                0.357267826795578,
                0.16985929012298584,
                0.1981355845928192,
                -0.13670659065246582,
                2.238973379135132,
                -0.10052016377449036,
                -0.10607104748487473,
                0.5580950975418091
            ],
            [
                0.7958620190620422,
                -0.0392029844224453,
                -0.0024403431452810764,
                -0.15320460498332977,
                1.7683926820755005,
                1.481823205947876,
                1.9070301055908203,
                -0.08113991469144821,
                0.41797494888305664,
                0.8197037577629089,
                -0.10253385454416275,
                0.2255881428718567,
                -0.046284254640340805,
                -0.0022788390051573515,
                2.0871975421905518,
                2.420464515686035
            ],
            [
                -0.13233616948127747,
                -0.09662563353776932,
                -0.17002318799495697,
                -0.13795030117034912,
                -0.15205954015254974,
                -0.16255128383636475,
                -0.16575302183628082,
                -0.09697109460830688,
                -0.15583042800426483,
                -0.05494298040866852,
                -0.15840406715869904,
                -0.1648143231868744,
                -0.16673801839351654,
                -0.16016149520874023,
                -0.16698208451271057,
                0.07938726246356964
            ],
            [
                -0.06416558474302292,
                -0.16952452063560486,
                -0.12074881047010422,
                -0.15276138484477997,
                -0.08564510941505432,
                -0.09682347625494003,
                -0.1427098512649536,
                -0.15604551136493683,
                -0.12645797431468964,
                -0.1359235793352127,
                -0.04817068204283714,
                -0.14699223637580872,
                -0.12290102243423462,
                -0.1594630628824234,
                -0.05746558681130409,
                -0.018293246626853943
            ],
            [
                -0.08923739939928055,
                -0.09587841480970383,
                -0.16996948421001434,
                -0.1453486531972885,
                -0.14592278003692627,
                -0.16871272027492523,
                -0.1700233668088913,
                -0.16786499321460724,
                -0.16942982375621796,
                -0.16640573740005493,
                -0.1390780806541443,
                -0.10282287746667862,
                -0.11544216424226761,
                -0.07806763052940369,
                -0.1214669868350029,
                0.13159610331058502
            ],
            [
                0.014461889863014221,
                -0.16128893196582794,
                -0.16969525814056396,
                -0.16160807013511658,
                -0.0926327183842659,
                -0.08201678842306137,
                -0.13984592258930206,
                -0.07565402239561081,
                -0.14182348549365997,
                -0.147359699010849,
                -0.048323892056941986,
                -0.16188907623291016,
                -0.09530597925186157,
                0.003606062615290284,
                -0.07602141052484512,
                -0.11887098848819733
            ],
            [
                -0.008500441908836365,
                -0.15437543392181396,
                -0.16989503800868988,
                -0.15881480276584625,
                -0.15124979615211487,
                -0.1458190381526947,
                -0.062144387513399124,
                -0.12103673815727234,
                -0.13119375705718994,
                -0.16306035220623016,
                -0.16774173080921173,
                -0.001693030702881515,
                -0.13936728239059448,
                -0.1012469083070755,
                -0.1385580152273178,
                -0.10655952244997025
            ],
            [
                -0.08872836828231812,
                -0.15285572409629822,
                -0.0777142196893692,
                -0.16300052404403687,
                -0.164913147687912,
                -0.16173700988292694,
                -0.13753817975521088,
                -0.12073396891355515,
                -0.1460915505886078,
                -0.14421099424362183,
                -0.043315522372722626,
                -0.1029905304312706,
                -0.1700345277786255,
                -0.07964001595973969,
                -0.12566673755645752,
                -0.04750308394432068
            ],
            [
                -0.08734942227602005,
                -0.1441078633069992,
                -0.11782797425985336,
                -0.0705258920788765,
                -0.16723033785820007,
                -0.09648013859987259,
                -0.11398284137248993,
                -0.09628520905971527,
                -0.15330764651298523,
                -0.15648053586483002,
                -0.04921887442469597,
                -0.14046044647693634,
                -0.15850186347961426,
                -0.1674259454011917,
                0.021622763946652412,
                -0.09987127035856247
            ],
            [
                0.05226265639066696,
                0.013793300837278366,
                -0.0310983769595623,
                -0.03334210813045502,
                -0.14222145080566406,
                -0.134128138422966,
                -0.13099603354930878,
                -0.05636661872267723,
                -0.10662102699279785,
                -0.04517800360918045,
                -0.11926388740539551,
                -0.08510632067918777,
                -0.14344558119773865,
                -0.061311863362789154,
                -0.1573321521282196,
                -0.006897362414747477
            ]
        ],
        [
            [
                1.8801929950714111,
                0.6705629825592041,
                -0.07804430276155472,
                0.70809406042099,
                0.6665538549423218,
                2.146543264389038,
                1.7831196784973145,
                1.8428725004196167,
                2.2851037979125977,
                1.035139799118042,
                0.8914674520492554,
                0.4274551272392273,
                1.8544026613235474,
                2.503244638442993,
                0.04204564914107323,
                1.4067394733428955
            ],
            [
                1.714280605316162,
                0.12013179808855057,
                0.6862800121307373,
                0.023469282314181328,
                1.3252997398376465,
                3.4977877140045166,
                1.800051212310791,
                1.8294092416763306,
                -0.1498923897743225,
                0.60645991563797,
                1.1040343046188354,
                1.7259420156478882,
                1.2742096185684204,
                2.2935831546783447,
                0.18658220767974854,
                0.5930616855621338
            ],
            [
                1.5297706127166748,
                0.29766714572906494,
                1.127585768699646,
                1.299946665763855,
                1.032199501991272,
                1.118069052696228,
                1.204580545425415,
                1.0848431587219238,
                0.6617594361305237,
                1.7756149768829346,
                1.5914952754974365,
                0.3650285005569458,
                1.2125728130340576,
                1.399917721748352,
                -0.04323442280292511,
                0.14265304803848267
            ],
            [
                2.419414520263672,
                2.7009098529815674,
                1.531711220741272,
                0.17275241017341614,
                0.28207260370254517,
                -0.0666457936167717,
                2.140204906463623,
                2.7521159648895264,
                1.4658031463623047,
                0.048250652849674225,
                1.3173035383224487,
                1.4274989366531372,
                0.41898614168167114,
                1.256087303161621,
                1.9875861406326294,
                1.314652919769287
            ],
            [
                1.7407282590866089,
                2.688542604446411,
                1.3303600549697876,
                0.32439225912094116,
                1.6717532873153687,
                1.016876459121704,
                0.366408109664917,
                1.7016932964324951,
                0.33220282196998596,
                0.44061270356178284,
                1.7510713338851929,
                1.072126865386963,
                0.5886397957801819,
                0.5579831600189209,
                -0.1203400269150734,
                1.5892337560653687
            ],
            [
                2.207587718963623,
                1.8606631755828857,
                1.8900772333145142,
                1.9168330430984497,
                1.4949966669082642,
                2.9249374866485596,
                2.177722215652466,
                1.9420584440231323,
                1.6713639497756958,
                1.4316099882125854,
                0.2521521747112274,
                -0.1694801300764084,
                0.59363853931427,
                0.21440304815769196,
                0.2561153173446655,
                1.5849186182022095
            ],
            [
                2.56217885017395,
                0.639625072479248,
                1.7955410480499268,
                2.317762613296509,
                1.2284892797470093,
                2.4223859310150146,
                1.9139094352722168,
                2.977367639541626,
                2.5259573459625244,
                0.44195687770843506,
                0.09163520485162735,
                0.1330871284008026,
                0.8043613433837891,
                -0.011899258010089397,
                2.6784584522247314,
                0.9190188646316528
            ],
            [
                1.939874291419983,
                1.496567726135254,
                0.21219462156295776,
                -0.1699543595314026,
                0.1985490322113037,
                3.2016873359680176,
                3.1496291160583496,
                1.9177274703979492,
                1.5575391054153442,
                1.6127294301986694,
                1.9567605257034302,
                1.6702569723129272,
                0.8548070192337036,
                -0.11261558532714844,
                -0.09582281857728958,
                0.7786487936973572
            ],
            [
                0.13345880806446075,
                -0.11169165372848511,
                -0.14859169721603394,
                -0.14438755810260773,
                -0.128268301486969,
                -0.1005188524723053,
                -0.07936742156744003,
                0.01619836315512657,
                -0.1694457083940506,
                -0.1664610505104065,
                -0.10683045536279678,
                -0.11533673107624054,
                -0.10488498210906982,
                -0.05130774900317192,
                -0.15379029512405396,
                -0.12715299427509308
            ],
            [
                -0.05005737021565437,
                -0.1622539758682251,
                -0.1496487855911255,
                -0.06682940572500229,
                -0.16167473793029785,
                -0.1681108921766281,
                -0.11425808817148209,
                -0.15450599789619446,
                -0.15805353224277496,
                -0.12440122663974762,
                -0.12641210854053497,
                -0.12285003066062927,
                -0.11119817942380905,
                -0.14567133784294128,
                -0.15597087144851685,
                -0.07724499702453613
            ],
            [
                -0.0005911217886023223,
                -0.14156728982925415,
                -0.13516613841056824,
                -0.07653382420539856,
                -0.14433501660823822,
                -0.16978466510772705,
                -0.15551245212554932,
                -0.14407795667648315,
                -0.129139244556427,
                -0.1605532467365265,
                -0.06822698563337326,
                0.049092765897512436,
                -0.007854645140469074,
                -0.17000339925289154,
                -0.09332958608865738,
                -0.06357135623693466
            ],
            [
                0.1573137640953064,
                -0.15007932484149933,
                -0.10438041388988495,
                -0.12240421026945114,
                -0.12275703251361847,
                -0.16823308169841766,
                -0.15432584285736084,
                -0.09892857074737549,
                -0.06603734195232391,
                -0.1679212898015976,
                -0.16018591821193695,
                -0.0495796762406826,
                -0.07490217685699463,
                -0.09386571496725082,
                -0.15615613758563995,
                -0.16948416829109192
            ],
            [
                -0.06948056817054749,
                -0.16855008900165558,
                -0.09607788920402527,
                -0.13965454697608948,
                -0.13579319417476654,
                -0.12796421349048615,
                -0.027021797373890877,
                -0.12311806529760361,
                -0.12439247220754623,
                -0.05564079433679581,
                -0.05918451398611069,
                -0.12916915118694305,
                -0.09204040467739105,
                -0.07770191878080368,
                -0.14604659378528595,
                -0.17004059255123138
            ],
            [
                -0.11013970524072647,
                -0.159740149974823,
                -0.1661956012248993,
                -0.12453209608793259,
                -0.17003852128982544,
                -0.15745602548122406,
                -0.14175869524478912,
                -0.12151661515235901,
                -0.14449194073677063,
                -0.07137572020292282,
                -0.02523803897202015,
                -0.10176467150449753,
                -0.13199558854103088,
                0.006125774700194597,
                -0.1243874654173851,
                -0.13093750178813934
            ],
            [
                0.006696571130305529,
                -0.08667352795600891,
                -0.01210867427289486,
                0.13498230278491974,
                -0.1466875821352005,
                -0.12815384566783905,
                -0.01753450557589531,
                -0.10687169432640076,
                -0.15416279435157776,
                -0.13096144795417786,
                -0.13298213481903076,
                -0.15168894827365875,
                -0.13648752868175507,
                -0.10239578038454056,
                -0.08159598708152771,
                -0.14788022637367249
            ],
            [
                -0.05998668819665909,
                -0.027567168697714806,
                -0.018684007227420807,
                -0.02170228585600853,
                -0.029445914551615715,
                -0.0878085196018219,
                0.043878234922885895,
                -0.07753721624612808,
                -0.04068031162023544,
                -0.012951686978340149,
                0.05800286680459976,
                -0.1267162263393402,
                -0.10196435451507568,
                -0.1123206838965416,
                -0.03850534185767174,
                -0.14178481698036194
            ]
        ],
        [
            [
                6.534822463989258,
                5.944183349609375,
                5.354399681091309,
                5.638391971588135,
                6.059763431549072,
                8.999576568603516,
                8.03481674194336,
                6.0351243019104,
                7.190036296844482,
                8.00144100189209,
                6.493765354156494,
                8.01280689239502,
                6.597011566162109,
                7.036928176879883,
                7.180283546447754,
                5.404112815856934
            ],
            [
                7.100730895996094,
                8.873367309570312,
                8.054914474487305,
                6.214042663574219,
                6.344122886657715,
                9.516950607299805,
                8.804887771606445,
                6.642186164855957,
                5.139410018920898,
                6.829021453857422,
                6.847576141357422,
                6.702418327331543,
                6.181097507476807,
                7.391289710998535,
                5.188094139099121,
                6.854047775268555
            ],
            [
                6.3822126388549805,
                7.441497325897217,
                6.257546901702881,
                6.813636302947998,
                7.169003486633301,
                6.8666534423828125,
                8.10212230682373,
                5.734478950500488,
                7.2289533615112305,
                7.473154544830322,
                6.308077335357666,
                7.629025459289551,
                8.065861701965332,
                8.211234092712402,
                5.628698825836182,
                7.268733978271484
            ],
            [
                6.842716693878174,
                6.521293640136719,
                6.726388931274414,
                7.444544315338135,
                7.458034992218018,
                6.526021480560303,
                8.941136360168457,
                6.8247785568237305,
                7.5798468589782715,
                6.043647766113281,
                7.64247465133667,
                7.133249282836914,
                6.872187614440918,
                7.80340051651001,
                7.785299301147461,
                7.375931739807129
            ],
            [
                7.98771333694458,
                9.467167854309082,
                6.97894811630249,
                6.331276893615723,
                7.455660820007324,
                7.754887580871582,
                7.355537414550781,
                8.096861839294434,
                6.444396495819092,
                7.361982345581055,
                6.5818963050842285,
                7.898200511932373,
                6.580480575561523,
                6.935122966766357,
                6.017737865447998,
                5.720914363861084
            ],
            [
                6.074778079986572,
                6.736965179443359,
                9.077123641967773,
                7.571139335632324,
                7.323825359344482,
                7.835822582244873,
                7.482609748840332,
                7.451854228973389,
                7.192169666290283,
                7.4506964683532715,
                8.030136108398438,
                6.73327112197876,
                7.129680156707764,
                6.9954729080200195,
                5.9386162757873535,
                6.5967278480529785
            ],
            [
                7.219021320343018,
                7.286196231842041,
                8.685309410095215,
                7.126317977905273,
                6.244302272796631,
                6.979310512542725,
                7.352527618408203,
                6.00793981552124,
                7.519078254699707,
                6.449324131011963,
                7.589408874511719,
                6.821285247802734,
                6.294369697570801,
                6.34832763671875,
                5.8188700675964355,
                8.459456443786621
            ],
            [
                6.779113292694092,
                8.779062271118164,
                7.284303665161133,
                4.555320739746094,
                4.889969348907471,
                6.604538440704346,
                9.185237884521484,
                7.057619094848633,
                5.867664337158203,
                6.0297393798828125,
                7.493852138519287,
                7.162479400634766,
                7.086064338684082,
                6.972640514373779,
                2.863027334213257,
                4.267708778381348
            ],
            [
                0.7676131129264832,
                0.16056261956691742,
                -0.11732854694128036,
                0.44847506284713745,
                0.5977482199668884,
                1.260818600654602,
                0.7581702470779419,
                0.6226006746292114,
                0.8799673914909363,
                0.2110891491174698,
                0.7973069548606873,
                0.9537405371665955,
                0.7493135333061218,
                0.6173815727233887,
                0.560844361782074,
                0.7972961664199829
            ],
            [
                0.976333498954773,
                0.44155770540237427,
                0.39557620882987976,
                0.7126216888427734,
                0.7565832138061523,
                1.0042744874954224,
                1.1447304487228394,
                0.8306634426116943,
                0.7309789061546326,
                1.0429372787475586,
                0.6952674388885498,
                0.7559548616409302,
                1.4867361783981323,
                0.957125723361969,
                0.777566134929657,
                0.34931808710098267
            ],
            [
                0.7753978371620178,
                0.4475363492965698,
                0.8367626667022705,
                0.6291273832321167,
                0.28046417236328125,
                0.6189272403717041,
                0.7940788269042969,
                1.2158000469207764,
                1.6122238636016846,
                0.8055942058563232,
                0.898338794708252,
                1.0885388851165771,
                1.5322492122650146,
                1.1213792562484741,
                0.7745680809020996,
                0.6616120934486389
            ],
            [
                1.315036654472351,
                1.0096700191497803,
                0.6971971392631531,
                0.5542416572570801,
                0.3779354691505432,
                0.7199912071228027,
                1.0458470582962036,
                0.8023011088371277,
                1.2624318599700928,
                0.8674885034561157,
                0.9447178840637207,
                1.5969654321670532,
                0.9333176016807556,
                0.9447969794273376,
                0.5986166596412659,
                -0.11486714333295822
            ],
            [
                1.1139930486679077,
                1.1402350664138794,
                0.6995305418968201,
                0.5566147565841675,
                0.3387734889984131,
                1.218413233757019,
                1.1296803951263428,
                0.9493945837020874,
                0.6003473401069641,
                0.9786445498466492,
                0.9904541969299316,
                0.7942283749580383,
                1.1705886125564575,
                0.9534973502159119,
                0.9377711415290833,
                0.17026041448116302
            ],
            [
                0.6217949986457825,
                0.5614819526672363,
                0.43186211585998535,
                0.9209599494934082,
                0.4672216773033142,
                0.7397959232330322,
                1.3907665014266968,
                1.3495872020721436,
                1.228100061416626,
                1.411128282546997,
                0.5444139838218689,
                0.0897132158279419,
                0.7513081431388855,
                0.9987480640411377,
                0.7231876850128174,
                0.32482463121414185
            ],
            [
                0.9378334879875183,
                1.1888456344604492,
                1.0407841205596924,
                0.869886577129364,
                0.3611862361431122,
                1.114149808883667,
                1.3378549814224243,
                1.5306755304336548,
                1.0795942544937134,
                0.8566640615463257,
                0.616868793964386,
                0.5275665521621704,
                0.9287618398666382,
                0.858351469039917,
                0.4604969918727875,
                0.4488975703716278
            ],
            [
                1.0324429273605347,
                1.440645694732666,
                1.159529447555542,
                0.38471007347106934,
                0.5126150846481323,
                0.9274799227714539,
                0.7694966793060303,
                1.4703521728515625,
                0.9249043464660645,
                0.8524419665336609,
                1.4084833860397339,
                0.7286884784698486,
                1.1219335794448853,
                0.35473525524139404,
                0.9773521423339844,
                0.3303612172603607
            ]
        ],
        [
            [
                2.5059945583343506,
                2.023102283477783,
                2.801427125930786,
                2.383725881576538,
                3.3891849517822266,
                3.115882396697998,
                2.790106773376465,
                2.195077419281006,
                2.567603826522827,
                2.701467990875244,
                1.821805477142334,
                2.2250945568084717,
                1.0447639226913452,
                3.1044085025787354,
                2.3679325580596924,
                3.3622095584869385
            ],
            [
                0.41250061988830566,
                1.8507317304611206,
                -0.0516226589679718,
                0.2940919101238251,
                0.2526920735836029,
                2.209292411804199,
                2.286898136138916,
                0.49090340733528137,
                -0.16048432886600494,
                -0.07097329199314117,
                0.35113245248794556,
                0.6320074200630188,
                -0.15841032564640045,
                1.4458856582641602,
                -0.1643717736005783,
                0.6362696290016174
            ],
            [
                -0.10316778719425201,
                0.31722068786621094,
                1.137819528579712,
                0.23783306777477264,
                -0.1321849375963211,
                0.05509744584560394,
                0.47714030742645264,
                -0.16208194196224213,
                1.5504311323165894,
                1.7646924257278442,
                1.0387394428253174,
                3.1395487785339355,
                2.6296067237854004,
                0.39402127265930176,
                -0.05395854264497757,
                3.2975354194641113
            ],
            [
                1.456923007965088,
                -0.09556157886981964,
                -0.1653871387243271,
                -0.13761386275291443,
                0.14876948297023773,
                0.49231433868408203,
                0.9646273255348206,
                -0.10538336634635925,
                0.7914168834686279,
                0.3982356786727905,
                1.9107043743133545,
                0.0360577218234539,
                1.9831774234771729,
                3.795687198638916,
                0.896692156791687,
                1.7159379720687866
            ],
            [
                2.8582279682159424,
                2.0104339122772217,
                0.008116964250802994,
                -0.15331795811653137,
                -0.05158218741416931,
                -0.03490472584962845,
                0.2750394344329834,
                0.9329735636711121,
                0.013485358096659184,
                1.061704158782959,
                0.5731686353683472,
                1.835944652557373,
                -0.11503779888153076,
                0.08652351051568985,
                0.4942624568939209,
                1.3887919187545776
            ],
            [
                1.2589001655578613,
                0.5629567503929138,
                1.2125248908996582,
                0.200990229845047,
                0.5940910577774048,
                0.971766471862793,
                0.9785228371620178,
                1.8391791582107544,
                1.5777719020843506,
                -0.08152260631322861,
                0.2617201805114746,
                4.381466388702393,
                -0.12486139684915543,
                -0.16614139080047607,
                -0.03777601197361946,
                1.368699312210083
            ],
            [
                0.5195775032043457,
                -0.14135481417179108,
                0.4800621271133423,
                0.09128527343273163,
                1.2449129819869995,
                1.510270595550537,
                1.6989927291870117,
                0.5222737193107605,
                1.638266921043396,
                0.019783182069659233,
                1.2905924320220947,
                0.027833884581923485,
                -0.14311504364013672,
                3.158785581588745,
                1.8338580131530762,
                3.236076593399048
            ],
            [
                0.2875036597251892,
                1.9575471878051758,
                0.03204511106014252,
                1.2801876068115234,
                0.5459538698196411,
                0.444325715303421,
                2.4673008918762207,
                1.3954359292984009,
                0.060971010476350784,
                0.3558884561061859,
                2.1087987422943115,
                0.16367566585540771,
                -0.1385541409254074,
                1.7188448905944824,
                0.05918050557374954,
                0.6319368481636047
            ],
            [
                0.09102138876914978,
                0.01185669843107462,
                0.3098718822002411,
                -0.00800009910017252,
                -0.14023025333881378,
                -0.08020289987325668,
                0.35762354731559753,
                0.08555740118026733,
                -0.11952480673789978,
                0.06100232154130936,
                -0.01971016451716423,
                0.00593602005392313,
                -0.06840935349464417,
                -0.1676783412694931,
                -0.16527974605560303,
                -0.0098138228058815
            ],
            [
                0.2145952433347702,
                -0.004801299422979355,
                0.12393223494291306,
                -0.013300114311277866,
                -0.01723254658281803,
                -0.06650320440530777,
                0.09391782432794571,
                0.09324684739112854,
                0.053490906953811646,
                0.14005351066589355,
                0.04612012952566147,
                -0.05304857715964317,
                -0.029994364827871323,
                0.09397745877504349,
                -0.0007386421784758568,
                0.06802650541067123
            ],
            [
                0.1698765605688095,
                0.2227548360824585,
                0.04131964221596718,
                0.01916973665356636,
                0.4236198663711548,
                -0.00007266453030752018,
                0.4778040051460266,
                0.17949187755584717,
                0.13346163928508759,
                0.3906331956386566,
                0.13188254833221436,
                0.18885788321495056,
                -0.023298168554902077,
                0.26178547739982605,
                0.044946834444999695,
                0.36484089493751526
            ],
            [
                0.351603627204895,
                -0.017899073660373688,
                -0.09738516062498093,
                -0.1306302398443222,
                -0.056963708251714706,
                0.3029249310493469,
                0.147634819149971,
                0.09765161573886871,
                0.006174400914460421,
                0.32514458894729614,
                -0.011754117906093597,
                -0.019027989357709885,
                0.012174068950116634,
                -0.012040861882269382,
                0.1962098628282547,
                0.2554991543292999
            ],
            [
                0.11492174118757248,
                0.2265339344739914,
                -0.07225964963436127,
                0.023393046110868454,
                0.2413700371980667,
                -0.07963437587022781,
                0.04803655669093132,
                0.020783793181180954,
                0.017696404829621315,
                -0.03937803581357002,
                0.8102640509605408,
                0.4875275790691376,
                -0.08723925799131393,
                0.05421101301908493,
                0.021246999502182007,
                0.494406521320343
            ],
            [
                0.038552772253751755,
                0.3305031955242157,
                0.1559421867132187,
                0.16941289603710175,
                0.08329388499259949,
                0.06406332552433014,
                0.0389162078499794,
                -0.07818198204040527,
                0.0037150189746171236,
                -0.11462492495775223,
                -0.1537483185529709,
                0.47213661670684814,
                0.2950514256954193,
                -0.06318069994449615,
                0.24528488516807556,
                -0.01773008331656456
            ],
            [
                0.22040441632270813,
                -0.017409468069672585,
                0.050716374069452286,
                0.3713586628437042,
                0.2408219426870346,
                0.08245545625686646,
                0.27069735527038574,
                0.001280320226214826,
                0.05896500498056412,
                0.008748436346650124,
                0.0495774932205677,
                -0.15800271928310394,
                -0.0589178204536438,
                -0.05004218965768814,
                -0.07951971143484116,
                -0.13849689066410065
            ],
            [
                0.041086483746767044,
                0.05049533769488335,
                -0.11947771906852722,
                -0.027484077960252762,
                0.14154104888439178,
                -0.056863486766815186,
                -0.13518278300762177,
                -0.03861874341964722,
                -0.047691650688648224,
                -0.05988350138068199,
                -0.16984207928180695,
                -0.07521986216306686,
                0.033672939985990524,
                -0.009893260896205902,
                -0.0850059911608696,
                -0.10052299499511719
            ]
        ],
        [
            [
                -0.15777035057544708,
                0.5296623706817627,
                -0.02554595284163952,
                -0.1628524214029312,
                -0.16626057028770447,
                -0.023906858637928963,
                -0.15100689232349396,
                1.4929279088974,
                0.4624063968658447,
                -0.16329999268054962,
                0.4010254144668579,
                -0.1118285283446312,
                -0.12189207971096039,
                -0.12912504374980927,
                -0.000654077623039484,
                2.881371021270752
            ],
            [
                -0.11955959349870682,
                -0.012248936109244823,
                -0.015429514460265636,
                -0.15892215073108673,
                -0.13954392075538635,
                -0.1148587167263031,
                -0.16350996494293213,
                -0.00100662128534168,
                -0.13641417026519775,
                0.3883627653121948,
                0.789679229259491,
                0.20430396497249603,
                -0.1308082491159439,
                -0.0541064627468586,
                -0.0062821595929563046,
                2.214895486831665
            ],
            [
                0.5054779052734375,
                -0.05442008748650551,
                0.19023950397968292,
                0.20847098529338837,
                -0.012797661125659943,
                -0.1449042111635208,
                0.5603400468826294,
                -0.16321507096290588,
                -0.15290607511997223,
                2.754535675048828,
                2.7739181518554688,
                -0.12435216456651688,
                -0.10282015800476074,
                -0.00001279589287150884,
                -0.01741841621696949,
                0.29195794463157654
            ],
            [
                0.9399024844169617,
                -0.14853139221668243,
                -0.03295406326651573,
                -0.0008444816921837628,
                -0.000202906783670187,
                -0.003215865232050419,
                -0.09756132960319519,
                -0.16618342697620392,
                1.7548047304153442,
                1.5101141929626465,
                0.7776363492012024,
                0.23168188333511353,
                -0.09668590128421783,
                -0.04236438870429993,
                -0.06191467493772507,
                1.5448518991470337
            ],
            [
                -0.08732043951749802,
                -0.08770351856946945,
                -0.1670091152191162,
                -0.059936556965112686,
                -0.003151026088744402,
                0,
                -0.012404837645590305,
                -0.16239091753959656,
                0.398650586605072,
                0.11620313674211502,
                -0.00006140166806289926,
                -0.15915587544441223,
                -0.1667547971010208,
                -0.13778746128082275,
                -0.08255107700824738,
                2.3370184898376465
            ],
            [
                1.3570258617401123,
                -0.1541617512702942,
                -0.16744963824748993,
                -0.013307255692780018,
                -0.1410921961069107,
                -0.1045256108045578,
                -0.16829416155815125,
                0.8621812462806702,
                1.687733769416809,
                -0.11480449885129929,
                -0.16261638700962067,
                -0.0000010016325404649251,
                -0.06726435571908951,
                -0.015140591189265251,
                -0.0509251169860363,
                1.6237038373947144
            ],
            [
                -0.16298481822013855,
                -0.14549465477466583,
                -0.142410010099411,
                -0.1365865021944046,
                0.25849199295043945,
                -0.15675342082977295,
                1.0307040214538574,
                1.5910128355026245,
                -0.11210694909095764,
                -0.03009149059653282,
                -0.01038422342389822,
                -0.030680369585752487,
                -0.13326838612556458,
                -0.0013903583167120814,
                -0.15308812260627747,
                -0.14755798876285553
            ],
            [
                1.0926467180252075,
                -0.07994432747364044,
                -0.015480183996260166,
                -0.06794663518667221,
                2.8117663860321045,
                2.451756477355957,
                -0.169366255402565,
                -0.03101075254380703,
                -0.034061431884765625,
                0.934634268283844,
                0.7203237414360046,
                1.7242872714996338,
                -0.05549243465065956,
                -4.357952718692104e-7,
                -0.009178492240607738,
                1.7871062755584717
            ],
            [
                1.2650809288024902,
                0.5915040373802185,
                1.2182793617248535,
                1.7760486602783203,
                1.483768343925476,
                1.3794559240341187,
                1.7698848247528076,
                1.2793385982513428,
                0.47938039898872375,
                0.9010351300239563,
                1.356361746788025,
                1.210549235343933,
                1.5720778703689575,
                1.1075718402862549,
                1.4427430629730225,
                1.6345494985580444
            ],
            [
                1.0025856494903564,
                1.5620115995407104,
                0.8612088561058044,
                1.4744747877120972,
                1.502429723739624,
                1.242550253868103,
                1.5942481756210327,
                0.9734663367271423,
                1.3268049955368042,
                1.4463095664978027,
                1.5239473581314087,
                1.7387410402297974,
                1.6922484636306763,
                1.4258089065551758,
                1.6807185411453247,
                1.6568251848220825
            ],
            [
                1.4248309135437012,
                1.50985586643219,
                1.3914023637771606,
                1.3945567607879639,
                1.391819953918457,
                1.2405530214309692,
                1.446102261543274,
                1.383855938911438,
                1.4597597122192383,
                1.1787558794021606,
                1.2827966213226318,
                1.5953928232192993,
                1.2448229789733887,
                1.4182655811309814,
                1.6868478059768677,
                1.6105451583862305
            ],
            [
                1.2298460006713867,
                1.0247774124145508,
                1.4348169565200806,
                1.038796067237854,
                1.1972488164901733,
                1.0329080820083618,
                1.2026517391204834,
                1.6198019981384277,
                1.4056992530822754,
                1.1788324117660522,
                1.3778865337371826,
                1.4265087842941284,
                1.922805905342102,
                1.2760884761810303,
                1.1549338102340698,
                1.4857453107833862
            ],
            [
                1.437758207321167,
                1.5232583284378052,
                1.5163323879241943,
                1.3056988716125488,
                1.0336270332336426,
                1.2444008588790894,
                1.460701584815979,
                1.4039559364318848,
                1.4203379154205322,
                1.4707469940185547,
                1.3984378576278687,
                1.287766695022583,
                1.6756651401519775,
                1.998565673828125,
                0.8616464734077454,
                0.7697522044181824
            ],
            [
                1.3742868900299072,
                1.27593994140625,
                1.011541485786438,
                1.498143196105957,
                0.8283218145370483,
                1.594362735748291,
                1.4877573251724243,
                1.3890968561172485,
                1.695186972618103,
                1.6225963830947876,
                1.686708927154541,
                1.337985873222351,
                0.9967666268348694,
                1.7876604795455933,
                1.4230774641036987,
                1.1718943119049072
            ],
            [
                1.2554209232330322,
                1.427340030670166,
                1.5512328147888184,
                1.5386508703231812,
                1.110189437866211,
                1.461888313293457,
                1.494415283203125,
                1.3793976306915283,
                1.2129815816879272,
                1.5310583114624023,
                1.3355777263641357,
                1.2612017393112183,
                1.6144413948059082,
                1.4342565536499023,
                1.3844938278198242,
                1.058275818824768
            ],
            [
                1.131514072418213,
                1.4401112794876099,
                1.8547093868255615,
                1.581632137298584,
                1.2651152610778809,
                1.267162561416626,
                1.4055147171020508,
                1.7237801551818848,
                1.7057794332504272,
                1.4309226274490356,
                1.5387916564941406,
                1.228914737701416,
                1.464711308479309,
                1.193920373916626,
                1.9199166297912598,
                1.2427468299865723
            ]
        ],
        [
            [
                -0.1502607762813568,
                -0.11384090781211853,
                -0.0661681666970253,
                -0.05571582168340683,
                -0.07047875225543976,
                -0.12138732522726059,
                -0.07837145775556564,
                -0.07902128994464874,
                0.5162439942359924,
                -0.08423099666833878,
                -0.05654421076178551,
                -0.13198693096637726,
                -0.12601904571056366,
                -0.012552795931696892,
                1.413682460784912,
                -0.0739293172955513
            ],
            [
                -0.08893075585365295,
                -0.11436181515455246,
                -0.015685832127928734,
                -0.14092715084552765,
                -0.15397024154663086,
                -0.13073265552520752,
                -0.1221805140376091,
                -0.1629508137702942,
                2.3411967754364014,
                -0.13616631925106049,
                -0.008146504871547222,
                0.34846219420433044,
                -0.15338988602161407,
                1.3343976736068726,
                -0.15573947131633759,
                -0.08106566965579987
            ],
            [
                -0.022640787065029144,
                -0.16143587231636047,
                0.10814809054136276,
                0.9014700055122375,
                0.1511615514755249,
                0.173739954829216,
                0.3903963267803192,
                -0.16987821459770203,
                0.12190161645412445,
                -0.10730563849210739,
                -0.008963193744421005,
                0.05131024494767189,
                0.3164481520652771,
                -0.16228368878364563,
                -0.05041966214776039,
                1.8395825624465942
            ],
            [
                0.07509392499923706,
                -0.04355824738740921,
                -0.17002713680267334,
                0.7302234768867493,
                -0.03300794959068298,
                4.286247730255127,
                0.7642969489097595,
                -0.02496168203651905,
                0.052928950637578964,
                0.4826697111129761,
                -0.008272821083664894,
                -0.04639831930398941,
                -0.04865790903568268,
                -0.1636706441640854,
                -0.07230891287326813,
                -0.1457480937242508
            ],
            [
                -0.15297530591487885,
                0.4173262417316437,
                -0.1141839474439621,
                -0.006981418002396822,
                -0.16333314776420593,
                -0.07541538774967194,
                -0.16447634994983673,
                -0.048562854528427124,
                0.3796879053115845,
                -0.0664726197719574,
                -0.04000309482216835,
                -0.16809670627117157,
                -0.13627742230892181,
                -0.14952674508094788,
                -0.08069911599159241,
                -0.09190604090690613
            ],
            [
                0.11043065041303635,
                -0.1597805917263031,
                -0.14279809594154358,
                -0.03487568721175194,
                -0.1296008974313736,
                0.09885428845882416,
                -0.1642368882894516,
                0.8966415524482727,
                0.7391326427459717,
                -0.15654119849205017,
                -0.16302832961082458,
                -0.07761148363351822,
                2.5432345867156982,
                -0.10620550811290741,
                0.47340694069862366,
                -0.013772806152701378
            ],
            [
                0.7573354840278625,
                0.6120745539665222,
                -0.09939858317375183,
                -0.13056521117687225,
                -0.1279107928276062,
                1.2843272686004639,
                -0.16475845873355865,
                -0.15990953147411346,
                -0.16489925980567932,
                -0.16142700612545013,
                -0.16789710521697998,
                -0.1393953263759613,
                -0.09984410554170609,
                2.0955464839935303,
                1.2888251543045044,
                0.08288437128067017
            ],
            [
                0.2780814468860626,
                -0.13602007925510406,
                0.17622888088226318,
                0.6781452894210815,
                -0.06740505993366241,
                1.0643010139465332,
                -0.12700343132019043,
                -0.09136102348566055,
                0.70783931016922,
                -0.075871542096138,
                -0.16233497858047485,
                0.11624033749103546,
                -0.15409688651561737,
                -0.056367695331573486,
                -0.15485553443431854,
                -0.026749297976493835
            ],
            [
                0.0336042158305645,
                0.5536636710166931,
                0.7611771821975708,
                0.28962138295173645,
                0.3055429756641388,
                0.11931400001049042,
                0.2044677585363388,
                0.16536326706409454,
                0.657981276512146,
                0.5774929523468018,
                0.3628494441509247,
                0.0013046941021457314,
                0.03678404167294502,
                0.15716694295406342,
                -0.09495418518781662,
                0.16862009465694427
            ],
            [
                0.07277141511440277,
                0.3983615040779114,
                0.5940490365028381,
                0.1479797065258026,
                0.11216515302658081,
                0.0016620795940980315,
                0.1397351622581482,
                0.031035711988806725,
                -0.08602781593799591,
                0.07062388956546783,
                0.0693080723285675,
                0.24073883891105652,
                0.007841158658266068,
                0.04334814473986626,
                0.32565179467201233,
                -0.10821980237960815
            ],
            [
                0.13374026119709015,
                0.022323576733469963,
                0.19429506361484528,
                0.3612567186355591,
                0.3835805654525757,
                0.19411852955818176,
                0.43333137035369873,
                0.2747664749622345,
                0.18050457537174225,
                0.2978725731372833,
                0.2935527563095093,
                -0.035341136157512665,
                0.286591112613678,
                0.3136415481567383,
                0.041088175028562546,
                -0.030763598158955574
            ],
            [
                0.1521330028772354,
                0.17075882852077484,
                -0.05934962257742882,
                -0.004345363471657038,
                0.10426358133554459,
                0.09649825096130371,
                0.5603276491165161,
                0.09522882103919983,
                0.08604131639003754,
                0.24591189622879028,
                0.07316712290048599,
                0.17364251613616943,
                -0.06085801497101784,
                -0.07968146353960037,
                0.5278202295303345,
                0.7640049457550049
            ],
            [
                0.450269877910614,
                0.14242947101593018,
                0.23617810010910034,
                0.7888185381889343,
                0.030102241784334183,
                0.14230161905288696,
                0.10086178779602051,
                0.04995916038751602,
                0.09694211184978485,
                -0.013490964658558369,
                0.12668469548225403,
                -0.04249449074268341,
                -0.033325210213661194,
                -0.06127205491065979,
                0.21387231349945068,
                0.15829408168792725
            ],
            [
                0.19593046605587006,
                0.20773573219776154,
                0.5903118252754211,
                0.48410093784332275,
                0.3033484220504761,
                0.056334055960178375,
                -0.004175522830337286,
                0.04957744479179382,
                0.12830528616905212,
                -0.11921611428260803,
                0.09688866138458252,
                0.7149003148078918,
                0.046331584453582764,
                0.17699727416038513,
                0.07593158632516861,
                0.07950843125581741
            ],
            [
                0.112093485891819,
                0.2471071034669876,
                0.022415023297071457,
                0.01895861327648163,
                0.04975733160972595,
                0.24575449526309967,
                0.01839320734143257,
                0.0283498615026474,
                -0.08697664737701416,
                -0.01885092258453369,
                -0.10216076672077179,
                0.24462617933750153,
                0.026325082406401634,
                -0.14010114967823029,
                0.23432326316833496,
                -0.13605833053588867
            ],
            [
                0.172122523188591,
                -0.03794773295521736,
                -0.05068264156579971,
                -0.01760704070329666,
                0.007830943912267685,
                0.28254032135009766,
                -0.05260124430060387,
                -0.10929292440414429,
                0.09926482290029526,
                0.04016551002860069,
                -0.13804252445697784,
                -0.003512895666062832,
                0.04368335381150246,
                -0.12179604917764664,
                -0.05417130887508392,
                -0.16866014897823334
            ]
        ],
        [
            [
                2.3479702472686768,
                2.3534417152404785,
                1.9093799591064453,
                2.9650635719299316,
                2.4412360191345215,
                3.5407376289367676,
                0.854854941368103,
                1.411007046699524,
                3.066657781600952,
                2.1946632862091064,
                0.008092239499092102,
                2.2280526161193848,
                0.9539394378662109,
                2.2567319869995117,
                5.624912738800049,
                4.068602085113525
            ],
            [
                0.8500521779060364,
                5.335178375244141,
                2.6287484169006348,
                2.1788268089294434,
                5.154756546020508,
                6.397071838378906,
                3.9117021560668945,
                4.862042427062988,
                4.482346534729004,
                0.1008325070142746,
                -0.04423928260803223,
                1.6809231042861938,
                0.9784338474273682,
                4.194362640380859,
                4.782259464263916,
                5.156479358673096
            ],
            [
                0.22954368591308594,
                5.727468013763428,
                3.3392746448516846,
                1.627262830734253,
                5.015887260437012,
                6.316822052001953,
                6.195353031158447,
                1.5250287055969238,
                2.4494879245758057,
                1.6810358762741089,
                -0.0017653757240623236,
                4.0813446044921875,
                5.531259059906006,
                8.909038543701172,
                6.334808826446533,
                5.009031295776367
            ],
            [
                3.9055380821228027,
                3.25907301902771,
                0.8488734364509583,
                1.8131321668624878,
                4.902101516723633,
                6.311794281005859,
                4.270800590515137,
                2.195039749145508,
                4.171936988830566,
                0.6062164902687073,
                0.7207043766975403,
                0.3299572765827179,
                4.5739922523498535,
                9.438728332519531,
                7.703770160675049,
                3.090282678604126
            ],
            [
                4.4196271896362305,
                4.2393798828125,
                3.347240924835205,
                0.4615308940410614,
                2.459819793701172,
                4.974935531616211,
                5.086422443389893,
                6.172430992126465,
                4.809299468994141,
                2.357067584991455,
                2.5727505683898926,
                3.461886405944824,
                2.204157829284668,
                3.5216422080993652,
                3.7771661281585693,
                1.8059778213500977
            ],
            [
                1.7972736358642578,
                1.7417985200881958,
                4.2339768409729,
                0.19676825404167175,
                2.4478561878204346,
                2.5762827396392822,
                1.1807432174682617,
                2.873231887817383,
                0.7202972769737244,
                2.2283823490142822,
                7.5632643699646,
                12.61241626739502,
                5.3764166831970215,
                2.702461004257202,
                3.9104530811309814,
                1.1524114608764648
            ],
            [
                1.873539924621582,
                2.1016786098480225,
                4.501293659210205,
                5.196734428405762,
                1.669254183769226,
                4.756471633911133,
                3.3600728511810303,
                1.3620514869689941,
                4.292136192321777,
                5.736963272094727,
                6.218603610992432,
                6.8226094245910645,
                3.6685619354248047,
                12.009119033813477,
                9.239297866821289,
                8.929596900939941
            ],
            [
                0.2184458076953888,
                6.076868057250977,
                7.12849235534668,
                7.636772155761719,
                3.684201717376709,
                3.5990169048309326,
                6.993699073791504,
                3.0846264362335205,
                6.659440994262695,
                4.260721683502197,
                0.536773681640625,
                0.34070947766304016,
                5.463797569274902,
                11.62186050415039,
                12.536611557006836,
                4.82047700881958
            ],
            [
                -0.09928789734840393,
                -0.13269543647766113,
                -0.10302875190973282,
                -0.13602139055728912,
                -0.12099636346101761,
                -0.10105346143245697,
                -0.16281810402870178,
                -0.13706259429454803,
                -0.11534304916858673,
                -0.09845844656229019,
                -0.0995251014828682,
                -0.10680090636014938,
                -0.14780648052692413,
                -0.16875067353248596,
                -0.1696861833333969,
                -0.14041388034820557
            ],
            [
                -0.08297736197710037,
                -0.08423314243555069,
                -0.08907140791416168,
                -0.116031713783741,
                -0.10792314261198044,
                -0.14466716349124908,
                -0.15845642983913422,
                -0.1370788961648941,
                -0.10450419038534164,
                -0.10235653817653656,
                -0.12155601382255554,
                -0.12403743714094162,
                -0.14064258337020874,
                -0.12298519909381866,
                -0.07980482280254364,
                -0.09837643057107925
            ],
            [
                -0.09672978520393372,
                -0.10617183148860931,
                -0.12042966485023499,
                -0.0985119566321373,
                -0.081242635846138,
                -0.12827278673648834,
                -0.09624285250902176,
                -0.12332843989133835,
                -0.12849321961402893,
                -0.09701474010944366,
                -0.08884675055742264,
                -0.10128332674503326,
                -0.12503790855407715,
                -0.09867245703935623,
                -0.07994244992733002,
                -0.07056988030672073
            ],
            [
                -0.08543652296066284,
                -0.10789629817008972,
                -0.11975376307964325,
                -0.08547598868608475,
                -0.113555908203125,
                -0.11683634668588638,
                -0.11293703317642212,
                -0.1024937704205513,
                -0.13709311187267303,
                -0.10907774418592453,
                -0.1062597706913948,
                -0.14371733367443085,
                -0.14029130339622498,
                -0.07219730317592621,
                -0.0671183168888092,
                -0.031191514804959297
            ],
            [
                -0.08872989565134048,
                -0.1218770444393158,
                -0.09179291874170303,
                -0.09299902617931366,
                -0.1493401825428009,
                -0.15770326554775238,
                -0.1449459344148636,
                -0.10280638188123703,
                -0.11410166323184967,
                -0.12179619073867798,
                -0.10973802208900452,
                -0.13769592344760895,
                -0.15269115567207336,
                -0.15977197885513306,
                -0.054424889385700226,
                -0.045823145657777786
            ],
            [
                -0.09809384495019913,
                -0.12494579702615738,
                -0.10587964951992035,
                -0.13550162315368652,
                -0.1245921403169632,
                -0.1269235610961914,
                -0.1451442539691925,
                -0.13627450168132782,
                -0.13715557754039764,
                -0.14707918465137482,
                -0.14626801013946533,
                -0.07524164766073227,
                -0.10757844895124435,
                -0.1650066077709198,
                -0.13201293349266052,
                -0.11300741136074066
            ],
            [
                -0.10266802459955215,
                -0.11857642233371735,
                -0.09446268528699875,
                -0.11140336841344833,
                -0.09246719628572464,
                -0.14577637612819672,
                -0.1140584647655487,
                -0.13009431958198547,
                -0.1059570237994194,
                -0.13000811636447906,
                -0.1347087323665619,
                -0.12467172741889954,
                -0.09848625212907791,
                -0.12061338871717453,
                -0.13789789378643036,
                -0.13886407017707825
            ],
            [
                -0.08019305765628815,
                -0.0761827677488327,
                -0.11713260412216187,
                -0.08712200075387955,
                -0.08501972258090973,
                -0.10998257994651794,
                -0.10669423639774323,
                -0.09269870817661285,
                -0.080656036734581,
                -0.08961332589387894,
                -0.093556709587574,
                -0.07701722532510757,
                -0.06664025038480759,
                -0.08337291330099106,
                -0.10652799904346466,
                -0.11894842982292175
            ]
        ],
        [
            [
                0.06513871997594833,
                -0.16932888329029083,
                -0.10080822557210922,
                -0.09788729250431061,
                -0.039994366466999054,
                1.5910701751708984,
                -0.07246428728103638,
                -0.04583914205431938,
                -0.11737246811389923,
                -0.13491393625736237,
                -0.10458813607692719,
                0.13515891134738922,
                -0.15435504913330078,
                0.032821524888277054,
                1.2480064630508423,
                -0.12267753481864929
            ],
            [
                -0.13963095843791962,
                2.2518506050109863,
                0.6365621089935303,
                1.2997586727142334,
                0.0422324500977993,
                3.2962915897369385,
                0.9874159097671509,
                0.3124725818634033,
                0.7554905414581299,
                -0.01572822779417038,
                -0.011222797445952892,
                0.28375643491744995,
                -0.09034702926874161,
                -0.11276613920927048,
                2.4548697471618652,
                -0.07425820827484131
            ],
            [
                -0.16562172770500183,
                0.8371453881263733,
                1.4318385124206543,
                -0.16925294697284698,
                0.5274475812911987,
                0.717948317527771,
                0.6102693676948547,
                -0.16983415186405182,
                -0.1451391875743866,
                -0.09547419846057892,
                -0.034508902579545975,
                -0.08027476817369461,
                0.06875357776880264,
                2.7636570930480957,
                -0.16360732913017273,
                0.5224187970161438
            ],
            [
                1.96555495262146,
                0.15659776329994202,
                -0.11543046683073044,
                -0.11027824133634567,
                1.223855972290039,
                -0.1698717176914215,
                0.36320459842681885,
                -0.05308309569954872,
                -0.16864430904388428,
                -0.021681061014533043,
                -0.16425541043281555,
                -0.1700277328491211,
                0.2953427731990814,
                3.112067222595215,
                0.7289548516273499,
                0.25193193554878235
            ],
            [
                1.5772132873535156,
                0.34138205647468567,
                0.49854177236557007,
                -0.08540819585323334,
                -0.09815922379493713,
                1.2974547147750854,
                0.0650896430015564,
                1.4855153560638428,
                0.25965046882629395,
                -0.07773127406835556,
                -0.14721593260765076,
                0.022480124607682228,
                -0.10318584740161896,
                -0.16809234023094177,
                0.12450455129146576,
                -0.07040903717279434
            ],
            [
                0.8053327798843384,
                0.4898110330104828,
                1.2650337219238281,
                -0.14356933534145355,
                0.4134281873703003,
                1.3239612579345703,
                -0.15129482746124268,
                -0.11990147829055786,
                -0.07276391237974167,
                -0.13465997576713562,
                1.6126363277435303,
                0.6076995730400085,
                0.2140999287366867,
                -0.16942720115184784,
                -0.14743521809577942,
                -0.16953197121620178
            ],
            [
                -0.16479311883449554,
                -0.1463090479373932,
                1.939860224723816,
                0.5841923356056213,
                0.05948188900947571,
                0.190553680062294,
                0.6779459714889526,
                -0.11108922213315964,
                1.1904088258743286,
                -0.15220768749713898,
                2.1500415802001953,
                3.1439549922943115,
                -0.12276415526866913,
                0.7661504149436951,
                0.45286715030670166,
                0.359104722738266
            ],
            [
                -0.10712548345327377,
                1.0845938920974731,
                1.9613679647445679,
                0.8867797255516052,
                -0.12826621532440186,
                -0.09989450871944427,
                1.3049434423446655,
                -0.15845954418182373,
                -0.11235900223255157,
                -0.07142332196235657,
                -0.1621129959821701,
                -0.051479585468769073,
                0.49473196268081665,
                0.886073887348175,
                -0.04161156713962555,
                -0.054833412170410156
            ],
            [
                -0.03914296627044678,
                0.16372378170490265,
                0.058501236140728,
                0.4113388955593109,
                0.3103818893432617,
                0.0019230786710977554,
                -0.0028472079429775476,
                0.04260236769914627,
                0.5831538438796997,
                0.005637558177113533,
                0.10717251151800156,
                0.0244993157684803,
                0.2600390315055847,
                0.15298792719841003,
                -0.03858308121562004,
                -0.054403018206357956
            ],
            [
                0.2036089301109314,
                0.31539031863212585,
                0.04875741899013519,
                -0.041350312530994415,
                -0.08138061314821243,
                0.07419620454311371,
                -0.09783019125461578,
                0.2882777750492096,
                -0.015803441405296326,
                -0.09871141612529755,
                0.05418580397963524,
                -0.15670400857925415,
                0.06039607897400856,
                0.11430544406175613,
                0.062286339700222015,
                -0.08617839217185974
            ],
            [
                -0.15475890040397644,
                0.13304628431797028,
                -0.005533337593078613,
                -0.10238351672887802,
                -0.09701582044363022,
                -0.03438391536474228,
                0.5479949116706848,
                0.3252076208591461,
                0.0792943686246872,
                0.30420854687690735,
                -0.07070718705654144,
                -0.1453898698091507,
                -0.02364964969456196,
                -0.12228716909885406,
                -0.11620290577411652,
                0.04524625092744827
            ],
            [
                0.29249516129493713,
                0.3510591387748718,
                0.04731976240873337,
                -0.0219626035541296,
                -0.0889955684542656,
                0.02424323558807373,
                0.15171019732952118,
                -0.16674456000328064,
                -0.04548058286309242,
                0.10901343077421188,
                0.00768618006259203,
                -0.0796077772974968,
                0.054376713931560516,
                -0.11158960312604904,
                -0.03574365749955177,
                -0.03888017311692238
            ],
            [
                0.05871044099330902,
                -0.016939491033554077,
                0.023947138339281082,
                0.20406000316143036,
                -0.06063370034098625,
                -0.13306038081645966,
                -0.06251948326826096,
                0.03855380415916443,
                -0.05508971959352493,
                -0.00534819858148694,
                -0.10609670728445053,
                -0.1570814549922943,
                -0.1662270426750183,
                0.01080885250121355,
                0.1999557465314865,
                0.10644017159938812
            ],
            [
                -0.04166201502084732,
                -0.00010555694461800158,
                -0.0824260264635086,
                0.07539428770542145,
                0.224262535572052,
                0.0750071331858635,
                0.07467233389616013,
                0.12677788734436035,
                -0.0004965150146745145,
                -0.129609152674675,
                -0.16996406018733978,
                -0.12007103860378265,
                -0.16615861654281616,
                -0.16117647290229797,
                -0.14604255557060242,
                0.030584128573536873
            ],
            [
                0.14112280309200287,
                0.1347435712814331,
                0.20664840936660767,
                -0.11881999671459198,
                -0.055292319506406784,
                -0.06593013554811478,
                -0.021984117105603218,
                0.31831279397010803,
                0.08544516563415527,
                -0.03219461813569069,
                -0.10459662973880768,
                -0.07485464960336685,
                -0.13533887267112732,
                -0.163331538438797,
                -0.15588393807411194,
                -0.01301663275808096
            ],
            [
                0.0011417880887165666,
                -0.023724256083369255,
                -0.12098685652017593,
                -0.033713627606630325,
                0.28018832206726074,
                0.04570387676358223,
                0.10158278048038483,
                0.043354421854019165,
                -0.08255468308925629,
                0.13206836581230164,
                0.06378423422574997,
                -0.09459464997053146,
                -0.017311835661530495,
                -0.011613751761615276,
                0.002164682140573859,
                0.15498411655426025
            ]
        ],
        [
            [
                0.616758406162262,
                1.1268171072006226,
                -0.1694493591785431,
                0.07306451350450516,
                -0.07720727473497391,
                0.09815619885921478,
                0.703845739364624,
                1.193748950958252,
                0.257744163274765,
                2.2008910179138184,
                1.3333877325057983,
                0.029034769162535667,
                1.526818037033081,
                0.2993893325328827,
                0.6653966307640076,
                -0.03935658559203148
            ],
            [
                0.07549329847097397,
                1.759677529335022,
                0.0700565055012703,
                0.28374817967414856,
                1.884050965309143,
                1.8283226490020752,
                2.5097429752349854,
                0.43330639600753784,
                0.6517423391342163,
                0.3867558538913727,
                0.8575764298439026,
                2.545461654663086,
                0.9094967246055603,
                1.8741971254348755,
                0.6067889332771301,
                0.5376889705657959
            ],
            [
                0.2729179859161377,
                0.793229341506958,
                1.5628851652145386,
                1.8881251811981201,
                1.4376670122146606,
                1.310601830482483,
                0.7344913482666016,
                1.1037944555282593,
                2.2500011920928955,
                2.098702907562256,
                1.0832033157348633,
                0.22975566983222961,
                1.566359519958496,
                0.44174954295158386,
                0.19230037927627563,
                1.3284847736358643
            ],
            [
                1.9738807678222656,
                1.6027451753616333,
                -0.03962191566824913,
                1.5322421789169312,
                0.8490303754806519,
                1.1405236721038818,
                0.4439736604690552,
                -0.13292080163955688,
                3.152474880218506,
                2.377903699874878,
                0.10756959021091461,
                1.8267849683761597,
                1.7366747856140137,
                0.2943916916847229,
                1.9725090265274048,
                2.8406388759613037
            ],
            [
                -0.16998814046382904,
                0.6886880397796631,
                1.144912600517273,
                -0.1446964144706726,
                2.3125555515289307,
                0.2971654236316681,
                0.5338099598884583,
                2.6035373210906982,
                2.6073968410491943,
                1.6049929857254028,
                0.8889325857162476,
                0.8993501663208008,
                1.7354090213775635,
                0.08723914623260498,
                2.1458957195281982,
                1.8158365488052368
            ],
            [
                1.9530065059661865,
                2.04113507270813,
                2.118196725845337,
                2.1101319789886475,
                2.079789638519287,
                1.2155863046646118,
                0.8745681643486023,
                1.8429831266403198,
                1.9198548793792725,
                1.9014705419540405,
                0.57129967212677,
                -0.14743700623512268,
                0.6223974227905273,
                1.5483745336532593,
                0.5011688470840454,
                0.7150155305862427
            ],
            [
                0.3958023190498352,
                0.873016893863678,
                2.259331226348877,
                1.62204909324646,
                1.4436746835708618,
                1.8061652183532715,
                1.6446384191513062,
                1.585634708404541,
                1.2501002550125122,
                0.7052740454673767,
                1.5156654119491577,
                1.040199875831604,
                2.2674760818481445,
                -0.16536161303520203,
                1.5456005334854126,
                0.3404238820075989
            ],
            [
                0.8061418533325195,
                0.43202683329582214,
                0.19118350744247437,
                0.050340455025434494,
                2.2209720611572266,
                1.0274959802627563,
                1.0309706926345825,
                0.7062483429908752,
                1.1377828121185303,
                1.3183257579803467,
                1.19414222240448,
                0.9058311581611633,
                0.17929384112358093,
                -0.008726377040147781,
                -0.13440099358558655,
                1.1984330415725708
            ],
            [
                0.44773977994918823,
                0.6145538687705994,
                0.6779614686965942,
                0.6347687244415283,
                0.8847049474716187,
                0.764284074306488,
                0.4149608314037323,
                0.6079531908035278,
                1.756571888923645,
                1.2415707111358643,
                0.5837264060974121,
                0.5221207141876221,
                0.27989476919174194,
                0.5390831828117371,
                0.23332662880420685,
                0.1141280010342598
            ],
            [
                0.7470382452011108,
                0.6453503370285034,
                1.0036635398864746,
                0.27827250957489014,
                0.5708797574043274,
                0.16169215738773346,
                0.3717879354953766,
                0.84096360206604,
                0.3837279677391052,
                0.5654850006103516,
                0.6305103302001953,
                0.29890501499176025,
                0.4363947808742523,
                0.4283735752105713,
                0.5653691291809082,
                0.39230790734291077
            ],
            [
                0.37859371304512024,
                0.5463670492172241,
                0.5074127316474915,
                1.1194082498550415,
                0.7238621115684509,
                0.6313151121139526,
                1.0449776649475098,
                0.6791874170303345,
                0.7824248671531677,
                0.9308814406394958,
                0.4333624839782715,
                0.1961948573589325,
                0.21732695400714874,
                0.48891153931617737,
                0.18217773735523224,
                0.4217418432235718
            ],
            [
                0.23842546343803406,
                0.9939153790473938,
                0.3617839515209198,
                0.6937420964241028,
                0.6526153683662415,
                0.5502802133560181,
                0.43180549144744873,
                0.6533216238021851,
                0.5437029004096985,
                0.7611498832702637,
                0.39047670364379883,
                0.7896991968154907,
                0.2529784142971039,
                0.6374262571334839,
                1.2058343887329102,
                1.9019757509231567
            ],
            [
                0.4806613624095917,
                0.7696384787559509,
                0.5789138674736023,
                1.0258755683898926,
                0.5444086194038391,
                0.28914928436279297,
                0.5740745663642883,
                0.4987261891365051,
                0.7052544355392456,
                0.07995492964982986,
                0.9770431518554688,
                0.6093083024024963,
                0.4390961825847626,
                0.3346215784549713,
                1.4775352478027344,
                1.3647394180297852
            ],
            [
                0.2406424582004547,
                0.5829602479934692,
                0.8604195713996887,
                0.37518244981765747,
                1.0876649618148804,
                0.4596516191959381,
                0.7951475977897644,
                0.34036508202552795,
                0.7341734766960144,
                0.25492382049560547,
                0.6324068307876587,
                0.8492043614387512,
                0.330020010471344,
                0.06692903488874435,
                0.40303394198417664,
                0.33846136927604675
            ],
            [
                0.3020867705345154,
                1.0805974006652832,
                1.2676942348480225,
                0.6213544011116028,
                1.1167880296707153,
                0.23848962783813477,
                0.36059319972991943,
                0.5676848292350769,
                0.8011916279792786,
                0.5375558137893677,
                0.48525717854499817,
                0.1733347326517105,
                0.3364761471748352,
                0.47831103205680847,
                0.28288891911506653,
                0.052526671439409256
            ],
            [
                0.6515343189239502,
                0.14868944883346558,
                0.24651585519313812,
                0.26475289463996887,
                0.756991446018219,
                0.5169591307640076,
                0.47348982095718384,
                0.43067100644111633,
                0.6810112595558167,
                0.4966508448123932,
                0.2798698842525482,
                0.4116210341453552,
                0.3728666305541992,
                0.263821005821228,
                0.14409677684307098,
                0.29847288131713867
            ]
        ],
        [
            [
                0.809468150138855,
                1.2783756256103516,
                3.0147969722747803,
                0.5153440237045288,
                0.7885246872901917,
                -0.10678130388259888,
                0.25046393275260925,
                2.7093708515167236,
                2.3154923915863037,
                1.8917666673660278,
                2.947126626968384,
                0.9443299770355225,
                0.9003280997276306,
                0.3339725434780121,
                1.880135416984558,
                1.5491111278533936
            ],
            [
                1.7631133794784546,
                0.09521722048521042,
                2.5626258850097656,
                4.699645042419434,
                1.4804916381835938,
                -0.1661728322505951,
                0.23636394739151,
                2.2442328929901123,
                1.8619641065597534,
                1.2851890325546265,
                2.706500291824341,
                1.3935595750808716,
                0.5745960474014282,
                0.43533962965011597,
                -0.1638260930776596,
                0.9981788396835327
            ],
            [
                4.504018306732178,
                2.635101795196533,
                1.9923911094665527,
                -0.147812619805336,
                -0.0165484007447958,
                -0.06174451857805252,
                -0.14151065051555634,
                0.2149827480316162,
                0.6207583546638489,
                1.8166872262954712,
                2.512272834777832,
                2.672520637512207,
                -0.16880221664905548,
                -0.1700158566236496,
                1.6893486976623535,
                1.0110745429992676
            ],
            [
                2.29789137840271,
                3.6780388355255127,
                1.267357349395752,
                2.243495464324951,
                0.2353636920452118,
                0.9306150078773499,
                -0.15146593749523163,
                3.7732951641082764,
                3.585710287094116,
                2.141035795211792,
                3.00632643699646,
                4.955720901489258,
                3.445622205734253,
                1.8044695854187012,
                0.42316848039627075,
                4.129566669464111
            ],
            [
                0.28217655420303345,
                -0.07634952664375305,
                0.1380131095647812,
                4.5736918449401855,
                3.2688326835632324,
                1.4220550060272217,
                1.5981391668319702,
                -0.16368861496448517,
                -0.0450662337243557,
                0.3062834143638611,
                4.336188316345215,
                2.373849630355835,
                1.9699257612228394,
                3.0473814010620117,
                -0.16655467450618744,
                3.3731014728546143
            ],
            [
                1.9852015972137451,
                0.9341598749160767,
                -0.10877728462219238,
                1.434516429901123,
                0.8244163393974304,
                -0.11194495856761932,
                3.057173252105713,
                0.642116129398346,
                3.6769731044769287,
                2.5014476776123047,
                1.49247145652771,
                -0.003430362092331052,
                -0.04586239531636238,
                -0.16376084089279175,
                1.479946255683899,
                1.204609751701355
            ],
            [
                1.6068676710128784,
                2.821831464767456,
                -0.0073463632725179195,
                0.11529351025819778,
                0.169647216796875,
                2.376873254776001,
                0.7580503821372986,
                1.5275815725326538,
                -0.11371831595897675,
                1.5153565406799316,
                2.0816307067871094,
                1.3805021047592163,
                0.466886967420578,
                -0.02858635224401951,
                -0.09448445588350296,
                0.17541061341762543
            ],
            [
                1.846756935119629,
                -0.1392918974161148,
                -0.12448403984308243,
                1.8421577215194702,
                0.777732789516449,
                2.306884527206421,
                1.435965895652771,
                1.9495532512664795,
                -0.16869457066059113,
                1.627004861831665,
                2.573478937149048,
                0.929125964641571,
                1.8262555599212646,
                -0.1121138334274292,
                4.693451404571533,
                3.728830099105835
            ],
            [
                -0.097709059715271,
                0.14049674570560455,
                0.40492188930511475,
                0.055412407964468,
                -0.085990771651268,
                -0.038845982402563095,
                -0.050026919692754745,
                -0.09736282378435135,
                0.007335502654314041,
                -0.01015966385602951,
                -0.1271214634180069,
                -0.11225128173828125,
                -0.053458236157894135,
                -0.12258578836917877,
                -0.16947130858898163,
                -0.15711842477321625
            ],
            [
                -0.02827511355280876,
                -0.12073156237602234,
                0.10640347003936768,
                -0.11951444298028946,
                -0.13594669103622437,
                -0.14012262225151062,
                -0.16181333363056183,
                -0.10228490084409714,
                -0.10721611976623535,
                -0.014071484096348286,
                -0.13104777038097382,
                -0.16928881406784058,
                -0.1646149754524231,
                -0.1700402796268463,
                -0.009046304039657116,
                -0.0585334487259388
            ],
            [
                -0.09683693200349808,
                -0.085503488779068,
                -0.0974564403295517,
                -0.07875943928956985,
                -0.06850806623697281,
                -0.09840677678585052,
                0.021314775571227074,
                0.01830642856657505,
                -0.07345142960548401,
                -0.10212967544794083,
                0.053435951471328735,
                -0.12385080754756927,
                -0.12333951890468597,
                -0.16014531254768372,
                -0.13406667113304138,
                -0.05162676051259041
            ],
            [
                -0.10231716185808182,
                -0.1297328770160675,
                -0.14935381710529327,
                -0.10631832480430603,
                -0.04714007303118706,
                -0.044342782348394394,
                0.0737464427947998,
                -0.0781809464097023,
                -0.11486344039440155,
                -0.09528636932373047,
                -0.03389165550470352,
                -0.16460731625556946,
                -0.16961421072483063,
                -0.021990500390529633,
                -0.1175658255815506,
                0.10791011154651642
            ],
            [
                -0.098658986389637,
                -0.15425820648670197,
                -0.1331430971622467,
                -0.007703583687543869,
                0.008617931045591831,
                -0.07530980557203293,
                -0.14913052320480347,
                -0.0976879671216011,
                -0.1623939871788025,
                -0.1456661820411682,
                -0.07561955600976944,
                -0.09288143366575241,
                -0.1405400037765503,
                -0.0796491876244545,
                0.23115868866443634,
                0.2296711504459381
            ],
            [
                -0.09417156130075455,
                0.043281540274620056,
                -0.11505302786827087,
                -0.14799152314662933,
                -0.04673919454216957,
                -0.09984008222818375,
                -0.14690908789634705,
                -0.1111648678779602,
                0.002475802320986986,
                -0.17002856731414795,
                -0.02351354993879795,
                -0.0852561816573143,
                -0.13277675211429596,
                -0.1132001206278801,
                -0.1168670728802681,
                -0.12484069913625717
            ],
            [
                -0.11955375969409943,
                -0.10677272081375122,
                0.12312977761030197,
                0.008562774397432804,
                0.04082796722650528,
                -0.022744880989193916,
                -0.1403995007276535,
                -0.13564631342887878,
                -0.17003753781318665,
                -0.1497197151184082,
                -0.09847456216812134,
                -0.05367836728692055,
                -0.16159991919994354,
                -0.16776159405708313,
                -0.0710107684135437,
                0.024920368567109108
            ],
            [
                -0.14661604166030884,
                -0.1693006157875061,
                -0.16761483252048492,
                -0.03534704074263573,
                -0.15824191272258759,
                -0.16994434595108032,
                -0.1649000197649002,
                -0.10131427645683289,
                -0.14577944576740265,
                -0.16721349954605103,
                -0.16945971548557281,
                -0.1693398356437683,
                -0.1381201148033142,
                -0.05642961338162422,
                -0.16696569323539734,
                -0.13444840908050537
            ]
        ],
        [
            [
                -0.002318592742085457,
                -0.12559856474399567,
                -0.04615042731165886,
                0.13714134693145752,
                -0.13979534804821014,
                -0.011074898764491081,
                -0.0440196692943573,
                -0.15821287035942078,
                -0.04309439659118652,
                -0.1520642340183258,
                -0.044062647968530655,
                -0.026870934292674065,
                -0.04799608141183853,
                -0.03132355958223343,
                -0.16584554314613342,
                -0.11312321573495865
            ],
            [
                -0.008149025030434132,
                -0.1650344431400299,
                -0.16167765855789185,
                -0.015542744658887386,
                -0.10606075078248978,
                -0.03837769478559494,
                -0.030662741512060165,
                -0.16479019820690155,
                -0.11895548552274704,
                -0.1503685861825943,
                -0.15311942994594574,
                -0.15994705259799957,
                -0.08574896305799484,
                -0.08326607942581177,
                -0.15874184668064117,
                -0.16206194460391998
            ],
            [
                -0.018818140029907227,
                -0.1494736671447754,
                -0.15969853103160858,
                -0.15237650275230408,
                -0.12352339923381805,
                -0.17003019154071808,
                -0.10592664778232574,
                -0.1273907572031021,
                -0.16508831083774567,
                -0.15991507470607758,
                -0.11406103521585464,
                -0.08137111365795135,
                0.15589730441570282,
                -0.1674397587776184,
                0.39595454931259155,
                -0.14503510296344757
            ],
            [
                -0.021514229476451874,
                -0.1680842787027359,
                -0.15068912506103516,
                -0.16852864623069763,
                -0.16688193380832672,
                1.4482476711273193,
                -0.14690662920475006,
                -0.10800939798355103,
                -0.16993311047554016,
                -0.0315714068710804,
                -0.07316043972969055,
                -0.08345210552215576,
                0.7080590128898621,
                -0.07895772159099579,
                -0.11300823837518692,
                -0.1554284244775772
            ],
            [
                -0.00008799542410997674,
                -0.14200745522975922,
                -0.16284601390361786,
                -0.06222092732787132,
                -0.15268754959106445,
                -0.1585180014371872,
                -0.15482665598392487,
                -0.16981157660484314,
                0.18416093289852142,
                -0.08366438001394272,
                -0.15989692509174347,
                -0.10812953114509583,
                -0.16309544444084167,
                -0.07116270810365677,
                -0.1693505346775055,
                -0.15384694933891296
            ],
            [
                -0.14084316790103912,
                -0.01780242659151554,
                -0.16997753083705902,
                -0.08053948730230331,
                -0.15409621596336365,
                -0.04581668972969055,
                -0.16988973319530487,
                -0.16999316215515137,
                -0.09939531236886978,
                -0.07359708845615387,
                -0.1664825826883316,
                -0.1698475033044815,
                -0.03631889447569847,
                -0.16729848086833954,
                -0.10588356852531433,
                -0.09951813519001007
            ],
            [
                -0.0295088067650795,
                -0.08418351411819458,
                -0.03551744297146797,
                -0.0823528990149498,
                -0.16072548925876617,
                -0.010216285474598408,
                -0.1118350625038147,
                -0.10043390840291977,
                -0.16673077642917633,
                -0.00896935723721981,
                -0.1367652267217636,
                -0.16989073157310486,
                -0.05303018167614937,
                -0.1072680875658989,
                -0.1315632164478302,
                -0.046619534492492676
            ],
            [
                -0.07094859331846237,
                -0.01521718967705965,
                -0.13658089935779572,
                -0.15594732761383057,
                -0.13795551657676697,
                -0.06321389973163605,
                -0.06358721852302551,
                -0.09861427545547485,
                -0.05709288269281387,
                -0.07615573704242706,
                -0.08349397778511047,
                -0.10312724113464355,
                -0.0823194831609726,
                0.7387345433235168,
                0.650393009185791,
                -0.04053587466478348
            ],
            [
                0.6155226230621338,
                0.9657936096191406,
                1.3175328969955444,
                1.1613147258758545,
                1.0584678649902344,
                1.031383991241455,
                1.0447832345962524,
                1.3261959552764893,
                1.0073550939559937,
                1.3985341787338257,
                1.385984182357788,
                1.2098109722137451,
                0.9686221480369568,
                0.9501575827598572,
                0.9689661264419556,
                0.9932408928871155
            ],
            [
                0.9366739392280579,
                1.1224360466003418,
                1.191826343536377,
                1.1536681652069092,
                1.1213734149932861,
                1.1262409687042236,
                1.142279863357544,
                1.1906546354293823,
                1.2250365018844604,
                1.3928996324539185,
                1.149324655532837,
                0.8933355212211609,
                0.9557675719261169,
                1.0120364427566528,
                1.191524624824524,
                0.7920262813568115
            ],
            [
                0.6992860436439514,
                0.8735949397087097,
                1.3744584321975708,
                1.237856388092041,
                1.033374547958374,
                1.2349019050598145,
                1.4656429290771484,
                1.3097368478775024,
                1.217394471168518,
                1.3210878372192383,
                1.2134575843811035,
                1.0596846342086792,
                0.8036109805107117,
                1.148169994354248,
                1.1545603275299072,
                0.9486624002456665
            ],
            [
                0.715294599533081,
                1.2288029193878174,
                1.227627158164978,
                1.337231993675232,
                1.5944894552230835,
                1.2836846113204956,
                1.118324637413025,
                1.2062299251556396,
                1.051089882850647,
                1.324201226234436,
                1.1111942529678345,
                1.339097023010254,
                1.1719582080841064,
                0.9784276485443115,
                1.7542407512664795,
                1.2795072793960571
            ],
            [
                0.9081712961196899,
                1.5188190937042236,
                1.046436071395874,
                1.2624661922454834,
                1.3775423765182495,
                0.8001869320869446,
                1.2107588052749634,
                0.9200534224510193,
                0.9301407933235168,
                1.1148738861083984,
                1.4742478132247925,
                1.0941174030303955,
                0.9945981502532959,
                1.1631391048431396,
                1.5548819303512573,
                0.9753453731536865
            ],
            [
                0.7234559655189514,
                1.1388864517211914,
                1.1796091794967651,
                1.1269557476043701,
                1.2948236465454102,
                1.1035531759262085,
                1.14083731174469,
                0.9685898423194885,
                1.204105257987976,
                1.039844036102295,
                0.9368149638175964,
                1.4380501508712769,
                1.1893928050994873,
                1.0494053363800049,
                1.0266469717025757,
                0.710874080657959
            ],
            [
                0.7198371887207031,
                1.4305933713912964,
                1.1482256650924683,
                0.9835936427116394,
                1.3188772201538086,
                1.080412745475769,
                0.9467644095420837,
                1.1315453052520752,
                1.042310357093811,
                0.9628229737281799,
                1.1649800539016724,
                1.1570552587509155,
                1.00545072555542,
                1.0610216856002808,
                1.1454973220825195,
                0.9742630124092102
            ],
            [
                0.6021624803543091,
                0.6118036508560181,
                0.5682084560394287,
                0.6014527678489685,
                0.7323204874992371,
                0.8850414156913757,
                0.7928059101104736,
                0.7566149234771729,
                0.8867185115814209,
                0.9662842154502869,
                0.4510493278503418,
                0.8072049021720886,
                0.7161725163459778,
                0.507499635219574,
                0.8219401240348816,
                0.9866092801094055
            ]
        ],
        [
            [
                -2.925623618921236e-7,
                -0.000010803394616232254,
                -0.0020997810643166304,
                -0.000002347484951314982,
                -0.0016112494049593806,
                -0.000028312821086728945,
                -0.000006119769750512205,
                0,
                -0.0028525961097329855,
                -0.000036943110899301246,
                -2.951672968265484e-7,
                -0.000046863180614309385,
                0,
                0,
                -0.013365741819143295,
                0
            ],
            [
                -0.00018112796533387154,
                0,
                0,
                -1.5631979977115407e-7,
                -1.721567741697072e-7,
                -0.0037322069983929396,
                -1.5838895706110634e-7,
                -0.000007275499683601083,
                -0.0016162273241207004,
                0,
                0,
                -0.000005482343112817034,
                -0.011007055640220642,
                -0.00000587611748414929,
                -0.0024300464428961277,
                -0.000011797446859418415
            ],
            [
                -2.948011967873754e-7,
                -0.0006008796044625342,
                -0.006124199368059635,
                -0.0002963020233437419,
                -0.000023281932953977957,
                -0.000034711280022747815,
                -4.336987728947861e-7,
                -0.0000023531840724899666,
                -0.0006755140493623912,
                0,
                -1.8132097068246367e-7,
                -0.0004269715282134712,
                0,
                -0.000018925142285297625,
                -2.1973851005441247e-7,
                -0.048546645790338516
            ],
            [
                -4.3993404119646584e-7,
                -0.0002173781394958496,
                -0.00005102103023091331,
                -0.000004848340722674038,
                -0.00011249849194427952,
                -0.0013880014885216951,
                -0.0020607104524970055,
                0,
                -0.0000010019559795182431,
                -9.919867807184346e-7,
                0,
                0,
                0,
                -0.0003858348063658923,
                0,
                0
            ],
            [
                -0.0015004482120275497,
                -1.492685299808727e-7,
                -0.000004714332135336008,
                0,
                0,
                0,
                0,
                -0.0000031456845590582816,
                0,
                -0.000005359263923310209,
                0,
                -0.0005640416638925672,
                -2.932858365056745e-7,
                -4.382670795166632e-7,
                -0.00007980230293469504,
                0
            ],
            [
                -0.000020622599549824372,
                -0.000001001685291157628,
                -5.824693971590023e-7,
                -1.8932809098259895e-7,
                -0.0005655111162923276,
                -0.000004201149749860633,
                -0.0004347782232798636,
                -0.000008160336619766895,
                -2.925165460965218e-7,
                -0.000011172710401297081,
                0,
                -0.000030095277907093987,
                -0.000007911378816061188,
                0,
                -0.0000018228756744065322,
                -0.0000018172545424022246
            ],
            [
                -0.00766468932852149,
                -0.000012666329894273076,
                -0.02618875727057457,
                -0.0000024790526822471293,
                -0.0003933061670977622,
                -0.026276394724845886,
                -0.000001685408847151848,
                -0.00008093602082226425,
                -1.5300788902550266e-7,
                -0.0006068003131076694,
                0,
                -0.000981273828074336,
                0,
                -0.0000010038802429335192,
                -0.00035066730924881995,
                -0.0005723580834455788
            ],
            [
                0,
                -0.0004588441224768758,
                -0.0032326506916433573,
                -1.8700565362905763e-7,
                -0.00036905345041304827,
                -0.0000016812896319606807,
                -0.000019052158677368425,
                -0.0005744105437770486,
                -0.0001300243311561644,
                0,
                -0.0000018216076114185853,
                0,
                -1.4916101065409748e-7,
                -8.653269674141484e-7,
                0,
                -0.00004197162706987001
            ],
            [
                0.5858370065689087,
                0.5531355738639832,
                0.8284533023834229,
                0.8710693120956421,
                0.3793649673461914,
                0.8500598669052124,
                0.848299503326416,
                0.842393696308136,
                1.3523430824279785,
                0.686457633972168,
                0.9606453776359558,
                0.7596731781959534,
                1.0103759765625,
                1.1765789985656738,
                0.8522944450378418,
                0.5373185873031616
            ],
            [
                0.6474123597145081,
                0.7216623425483704,
                0.650834858417511,
                0.7118633389472961,
                0.6336091160774231,
                0.8700187802314758,
                0.9153359532356262,
                1.072046160697937,
                0.6801578998565674,
                0.7827799320220947,
                0.8056041598320007,
                0.544343888759613,
                0.8735398650169373,
                0.9946616888046265,
                0.9215337634086609,
                0.6626892685890198
            ],
            [
                0.5204722881317139,
                0.7050937414169312,
                0.9688313007354736,
                0.8342141509056091,
                0.8151470422744751,
                0.8207976818084717,
                0.8907385468482971,
                1.3121840953826904,
                0.8178214430809021,
                0.6335181593894958,
                0.6337251663208008,
                0.6262294054031372,
                0.7401559948921204,
                0.7131205201148987,
                0.5564941763877869,
                0.5906420350074768
            ],
            [
                0.8461498618125916,
                1.0041226148605347,
                0.920160710811615,
                0.6407422423362732,
                0.5945473909378052,
                0.7840591669082642,
                0.8189613223075867,
                0.5591421127319336,
                0.7356712222099304,
                0.8778046369552612,
                0.8643861413002014,
                0.9654923677444458,
                0.8565984964370728,
                0.8931609988212585,
                0.6215466856956482,
                0.460955411195755
            ],
            [
                0.7185209393501282,
                0.6544240713119507,
                0.6132987141609192,
                0.672395646572113,
                0.8539453148841858,
                0.7173447012901306,
                0.9860352873802185,
                0.8778565526008606,
                0.8450455665588379,
                0.6593464612960815,
                0.739278256893158,
                0.687702476978302,
                0.9765136241912842,
                1.0062246322631836,
                0.7373765707015991,
                0.44858428835868835
            ],
            [
                0.7972107529640198,
                0.6791996359825134,
                0.8583879470825195,
                0.8198728561401367,
                1.3412880897521973,
                0.8738171458244324,
                0.9967449307441711,
                0.8851816654205322,
                1.0132142305374146,
                0.6693577170372009,
                0.08820558339357376,
                0.8700873255729675,
                0.4852917790412903,
                0.693490207195282,
                0.5143024325370789,
                0.7415326237678528
            ],
            [
                0.7677300572395325,
                0.8356773853302002,
                0.875949501991272,
                0.695293128490448,
                0.5810998678207397,
                0.7621875405311584,
                1.0823793411254883,
                1.288676142692566,
                1.0597350597381592,
                0.8269163966178894,
                0.7530887126922607,
                0.9182569980621338,
                0.7880252599716187,
                0.5652609467506409,
                0.6262280941009521,
                0.6246300339698792
            ],
            [
                0.8203364610671997,
                0.9449567794799805,
                1.0308153629302979,
                0.756020724773407,
                0.6121401190757751,
                1.1362112760543823,
                0.7871862053871155,
                0.6651607155799866,
                0.8079867959022522,
                0.946672260761261,
                0.9317063689231873,
                0.9537853002548218,
                0.8098992109298706,
                0.7630832195281982,
                0.7747637629508972,
                0.7207042574882507
            ]
        ],
        [
            [
                2.544872522354126,
                2.851961851119995,
                2.7016141414642334,
                1.3173401355743408,
                3.6794636249542236,
                1.6347070932388306,
                0.7237233519554138,
                0.37557387351989746,
                2.1330668926239014,
                1.6160602569580078,
                0.8425278663635254,
                0.8232274651527405,
                -0.12740372121334076,
                1.6233062744140625,
                4.525710582733154,
                3.6602354049682617
            ],
            [
                -0.12447348237037659,
                4.257967472076416,
                0.6186911463737488,
                0.8228906989097595,
                0.9531708359718323,
                5.1014628410339355,
                1.0970336198806763,
                4.4524054527282715,
                2.5224814414978027,
                -0.10797656327486038,
                -0.11864740401506424,
                0.535865068435669,
                -0.13095897436141968,
                1.9531655311584473,
                1.9855836629867554,
                1.7209323644638062
            ],
            [
                0.06472516804933548,
                3.8622422218322754,
                3.723062515258789,
                0.7474535703659058,
                1.2658239603042603,
                3.251105785369873,
                5.259347915649414,
                -0.1453414112329483,
                -0.07067295908927917,
                -0.010726089589297771,
                -0.12581518292427063,
                2.6888749599456787,
                3.450650453567505,
                3.4983394145965576,
                2.590083122253418,
                7.857355117797852
            ],
            [
                3.0506296157836914,
                0.9428480267524719,
                -0.09145858138799667,
                1.339401125907898,
                3.4293224811553955,
                1.1266381740570068,
                1.3238462209701538,
                -0.026950668543577194,
                1.2564008235931396,
                -0.16093629598617554,
                -0.02391939051449299,
                0.9825653433799744,
                1.5406550168991089,
                7.610488414764404,
                6.043964862823486,
                2.666222333908081
            ],
            [
                4.866156578063965,
                2.211731433868408,
                0.7217809557914734,
                -0.12110384553670883,
                -0.16852475702762604,
                0.1846628338098526,
                3.055475950241089,
                3.425025701522827,
                3.2849929332733154,
                0.8585802912712097,
                -0.14039890468120575,
                2.2189109325408936,
                3.1255900859832764,
                0.5826427936553955,
                2.555558681488037,
                -0.06686574965715408
            ],
            [
                2.7307493686676025,
                0.4016534984111786,
                3.4275665283203125,
                -0.16983124613761902,
                0.16105176508426666,
                0.9242834448814392,
                -0.1523059755563736,
                1.0969886779785156,
                -0.16077204048633575,
                -0.05131124332547188,
                2.387326955795288,
                11.538466453552246,
                2.2718119621276855,
                0.9325875043869019,
                0.8997140526771545,
                1.5780351161956787
            ],
            [
                0.7543440461158752,
                -0.1700248271226883,
                2.2163262367248535,
                0.5715218782424927,
                1.6566523313522339,
                2.879805326461792,
                3.2520852088928223,
                0.4733846187591553,
                4.616705417633057,
                4.253057956695557,
                3.7707579135894775,
                4.048177719116211,
                0.9633548855781555,
                9.798849105834961,
                7.203616142272949,
                5.729913711547852
            ],
            [
                0.47148072719573975,
                2.4356985092163086,
                3.846506118774414,
                3.5420753955841064,
                0.8743312954902649,
                0.5888844728469849,
                4.182406425476074,
                1.713557243347168,
                5.711918354034424,
                1.2764744758605957,
                -0.08947484195232391,
                -0.16044330596923828,
                -0.09638529270887375,
                9.465274810791016,
                4.97562837600708,
                1.0408517122268677
            ],
            [
                -0.02781590260565281,
                0.10102006047964096,
                0.2470385730266571,
                0.29612281918525696,
                0.12346532195806503,
                0.12681657075881958,
                0.0428319051861763,
                0.19434180855751038,
                0.29884928464889526,
                0.5307026505470276,
                0.534023106098175,
                0.27180373668670654,
                -0.04134371131658554,
                0.026555566117167473,
                -0.045509472489356995,
                -0.06899353861808777
            ],
            [
                0.44038957357406616,
                0.37444138526916504,
                0.45607128739356995,
                0.26391464471817017,
                -0.022375239059329033,
                0.22824156284332275,
                0.008264624513685703,
                0.20657160878181458,
                0.15728974342346191,
                -0.007652563508599997,
                0.29984787106513977,
                0.291474312543869,
                0.11535932868719101,
                0.2619279623031616,
                0.16808268427848816,
                0.31946858763694763
            ],
            [
                0.10636699944734573,
                0.15710702538490295,
                0.29949262738227844,
                0.049415092915296555,
                0.3503151535987854,
                0.2865060567855835,
                0.4035576283931732,
                0.33424508571624756,
                0.20186713337898254,
                0.48605239391326904,
                0.3012973964214325,
                0.13340799510478973,
                0.38614508509635925,
                0.47733888030052185,
                0.19127419590950012,
                0.09950077533721924
            ],
            [
                0.14603018760681152,
                0.4092032313346863,
                -0.0830271914601326,
                -0.07587794214487076,
                0.15012669563293457,
                0.09421855211257935,
                0.24321340024471283,
                0.15318889915943146,
                -0.009781735017895699,
                0.13410519063472748,
                0.0258925911039114,
                0.3343816101551056,
                0.21848958730697632,
                0.08448117971420288,
                0.23186355829238892,
                0.33067142963409424
            ],
            [
                0.17928726971149445,
                0.4262165129184723,
                0.12266483157873154,
                0.029842590913176537,
                0.28637412190437317,
                0.11385148018598557,
                0.18348370492458344,
                0.09895773977041245,
                -0.04660932719707489,
                0.36495816707611084,
                0.11671929061412811,
                0.40885069966316223,
                0.1627802550792694,
                0.23784087598323822,
                0.20696257054805756,
                0.34477004408836365
            ],
            [
                0.02635134942829609,
                -0.017547691240906715,
                0.18954122066497803,
                0.17046451568603516,
                0.3927638530731201,
                0.2636910080909729,
                0.37756118178367615,
                -0.06283723562955856,
                -0.03831390291452408,
                0.25982505083084106,
                0.1941716969013214,
                0.7756810784339905,
                0.29205965995788574,
                0.24638135731220245,
                0.1691691130399704,
                0.04006235674023628
            ],
            [
                0.22481127083301544,
                -0.021495787426829338,
                0.21325884759426117,
                0.4554334580898285,
                0.24136526882648468,
                -0.053864043205976486,
                0.35192811489105225,
                0.3997173011302948,
                0.3523865342140198,
                0.23515678942203522,
                -0.07404676824808121,
                0.3063957691192627,
                0.3484727740287781,
                0.46608781814575195,
                -0.049332451075315475,
                -0.11057478189468384
            ],
            [
                0.22421136498451233,
                0.1695903241634369,
                0.12503601610660553,
                0.39029425382614136,
                0.005460039712488651,
                0.23259517550468445,
                0.2950765788555145,
                0.13901099562644958,
                0.3085724115371704,
                0.41207849979400635,
                0.214832603931427,
                0.13747860491275787,
                0.1860482096672058,
                0.3096150755882263,
                0.0492033027112484,
                0.11913371831178665
            ]
        ],
        [
            [
                3.2407872676849365,
                2.1882810592651367,
                3.62434458732605,
                3.960171699523926,
                4.344747543334961,
                3.7334413528442383,
                3.7812604904174805,
                3.557112216949463,
                4.884361743927002,
                2.300389051437378,
                3.931032180786133,
                5.361560821533203,
                3.7302277088165283,
                4.008577346801758,
                6.4200239181518555,
                3.9424357414245605
            ],
            [
                1.3014672994613647,
                8.226161003112793,
                4.339539527893066,
                3.5770719051361084,
                2.4018521308898926,
                5.586000919342041,
                3.129910707473755,
                5.435791015625,
                3.4126110076904297,
                0.8648828864097595,
                2.580787181854248,
                0.8102878332138062,
                3.1207568645477295,
                3.8048858642578125,
                2.4464967250823975,
                4.685985088348389
            ],
            [
                3.8226919174194336,
                2.874885320663452,
                2.8406870365142822,
                1.3147567510604858,
                3.175913095474243,
                4.574262619018555,
                7.288881778717041,
                4.79705810546875,
                4.10048246383667,
                2.2685468196868896,
                2.1549041271209717,
                3.0759835243225098,
                3.7306840419769287,
                6.2320404052734375,
                5.231517314910889,
                11.055648803710938
            ],
            [
                1.389955759048462,
                1.5629454851150513,
                3.3532299995422363,
                6.773434162139893,
                6.711674213409424,
                4.825324535369873,
                5.957904815673828,
                4.135225772857666,
                6.052707195281982,
                0.773648738861084,
                3.7793824672698975,
                4.489192008972168,
                2.471601724624634,
                6.559316158294678,
                4.511645317077637,
                7.120438098907471
            ],
            [
                4.163395881652832,
                3.738262414932251,
                3.1361966133117676,
                2.9324734210968018,
                4.348108291625977,
                4.092641353607178,
                4.538248538970947,
                2.933331251144409,
                3.850957155227661,
                2.712801933288574,
                4.463012218475342,
                4.228019714355469,
                4.592172622680664,
                3.3306891918182373,
                3.325376272201538,
                1.828463077545166
            ],
            [
                3.7044966220855713,
                4.346874713897705,
                4.3234968185424805,
                2.434230327606201,
                2.7325830459594727,
                1.996993064880371,
                2.9132332801818848,
                3.0851798057556152,
                3.7007815837860107,
                2.1962196826934814,
                6.939050674438477,
                7.7438130378723145,
                2.6609108448028564,
                4.531974792480469,
                5.560239791870117,
                4.696793079376221
            ],
            [
                2.722003698348999,
                3.9250588417053223,
                2.1159989833831787,
                4.261476993560791,
                3.8816285133361816,
                2.662562131881714,
                1.6206952333450317,
                1.2902803421020508,
                6.410342216491699,
                6.7690019607543945,
                4.967072486877441,
                4.531399250030518,
                5.102442741394043,
                6.97210693359375,
                5.980562686920166,
                4.309528350830078
            ],
            [
                2.1311252117156982,
                6.09320068359375,
                5.9458327293396,
                3.8119935989379883,
                -0.169500932097435,
                0.9797847867012024,
                2.816812038421631,
                2.033097743988037,
                6.174568176269531,
                3.7067503929138184,
                1.582983136177063,
                0.5476433634757996,
                4.409440994262695,
                8.130831718444824,
                4.518556594848633,
                2.900099754333496
            ],
            [
                0.5373111963272095,
                0.33943477272987366,
                0.5284151434898376,
                0.3019736111164093,
                -0.008574917912483215,
                0.06550104916095734,
                -0.04365367069840431,
                0.22418436408042908,
                0.43917813897132874,
                0.18960335850715637,
                0.6800974607467651,
                0.42605501413345337,
                0.45360267162323,
                -0.06555819511413574,
                0.04827497899532318,
                0.2263033092021942
            ],
            [
                0.45520588755607605,
                -0.01532666478306055,
                0.03112894482910633,
                0.6754304766654968,
                0.6711223721504211,
                0.5320420861244202,
                0.07722851634025574,
                0.0004395792493596673,
                0.06729143112897873,
                0.11452785134315491,
                0.27635902166366577,
                -0.024295546114444733,
                -0.04422721266746521,
                -0.1198904737830162,
                -0.0818372443318367,
                0.05955783277750015
            ],
            [
                0.08588144183158875,
                0.2633472979068756,
                0.029970087110996246,
                0.15618200600147247,
                -0.04122396185994148,
                -0.08229579776525497,
                0.1434604674577713,
                0.07073400914669037,
                0.34590354561805725,
                0.3114871680736542,
                0.1336279660463333,
                0.38209637999534607,
                0.4374425709247589,
                0.05048280209302902,
                0.029443006962537766,
                0.02830834686756134
            ],
            [
                0.1057775467634201,
                -0.05808153375983238,
                0.2658381760120392,
                0.3994494378566742,
                0.22625991702079773,
                0.3159521222114563,
                0.3142959177494049,
                0.14699634909629822,
                0.44401708245277405,
                0.026805879548192024,
                -0.04102906957268715,
                0.06612052768468857,
                -0.10464517772197723,
                0.014478492550551891,
                -0.11411547660827637,
                -0.06426596641540527
            ],
            [
                0.18133488297462463,
                0.33023834228515625,
                0.4176313877105713,
                -0.06871837377548218,
                0.1503627598285675,
                0.9467155337333679,
                -0.0890038013458252,
                -0.041771937161684036,
                0.01693190261721611,
                0.05819103494286537,
                0.39000657200813293,
                0.7702746987342834,
                0.24694278836250305,
                0.3571677505970001,
                -0.015877841040492058,
                0.06906479597091675
            ],
            [
                -0.0008921849075704813,
                -0.06756360828876495,
                0.42255571484565735,
                0.5002784132957458,
                -0.1392582207918167,
                -0.12411638349294662,
                -0.04766606539487839,
                0.08761192858219147,
                0.044725410640239716,
                0.3259577751159668,
                1.459316372871399,
                0.9725081324577332,
                0.5185582041740417,
                0.27426809072494507,
                0.002841140143573284,
                0.2413369119167328
            ],
            [
                0.11717068403959274,
                -0.07072126120328903,
                0.1466202586889267,
                0.25848907232284546,
                0.5814804434776306,
                1.1533315181732178,
                0.3352068066596985,
                0.06038210168480873,
                -0.003703100373968482,
                0.39772355556488037,
                0.4909795820713043,
                -0.04041145741939545,
                -0.10238119214773178,
                0.1785823553800583,
                0.8989226222038269,
                0.6013508439064026
            ],
            [
                0.2060719132423401,
                -0.05576438456773758,
                0.2582383453845978,
                0.07449166476726532,
                -0.12849800288677216,
                0.07885225862264633,
                0.09236549586057663,
                0.07778602093458176,
                0.26230624318122864,
                0.2730201482772827,
                0.35503485798835754,
                -0.1274183690547943,
                -0.0936993956565857,
                -0.1382114589214325,
                -0.14214885234832764,
                -0.14627006649971008
            ]
        ],
        [
            [
                -0.1251114457845688,
                0.10564188659191132,
                0.2290123701095581,
                0.12203075736761093,
                0.3132714331150055,
                0.08083483576774597,
                -0.14726786315441132,
                0.12535080313682556,
                -0.0033914705272763968,
                -0.09653281420469284,
                -0.018350588157773018,
                -0.14886847138404846,
                -0.0833561047911644,
                -0.16904820501804352,
                0.23010025918483734,
                0.9693558812141418
            ],
            [
                -0.11459611356258392,
                -0.13913899660110474,
                -0.04336061328649521,
                0.05411938577890396,
                -0.12474650889635086,
                0.40378275513648987,
                0.024376465007662773,
                0.43628591299057007,
                1.0894956588745117,
                -0.048412781208753586,
                -0.11947502195835114,
                -0.10792570561170578,
                -0.1668054610490799,
                -0.13895684480667114,
                1.489892840385437,
                0.608030378818512
            ],
            [
                -0.1425602287054062,
                -0.1198979914188385,
                -0.16905200481414795,
                -0.08299283683300018,
                -0.16103596985340118,
                1.022821068763733,
                -0.09387161582708359,
                0.3938417136669159,
                0.1152796521782875,
                -0.1655232459306717,
                -0.1652875393629074,
                -0.10895595699548721,
                0.26212626695632935,
                -0.15984103083610535,
                2.605241298675537,
                0.6782463788986206
            ],
            [
                -0.16925086081027985,
                -0.15905576944351196,
                -0.16573207080364227,
                -0.16761085391044617,
                -0.15049609541893005,
                2.229809045791626,
                1.0371806621551514,
                -0.07660744339227676,
                -0.1421397179365158,
                -0.14367085695266724,
                0.0612039752304554,
                -0.13628347218036652,
                -0.031111707910895348,
                0.4190075099468231,
                -0.05532660707831383,
                0.35732629895210266
            ],
            [
                -0.16349530220031738,
                -0.16890189051628113,
                -0.16742989420890808,
                0.16151733696460724,
                0.01678660698235035,
                0.8381029963493347,
                0.6970349550247192,
                -0.08539198338985443,
                -0.15286566317081451,
                0.006139217410236597,
                0.1314641237258911,
                -0.15468868613243103,
                -0.16993799805641174,
                -0.12224157154560089,
                -0.06571293622255325,
                0.2380894273519516
            ],
            [
                -0.14943356812000275,
                -0.13802321255207062,
                -0.15135769546031952,
                -0.1334153711795807,
                -0.16168956458568573,
                -0.1696680784225464,
                -0.16573676466941833,
                -0.16973985731601715,
                -0.12220741808414459,
                -0.1672753542661667,
                0.7066832184791565,
                1.5014928579330444,
                1.0007127523422241,
                0.028967082500457764,
                0.44023045897483826,
                0.5775593519210815
            ],
            [
                0.028481800109148026,
                -0.1517316699028015,
                -0.08553463220596313,
                1.1549869775772095,
                -0.16976292431354523,
                -0.16398441791534424,
                -0.16769714653491974,
                -0.14733773469924927,
                -0.16782985627651215,
                1.0647672414779663,
                0.31712350249290466,
                -0.09285931289196014,
                0.2407865673303604,
                1.6555042266845703,
                1.7144999504089355,
                0.28483784198760986
            ],
            [
                -0.13561852276325226,
                0.4320478141307831,
                -0.11326869577169418,
                2.47540545463562,
                1.6692113876342773,
                -0.13824890553951263,
                -0.10675044357776642,
                -0.13256242871284485,
                0.7398325800895691,
                1.1358410120010376,
                -0.13799181580543518,
                -0.1637352705001831,
                -0.01024995744228363,
                1.6881163120269775,
                3.3638906478881836,
                1.1658090353012085
            ],
            [
                0.5705726742744446,
                0.6310897469520569,
                0.9774055480957031,
                1.094979166984558,
                1.189246416091919,
                0.958939254283905,
                0.8999615907669067,
                0.9545620679855347,
                0.9604180455207825,
                0.7828551530838013,
                0.9842337369918823,
                0.5074889063835144,
                0.7512348294258118,
                0.8355149626731873,
                1.1019583940505981,
                1.2088494300842285
            ],
            [
                0.5492907762527466,
                1.0131880044937134,
                0.8038332462310791,
                0.8793023228645325,
                0.9575832486152649,
                1.2602509260177612,
                0.9901077151298523,
                0.9876692295074463,
                0.9575760960578918,
                0.9021022319793701,
                1.0737394094467163,
                1.0168967247009277,
                0.744701087474823,
                0.8385133743286133,
                1.285441279411316,
                1.023046612739563
            ],
            [
                0.8440507054328918,
                0.9207345843315125,
                0.9954272508621216,
                1.0954651832580566,
                1.0678147077560425,
                1.1198811531066895,
                0.9046114683151245,
                1.0091854333877563,
                1.0889185667037964,
                0.9611958861351013,
                1.2436186075210571,
                0.7435747981071472,
                0.6676889061927795,
                0.9244981408119202,
                0.8839330077171326,
                1.167542576789856
            ],
            [
                0.6948390603065491,
                0.9872885346412659,
                1.0093157291412354,
                1.1822870969772339,
                1.060451865196228,
                1.0593522787094116,
                0.9423022866249084,
                0.9177407026290894,
                1.0288928747177124,
                1.041433572769165,
                1.0694667100906372,
                1.274841070175171,
                1.1003270149230957,
                0.9610490202903748,
                1.0997493267059326,
                1.2762256860733032
            ],
            [
                0.8774657845497131,
                0.9214890003204346,
                1.1609363555908203,
                1.2536121606826782,
                1.2169402837753296,
                1.1095936298370361,
                1.0917104482650757,
                0.9169813990592957,
                1.0480104684829712,
                0.7578072547912598,
                1.110361099243164,
                0.8252527117729187,
                1.229636311531067,
                1.153562068939209,
                1.2443654537200928,
                1.0014342069625854
            ],
            [
                0.648145318031311,
                0.8614389896392822,
                1.216949462890625,
                0.8613212704658508,
                0.9665372371673584,
                1.2590676546096802,
                0.9924347400665283,
                0.8094235062599182,
                1.233215570449829,
                1.0165281295776367,
                1.0915822982788086,
                1.140041470527649,
                0.9212193489074707,
                1.063629150390625,
                1.1823844909667969,
                1.0774317979812622
            ],
            [
                0.642419159412384,
                0.9331409931182861,
                1.0489387512207031,
                1.0371536016464233,
                1.0137308835983276,
                1.2727949619293213,
                0.9812135100364685,
                0.7945963740348816,
                0.8170346021652222,
                1.1712992191314697,
                1.0399171113967896,
                1.0978890657424927,
                1.003122329711914,
                1.0200517177581787,
                1.1010358333587646,
                1.1354339122772217
            ],
            [
                0.8250775337219238,
                0.8676106333732605,
                1.1060069799423218,
                0.7976893782615662,
                0.8160061836242676,
                1.3576698303222656,
                0.9999873638153076,
                0.7702476382255554,
                0.8000826835632324,
                0.8434793949127197,
                0.8736447691917419,
                0.9569618701934814,
                0.8966636657714844,
                0.7673913836479187,
                1.0576611757278442,
                1.3199272155761719
            ]
        ],
        [
            [
                -0.008266225457191467,
                -0.000512013619299978,
                -0.053264956921339035,
                -0.008756358176469803,
                -0.00312241492792964,
                -0.00043749611359089613,
                -0.05435287952423096,
                -0.12427593767642975,
                -0.05389595776796341,
                -0.15255410969257355,
                -0.14621688425540924,
                -0.14808416366577148,
                -0.15583960711956024,
                -0.06786065548658371,
                0,
                -0.04765917360782623
            ],
            [
                -0.1685522049665451,
                0,
                -0.0002603813190944493,
                -0.0891527384519577,
                -0.0000023535060336143943,
                -0.000005357426289265277,
                -0.000019168068320141174,
                -0.000013778956599708181,
                -0.0006562175694853067,
                -0.0245777890086174,
                -0.07209097594022751,
                -0.015703480690717697,
                -0.0015803739661350846,
                -0.002785454737022519,
                -0.00000675818864692701,
                -0.022023959085345268
            ],
            [
                -0.012779044918715954,
                -0.04047941416501999,
                -0.06514470279216766,
                -0.14422360062599182,
                -0.00001831235204008408,
                -0.000007409134468616685,
                -0.03568491339683533,
                -0.0009562207269482315,
                -0.00041955127380788326,
                -0.05175653100013733,
                -0.10367536544799805,
                -0.016012713313102722,
                -0.0001129347801906988,
                0,
                0,
                -0.031080344691872597
            ],
            [
                -0.0002781357616186142,
                -0.026400126516819,
                -0.11285775899887085,
                -0.005733977071940899,
                -0.0000011395853789508692,
                -0.0035581528209149837,
                -0.0000014063676871955977,
                -0.014926500618457794,
                -0.05678253620862961,
                -0.08755317330360413,
                -0.1566360592842102,
                -0.009295993484556675,
                -0.0027597479056566954,
                -0.003690471174195409,
                0,
                -0.01092460285872221
            ],
            [
                0,
                -2.9205810392340936e-7,
                -0.012710019946098328,
                -0.14064562320709229,
                -0.00033200863981619477,
                -0.000008795658686722163,
                0,
                -0.000044640866690315306,
                -0.0000011401505162211834,
                -0.022806236520409584,
                -0.1551675796508789,
                -0.009772615507245064,
                -0.023134702816605568,
                -0.09576619416475296,
                -0.0015918611316010356,
                -0.03711111098527908
            ],
            [
                -0.10648337006568909,
                -0.027664998546242714,
                -0.03458452969789505,
                -0.0477755069732666,
                -0.007917841896414757,
                -0.0026487174909561872,
                -0.013022856786847115,
                -0.006911817472428083,
                -0.004760670941323042,
                -0.0011640323791652918,
                -0.01870996318757534,
                -2.045090212732248e-7,
                -0.00491695711389184,
                -4.364241874554864e-7,
                0,
                -0.13838626444339752
            ],
            [
                -0.16841275990009308,
                -0.017267616465687752,
                -0.0013050956185907125,
                -0.03835490718483925,
                -0.002773314481601119,
                -0.008935979567468166,
                -0.03069821186363697,
                -0.09310508519411087,
                -0.0003310961474198848,
                0,
                -0.0013936135219410062,
                -0.024203866720199585,
                -0.0000032780299079604447,
                0,
                -2.0206726958349464e-7,
                -0.0001156852813437581
            ],
            [
                -0.036795731633901596,
                -0.0005065698060207069,
                0,
                -0.001398305525071919,
                -0.17002373933792114,
                -0.14650602638721466,
                -0.00010299524001311511,
                -0.02326914668083191,
                -0.0000674926268402487,
                -0.000010297485459886957,
                -0.16913525760173798,
                -0.03888649120926857,
                -0.022942636162042618,
                -1.7728881118728168e-7,
                -2.933544465122395e-7,
                -0.1311158984899521
            ],
            [
                0.050189223140478134,
                -0.11615578085184097,
                -0.008860563859343529,
                0.031405992805957794,
                -0.019047779962420464,
                0.11195269227027893,
                -0.04148133471608162,
                0.22066938877105713,
                0.13179978728294373,
                0.10933136194944382,
                0.18719783425331116,
                -0.07174407690763474,
                -0.10561875253915787,
                -0.10459837317466736,
                -0.14453229308128357,
                -0.16329598426818848
            ],
            [
                -0.06966129690408707,
                0.2187853902578354,
                0.428286075592041,
                0.22508491575717926,
                0.06365630030632019,
                -0.01096712052822113,
                0.4713873267173767,
                0.9389878511428833,
                -0.07243182510137558,
                0.47931018471717834,
                0.4873460531234741,
                0.08616730570793152,
                0.04930148273706436,
                0.03436785191297531,
                0.13367478549480438,
                0.07447399944067001
            ],
            [
                0.0189678892493248,
                -0.14520783722400665,
                0.2271668165922165,
                0.07855603843927383,
                0.043881554156541824,
                0.2902594804763794,
                1.0214780569076538,
                1.0259475708007812,
                0.7612220048904419,
                0.3113158941268921,
                0.4147721827030182,
                -0.13920623064041138,
                -0.0232070479542017,
                0.2674045264720917,
                -0.03548995405435562,
                0.13510732352733612
            ],
            [
                -0.049787506461143494,
                0.23005986213684082,
                -0.03215242177248001,
                -0.1530182957649231,
                -0.10968372970819473,
                -0.007585400249809027,
                0.40702101588249207,
                -0.14625142514705658,
                -0.003805650630965829,
                0.451430082321167,
                0.13498333096504211,
                0.3265669345855713,
                -0.15161092579364777,
                0.02862613834440708,
                0.6662317514419556,
                0.9091328978538513
            ],
            [
                0.3712109625339508,
                0.9273516535758972,
                0.0885513573884964,
                0.3184378743171692,
                0.17464342713356018,
                -0.04846157133579254,
                0.14988143742084503,
                -0.05466173216700554,
                -0.08498864620923996,
                0.0250996146351099,
                0.618865966796875,
                0.10938376933336258,
                -0.05745633691549301,
                0.05276460200548172,
                0.4075644612312317,
                0.25525084137916565
            ],
            [
                0.12662138044834137,
                0.6962171792984009,
                0.19756929576396942,
                0.2492487132549286,
                0.695499837398529,
                0.32267633080482483,
                0.23102974891662598,
                -0.06509849429130554,
                0.016049837693572044,
                0.03204602748155594,
                -0.13565698266029358,
                -0.1483885645866394,
                -0.10768033564090729,
                -0.11339474469423294,
                0.09911728650331497,
                0.15435956418514252
            ],
            [
                0.07178772985935211,
                0.2774854600429535,
                0.0069107189774513245,
                -0.14413906633853912,
                0.05251479521393776,
                0.47596418857574463,
                -0.07807184010744095,
                -0.04750388115644455,
                0.050469543784856796,
                0.2903938591480255,
                -0.14326322078704834,
                -0.16605152189731598,
                0.10787102580070496,
                0.0710470974445343,
                0.1831609457731247,
                -0.08562281727790833
            ],
            [
                0.30244192481040955,
                -0.06630559265613556,
                -0.02368607558310032,
                -0.16619576513767242,
                -0.13440538942813873,
                0.07295770198106766,
                -0.13014769554138184,
                -0.13228072226047516,
                0.11439236253499985,
                0.09684973210096359,
                -0.13606318831443787,
                -0.02480464242398739,
                0.1687677502632141,
                0.007427081931382418,
                0.14398159086704254,
                -0.14362114667892456
            ]
        ],
        [
            [
                1.5450952053070068,
                1.984433889389038,
                2.5202181339263916,
                3.7515833377838135,
                1.7027885913848877,
                1.5334501266479492,
                2.3232452869415283,
                1.4652405977249146,
                2.3255231380462646,
                1.6508713960647583,
                1.613945722579956,
                2.465366840362549,
                1.5377528667449951,
                1.4253572225570679,
                1.5969407558441162,
                1.856622338294983
            ],
            [
                2.029710531234741,
                3.4216761589050293,
                2.447969913482666,
                2.05422043800354,
                1.1092339754104614,
                2.81997013092041,
                1.359126091003418,
                2.21327543258667,
                1.2549959421157837,
                1.3135261535644531,
                1.032029628753662,
                1.0742089748382568,
                2.0398166179656982,
                1.3408867120742798,
                0.6897681355476379,
                4.533552169799805
            ],
            [
                3.3324060440063477,
                1.3873473405838013,
                2.201544761657715,
                0.4107574224472046,
                1.0429964065551758,
                1.9656627178192139,
                2.099719285964966,
                0.4456648826599121,
                2.458144187927246,
                2.1124582290649414,
                1.9955044984817505,
                1.5959270000457764,
                2.108741044998169,
                2.8485445976257324,
                4.070515155792236,
                5.411381244659424
            ],
            [
                1.1839414834976196,
                1.9679455757141113,
                0.7875853776931763,
                1.8310317993164062,
                2.363631248474121,
                3.0563571453094482,
                2.521036386489868,
                1.3179572820663452,
                3.3836212158203125,
                0.8281038403511047,
                1.490003228187561,
                1.7599068880081177,
                2.2532799243927,
                1.7969112396240234,
                3.4999427795410156,
                2.9694204330444336
            ],
            [
                2.0921711921691895,
                1.159047245979309,
                1.645609736442566,
                0.561004102230072,
                3.0504679679870605,
                1.600216031074524,
                3.0932092666625977,
                1.4489847421646118,
                2.356618642807007,
                0.9387120008468628,
                2.7486865520477295,
                2.8211841583251953,
                2.316316843032837,
                2.0466861724853516,
                1.439758062362671,
                1.7099541425704956
            ],
            [
                1.0952167510986328,
                2.1504228115081787,
                2.080859661102295,
                1.4946171045303345,
                1.9494976997375488,
                1.1519811153411865,
                1.7389086484909058,
                1.4514037370681763,
                2.0306661128997803,
                1.535591959953308,
                3.3226101398468018,
                5.425082206726074,
                2.519927740097046,
                1.972442388534546,
                2.98164963722229,
                2.8322463035583496
            ],
            [
                1.3994263410568237,
                2.1758196353912354,
                1.265787959098816,
                2.6852967739105225,
                1.7867602109909058,
                1.8449461460113525,
                1.8820476531982422,
                2.061875820159912,
                2.0440826416015625,
                2.2437479496002197,
                1.2032625675201416,
                1.165581226348877,
                2.070293664932251,
                4.827631950378418,
                3.50382137298584,
                0.8722097873687744
            ],
            [
                1.605102777481079,
                2.7641055583953857,
                3.306612253189087,
                4.20941686630249,
                0.9592567682266235,
                1.1226410865783691,
                1.4476498365402222,
                0.9459061026573181,
                2.4160404205322266,
                4.1505632400512695,
                1.0975849628448486,
                1.718328833580017,
                2.820319175720215,
                5.780640125274658,
                6.858331680297852,
                1.515167474746704
            ],
            [
                0.2545814514160156,
                0.11580369621515274,
                -0.16897432506084442,
                0.03636573627591133,
                0.3212617039680481,
                0.7487216591835022,
                0.7949516773223877,
                0.2414955198764801,
                0.07296854257583618,
                -0.01463758759200573,
                0.5278932452201843,
                0.5687456727027893,
                0.6559586524963379,
                0.36486366391181946,
                0.41389578580856323,
                0.7410944700241089
            ],
            [
                0.38357433676719666,
                0.20765554904937744,
                0.033457718789577484,
                0.29936039447784424,
                0.25789356231689453,
                0.578163206577301,
                0.7930919528007507,
                0.06426315754652023,
                0.24217373132705688,
                0.27979326248168945,
                0.3940597176551819,
                0.4173024892807007,
                0.6448537111282349,
                0.5688877105712891,
                0.2177700251340866,
                0.3408864736557007
            ],
            [
                0.29167482256889343,
                0.424269437789917,
                0.0881819948554039,
                0.3060414791107178,
                -0.00000283120789390523,
                0.24543441832065582,
                0.20486007630825043,
                0.320150226354599,
                0.4015585482120514,
                0.2108180820941925,
                0.2611055374145508,
                0.6478931307792664,
                1.0281095504760742,
                0.4203425645828247,
                0.557106077671051,
                0.5180060863494873
            ],
            [
                0.8668628931045532,
                0.46711465716362,
                0.46446147561073303,
                0.2331327348947525,
                0.11173346638679504,
                0.22842559218406677,
                0.5053690075874329,
                0.46924662590026855,
                0.608941376209259,
                0.45015808939933777,
                0.27918127179145813,
                0.654671311378479,
                0.6553674936294556,
                0.33646026253700256,
                0.45073989033699036,
                -0.12042322009801865
            ],
            [
                0.3762536644935608,
                0.2704594135284424,
                0.314282089471817,
                0.08004331588745117,
                0.08792869746685028,
                0.6631807088851929,
                0.4917011260986328,
                0.43315550684928894,
                0.45522114634513855,
                0.6429874897003174,
                0.41060811281204224,
                0.45507946610450745,
                0.3796039819717407,
                0.29112544655799866,
                0.26343613862991333,
                -0.089141346514225
            ],
            [
                0.24291522800922394,
                0.23838211596012115,
                -0.00682529341429472,
                0.14873851835727692,
                0.017693402245640755,
                0.34765878319740295,
                0.6134771704673767,
                0.6360182166099548,
                0.6845142841339111,
                0.8125206232070923,
                0.3506968319416046,
                -0.1062987819314003,
                0.38714078068733215,
                0.5511763095855713,
                0.27817556262016296,
                0.5440878868103027
            ],
            [
                0.1777179092168808,
                0.5374235510826111,
                0.4749148190021515,
                0.3725341558456421,
                0.18469683825969696,
                0.5675182342529297,
                0.7052301168441772,
                0.6548535823822021,
                0.6865702867507935,
                0.41345956921577454,
                0.5016854405403137,
                0.5208104848861694,
                0.377096563577652,
                0.490374356508255,
                0.40162524580955505,
                0.48939627408981323
            ],
            [
                0.4196804463863373,
                0.9314259886741638,
                0.8107615113258362,
                0.4378945827484131,
                0.4192621409893036,
                0.32400211691856384,
                0.29473164677619934,
                0.790975034236908,
                0.3062257468700409,
                0.5504910945892334,
                0.7237616181373596,
                0.5053764581680298,
                0.6304446458816528,
                0.6196459531784058,
                0.3775656819343567,
                0.38037800788879395
            ]
        ],
        [
            [
                0.39979973435401917,
                2.4951212406158447,
                4.09752082824707,
                4.497599124908447,
                5.637639999389648,
                2.019542694091797,
                0.7223945260047913,
                0.6788280010223389,
                2.4313793182373047,
                0.6566585898399353,
                1.4607093334197998,
                0.5013596415519714,
                0.8321737051010132,
                0.27667132019996643,
                4.334261894226074,
                8.476730346679688
            ],
            [
                3.2573211193084717,
                0.6235222220420837,
                1.602315902709961,
                3.040739059448242,
                -0.108562171459198,
                4.479870796203613,
                2.7614548206329346,
                4.601442337036133,
                5.204983711242676,
                2.782909393310547,
                0.33235958218574524,
                0.05162803828716278,
                -0.13614784181118011,
                0.8942614793777466,
                5.194882869720459,
                7.15845251083374
            ],
            [
                0.9215506911277771,
                4.491467475891113,
                -0.07878872752189636,
                0.8152528405189514,
                1.057206630706787,
                4.108442306518555,
                4.818558692932129,
                2.1769940853118896,
                2.485966205596924,
                0.19447779655456543,
                0.7871795892715454,
                0.1834753453731537,
                4.6703057289123535,
                4.22381067276001,
                6.629777908325195,
                6.362537860870361
            ],
            [
                0.8758110404014587,
                -0.07078228145837784,
                1.4456806182861328,
                1.9936680793762207,
                1.079705834388733,
                9.46618366241455,
                4.184609413146973,
                1.7998970746994019,
                1.193271279335022,
                2.396129846572876,
                0.024807458743453026,
                0.9027329087257385,
                1.113083839416504,
                6.432446479797363,
                7.6409759521484375,
                0.2759547531604767
            ],
            [
                2.8917977809906006,
                4.839917182922363,
                0.159227192401886,
                3.5467517375946045,
                3.056095600128174,
                0.6129910945892334,
                2.1977148056030273,
                3.501335859298706,
                1.615364670753479,
                0.6478567719459534,
                2.6832728385925293,
                0.6944488883018494,
                1.6878128051757812,
                1.4539737701416016,
                0.3244210481643677,
                -0.11327553540468216
            ],
            [
                -0.1624370813369751,
                -0.17003995180130005,
                0.028028616681694984,
                0.9322433471679688,
                -0.08699964731931686,
                1.4938009977340698,
                -0.09446337819099426,
                1.1568065881729126,
                -0.16643625497817993,
                0.23952463269233704,
                5.08864164352417,
                null,
                4.933757781982422,
                3.9534809589385986,
                1.064455509185791,
                -0.13484345376491547
            ],
            [
                0.02410181611776352,
                0.22820384800434113,
                2.022860288619995,
                4.766217231750488,
                1.9543472528457642,
                1.9778660535812378,
                -0.11881281435489655,
                -0.07498227804899216,
                0.9682783484458923,
                7.482285022735596,
                0.9259523749351501,
                2.241844654083252,
                2.95159912109375,
                null,
                8.525769233703613,
                7.482253074645996
            ],
            [
                -0.07414250075817108,
                4.704519271850586,
                5.702479362487793,
                8.527037620544434,
                4.88477087020874,
                0.28380903601646423,
                4.978211402893066,
                0.28742051124572754,
                6.706228733062744,
                2.745331048965454,
                2.6306440830230713,
                0.5453811883926392,
                3.56048846244812,
                12.694146156311035,
                null,
                5.0807695388793945
            ],
            [
                0.8445597887039185,
                0.3891298174858093,
                -0.12311305850744247,
                0.5372271537780762,
                0.6613965630531311,
                0.9895575046539307,
                1.1964808702468872,
                0.4668331742286682,
                0.20761388540267944,
                0.26414933800697327,
                0.4461153745651245,
                0.6878406405448914,
                0.7301942110061646,
                0.7899317741394043,
                1.0043425559997559,
                0.11375485360622406
            ],
            [
                0.2767559289932251,
                0.682377815246582,
                0.280129075050354,
                0.49797719717025757,
                0.5285520553588867,
                1.054996132850647,
                1.3686143159866333,
                0.5929186344146729,
                0.8748061060905457,
                1.0098098516464233,
                0.6093299388885498,
                1.0118812322616577,
                1.275858759880066,
                0.9213628768920898,
                0.4364699125289917,
                0.19981101155281067
            ],
            [
                0.841982901096344,
                0.8482754230499268,
                1.1786108016967773,
                0.5119405388832092,
                0.2408977746963501,
                0.3849954307079315,
                0.7648245692253113,
                0.6272129416465759,
                1.0250868797302246,
                0.5242984890937805,
                0.4628606140613556,
                1.4089884757995605,
                0.8138309717178345,
                0.6195734143257141,
                0.5881989598274231,
                0.1665969341993332
            ],
            [
                0.8124508857727051,
                0.7455982565879822,
                1.2949748039245605,
                0.2883961498737335,
                0.045542892068624496,
                0.42297834157943726,
                0.5859265327453613,
                0.7554301619529724,
                1.4597804546356201,
                1.0440070629119873,
                0.8141225576400757,
                1.091310977935791,
                1.690846562385559,
                0.9288671016693115,
                0.0870753601193428,
                -0.10775737464427948
            ],
            [
                0.9116259813308716,
                0.9241946339607239,
                0.9906332492828369,
                1.000954270362854,
                0.4714263379573822,
                0.9314936399459839,
                1.2223727703094482,
                0.3101535737514496,
                0.8190594911575317,
                0.9969903826713562,
                0.6814518570899963,
                0.4829728901386261,
                1.1897304058074951,
                1.176019310951233,
                -0.1105724573135376,
                -0.16351686418056488
            ],
            [
                0.5409522652626038,
                1.0049347877502441,
                0.2150982916355133,
                0.8832647800445557,
                0.4020787477493286,
                0.90603107213974,
                1.2426230907440186,
                1.3562493324279785,
                1.0873043537139893,
                1.152815818786621,
                0.874736487865448,
                -0.11636509746313095,
                0.4394572675228119,
                1.230799913406372,
                0.5962321758270264,
                -0.014711412601172924
            ],
            [
                0.7691546678543091,
                0.6464194655418396,
                0.8947276473045349,
                0.5060721635818481,
                0.43011391162872314,
                1.2730361223220825,
                0.969585120677948,
                0.855117917060852,
                0.8171067833900452,
                0.6914798021316528,
                0.6923187375068665,
                0.7737032771110535,
                1.2122379541397095,
                0.4787345826625824,
                0.7008532285690308,
                0.30163705348968506
            ],
            [
                0.36014309525489807,
                0.6298502087593079,
                0.6273780465126038,
                0.6754168272018433,
                0.19717583060264587,
                0.18487702310085297,
                1.00510835647583,
                0.6855432391166687,
                0.8660542964935303,
                0.7952870726585388,
                1.5845842361450195,
                0.6592363119125366,
                0.5864183902740479,
                0.492403119802475,
                0.9791163802146912,
                0.2694586217403412
            ]
        ],
        [
            [
                -0.16184642910957336,
                -0.05007118359208107,
                -0.07248710095882416,
                -0.16959328949451447,
                0.24222643673419952,
                1.8695765733718872,
                -0.15260297060012817,
                0.13217130303382874,
                -0.0367945171892643,
                0.00584883289411664,
                1.1097023487091064,
                -0.03963658958673477,
                1.415971279144287,
                0.19767744839191437,
                -0.1690613329410553,
                -0.00015797386004123837
            ],
            [
                3.6176023483276367,
                -0.1568301022052765,
                0.00007827067747712135,
                1.1834466457366943,
                -0.16998976469039917,
                0.22121836245059967,
                0.3482924997806549,
                0.20826232433319092,
                -0.13645391166210175,
                0.45842432975769043,
                -0.025653377175331116,
                0.7376910448074341,
                0.6871349811553955,
                -0.08365795761346817,
                -0.04710445925593376,
                -0.06597606092691422
            ],
            [
                0.49382975697517395,
                0.6955718398094177,
                1.138527512550354,
                1.1879256963729858,
                0.5573415756225586,
                -0.011924452148377895,
                0.23270383477210999,
                0.209793820977211,
                0.09275058656930923,
                -0.1545877754688263,
                -0.16408416628837585,
                -0.15519846975803375,
                -0.06497358530759811,
                -0.05453253164887428,
                -0.00007044256199151278,
                -0.03972061723470688
            ],
            [
                -0.1604948788881302,
                -0.1695936918258667,
                0.2481328248977661,
                0.03923846408724785,
                0.4847467839717865,
                -0.16017311811447144,
                -0.13050906360149384,
                -0.1474422663450241,
                -0.15922947227954865,
                0.8201904892921448,
                0.4410122334957123,
                0.4242783486843109,
                -0.16072463989257812,
                -0.07877451181411743,
                -0.00383473327383399,
                -0.08935073763132095
            ],
            [
                -0.09906215965747833,
                -0.12211339175701141,
                1.338442325592041,
                0.9089430570602417,
                1.2523490190505981,
                -0.16538475453853607,
                -0.009518659673631191,
                -0.14655913412570953,
                -0.11489241570234299,
                -0.0021992973051965237,
                0.6535412073135376,
                -0.05657464638352394,
                -0.037941671907901764,
                0.4204113185405731,
                -0.08845071494579315,
                -0.11315616965293884
            ],
            [
                -0.11883044242858887,
                1.1509413719177246,
                0.24599102139472961,
                1.0023969411849976,
                0.9813275933265686,
                1.1994234323501587,
                -0.15088562667369843,
                -0.15613529086112976,
                -0.130562886595726,
                -0.11500101536512375,
                -0.14739327132701874,
                -0.0003658891364466399,
                0.18454797565937042,
                0.7276684641838074,
                0.17592094838619232,
                -0.034583497792482376
            ],
            [
                0.13743014633655548,
                0.9739742279052734,
                1.1846303939819336,
                1.5017508268356323,
                0.2028089016675949,
                0.7197760343551636,
                -0.00476623373106122,
                -0.014505947008728981,
                -0.11043303459882736,
                0.21900705993175507,
                0.10420101881027222,
                0.45465439558029175,
                -0.10612532496452332,
                -0.06770282983779907,
                -0.15398606657981873,
                -0.16997453570365906
            ],
            [
                -0.03788760304450989,
                0.47999563813209534,
                -0.0987812802195549,
                -0.09410074353218079,
                0.236201673746109,
                -0.13161695003509521,
                -0.058092616498470306,
                -0.16620975732803345,
                -0.14964061975479126,
                -0.11976619809865952,
                -0.06928128749132156,
                0.2799915671348572,
                0.2534950077533722,
                -0.0668548196554184,
                -0.000001944796395036974,
                -0.007954012602567673
            ],
            [
                -0.058894552290439606,
                -0.10988101363182068,
                -0.14150433242321014,
                0.07484354078769684,
                -0.037430115044116974,
                0.06364183872938156,
                -0.025437094271183014,
                0.039177246391773224,
                0.00767558766528964,
                -0.14015907049179077,
                0.03548300638794899,
                -0.002677849493920803,
                -0.0792689174413681,
                0.03959218040108681,
                0.11484675854444504,
                0.21123361587524414
            ],
            [
                -0.15075340867042542,
                0.09744879603385925,
                -0.00006825478340033442,
                0.11208702623844147,
                0.050125639885663986,
                0.1030631959438324,
                0.09536415338516235,
                0.054899394512176514,
                -0.0077111683785915375,
                0.01877218298614025,
                0.03586694225668907,
                0.2246587872505188,
                0.23489367961883545,
                0.14523793756961823,
                0.2705625295639038,
                0.34373199939727783
            ],
            [
                -0.14268600940704346,
                0.03866414725780487,
                0.21185284852981567,
                0.03285878896713257,
                0.14333409070968628,
                -0.0010152646573260427,
                0.2058458775281906,
                -0.010077804327011108,
                0.22341705858707428,
                0.09326590597629547,
                0.13778366148471832,
                0.1600559651851654,
                0.1302739530801773,
                0.05983470752835274,
                0.15943776071071625,
                0.1309785544872284
            ],
            [
                -0.12036837637424469,
                0.34428203105926514,
                0.10332365334033966,
                0.1467328518629074,
                0.05663694441318512,
                -0.08884547650814056,
                0.1928073912858963,
                0.043382953852415085,
                0.0735144093632698,
                0.12791569530963898,
                0.05958585813641548,
                0.24463531374931335,
                0.07285252958536148,
                0.13708330690860748,
                0.10434257984161377,
                0.24173787236213684
            ],
            [
                -0.06790780276060104,
                0.046481408178806305,
                0.12429904192686081,
                0.0845782458782196,
                0.47054505348205566,
                -0.02586205303668976,
                0.08352144807577133,
                0.323813259601593,
                0.03447360545396805,
                0.199951171875,
                0.15307709574699402,
                0.3169194161891937,
                0.4438401460647583,
                0.2578459084033966,
                0.16806216537952423,
                0.05173188075423241
            ],
            [
                -0.13184599578380585,
                0.0753934234380722,
                0.273448646068573,
                0.06123233586549759,
                0.3904483914375305,
                0.1394645869731903,
                0.1037968248128891,
                0.27572646737098694,
                -0.02712351456284523,
                0.2033032923936844,
                0.11541266739368439,
                -0.05649537593126297,
                -0.031395312398672104,
                0.2576502561569214,
                0.13326583802700043,
                0.22810177505016327
            ],
            [
                -0.03538357838988304,
                0.18784233927726746,
                0.11728869378566742,
                0.14594469964504242,
                -0.0794057846069336,
                0.08944937586784363,
                0.08053631335496902,
                0.27748215198516846,
                0.17049415409564972,
                0.2127700299024582,
                0.3316825032234192,
                0.16271524131298065,
                0.25306135416030884,
                0.23703789710998535,
                0.0828036218881607,
                0.029468758031725883
            ],
            [
                0.0529843233525753,
                0.11603052169084549,
                0.3196720778942108,
                0.15277734398841858,
                0.3586719036102295,
                0.15989676117897034,
                0.09799045324325562,
                0.13894793391227722,
                0.10507600754499435,
                0.264735609292984,
                0.5474048256874084,
                0.2271042764186859,
                0.14067751169204712,
                0.23265811800956726,
                0.49211621284484863,
                0.25870224833488464
            ]
        ],
        [
            [
                3.0092365741729736,
                4.97178316116333,
                4.758148670196533,
                7.784613132476807,
                5.287879467010498,
                5.1305131912231445,
                4.949882507324219,
                3.844406843185425,
                2.7986948490142822,
                3.066621780395508,
                3.476004123687744,
                5.6145339012146,
                3.7436044216156006,
                3.416152000427246,
                4.902693271636963,
                4.8301496505737305
            ],
            [
                3.3792152404785156,
                4.62962007522583,
                4.9436259269714355,
                4.106210231781006,
                2.8581438064575195,
                4.795721530914307,
                2.7196953296661377,
                6.249479293823242,
                5.8728227615356445,
                3.0103487968444824,
                2.6182141304016113,
                1.5728263854980469,
                2.9695425033569336,
                2.990997552871704,
                3.8554725646972656,
                7.662652969360352
            ],
            [
                4.7190961837768555,
                3.264584541320801,
                3.0744447708129883,
                1.0436742305755615,
                1.7709790468215942,
                4.293336391448975,
                4.821266174316406,
                1.9432716369628906,
                6.969058036804199,
                3.379445791244507,
                2.46633243560791,
                4.594463348388672,
                3.5402796268463135,
                5.723165512084961,
                9.211374282836914,
                8.408241271972656
            ],
            [
                2.8544726371765137,
                3.1267831325531006,
                2.2936153411865234,
                3.903801679611206,
                3.632633924484253,
                6.9925665855407715,
                5.3941826820373535,
                5.158089637756348,
                7.035372734069824,
                2.1107795238494873,
                3.654197931289673,
                3.6710546016693115,
                3.6699090003967285,
                4.452463626861572,
                5.172983646392822,
                5.593884468078613
            ],
            [
                4.262089252471924,
                4.429407596588135,
                3.3089470863342285,
                3.032435417175293,
                5.2270588874816895,
                5.701871871948242,
                5.55747652053833,
                3.0395283699035645,
                3.709246873855591,
                2.938140392303467,
                4.849918842315674,
                4.166870594024658,
                2.5282583236694336,
                3.6588656902313232,
                3.0457143783569336,
                2.988668441772461
            ],
            [
                4.186942100524902,
                3.0475716590881348,
                4.818323612213135,
                3.489753246307373,
                3.263930559158325,
                1.9878802299499512,
                3.8859505653381348,
                3.5522334575653076,
                4.98118257522583,
                3.830758571624756,
                5.712414264678955,
                6.8975830078125,
                5.731924533843994,
                2.311814069747925,
                4.87431526184082,
                4.629909515380859
            ],
            [
                2.0542490482330322,
                4.2634477615356445,
                3.5166797637939453,
                4.500861167907715,
                2.3914480209350586,
                4.237318992614746,
                3.0681588649749756,
                2.119178295135498,
                2.8090555667877197,
                4.552242755889893,
                4.9774675369262695,
                3.936079502105713,
                3.96014142036438,
                7.569599151611328,
                3.7033612728118896,
                2.973679304122925
            ],
            [
                3.6676502227783203,
                3.3128788471221924,
                7.225160121917725,
                6.782021999359131,
                1.6854968070983887,
                3.109957218170166,
                4.098710060119629,
                2.5335493087768555,
                4.378859996795654,
                5.404801845550537,
                3.056514024734497,
                2.072784423828125,
                5.862687110900879,
                7.732813358306885,
                7.5776047706604,
                4.147825241088867
            ],
            [
                -0.1664992719888687,
                0.4747234284877777,
                -0.021454259753227234,
                0.10799248516559601,
                -0.10385268181562424,
                0.03436759114265442,
                -0.1080055758357048,
                -0.15733671188354492,
                0.614911675453186,
                0.31240275502204895,
                -0.11106721311807632,
                -0.04453742131590843,
                -0.07882761210203171,
                -0.056221842765808105,
                -0.09505538642406464,
                -0.03608114272356033
            ],
            [
                -0.012715401127934456,
                -0.12630900740623474,
                0.08195741474628448,
                0.14061804115772247,
                -0.14013753831386566,
                -0.03825577720999718,
                -0.13794077932834625,
                0.05048453062772751,
                -0.16975194215774536,
                -0.07779532670974731,
                0.11301010847091675,
                -0.15991175174713135,
                -0.11050254851579666,
                -0.08669522404670715,
                -0.12017439305782318,
                -0.016606850549578667
            ],
            [
                -0.1445568948984146,
                -0.022376634180545807,
                -0.10721080005168915,
                0.434763103723526,
                -0.004250813741236925,
                0.06012538820505142,
                0.27214503288269043,
                0.21580082178115845,
                0.013931912370026112,
                0.08524703979492188,
                -0.14372518658638,
                -0.08902676403522491,
                0.058349352329969406,
                -0.03331610932946205,
                -0.07722760736942291,
                -0.12480291724205017
            ],
            [
                -0.16304416954517365,
                0.17696715891361237,
                -0.10051710158586502,
                0.14983052015304565,
                1.133426308631897,
                -0.06786051392555237,
                0.0914144366979599,
                -0.15616758167743683,
                -0.1567658931016922,
                -0.015826191753149033,
                -0.14103439450263977,
                0.3189241588115692,
                -0.16765378415584564,
                -0.15789498388767242,
                -0.06260491162538528,
                0.37908944487571716
            ],
            [
                -0.07556823641061783,
                -0.12570872902870178,
                -0.008893142454326153,
                -0.16998836398124695,
                0.19389677047729492,
                -0.051069475710392,
                -0.14298109710216522,
                0.14936105906963348,
                0.10327790677547455,
                -0.12235056608915329,
                -0.13902275264263153,
                0.0339621864259243,
                0.16747842729091644,
                -0.09395256638526917,
                0.14363324642181396,
                0.1425609290599823
            ],
            [
                0.04307000711560249,
                -0.045007556676864624,
                0.03683251515030861,
                -0.1212816908955574,
                0.26176536083221436,
                -0.11731364578008652,
                -0.02043292298913002,
                -0.14169307053089142,
                -0.04166741669178009,
                -0.170005664229393,
                -0.16424866020679474,
                0.2406650185585022,
                0.09835454821586609,
                -0.09533288329839706,
                -0.16136835515499115,
                0.6308448910713196
            ],
            [
                -0.033676985651254654,
                -0.13741770386695862,
                0.21568995714187622,
                -0.1317289173603058,
                0.12410178035497665,
                0.1746906340122223,
                0.04008205980062485,
                0.03473221883177757,
                -0.06339787691831589,
                -0.059155818074941635,
                0.17899303138256073,
                0.14126941561698914,
                -0.11832816898822784,
                -0.08781441301107407,
                -0.1427057981491089,
                0.0904376283288002
            ],
            [
                0.09602151811122894,
                -0.1263815313577652,
                -0.09715224802494049,
                -0.14442071318626404,
                -0.08988551795482635,
                0.2940974533557892,
                -0.054000262171030045,
                -0.13873393833637238,
                0.03908105939626694,
                -0.1057773306965828,
                -0.164683535695076,
                -0.0501537062227726,
                -0.14688466489315033,
                -0.11404881626367569,
                -0.16479697823524475,
                -0.047372058033943176
            ]
        ],
        [
            [
                -0.004454873036593199,
                -0.005197817925363779,
                -0.050935108214616776,
                -0.005160784348845482,
                -0.0004929157439619303,
                0,
                -0.001109738484956324,
                -0.04412371665239334,
                -0.01772773638367653,
                -0.03857916593551636,
                -0.0534907802939415,
                -0.004086966626346111,
                -0.008551927283406258,
                -0.00870272796601057,
                -0.0004616288060788065,
                -0.025675348937511444
            ],
            [
                -0.0019670359324663877,
                0,
                -0.0000665929401293397,
                -0.02834750898182392,
                -0.000004843298484047409,
                0,
                -1.500901163353774e-7,
                -0.015341299585998058,
                -0.021113796159625053,
                -0.0008864898118190467,
                -0.03377170115709305,
                -0.00014208469656296074,
                -0.0008795055910013616,
                0,
                0,
                -0.052172161638736725
            ],
            [
                -0.10070684552192688,
                -0.05518016964197159,
                -0.022989002987742424,
                0,
                -8.607006520833238e-7,
                0,
                0,
                -0.0000032820639717101585,
                -0.006382872816175222,
                -0.16769279539585114,
                -0.13088971376419067,
                -0.01836906187236309,
                0,
                0,
                0.06768666952848434,
                -0.0004345931811258197
            ],
            [
                -0.00010068198025692254,
                -0.04619074612855911,
                -0.00002830434459610842,
                -0.1417427808046341,
                -0.0002776064502540976,
                -0.0004669060872402042,
                0,
                0.7566079497337341,
                -0.045734651386737823,
                -0.12563124299049377,
                -0.14279019832611084,
                -0.1382085084915161,
                -0.00073569908272475,
                -4.3669905380738783e-7,
                0,
                -0.16038314998149872
            ],
            [
                0,
                0,
                -0.0022870455868542194,
                -0.0449054092168808,
                -0.12199290841817856,
                0,
                -0.0029192185029387474,
                0,
                -0.0000018163254935643636,
                -0.011250083334743977,
                0.4384557008743286,
                -0.012001113966107368,
                -0.0006588228861801326,
                -0.04977990314364433,
                -4.415225021148217e-7,
                -0.16804465651512146
            ],
            [
                -0.00048857118235901,
                -0.0000040661088860360906,
                0,
                -0.0029657771810889244,
                -0.0013371057575568557,
                0,
                -0.1188429743051529,
                -0.00001550636807223782,
                -0.09118476510047913,
                -0.04088350012898445,
                0,
                -2.406247290309693e-7,
                0,
                -2.9033591886218346e-7,
                -0.00009021888399729505,
                -0.1225133091211319
            ],
            [
                -0.0065620057284832,
                -0.048402443528175354,
                0,
                0,
                -0.00005597458948614076,
                -0.0033191577531397343,
                -0.0013698438415303826,
                -0.006943336687982082,
                0,
                -0.0000010089034958582488,
                -0.043360400944948196,
                -0.0005415562773123384,
                0,
                0,
                0,
                -0.000003530053845679504
            ],
            [
                -0.08647190779447556,
                -2.146272208847222e-7,
                0,
                -0.062137845903635025,
                -0.00004928468115394935,
                -0.009172341786324978,
                -0.0009610829292796552,
                -0.0014731166884303093,
                0,
                -0.0026211084332317114,
                -0.008262760937213898,
                -0.00001439696006855229,
                -0.000004193980203126557,
                0,
                0.899639904499054,
                -0.06338983029127121
            ],
            [
                0.0988132655620575,
                0.17966300249099731,
                0.17994756996631622,
                0.1849137842655182,
                0.01249602809548378,
                0.3635048270225525,
                0.00539424316957593,
                0.16235162317752838,
                0.12163109332323074,
                0.3568272590637207,
                0.1727733463048935,
                0.3180113732814789,
                0.11661531031131744,
                0.06994447112083435,
                -0.09080424159765244,
                0.196294903755188
            ],
            [
                0.13758106529712677,
                0.1702871471643448,
                0.3521580100059509,
                0.08344531059265137,
                -0.015045445412397385,
                0.19806423783302307,
                0.16260474920272827,
                0.37598592042922974,
                0.3083375096321106,
                0.2864517569541931,
                0.06072641909122467,
                0.08228931576013565,
                0.07756449282169342,
                0.21221287548542023,
                0.4253843426704407,
                0.4422752261161804
            ],
            [
                0.07833485305309296,
                0.04794281721115112,
                0.14310546219348907,
                0.11242073029279709,
                0.018642181530594826,
                0.2619483470916748,
                0.5508480072021484,
                0.19445371627807617,
                0.12621979415416718,
                0.4258929491043091,
                0.14288754761219025,
                0.3271671533584595,
                0.24992862343788147,
                0.2673019766807556,
                0.3271245062351227,
                0.13530343770980835
            ],
            [
                0.2756315767765045,
                0.36840587854385376,
                0.04478704556822777,
                -0.026776982471346855,
                -0.027829769998788834,
                0.11516127735376358,
                0.17897751927375793,
                -0.0030284111853688955,
                -0.04703747108578682,
                0.13918310403823853,
                0.05079542100429535,
                -0.08373371511697769,
                0.20925751328468323,
                0.4864940047264099,
                0.2624397873878479,
                0.30531176924705505
            ],
            [
                0.3985724151134491,
                0.21574945747852325,
                0.23568511009216309,
                0.2469107061624527,
                -0.0404537059366703,
                0.032850559800863266,
                0.1684368997812271,
                0.359004944562912,
                0.2281905561685562,
                0.05300208553671837,
                0.18691317737102509,
                -0.10418801009654999,
                0.18120267987251282,
                0.13415907323360443,
                0.3318762481212616,
                0.31603607535362244
            ],
            [
                0.17697326838970184,
                0.26134490966796875,
                0.13032351434230804,
                0.2543109357357025,
                0.23675261437892914,
                0.16950248181819916,
                0.1380281299352646,
                0.029602061957120895,
                -0.003908608108758926,
                0.01442442275583744,
                0.03773214668035507,
                -0.08519892394542694,
                -0.014973378740251064,
                -0.03692185878753662,
                0.1079583689570427,
                0.06322146207094193
            ],
            [
                0.0198088176548481,
                0.07452701032161713,
                0.05600375309586525,
                0.1320999413728714,
                -0.11668360978364944,
                0.03357217088341713,
                0.1300048828125,
                0.16008158028125763,
                0.11350971460342407,
                0.13683906197547913,
                0.2890115976333618,
                0.24659229815006256,
                0.09808102995157242,
                0.03691935911774635,
                0.24025654792785645,
                0.10426889359951019
            ],
            [
                0.12810805439949036,
                0.19688595831394196,
                0.010168829001486301,
                0.4392087161540985,
                0.13787591457366943,
                0.3346250355243683,
                0.32273969054222107,
                0.30024608969688416,
                0.3547842502593994,
                -0.026479465886950493,
                0.044577211141586304,
                0.32994505763053894,
                0.38811805844306946,
                0.5272625684738159,
                -0.01730334758758545,
                0.34107673168182373
            ]
        ],
        [
            [
                -0.14828547835350037,
                0.01757429726421833,
                -0.16544312238693237,
                0.06464724987745285,
                0.13553117215633392,
                -0.16555540263652802,
                -0.04874636232852936,
                -0.06493480503559113,
                2.0656216144561768,
                -0.1569622904062271,
                -0.025229159742593765,
                -0.0970667228102684,
                -0.1552879363298416,
                -0.1582433134317398,
                2.2982349395751953,
                -0.09106386452913284
            ],
            [
                -0.16927340626716614,
                -0.15973365306854248,
                -0.0863223671913147,
                -0.084539994597435,
                -0.11906425654888153,
                -0.1097838506102562,
                0.10357391834259033,
                0.005700195208191872,
                1.073862075805664,
                -0.0012844676384702325,
                -0.00007338752766372636,
                -0.16375766694545746,
                -0.11133027076721191,
                0.7867324948310852,
                -0.12710344791412354,
                -0.04808694124221802
            ],
            [
                -0.12395965307950974,
                1.1554591655731201,
                -0.15001773834228516,
                0.059010207653045654,
                0.835002064704895,
                -0.09508199989795685,
                -0.09094955027103424,
                -0.11992534250020981,
                0.6533118486404419,
                -0.011774476617574692,
                -0.000004966890628566034,
                -0.16168634593486786,
                0.39020857214927673,
                2.1839451789855957,
                0.004329125862568617,
                0.26938724517822266
            ],
            [
                0.07048262655735016,
                -0.07629664242267609,
                -0.05046069622039795,
                -0.17001795768737793,
                0.27722981572151184,
                -0.041212622076272964,
                -0.15990865230560303,
                -0.004191298969089985,
                -0.08101441711187363,
                -0.029092686250805855,
                -0.0003533139533828944,
                -0.036550380289554596,
                -0.038150135427713394,
                0.5691831707954407,
                -0.1553071141242981,
                -0.12021418660879135
            ],
            [
                0.5018321871757507,
                0.0855552926659584,
                -0.12782661616802216,
                -0.03199504315853119,
                0.43033257126808167,
                -0.0860321968793869,
                1.0150105953216553,
                0.15871542692184448,
                -0.1263345330953598,
                -0.1021108478307724,
                -0.05000705644488335,
                -0.09744425117969513,
                -0.11379967629909515,
                -0.0313861258327961,
                -0.16952481865882874,
                -0.1268824189901352
            ],
            [
                -0.01745852828025818,
                -0.025757569819688797,
                -0.16653841733932495,
                -0.0006611262797378004,
                -0.1458168476819992,
                -0.09260445088148117,
                -0.003094199113547802,
                -0.1256033480167389,
                -0.005427622236311436,
                -0.16944517195224762,
                0.7000131607055664,
                2.1866629123687744,
                -0.14977087080478668,
                0.42558416724205017,
                0.2278742492198944,
                -0.0040331739000976086
            ],
            [
                -0.10352499783039093,
                -0.07620035856962204,
                0.1840178221464157,
                0.42639830708503723,
                -0.15601852536201477,
                0.016847195103764534,
                -0.08432690799236298,
                -0.016475383192300797,
                -0.17001543939113617,
                2.4464969635009766,
                -0.16496802866458893,
                -0.1364554613828659,
                -0.16243186593055725,
                3.3630635738372803,
                3.055349111557007,
                1.401781678199768
            ],
            [
                -0.004180105868726969,
                1.6739833354949951,
                2.509617328643799,
                -0.16550405323505402,
                0.05394859239459038,
                -0.16933272778987885,
                0.06550227850675583,
                -0.16595396399497986,
                0.6052732467651367,
                -0.04045403003692627,
                -0.020991189405322075,
                -0.016547387465834618,
                -0.10807057470083237,
                2.1303751468658447,
                0.04195215180516243,
                0.28447481989860535
            ],
            [
                0.14942647516727448,
                0.5306916236877441,
                1.048041582107544,
                0.2737451493740082,
                0.5264744758605957,
                0.013751049526035786,
                0.43939387798309326,
                0.4992356598377228,
                0.9165403246879578,
                0.8742379546165466,
                0.2797117531299591,
                0.2528982162475586,
                0.22664706408977509,
                0.5580579042434692,
                0.3906388580799103,
                0.38743770122528076
            ],
            [
                0.15234492719173431,
                0.36742082238197327,
                0.6005554795265198,
                0.47084927558898926,
                0.41830116510391235,
                0.1465001106262207,
                0.2333911508321762,
                0.25854578614234924,
                0.5280867218971252,
                0.11142116785049438,
                0.313339501619339,
                0.2166372537612915,
                0.00335342506878078,
                0.038058727979660034,
                0.26915088295936584,
                0.3087794780731201
            ],
            [
                0.26622846722602844,
                0.4905170202255249,
                0.4139070212841034,
                0.8920422792434692,
                0.7293572425842285,
                0.4377320110797882,
                0.0522330105304718,
                0.2705216705799103,
                0.07616613805294037,
                0.4419358968734741,
                0.43876010179519653,
                0.143691286444664,
                0.31318992376327515,
                0.28414708375930786,
                0.2805722951889038,
                0.39744672179222107
            ],
            [
                0.14255571365356445,
                0.17531728744506836,
                0.34496021270751953,
                0.8363988995552063,
                0.6468572616577148,
                0.5682091116905212,
                0.19750003516674042,
                0.4249850809574127,
                0.06054964289069176,
                0.09696085005998611,
                0.40057459473609924,
                0.0449787862598896,
                0.26922744512557983,
                0.32453417778015137,
                0.7780368328094482,
                0.6228029727935791
            ],
            [
                0.09669152647256851,
                0.22787417471408844,
                0.27281033992767334,
                0.4178825914859772,
                0.7788887023925781,
                0.5974838137626648,
                0.16203558444976807,
                0.2136641889810562,
                0.22689232230186462,
                0.19218701124191284,
                0.40580472350120544,
                0.7567950487136841,
                0.2837820053100586,
                0.20898810029029846,
                0.8021519184112549,
                0.43522903323173523
            ],
            [
                0.1859789788722992,
                0.4376092553138733,
                1.0663907527923584,
                0.24770183861255646,
                0.2706897556781769,
                0.2383723109960556,
                0.010829520411789417,
                0.19130448997020721,
                0.19266611337661743,
                0.4173623025417328,
                0.6114311218261719,
                1.2650322914123535,
                0.5749998688697815,
                0.42800185084342957,
                0.3808121085166931,
                0.41067826747894287
            ],
            [
                0.08087846636772156,
                0.19391991198062897,
                0.30602097511291504,
                0.4049011170864105,
                0.8139345049858093,
                0.5116422176361084,
                0.1534585952758789,
                0.05200979858636856,
                0.14097847044467926,
                0.4865071773529053,
                0.42586666345596313,
                0.6024329662322998,
                0.26849687099456787,
                0.15422610938549042,
                0.3758964240550995,
                0.4418305456638336
            ],
            [
                0.11405683308839798,
                0.07243489474058151,
                0.13188190758228302,
                0.3505820035934448,
                0.1955803632736206,
                0.13719002902507782,
                0.11544254422187805,
                0.11067292839288712,
                0.11344926804304123,
                0.07599443197250366,
                0.03390774875879288,
                0.13556040823459625,
                0.17967906594276428,
                0.1378810703754425,
                0.2020491361618042,
                0.40819451212882996
            ]
        ],
        [
            [
                5.162698268890381,
                5.279122352600098,
                5.67796516418457,
                7.499814510345459,
                4.3607025146484375,
                6.552049160003662,
                6.861659526824951,
                4.038553237915039,
                6.900716781616211,
                5.3567728996276855,
                5.24544095993042,
                7.4113545417785645,
                4.72528076171875,
                6.473899841308594,
                5.839242935180664,
                4.417524814605713
            ],
            [
                5.390563488006592,
                11.990652084350586,
                8.503494262695312,
                9.162649154663086,
                6.8938140869140625,
                8.348104476928711,
                6.893094062805176,
                7.9238128662109375,
                6.185516357421875,
                7.112734794616699,
                7.364846229553223,
                6.773580551147461,
                7.816751480102539,
                7.9594221115112305,
                5.842507362365723,
                8.80208969116211
            ],
            [
                7.036888122558594,
                7.588932037353516,
                7.577131271362305,
                4.548303127288818,
                7.010690212249756,
                5.762052536010742,
                8.472990036010742,
                5.907932281494141,
                10.328428268432617,
                6.823145866394043,
                6.7542924880981445,
                7.608746528625488,
                8.253605842590332,
                8.094063758850098,
                11.061393737792969,
                10.765920639038086
            ],
            [
                7.474643230438232,
                7.420723915100098,
                7.038798809051514,
                9.437861442565918,
                9.468644142150879,
                9.825328826904297,
                8.017132759094238,
                7.866145133972168,
                10.109123229980469,
                4.584559917449951,
                7.364692211151123,
                10.18117904663086,
                8.988434791564941,
                6.9633684158325195,
                6.897132873535156,
                9.601933479309082
            ],
            [
                6.998933792114258,
                7.0924835205078125,
                5.914468288421631,
                7.787795543670654,
                9.232646942138672,
                9.051909446716309,
                8.005648612976074,
                8.000956535339355,
                8.272673606872559,
                8.128580093383789,
                9.362442970275879,
                7.058566570281982,
                6.047388076782227,
                6.569101333618164,
                4.0857834815979,
                6.41829776763916
            ],
            [
                7.9660444259643555,
                7.086226940155029,
                7.066715717315674,
                7.308673858642578,
                7.388352394104004,
                6.860872745513916,
                7.267640113830566,
                8.329743385314941,
                8.797500610351562,
                7.281748294830322,
                8.937647819519043,
                5.023869514465332,
                6.138369560241699,
                6.638172626495361,
                9.05019474029541,
                6.741166591644287
            ],
            [
                4.678343772888184,
                7.76431941986084,
                5.748619079589844,
                6.895370006561279,
                5.8053693771362305,
                7.85304594039917,
                6.764369487762451,
                6.3362603187561035,
                7.504087924957275,
                8.879182815551758,
                10.30710506439209,
                6.7663421630859375,
                8.416626930236816,
                9.486021995544434,
                6.39756965637207,
                6.63493013381958
            ],
            [
                5.034870624542236,
                8.66214656829834,
                9.35678768157959,
                6.534220218658447,
                2.5756092071533203,
                5.125085830688477,
                6.575606346130371,
                4.608450412750244,
                5.228875637054443,
                5.985619068145752,
                8.270249366760254,
                8.472307205200195,
                7.4187541007995605,
                6.63225793838501,
                4.986957550048828,
                4.339278697967529
            ],
            [
                0.1887011080980301,
                1.0741575956344604,
                0.1626754254102707,
                0.7856954336166382,
                0.30396342277526855,
                -0.027197962626814842,
                -0.11321620643138885,
                0.12022095173597336,
                0.8650293350219727,
                0.29121559858322144,
                0.35592120885849,
                0.32826346158981323,
                0.5040262937545776,
                0.1967628449201584,
                0.507896363735199,
                0.6081414818763733
            ],
            [
                0.09642951935529709,
                0.2441776543855667,
                0.28484317660331726,
                0.00797358714044094,
                0.4640612006187439,
                0.31489241123199463,
                -0.014802123419940472,
                0.4230002164840698,
                -0.055249493569135666,
                0.1121358722448349,
                0.06635914742946625,
                -0.15805307030677795,
                -0.1429780274629593,
                -0.06125221773982048,
                0.1499597579240799,
                0.0977131798863411
            ],
            [
                -0.16909074783325195,
                0.7203068733215332,
                -0.08647812157869339,
                0.3565392792224884,
                -0.044328246265649796,
                -0.03311854973435402,
                0.6966955661773682,
                0.02764253504574299,
                -0.0004694887902587652,
                0.5790422558784485,
                -0.0423811599612236,
                -0.09433314204216003,
                0.35024163126945496,
                -0.08630849421024323,
                -0.08657492697238922,
                0.09923484176397324
            ],
            [
                0.14616653323173523,
                0.5083240866661072,
                -0.06924990564584732,
                0.7700286507606506,
                0.4811248481273651,
                0.3703165352344513,
                0.07846595346927643,
                -0.11739474534988403,
                -0.05671338364481926,
                0.17559221386909485,
                -0.08287470787763596,
                0.02343972586095333,
                -0.14017944037914276,
                0.031988419592380524,
                0.028748594224452972,
                0.4850893020629883
            ],
            [
                -0.10016386955976486,
                0.05766504257917404,
                0.06499098986387253,
                0.24381062388420105,
                0.5453623533248901,
                -0.08845573663711548,
                -0.08212390542030334,
                0.16336050629615784,
                0.17391614615917206,
                0.028470031917095184,
                0.04833348095417023,
                -0.016857434064149857,
                -0.08051661401987076,
                -0.15676358342170715,
                0.5934809446334839,
                0.40959474444389343
            ],
            [
                -0.06108997017145157,
                0.09505871683359146,
                0.15115998685359955,
                -0.08309227973222733,
                0.4659391939640045,
                -0.050033826380968094,
                0.00633832486346364,
                -0.1431557834148407,
                0.10562897473573685,
                -0.13793203234672546,
                0.2424282431602478,
                0.7595695853233337,
                0.0367288812994957,
                -0.14025376737117767,
                -0.1502165049314499,
                0.680501401424408
            ],
            [
                0.08814692497253418,
                0.1409066617488861,
                0.5400541424751282,
                0.27429091930389404,
                0.6526656150817871,
                0.2240559309720993,
                -0.032958678901195526,
                0.10181986540555954,
                0.24558088183403015,
                0.0048743183724582195,
                0.4623802900314331,
                0.13425789773464203,
                -0.15725447237491608,
                -0.05761122331023216,
                -0.01469575334340334,
                0.361978143453598
            ],
            [
                -0.14565598964691162,
                0.0032351447734981775,
                -0.07109446823596954,
                -0.12921494245529175,
                0.21696512401103973,
                0.47773247957229614,
                -0.15537962317466736,
                -0.06064332276582718,
                -0.06412103772163391,
                -0.12278439849615097,
                -0.0007385901408270001,
                -0.002881778636947274,
                -0.013284113258123398,
                0.03748622164130211,
                0.0660598874092102,
                0.3191281259059906
            ]
        ],
        [
            [
                -0.044968247413635254,
                -0.05629025399684906,
                -0.008123097009956837,
                -0.001695274724625051,
                -0.004557235632091761,
                -0.08211395144462585,
                -0.1317623108625412,
                -0.1593833863735199,
                -0.0504736565053463,
                -0.15935145318508148,
                0.029757438227534294,
                -0.009111505001783371,
                -0.13932029902935028,
                -0.09410687536001205,
                -0.11044454574584961,
                -0.00018740892119240016
            ],
            [
                -0.1675359606742859,
                -0.16316844522953033,
                -0.16373813152313232,
                -0.007196408696472645,
                -0.15746062994003296,
                0.8409171104431152,
                0.5157167911529541,
                -0.08498009294271469,
                -0.1491127610206604,
                -0.1319272518157959,
                -0.08908139914274216,
                -0.06442880630493164,
                -0.16528171300888062,
                0.017430352047085762,
                -0.06963148713111877,
                -0.005538470111787319
            ],
            [
                -0.03923379257321358,
                0.10455203056335449,
                0.031182602047920227,
                1.5743029117584229,
                0.23859219253063202,
                -0.10387326031923294,
                0.5138527154922485,
                -0.14690789580345154,
                -0.07001218944787979,
                -0.1660161018371582,
                -0.16453570127487183,
                -0.027516700327396393,
                -0.06718652695417404,
                -0.1282835453748703,
                -0.16978231072425842,
                -0.15055282413959503
            ],
            [
                0.7093002200126648,
                0.23163443803787231,
                0.9510552287101746,
                -0.013152974657714367,
                0.4800341725349426,
                0.9997771978378296,
                -0.13921703398227692,
                -0.1509326845407486,
                -0.07028418034315109,
                -0.05234333500266075,
                -0.16876929998397827,
                -0.15896663069725037,
                -0.14761777222156525,
                -0.09039797633886337,
                -0.03924066573381424,
                -0.10437177866697311
            ],
            [
                -0.104049451649189,
                -0.16983488202095032,
                -0.1347075253725052,
                -0.054472919553518295,
                0.43818745017051697,
                0.06465853750705719,
                -0.035412147641181946,
                0.005980304908007383,
                0.3212379217147827,
                -0.014675743877887726,
                -0.16959631443023682,
                -0.1689082682132721,
                -0.05715734884142876,
                0.16253462433815002,
                -0.16942746937274933,
                -0.14520147442817688
            ],
            [
                -0.025723060593008995,
                0.0038441549986600876,
                0.36565762758255005,
                -0.15872608125209808,
                1.1421959400177002,
                0.1848127394914627,
                0.2669784724712372,
                -0.14876993000507355,
                -0.1460181027650833,
                0.18176107108592987,
                -0.11035376042127609,
                -0.01727854646742344,
                1.1230403184890747,
                -0.1618434488773346,
                -0.024755768477916718,
                -0.01924213580787182
            ],
            [
                -0.06844133138656616,
                -0.12594737112522125,
                2.731719732284546,
                1.191786766052246,
                -0.08679619431495667,
                1.5206761360168457,
                -0.16835984587669373,
                -0.1168229728937149,
                0.029503637924790382,
                -0.13569830358028412,
                -0.13210998475551605,
                -0.0338008739054203,
                -0.07372633367776871,
                -0.1467081904411316,
                -0.12330110371112823,
                -0.16605377197265625
            ],
            [
                -0.09858884662389755,
                0.5177978873252869,
                -0.15284116566181183,
                -0.12510862946510315,
                -0.14483481645584106,
                -0.11531256139278412,
                -0.168891042470932,
                -0.16784974932670593,
                -0.14494997262954712,
                -0.166695699095726,
                -0.16961124539375305,
                0.0994725376367569,
                0.49819067120552063,
                -0.09571680426597595,
                -0.000051715302106458694,
                -0.026796432211995125
            ],
            [
                0.2700570523738861,
                0.436448872089386,
                0.3598136603832245,
                0.35493984818458557,
                0.3186129033565521,
                0.08197712898254395,
                0.4675150215625763,
                0.6838104128837585,
                0.1325843185186386,
                0.30784210562705994,
                0.2487902045249939,
                0.4225965738296509,
                0.31882843375205994,
                0.2954677939414978,
                0.5837737917900085,
                0.08482654392719269
            ],
            [
                0.15333864092826843,
                0.653641939163208,
                0.5859928727149963,
                0.4949057400226593,
                0.4876294434070587,
                0.4030163288116455,
                0.6069808602333069,
                0.4494951665401459,
                0.27527832984924316,
                0.505060076713562,
                0.5987673997879028,
                0.29851919412612915,
                0.197173073887825,
                0.43495991826057434,
                0.6776207089424133,
                0.2711976170539856
            ],
            [
                0.2121976613998413,
                0.3652312457561493,
                0.7685755491256714,
                0.36670517921447754,
                0.2178570032119751,
                0.27155831456184387,
                0.49364665150642395,
                0.44354480504989624,
                0.43424034118652344,
                0.34773799777030945,
                0.37861931324005127,
                0.23835308849811554,
                0.03891344368457794,
                0.4162612855434418,
                0.2538765072822571,
                0.5429633259773254
            ],
            [
                0.08548624813556671,
                0.5392581820487976,
                0.7293335199356079,
                0.6244006156921387,
                0.2691960632801056,
                0.306826114654541,
                0.4019569754600525,
                0.2234470695257187,
                0.37410804629325867,
                0.3038594126701355,
                0.3105734586715698,
                0.2068604677915573,
                0.28733333945274353,
                0.300248920917511,
                0.5447263121604919,
                0.5792317986488342
            ],
            [
                0.21110719442367554,
                0.5471069812774658,
                0.27900731563568115,
                0.6727613806724548,
                0.854796826839447,
                0.30911242961883545,
                0.30279603600502014,
                0.3119688034057617,
                0.2413824200630188,
                0.49914029240608215,
                0.7077494263648987,
                0.10894865542650223,
                0.3303942084312439,
                0.5596973896026611,
                0.6075631976127625,
                0.18751934170722961
            ],
            [
                0.24706456065177917,
                0.632408082485199,
                0.4642910063266754,
                0.5118416547775269,
                0.5970222353935242,
                0.30096110701560974,
                0.32554689049720764,
                0.1968618631362915,
                0.4221732020378113,
                0.26347634196281433,
                0.5577204823493958,
                0.028543956577777863,
                0.32726338505744934,
                0.4265040457248688,
                0.4696321189403534,
                0.24364301562309265
            ],
            [
                0.2774539291858673,
                0.5788421034812927,
                0.4052116870880127,
                0.5303597450256348,
                0.45977962017059326,
                0.3402576148509979,
                0.3359246253967285,
                0.20542757213115692,
                0.6466090679168701,
                0.3474442660808563,
                0.7471790909767151,
                0.15569718182086945,
                0.7140308618545532,
                0.5200830101966858,
                0.7096813321113586,
                0.13569867610931396
            ],
            [
                0.015522655099630356,
                0.14463765919208527,
                0.3724796175956726,
                0.12476116418838501,
                0.1053985133767128,
                0.5688403248786926,
                0.3735105097293854,
                -0.06688793748617172,
                0.23693257570266724,
                0.11535657942295074,
                0.3410665988922119,
                0.3031642436981201,
                0.2790859043598175,
                0.08663134276866913,
                1.02997887134552,
                0.3012058436870575
            ]
        ],
        [
            [
                0.3248479664325714,
                -0.12404952198266983,
                0.015392426401376724,
                0.27489858865737915,
                0.018548674881458282,
                0.6728456616401672,
                1.49407958984375,
                0.07799608260393143,
                0.6733723282814026,
                1.159256100654602,
                0.8478953242301941,
                1.2879599332809448,
                0.3765881061553955,
                0.8277204036712646,
                0.07951077818870544,
                0.4577862322330475
            ],
            [
                0.15857066214084625,
                -0.007734211161732674,
                -0.07184448838233948,
                -0.15968939661979675,
                -0.013683163560926914,
                -0.03811681270599365,
                -0.02703750506043434,
                -0.13961048424243927,
                0.20726358890533447,
                -0.10978004336357117,
                -0.14527462422847748,
                -0.09545092284679413,
                -0.09423918277025223,
                -0.06912655383348465,
                -0.15890637040138245,
                0.01014325674623251
            ],
            [
                -0.16192510724067688,
                -0.024311743676662445,
                -0.09312397241592407,
                -0.16749556362628937,
                -0.07132001966238022,
                -0.12004352360963821,
                -0.04742798954248428,
                -0.006979512050747871,
                -0.1537751853466034,
                -0.16982673108577728,
                -0.12709082663059235,
                -0.15774066746234894,
                -0.00678179832175374,
                -0.012913121841847897,
                0.7859092950820923,
                -0.1588696390390396
            ],
            [
                -0.14657165110111237,
                -0.15816465020179749,
                -0.1521148383617401,
                -0.10486681014299393,
                -0.16935034096240997,
                -0.16881754994392395,
                -0.1691512018442154,
                -0.10302333533763885,
                -0.100799061357975,
                -0.16976988315582275,
                0.07892101258039474,
                -0.16876882314682007,
                -0.10182712972164154,
                -0.02714783512055874,
                -0.04631519690155983,
                -0.01622495986521244
            ],
            [
                -0.021740829572081566,
                -0.06496080756187439,
                -0.09997907280921936,
                -0.16662362217903137,
                -0.0468614436686039,
                -0.0851464495062828,
                -0.011512083932757378,
                -0.016229374334216118,
                -0.005811258684843779,
                -0.11402992904186249,
                -0.16957473754882812,
                -0.05442434549331665,
                -0.08844003826379776,
                -0.16201168298721313,
                -0.024078575894236565,
                -0.1628902107477188
            ],
            [
                0.14000584185123444,
                -0.08021121472120285,
                -0.026484573259949684,
                -0.09328567981719971,
                -0.012543205171823502,
                -0.03952471911907196,
                -0.1631637066602707,
                -0.10389360040426254,
                -0.11064434051513672,
                -0.1523061841726303,
                -0.026882076635956764,
                -0.04764195531606674,
                -0.010180177167057991,
                -0.006351051852107048,
                -0.12700073421001434,
                1.329917073249817
            ],
            [
                -0.1072465106844902,
                -0.1078949049115181,
                -0.14825105667114258,
                -0.047937892377376556,
                -0.1258174329996109,
                -0.08559969812631607,
                -0.08870573341846466,
                -0.12565402686595917,
                -0.05765121057629585,
                -0.037297479808330536,
                -0.12351734191179276,
                -0.15753915905952454,
                -0.036554768681526184,
                -0.07591286301612854,
                -0.00021188579557929188,
                0.5273340344429016
            ],
            [
                -0.16826672852039337,
                -0.052903175354003906,
                -0.12514176964759827,
                -0.07549502700567245,
                -0.003906417638063431,
                -0.137328639626503,
                -0.1334584355354309,
                -0.11632932722568512,
                -0.06672567874193192,
                -0.030822928994894028,
                -0.1580633968114853,
                -0.16809436678886414,
                -0.11463602632284164,
                -0.07833785563707352,
                -0.16722871363162994,
                0.039456356316804886
            ],
            [
                0.07769226282835007,
                0.14266055822372437,
                0.12303154915571213,
                0.13068604469299316,
                0.16479964554309845,
                0.09024301171302795,
                0.08333650231361389,
                0.13565076887607574,
                0.14248289167881012,
                0.19529198110103607,
                0.10315801948308945,
                0.022485319525003433,
                -0.009617896750569344,
                0.06636051088571548,
                0.10871468484401703,
                0.09435164928436279
            ],
            [
                0.2560166120529175,
                0.2513149082660675,
                0.22027835249900818,
                0.12054692208766937,
                0.1256479024887085,
                0.1089600920677185,
                0.3087562620639801,
                0.35231760144233704,
                0.15038925409317017,
                0.2338535487651825,
                0.14899590611457825,
                0.15793822705745697,
                0.19984598457813263,
                0.20141276717185974,
                0.16669905185699463,
                0.1648399829864502
            ],
            [
                0.15040527284145355,
                0.18228161334991455,
                0.2438296675682068,
                0.19695141911506653,
                0.2172950655221939,
                0.3266107141971588,
                0.38096585869789124,
                0.33253663778305054,
                0.2950538396835327,
                0.29460859298706055,
                0.19851228594779968,
                0.1842636615037918,
                0.1907721757888794,
                0.2667168974876404,
                0.18010123074054718,
                0.19074979424476624
            ],
            [
                0.33399078249931335,
                0.3916882574558258,
                0.2568444013595581,
                0.17757335305213928,
                0.15386171638965607,
                0.223745658993721,
                0.227516770362854,
                0.1576249748468399,
                0.197089284658432,
                0.2705875337123871,
                0.2703264057636261,
                0.20048049092292786,
                0.1315806806087494,
                0.16342715919017792,
                0.43254759907722473,
                0.48505905270576477
            ],
            [
                0.37809324264526367,
                0.44144460558891296,
                0.2735597789287567,
                0.2926933467388153,
                0.18736335635185242,
                0.1661885380744934,
                0.20299525558948517,
                0.17853017151355743,
                0.24874934554100037,
                0.23580104112625122,
                0.28885143995285034,
                0.16621387004852295,
                0.20154713094234467,
                0.15469983220100403,
                0.38387224078178406,
                0.309726744890213
            ],
            [
                0.247308611869812,
                0.28254273533821106,
                0.21307773888111115,
                0.42114219069480896,
                0.3765901029109955,
                0.1757480502128601,
                0.22503040730953217,
                0.12465160340070724,
                0.26195022463798523,
                0.18981507420539856,
                0.10484910011291504,
                0.17078819870948792,
                0.10459854453802109,
                0.10244102030992508,
                0.1087074875831604,
                0.09787391871213913
            ],
            [
                0.13758009672164917,
                0.27679604291915894,
                0.30838075280189514,
                0.19142235815525055,
                0.18516002595424652,
                0.12149282544851303,
                0.18841063976287842,
                0.20321513712406158,
                0.21006041765213013,
                0.10855643451213837,
                0.04426313191652298,
                0.14434699714183807,
                0.1826992928981781,
                0.08914382010698318,
                0.05459882318973541,
                0.031002510339021683
            ],
            [
                0.20381909608840942,
                0.2411421686410904,
                0.22329097986221313,
                0.08835909515619278,
                0.14864613115787506,
                0.1654510349035263,
                0.14485040307044983,
                0.18477778136730194,
                0.2791375517845154,
                0.13148359954357147,
                0.1398792713880539,
                0.23085206747055054,
                0.2611815929412842,
                0.17277272045612335,
                0.06485524028539658,
                0.05859745293855667
            ]
        ],
        [
            [
                3.721095323562622,
                3.6597273349761963,
                3.410121202468872,
                2.94050669670105,
                3.796292543411255,
                3.669722080230713,
                2.60015606880188,
                2.9942848682403564,
                4.21692419052124,
                4.162909507751465,
                3.0049595832824707,
                1.4457521438598633,
                2.6147549152374268,
                2.5296764373779297,
                4.371214389801025,
                5.551208019256592
            ],
            [
                3.1686432361602783,
                3.1405398845672607,
                3.1625688076019287,
                1.0388838052749634,
                4.912341117858887,
                4.152976036071777,
                3.05098295211792,
                2.447117805480957,
                3.1783969402313232,
                1.0469533205032349,
                1.3606942892074585,
                2.3573720455169678,
                2.2583742141723633,
                3.315476655960083,
                4.120205879211426,
                4.548091888427734
            ],
            [
                1.840078353881836,
                4.291604042053223,
                3.627436876296997,
                2.9119760990142822,
                5.835765361785889,
                4.524756908416748,
                4.069543361663818,
                3.293402910232544,
                3.752803325653076,
                2.5102951526641846,
                1.070214033126831,
                2.6742753982543945,
                5.389641761779785,
                6.673293590545654,
                5.730429172515869,
                4.969109535217285
            ],
            [
                4.255429267883301,
                3.4933910369873047,
                2.278115749359131,
                2.037254571914673,
                4.183681011199951,
                4.8324809074401855,
                4.246726989746094,
                0.6396193504333496,
                3.5594072341918945,
                1.547550082206726,
                1.3649876117706299,
                2.840885639190674,
                4.709296226501465,
                6.68102502822876,
                5.945657730102539,
                3.0459916591644287
            ],
            [
                3.7602267265319824,
                4.770501136779785,
                1.7209657430648804,
                0.23811359703540802,
                2.5044233798980713,
                3.1581289768218994,
                3.714130163192749,
                4.013587951660156,
                4.892528533935547,
                3.677400588989258,
                1.1502935886383057,
                3.004652976989746,
                2.465233087539673,
                2.481904983520508,
                2.9559388160705566,
                3.2109808921813965
            ],
            [
                2.798107862472534,
                3.0088744163513184,
                2.726605176925659,
                0.1379881650209427,
                2.754132032394409,
                2.259891986846924,
                2.782681465148926,
                2.179203510284424,
                1.4530919790267944,
                3.5293803215026855,
                3.9722018241882324,
                6.076938152313232,
                3.7101926803588867,
                3.0961501598358154,
                2.6292836666107178,
                0.8681698441505432
            ],
            [
                1.866052508354187,
                1.9427202939987183,
                5.332211971282959,
                2.8239879608154297,
                1.9873944520950317,
                3.6565401554107666,
                3.8612892627716064,
                2.2406840324401855,
                4.161259651184082,
                4.476065635681152,
                4.350516319274902,
                3.050762414932251,
                3.4485533237457275,
                4.517757415771484,
                5.6517438888549805,
                6.609029769897461
            ],
            [
                2.818819999694824,
                3.825366973876953,
                3.769364833831787,
                3.064736843109131,
                3.696782350540161,
                3.9016687870025635,
                4.508078575134277,
                3.1102757453918457,
                4.838386058807373,
                4.122763156890869,
                2.0537304878234863,
                1.1472034454345703,
                4.303474426269531,
                8.709447860717773,
                5.220284461975098,
                4.440918445587158
            ],
            [
                0.3245299756526947,
                -0.09971469640731812,
                -0.03308761119842529,
                0.27878817915916443,
                0.18690991401672363,
                0.01655213162302971,
                0.04447927325963974,
                -0.12963378429412842,
                -0.16910792887210846,
                -0.07959110289812088,
                0.10174576938152313,
                0.04413682222366333,
                -0.14944025874137878,
                -0.16615737974643707,
                -0.13207422196865082,
                -0.05191047862172127
            ],
            [
                0.07858951389789581,
                0.09364012628793716,
                -0.08854173123836517,
                0.22653475403785706,
                -0.012695617973804474,
                -0.12238423526287079,
                -0.0602303147315979,
                -0.15260788798332214,
                -0.037207793444395065,
                -0.04804086685180664,
                0.042700786143541336,
                0.2816554605960846,
                0.032636962831020355,
                -0.014518694020807743,
                0.1658940315246582,
                0.32303309440612793
            ],
            [
                0.519195556640625,
                0.0018553055124357343,
                0.0015715535264462233,
                -0.04010278731584549,
                -0.08996648341417313,
                0.023458993062376976,
                -0.04764851555228233,
                -0.09980093687772751,
                -0.1162678599357605,
                -0.153940349817276,
                -0.07394689321517944,
                0.25726306438446045,
                -0.08087325096130371,
                0.1428174376487732,
                0.2619871497154236,
                -0.007057045586407185
            ],
            [
                0.046704672276973724,
                -0.11338143050670624,
                0.08744790405035019,
                -0.16211800277233124,
                -0.16202092170715332,
                -0.07982341945171356,
                0.26605141162872314,
                0.13793827593326569,
                0.008884185925126076,
                -0.02353421226143837,
                -0.11602040380239487,
                0.11308735609054565,
                0.07995307445526123,
                0.21327263116836548,
                0.08116739988327026,
                0.07954542338848114
            ],
            [
                0.412937730550766,
                0.10525500029325485,
                0.1739291250705719,
                0.08427460491657257,
                -0.1550886631011963,
                -0.08346311002969742,
                -0.048484161496162415,
                -0.017475532367825508,
                -0.03411179408431053,
                -0.04527806490659714,
                -0.10131821036338806,
                -0.0903668999671936,
                0.04981502518057823,
                0.22547833621501923,
                -0.03933146968483925,
                -0.03459113463759422
            ],
            [
                0.20586785674095154,
                0.02046005055308342,
                -0.04878174886107445,
                0.010222329758107662,
                -0.09991035610437393,
                0.011092405766248703,
                -0.04868714138865471,
                -0.09092877060174942,
                -0.07654853910207748,
                0.05431835725903511,
                0.2376594841480255,
                -0.16963987052440643,
                -0.1553620994091034,
                0.04209857061505318,
                0.30537596344947815,
                -0.06146174296736717
            ],
            [
                0.08217530697584152,
                -0.0693444237112999,
                0.05625716224312782,
                0.027131337672472,
                -0.1059078797698021,
                -0.042116135358810425,
                0.019919076934456825,
                -0.04079785570502281,
                0.03570699691772461,
                -0.027443207800388336,
                -0.05341191589832306,
                -0.11053398996591568,
                0.020540626719594002,
                -0.01067016739398241,
                -0.09818127006292343,
                -0.16321296989917755
            ],
            [
                0.2741592824459076,
                0.1454179883003235,
                0.4468062222003937,
                0.7601158618927002,
                0.15758734941482544,
                -0.09353718906641006,
                0.1391744166612625,
                0.1546822041273117,
                0.36980968713760376,
                0.08368784934282303,
                0.12317600846290588,
                0.08088887482881546,
                0.31229403614997864,
                0.3094162046909332,
                0.46924930810928345,
                -0.13375377655029297
            ]
        ],
        [
            [
                2.9174318313598633,
                2.384192943572998,
                2.5145387649536133,
                2.9771690368652344,
                4.324110507965088,
                3.6555774211883545,
                2.625819683074951,
                2.256013870239258,
                4.204705238342285,
                3.367786169052124,
                1.7387957572937012,
                1.9057629108428955,
                2.348691463470459,
                2.8754029273986816,
                3.856428384780884,
                3.100698471069336
            ],
            [
                2.5863778591156006,
                6.429454803466797,
                1.5946557521820068,
                1.2159621715545654,
                3.9251368045806885,
                5.3845319747924805,
                4.68735933303833,
                4.339476585388184,
                3.3495044708251953,
                1.737798810005188,
                1.401232123374939,
                2.5800414085388184,
                2.6602368354797363,
                3.0704751014709473,
                4.217134475708008,
                3.65346360206604
            ],
            [
                1.5707544088363647,
                5.933931350708008,
                5.112869739532471,
                3.080286741256714,
                5.204445838928223,
                5.362606525421143,
                5.305222988128662,
                2.5395591259002686,
                3.4877357482910156,
                1.9062328338623047,
                1.3365143537521362,
                2.7659695148468018,
                4.600812911987305,
                7.701314449310303,
                6.776034832000732,
                5.2107415199279785
            ],
            [
                2.1801342964172363,
                3.5248184204101562,
                3.5357367992401123,
                3.740492105484009,
                4.923011302947998,
                4.681071758270264,
                5.729462146759033,
                1.5058398246765137,
                3.4829156398773193,
                -0.11200179159641266,
                3.4095826148986816,
                2.510370969772339,
                2.4681873321533203,
                8.393054962158203,
                8.731170654296875,
                1.8298245668411255
            ],
            [
                3.3899829387664795,
                5.856797695159912,
                2.851245403289795,
                1.5366252660751343,
                4.732222080230713,
                4.691359996795654,
                4.787721633911133,
                6.010189533233643,
                4.075717449188232,
                2.630783796310425,
                2.514331102371216,
                2.9107706546783447,
                3.667590618133545,
                2.0615944862365723,
                3.2102625370025635,
                2.176105260848999
            ],
            [
                1.6565865278244019,
                2.988054037094116,
                3.9268863201141357,
                2.484170436859131,
                3.076113700866699,
                5.1255998611450195,
                2.0227675437927246,
                3.8240342140197754,
                2.614231586456299,
                3.491246461868286,
                4.527553558349609,
                10.148858070373535,
                4.547468185424805,
                5.540194034576416,
                2.9331114292144775,
                2.6315815448760986
            ],
            [
                2.6576826572418213,
                3.016587495803833,
                4.779020309448242,
                5.150571346282959,
                3.4899234771728516,
                3.9148216247558594,
                4.30012321472168,
                2.239410400390625,
                5.8054118156433105,
                5.839181900024414,
                5.7015275955200195,
                5.371742248535156,
                4.16249418258667,
                10.388419151306152,
                7.896533012390137,
                5.965237617492676
            ],
            [
                1.3577914237976074,
                6.055099964141846,
                3.9213147163391113,
                7.067873954772949,
                1.073966145515442,
                1.9330315589904785,
                4.985576152801514,
                2.536301612854004,
                5.540140151977539,
                3.7516980171203613,
                3.615298271179199,
                2.3070242404937744,
                3.9789979457855225,
                null,
                11.646815299987793,
                3.0470783710479736
            ],
            [
                -0.1560204178094864,
                -0.14878447353839874,
                -0.1699562966823578,
                -0.15745896100997925,
                -0.16061176359653473,
                -0.16526533663272858,
                -0.12503249943256378,
                -0.16486747562885284,
                -0.15982584655284882,
                -0.16663557291030884,
                -0.16671811044216156,
                -0.16892410814762115,
                -0.1674608588218689,
                -0.16510958969593048,
                -0.16732974350452423,
                -0.15518395602703094
            ],
            [
                -0.1621917188167572,
                -0.16755785048007965,
                -0.1694270521402359,
                -0.1581922173500061,
                -0.15079931914806366,
                -0.15802331268787384,
                -0.15933428704738617,
                -0.1501295566558838,
                -0.12839971482753754,
                -0.16821728646755219,
                -0.16977949440479279,
                -0.14910700917243958,
                -0.16066598892211914,
                -0.13775180280208588,
                -0.16919125616550446,
                -0.15593457221984863
            ],
            [
                -0.16875550150871277,
                -0.16978132724761963,
                -0.1521967351436615,
                -0.16103686392307281,
                -0.16729535162448883,
                -0.1677781343460083,
                -0.1649290919303894,
                -0.1690674126148224,
                -0.16361334919929504,
                -0.16934771835803986,
                -0.15493090450763702,
                -0.1515801101922989,
                -0.13601110875606537,
                -0.16916833817958832,
                -0.16451376676559448,
                -0.16203060746192932
            ],
            [
                -0.1686202585697174,
                -0.16897200047969818,
                -0.15526948869228363,
                -0.146762415766716,
                -0.16270139813423157,
                -0.1679498255252838,
                -0.16596224904060364,
                -0.1498919129371643,
                -0.15462380647659302,
                -0.1474347561597824,
                -0.1691358983516693,
                -0.16970989108085632,
                -0.1500970870256424,
                -0.16352958977222443,
                -0.1552705019712448,
                -0.13783994317054749
            ],
            [
                -0.16760413348674774,
                -0.1570930778980255,
                -0.1674908995628357,
                -0.13754548132419586,
                -0.15293918550014496,
                -0.150014728307724,
                -0.16187061369419098,
                -0.15661561489105225,
                -0.16900615394115448,
                -0.1393788903951645,
                -0.16871333122253418,
                -0.1699552983045578,
                -0.1307581514120102,
                -0.14180637896060944,
                -0.15772564709186554,
                -0.16983771324157715
            ],
            [
                -0.16940373182296753,
                -0.1550254076719284,
                -0.14826834201812744,
                -0.16995075345039368,
                -0.1448085606098175,
                -0.15496505796909332,
                -0.16986452043056488,
                -0.14627258479595184,
                -0.16980208456516266,
                -0.14994965493679047,
                -0.1700403094291687,
                -0.13707323372364044,
                -0.1696731597185135,
                -0.12736140191555023,
                -0.16812282800674438,
                -0.16970045864582062
            ],
            [
                -0.14561407268047333,
                -0.16162964701652527,
                -0.15527856349945068,
                -0.14774426817893982,
                -0.16773800551891327,
                -0.16992482542991638,
                -0.1466411054134369,
                -0.16502821445465088,
                -0.16971321403980255,
                -0.17004050314426422,
                -0.16300129890441895,
                -0.08984945714473724,
                -0.16803868114948273,
                -0.15994471311569214,
                -0.1641230583190918,
                -0.169913649559021
            ],
            [
                -0.11386294662952423,
                -0.1651400476694107,
                -0.15387922525405884,
                -0.16400958597660065,
                -0.1654311567544937,
                -0.15988554060459137,
                -0.16698653995990753,
                -0.16880843043327332,
                -0.11043615639209747,
                -0.12381521612405777,
                -0.16414125263690948,
                -0.1572454273700714,
                -0.16181334853172302,
                -0.15332187712192535,
                -0.14789968729019165,
                -0.15998058021068573
            ]
        ],
        [
            [
                1.2085436582565308,
                2.891911029815674,
                2.5956790447235107,
                1.9580491781234741,
                3.7551429271698,
                2.696291446685791,
                1.6652417182922363,
                1.8605360984802246,
                4.092567443847656,
                1.5950292348861694,
                2.038393497467041,
                0.8141419887542725,
                2.375865936279297,
                1.3108558654785156,
                3.2652926445007324,
                2.946735143661499
            ],
            [
                2.8565402030944824,
                0.7640080451965332,
                1.5026195049285889,
                0.7471232414245605,
                0.8297513127326965,
                2.2677602767944336,
                0.97850501537323,
                1.2218865156173706,
                3.5289716720581055,
                2.737144708633423,
                1.724277377128601,
                2.739137649536133,
                1.6192247867584229,
                3.3852145671844482,
                4.073276519775391,
                0.27644187211990356
            ],
            [
                1.2997279167175293,
                1.6148282289505005,
                1.5706733465194702,
                1.9547010660171509,
                2.4515037536621094,
                3.4540908336639404,
                4.107032299041748,
                2.5220978260040283,
                2.6338629722595215,
                2.642267942428589,
                1.2124254703521729,
                0.8943023681640625,
                2.4066970348358154,
                1.827191948890686,
                3.1718578338623047,
                3.94331693649292
            ],
            [
                2.278440475463867,
                1.7398350238800049,
                3.17830491065979,
                2.463702440261841,
                2.1469027996063232,
                3.8640010356903076,
                1.6535370349884033,
                0.3437004089355469,
                1.1725844144821167,
                2.9263432025909424,
                0.12415991723537445,
                0.6824603080749512,
                0.8784998059272766,
                3.934596538543701,
                2.2992684841156006,
                3.0530402660369873
            ],
            [
                1.485001802444458,
                2.1777284145355225,
                2.318850517272949,
                2.654308319091797,
                2.2250280380249023,
                0.7391384840011597,
                1.8101249933242798,
                1.1772997379302979,
                1.6571344137191772,
                0.9074131846427917,
                1.839531421661377,
                1.9520478248596191,
                2.705376625061035,
                2.266664505004883,
                2.4742164611816406,
                2.018336772918701
            ],
            [
                0.9499084949493408,
                1.1956146955490112,
                2.0394392013549805,
                1.5820491313934326,
                2.7679507732391357,
                2.060391664505005,
                0.42894187569618225,
                1.9052749872207642,
                2.0763702392578125,
                1.53148353099823,
                3.1445188522338867,
                2.6185545921325684,
                4.1281208992004395,
                2.88944411277771,
                2.538607120513916,
                2.0160043239593506
            ],
            [
                2.8902018070220947,
                3.792752504348755,
                3.565402030944824,
                4.0851731300354,
                2.2312419414520264,
                4.036233425140381,
                0.18187539279460907,
                1.9581547975540161,
                0.9381117224693298,
                3.122875452041626,
                0.8801285624504089,
                1.167942762374878,
                0.9662377238273621,
                3.4384963512420654,
                3.735929250717163,
                3.8029258251190186
            ],
            [
                1.19968581199646,
                3.8769752979278564,
                2.5490670204162598,
                1.764730453491211,
                3.954392433166504,
                2.9925742149353027,
                2.742382526397705,
                1.8466289043426514,
                3.1693379878997803,
                4.454963684082031,
                2.506904363632202,
                2.486156702041626,
                3.216233253479004,
                2.9229533672332764,
                2.4259562492370605,
                4.833885192871094
            ],
            [
                0.27066558599472046,
                1.0895559787750244,
                1.2814266681671143,
                1.1640348434448242,
                1.1843256950378418,
                0.8048339486122131,
                1.4599374532699585,
                0.5316728353500366,
                1.1464450359344482,
                1.0658435821533203,
                0.6567131280899048,
                0.6939417719841003,
                0.3768967390060425,
                0.8773046731948853,
                1.0948773622512817,
                1.6462481021881104
            ],
            [
                0.3174006938934326,
                0.4623989462852478,
                0.7375392317771912,
                1.0930038690567017,
                0.6516932249069214,
                0.916471004486084,
                0.9674316644668579,
                0.5288625359535217,
                0.7983729243278503,
                0.8738772869110107,
                1.0368162393569946,
                1.243301510810852,
                0.4814864993095398,
                0.7046608328819275,
                1.0298408269882202,
                0.9876442551612854
            ],
            [
                0.4996426999568939,
                0.5745448470115662,
                1.4017119407653809,
                0.8643218278884888,
                0.7602941393852234,
                0.9840390682220459,
                0.48569563031196594,
                0.49844300746917725,
                0.6700129508972168,
                0.6016125679016113,
                0.6955727934837341,
                1.027976155281067,
                1.119585633277893,
                1.0506069660186768,
                1.0268641710281372,
                0.5425136089324951
            ],
            [
                0.20920036733150482,
                0.7488085031509399,
                1.0253039598464966,
                0.6760304570198059,
                0.9853276014328003,
                0.7855647802352905,
                0.5386286377906799,
                1.0343300104141235,
                0.44099509716033936,
                0.7670584917068481,
                0.921445906162262,
                0.7548549771308899,
                1.4695249795913696,
                0.5971052646636963,
                1.1773030757904053,
                1.0915772914886475
            ],
            [
                0.5910708904266357,
                0.9380990266799927,
                0.7533679008483887,
                1.2827764749526978,
                0.4312729239463806,
                1.3428148031234741,
                0.5791710019111633,
                0.7146565914154053,
                0.7021198272705078,
                0.9254847764968872,
                0.8877264857292175,
                1.1785962581634521,
                1.4605807065963745,
                1.1562286615371704,
                0.7795951962471008,
                0.9707486629486084
            ],
            [
                0.48960694670677185,
                0.5465306639671326,
                0.9533662796020508,
                0.9330845475196838,
                0.6398873329162598,
                0.7391947507858276,
                0.3320881128311157,
                0.838141918182373,
                0.7994123101234436,
                0.9645847678184509,
                1.4189119338989258,
                1.1363091468811035,
                1.073423147201538,
                1.3447848558425903,
                0.8906505107879639,
                0.8976912498474121
            ],
            [
                0.13840365409851074,
                0.7507665753364563,
                1.2549089193344116,
                0.43055418133735657,
                1.4499096870422363,
                1.3001270294189453,
                0.6301120519638062,
                0.42095932364463806,
                0.5447345972061157,
                1.2154076099395752,
                0.4105203449726105,
                1.8525105714797974,
                0.9805030226707458,
                1.0252636671066284,
                0.9724710583686829,
                0.7069031000137329
            ],
            [
                0.45730698108673096,
                0.4499821364879608,
                0.7154573798179626,
                0.6139975786209106,
                0.6783519387245178,
                0.7570680975914001,
                1.228899598121643,
                0.6550249457359314,
                0.8249872922897339,
                0.7999973297119141,
                1.0536409616470337,
                0.7140188217163086,
                0.5008475184440613,
                0.9404005408287048,
                0.4999088943004608,
                0.5956020951271057
            ]
        ],
        [
            [
                -0.149471715092659,
                -0.004948743153363466,
                -0.05760404095053673,
                -0.0007185481954365969,
                -0.0957869291305542,
                -0.0355280302464962,
                -0.009448016062378883,
                -0.09098610281944275,
                0.2352716028690338,
                -0.001971834572032094,
                -0.017632873728871346,
                -0.007563488092273474,
                -0.0069047994911670685,
                -0.00007789059600327164,
                0.11891073733568192,
                -0.01306939497590065
            ],
            [
                -0.16579534113407135,
                -0.0038006079848855734,
                -9.968950962502277e-7,
                -0.00153399130795151,
                -0.042436111718416214,
                0.19566480815410614,
                -0.10235076397657394,
                -0.1096152663230896,
                -0.12328869104385376,
                -0.004126083571463823,
                -0.00014533221838064492,
                -0.1699201464653015,
                -0.15999196469783783,
                -0.17001532018184662,
                -0.10048558562994003,
                -0.0006711883470416069
            ],
            [
                -0.0001162380794994533,
                -0.09419939666986465,
                -0.1492994874715805,
                -0.06361628323793411,
                -0.15866118669509888,
                -0.00010200821998296306,
                -0.1454591155052185,
                -0.15921823680400848,
                -0.15538306534290314,
                -0.08276280015707016,
                -0.0018712441669777036,
                -0.005165718030184507,
                -0.0019812178798019886,
                -0.11128589510917664,
                0,
                0.38403934240341187
            ],
            [
                0.7577142715454102,
                -0.028949322178959846,
                -0.15415911376476288,
                -0.10789396613836288,
                -0.16395948827266693,
                0.1448037028312683,
                -0.16944393515586853,
                -4.352632458903827e-7,
                -0.005153991747647524,
                -0.009713433682918549,
                -0.013853201642632484,
                -0.023311972618103027,
                -0.0000023471573058486683,
                -0.16779455542564392,
                0.23069651424884796,
                -0.0003311856125947088
            ],
            [
                0.1871412694454193,
                0.2423405796289444,
                -0.11396551877260208,
                -0.00003162848952342756,
                -0.03994985297322273,
                -0.0019728788174688816,
                -0.005072255618870258,
                0.8456394076347351,
                1.127306342124939,
                -0.0010356256971135736,
                -0.0006372280768118799,
                -0.027511540800333023,
                -0.00413188710808754,
                -0.06970042735338211,
                -0.0562281496822834,
                -0.02359110116958618
            ],
            [
                -0.16491097211837769,
                -0.08896764367818832,
                -0.09556595981121063,
                -0.00023333502758760005,
                -0.1317768096923828,
                -0.03165220841765404,
                -0.05642123520374298,
                -0.15813462436199188,
                -0.14915400743484497,
                -0.15947581827640533,
                -0.031905077397823334,
                -0.11563722044229507,
                0.22334925830364227,
                -0.018581118434667587,
                -0.0007436493178829551,
                -0.0009923650650307536
            ],
            [
                -0.12808817625045776,
                -0.006613170262426138,
                1.1466460227966309,
                -0.1581018567085266,
                -0.11940664052963257,
                -0.05661535635590553,
                -0.04952671006321907,
                -0.08730930835008621,
                0.06827559322118759,
                -0.07444535940885544,
                -0.0021935494150966406,
                -0.055250510573387146,
                -0.00002400158336968161,
                -0.08994805812835693,
                -0.054917216300964355,
                -0.10255791991949081
            ],
            [
                0.03436426818370819,
                0.09370105713605881,
                2.409262180328369,
                -0.017161766067147255,
                -0.04048533737659454,
                1.7098942995071411,
                0.7863609194755554,
                -0.11904964596033096,
                1.111608624458313,
                -0.05189211294054985,
                -0.02963642030954361,
                0.4293384253978729,
                -0.027796050533652306,
                -0.0019840190652757883,
                0,
                -0.00012488251377362758
            ],
            [
                -0.16487011313438416,
                -0.14849123358726501,
                -0.16994570195674896,
                -0.11774590611457825,
                -0.07176383584737778,
                -0.13904206454753876,
                -0.16761468350887299,
                -0.15459014475345612,
                -0.14558225870132446,
                -0.15809382498264313,
                -0.15524965524673462,
                -0.16486358642578125,
                -0.1546371579170227,
                -0.1287515014410019,
                -0.15484581887722015,
                -0.1356235146522522
            ],
            [
                -0.17003878951072693,
                -0.1339707225561142,
                -0.16644041240215302,
                -0.16767480969429016,
                -0.15899674594402313,
                -0.16143734753131866,
                -0.14495794475078583,
                -0.16407641768455505,
                -0.16126152873039246,
                -0.1617884784936905,
                -0.1613483875989914,
                -0.1468295305967331,
                -0.16849540174007416,
                -0.16235198080539703,
                -0.16118954122066498,
                -0.13335241377353668
            ],
            [
                -0.11591026932001114,
                -0.16058677434921265,
                -0.16571666300296783,
                -0.1667674332857132,
                -0.1695016622543335,
                -0.11785713583230972,
                -0.169804647564888,
                -0.15931649506092072,
                -0.16876734793186188,
                -0.1697290539741516,
                -0.16715842485427856,
                -0.16807307302951813,
                -0.16801396012306213,
                -0.16803164780139923,
                -0.1655510812997818,
                -0.15184253454208374
            ],
            [
                -0.15801875293254852,
                -0.10489444434642792,
                -0.1258530467748642,
                -0.1214931532740593,
                -0.15309035778045654,
                -0.16540759801864624,
                -0.1593383103609085,
                -0.16989536583423615,
                -0.16970553994178772,
                -0.1700175255537033,
                -0.14098215103149414,
                -0.14856162667274475,
                -0.1700192242860794,
                -0.17003151774406433,
                -0.11877255886793137,
                -0.14457759261131287
            ],
            [
                -0.1671600043773651,
                -0.12950517237186432,
                -0.154362753033638,
                -0.16868561506271362,
                -0.16560932993888855,
                -0.16904354095458984,
                -0.1336461752653122,
                -0.16802331805229187,
                -0.12967807054519653,
                -0.1651744842529297,
                -0.16327279806137085,
                -0.16742533445358276,
                -0.1684265285730362,
                -0.1698613464832306,
                -0.15986451506614685,
                -0.16837431490421295
            ],
            [
                -0.15861280262470245,
                -0.16174061596393585,
                -0.14970824122428894,
                -0.16696758568286896,
                -0.1643260270357132,
                -0.16856749355793,
                -0.15376871824264526,
                -0.12959665060043335,
                -0.16876232624053955,
                -0.1581159085035324,
                0.06405583024024963,
                -0.10390967130661011,
                -0.16602687537670135,
                -0.1532360464334488,
                -0.12822076678276062,
                -0.13623236119747162
            ],
            [
                -0.1699683517217636,
                -0.13970135152339935,
                -0.13989455997943878,
                -0.14472131431102753,
                -0.15790481865406036,
                -0.16769330203533173,
                -0.16658605635166168,
                -0.16647464036941528,
                -0.15155796706676483,
                -0.16895754635334015,
                -0.1694248765707016,
                -0.1651262491941452,
                -0.16248421370983124,
                -0.1610441356897354,
                -0.149998739361763,
                -0.17001444101333618
            ],
            [
                -0.15204164385795593,
                -0.16850508749485016,
                -0.16574770212173462,
                -0.16978216171264648,
                -0.16952893137931824,
                -0.14157071709632874,
                -0.15602417290210724,
                -0.1676727831363678,
                -0.16799084842205048,
                -0.1670316755771637,
                -0.14768332242965698,
                -0.15890668332576752,
                -0.16317541897296906,
                -0.1298539638519287,
                -0.16970393061637878,
                -0.16803090274333954
            ]
        ],
        [
            [
                -0.027645984664559364,
                -0.14620272815227509,
                -0.04015970975160599,
                -0.004190503153949976,
                -0.1069437637925148,
                -0.04284859448671341,
                -0.05710264667868614,
                -0.1408207267522812,
                -0.13172152638435364,
                -0.14292700588703156,
                -0.15055860579013824,
                -0.016214733943343163,
                -0.13869796693325043,
                -0.10560360550880432,
                -0.0028630245942622423,
                -0.09827066957950592
            ],
            [
                -0.1318775713443756,
                -0.16088931262493134,
                -0.16556818783283234,
                -0.09407681226730347,
                -0.053586818277835846,
                -0.16070522367954254,
                -0.07107138633728027,
                -0.05976536497473717,
                -0.15310141444206238,
                -0.16790509223937988,
                -0.11470867693424225,
                -0.15873116254806519,
                0.032837171107530594,
                0.09864217787981033,
                -0.011608827859163284,
                -0.13296100497245789
            ],
            [
                -0.06960853934288025,
                -0.14686629176139832,
                0.19134841859340668,
                0.713862419128418,
                -0.16956211626529694,
                -0.1226925402879715,
                -0.1684001237154007,
                -0.04129752144217491,
                0.07297047972679138,
                0.5598985552787781,
                -0.09427718073129654,
                -0.13864730298519135,
                -0.15474067628383636,
                -0.12618255615234375,
                -0.009010030888020992,
                1.0672212839126587
            ],
            [
                0.6566640138626099,
                0.025601577013731003,
                -0.12056230753660202,
                -0.0841178223490715,
                -0.11251157522201538,
                -0.05920422077178955,
                -0.16857855021953583,
                -0.03396889194846153,
                0.5395892262458801,
                0.12694722414016724,
                -0.03653579577803612,
                -0.020829876884818077,
                -0.11712905764579773,
                -0.11215467751026154,
                0.018543142825365067,
                0.8637750148773193
            ],
            [
                -0.006004258990287781,
                -0.15787717700004578,
                -0.07107296586036682,
                -0.0290458332747221,
                0.07365601509809494,
                -0.0015760075766593218,
                -0.04593711718916893,
                -0.16971774399280548,
                -0.1700376719236374,
                -0.12935848534107208,
                -0.16797834634780884,
                -0.06743408739566803,
                -0.12103989720344543,
                -0.09949655830860138,
                -0.07297568023204803,
                0.38955068588256836
            ],
            [
                0.00556143606081605,
                -0.15535782277584076,
                -0.061638373881578445,
                -0.08984333276748657,
                1.086777687072754,
                -0.16700685024261475,
                -0.07984752207994461,
                0.06969353556632996,
                0.211958646774292,
                0.22832900285720825,
                -0.10908497869968414,
                -0.00013590759772341698,
                -0.16128365695476532,
                -0.1328199952840805,
                -0.14941895008087158,
                -0.16548514366149902
            ],
            [
                0.07475097477436066,
                -0.13711857795715332,
                -0.16175991296768188,
                -0.09322996437549591,
                -0.1275910586118698,
                0.28097909688949585,
                0.30462846159935,
                0.2475655972957611,
                -0.08924084156751633,
                -0.06866934150457382,
                -0.15986323356628418,
                -0.03161226958036423,
                -0.006083374377340078,
                -0.02763976715505123,
                -0.1439528912305832,
                -0.15221774578094482
            ],
            [
                -0.12251779437065125,
                -0.10589607059955597,
                -0.028219925239682198,
                -0.0003503490297589451,
                0.05688389390707016,
                -0.1359308511018753,
                -0.11776654422283173,
                -0.16276973485946655,
                -0.05499860644340515,
                -0.16941212117671967,
                -0.10872089862823486,
                -0.1601349115371704,
                -0.08641944825649261,
                -0.0000036654937503044493,
                0,
                -0.0998232439160347
            ],
            [
                0.41948622465133667,
                0.8491931557655334,
                1.1987076997756958,
                0.9955143332481384,
                0.9780168533325195,
                0.5807273983955383,
                0.7439386248588562,
                0.8968501091003418,
                0.6813023686408997,
                0.7971763014793396,
                0.8613594770431519,
                0.8953653573989868,
                1.033279538154602,
                0.9622425436973572,
                1.0269492864608765,
                0.49907806515693665
            ],
            [
                0.419765830039978,
                0.716758131980896,
                0.8791327476501465,
                0.7015385627746582,
                0.8937462568283081,
                0.9121389985084534,
                1.131169319152832,
                1.0828001499176025,
                0.7440575957298279,
                1.0480647087097168,
                0.9752317070960999,
                0.9265179634094238,
                0.7611966133117676,
                0.9427432417869568,
                1.0493637323379517,
                0.6183521747589111
            ],
            [
                0.47126317024230957,
                0.7741621136665344,
                0.8238171935081482,
                0.9754719734191895,
                0.9989975690841675,
                0.7842079997062683,
                1.0281400680541992,
                0.8522626757621765,
                0.9978857040405273,
                1.118970513343811,
                1.0036060810089111,
                0.8772700428962708,
                0.6840758323669434,
                0.9864466786384583,
                0.9872908592224121,
                1.0276647806167603
            ],
            [
                0.5212094783782959,
                1.222338318824768,
                0.747589647769928,
                0.9077399373054504,
                0.8843984603881836,
                0.7086063027381897,
                0.8130146265029907,
                0.7910009622573853,
                0.6751255393028259,
                0.9368863105773926,
                0.881415069103241,
                1.1263009309768677,
                0.7003787159919739,
                0.8093916773796082,
                1.2239553928375244,
                0.9895937442779541
            ],
            [
                0.5324223637580872,
                1.1538887023925781,
                1.012813925743103,
                1.2520930767059326,
                1.5245497226715088,
                0.7157214879989624,
                0.7425131797790527,
                0.8166126608848572,
                0.9775217771530151,
                1.0194815397262573,
                0.9749561548233032,
                0.9497293829917908,
                0.8212352395057678,
                0.6717721819877625,
                1.1419771909713745,
                0.5445038080215454
            ],
            [
                0.5072413682937622,
                1.068000316619873,
                0.9805408716201782,
                0.9682996273040771,
                1.1138197183609009,
                0.924258291721344,
                1.0049258470535278,
                0.6519058346748352,
                0.7171066403388977,
                0.900816798210144,
                0.7674556970596313,
                0.8682626485824585,
                0.8817246556282043,
                0.7024648189544678,
                1.077673316001892,
                0.75885409116745
            ],
            [
                0.4468536376953125,
                0.7125182151794434,
                0.6990728974342346,
                0.9080137610435486,
                0.7828825116157532,
                0.5472675561904907,
                0.7299020290374756,
                0.8212895393371582,
                0.8593689799308777,
                0.7927052974700928,
                0.6051396131515503,
                0.6977512240409851,
                0.9767711162567139,
                0.9380577206611633,
                0.9240661263465881,
                0.5637956857681274
            ],
            [
                0.3920404314994812,
                0.7465784549713135,
                0.5315061211585999,
                0.7040256857872009,
                0.6758630275726318,
                0.7746059894561768,
                0.665195643901825,
                0.6054412126541138,
                0.6103317141532898,
                0.7197347283363342,
                0.5817967057228088,
                0.8074528574943542,
                0.7236141562461853,
                0.6192528605461121,
                1.0733331441879272,
                0.6439982652664185
            ]
        ],
        [
            [
                1.2762092351913452,
                3.243053674697876,
                1.651502013206482,
                1.5221304893493652,
                2.348729133605957,
                4.397122383117676,
                2.3767359256744385,
                3.4923133850097656,
                0.5552359223365784,
                2.964977741241455,
                3.3603694438934326,
                0.28500309586524963,
                2.635470390319824,
                3.372809886932373,
                -0.14847834408283234,
                1.0832046270370483
            ],
            [
                4.157554626464844,
                2.277547836303711,
                2.8921611309051514,
                0.9422541260719299,
                1.1889926195144653,
                3.6026883125305176,
                1.1862657070159912,
                1.55973482131958,
                2.5746309757232666,
                3.6307260990142822,
                2.378699541091919,
                2.137805938720703,
                2.893871784210205,
                1.8954737186431885,
                1.6513671875,
                1.4267644882202148
            ],
            [
                2.1447808742523193,
                2.402517795562744,
                2.5869226455688477,
                3.4126715660095215,
                3.094808578491211,
                1.716537356376648,
                1.0169923305511475,
                2.99884295463562,
                3.8486340045928955,
                2.340006113052368,
                2.8175406455993652,
                0.38212457299232483,
                0.7098527550697327,
                -0.11701543629169464,
                2.2661995887756348,
                1.1141680479049683
            ],
            [
                4.260490894317627,
                2.539283037185669,
                2.8738291263580322,
                2.734903335571289,
                2.3988611698150635,
                0.3981928825378418,
                0.9884672164916992,
                0.8378958702087402,
                2.5360348224639893,
                1.3233023881912231,
                1.5239969491958618,
                3.823955535888672,
                2.32950496673584,
                2.108633518218994,
                0.9797868132591248,
                2.1866846084594727
            ],
            [
                -0.1663658618927002,
                -0.04135388135910034,
                2.4666595458984375,
                2.6450233459472656,
                3.9438483715057373,
                1.1162872314453125,
                0.1526147574186325,
                1.1328414678573608,
                3.554600477218628,
                3.5468578338623047,
                2.388326644897461,
                0.7859247922897339,
                1.4576761722564697,
                2.111917495727539,
                0.9729938507080078,
                1.9386794567108154
            ],
            [
                2.324302911758423,
                3.576171875,
                4.068814277648926,
                2.823608875274658,
                3.5239763259887695,
                2.845520257949829,
                2.1218111515045166,
                2.589833974838257,
                1.9238170385360718,
                2.383943796157837,
                2.507953643798828,
                0.3337130844593048,
                3.392962694168091,
                3.2311699390411377,
                1.4392775297164917,
                2.568451166152954
            ],
            [
                1.828289270401001,
                2.8840134143829346,
                1.0721603631973267,
                3.736599922180176,
                2.103019952774048,
                1.380218505859375,
                1.0712472200393677,
                2.460235834121704,
                1.40809166431427,
                3.199265956878662,
                1.943615436553955,
                1.087289571762085,
                1.5821945667266846,
                -0.12292171269655228,
                1.3918561935424805,
                -0.16453112661838531
            ],
            [
                1.4986381530761719,
                2.5749616622924805,
                -0.16858087480068207,
                0.39085668325424194,
                3.2944142818450928,
                1.6854318380355835,
                0.6739507913589478,
                1.232419729232788,
                0.34493905305862427,
                1.8105143308639526,
                3.563473701477051,
                2.9462738037109375,
                2.0875627994537354,
                1.946045160293579,
                0.4754333198070526,
                2.8319671154022217
            ],
            [
                1.3471356630325317,
                1.2594281435012817,
                1.5406619310379028,
                1.1436007022857666,
                1.085301399230957,
                1.5018717050552368,
                1.3229153156280518,
                1.1460330486297607,
                1.9378987550735474,
                1.7257583141326904,
                0.9574958682060242,
                0.9528088569641113,
                0.9469512104988098,
                0.6725202798843384,
                0.3775204122066498,
                0.6682579517364502
            ],
            [
                1.2994590997695923,
                1.3399429321289062,
                1.5266746282577515,
                0.8775882720947266,
                1.025416374206543,
                0.9756108522415161,
                1.0184788703918457,
                1.4155571460723877,
                0.7375068664550781,
                0.927619218826294,
                1.1532429456710815,
                0.8442828059196472,
                1.3241103887557983,
                1.200069546699524,
                0.9106298089027405,
                1.1673312187194824
            ],
            [
                1.0291380882263184,
                0.823404848575592,
                0.9890233278274536,
                0.9259045720100403,
                1.1001895666122437,
                1.3335533142089844,
                1.2122002840042114,
                1.4138436317443848,
                1.155050277709961,
                1.1884686946868896,
                1.348486065864563,
                1.0798362493515015,
                1.4713910818099976,
                1.4082406759262085,
                1.1348912715911865,
                1.3916120529174805
            ],
            [
                1.3452178239822388,
                1.0496512651443481,
                0.8767200708389282,
                1.049257755279541,
                1.1961607933044434,
                1.18946373462677,
                1.3175114393234253,
                0.7444373369216919,
                1.1499712467193604,
                1.2214980125427246,
                1.2791945934295654,
                0.7086355090141296,
                0.9455569982528687,
                1.2465837001800537,
                1.4525851011276245,
                1.8191851377487183
            ],
            [
                1.6321381330490112,
                1.334373950958252,
                1.0527088642120361,
                1.16025710105896,
                1.190765380859375,
                1.2187391519546509,
                1.211091160774231,
                1.0139800310134888,
                0.9272506237030029,
                0.9845840930938721,
                1.0766178369522095,
                0.8787012696266174,
                0.7941980957984924,
                0.8428487777709961,
                1.4773277044296265,
                1.7157055139541626
            ],
            [
                1.5092623233795166,
                1.1224075555801392,
                1.1486802101135254,
                1.4070069789886475,
                1.4255893230438232,
                1.0758287906646729,
                1.0762035846710205,
                1.051277756690979,
                0.9773345589637756,
                1.044715404510498,
                1.103836178779602,
                1.179794192314148,
                0.8573133945465088,
                0.9524105191230774,
                0.9562057852745056,
                1.4094886779785156
            ],
            [
                1.1652828454971313,
                1.26177179813385,
                1.2009128332138062,
                1.138335943222046,
                0.9805753231048584,
                0.9912278056144714,
                1.0321404933929443,
                1.3731895685195923,
                1.0178489685058594,
                0.7923399209976196,
                0.4786985516548157,
                1.1245743036270142,
                0.8787744641304016,
                0.7330149412155151,
                0.8044191598892212,
                1.1246154308319092
            ],
            [
                1.3652353286743164,
                1.2170416116714478,
                1.2152866125106812,
                1.046405553817749,
                1.2002615928649902,
                0.9726392030715942,
                0.9099016189575195,
                1.2498328685760498,
                1.4550800323486328,
                1.0337148904800415,
                0.9899390339851379,
                1.0904091596603394,
                1.102789282798767,
                1.0237919092178345,
                0.5669509768486023,
                0.6487125754356384
            ]
        ],
        [
            [
                1.3263843059539795,
                3.6647071838378906,
                4.783805847167969,
                5.976871490478516,
                5.409144878387451,
                5.399319171905518,
                4.305513381958008,
                5.549578666687012,
                1.795760989189148,
                5.2170329093933105,
                4.855177402496338,
                4.517338752746582,
                4.2902140617370605,
                5.318163871765137,
                4.279104232788086,
                0.32535120844841003
            ],
            [
                5.0022807121276855,
                5.679832935333252,
                6.887237071990967,
                7.880573272705078,
                6.286127090454102,
                4.755751609802246,
                4.48919153213501,
                6.6396284103393555,
                6.52308464050293,
                6.929964065551758,
                5.5825066566467285,
                6.014076232910156,
                7.50046443939209,
                4.858970642089844,
                7.587405204772949,
                2.4363019466400146
            ],
            [
                4.783266544342041,
                5.522644519805908,
                4.6364569664001465,
                5.032381534576416,
                6.28204345703125,
                5.4350481033325195,
                6.683579444885254,
                7.921246528625488,
                5.931417942047119,
                5.081503391265869,
                5.805317401885986,
                5.060605049133301,
                3.8344039916992188,
                5.341840744018555,
                9.068946838378906,
                4.0100860595703125
            ],
            [
                2.909090518951416,
                5.738742351531982,
                6.644111156463623,
                7.860225200653076,
                8.537755966186523,
                7.398640155792236,
                4.669139862060547,
                7.06302547454834,
                4.369316101074219,
                4.994560718536377,
                4.659093379974365,
                6.027829647064209,
                6.992088794708252,
                5.850579738616943,
                4.342156410217285,
                4.484626770019531
            ],
            [
                1.0539556741714478,
                6.3257646560668945,
                6.059377670288086,
                7.341296195983887,
                7.633291244506836,
                8.144747734069824,
                5.879227638244629,
                3.6718263626098633,
                5.9292168617248535,
                6.646664619445801,
                5.916141033172607,
                3.8733673095703125,
                5.011765956878662,
                6.434276580810547,
                4.663976669311523,
                2.9876723289489746
            ],
            [
                4.48495626449585,
                6.262702465057373,
                5.852453231811523,
                6.77703857421875,
                6.026271820068359,
                5.095614910125732,
                6.464343547821045,
                3.3254430294036865,
                6.16154670715332,
                6.60776424407959,
                5.981318473815918,
                4.664484977722168,
                6.075794219970703,
                6.269026279449463,
                5.543207168579102,
                4.500013828277588
            ],
            [
                4.365769386291504,
                6.6399312019348145,
                4.395362377166748,
                5.603833198547363,
                5.142045974731445,
                3.1248881816864014,
                3.096052408218384,
                3.8525145053863525,
                3.9648234844207764,
                5.533180236816406,
                7.3130083084106445,
                7.196132183074951,
                6.090431213378906,
                6.894331455230713,
                4.839425563812256,
                3.4392008781433105
            ],
            [
                3.8471689224243164,
                5.407535552978516,
                5.539649486541748,
                6.496407985687256,
                6.640177249908447,
                3.1659657955169678,
                2.620166301727295,
                3.37162446975708,
                3.893308401107788,
                6.0127081871032715,
                6.379712104797363,
                7.339367389678955,
                7.728775501251221,
                8.533236503601074,
                8.759208679199219,
                5.9566450119018555
            ],
            [
                -0.10842820256948471,
                -0.1698393076658249,
                -0.15758948028087616,
                -0.15286433696746826,
                -0.15791279077529907,
                -0.1337181031703949,
                -0.11141413450241089,
                -0.09542718529701233,
                -0.16757972538471222,
                -0.10166232287883759,
                -0.08556149899959564,
                -0.07224497944116592,
                -0.09670369327068329,
                -0.13338902592658997,
                -0.16601920127868652,
                -0.08938013017177582
            ],
            [
                -0.04013101011514664,
                -0.1091490089893341,
                -0.1056123748421669,
                -0.11126909404993057,
                -0.11993162333965302,
                -0.16689182817935944,
                -0.11535681039094925,
                -0.09362441301345825,
                -0.1253243237733841,
                -0.10030074417591095,
                -0.1513718068599701,
                -0.09048502147197723,
                -0.0867777094244957,
                -0.06891708821058273,
                -0.08648848533630371,
                -0.055024463683366776
            ],
            [
                -0.07486847043037415,
                -0.1341593712568283,
                -0.1115550622344017,
                -0.15679092705249786,
                -0.13698336482048035,
                -0.10545389354228973,
                -0.09467488527297974,
                -0.06676312536001205,
                -0.12162352353334427,
                -0.07954774796962738,
                -0.08491005748510361,
                -0.12320996820926666,
                -0.13305577635765076,
                -0.060797739773988724,
                -0.09831336885690689,
                -0.04762924462556839
            ],
            [
                -0.055471088737249374,
                -0.10302327573299408,
                -0.1537569761276245,
                -0.16957049071788788,
                -0.16865797340869904,
                -0.10235894471406937,
                -0.0828278660774231,
                -0.09508489817380905,
                -0.15740764141082764,
                -0.12567545473575592,
                -0.13615837693214417,
                -0.14530912041664124,
                -0.1537245810031891,
                -0.1086813285946846,
                -0.09967640787363052,
                -0.08878877758979797
            ],
            [
                -0.0540262833237648,
                -0.07729959487915039,
                -0.14008450508117676,
                -0.14831294119358063,
                -0.15209752321243286,
                -0.13701051473617554,
                -0.08018385618925095,
                -0.09610838443040848,
                -0.09912871569395065,
                -0.13266415894031525,
                -0.11700563877820969,
                -0.125965878367424,
                -0.1429557353258133,
                -0.12977905571460724,
                -0.13237349689006805,
                -0.1383546143770218
            ],
            [
                -0.046924229711294174,
                -0.13482457399368286,
                -0.15871040523052216,
                -0.09243973344564438,
                -0.08104477822780609,
                -0.1070815697312355,
                -0.07240962982177734,
                -0.10055144131183624,
                -0.11630947887897491,
                -0.13691656291484833,
                -0.16999945044517517,
                -0.1441602110862732,
                -0.13211818039417267,
                -0.16597242653369904,
                -0.1116754561662674,
                -0.07826433330774307
            ],
            [
                -0.06887134164571762,
                -0.09900601208209991,
                -0.1609802544116974,
                -0.14027714729309082,
                -0.1372811496257782,
                -0.13552050292491913,
                -0.10307687520980835,
                -0.07460392266511917,
                -0.06628933548927307,
                -0.1513848751783371,
                -0.16638843715190887,
                -0.1589967906475067,
                -0.08906479179859161,
                -0.15997128188610077,
                -0.16458843648433685,
                -0.1394128054380417
            ],
            [
                -0.018070509657263756,
                -0.05254141986370087,
                -0.052744653075933456,
                -0.10957858711481094,
                -0.13254565000534058,
                -0.10048037022352219,
                -0.14469458162784576,
                -0.06913100183010101,
                -0.04797055944800377,
                -0.07256683707237244,
                -0.0927988663315773,
                -0.04967711120843887,
                -0.04635682702064514,
                -0.097075916826725,
                -0.12711013853549957,
                -0.1452479362487793
            ]
        ],
        [
            [
                2.4286911487579346,
                3.6045451164245605,
                3.5972394943237305,
                6.721657752990723,
                3.6648547649383545,
                4.726587772369385,
                5.228931427001953,
                3.619386672973633,
                3.3344013690948486,
                6.029624938964844,
                4.004492282867432,
                6.273587226867676,
                5.320426940917969,
                3.84269642829895,
                6.616965293884277,
                3.0434646606445312
            ],
            [
                3.7629148960113525,
                6.997407913208008,
                4.283626556396484,
                4.566239833831787,
                5.071375846862793,
                4.607970237731934,
                4.566211223602295,
                4.823918342590332,
                5.545460224151611,
                3.5686299800872803,
                4.36784029006958,
                5.7853522300720215,
                4.357236385345459,
                4.68639612197876,
                4.99567985534668,
                5.112596035003662
            ],
            [
                4.437467098236084,
                5.188882827758789,
                4.7391886711120605,
                4.6675567626953125,
                5.361387729644775,
                4.714957237243652,
                4.318918704986572,
                4.214461326599121,
                5.502665042877197,
                5.8796820640563965,
                3.9583241939544678,
                5.020839214324951,
                5.574899673461914,
                8.476811408996582,
                6.6996331214904785,
                5.660772323608398
            ],
            [
                2.395000457763672,
                4.516528606414795,
                3.629903554916382,
                5.326520919799805,
                6.320420265197754,
                8.334221839904785,
                5.365372180938721,
                5.175951957702637,
                5.471958637237549,
                4.2288994789123535,
                3.7128195762634277,
                4.72396183013916,
                6.430760383605957,
                2.8899264335632324,
                4.6884565353393555,
                4.429988861083984
            ],
            [
                1.3831005096435547,
                5.997964382171631,
                5.2590718269348145,
                3.072094202041626,
                4.807662010192871,
                6.225619316101074,
                4.14309549331665,
                6.101534843444824,
                4.668865203857422,
                5.696676731109619,
                3.656428337097168,
                3.491422414779663,
                4.79199743270874,
                5.408332347869873,
                4.863156795501709,
                3.1293296813964844
            ],
            [
                3.899561882019043,
                5.430880069732666,
                5.435631275177002,
                3.7128865718841553,
                5.091907024383545,
                5.327805042266846,
                5.793606758117676,
                3.929682493209839,
                4.5991339683532715,
                5.021432876586914,
                5.9026594161987305,
                4.715086936950684,
                3.8407766819000244,
                6.221767902374268,
                5.849234580993652,
                5.675020694732666
            ],
            [
                2.5982820987701416,
                5.836209774017334,
                2.915271282196045,
                2.546083450317383,
                3.491844415664673,
                2.3773112297058105,
                3.220362424850464,
                3.4910669326782227,
                6.152124881744385,
                4.113536357879639,
                7.000548362731934,
                7.206072807312012,
                8.869915008544922,
                5.99816370010376,
                2.617806911468506,
                5.4089555740356445
            ],
            [
                3.2752890586853027,
                6.38799524307251,
                4.483852386474609,
                8.386815071105957,
                2.651745319366455,
                1.6213253736495972,
                3.0036230087280273,
                2.8474133014678955,
                3.995274543762207,
                3.744624137878418,
                4.589685916900635,
                5.1699042320251465,
                4.255550384521484,
                7.467893600463867,
                5.079148292541504,
                2.2841858863830566
            ],
            [
                -0.16465727984905243,
                0.047056376934051514,
                0.15667255222797394,
                -0.07158935815095901,
                -0.016211895272135735,
                -0.023703429847955704,
                0.07088330388069153,
                0.05775044858455658,
                0.004578647203743458,
                0.2832704484462738,
                0.3215131461620331,
                -0.10284949094057083,
                0.013390039093792439,
                -0.13414235413074493,
                0.039861418306827545,
                -0.04374543949961662
            ],
            [
                -0.11113878339529037,
                0.15737679600715637,
                0.21707561612129211,
                -0.01022602804005146,
                0.09040074050426483,
                0.07412848621606827,
                0.07623767107725143,
                0.20467567443847656,
                -0.09431146830320358,
                0.021724319085478783,
                0.10917673259973526,
                -0.058942269533872604,
                -0.141280397772789,
                0.08340133726596832,
                0.10270499438047409,
                0.05724010616540909
            ],
            [
                -0.12556178867816925,
                -0.02232196554541588,
                -0.013612993061542511,
                0.00909404456615448,
                0.0815209150314331,
                0.12387668341398239,
                0.2023477405309677,
                0.36704742908477783,
                0.14409439265727997,
                0.3271620571613312,
                0.3811861276626587,
                -0.08641915768384933,
                0.031789690256118774,
                0.17704854905605316,
                0.1757466197013855,
                0.22555336356163025
            ],
            [
                -0.08299343287944794,
                0.3303951323032379,
                0.1349070817232132,
                0.10844317823648453,
                0.10864482820034027,
                0.006504278164356947,
                0.06142177805304527,
                -0.051186297088861465,
                -0.10449427366256714,
                0.2148512452840805,
                -0.0461578331887722,
                0.14193542301654816,
                -0.0759027749300003,
                0.05768156424164772,
                0.3943190276622772,
                0.6703611612319946
            ],
            [
                -0.149208664894104,
                0.3487878739833832,
                -0.06849494576454163,
                0.18356391787528992,
                0.3261421322822571,
                0.013111453503370285,
                -0.043325550854206085,
                0.10107269138097763,
                -0.09649449586868286,
                0.07785344868898392,
                0.18210576474666595,
                0.015332561917603016,
                -0.07775350660085678,
                -0.04985395818948746,
                0.4913899302482605,
                0.5722085237503052
            ],
            [
                -0.09877022355794907,
                0.15284225344657898,
                0.5907464027404785,
                0.12061363458633423,
                0.28864675760269165,
                0.03462669253349304,
                0.04756145551800728,
                -0.055608004331588745,
                0.01062348484992981,
                -0.05874468386173248,
                -0.09016988426446915,
                0.0445125438272953,
                0.1346496194601059,
                0.04628461226820946,
                -0.08795126527547836,
                -0.07961153239011765
            ],
            [
                -0.07008291780948639,
                0.02416086569428444,
                0.03861459344625473,
                -0.0009278287761844695,
                0.5380277037620544,
                0.18331657350063324,
                0.07350316643714905,
                0.0976012721657753,
                0.12675631046295166,
                -0.06747888028621674,
                -0.035740405321121216,
                -0.02139861509203911,
                -0.016042545437812805,
                0.1804262399673462,
                -0.12000776082277298,
                0.05946679413318634
            ],
            [
                -0.15163205564022064,
                -0.05641943961381912,
                -0.12322026491165161,
                -0.16196827590465546,
                -0.038834188133478165,
                -0.0511932335793972,
                -0.11453992128372192,
                -0.12684929370880127,
                -0.13608881831169128,
                -0.11286742240190506,
                -0.16753274202346802,
                -0.08909545093774796,
                -0.08086714148521423,
                -0.10891716927289963,
                -0.16868449747562408,
                0.021702013909816742
            ]
        ],
        [
            [
                -0.023351486772298813,
                -0.007555742282420397,
                0.01555713452398777,
                -0.023564642295241356,
                -0.10755829513072968,
                -0.01362681295722723,
                -0.016289599239826202,
                -0.026534702628850937,
                -0.16018033027648926,
                -0.060370322316884995,
                -0.032241351902484894,
                -0.12152308970689774,
                -0.06050261855125427,
                -0.009444281458854675,
                -0.007011696696281433,
                -0.1084219440817833
            ],
            [
                -0.07272227853536606,
                0,
                -0.000013646224033436738,
                -0.14772754907608032,
                -0.000006758486506441841,
                -0.03202052786946297,
                -0.003362595569342375,
                -0.00047248537885025144,
                -0.0014102767454460263,
                -0.000008666824214742519,
                -0.00011183162132510915,
                -0.000537041574716568,
                -0.00392912095412612,
                -0.007534383796155453,
                -0.009097236208617687,
                -0.07967206835746765
            ],
            [
                -0.03594907000660896,
                -0.0005661883042193949,
                -0.0449918694794178,
                -0.12088057398796082,
                -0.00014133074728306383,
                -0.00004300897489883937,
                -0.005697586107999086,
                -0.1617315411567688,
                -0.0016751018119975924,
                -0.0005560857825912535,
                -0.000544618524145335,
                -0.0002215932181570679,
                -0.0036886835005134344,
                0,
                -2.305634438926063e-7,
                -0.05802595615386963
            ],
            [
                -0.15204425156116486,
                -0.08866840600967407,
                -0.00044897105544805527,
                -0.00044139314559288323,
                -4.3891904510928725e-7,
                -0.024344494566321373,
                -0.0036006669979542494,
                -0.0074432590045034885,
                -0.0073348162695765495,
                -0.012481740675866604,
                -0.0056455680169165134,
                -0.0003154294681735337,
                0,
                -0.0000032834136618475895,
                -1.917515106697465e-7,
                -0.0005873964983038604
            ],
            [
                -0.009928189218044281,
                -0.0006304363487288356,
                -0.0005860362434759736,
                -0.00007101346272975206,
                -0.00009445785690331832,
                0,
                -0.00000509643905388657,
                -0.0027107123751193285,
                -4.382033012007014e-7,
                -0.0007407573284581304,
                -0.02490801177918911,
                -0.1129031702876091,
                -0.11154674738645554,
                -0.001670864294283092,
                -0.0055969045497477055,
                -0.012058267369866371
            ],
            [
                -0.16286800801753998,
                -0.018434641882777214,
                -0.023740360513329506,
                -0.006934892851859331,
                -0.0037238311488181353,
                -0.011178065091371536,
                -0.00326162064447999,
                -0.000001136156242864672,
                -0.0000883249012986198,
                -0.000027469499400467612,
                -0.0023541883565485477,
                -0.00007033260044408962,
                -0.037442442029714584,
                -0.007148285396397114,
                -1.6104146993711765e-7,
                -0.021412359550595284
            ],
            [
                -0.09181956201791763,
                -0.012020565569400787,
                -0.06704116612672806,
                -0.10788337141275406,
                -0.053139835596084595,
                -0.1592671126127243,
                -0.031081246212124825,
                -0.03358668088912964,
                -0.013604630716145039,
                -0.004204889759421349,
                0,
                -0.0014278332237154245,
                -0.0000018137837969334214,
                -4.359686727184453e-7,
                -0.00009222983499057591,
                -0.00163858977612108
            ],
            [
                -0.0060336352325975895,
                -0.028127986937761307,
                -0.008535115048289299,
                -4.3318871689734806e-7,
                1.5275379419326782,
                -0.12350371479988098,
                -0.00022579285723622888,
                -0.01284174807369709,
                -0.0890231803059578,
                -0.00038523832336068153,
                -0.002091313013806939,
                -0.024310173466801643,
                -0.01751108653843403,
                0,
                0,
                -0.10081373900175095
            ],
            [
                0.09025754034519196,
                -0.03362687677145004,
                0.014071978628635406,
                -0.022167149931192398,
                -0.11618383973836899,
                0.12427724152803421,
                -0.06699784845113754,
                0.10051169991493225,
                0.13676056265830994,
                0.09122580289840698,
                0.15665273368358612,
                -0.024489333853125572,
                -0.04267057776451111,
                -0.03999451920390129,
                -0.12161710113286972,
                -0.1062583178281784
            ],
            [
                -0.00760713079944253,
                0.27794384956359863,
                0.35764098167419434,
                -0.04117019474506378,
                0.5610191226005554,
                0.188322976231575,
                0.17344549298286438,
                0.01918928138911724,
                -0.04962180554866791,
                0.2605534493923187,
                0.07940603792667389,
                0.02248566970229149,
                0.023273799568414688,
                -0.018385913223028183,
                0.0004393255803734064,
                -0.10310695320367813
            ],
            [
                -0.08324532955884933,
                0.1301712840795517,
                0.16753602027893066,
                0.30346375703811646,
                0.7468899488449097,
                0.31200817227363586,
                0.13082358241081238,
                0.2385139763355255,
                0.09306509792804718,
                0.1853737235069275,
                0.18187814950942993,
                -0.11576095223426819,
                -0.05724542215466499,
                0.16601580381393433,
                -0.025658663362264633,
                0.028617871925234795
            ],
            [
                0.08020512014627457,
                0.03117375820875168,
                0.07175169885158539,
                0.07373394817113876,
                0.16404807567596436,
                0.15179947018623352,
                -0.054289355874061584,
                -0.09094976633787155,
                -0.019213316962122917,
                0.14032083749771118,
                -0.05070780590176582,
                0.43573516607284546,
                0.09239603579044342,
                -0.040239397436380386,
                0.14684586226940155,
                0.3847309947013855
            ],
            [
                -0.0019203370902687311,
                0.17682920396327972,
                0.5509952306747437,
                0.3734114468097687,
                0.062252119183540344,
                0.04588914290070534,
                -0.019373899325728416,
                0.01873132772743702,
                -0.013240180909633636,
                -0.06777025759220123,
                0.13426342606544495,
                0.2942161560058594,
                0.3823445737361908,
                0.09642864018678665,
                0.39456766843795776,
                0.1860370934009552
            ],
            [
                -0.06529748439788818,
                0.33210742473602295,
                0.599610447883606,
                0.6747077107429504,
                0.46180498600006104,
                0.07720739394426346,
                0.07711446285247803,
                -0.09733341634273529,
                0.013524821028113365,
                0.08150516450405121,
                -0.0996418371796608,
                0.5764073729515076,
                0.35362544655799866,
                0.37102988362312317,
                0.15050408244132996,
                -0.12302924692630768
            ],
            [
                0.06756050884723663,
                0.17751196026802063,
                -0.06501194834709167,
                0.09039412438869476,
                0.0989193543791771,
                0.1232123151421547,
                0.4213971793651581,
                0.12732820212841034,
                0.1413242071866989,
                0.16920877993106842,
                0.217786967754364,
                0.0002664118364918977,
                0.3356134593486786,
                -0.03619007393717766,
                0.021230025216937065,
                0.04440460354089737
            ],
            [
                0.05298963189125061,
                0.05629458278417587,
                -0.038582488894462585,
                0.08502029627561569,
                0.22749900817871094,
                0.0959174782037735,
                -0.12311272323131561,
                -0.06269600242376328,
                -0.05720806121826172,
                -0.05348939448595047,
                0.09431011229753494,
                0.017636390402913094,
                0.04424401745200157,
                -0.016851408407092094,
                0.40285158157348633,
                -0.0747593343257904
            ]
        ],
        [
            [
                0,
                -0.000006766829301341204,
                -0.000207564837182872,
                -0.0079837990924716,
                -0.00033120231819339097,
                -0.0000010055234724859474,
                -0.000002349095666431822,
                -0.00001526270352769643,
                -0.000001686542532297608,
                -0.0010879505425691605,
                -0.000622577324975282,
                -0.0012990889372304082,
                -0.0008373762248083949,
                -0.000006509090326289879,
                -0.12655676901340485,
                0
            ],
            [
                -0.0023303087800741196,
                -0.00025622546672821045,
                -0.0045013283379375935,
                -0.012288604862987995,
                -0.0000057418214964855,
                -0.0011135307140648365,
                -0.000005228359896136681,
                -0.013714980334043503,
                -0.14931732416152954,
                -0.000018810831534210593,
                -0.0006637448095716536,
                -0.006632152013480663,
                -0.00034221081295982003,
                -0.0000399768068746198,
                -0.010797335766255856,
                -0.001180359162390232
            ],
            [
                -0.0001508465938968584,
                -0.020987918600440025,
                -0.003998675849288702,
                -0.0017238835571333766,
                -0.0024537667632102966,
                -0.019128838554024696,
                -0.002397551666945219,
                -0.0024557006545364857,
                -0.0001241332065546885,
                -0.00007777853170409799,
                -0.000022319552954286337,
                -0.0008807296981103718,
                -0.021912172436714172,
                -0.00005597632480203174,
                -0.004768635146319866,
                -0.00045704407966695726
            ],
            [
                0,
                -0.00001685935421846807,
                -0.00035909461439587176,
                -0.001943115028552711,
                -0.004161166027188301,
                -0.16334675252437592,
                -0.012677351012825966,
                -0.007119789253920317,
                -0.0031343670561909676,
                -0.07060929387807846,
                -0.0000250859866355313,
                -0.05988408625125885,
                -0.015219484455883503,
                -0.0046598524786531925,
                0,
                -0.000006005274372000713
            ],
            [
                -0.0000010029525583377108,
                -0.0001193093994515948,
                -0.005161501467227936,
                -0.0002635009295772761,
                -0.014619228430092335,
                -0.00031542647047899663,
                -0.000006761205440852791,
                -0.00825666543096304,
                -0.0009127400699071586,
                -0.032217901200056076,
                -0.00003139247928629629,
                -0.00031829543877393007,
                -0.0027776756323873997,
                -0.0008718782919459045,
                -0.00048756765318103135,
                -0.0000011357406037859619
            ],
            [
                -0.0001413394493283704,
                -0.00008530836930731311,
                -0.0012928334763273597,
                0,
                -0.0000016747212612244766,
                -0.00003998161992058158,
                -0.000018318471120437607,
                -0.000006251967988646356,
                -0.000021224364900263026,
                -1.5634792305263545e-7,
                -0.000003146408516840893,
                -0.002868112176656723,
                -0.15727365016937256,
                -0.0000922347535379231,
                -0.0040233563631772995,
                -4.372019475340494e-7
            ],
            [
                -0.000006638384093093919,
                -0.00019889774557668716,
                -0.00003009142710652668,
                -0.00003541004116414115,
                -0.0000018098744476446882,
                0,
                -0.00002448602390359156,
                -0.0002698381431400776,
                -0.0010826855432242155,
                -0.03387276828289032,
                -0.02876640111207962,
                -0.018953491002321243,
                -0.005306133069097996,
                -0.009769263677299023,
                -0.000020988542019040324,
                -2.9030366022197995e-7
            ],
            [
                -0.000007784989065839909,
                -0.0000016836216900628642,
                -0.0013745678588747978,
                -0.16877537965774536,
                -0.003231006907299161,
                -1.6086849541352422e-7,
                0,
                0,
                -0.005477654282003641,
                -0.008095545694231987,
                -0.000019528115444700234,
                -0.0001474041200708598,
                -0.00001684571361693088,
                -0.1400531381368637,
                0.6651750802993774,
                -0.000012787286323145963
            ],
            [
                -0.1681251972913742,
                -0.10157154500484467,
                -0.10794597864151001,
                -0.16163186728954315,
                -0.15761904418468475,
                -0.16287222504615784,
                -0.16994473338127136,
                -0.1677504926919937,
                -0.15270091593265533,
                -0.14552372694015503,
                -0.16495797038078308,
                -0.1637144684791565,
                -0.16866320371627808,
                -0.14309169352054596,
                -0.1350761502981186,
                -0.15754982829093933
            ],
            [
                -0.15903042256832123,
                -0.16943985223770142,
                -0.14909647405147552,
                -0.1689874231815338,
                -0.16573484241962433,
                -0.1587650626897812,
                -0.1688738763332367,
                -0.16743668913841248,
                -0.169998899102211,
                -0.1666121929883957,
                -0.14513327181339264,
                -0.16635288298130035,
                -0.16000892221927643,
                -0.16690915822982788,
                -0.15281926095485687,
                -0.16944123804569244
            ],
            [
                -0.15552815794944763,
                -0.16879746317863464,
                -0.1687367558479309,
                0.011512482538819313,
                -0.15848441421985626,
                -0.1663709133863449,
                -0.1699143946170807,
                -0.1671016365289688,
                -0.16910699009895325,
                -0.1631198525428772,
                -0.1695680469274521,
                -0.16973230242729187,
                -0.1655885875225067,
                -0.16768445074558258,
                -0.16992318630218506,
                -0.16059452295303345
            ],
            [
                -0.14003795385360718,
                -0.1696845293045044,
                -0.1686364859342575,
                -0.12263074517250061,
                -0.14357297122478485,
                -0.151558056473732,
                -0.16581058502197266,
                -0.16981814801692963,
                -0.16790653765201569,
                -0.1653144657611847,
                -0.16930416226387024,
                -0.15799075365066528,
                -0.16984401643276215,
                -0.16935932636260986,
                -0.15212289988994598,
                -0.1329960972070694
            ],
            [
                -0.1478395164012909,
                -0.16729752719402313,
                -0.17001743614673615,
                -0.14913806319236755,
                -0.15934962034225464,
                -0.1424660086631775,
                -0.16467715799808502,
                -0.1688842624425888,
                -0.16623002290725708,
                -0.16719403862953186,
                -0.1699099838733673,
                -0.16173651814460754,
                -0.16987662017345428,
                -0.16985447704792023,
                -0.08547655493021011,
                -0.1581062227487564
            ],
            [
                -0.16981562972068787,
                -0.1698065549135208,
                -0.13349731266498566,
                -0.16629798710346222,
                -0.16949619352817535,
                -0.16115254163742065,
                -0.16620038449764252,
                -0.15090659260749817,
                -0.168907031416893,
                -0.17003999650478363,
                -0.13927686214447021,
                -0.11766289919614792,
                -0.14673519134521484,
                -0.1642703115940094,
                -0.170039102435112,
                -0.16836494207382202
            ],
            [
                -0.15652945637702942,
                -0.17001427710056305,
                -0.11011157929897308,
                -0.15972545742988586,
                -0.09153071790933609,
                -0.1700267642736435,
                -0.16918040812015533,
                -0.16681571304798126,
                -0.16605792939662933,
                -0.16787658631801605,
                -0.1393471509218216,
                0.030801672488451004,
                -0.16989612579345703,
                -0.13695307075977325,
                -0.15823017060756683,
                -0.15223486721515656
            ],
            [
                -0.13751117885112762,
                -0.13217201828956604,
                -0.1687794178724289,
                -0.14961786568164825,
                -0.1698329597711563,
                -0.16739001870155334,
                -0.16231635212898254,
                -0.149554044008255,
                -0.153901606798172,
                -0.13975371420383453,
                -0.15250760316848755,
                -0.16583251953125,
                -0.1415850818157196,
                -0.16340817511081696,
                -0.15964168310165405,
                -0.1600581407546997
            ]
        ],
        [
            [
                -0.006140977144241333,
                -0.003721068613231182,
                -0.01482628844678402,
                -0.002021520398557186,
                -0.0014029856538400054,
                -0.033876873552799225,
                -0.01588054932653904,
                -0.00828892644494772,
                -0.16201545298099518,
                -0.01832827925682068,
                -0.11582866311073303,
                -0.000045343436795519665,
                -0.08131592720746994,
                -0.020530348643660545,
                -0.010291794314980507,
                -0.1629551500082016
            ],
            [
                -0.12248806655406952,
                -0.000007399230526061729,
                -0.010542449541389942,
                -0.048812925815582275,
                -0.010024307295680046,
                0.46823132038116455,
                -0.02076144516468048,
                -0.006398354656994343,
                -0.012078034691512585,
                -0.004847949370741844,
                -0.06596408039331436,
                -0.1514953225851059,
                -0.029412969946861267,
                -0.11121799051761627,
                -0.02383943274617195,
                -0.09330151975154877
            ],
            [
                -0.027238542214035988,
                -0.008015098050236702,
                -0.14675627648830414,
                -0.11086703836917877,
                -0.020102156326174736,
                -0.0012760929530486465,
                -0.024981951341032982,
                -0.16527163982391357,
                -0.1110210120677948,
                -0.08566475659608841,
                -0.0288388654589653,
                -0.0011588070774450898,
                -0.0002863967092707753,
                -0.0012199229095131159,
                -0.005380443297326565,
                -0.14956647157669067
            ],
            [
                -0.13110525906085968,
                -0.041003819555044174,
                -0.13059549033641815,
                -0.0005905393627472222,
                -0.07100991904735565,
                -0.011428906582295895,
                -0.11455123871564865,
                -0.13674725592136383,
                -0.064982570707798,
                -0.059676479548215866,
                -0.04303870350122452,
                -0.07349979132413864,
                -0.002252964535728097,
                -0.00005366202094592154,
                -0.014647968113422394,
                -0.0034937234595417976
            ],
            [
                -0.0010306139010936022,
                -0.0002943504077848047,
                -0.11892279237508774,
                -0.04502902179956436,
                -0.022641589865088463,
                -0.0033554919064044952,
                -0.01268859300762415,
                -0.1582583785057068,
                -0.04819226637482643,
                -0.0026657741982489824,
                -0.07456328719854355,
                -0.021845586597919464,
                -0.03528245911002159,
                -0.05486375465989113,
                -0.00332113029435277,
                -0.12960536777973175
            ],
            [
                -0.06640361994504929,
                -0.027367381379008293,
                -0.026138028129935265,
                -0.0375472791492939,
                -0.04101841524243355,
                -0.12174167484045029,
                -0.16365191340446472,
                -0.15100041031837463,
                -0.038905076682567596,
                -0.004382523708045483,
                -0.0003545385552570224,
                -0.00007145880226744339,
                -0.1647855043411255,
                -0.032094135880470276,
                -0.00011195093247806653,
                -0.009773045778274536
            ],
            [
                -0.0017997190589085221,
                -0.008079489693045616,
                -0.08256234228610992,
                -0.1183774545788765,
                -0.013996638357639313,
                -0.10645347088575363,
                -0.024053353816270828,
                -0.10890839248895645,
                -0.16916197538375854,
                -0.12297957390546799,
                -0.00013937050243839622,
                -0.0002634065749589354,
                -0.0010527737904340029,
                -0.000014632329111918807,
                -0.15900948643684387,
                -0.07435530424118042
            ],
            [
                -0.12789754569530487,
                -0.08812351524829865,
                -0.1303197145462036,
                -0.005333055276423693,
                0.00324972509406507,
                -0.16820743680000305,
                -0.03671081364154816,
                -0.03294574096798897,
                -0.026296259835362434,
                -0.145792156457901,
                -0.15104259550571442,
                -0.1066335067152977,
                -0.0948474109172821,
                -0.020925700664520264,
                -0.14893370866775513,
                -0.03128775954246521
            ],
            [
                -0.13586460053920746,
                -0.09621991217136383,
                -0.01942044496536255,
                -0.10701121389865875,
                -0.16477003693580627,
                -0.16775931417942047,
                -0.16065531969070435,
                -0.15702283382415771,
                -0.11403442919254303,
                -0.024074995890259743,
                -0.16277562081813812,
                -0.1648939847946167,
                -0.16849614679813385,
                -0.16991916298866272,
                -0.16360242664813995,
                -0.08995833992958069
            ],
            [
                -0.1303364485502243,
                -0.15233860909938812,
                -0.16949650645256042,
                -0.16992917656898499,
                -0.16299627721309662,
                -0.17004074156284332,
                -0.15164518356323242,
                -0.14075154066085815,
                -0.1645365208387375,
                -0.1533275842666626,
                -0.1668822467327118,
                -0.17003843188285828,
                -0.0912720337510109,
                -0.13986609876155853,
                -0.16898714005947113,
                -0.1687408983707428
            ],
            [
                -0.14423567056655884,
                -0.16589698195457458,
                -0.1697627604007721,
                -0.16159285604953766,
                -0.1157151609659195,
                -0.1619463711977005,
                -0.1169574186205864,
                -0.16913361847400665,
                -0.1538965106010437,
                -0.1599244624376297,
                -0.15955284237861633,
                -0.16624639928340912,
                -0.15663224458694458,
                -0.16982434689998627,
                -0.16640745103359222,
                -0.16916032135486603
            ],
            [
                -0.13112643361091614,
                -0.12700575590133667,
                -0.15967193245887756,
                -0.10778281837701797,
                -0.12453646212816238,
                -0.12434624135494232,
                -0.1547263264656067,
                -0.16989533603191376,
                -0.13176044821739197,
                -0.15326300263404846,
                -0.16978077590465546,
                -0.1667485237121582,
                -0.1658330112695694,
                -0.16772325336933136,
                -0.16121940314769745,
                0.06176523491740227
            ],
            [
                -0.1134403869509697,
                -0.10478948056697845,
                -0.16535495221614838,
                -0.16900432109832764,
                -0.14591585099697113,
                -0.11019247025251389,
                -0.12820394337177277,
                -0.15833544731140137,
                -0.16975317895412445,
                -0.16569316387176514,
                -0.16706447303295135,
                -0.16715070605278015,
                -0.16749617457389832,
                -0.1561911255121231,
                0.04794050380587578,
                0.08186575770378113
            ],
            [
                -0.1590353101491928,
                -0.14326077699661255,
                -0.031991902738809586,
                -0.16820451617240906,
                -0.16068612039089203,
                -0.1663365513086319,
                -0.10906260460615158,
                -0.1038365513086319,
                -0.1615510880947113,
                -0.16677244007587433,
                -0.12473347038030624,
                0.14580921828746796,
                -0.09308820217847824,
                -0.15934760868549347,
                -0.16796191036701202,
                -0.08949998766183853
            ],
            [
                -0.1304539442062378,
                -0.13958410918712616,
                -0.09607570618391037,
                -0.1520795226097107,
                -0.14159953594207764,
                -0.16601881384849548,
                -0.16467536985874176,
                -0.14454160630702972,
                -0.0986204519867897,
                -0.16917681694030762,
                -0.1699308305978775,
                -0.04379359260201454,
                -0.1668330281972885,
                -0.16255277395248413,
                -0.16785107553005219,
                -0.09446625411510468
            ],
            [
                -0.15079708397388458,
                -0.13254401087760925,
                -0.14985784888267517,
                -0.1653255969285965,
                -0.16223300993442535,
                -0.16733717918395996,
                -0.16908788681030273,
                -0.15434707701206207,
                -0.13695946335792542,
                -0.13936644792556763,
                -0.15811963379383087,
                -0.15425390005111694,
                -0.15010516345500946,
                -0.16599009931087494,
                -0.16740424931049347,
                -0.0784824937582016
            ]
        ],
        [
            [
                1.9332771301269531,
                4.3472089767456055,
                5.048187732696533,
                6.267887115478516,
                7.297171115875244,
                2.761730909347534,
                2.8329131603240967,
                3.577366828918457,
                3.284221649169922,
                2.849459648132324,
                2.7510900497436523,
                3.7441294193267822,
                3.4987449645996094,
                2.706970453262329,
                5.406345367431641,
                5.173025608062744
            ],
            [
                0.9125346541404724,
                3.103494167327881,
                2.996192216873169,
                2.561122417449951,
                3.773235559463501,
                3.559112548828125,
                3.320497989654541,
                7.7677507400512695,
                6.202178001403809,
                3.2745308876037598,
                2.128927230834961,
                1.6073178052902222,
                3.3202965259552,
                1.8571151494979858,
                2.1553971767425537,
                6.324185848236084
            ],
            [
                3.927147150039673,
                4.017453193664551,
                2.7476325035095215,
                2.026411294937134,
                0.7182657122612,
                5.3471150398254395,
                4.763416767120361,
                2.0978386402130127,
                3.8052420616149902,
                2.2698373794555664,
                3.0491840839385986,
                2.399116039276123,
                4.278374195098877,
                4.012263298034668,
                8.68759536743164,
                7.152642726898193
            ],
            [
                0.8578342199325562,
                2.46012544631958,
                1.910683035850525,
                3.7458598613739014,
                2.981020927429199,
                7.2388176918029785,
                3.970585346221924,
                3.149751901626587,
                4.777085781097412,
                2.468874931335449,
                1.3273178339004517,
                3.7866437435150146,
                2.425727128982544,
                4.142956256866455,
                5.3972697257995605,
                4.560446262359619
            ],
            [
                2.7363226413726807,
                3.698620557785034,
                3.6337649822235107,
                2.368354558944702,
                4.432240009307861,
                4.891472816467285,
                6.457771301269531,
                1.9451379776000977,
                3.3167407512664795,
                1.686599850654602,
                5.250062942504883,
                3.317889451980591,
                4.972620487213135,
                3.8263449668884277,
                4.501672267913818,
                2.2475082874298096
            ],
            [
                2.6437690258026123,
                2.9877634048461914,
                3.6129848957061768,
                1.912870168685913,
                3.939702272415161,
                2.440168857574463,
                3.7785489559173584,
                1.5903174877166748,
                3.4696922302246094,
                3.7901062965393066,
                6.036396503448486,
                7.56751012802124,
                4.672608852386475,
                1.6647008657455444,
                5.855612754821777,
                4.514941692352295
            ],
            [
                1.6018754243850708,
                3.5627660751342773,
                3.8265228271484375,
                4.4539031982421875,
                3.532684564590454,
                4.462933540344238,
                2.7369048595428467,
                2.4839823246002197,
                2.167337417602539,
                3.6512105464935303,
                4.8853912353515625,
                5.1152753829956055,
                3.839179754257202,
                8.147171020507812,
                6.598835468292236,
                2.9715850353240967
            ],
            [
                3.376958131790161,
                3.7074525356292725,
                6.481692314147949,
                7.174960136413574,
                4.334653377532959,
                2.2566773891448975,
                2.922008752822876,
                2.000046730041504,
                4.82835578918457,
                6.588113307952881,
                1.2630537748336792,
                2.4687340259552,
                3.8481764793395996,
                6.808362007141113,
                9.126912117004395,
                5.734747886657715
            ],
            [
                -0.16995342075824738,
                0.3660026490688324,
                0.07478811591863632,
                0.019709276035428047,
                -0.05088170990347862,
                -0.1554875373840332,
                -0.03340958058834076,
                -0.0726044625043869,
                0.046657711267471313,
                -0.004768596030771732,
                -0.1643516719341278,
                -0.08236528933048248,
                -0.13622929155826569,
                -0.0719119980931282,
                -0.08260723203420639,
                0.135917067527771
            ],
            [
                -0.07157573103904724,
                0.05633227154612541,
                -0.1536305993795395,
                -0.1296188086271286,
                -0.16978107392787933,
                0.06380680948495865,
                -0.06539177894592285,
                -0.12661373615264893,
                -0.07283399999141693,
                -0.1392800360918045,
                0.010280008427798748,
                -0.028405889868736267,
                -0.15925095975399017,
                0.13131849467754364,
                -0.020056260749697685,
                0.001331913168542087
            ],
            [
                -0.167524516582489,
                -0.01961364783346653,
                -0.05739811807870865,
                -0.13795101642608643,
                0.101356141269207,
                0.02881893515586853,
                -0.09722130000591278,
                -0.11151178926229477,
                -0.16988100111484528,
                0.02390538528561592,
                -0.10492895543575287,
                -0.16986604034900665,
                0.14150220155715942,
                -0.042265694588422775,
                0.15058381855487823,
                -0.07726295292377472
            ],
            [
                -0.06294937431812286,
                0.02434016950428486,
                -0.06504570692777634,
                -0.13528314232826233,
                -0.00875695701688528,
                -0.13780748844146729,
                -0.13316592574119568,
                -0.14926449954509735,
                -0.16324065625667572,
                -0.11447558552026749,
                -0.08027566224336624,
                -0.11969240009784698,
                0.07552133500576019,
                -0.14263708889484406,
                0.33685174584388733,
                0.043437231332063675
            ],
            [
                -0.14712069928646088,
                -0.12805619835853577,
                -0.020547639578580856,
                -0.07201835513114929,
                -0.13795797526836395,
                -0.07930081337690353,
                -0.12499485909938812,
                -0.14959047734737396,
                -0.16539444029331207,
                -0.11330123990774155,
                -0.10122065991163254,
                -0.06670130044221878,
                -0.14559441804885864,
                -0.04295670613646507,
                -0.007921036332845688,
                0.18030260503292084
            ],
            [
                -0.09411833435297012,
                -0.032096702605485916,
                -0.05205091834068298,
                -0.12238341569900513,
                0.06220036745071411,
                0.012415243312716484,
                -0.16958950459957123,
                -0.1279003918170929,
                -0.054463911801576614,
                -0.15588867664337158,
                -0.13388274610042572,
                -0.05903863534331322,
                -0.12889721989631653,
                -0.11849591135978699,
                -0.08571875840425491,
                -0.07030202448368073
            ],
            [
                -0.13794048130512238,
                -0.08479196578264236,
                -0.08296648412942886,
                -0.12263593822717667,
                0.036840151995420456,
                -0.16931764781475067,
                -0.01744821108877659,
                -0.1527254283428192,
                -0.0015631173737347126,
                -0.16888852417469025,
                -0.150719553232193,
                0.15455850958824158,
                -0.04524775221943855,
                -0.13456425070762634,
                0.057311270385980606,
                -0.04135517030954361
            ],
            [
                -0.15879525244235992,
                -0.06022889167070389,
                -0.07440957427024841,
                -0.09324277192354202,
                0.08420330286026001,
                -0.03372371196746826,
                0.34438803791999817,
                -0.08696462213993073,
                -0.05006326362490654,
                -0.10908472537994385,
                -0.12724757194519043,
                0.10474154353141785,
                -0.11964382231235504,
                0.4444807171821594,
                -0.14276178181171417,
                0.15839342772960663
            ]
        ],
        [
            [
                1.582633376121521,
                1.6491386890411377,
                0.22171904146671295,
                -0.11127086728811264,
                0.13204212486743927,
                1.4919968843460083,
                -0.16933070123195648,
                0.14727209508419037,
                1.9880093336105347,
                -0.09981744736433029,
                0.6321438550949097,
                -0.16408850252628326,
                0.5596873164176941,
                0.7494677901268005,
                1.8847815990447998,
                0.7712059617042542
            ],
            [
                1.7890361547470093,
                0.15935587882995605,
                -0.11428243666887283,
                0.6358676552772522,
                -0.13379791378974915,
                -0.13993686437606812,
                0.13513663411140442,
                -0.10681691765785217,
                -0.15195782482624054,
                -0.01827826164662838,
                -0.16896474361419678,
                0.3197634816169739,
                -0.16993483901023865,
                0.16741763055324554,
                -0.07192495465278625,
                -0.00460608908906579
            ],
            [
                0.38396555185317993,
                -0.0018848218023777008,
                0.48402586579322815,
                -0.16977623105049133,
                0.5706353783607483,
                -0.16304850578308105,
                1.0358200073242188,
                0.4082256853580475,
                -0.07750768959522247,
                -0.1141631230711937,
                -0.12683939933776855,
                -0.16991961002349854,
                -0.12521935999393463,
                1.7274445295333862,
                -0.03500394895672798,
                -0.15831783413887024
            ],
            [
                3.117799997329712,
                -0.1117134541273117,
                -0.16271038353443146,
                0.06647966802120209,
                0.8707579374313354,
                -0.1694425344467163,
                0.13700538873672485,
                0.022783393040299416,
                -0.16930916905403137,
                0.9948055148124695,
                -0.11964316666126251,
                -0.16978852450847626,
                -0.08953671902418137,
                1.3569257259368896,
                -0.08097996562719345,
                -0.16562078893184662
            ],
            [
                2.1748571395874023,
                0.5028133988380432,
                -0.14012685418128967,
                0.17533764243125916,
                1.8752615451812744,
                0.07558375597000122,
                -0.0052650198340415955,
                1.4581636190414429,
                -0.15659308433532715,
                -0.11976676434278488,
                -0.15143270790576935,
                0.24479934573173523,
                -0.03984237462282181,
                -0.10497257113456726,
                -0.15574094653129578,
                0.03761047124862671
            ],
            [
                0.6590595245361328,
                -0.1666894555091858,
                0.6897581219673157,
                -0.12723807990550995,
                -0.10639715939760208,
                1.0970124006271362,
                -0.09646618366241455,
                -0.12890085577964783,
                -0.16148854792118073,
                -0.1401311755180359,
                -0.12993280589580536,
                0.04131987690925598,
                -0.10675433278083801,
                0.6861257553100586,
                -0.10515767335891724,
                -0.15323123335838318
            ],
            [
                0.39235541224479675,
                -0.1699158102273941,
                -0.16760896146297455,
                1.1487584114074707,
                -0.16175036132335663,
                1.0069657564163208,
                0.6135030388832092,
                -0.16887415945529938,
                1.7029980421066284,
                0.5112271308898926,
                -0.07863384485244751,
                -0.13156652450561523,
                -0.1700219213962555,
                0.783780038356781,
                1.417423963546753,
                2.205150842666626
            ],
            [
                0.4365493655204773,
                1.0626400709152222,
                3.1218814849853516,
                0.6003236770629883,
                -0.1500544548034668,
                0.3357559144496918,
                1.7471288442611694,
                -0.05914273485541344,
                1.7227511405944824,
                -0.06529272347688675,
                0.6442162990570068,
                -0.08997619897127151,
                -0.14710916578769684,
                0.6797652840614319,
                -0.14648953080177307,
                -0.1644747406244278
            ],
            [
                -0.14213524758815765,
                -0.15662485361099243,
                -0.16204476356506348,
                -0.06563641875982285,
                -0.12449432909488678,
                -0.06669262796640396,
                -0.1512836068868637,
                -0.08151014894247055,
                -0.16923706233501434,
                -0.030604371801018715,
                -0.12024393677711487,
                -0.13706326484680176,
                0.0016573760658502579,
                -0.08525969833135605,
                -0.14632390439510345,
                0.0021651098504662514
            ],
            [
                -0.05276015028357506,
                -0.16382324695587158,
                -0.10530535876750946,
                -0.05805648863315582,
                -0.0810096263885498,
                -0.14472980797290802,
                -0.05466986075043678,
                -0.11961028724908829,
                -0.1619914323091507,
                -0.029304606840014458,
                -0.06855389475822449,
                -0.049397457391023636,
                0.013760117813944817,
                -0.043573614209890366,
                -0.02734353207051754,
                0.07558342814445496
            ],
            [
                -0.08836709707975388,
                -0.1387150138616562,
                -0.1373683363199234,
                -0.167343407869339,
                -0.14177599549293518,
                -0.14693160355091095,
                -0.0740809366106987,
                -0.030967317521572113,
                -0.05680849775671959,
                -0.08420021086931229,
                -0.14766710996627808,
                0.037478458136320114,
                -0.05194694548845291,
                -0.028178779408335686,
                -0.11469296365976334,
                0.06123796105384827
            ],
            [
                0.05758313089609146,
                -0.07465743273496628,
                -0.13146072626113892,
                -0.1679922342300415,
                -0.10583268851041794,
                -0.09343533217906952,
                0.003614366753026843,
                -0.11151911318302155,
                -0.06353610754013062,
                -0.09007441997528076,
                -0.09780650585889816,
                -0.0006336408550851047,
                -0.04691535606980324,
                -0.06186067685484886,
                -0.03395354375243187,
                -0.09388401359319687
            ],
            [
                -0.026447143405675888,
                0.0596720427274704,
                -0.11168887466192245,
                -0.15076449513435364,
                -0.15811727941036224,
                -0.11246560513973236,
                -0.07442138344049454,
                -0.013272415846586227,
                -0.07909705489873886,
                -0.12974780797958374,
                -0.13462123274803162,
                -0.052627433091402054,
                -0.10210353881120682,
                -0.02675231173634529,
                0.1663486361503601,
                -0.09572258591651917
            ],
            [
                -0.14762181043624878,
                -0.13363613188266754,
                -0.15332286059856415,
                -0.13538183271884918,
                -0.15850548446178436,
                -0.10254886001348495,
                -0.049297891557216644,
                -0.05282552167773247,
                -0.036301661282777786,
                -0.12077125906944275,
                -0.15078601241111755,
                -0.16666632890701294,
                -0.1602013111114502,
                -0.15309670567512512,
                -0.14875508844852448,
                0.06440375745296478
            ],
            [
                -0.02873518504202366,
                -0.07580126076936722,
                -0.112090565264225,
                -0.10127171128988266,
                -0.1698950082063675,
                -0.07139291614294052,
                -0.14420738816261292,
                0.09766635298728943,
                -0.07438512146472931,
                -0.054920803755521774,
                -0.16574662923812866,
                -0.16266165673732758,
                -0.12545530498027802,
                -0.1551448404788971,
                -0.12980884313583374,
                -0.13534310460090637
            ],
            [
                0.08095703274011612,
                0.08275303989648819,
                -0.04917573183774948,
                -0.13147324323654175,
                -0.08099431544542313,
                -0.07289975136518478,
                -0.13804909586906433,
                -0.04140268266201019,
                -0.011862557381391525,
                0.06857005506753922,
                -0.12192712724208832,
                -0.0559130534529686,
                0.018544357270002365,
                -0.08087285608053207,
                -0.12063422799110413,
                -0.16861438751220703
            ]
        ],
        [
            [
                -0.021741675212979317,
                -0.030826570466160774,
                -0.13133974373340607,
                -0.0025311042554676533,
                -0.053969334810972214,
                -0.0042751808650791645,
                -0.06497605890035629,
                -0.014518095180392265,
                -0.1697363555431366,
                -0.10099054127931595,
                -0.07970122992992401,
                -0.024874644353985786,
                -0.02498471923172474,
                -0.01861042156815529,
                -0.11304725706577301,
                0.7695111036300659
            ],
            [
                -0.15768881142139435,
                -0.00003905666380887851,
                -0.05881412327289581,
                -0.17002850770950317,
                -0.09866266697645187,
                1.375050663948059,
                -0.10662127286195755,
                -0.16304530203342438,
                -0.07622940838336945,
                -0.09548591822385788,
                -0.07419741898775101,
                -0.032696858048439026,
                -0.15500248968601227,
                -0.04281197115778923,
                1.3484904766082764,
                -0.1335296481847763
            ],
            [
                -0.15292692184448242,
                -0.0005145638715475798,
                -0.07617799192667007,
                -0.05704561993479729,
                -0.10058049857616425,
                -0.0014827181585133076,
                0.8028318881988525,
                0.805645227432251,
                -0.022969573736190796,
                -0.003331833751872182,
                -0.11584599316120148,
                -0.013656658120453358,
                -0.0011789861600846052,
                -0.15994490683078766,
                0,
                0.832430899143219
            ],
            [
                -0.0022325573954731226,
                -0.0677751824259758,
                -0.06726296246051788,
                -0.0008404066320508718,
                -0.012808484956622124,
                -0.000016123703971970826,
                -0.11288560181856155,
                0.7757592797279358,
                -0.006606696639209986,
                -0.10037992894649506,
                -0.17001865804195404,
                -0.06881845742464066,
                -0.02453731559216976,
                -0.04811135306954384,
                -0.04774856939911842,
                -0.020651355385780334
            ],
            [
                -0.15560416877269745,
                -0.036096684634685516,
                -0.1280083805322647,
                -0.13792824745178223,
                -0.06523237377405167,
                -0.10760880261659622,
                -0.01878221705555916,
                -0.13216358423233032,
                -0.054998788982629776,
                -0.00129156862385571,
                -0.10824020951986313,
                -0.13004285097122192,
                -0.01789766550064087,
                -0.11830473691225052,
                -0.000732248998247087,
                -0.10417263954877853
            ],
            [
                -0.06276538968086243,
                -0.01995045132935047,
                -0.019503122195601463,
                -0.14651502668857574,
                -0.09324769675731659,
                -0.10231752693653107,
                -0.14821545779705048,
                -0.06916743516921997,
                -0.011578425765037537,
                -0.05097539722919464,
                -0.04911128804087639,
                -0.041089095175266266,
                -0.10227829962968826,
                -0.15610919892787933,
                -0.00564091419801116,
                -0.16804490983486176
            ],
            [
                -0.08323244005441666,
                -0.026324082165956497,
                -0.06342022120952606,
                -0.11619680374860764,
                -0.12801745533943176,
                -0.11182566732168198,
                -0.032699696719646454,
                -0.1207098439335823,
                0.2742786407470703,
                -0.07891396433115005,
                -0.0014194559771567583,
                -0.015703121200203896,
                -0.0034938002936542034,
                -0.05232016369700432,
                1.4466750621795654,
                -0.10074140876531601
            ],
            [
                -0.07439179718494415,
                -0.1628039926290512,
                0.821064293384552,
                -0.01213926076889038,
                0.3192097842693329,
                -0.09039749205112457,
                -0.10561258345842361,
                -0.16241270303726196,
                -0.09730136394500732,
                0.36702725291252136,
                -0.042574070394039154,
                -0.09082940965890884,
                -0.15302221477031708,
                -0.07450084388256073,
                -0.09978146106004715,
                -0.03409605845808983
            ],
            [
                -0.16779235005378723,
                -0.12373853474855423,
                -0.16585439443588257,
                -0.152241051197052,
                -0.16538897156715393,
                -0.125670924782753,
                -0.16970542073249817,
                -0.12284605205059052,
                -0.1621352732181549,
                -0.16427284479141235,
                -0.16967715322971344,
                -0.16995306313037872,
                -0.16913941502571106,
                -0.15937839448451996,
                -0.16619525849819183,
                -0.1403832733631134
            ],
            [
                -0.16924425959587097,
                -0.16473664343357086,
                -0.16875140368938446,
                -0.16061772406101227,
                -0.16985714435577393,
                -0.16853637993335724,
                -0.12176726758480072,
                -0.1619781106710434,
                -0.16969653964042664,
                -0.07980109006166458,
                -0.15425217151641846,
                -0.16873431205749512,
                -0.16256853938102722,
                -0.16486135125160217,
                -0.1615479290485382,
                -0.15985339879989624
            ],
            [
                -0.16962964832782745,
                -0.16905008256435394,
                -0.16507090628147125,
                -0.13716381788253784,
                -0.15665499866008759,
                -0.14855627715587616,
                -0.14350877702236176,
                -0.16989082098007202,
                -0.16303765773773193,
                -0.14999660849571228,
                -0.16946843266487122,
                -0.16624487936496735,
                -0.1143907904624939,
                -0.16752932965755463,
                -0.12541690468788147,
                -0.13743548095226288
            ],
            [
                -0.1397082507610321,
                -0.16928735375404358,
                -0.16819508373737335,
                -0.144964799284935,
                -0.08390714228153229,
                -0.16673772037029266,
                -0.14849068224430084,
                -0.13581092655658722,
                -0.10791508853435516,
                -0.16680659353733063,
                -0.12375350296497345,
                -0.16916492581367493,
                -0.15227241814136505,
                -0.15797577798366547,
                -0.16301631927490234,
                -0.16961582005023956
            ],
            [
                -0.15275165438652039,
                -0.1636279970407486,
                -0.14591671526432037,
                -0.15576401352882385,
                -0.16875535249710083,
                -0.03975275158882141,
                -0.16423143446445465,
                -0.1563701331615448,
                -0.1700161099433899,
                -0.10580579936504364,
                -0.1408998668193817,
                -0.16679523885250092,
                -0.06436364352703094,
                -0.06254161149263382,
                -0.16998589038848877,
                -0.16438503563404083
            ],
            [
                -0.14081589877605438,
                -0.16991713643074036,
                -0.16997772455215454,
                -0.17000649869441986,
                -0.17003430426120758,
                -0.1601645052433014,
                -0.1675647348165512,
                -0.12116750329732895,
                -0.07090377807617188,
                -0.16937750577926636,
                -0.02616206184029579,
                -0.1105281263589859,
                -0.05789831653237343,
                -0.06593603640794754,
                -0.14524488151073456,
                -0.09454627335071564
            ],
            [
                -0.16932961344718933,
                -0.13119666278362274,
                -0.1697460114955902,
                -0.15767189860343933,
                -0.15522928535938263,
                -0.11302611976861954,
                -0.08347699046134949,
                -0.141860231757164,
                -0.1561337113380432,
                -0.145964115858078,
                -0.024582955986261368,
                -0.16494420170783997,
                -0.16636934876441956,
                -0.17003561556339264,
                -0.07897908985614777,
                0.007798513863235712
            ],
            [
                -0.16333211958408356,
                -0.16307756304740906,
                -0.16964459419250488,
                -0.12243927270174026,
                -0.17000427842140198,
                -0.16991764307022095,
                -0.14052997529506683,
                -0.15911592543125153,
                -0.1682485044002533,
                -0.1689452975988388,
                -0.13692606985569,
                -0.1691230684518814,
                -0.1635977178812027,
                -0.16566342115402222,
                -0.16867351531982422,
                -0.013672363944351673
            ]
        ],
        [
            [
                2.1560704708099365,
                2.1777918338775635,
                2.641843318939209,
                3.2163922786712646,
                2.79086971282959,
                4.170819282531738,
                3.0783603191375732,
                1.7783061265945435,
                2.1219353675842285,
                4.979445457458496,
                3.0587775707244873,
                4.943465709686279,
                3.826204299926758,
                3.7248404026031494,
                6.151185035705566,
                2.412445306777954
            ],
            [
                3.8168528079986572,
                5.589433193206787,
                3.607614517211914,
                3.213895559310913,
                3.720301628112793,
                6.940000057220459,
                4.472011089324951,
                4.097301483154297,
                5.149133682250977,
                2.5499684810638428,
                3.387498617172241,
                3.1323671340942383,
                4.308858871459961,
                4.500176906585693,
                5.687371253967285,
                4.229689598083496
            ],
            [
                2.40803861618042,
                5.536258697509766,
                3.9479804039001465,
                3.8556673526763916,
                5.476546287536621,
                5.016280651092529,
                5.920957088470459,
                2.661633253097534,
                4.973911285400391,
                3.848825454711914,
                2.0115296840667725,
                4.132927894592285,
                5.0804338455200195,
                7.601196765899658,
                5.140347003936768,
                6.072541236877441
            ],
            [
                2.72987961769104,
                2.1972131729125977,
                2.303748369216919,
                3.826197385787964,
                6.451686859130859,
                7.148534297943115,
                4.573263645172119,
                3.094484567642212,
                3.7701804637908936,
                2.8841567039489746,
                3.1467854976654053,
                2.9881606101989746,
                6.183689594268799,
                6.610760688781738,
                5.5427632331848145,
                2.6070282459259033
            ],
            [
                4.748512268066406,
                6.226253509521484,
                4.03374719619751,
                3.53694748878479,
                4.7259392738342285,
                4.221087455749512,
                4.701441287994385,
                5.196455955505371,
                4.11960506439209,
                5.602898597717285,
                2.794294595718384,
                3.3761696815490723,
                2.368138551712036,
                3.0192549228668213,
                4.251828670501709,
                2.8437845706939697
            ],
            [
                2.9506993293762207,
                4.356379508972168,
                3.980208158493042,
                2.4449236392974854,
                4.1613664627075195,
                4.245007514953613,
                4.614138126373291,
                3.6484475135803223,
                2.775479555130005,
                3.160781145095825,
                5.084803581237793,
                7.447012901306152,
                4.928031921386719,
                4.945945739746094,
                4.084192752838135,
                1.0187058448791504
            ],
            [
                2.8524622917175293,
                2.70913028717041,
                5.492929458618164,
                2.573884963989258,
                2.924075126647949,
                4.000846862792969,
                3.0411880016326904,
                2.546870708465576,
                4.6273369789123535,
                4.380767822265625,
                5.872989654541016,
                6.612155437469482,
                6.013176918029785,
                7.581756591796875,
                6.989511013031006,
                4.944978713989258
            ],
            [
                1.1206440925598145,
                4.127493381500244,
                4.633059978485107,
                6.475038528442383,
                0.6811763048171997,
                2.6013922691345215,
                5.029517650604248,
                3.3738114833831787,
                4.283355236053467,
                1.542811393737793,
                2.5521020889282227,
                2.454453945159912,
                4.894162178039551,
                7.131077766418457,
                5.298130512237549,
                3.1344854831695557
            ],
            [
                0.8283449411392212,
                0.8948760032653809,
                1.0067267417907715,
                1.1260077953338623,
                0.9401947259902954,
                1.2668709754943848,
                1.0823369026184082,
                0.8470145463943481,
                1.8276746273040771,
                1.1615400314331055,
                0.8095685839653015,
                1.1535556316375732,
                1.241685390472412,
                1.1754176616668701,
                0.5670016407966614,
                0.41805407404899597
            ],
            [
                0.6911917924880981,
                1.155912160873413,
                1.012883186340332,
                0.6576652526855469,
                0.8257251977920532,
                0.5662890076637268,
                0.6612709760665894,
                1.1021547317504883,
                0.8323525786399841,
                1.056281328201294,
                0.5918762683868408,
                0.8523123264312744,
                0.9713078737258911,
                0.8933233618736267,
                0.9381101727485657,
                0.3947266936302185
            ],
            [
                0.6906538009643555,
                0.6178556680679321,
                0.8339443802833557,
                1.0642858743667603,
                0.984902560710907,
                1.0215400457382202,
                1.3118648529052734,
                1.1730445623397827,
                1.2823883295059204,
                1.0656638145446777,
                0.9148443937301636,
                1.1096885204315186,
                1.0798712968826294,
                1.0561604499816895,
                0.7970293164253235,
                0.36589956283569336
            ],
            [
                0.8121750950813293,
                1.7487752437591553,
                0.9771143198013306,
                0.7714473605155945,
                0.6696592569351196,
                0.8777029514312744,
                0.9193552136421204,
                0.8064470291137695,
                0.9264287352561951,
                1.198580265045166,
                0.9251003265380859,
                1.1992249488830566,
                0.8110361695289612,
                1.1469860076904297,
                0.8397136926651001,
                1.081872582435608
            ],
            [
                0.9271647930145264,
                1.2818089723587036,
                1.1289900541305542,
                1.4704952239990234,
                1.2708579301834106,
                0.9581448435783386,
                0.9989407062530518,
                1.237516164779663,
                1.3471121788024902,
                0.8985932469367981,
                1.0562223196029663,
                0.6920410990715027,
                0.6626725196838379,
                0.973558247089386,
                1.214436650276184,
                1.0038917064666748
            ],
            [
                0.5894287824630737,
                1.09075129032135,
                1.0565351247787476,
                1.06409752368927,
                1.4708008766174316,
                1.4078360795974731,
                1.3340917825698853,
                0.8822212815284729,
                0.7839422821998596,
                0.9195992946624756,
                0.7638177275657654,
                1.1139973402023315,
                0.779543399810791,
                0.618047833442688,
                0.7976534962654114,
                0.4361446499824524
            ],
            [
                0.6738490462303162,
                1.1045372486114502,
                1.007997989654541,
                0.8786863088607788,
                0.9145486354827881,
                0.6489664912223816,
                1.0880610942840576,
                1.1445841789245605,
                1.215813159942627,
                0.9046353101730347,
                1.2691699266433716,
                0.8318226337432861,
                1.1012365818023682,
                1.1443140506744385,
                1.0343807935714722,
                0.3502095639705658
            ],
            [
                0.7776716947555542,
                0.7970445156097412,
                0.35425516963005066,
                0.7923966646194458,
                1.0088587999343872,
                0.9990296959877014,
                0.8940916657447815,
                0.9088245034217834,
                0.8121919631958008,
                0.7115100622177124,
                0.7829233407974243,
                0.6888037919998169,
                0.9176639318466187,
                0.555873692035675,
                0.6319929361343384,
                0.17379243671894073
            ]
        ],
        [
            [
                -0.1699058562517166,
                0.9119608402252197,
                1.4222396612167358,
                1.6899186372756958,
                1.106966257095337,
                0.9408940076828003,
                0.6516084671020508,
                2.420276403427124,
                0.12832102179527283,
                -0.03448392450809479,
                1.927771806716919,
                1.5185445547103882,
                0.6508772373199463,
                0.6826128959655762,
                -0.08089900761842728,
                -0.1594831496477127
            ],
            [
                1.0591650009155273,
                -0.034312278032302856,
                0.34173688292503357,
                2.0090367794036865,
                2.3376922607421875,
                -0.12118900567293167,
                -0.1687353402376175,
                0.35788071155548096,
                0.22054456174373627,
                1.2752981185913086,
                1.977231502532959,
                0.7508432269096375,
                0.03544614464044571,
                -0.1159961000084877,
                1.7115159034729004,
                0.42101091146469116
            ],
            [
                1.1634472608566284,
                -0.16971392929553986,
                -0.1628974825143814,
                0.488053560256958,
                0.4528213143348694,
                1.3032511472702026,
                -0.025234993547201157,
                2.756380558013916,
                0.0603274405002594,
                1.5728437900543213,
                1.7046018838882446,
                -0.14020386338233948,
                -0.12402119487524033,
                -0.0036060952115803957,
                0.9298126101493835,
                -0.056374333798885345
            ],
            [
                -0.15443196892738342,
                -0.08722196519374847,
                1.113499641418457,
                0.6287161707878113,
                0.16009922325611115,
                -0.1681397557258606,
                -0.1612771600484848,
                1.708493947982788,
                -0.14930681884288788,
                2.5224411487579346,
                0.9626826047897339,
                0.9975035786628723,
                1.3137181997299194,
                0.2337723821401596,
                -0.04960493743419647,
                0.237346351146698
            ],
            [
                -0.15531481802463531,
                -0.003663795068860054,
                -0.13863009214401245,
                1.5511444807052612,
                0.945354163646698,
                0.11049243807792664,
                -0.02547304332256317,
                -0.08401597291231155,
                0.15721167623996735,
                1.8405580520629883,
                1.2568117380142212,
                0.058258961886167526,
                1.4966543912887573,
                1.1699035167694092,
                -0.14752231538295746,
                0.07779578864574432
            ],
            [
                2.084836483001709,
                1.3336578607559204,
                0.23352308571338654,
                0.5497364401817322,
                0.13265572488307953,
                -0.039195068180561066,
                -0.01183065865188837,
                -0.07592014968395233,
                0.35164839029312134,
                0.422713965177536,
                0.7578034996986389,
                -0.15232977271080017,
                0.8545475006103516,
                2.83150053024292,
                -0.11522141098976135,
                1.4265496730804443
            ],
            [
                1.436299204826355,
                1.3569666147232056,
                -0.009729648940265179,
                0.4571291506290436,
                0.00020427952404133976,
                -0.034856464713811874,
                -0.09254250675439835,
                0.10553120076656342,
                0.04445097595453262,
                1.304004430770874,
                -0.10966398566961288,
                -0.13002271950244904,
                0.47761648893356323,
                0.7567166686058044,
                -0.0995410680770874,
                -0.051052648574113846
            ],
            [
                2.013184070587158,
                0.030659614130854607,
                -0.021431609988212585,
                -0.12482893466949463,
                1.4127875566482544,
                1.0723663568496704,
                -0.13967545330524445,
                0.1589154452085495,
                -0.1468925029039383,
                1.8141965866088867,
                1.2389034032821655,
                1.8158409595489502,
                0.2782779037952423,
                2.1863956451416016,
                2.1705799102783203,
                2.310957670211792
            ],
            [
                1.1836891174316406,
                0.8349634408950806,
                0.8891416192054749,
                1.0495868921279907,
                1.1491568088531494,
                0.717735230922699,
                1.1865555047988892,
                0.8670209050178528,
                0.8997781276702881,
                0.64478999376297,
                0.550591766834259,
                0.9112454652786255,
                1.197475790977478,
                2.0013315677642822,
                1.9555965662002563,
                1.184887409210205
            ],
            [
                0.421843945980072,
                0.7214257121086121,
                0.5093713998794556,
                0.8619886040687561,
                0.7436676621437073,
                0.9003857970237732,
                1.2922080755233765,
                0.7505789995193481,
                0.8459936380386353,
                1.2639217376708984,
                0.6803961396217346,
                0.8799864649772644,
                0.6993178129196167,
                0.7003478407859802,
                0.5715711116790771,
                0.3921990394592285
            ],
            [
                0.7565596699714661,
                0.9307875037193298,
                1.0420701503753662,
                0.9942226409912109,
                0.6175023317337036,
                0.5509288907051086,
                0.48023664951324463,
                0.5916913747787476,
                0.6521185040473938,
                0.4149087071418762,
                0.5764054656028748,
                0.7349974513053894,
                0.5840005278587341,
                0.5172733664512634,
                0.5007043480873108,
                0.3648047149181366
            ],
            [
                0.9009785056114197,
                0.7450526356697083,
                1.0802468061447144,
                0.9368276000022888,
                0.9160585999488831,
                0.6945374011993408,
                0.5206414461135864,
                0.6744250059127808,
                0.7742876410484314,
                0.8362681865692139,
                0.7925742864608765,
                0.8840194344520569,
                1.1551547050476074,
                0.6793753504753113,
                0.41816872358322144,
                0.14794541895389557
            ],
            [
                0.5182417035102844,
                0.4699595868587494,
                0.8237856030464172,
                0.9186968207359314,
                1.167142391204834,
                0.8825573325157166,
                0.9079890251159668,
                0.5604026317596436,
                0.7632374167442322,
                1.0090571641921997,
                0.6481019258499146,
                0.9003854393959045,
                1.2329381704330444,
                0.9202402234077454,
                0.6038240790367126,
                0.4029746353626251
            ],
            [
                0.5671185255050659,
                0.7977592945098877,
                0.591320276260376,
                0.7396180629730225,
                0.5790228843688965,
                0.7188825607299805,
                0.737551748752594,
                0.9121534824371338,
                0.6971415877342224,
                0.7993910908699036,
                1.2551546096801758,
                0.682731032371521,
                0.7981899380683899,
                1.2660889625549316,
                0.9099928140640259,
                0.73408442735672
            ],
            [
                0.8794881701469421,
                1.0667624473571777,
                0.9777319431304932,
                0.9569196105003357,
                1.1101080179214478,
                1.0064187049865723,
                0.7107409834861755,
                0.6638573408126831,
                0.6289414167404175,
                1.0200492143630981,
                1.4187945127487183,
                1.4674739837646484,
                0.9941843748092651,
                1.0395272970199585,
                1.569007158279419,
                0.9782763719558716
            ],
            [
                0.28894537687301636,
                0.657896876335144,
                0.550385594367981,
                0.8859277963638306,
                0.6666393876075745,
                0.6319164037704468,
                0.6302205920219421,
                0.3336710035800934,
                0.527458667755127,
                0.9726434350013733,
                1.4229395389556885,
                0.5186841487884521,
                0.36784613132476807,
                0.7635933756828308,
                1.3100413084030151,
                0.9171842932701111
            ]
        ],
        [
            [
                4.736283779144287,
                5.747889995574951,
                3.964048147201538,
                5.005827903747559,
                4.32148551940918,
                4.827167510986328,
                5.27386999130249,
                5.050436496734619,
                6.8129658699035645,
                5.528278827667236,
                3.6610209941864014,
                4.874915599822998,
                4.602668762207031,
                4.584091663360596,
                4.495276927947998,
                6.790847301483154
            ],
            [
                4.865328311920166,
                6.225250720977783,
                5.039729118347168,
                2.568742036819458,
                5.873438358306885,
                4.699702262878418,
                4.523599624633789,
                6.163722991943359,
                3.3261919021606445,
                4.351664066314697,
                4.627915859222412,
                4.0858964920043945,
                5.138890266418457,
                4.6779022216796875,
                5.7069597244262695,
                7.701621055603027
            ],
            [
                5.608402252197266,
                5.844210147857666,
                6.5070481300354,
                3.158005952835083,
                5.636250019073486,
                5.246583938598633,
                8.18122386932373,
                4.974489212036133,
                4.78811502456665,
                4.659897804260254,
                4.919290065765381,
                4.78517484664917,
                6.56156063079834,
                7.088614463806152,
                7.360414981842041,
                5.424105644226074
            ],
            [
                5.282041072845459,
                5.588748931884766,
                3.9331533908843994,
                4.169962406158447,
                5.650139808654785,
                5.7072834968566895,
                6.380147933959961,
                5.406877040863037,
                5.978238105773926,
                4.560003757476807,
                4.758187294006348,
                4.804807662963867,
                5.738175392150879,
                7.276418685913086,
                7.657364368438721,
                5.021081924438477
            ],
            [
                5.903786659240723,
                6.100033760070801,
                3.8373239040374756,
                3.0644876956939697,
                4.474191665649414,
                5.784465312957764,
                6.201229572296143,
                5.830088138580322,
                6.481866359710693,
                4.3250274658203125,
                4.382331371307373,
                5.228000640869141,
                5.299311637878418,
                3.908006429672241,
                4.948355197906494,
                4.585003852844238
            ],
            [
                5.016289710998535,
                5.3157639503479,
                5.060512065887451,
                3.3068840503692627,
                4.555179119110107,
                4.011144638061523,
                4.558535575866699,
                4.378535270690918,
                4.5439839363098145,
                5.348555088043213,
                5.770297527313232,
                7.7051897048950195,
                6.666078090667725,
                5.77742338180542,
                4.832336902618408,
                4.801770210266113
            ],
            [
                5.588968276977539,
                5.4035468101501465,
                4.800119400024414,
                4.821074485778809,
                4.215611457824707,
                5.117054462432861,
                5.429117202758789,
                4.835204601287842,
                5.696776866912842,
                5.936429977416992,
                5.294363975524902,
                4.7185258865356445,
                6.756984233856201,
                7.409144878387451,
                7.508994102478027,
                5.995311737060547
            ],
            [
                5.357158184051514,
                5.989358901977539,
                5.644400119781494,
                4.974542617797852,
                4.47355318069458,
                5.701353073120117,
                5.90486478805542,
                5.046710014343262,
                7.7042341232299805,
                6.714570999145508,
                4.299119472503662,
                4.710008144378662,
                4.2331061363220215,
                10.345264434814453,
                5.502847194671631,
                5.003408432006836
            ],
            [
                -0.013705226592719555,
                0.09340425580739975,
                -0.07628468424081802,
                -0.14910776913166046,
                -0.16451668739318848,
                -0.12338101863861084,
                -0.04421061649918556,
                0.02730651944875717,
                0.12730228900909424,
                -0.10992775112390518,
                -0.15615306794643402,
                -0.14926579594612122,
                -0.05323377251625061,
                0.0008051431505009532,
                0.22770985960960388,
                0.021993406116962433
            ],
            [
                -0.16485172510147095,
                -0.16869884729385376,
                -0.16739223897457123,
                -0.0451614074409008,
                -0.1313776969909668,
                -0.06590240448713303,
                -0.0201902873814106,
                0.16809722781181335,
                -0.0586831234395504,
                -0.07181954383850098,
                -0.13292673230171204,
                -0.12678112089633942,
                0.04458086937665939,
                -0.05499519035220146,
                -0.16999739408493042,
                -0.15287840366363525
            ],
            [
                -0.1700262427330017,
                -0.15784867107868195,
                -0.07232917100191116,
                -0.13186703622341156,
                -0.0751023143529892,
                -0.16988414525985718,
                -0.14783288538455963,
                -0.1465086042881012,
                0.15292610228061676,
                -0.03102976828813553,
                -0.12021564692258835,
                -0.1094546690583229,
                0.05003724247217178,
                -0.15940305590629578,
                -0.10827810317277908,
                -0.10249993205070496
            ],
            [
                -0.0956634059548378,
                -0.04247279837727547,
                -0.14588558673858643,
                -0.02991364523768425,
                -0.16906149685382843,
                -0.09676279872655869,
                -0.14925207197666168,
                -0.16392718255519867,
                -0.024028141051530838,
                -0.15671420097351074,
                -0.12368173152208328,
                -0.013310163281857967,
                -0.16878628730773926,
                -0.161552295088768,
                -0.16829191148281097,
                -0.16947107017040253
            ],
            [
                -0.15743467211723328,
                -0.16844964027404785,
                -0.15892747044563293,
                -0.17002420127391815,
                0.2685159146785736,
                -0.11448737978935242,
                -0.01712389662861824,
                -0.11899969726800919,
                -0.12556715309619904,
                -0.04426060989499092,
                -0.15157544612884521,
                0.011211065575480461,
                0.07375669479370117,
                -0.048899032175540924,
                -0.002212110674008727,
                -0.14994241297245026
            ],
            [
                -0.12386208027601242,
                -0.08182625472545624,
                -0.04710403084754944,
                -0.1566847562789917,
                -0.14921525120735168,
                0.005850259680300951,
                -0.06561823934316635,
                0.13618840277194977,
                -0.037710849195718765,
                -0.1304078996181488,
                -0.1686844676733017,
                0.12299889326095581,
                -0.09592726081609726,
                -0.10261452198028564,
                -0.1620541661977768,
                0.07110152393579483
            ],
            [
                -0.08737492561340332,
                -0.13748705387115479,
                -0.16901837289333344,
                -0.13195689022541046,
                -0.1629732847213745,
                -0.15053433179855347,
                0.018355879932641983,
                0.16166380047798157,
                0.2190798670053482,
                -0.03682267293334007,
                0.06976738572120667,
                -0.12220166623592377,
                -0.16507907211780548,
                -0.12427975237369537,
                -0.15382389724254608,
                -0.12805934250354767
            ],
            [
                -0.039578162133693695,
                0.09431938081979752,
                -0.13526469469070435,
                -0.1612972617149353,
                0.11918919533491135,
                0.10078014433383942,
                -0.008634934201836586,
                -0.0939718708395958,
                -0.1314190775156021,
                -0.12293912470340729,
                0.38833993673324585,
                -0.07867639511823654,
                -0.09876656532287598,
                -0.16546472907066345,
                0.6261323690414429,
                0.4588148295879364
            ]
        ],
        [
            [
                -2.8067464086234395e-7,
                0,
                -1.9862486055899353e-7,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                -2.9213811103545595e-7,
                0
            ],
            [
                -2.9798792411384056e-7,
                -3.537770112416183e-7,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                -2.935081795385486e-7,
                0,
                0,
                -3.039935165816132e-7,
                -2.9834714609933144e-7,
                -3.432430162320088e-7,
                -1.5325214519634756e-7
            ],
            [
                0,
                0,
                0,
                -1.7701547960768949e-7,
                0,
                0,
                0,
                -2.73853430599047e-7,
                -2.788536050957191e-7,
                -2.1597890054181335e-7,
                0,
                0,
                0,
                0,
                null,
                0
            ],
            [
                0,
                0,
                0,
                0,
                0,
                0,
                -3.405709492199094e-7,
                0,
                -0.000001132480065280106,
                -1.6640481703689147e-7,
                0,
                0,
                -3.275065409980016e-7,
                0,
                null,
                0
            ],
            [
                0,
                0,
                0,
                0,
                0,
                null,
                0,
                0,
                0,
                -1.9715446342161158e-7,
                -2.9369567755566095e-7,
                0,
                0,
                0,
                -2.3550992978016438e-7,
                0
            ],
            [
                0,
                -2.9243398103062646e-7,
                0,
                0,
                0,
                0,
                0,
                -2.9772093057545135e-7,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            [
                0,
                -2.940598164968833e-7,
                0,
                0,
                0,
                0,
                0,
                -1.8625206621436519e-7,
                -3.1864021821093047e-7,
                0,
                -6.47621488571167,
                0,
                -2.611767797588982e-7,
                0,
                null,
                0
            ],
            [
                0,
                -3.3759712891878735e-7,
                0,
                0,
                -0.00000599711120230495,
                0,
                0,
                0,
                0,
                -2.780071497454628e-7,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            [
                0.12473274022340775,
                -0.050745878368616104,
                0.10167113691568375,
                0.19582124054431915,
                0.23708656430244446,
                0.0464356429874897,
                0.17402856051921844,
                0.15506905317306519,
                0.07598017901182175,
                0.09014233201742172,
                0.2683617174625397,
                0.24993103742599487,
                0.03884565085172653,
                0.04987045377492905,
                0.15630292892456055,
                0.20449431240558624
            ],
            [
                0.28593140840530396,
                0.27814531326293945,
                0.3806479275226593,
                0.2601551115512848,
                0.279959112405777,
                0.13316921889781952,
                0.45630863308906555,
                0.3964195251464844,
                0.3507857024669647,
                0.3292231857776642,
                0.27139124274253845,
                0.38566988706588745,
                0.37025395035743713,
                0.32136070728302,
                0.3412553071975708,
                0.4189884662628174
            ],
            [
                0.3373245894908905,
                0.3883121907711029,
                0.28383585810661316,
                0.17243105173110962,
                0.28763243556022644,
                0.4124675393104553,
                0.4749797284603119,
                0.4151459336280823,
                0.41768813133239746,
                0.35226574540138245,
                0.37613043189048767,
                0.34891030192375183,
                0.3507607877254486,
                0.4691030979156494,
                0.3604643940925598,
                0.41677126288414
            ],
            [
                0.24975356459617615,
                0.4443192780017853,
                0.1913308948278427,
                0.12318698316812515,
                0.09039180725812912,
                0.22723975777626038,
                0.49419716000556946,
                0.3934728801250458,
                0.2575109899044037,
                0.39072275161743164,
                0.2042042315006256,
                0.442288875579834,
                0.25397059321403503,
                0.25128963589668274,
                0.19873948395252228,
                0.2749420404434204
            ],
            [
                0.3106105327606201,
                0.5541501641273499,
                0.2724153399467468,
                0.3538983464241028,
                0.09246029704809189,
                0.23163950443267822,
                0.5044364929199219,
                0.30481645464897156,
                0.39737024903297424,
                0.34133657813072205,
                0.2623560130596161,
                0.39173001050949097,
                0.41625308990478516,
                0.2597736716270447,
                0.23302677273750305,
                0.08677420020103455
            ],
            [
                0.2005275934934616,
                0.27517732977867126,
                0.175268292427063,
                0.39231187105178833,
                0.37000077962875366,
                0.28410863876342773,
                0.4229527413845062,
                0.23646189272403717,
                0.24921609461307526,
                0.4843916594982147,
                0.15500807762145996,
                0.001675247447565198,
                0.20099078118801117,
                0.41968289017677307,
                0.23254363238811493,
                0.2136923223733902
            ],
            [
                0.22689811885356903,
                0.34310382604599,
                0.1631394922733307,
                0.08667517453432083,
                0.2737226188182831,
                0.24894796311855316,
                0.2714817523956299,
                0.2867995500564575,
                0.34880438446998596,
                0.24370136857032776,
                0.11401911824941635,
                0.11644148081541061,
                0.32956647872924805,
                0.18730582296848297,
                0.10838671028614044,
                0.11997611075639725
            ],
            [
                0.47879594564437866,
                0.496340811252594,
                0.35014772415161133,
                0.14819040894508362,
                0.1525425761938095,
                0.2542261779308319,
                0.23027972877025604,
                0.3575764000415802,
                0.3695719242095947,
                0.413806289434433,
                0.24931593239307404,
                0.3003331422805786,
                0.3425249457359314,
                0.21833573281764984,
                0.2806074619293213,
                0.15876822173595428
            ]
        ],
        [
            [
                -0.11607570946216583,
                -0.1397428661584854,
                -0.018094854429364204,
                -0.01579643040895462,
                -0.002084417035803199,
                -0.0049119144678115845,
                -0.010861172340810299,
                -0.13059796392917633,
                -0.1614844650030136,
                -0.05959271639585495,
                -0.08157338947057724,
                -0.027575191110372543,
                -0.12424234300851822,
                -0.06747987121343613,
                0,
                -0.12189161032438278
            ],
            [
                0.14272335171699524,
                -0.00046570453559979796,
                -0.001338096335530281,
                -0.002340017817914486,
                -0.009150718338787556,
                -0.001242299797013402,
                -0.005825812462717295,
                -0.008213300257921219,
                -4.348711399870808e-7,
                -0.006615855265408754,
                -0.16352607309818268,
                -0.06112256273627281,
                -0.04407511278986931,
                -0.07072988152503967,
                -0.00000613340262134443,
                0.3639766275882721
            ],
            [
                0.4501171410083771,
                -0.0016422379994764924,
                -0.16516178846359253,
                -0.15063540637493134,
                -0.0013929676497355103,
                0,
                -0.005986650940030813,
                -0.000244964292505756,
                -0.11141816526651382,
                0.7448427677154541,
                0.4500046670436859,
                -0.046016015112400055,
                -0.042331963777542114,
                -0.0007451667916029692,
                0,
                -0.08507956564426422
            ],
            [
                -0.14184297621250153,
                -0.16928571462631226,
                -0.0017327229725196958,
                -0.000008033753147174139,
                -0.0000268781186605338,
                -1.8290307934876182e-7,
                -0.04644814878702164,
                -0.0010161981917917728,
                0.6420581340789795,
                1.2020131349563599,
                0.04062565416097641,
                -0.09117384999990463,
                -0.05071501433849335,
                -0.0000011339728871462285,
                -0.0032053457107394934,
                0.23589631915092468
            ],
            [
                -0.044143229722976685,
                -0.00005688175588147715,
                -0.0008418213692493737,
                -1.5808073783318832e-7,
                -0.009274345822632313,
                -0.000017342516002827324,
                -0.0001607593148946762,
                -0.04783301800489426,
                0.14066584408283234,
                -0.1470508873462677,
                -0.020157618448138237,
                -0.059002868831157684,
                -0.004368611145764589,
                -0.002578022191300988,
                -0.005947188939899206,
                0.9819210171699524
            ],
            [
                0.47835662961006165,
                -0.016490519046783447,
                -0.011499403044581413,
                -0.00005010216773371212,
                -0.04988472908735275,
                -0.0031022722832858562,
                -0.03567768260836601,
                0.6174895167350769,
                -0.1616523265838623,
                -0.16650129854679108,
                -0.00617539556697011,
                -2.5359793198731495e-7,
                -0.06637725979089737,
                -0.09741159528493881,
                -0.000012671383956330828,
                -0.15012869238853455
            ],
            [
                -0.0756731629371643,
                -0.001902617746964097,
                -0.002469023922458291,
                -0.0004381722828838974,
                -0.13202041387557983,
                -0.06256381422281265,
                -0.10764052718877792,
                0.052437882870435715,
                -0.15762540698051453,
                -0.032950833439826965,
                -0.004361920058727264,
                -0.000005998535925755277,
                -0.020156847313046455,
                0,
                -0.0000835099708638154,
                -0.021694332361221313
            ],
            [
                -0.16899655759334564,
                -0.000002612266825963161,
                -0.000007788641596562229,
                -2.6308319434065197e-7,
                0.12066565454006195,
                -0.10792182385921478,
                -0.04886242002248764,
                -0.169691264629364,
                -0.006564690265804529,
                -0.06079757958650589,
                -0.07664814591407776,
                -0.16845852136611938,
                -0.0023415677715092897,
                0,
                -2.2387084186448192e-7,
                -0.10401376336812973
            ],
            [
                0.5460466146469116,
                0.292868047952652,
                0.579874575138092,
                0.5993971228599548,
                0.5067685842514038,
                0.20039309561252594,
                0.2204342782497406,
                -0.07419096678495407,
                0.15375898778438568,
                0.3679637014865875,
                0.5920138955116272,
                0.5352011322975159,
                0.12002203613519669,
                0.49162957072257996,
                0.5074532628059387,
                1.1199480295181274
            ],
            [
                0.5908536911010742,
                0.24748854339122772,
                0.22978989779949188,
                0.3470620810985565,
                -0.004153609741479158,
                0.30603039264678955,
                0.17064444720745087,
                -0.14733688533306122,
                0.3959611654281616,
                0.02065901644527912,
                0.5846911668777466,
                0.5123687386512756,
                0.17286492884159088,
                0.1229039877653122,
                0.4466278851032257,
                0.5846505761146545
            ],
            [
                0.6096892356872559,
                0.23500320315361023,
                0.17355382442474365,
                0.21490342915058136,
                0.44474542140960693,
                0.411420613527298,
                -0.07781241089105606,
                0.057311683893203735,
                -0.10928624868392944,
                -0.14718540012836456,
                0.1393962800502777,
                0.5413238406181335,
                0.037903837859630585,
                0.26384979486465454,
                0.4049893915653229,
                0.0329446978867054
            ],
            [
                0.3999210000038147,
                0.05461234599351883,
                0.5375128388404846,
                0.11747188866138458,
                0.3275447487831116,
                0.14733199775218964,
                0.08703431487083435,
                0.9731019735336304,
                0.09499026834964752,
                0.05008849874138832,
                0.19658376276493073,
                0.5648375153541565,
                1.028464436531067,
                0.40853750705718994,
                0.27087438106536865,
                0.301628053188324
            ],
            [
                0.27812713384628296,
                -0.06421782821416855,
                0.2031407207250595,
                0.4820376932621002,
                0.03694159910082817,
                0.09494534879922867,
                -0.008476616814732552,
                0.18949882686138153,
                0.3256186246871948,
                -0.02410038560628891,
                -0.0663934051990509,
                0.26768410205841064,
                0.5549936294555664,
                0.15421801805496216,
                0.2603317201137543,
                0.4321867823600769
            ],
            [
                -0.0022222010884433985,
                -0.07864221185445786,
                0.6250991821289062,
                0.2037922441959381,
                -0.14603382349014282,
                0.1607997566461563,
                -0.09055544435977936,
                -0.01165712159126997,
                0.1650434136390686,
                0.19711650907993317,
                0.9266156554222107,
                0.6133120059967041,
                0.17666293680667877,
                0.46426263451576233,
                0.3256925344467163,
                0.29211166501045227
            ],
            [
                0.16936424374580383,
                0.31588441133499146,
                0.5635290145874023,
                0.7063745856285095,
                0.05144038796424866,
                0.06139160692691803,
                0.20473209023475647,
                0.03492726758122444,
                0.12092263996601105,
                0.17756937444210052,
                0.42823559045791626,
                1.1635974645614624,
                0.46926194429397583,
                0.7216525673866272,
                0.27100393176078796,
                0.18479619920253754
            ],
            [
                0.15497907996177673,
                0.1528571993112564,
                0.743847131729126,
                1.1827735900878906,
                0.5026359558105469,
                0.2960476875305176,
                0.873579740524292,
                0.24773573875427246,
                0.5966682434082031,
                0.5366950035095215,
                0.414939284324646,
                0.3333525061607361,
                0.39088988304138184,
                0.49748578667640686,
                0.7399007081985474,
                0.3804524540901184
            ]
        ],
        [
            [
                5.668883323669434,
                6.694712162017822,
                5.724091053009033,
                7.859114646911621,
                7.040717124938965,
                5.508238315582275,
                5.53879451751709,
                5.818873405456543,
                6.234701633453369,
                5.153206825256348,
                4.566443920135498,
                5.247832298278809,
                5.772440433502197,
                5.8534674644470215,
                5.951720714569092,
                7.886575698852539
            ],
            [
                5.391050815582275,
                5.996126651763916,
                5.439068794250488,
                5.363512992858887,
                6.288680553436279,
                5.462038516998291,
                4.862354278564453,
                7.986875534057617,
                7.3355631828308105,
                5.274210453033447,
                4.734894752502441,
                3.7929277420043945,
                4.9136786460876465,
                3.7165865898132324,
                5.78969144821167,
                8.831927299499512
            ],
            [
                6.491209030151367,
                6.257997512817383,
                5.557643890380859,
                3.3693745136260986,
                5.58046817779541,
                6.3462629318237305,
                7.4567108154296875,
                6.751794338226318,
                6.052927494049072,
                4.761193752288818,
                5.182436943054199,
                5.537761688232422,
                6.140120506286621,
                7.4646806716918945,
                9.043665885925293,
                7.940521240234375
            ],
            [
                4.805878162384033,
                4.20491361618042,
                4.278875350952148,
                5.919327735900879,
                5.862697601318359,
                9.35659122467041,
                7.053597927093506,
                5.940715312957764,
                5.831079959869385,
                5.009083271026611,
                5.408570289611816,
                5.195166110992432,
                6.531234264373779,
                7.535614967346191,
                8.106346130371094,
                5.743645668029785
            ],
            [
                5.863969326019287,
                6.434147834777832,
                4.9187331199646,
                4.529013633728027,
                6.0711350440979,
                6.975826263427734,
                6.56229829788208,
                5.366460800170898,
                6.033627986907959,
                5.7617082595825195,
                7.427513122558594,
                5.199320316314697,
                4.999030590057373,
                6.257423400878906,
                5.664138317108154,
                4.815671920776367
            ],
            [
                4.527029991149902,
                5.591212749481201,
                5.450168132781982,
                4.531914234161377,
                5.414108753204346,
                4.138336181640625,
                5.169552803039551,
                4.110604763031006,
                5.2978925704956055,
                5.5260419845581055,
                9.229605674743652,
                11.769342422485352,
                6.835634231567383,
                5.330197334289551,
                5.671253681182861,
                5.8123979568481445
            ],
            [
                5.59244966506958,
                5.4757585525512695,
                5.4565887451171875,
                7.1876540184021,
                4.924931049346924,
                5.388683795928955,
                4.8148274421691895,
                4.285084247589111,
                5.435440540313721,
                7.660571575164795,
                7.188137054443359,
                8.11490535736084,
                5.604677200317383,
                9.946951866149902,
                8.063499450683594,
                6.3848137855529785
            ],
            [
                5.453731536865234,
                6.03666877746582,
                7.561370849609375,
                8.430620193481445,
                6.471799850463867,
                5.785639762878418,
                6.421915054321289,
                4.4649577140808105,
                8.436843872070312,
                7.655831813812256,
                5.259807586669922,
                4.686673164367676,
                6.715710639953613,
                12.050912857055664,
                10.122260093688965,
                7.294139385223389
            ],
            [
                0.8820407390594482,
                0.5521261096000671,
                0.41487371921539307,
                0.8523873090744019,
                0.32620033621788025,
                0.624810516834259,
                0.676460862159729,
                0.7095434069633484,
                0.5700088739395142,
                0.418470174074173,
                0.762570858001709,
                0.5769093036651611,
                0.5078794956207275,
                0.48832958936691284,
                0.6044514179229736,
                0.8090341687202454
            ],
            [
                0.6958248615264893,
                0.6785730123519897,
                0.6102771162986755,
                0.7199089527130127,
                0.587765097618103,
                0.8633716106414795,
                1.0395393371582031,
                0.8451297879219055,
                0.7736449837684631,
                0.7391573190689087,
                0.7998188734054565,
                0.6658872961997986,
                0.6960943937301636,
                0.6741007566452026,
                0.708820104598999,
                0.8531385064125061
            ],
            [
                0.5270097851753235,
                0.8037703037261963,
                1.0129724740982056,
                0.7331409454345703,
                0.614647626876831,
                0.7661312222480774,
                0.9323154091835022,
                0.8366329669952393,
                0.8086125254631042,
                0.6396362781524658,
                0.7987940311431885,
                0.830676257610321,
                0.8109524846076965,
                0.8096688985824585,
                0.9029128551483154,
                0.9112561345100403
            ],
            [
                0.7438861131668091,
                0.8398061990737915,
                0.868834376335144,
                0.6471317410469055,
                0.572327196598053,
                0.6124094128608704,
                0.8248133659362793,
                0.6686040759086609,
                0.9072561860084534,
                0.7989457845687866,
                0.8137513399124146,
                0.7880937457084656,
                0.8770608305931091,
                0.8438310027122498,
                0.6954498291015625,
                0.7935497164726257
            ],
            [
                0.8396843671798706,
                0.7684580683708191,
                0.9136892557144165,
                0.6862519979476929,
                0.5966098308563232,
                0.6403540968894958,
                0.8081057071685791,
                0.8575841188430786,
                0.7330464124679565,
                0.8407592177391052,
                0.75449538230896,
                0.7057934403419495,
                0.7649787068367004,
                0.7189542651176453,
                0.6831680536270142,
                0.6162810921669006
            ],
            [
                0.8260425329208374,
                0.8961440920829773,
                1.0469334125518799,
                0.921176016330719,
                0.9615634083747864,
                0.8107930421829224,
                0.6991685032844543,
                0.81200110912323,
                0.7639774680137634,
                0.8408582210540771,
                0.7085412740707397,
                0.47680824995040894,
                0.6528010964393616,
                0.9728633165359497,
                0.8199841976165771,
                0.8468278646469116
            ],
            [
                0.6703351736068726,
                0.872058629989624,
                0.8213537931442261,
                0.8986616134643555,
                0.46338194608688354,
                0.6402999758720398,
                0.8156858682632446,
                0.9668369293212891,
                0.7794482111930847,
                0.8246921300888062,
                1.006591796875,
                0.9163073897361755,
                0.7438976764678955,
                0.9208470582962036,
                0.6922758221626282,
                0.90983647108078
            ],
            [
                0.6862790584564209,
                0.8067229986190796,
                0.8280277848243713,
                0.8493773341178894,
                0.634503185749054,
                0.7442114949226379,
                0.9631629586219788,
                0.7188810110092163,
                0.8482155799865723,
                0.634834349155426,
                0.7047957181930542,
                0.6324811577796936,
                0.758893609046936,
                0.8124203085899353,
                0.9049406051635742,
                0.8639037609100342
            ]
        ],
        [
            [
                -0.16187399625778198,
                0.45745253562927246,
                2.345767021179199,
                4.67265510559082,
                2.9042747020721436,
                0.3839676082134247,
                -0.11800569295883179,
                0.9122486114501953,
                -0.0739443376660347,
                0.2642686367034912,
                0.5506238341331482,
                0.8081401586532593,
                0.013523034751415253,
                0.7443846464157104,
                2.000366449356079,
                0.8214940428733826
            ],
            [
                -0.1698503941297531,
                0.05523085594177246,
                2.81950306892395,
                2.9246885776519775,
                2.732294797897339,
                -0.14336876571178436,
                -0.10486285388469696,
                4.613003730773926,
                3.7357208728790283,
                0.5470316410064697,
                1.04384446144104,
                -0.10711582750082016,
                -0.09767115861177444,
                -0.16429586708545685,
                3.3914976119995117,
                0.6882678866386414
            ],
            [
                0.7077528834342957,
                -0.16935943067073822,
                0.36576929688453674,
                -0.15756969153881073,
                1.4161397218704224,
                3.1051719188690186,
                0.6891732811927795,
                3.323672294616699,
                1.1912236213684082,
                -0.0771571695804596,
                -0.04148496687412262,
                1.1878117322921753,
                1.2100268602371216,
                0.31302422285079956,
                5.6425628662109375,
                -0.1664322316646576
            ],
            [
                -0.041882988065481186,
                -0.16975361108779907,
                -0.12174879014492035,
                0.4603118598461151,
                -0.13901010155677795,
                6.1398491859436035,
                0.7369744777679443,
                3.350388288497925,
                2.0714945793151855,
                2.4920549392700195,
                2.145987033843994,
                0.19838622212409973,
                1.2999002933502197,
                0.6429125666618347,
                0.10753726214170456,
                0.7439010739326477
            ],
            [
                -0.1278199851512909,
                0.13615179061889648,
                0.44425681233406067,
                1.7862485647201538,
                0.16247907280921936,
                0.6399289965629578,
                -0.08120660483837128,
                -0.12114175409078598,
                1.2338457107543945,
                2.9872612953186035,
                2.8761608600616455,
                -0.09583376348018646,
                1.0494846105575562,
                0.15378016233444214,
                0.6927130222320557,
                -0.10972051322460175
            ],
            [
                0.15470768511295319,
                1.0193753242492676,
                0.14661891758441925,
                -0.10859912633895874,
                0.31801706552505493,
                -0.0007740181172266603,
                -0.1647026538848877,
                -0.09822475910186768,
                -0.04341349005699158,
                -0.1578257977962494,
                2.736999988555908,
                4.310342788696289,
                0.753591001033783,
                1.64458167552948,
                2.162299156188965,
                0.11516676098108292
            ],
            [
                0.5924566984176636,
                0.8165102005004883,
                -0.08504756540060043,
                0.24331440031528473,
                -0.09074008464813232,
                -0.016826767474412918,
                -0.13107572495937347,
                -0.012502745725214481,
                -0.13115960359573364,
                1.8144558668136597,
                2.2313990592956543,
                0.6675165295600891,
                4.096525192260742,
                6.717426300048828,
                3.042708158493042,
                -0.12383706867694855
            ],
            [
                1.6323424577713013,
                -0.16932931542396545,
                0.39029213786125183,
                4.516414642333984,
                2.051246166229248,
                1.5243098735809326,
                -0.05817355215549469,
                -0.16442260146141052,
                1.8967924118041992,
                4.562551498413086,
                -0.16282731294631958,
                0.0640580803155899,
                0.31430092453956604,
                5.683987140655518,
                9.62777328491211,
                3.996286630630493
            ],
            [
                0.535010576248169,
                0.8258602619171143,
                0.6282495856285095,
                0.5694268345832825,
                0.6640351414680481,
                0.5180746912956238,
                0.3609214127063751,
                0.7376264333724976,
                0.8576375842094421,
                0.9345498085021973,
                0.869703471660614,
                0.589644193649292,
                0.48288261890411377,
                0.5841500163078308,
                0.4970117509365082,
                0.2024237960577011
            ],
            [
                0.6671966910362244,
                0.618705689907074,
                0.7882973551750183,
                0.7480764985084534,
                0.8989657163619995,
                0.5642172694206238,
                0.762810230255127,
                0.8652344942092896,
                0.5100518465042114,
                0.5507583022117615,
                0.7619008421897888,
                0.7903686761856079,
                0.6957011222839355,
                0.759788990020752,
                0.6347562074661255,
                0.3267189562320709
            ],
            [
                0.8219559192657471,
                0.916831374168396,
                0.7584840059280396,
                0.6364878416061401,
                0.7743092179298401,
                0.9270350933074951,
                0.8510743379592896,
                0.7736454010009766,
                0.6449487805366516,
                0.7756369113922119,
                0.5735864639282227,
                0.5173361301422119,
                0.5845707058906555,
                0.9495728015899658,
                0.5914350748062134,
                0.44936057925224304
            ],
            [
                0.4960799217224121,
                0.840195894241333,
                0.5236726999282837,
                0.6523358225822449,
                0.8152876496315002,
                1.1549646854400635,
                0.6765176057815552,
                0.8810032606124878,
                0.5392978191375732,
                0.7395185232162476,
                0.5228947997093201,
                0.9782200455665588,
                0.6013144254684448,
                0.6648507118225098,
                1.0117433071136475,
                1.4149750471115112
            ],
            [
                0.4872768819332123,
                0.9204398393630981,
                0.7604686617851257,
                0.7546733021736145,
                0.6786664128303528,
                0.4234650135040283,
                0.763023316860199,
                0.60383540391922,
                0.9546310901641846,
                0.5339227318763733,
                0.5268557667732239,
                0.7560624480247498,
                0.7409435510635376,
                0.4330327808856964,
                0.7778739929199219,
                0.6340646743774414
            ],
            [
                0.6974929571151733,
                0.9397801756858826,
                0.8217174410820007,
                0.5730337500572205,
                0.8270952105522156,
                0.46650251746177673,
                0.8098829388618469,
                0.31821995973587036,
                0.5810896158218384,
                0.5596766471862793,
                0.5273332595825195,
                0.6731364727020264,
                0.6836655139923096,
                0.5493233799934387,
                0.628584623336792,
                0.43981850147247314
            ],
            [
                0.5527248382568359,
                0.9008307456970215,
                0.789780855178833,
                0.6867441534996033,
                1.199055790901184,
                0.5790776014328003,
                0.6458805203437805,
                0.6993235349655151,
                0.9159586429595947,
                0.6644282937049866,
                0.6137382984161377,
                0.6000994443893433,
                0.57221519947052,
                0.7600060105323792,
                0.48937174677848816,
                0.2818646728992462
            ],
            [
                0.6679332852363586,
                0.5267775654792786,
                0.5887101888656616,
                0.45195508003234863,
                0.7259209156036377,
                0.7059952616691589,
                0.5000942945480347,
                0.6536255478858948,
                0.792584240436554,
                0.6439868211746216,
                0.3159019649028778,
                0.6166447401046753,
                0.5191287398338318,
                0.7134178280830383,
                0.3749839961528778,
                0.2845371961593628
            ]
        ],
        [
            [
                1.1772205829620361,
                0.33633145689964294,
                0.6643489003181458,
                1.8739515542984009,
                0.1947138011455536,
                0.5317991375923157,
                0.8074249029159546,
                0.5034361481666565,
                -0.1287723183631897,
                0.22904090583324432,
                1.2566825151443481,
                1.6006985902786255,
                1.2468260526657104,
                1.2068538665771484,
                -0.08148246258497238,
                -0.13118267059326172
            ],
            [
                1.3711307048797607,
                -0.16099292039871216,
                -0.09991636872291565,
                0.0038761701434850693,
                -0.0541507750749588,
                -0.032694924622774124,
                0.9417804479598999,
                0.13332554697990417,
                -0.13668905198574066,
                0.3161018490791321,
                -0.16977190971374512,
                1.2719730138778687,
                0.10404881834983826,
                0.09994236379861832,
                0.19880318641662598,
                -0.12658700346946716
            ],
            [
                0.9851215481758118,
                0.030153004452586174,
                0.43568500876426697,
                -0.14073459804058075,
                0.5930899977684021,
                -0.04458017647266388,
                0.4248911738395691,
                0.6285127997398376,
                2.293395757675171,
                0.5255317687988281,
                0.30656254291534424,
                -0.05471271276473999,
                -0.0861971378326416,
                -0.15912149846553802,
                -0.004447084851562977,
                -0.03444017097353935
            ],
            [
                0.08163836598396301,
                -0.1632431596517563,
                1.0064936876296997,
                0.6751917004585266,
                -0.1288183033466339,
                -0.14196375012397766,
                -0.16914667189121246,
                0.5257565975189209,
                0.7395364046096802,
                -0.13947589695453644,
                0.735251247882843,
                1.500320553779602,
                0.2492724359035492,
                -0.10846627503633499,
                -0.15867479145526886,
                -0.16992197930812836
            ],
            [
                -0.16749031841754913,
                -0.16978265345096588,
                0.13029661774635315,
                -0.16157226264476776,
                0.10397052019834518,
                0.09240147471427917,
                -0.14755403995513916,
                -0.1508529782295227,
                0.44247308373451233,
                -0.1390196681022644,
                0.7195626497268677,
                1.0120421648025513,
                0.31949612498283386,
                -0.16091938316822052,
                -0.14421424269676208,
                -0.005344756413251162
            ],
            [
                1.3709471225738525,
                0.2555294632911682,
                -0.16992506384849548,
                0.08629593998193741,
                0.5693437457084656,
                -0.09532902389764786,
                -0.08534108102321625,
                -0.13363011181354523,
                0.6097471714019775,
                0.08159840852022171,
                -0.16932155191898346,
                -0.04570472240447998,
                0.6807908415794373,
                1.3712067604064941,
                -0.09826500713825226,
                0.12301703542470932
            ],
            [
                0.7254552245140076,
                0.10659066587686539,
                -0.16718558967113495,
                0.6357788443565369,
                0.3828941583633423,
                -0.15788884460926056,
                -0.002475832821801305,
                0.04034361615777016,
                -0.033207882195711136,
                0.7868141531944275,
                -0.056261204183101654,
                -0.14244219660758972,
                0.46416163444519043,
                -0.0031861080788075924,
                -0.06321951001882553,
                -0.028042735531926155
            ],
            [
                0.8431742191314697,
                0.5822690725326538,
                -0.16614779829978943,
                -0.04999694973230362,
                0.6180881857872009,
                1.5787758827209473,
                -0.15795287489891052,
                0.7885103225708008,
                -0.059372156858444214,
                -0.15587233006954193,
                1.6050955057144165,
                1.3737653493881226,
                0.09220023453235626,
                -0.128528892993927,
                -0.03582003712654114,
                0.9766290783882141
            ],
            [
                -0.08142764121294022,
                0.040867894887924194,
                -0.07617264986038208,
                0.039268169552087784,
                0.03535625711083412,
                0.15896838903427124,
                -0.10886295139789581,
                0.1659059226512909,
                0.11932564526796341,
                0.6921805143356323,
                0.011386709287762642,
                0.13372065126895905,
                0.05758976936340332,
                -0.1540912240743637,
                -0.16991792619228363,
                0.41335824131965637
            ],
            [
                0.25278663635253906,
                0.011702618561685085,
                0.023995520547032356,
                -0.00784813892096281,
                0.4367309510707855,
                -0.08820997923612595,
                -0.12153536081314087,
                -0.10502953082323074,
                0.11945363134145737,
                0.32593563199043274,
                -0.02704467996954918,
                -0.14366522431373596,
                -0.07323519885540009,
                -0.09581264108419418,
                0.02831362560391426,
                0.8093537092208862
            ],
            [
                0.12732800841331482,
                -0.15862227976322174,
                -0.13129273056983948,
                0.422732412815094,
                -0.06712473183870316,
                0.10083682835102081,
                -0.09916950762271881,
                0.18935024738311768,
                0.012013259343802929,
                0.1662573367357254,
                0.24264293909072876,
                0.11556758731603622,
                0.16980262100696564,
                0.5367836952209473,
                0.35234835743904114,
                0.6317989826202393
            ],
            [
                0.09083057940006256,
                0.07085280120372772,
                -0.16271208226680756,
                0.15648968517780304,
                0.16488641500473022,
                0.26937541365623474,
                -0.10663797706365585,
                0.033012911677360535,
                -0.030418984591960907,
                0.1641988456249237,
                0.055105067789554596,
                -0.1079540029168129,
                0.05962243303656578,
                0.2800196409225464,
                0.28982797265052795,
                0.1695227175951004
            ],
            [
                0.5030098557472229,
                0.25729167461395264,
                0.19071431457996368,
                0.047659873962402344,
                -0.164008229970932,
                0.2692863941192627,
                -0.027328787371516228,
                0.16613827645778656,
                0.40888476371765137,
                -0.12762165069580078,
                0.2049335092306137,
                0.18793989717960358,
                -0.0542326346039772,
                0.24569152295589447,
                0.08719678223133087,
                0.08294457197189331
            ],
            [
                0.07070951163768768,
                -0.1169397160410881,
                -0.06843263655900955,
                0.10166220366954803,
                -0.006443880498409271,
                -0.05113638564944267,
                0.2740720212459564,
                0.07529255747795105,
                0.3781091570854187,
                -0.029040534049272537,
                0.22843122482299805,
                0.2802901268005371,
                0.7441698312759399,
                -0.02300466038286686,
                0.24770091474056244,
                0.234667107462883
            ],
            [
                0.0020877073984593153,
                -0.025455234572291374,
                -0.007606957107782364,
                0.07003794610500336,
                0.04855295643210411,
                0.24077363312244415,
                0.33246666193008423,
                0.012217137962579727,
                -0.16047342121601105,
                0.03378165513277054,
                0.4222510755062103,
                -0.04863946884870529,
                0.09467527270317078,
                -0.027559470385313034,
                0.2800741493701935,
                0.8065320253372192
            ],
            [
                0.5114806890487671,
                0.05860598012804985,
                -0.07015020400285721,
                0.004467266611754894,
                -0.09719071537256241,
                -0.061930976808071136,
                0.032834894955158234,
                0.4935116171836853,
                0.08516210317611694,
                0.05660206824541092,
                0.07596918940544128,
                0.2356174737215042,
                0.0885910764336586,
                -0.037817131727933884,
                -0.15180516242980957,
                0.11860920488834381
            ]
        ],
        [
            [
                3.5882601737976074,
                1.6415952444076538,
                0.6986401677131653,
                2.4762721061706543,
                3.6178364753723145,
                4.355965614318848,
                3.1309707164764404,
                4.417500019073486,
                3.2106425762176514,
                0.4444548189640045,
                1.3889591693878174,
                1.5268001556396484,
                3.6970677375793457,
                2.794529914855957,
                0.23241351544857025,
                1.0248521566390991
            ],
            [
                0.16381026804447174,
                1.4235763549804688,
                0.11624836921691895,
                -0.005179793108254671,
                0.5194988250732422,
                1.6270451545715332,
                2.752918004989624,
                4.318803310394287,
                0.18462972342967987,
                2.3698601722717285,
                1.9469045400619507,
                2.8695690631866455,
                2.8637053966522217,
                0.27267172932624817,
                0.1701192557811737,
                0.13012804090976715
            ],
            [
                1.9889799356460571,
                -0.10185693949460983,
                1.2613316774368286,
                -0.08131952583789825,
                0.4116481840610504,
                2.5964155197143555,
                4.504863739013672,
                1.7356791496276855,
                1.128512978553772,
                2.305292844772339,
                3.1722137928009033,
                0.9896376729011536,
                1.8112579584121704,
                1.6971542835235596,
                3.137206792831421,
                1.6955816745758057
            ],
            [
                0.4819076359272003,
                1.8492428064346313,
                2.630150556564331,
                1.2400819063186646,
                1.143462061882019,
                0.2550710141658783,
                2.7557895183563232,
                2.675424337387085,
                1.3760924339294434,
                0.8917669653892517,
                4.0623955726623535,
                2.2996270656585693,
                1.2065820693969727,
                2.0510997772216797,
                3.269785165786743,
                3.957963705062866
            ],
            [
                3.2515017986297607,
                3.9802792072296143,
                0.9847081899642944,
                0.05596163868904114,
                -0.04479596018791199,
                1.4888383150100708,
                2.5328471660614014,
                0.8677275776863098,
                -0.06108282506465912,
                -0.11073930561542511,
                3.843940258026123,
                2.654993772506714,
                2.660662889480591,
                1.2290513515472412,
                1.024061918258667,
                2.4957127571105957
            ],
            [
                1.0720436573028564,
                4.201565742492676,
                1.6989431381225586,
                0.5571998953819275,
                1.7347102165222168,
                1.6156549453735352,
                2.568977117538452,
                1.9269888401031494,
                3.3087000846862793,
                2.7350330352783203,
                2.1006760597229004,
                0.22957880795001984,
                0.3555772304534912,
                0.8580906987190247,
                0.5180752277374268,
                4.402581214904785
            ],
            [
                4.281802654266357,
                3.075563907623291,
                3.4813928604125977,
                3.1556589603424072,
                3.23945951461792,
                3.2665939331054688,
                2.631319046020508,
                2.3790736198425293,
                1.7346200942993164,
                0.4957466721534729,
                0.8828673958778381,
                0.6000818014144897,
                1.736075520515442,
                0.07355484366416931,
                3.532198905944824,
                1.288296103477478
            ],
            [
                2.478562355041504,
                3.329885482788086,
                2.3356096744537354,
                -0.009002185426652431,
                -0.10360588133335114,
                4.1102471351623535,
                2.5600695610046387,
                3.220912456512451,
                3.4456546306610107,
                2.6991119384765625,
                3.590592384338379,
                1.2677463293075562,
                0.02139800786972046,
                -0.14277154207229614,
                1.1256448030471802,
                3.588793992996216
            ],
            [
                0.006324440240859985,
                -0.035823091864585876,
                0.15001201629638672,
                0.1124340370297432,
                -0.01903657801449299,
                -0.11107581853866577,
                -0.13078191876411438,
                0.04623368754982948,
                0.4265930950641632,
                0.014320790767669678,
                -0.09178141504526138,
                -0.12128733843564987,
                -0.02523169107735157,
                -0.13784025609493256,
                -0.07070975750684738,
                -0.04369724169373512
            ],
            [
                0.001102427951991558,
                0.040807876735925674,
                0.1327064484357834,
                0.11374855041503906,
                -0.016406890004873276,
                -0.06503669917583466,
                0.12076155841350555,
                0.24042464792728424,
                -0.04329502955079079,
                0.24242334067821503,
                0.29474666714668274,
                -0.1646217703819275,
                -0.10925086587667465,
                -0.1104547455906868,
                -0.12005902826786041,
                0.15509003400802612
            ],
            [
                0.0063307322561740875,
                0.10529779642820358,
                0.18496033549308777,
                -0.08061180263757706,
                -0.03786300867795944,
                -0.03227725997567177,
                0.16329248249530792,
                0.20308013260364532,
                0.1473236232995987,
                0.0028312169015407562,
                -0.0601646825671196,
                -0.10844207555055618,
                -0.0391710065305233,
                -0.028195377439260483,
                -0.09393700212240219,
                0.31714290380477905
            ],
            [
                0.07085402309894562,
                0.0799054205417633,
                -0.02192830666899681,
                0.15625184774398804,
                0.04782281443476677,
                -0.11504673957824707,
                0.013075807131826878,
                -0.09574864059686661,
                0.0724383220076561,
                0.03076467476785183,
                0.09902540594339371,
                -0.062324900180101395,
                -0.15930919349193573,
                -0.042146001011133194,
                -0.014004573225975037,
                0.37856629490852356
            ],
            [
                0.18206855654716492,
                0.2575781047344208,
                0.11618480086326599,
                -0.0554455891251564,
                0.167127788066864,
                0.075377456843853,
                -0.05552919954061508,
                -0.0824059322476387,
                -0.045588646084070206,
                -0.13027611374855042,
                -0.03022419661283493,
                0.03529691323637962,
                -0.09289202094078064,
                0.015597211197018623,
                0.031644344329833984,
                0.3588343560695648
            ],
            [
                0.12446240335702896,
                0.02013530395925045,
                0.1823519915342331,
                0.16210465133190155,
                0.11106313765048981,
                -0.03465784341096878,
                0.04429172724485397,
                0.15457861125469208,
                0.01960141956806183,
                -0.10557787120342255,
                -0.022010894492268562,
                0.00630548782646656,
                -0.1046939268708229,
                -0.12233736366033554,
                0.0820598229765892,
                0.05200678110122681
            ],
            [
                0.10992418974637985,
                -0.05117173492908478,
                0.06262395530939102,
                0.03418336436152458,
                0.0348065122961998,
                0.0766400694847107,
                -0.008852530270814896,
                0.022842561826109886,
                -0.07151170819997787,
                -0.07319393008947372,
                -0.06805964559316635,
                -0.1317971795797348,
                -0.04936177656054497,
                -0.12293191999197006,
                -0.004585455637425184,
                0.10547120869159698
            ],
            [
                0.3891889750957489,
                0.2558295726776123,
                0.3785257339477539,
                0.030915893614292145,
                0.24482561647891998,
                0.5784422159194946,
                0.12668810784816742,
                0.16629290580749512,
                0.14695900678634644,
                0.2633514702320099,
                0.22677162289619446,
                0.16509807109832764,
                0.14528854191303253,
                0.1145423948764801,
                0.13102269172668457,
                0.15965919196605682
            ]
        ],
        [
            [
                1.819661021232605,
                1.0394501686096191,
                3.0856635570526123,
                1.8975369930267334,
                1.3313082456588745,
                1.7465678453445435,
                1.3343526124954224,
                0.8149837255477905,
                0.26455244421958923,
                0.6263152360916138,
                1.95059072971344,
                1.3654661178588867,
                1.3101987838745117,
                1.2787706851959229,
                0.26383328437805176,
                0.5616438388824463
            ],
            [
                2.5679967403411865,
                1.3377137184143066,
                4.600773334503174,
                4.030395984649658,
                2.534168004989624,
                0.010232157073915005,
                0.07710161805152893,
                1.7545725107192993,
                2.9130125045776367,
                2.2266921997070312,
                2.9833858013153076,
                2.2720186710357666,
                2.6809675693511963,
                2.3703255653381348,
                2.4470667839050293,
                2.3993892669677734
            ],
            [
                2.6044464111328125,
                1.3498272895812988,
                1.63351571559906,
                0.65993332862854,
                2.7748160362243652,
                0.4752342998981476,
                0.3139720559120178,
                3.092736005783081,
                1.710741400718689,
                1.4751237630844116,
                1.784158706665039,
                2.768434762954712,
                1.3231837749481201,
                0.42273953557014465,
                1.8710137605667114,
                0.8196073770523071
            ],
            [
                0.001493913820013404,
                2.205702543258667,
                3.7567341327667236,
                1.6851460933685303,
                1.0190941095352173,
                1.9155765771865845,
                3.088229179382324,
                3.3789892196655273,
                1.646897554397583,
                3.338268995285034,
                1.9956941604614258,
                3.2860491275787354,
                2.3721070289611816,
                2.2041049003601074,
                0.6589595079421997,
                0.5253031849861145
            ],
            [
                -0.1594105362892151,
                -0.16728410124778748,
                2.095179796218872,
                4.3697614669799805,
                3.328137159347534,
                2.1642980575561523,
                1.6362216472625732,
                0.8476075530052185,
                0.5411907434463501,
                2.506498098373413,
                1.6123648881912231,
                0.6421991586685181,
                2.523662567138672,
                2.1393465995788574,
                1.103118658065796,
                -0.15662632882595062
            ],
            [
                0.5061706304550171,
                0.8397265076637268,
                0.36879241466522217,
                3.8087077140808105,
                1.6299858093261719,
                0.3054826855659485,
                2.7392759323120117,
                0.6523395776748657,
                2.5192506313323975,
                2.554349184036255,
                1.6560980081558228,
                1.9820802211761475,
                1.1019610166549683,
                2.701406717300415,
                0.8516189455986023,
                1.2450342178344727
            ],
            [
                2.485417604446411,
                4.108031749725342,
                -0.1497432291507721,
                0.8820358514785767,
                2.016824960708618,
                -0.16786330938339233,
                0.3159506618976593,
                0.25324541330337524,
                0.9758126139640808,
                1.7380613088607788,
                0.39339810609817505,
                2.6503913402557373,
                2.9567031860351562,
                1.721488118171692,
                1.2384761571884155,
                0.24769634008407593
            ],
            [
                1.8895246982574463,
                0.20501314103603363,
                0.2687109410762787,
                0.907436728477478,
                0.9639845490455627,
                -0.006504049524664879,
                -0.020585445687174797,
                0.9342139959335327,
                -0.01371696311980486,
                1.5292125940322876,
                1.7878139019012451,
                2.710812568664551,
                2.649613857269287,
                3.173987865447998,
                3.599332809448242,
                1.0153967142105103
            ],
            [
                -0.1429126262664795,
                -0.15251053869724274,
                -0.08212326467037201,
                -0.10112314671278,
                -0.06806672364473343,
                -0.10755105316638947,
                -0.10150008648633957,
                -0.09676533192396164,
                -0.09049205482006073,
                -0.07055968046188354,
                -0.10877835750579834,
                -0.10823028534650803,
                -0.14232531189918518,
                -0.14807148277759552,
                -0.16491307318210602,
                -0.1635364145040512
            ],
            [
                -0.15320827066898346,
                -0.0644121915102005,
                -0.050381191074848175,
                -0.0868891105055809,
                -0.10563194006681442,
                -0.13782399892807007,
                -0.05153929814696312,
                -0.09684519469738007,
                -0.11908171325922012,
                -0.06324825435876846,
                -0.1412823498249054,
                -0.08362758159637451,
                -0.08593433350324631,
                -0.09334560483694077,
                -0.07985476404428482,
                -0.10993720591068268
            ],
            [
                -0.1433131843805313,
                -0.1306549310684204,
                -0.05587643384933472,
                -0.09602665901184082,
                -0.08060150593519211,
                -0.07393533736467361,
                -0.09301196783781052,
                -0.1011534109711647,
                -0.06146605312824249,
                -0.06552282720804214,
                -0.08676020056009293,
                -0.07801047712564468,
                -0.14552336931228638,
                -0.09486435353755951,
                -0.07124253362417221,
                -0.12694363296031952
            ],
            [
                -0.15363670885562897,
                -0.0918399915099144,
                -0.08220630139112473,
                -0.10804731398820877,
                -0.11797426640987396,
                -0.08566456288099289,
                -0.10525641590356827,
                -0.07938315719366074,
                -0.11368175595998764,
                -0.0983363687992096,
                -0.06195607781410217,
                -0.12472371757030487,
                -0.13693402707576752,
                -0.10474501550197601,
                -0.0880170613527298,
                -0.06645415723323822
            ],
            [
                -0.15229159593582153,
                -0.0889255627989769,
                -0.12930487096309662,
                -0.09272173047065735,
                -0.10755013674497604,
                -0.12530359625816345,
                -0.08957035839557648,
                -0.09153969585895538,
                -0.1173674613237381,
                -0.09457603096961975,
                -0.08234262466430664,
                -0.16468259692192078,
                -0.12859344482421875,
                -0.13057997822761536,
                -0.08787258714437485,
                -0.10386175662279129
            ],
            [
                -0.16680411994457245,
                -0.09769207239151001,
                -0.10807953029870987,
                -0.060301296412944794,
                -0.1145043820142746,
                -0.12083631008863449,
                -0.07998386770486832,
                -0.12076018750667572,
                -0.10972610116004944,
                -0.11476431787014008,
                -0.09809625148773193,
                -0.11444392055273056,
                -0.11051695048809052,
                -0.12433021515607834,
                -0.09228809922933578,
                -0.16529490053653717
            ],
            [
                -0.1643197387456894,
                -0.08320188522338867,
                -0.13156971335411072,
                -0.065662682056427,
                -0.10168253630399704,
                -0.14066211879253387,
                -0.10318654030561447,
                -0.12896031141281128,
                -0.12918558716773987,
                -0.12933331727981567,
                -0.1597156822681427,
                -0.1495208740234375,
                -0.08792728185653687,
                -0.10814814269542694,
                -0.138169065117836,
                -0.16950200498104095
            ],
            [
                -0.15785960853099823,
                -0.16183830797672272,
                -0.15887419879436493,
                -0.16412925720214844,
                -0.15376608073711395,
                -0.16472575068473816,
                -0.1695583015680313,
                -0.16926084458827972,
                -0.16919568181037903,
                -0.16973301768302917,
                -0.16408109664916992,
                -0.16393117606639862,
                -0.16447947919368744,
                -0.16802094876766205,
                -0.16968637704849243,
                -0.15294191241264343
            ]
        ]
    ]
]
